import React, { useEffect, useState, SetStateAction } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  TableBody,
  Checkbox,
  TableCell,
  Tooltip,
  Table,
  Collapse,
  TableRow,
  Switch,
  Typography
} from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ErrorOutline as BulkErrorIcon } from "@material-ui/icons";

import HistoryDialog from "./HistoryDialog";
import TransferableDialog from './TransferableDialog';

import { TicketRS } from "@cbtravel/common/lib/web/messages/general/responses/ticket-rs";
import { Configuration } from "@cbtravel/common/lib/shared/config/client-config";
import { TicketController } from "@cbtravel/common/lib/web/api/general/controllers/ticket-controller";
import { EntityDepth } from "@cbtravel/common/lib/shared/common/enumerations/entity-depth";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { TicketRQ } from "@cbtravel/common/lib/web/messages/general/requests/ticket-rq";
import { ExceptionDetail } from '@cbtravel/common/lib/shared/common/exceptions/exception-detail';
import CircularProgress from "@material-ui/core/CircularProgress";
import { dateUtil } from "../../../util/date-util"
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      // margin: theme.spacing(0.5),
    },
    "& .MuiTableCell-root": {
      lineHeight: 1.4,
    },
    "& div.MuiFormGroup-row": {
      display: "inline-block",
      marginTop: "6px",
    },
    "& .MuiFormControlLabel-label.MuiTypography-body1": {
      fontSize: ".9rem",
      lineHeight: 1,
      marginTop: "-2px",
    },
  },
  results: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    marginBottom: 0,
  },
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  download: {
    paddingTop: "7px",
    paddingRight: "4px",
  },
  filetype: {
    padding: 0,
    "& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense": {
      padding: "0 4px 2px",
    },
  },
  switchHeader: {
    fontWeight: 600,
    paddingRight: theme.spacing(2),
    display: "inline",
  },
  table: {
    minWidth: 500,
    width: "100%",
    th: {
      fontWeight: 700,
    },
    "& a": {
      color: "#00467E",
      textDecoration: "none",
    },
    "& .MuiTableCell-head": {
      fontWeight: 700,
    },
    "& .MuiTableCell-head.MuiTableCell-sizeSmall": {
      padding: "0 8px 8px 8px",
    },
    // background color for when "history" gets collapsed
    "& .MuiTableRow-root.moreInfoRow": {
      background: "#f9f9f9",
    },
    "& .moreInfoCell": {
      borderBottom: "none",
      padding: 0,
    },
    "& .tableHeader": {
      fontWeight: 700,
    },
    // This is the table cell padding for the unused tickets
    "& .MuiTableCell-sizeSmall": {
      padding: "0 16px 0 8px",
      marginTop: "2px",
    },
    "& .moreInfoTable .MuiTableCell-sizeSmall": {
      padding: "8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      textAlign: "center",
      width: 400,
    },
    "& .MuiDialogTitle-root": {
      padding: "16px 24px 0",
    },
    "& .MuiDialogContent-root": {
      padding: "0 24px",
    },
    "& .MuiTypography-body1": {
      lineHeight: 1.6,
      fontSize: "13px",
      color: "#4D4D4D",
    },
    "& .MuiTypography-h6": {
      fontWeight: 600,
    },
  },
  iconNonTransferable: {
    margin: "8px auto 14px",
    background: "#bc2c2f",
    borderRadius: 40,
    width: "44px",
    height: "44px",
    "& .MuiSvgIcon-root": {
      color: "#fff",
      marginTop: "10px",
      fontSize: "1.5rem",
    },
    "& svg": {
      color: "#fff",
      marginTop: "11px",
    },
  },
  iconTransferable: {
    margin: "8px auto 14px",
    background: "#417505",
    borderRadius: 40,
    width: "44px",
    height: "44px",
    "& .MuiSvgIcon-root": {
      color: "#fff",
      marginTop: "10px",
      fontSize: "1.5rem",
    },
    "& svg": {
      color: "#fff",
      marginTop: "11px",
    },
  },
  dialogActions: {
    padding: "16px 24px",
    "& .MuiButton-contained.btnTransferable": {
      backgroundColor: "#417505",
    },
    "& .MuiButton-contained.btnNonTransferable": {
      backgroundColor: "#bc2c2f",
    },
  },
  btnCancel: {
    color: "#00467E",
    marginLeft: "0 !important",
    fontSize: "13px",
  },
  redRow: {
    backgroundColor: "#f9ebeb",
  },
  noWrapTooltip: {
    backgroundColor: "#4D4D4D", // snackbar default background color
    maxWidth: "none",
    borderRadius: "5px",
  },
  bulkErrorInfo: {
    top: "7px !important",
    position: "relative",
    // marginLeft: "-23px",
    cursor: "help",
  },
  bulkErrorText: {
    color: "#fff",
    padding: "6px 16px",
    fontSize: "0.8rem",
    fontFamily: "Open Sans, Arial, sans-serif",
    fontWeight: 400,
    "& span": {
      display: "block",
      marginBottom: "5px",
    },
  },
  tableRowErrorHighlight: {
    backgroundColor: "#F8EAEA",
  },
  center: {
    textAlign: "center",
  },
  switchMargin: {
    marginLeft: theme.spacing(2),
  },
}));

interface AirlineDataValue {
  /** The full name of the airline. */
  airline: string;
  /** The path to the image to use for the airline's logo. */
  imgPath: string;
}
interface AirlineDictionary {
  /** The two-character code of the airline to look up. */
  [airlineCode: string]: AirlineDataValue;
}

interface AirlineTooltipProps {
  /** The two-character code of the airline. */
  airlineCode: string
}

/**
 * Component for displaying an airline logo and a tooltip with its name when moused over.
 * 
 * @param props {@link AirlineTooltipProps Properties} for the `AirlineTooltip` component.
 * @returns A JSX element for displaying an airline logo with tooltip display on hover.
 */
function AirlineTooltip(props: AirlineTooltipProps) {
  var { airlineCode } = props;
  const [open, setOpen] = React.useState(false);

  /**
   * Handler for closing the contents of the tooltip.
   */
  function handleClose() {
    setOpen(false);
  }

  /**
   * Handler for opening the contents of the tooltip.
   */
  function handleOpen() {
    setOpen(true);
  }

  const airlineData: AirlineDictionary = {
    AA: {
      airline: "American Airlines",
      imgPath: require("../../../icons/airlines/AA.png").default,
    },
    AC: {
      airline: "Air Canada",
      imgPath: require("../../../icons/airlines/AC.png").default,
    },
    AF: {
      airline: "Air France",
      imgPath: require("../../../icons/airlines/AF.png").default,
    },
    AS: {
      airline: "Alaska Airlines",
      imgPath: require("../../../icons/airlines/AS.png").default,
    },
    DL: {
      airline: "Delta Airlines",
      imgPath: require("../../../icons/airlines/DL.png").default,
    },
    LH: {
      airline: "Lufthansa",
      imgPath: require("../../../icons/airlines/LH.png").default,
    },
    QF: {
      airline: "Qantas",
      imgPath: require("../../../icons/airlines/QF.png").default,
    },
    QR: {
      airline: "Qatar Airways",
      imgPath: require("../../../icons/airlines/QR.png").default,
    },
    SQ: {
      airline: "Singapore Airlines",
      imgPath: require("../../../icons/airlines/SQ.png").default,
    },
    UA: {
      airline: "United Airlines",
      imgPath: require("../../../icons/airlines/UA.png").default,
    },
    WN: {
      airline: "Southwest Airlines",
      imgPath: require("../../../icons/airlines/WN.png").default,
    },
    Unknown: {
      airline: `Unknown (${airlineCode})`,
      imgPath: require("../../../icons/airlines/unknown.png").default,
    },
  };

  // display airline code if we don't have an icon
  if (airlineCode in airlineData) {
    return (
      <React.Fragment>
        <Tooltip
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          title={airlineData[airlineCode].airline}
        >
          <img
            id={`${airlineData[airlineCode].imgPath}`}
            src={airlineData[airlineCode].imgPath}
            style={{ height: "20px" }}
          />
        </Tooltip>
      </React.Fragment>
    );
  } else {
    return (
      <span>{airlineCode}</span>
    );
  }
}

interface TicketDataRowProps {
  /** Object representing the ticket to back this component. */
  ticket: TicketRS;
  /** Object representing the current states of the bulk action switches. */
  bulkSwitchStates: { transferable: boolean; nonTransferable: boolean };
  /** Set of ticket IDs that are currently selected for bulk action. */
  selectedTransferableIds: Set<number>;
  /** Handler for toggling the selection of this ticket data row. */
  handleSelectToggle: (event: React.ChangeEvent<HTMLInputElement>, requestId: number) => void;
  /** React state setter for displaying the spinner. */
  setShowMainSpinner: React.Dispatch<SetStateAction<boolean>>;
  /** The user's access token. */
  token: string;
  /** The timezone to use when displaying times. */
  timeZone: string;
  /** Function to execute when sending a message to the snackbar. */
  showTimedInfoSnackbar: (message: string) => void;
  /** Function to execute when tallying this ticket as successful or not during a bulk update.. */
  tallyBulkResults: (didSucceed: boolean) => void;
  /** Flag to trigger a change for the ticket in a bulk action. */
  inBulkRequest: boolean;
  /** The name of the bulk action that may be performed. */
  bulkAction: "transferable" | "non-transferable";
  /** The text to use as the ticket's comment during a bulk action. */
  bulkComment: string;
  /** Function getting all of the unused tickets and updating the ticket list. */
  getTickets: (ticket: TicketRQ | null) => void;
  /** Whether or not this row has been selected for a bulk action. */
  selected: boolean;
}

/**
 * Component used to display information about a ticket on the UnusedTickets page.
 * 
 * @param props {@link TicketDataRowProps Properties} for the `TicketDataRow` component.
 * @returns A JSX element for displaying ticket information on the UnusedTickets page.
 */
export default function TicketDataRow(props: TicketDataRowProps) {
  const ticket = props.ticket;
  const classes = useStyles();
  const snackbar = useCustomSnackbar();
  const [open, setOpen] = React.useState(false);
  const [openTransferableDialog, setOpenTransferableDialog] = useState<boolean>(false);
  const [actionName, setActionName] = useState<"transferable" | "non-transferable">("transferable");
  const [showMiniSpinner, setShowMiniSpinner] = useState<boolean>(false); // whether to show the mini spinners on each row in a bulk action request
  const [rowBulkError, setRowBulkError] = useState<JsonException | undefined>(undefined); //stores the error object if this row fails a bulk request

  const airlinesThatAllowTransferableTickets = [
    "DL",
    "UA",
    "AS",
    "B6",
    "AA",
    "NH",
  ];

  let ticketCanBeTransfered = airlinesThatAllowTransferableTickets.includes(
    props.ticket.vendorCode
  );

  //triggers individual DataRows calling to services when a bulk request is made in the parent
  useEffect(() => {
    if (props.selected && props.inBulkRequest) {
      let isTransferable = props.bulkAction === "transferable" ? true : false
      setShowMiniSpinner(true)
      updateTicketTransferability(isTransferable, props.bulkComment)
    }
  }, [props.inBulkRequest, props.bulkComment])

  /**
   * Handler for when the transferable switch gets clicked for a single row.
   */
  function handleSingleSwitchToggle() {
    setActionName(ticket.isTransferable ? "non-transferable" : "transferable")
    setOpenTransferableDialog(true)
  }

  /**
   * Makes an API request to change the transferable flag of the ticket backing this row.
   * 
   * @param isTransferable Whether or not this ticket should be changed to transferable or non-transferable.
   * @param comment Comment to include with change to the ticket's transferability.
   */
  async function updateTicketTransferability(isTransferable: boolean, comment: string = "") {
    const ticketRS = ticket;
    ticketRS.isTransferable = isTransferable;

    if (comment) {
      ticketRS.notes = comment;
    }

    const action = isTransferable ? "transferable" : "non-transferable"

    try {
      const response = await TicketController.Update(props.token, ticketRS, EntityDepth.Shallow)

      if (response.ok && !props.inBulkRequest) {
        //show regular snackbar message
        props.showTimedInfoSnackbar(`This ticket is set as ${action}.`)
        setRowBulkError(undefined)
      }

      if (response.ok && props.inBulkRequest) {
        //add bulk to success count
        props.tallyBulkResults(true)
        setRowBulkError(undefined)
      }
    } catch (err) {
      if (props.inBulkRequest) {
        //add to bulk failure count and set individual error
        props.tallyBulkResults(false)
        setRowBulkError(err as JsonException)
      } else {
        snackbar.error(err as JsonException);
        props.setShowMainSpinner(false);
      }
    }
    finally {
      setShowMiniSpinner(false)
      if (!props.inBulkRequest) {
        props.getTickets(null)
      }
    }
  }

  /**
   * the tooltip/error icon to render on rows that fail during a bulk request
   * @param props a JsonException
   * @returns the tooltip/error icon to render on rows that fail during a bulk request; null if no error is passed
   */
  function ErrorTooltip(props: { err: JsonException }) {
    const { err } = props
    if (err) {
      return (
        <Tooltip
          placement='right'
          interactive={true}
          classes={{ tooltip: classes.noWrapTooltip }}
          title={
            <React.Fragment>
              <Typography classes={{ root: classes.bulkErrorText }}>
                <span>{err.message}</span>
                {
                  err.exceptionDetailList.length > 0 &&
                  err.exceptionDetailList.map((e: ExceptionDetail) => <span>{e.message}</span>)
                }
                {(err.uniqueID && Configuration.DisplayRaygunGUID) && <span>Unique ID: {err.uniqueID}</span>}
              </Typography>
            </React.Fragment>
          }>
          <BulkErrorIcon color="error" classes={{ root: classes.bulkErrorInfo }} />
        </Tooltip>
      )
    } else {
      return null;
    }
  }

  /**
   * A functional Component customized for the Unused Tickets page that displays different buttons under different conditions
   * @returns a TableCell with the appropriate buttons based on other conditions on the page (or an empty cell)
   */
  function ActionButtons() {
    let actionsCell = null;
    if (props.ticket.ticketOpenSegments === "AllOpen" && ticketCanBeTransfered) {
      if (props.bulkSwitchStates.transferable || props.bulkSwitchStates.nonTransferable) {
        if (showMiniSpinner) {
          // mini spinners
          actionsCell =
            <React.Fragment>
              <CircularProgress size={20} color={'secondary'} />
            </React.Fragment>;
        }
        else if ((!(props.bulkSwitchStates.transferable && !props.ticket.isTransferable || (props.bulkSwitchStates.nonTransferable && props.ticket.isTransferable)))) {
          actionsCell = <React.Fragment>
            <Box display="flex" justifyContent="center">   
            <div className={classes.center}>
              {rowBulkError && <ErrorTooltip err={rowBulkError} />}
              </div>
              </Box>
            </React.Fragment>;
        }
        else {
          // Bulk Checkboxes
          actionsCell =
            <React.Fragment>
              <Box display="flex" justifyContent="center">              
                <div className={classes.center}>
                {rowBulkError && <ErrorTooltip err={rowBulkError} />}
                  <Checkbox
                    checked={props.selectedTransferableIds.has(ticket.ticketId)}
                    onChange={(event) =>
                      props.handleSelectToggle(event, ticket.ticketId)
                    }
                    inputProps={{ "aria-label": "select all approval requests" }}
                    size="small"
                  />
                </div>
              </Box>

            </React.Fragment>
        }

      } else {
        //toggles
        actionsCell =
          <React.Fragment>
            {/* <Box display="flex" justifyContent="center">  //todo: make the tooltip not suck when it's there. I moved all these commented out styles down from the checkboxes but it's only marginally better
              <div className={classes.center}>   */}
            {rowBulkError && <ErrorTooltip err={rowBulkError} />}
            <Switch
              onChange={() => handleSingleSwitchToggle()}
              checked={ticket.isTransferable}
              name="deny"
              color="secondary"
              size="small"
              className={(rowBulkError === undefined) ? classes.switchMargin : ""}
            />
            {/* </div> */}
            <TransferableDialog
              openActionDialog={openTransferableDialog}
              setOpenActionDialog={setOpenTransferableDialog}
              action={actionName}
              setShowSpinner={props.setShowMainSpinner}
              bulkActionCount={0}
              onConfirm={updateTicketTransferability}
            />
            {/* </Box> */}
          </React.Fragment>
      }
    } else {
      // not transferable; no buttons
      actionsCell = null;
    }

    return (
      <TableCell>
        {actionsCell}
      </TableCell>
    )
  }

  //highlights row if it failed on a bulk action
  let bulkErrorClass = rowBulkError ? { root: classes.tableRowErrorHighlight } : {};
  return (
    <React.Fragment>
      <TableRow
        key={ticket.lastName}
        classes={bulkErrorClass}
      >
        <ActionButtons />
        <TableCell id={`unused-tickets-ticket-status-${ticket.ticketStatus}`}>{ticket.ticketStatus}</TableCell>
        <TableCell id={`unused-tickets-client-name-${ticket.clientName.length > 0 ? ticket.clientName : "-"}`}>
          {ticket.clientName.length > 0 ? ticket.clientName : "-"}
        </TableCell>
        <TableCell id={`unused-tickets-date-of-expiration-${new Date(ticket.dateOfExpiration).toLocaleDateString("en-US")}`}>
          {new Date(ticket.dateOfExpiration).toLocaleDateString("en-US")}
        </TableCell>
        <TableCell id={`unused-tickets-last-name-${ticket.lastName}`}>{ticket.lastName}</TableCell>
        <TableCell id={`unused-tickets-first-name-${ticket.firstName}`}>{ticket.firstName}</TableCell>
        <TableCell id={`unused-tickets-vendor-code-${ticket.vendorCode}`} align="center">
          <AirlineTooltip airlineCode={ticket.vendorCode} />
        </TableCell>
        <TableCell id={`unused-tickets-ticket-number-${String(ticket.ticketNumber)}`}>
          {ticket.ticketNumber}
        </TableCell>
        <TableCell id={`unused-tickets-confirmation-number-${ticket.confirmationNumber}`}>
          <a
            href={`${Configuration.AirPortalUrl}/airtinerary/view/index?recloc=${ticket.confirmationNumber}`}
            target="_blank"
          >
            {ticket.confirmationNumber}
          </a>
        </TableCell>

        <TableCell id={`unused-tickets-total-airfare-${ticket.totalAirFare.toFixed(2)}`} align="center">{ticket.totalAirFare.toFixed(2)}</TableCell>
        <TableCell align="center">
          <HistoryDialog ticket={ticket} timeZone={props.timeZone} />
        </TableCell>
        <TableCell>
          <IconButton
            id="btn-icon-expand-row"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* Collapse info starts here  */}
      <TableRow className="moreInfoRow">
        <TableCell className="moreInfoCell" colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table
                aria-label="more info about unused tickets"
                className="moreInfoTable"
                size="small"
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      width="150"
                      className="tableHeader"
                      scope="row"
                    >
                      Ticket Type
                    </TableCell>
                    <TableCell id={`unused-tickets-ticket-type-${ticket.ticketType}`} width="300">{ticket.ticketType}</TableCell>
                    <TableCell
                      component="th"
                      width="140"
                      className="tableHeader"
                    >
                      City Pair
                    </TableCell>
                    <TableCell id={`unused-tickets-location-pair-${ticket.locationPair.length > 0? ticket.locationPair : "-"}`}  width="260">
                      {ticket.locationPair.length > 0
                        ? ticket.locationPair
                        : "-"}
                    </TableCell>
                    <TableCell
                      component="th"
                      width="160"
                      className="tableHeader"
                    >
                      Project Code
                    </TableCell>
                    <TableCell id={`unused-tickets-project-code-${ticket.projectCode.length > 0 ? ticket.projectCode : "-"}`}  width="260">
                      {ticket.projectCode.length > 0 ? ticket.projectCode : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className="tableHeader">
                      Fare Basis Code
                    </TableCell>
                    <TableCell id={`unused-tickets-fare-basis-code-${ticket.fareBasisCode.length > 0 ? ticket.fareBasisCode : "-"}`}>
                      {ticket.fareBasisCode.length > 0
                        ? ticket.fareBasisCode
                        : "-"}
                    </TableCell>
                    <TableCell component="th" className="tableHeader">
                      Department Code
                    </TableCell>
                    <TableCell id={`unused-tickets-dept-code-${ticket.departmentCode.length > 0 ? ticket.departmentCode : "-"}`}>
                      {ticket.departmentCode.length > 0
                        ? ticket.departmentCode
                        : "-"}
                    </TableCell>
                    <TableCell
                      component="th"
                      className="tableHeader"
                      scope="row"
                    >
                      Account Number
                    </TableCell>
                    <TableCell id={`unused-tickets-account-number-${ticket.clientAccountNumber.length > 0 ? ticket.clientAccountNumber : "-"}`}>
                      {ticket.clientAccountNumber.length > 0
                        ? ticket.clientAccountNumber
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" className="tableHeader">
                      Seat Class
                    </TableCell>
                    <TableCell id={`unused-tickets-seat-class-${ticket.classCode.length > 0 ? ticket.classCode : "-"}`}>
                      {ticket.classCode.length > 0 ? ticket.classCode : "-"}
                    </TableCell>
                    <TableCell component="th" className="tableHeader">
                      Issued Date
                    </TableCell>
                    <TableCell id={`unused-tickets-issued-date-${ticket.dateOfIssue ? dateUtil.formatDate(new Date(ticket.dateOfIssue.toString() + "+00:00"), props.timeZone) : "-"}`}>
                      {ticket.dateOfIssue
                        ? dateUtil.formatDate(new Date(ticket.dateOfIssue.toString() + "+00:00"), props.timeZone)
                        : "-"}
                    </TableCell>
                    <TableCell
                      component="th"
                      className="tableHeader"
                      scope="row"
                    >
                      Invoice
                    </TableCell>
                    <TableCell id={`unused-tickets-invoice-number-${ticket.invoiceNumber.length > 0 ? ticket.invoiceNumber : "-"}`}>
                      {ticket.invoiceNumber.length > 0
                        ? ticket.invoiceNumber
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      className="tableHeader"
                      scope="row"
                    >
                      Departure Date
                    </TableCell>
                    <TableCell id={`unused-tickets-date-of-departure-${ticket.dateOfDeparture ? new Date(ticket.dateOfDeparture).toLocaleDateString(  "en-US"  ) : "-"}`}>
                      {ticket.dateOfDeparture
                        ? new Date(ticket.dateOfDeparture).toLocaleDateString(
                          "en-US"
                        )
                        : "-"}
                    </TableCell>
                    <TableCell component="th" className="tableHeader">
                      Inserted Date
                    </TableCell>
                    <TableCell id={`unused-tickets-inserted-date-${ticket.dateCreated ? dateUtil.formatDate(new Date(ticket.dateCreated.toString() + "+00:00"), props.timeZone) : "-"}`}>
                      {ticket.dateCreated
                        ? dateUtil.formatDate(new Date(ticket.dateCreated.toString() + "+00:00"), props.timeZone)
                        : "-"}
                    </TableCell>
                    <TableCell component="th" className="tableHeader">
                      International
                    </TableCell>
                    <TableCell id={`unused-tickets-international-${ticket.isInternational ? "True" : "False"}`}>
                      {ticket.isInternational ? "True" : "False"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      className="tableHeader"
                      scope="row"
                    >
                      Email
                    </TableCell>
                    <TableCell id={`unused-tickets-email-${ticket.emailAddress}`}>{ticket.emailAddress}</TableCell>
                    <TableCell component="th" className="tableHeader">
                      Inserted by
                    </TableCell>
                    <TableCell id={`unused-tickets-name-${ticket.firstName + "-" + ticket.lastName}`}>
                      {ticket.firstName + " " + ticket.lastName}
                    </TableCell>
                    <TableCell component="th" className="tableHeader">
                      Notes
                    </TableCell>
                    <TableCell id={`unused-tickets-notes-${ticket.notes.length > 0 ? ticket.notes : "-"}`}>
                      {ticket.notes.length > 0 ? ticket.notes : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      className="tableHeader"
                      scope="row"
                    >
                      Currency
                    </TableCell>
                    <TableCell id={`unused-tickets-currency-${ticket.currencyCode.length > 0 ? ticket.currencyCode : "-"}`}>
                      {ticket.currencyCode.length > 0
                        ? ticket.currencyCode
                        : "-"}
                    </TableCell>
                    <TableCell component="th" className="tableHeader">
                      Agent
                    </TableCell>
                    <TableCell id={`unused-tickets-agent-${ticket.agentInitials.length > 0 ? ticket.agentInitials : "-"}`}>
                      {ticket.agentInitials.length > 0
                        ? ticket.agentInitials
                        : "-"}
                    </TableCell>
                    <TableCell component="th" className="tableHeader">
                      Exchange Ticket
                    </TableCell>
                    <TableCell id={`unused-tickets-exchange-ticket-${ticket.ticketNumberExchange.length > 0 ? ticket.ticketNumberExchange : "-"}`}>
                      {ticket.ticketNumberExchange.length > 0
                        ? ticket.ticketNumberExchange
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      className="tableHeader"
                      scope="row"
                    >
                      Segments
                    </TableCell>
                    <TableCell id={`unused-tickets-segments-${ticket.ticketOpenSegments}`}>{ticket.ticketOpenSegments}</TableCell>
                    <TableCell component="th" className="tableHeader">
                      PCC/SID/OID
                    </TableCell>
                    <TableCell id={`unused-tickets-ppc-sid-oid-${ticket.pcc.length > 0 ? ticket.pcc : "-"}`}>
                      {ticket.pcc.length > 0 ? ticket.pcc : "-"}
                    </TableCell>
                    <TableCell component="th" className="tableHeader">
                      Original Ticket
                    </TableCell>
                    <TableCell id={`unused-tickets-orginal-ticket-${ticket.ticketNumberOriginal.length > 0 ? ticket.ticketNumberOriginal  : "-"}`}>
                      {ticket.ticketNumberOriginal.length > 0
                        ? ticket.ticketNumberOriginal
                        : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import OktaSignInWidget from '../components/auth/OktaSignInWidget';
import { withOktaAuth } from '@okta/okta-react';
import { withStyles } from "@material-ui/core/styles";
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';

const styles = {
    "@global": {
        "#pendo-image-badge-5c3c4f83": {
            display: "none !important",
        },
    },
};

export default withStyles(styles)(withOktaAuth(
    class Login extends Component<any> {
        constructor(props: any) {
            super(props);
        }

        /**
         * Either renders the okta signin widget or redirects the user to the default landing page.
         */
        render() {
            return this.props.authState && this.props.authState.isAuthenticated ?
                <Redirect to={{ pathname: Configuration.DefaultLandingPage }} />
                :
                <OktaSignInWidget />
        }
    }
));

import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({

    closeIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
    },
}));

/**
 * Properties for CloseDialogIcon.
 */
interface CloseDialogIconProps {
    /** Function to execute when the close icon is clicked. */
    onClick?:() => void
}

/**
 * Component used to render an icon intended to close something when clicked.
 * 
 * @param props {@link CloseDialogIconProps Properties} for the CloseDialogIcon component.
 * @returns A JSX element used for a close icon.
 */
export default function CloseDialogIcon(props: CloseDialogIconProps) {
    const classes = useStyles();

    return (
        <CloseIcon onClick={props.onClick} className={classes.closeIcon}/>
    );
}

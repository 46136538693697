import { useContext, useEffect, useRef, useState } from "react";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { UserContext } from "../../../components/shared/UserContext";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, CircularProgress, Grid, IconButton, Link, TextField, Typography } from "@material-ui/core";
import { TableContainer, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, } from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import SortIcon from "../../../icons/SortIcon";
import Spinner from "../../../components/shared/Spinner";

import HotelVendorDataRow from "./HotelVendorDataRow";
import VendorForm from "./VendorForm"

interface HotelTabProps {
    /** Object representing the currently active client. */
    activeClient?: ClientRS,
    /** Whether or not to display the VendorForm*/
    displayVendorForm: boolean,
    /** Function to call when changes are discarded from the VendorForm */
    discardChanges: () => void,
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number
    ) => void;
}

const useStyles = makeStyles((theme) => ({
    "@global": {
        ".MuiAutocomplete-option": {
            display: "block",
        },
        ".MuiAutocomplete-popupIndicatorOpen": {
            transform: "rotate(0)"
        }
    },
    results: {
        paddingTop: 33,
        paddingBottom: theme.spacing(0.5),
        marginBottom: 0,
        fontSize: '1rem',
    },
    pagination: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    downloadWrapper: {
        paddingTop: 33,
    },
    download: {
        paddingTop: 18,
        paddingRight: "4px",
        fontSize: '.8rem',
    },
    filetype: {
        padding: 0,
        "& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense":
        {
            padding: "0 4px 2px",
        },
    },
    headersWithIcon: {
        padding: '0px 24px 0px 16px !important',
    },
    table: {
        "& .MuiTableCell-root": {
            borderBottom: 'none',
            lineHeight: 1.5,
        },
        "& .MuiTableCell-head": {
            borderTop: '1px solid #E0E0E0 !important',
            borderBottom: '1px solid #E0E0E0 !important',
            padding: '3px 24px 5px 16px',
            whiteSpace: 'nowrap',
        },
    },
}));


/** Hotel tab for Vendors page */
export default function HotelTab(props: HotelTabProps) {
    const userState = useContext(UserContext);
    const classes = useStyles();
    const fileType = ["CSV", "ZIP"];
    const [loading, setLoading] = useState<boolean>(false);
    const { displayVendorForm, discardChanges } = props;

    return (
        <>
            {displayVendorForm ?
                <VendorForm
                    discardChanges={discardChanges}
                    vendorType="hotel chain"
                    showVendorHelpText={false}
                    isHotel={true}
                /> :
                <Box mt={3}>
                    <Grid container spacing={2} alignContent="flex-end">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Autocomplete
                                options={hotelTestData}
                                getOptionLabel={(option) => option.hotelName}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Filter by name"
                                    variant="outlined"
                                    margin="dense"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <>
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />}
                                popupIcon={<SearchOutlinedIcon />}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Autocomplete
                                id="filter-by-hotel-code"
                                options={hotelCodeData}
                                getOptionLabel={(option) => option.hotelCode}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Filter by code"
                                    variant="outlined"
                                    margin="dense"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <>
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />}
                                popupIcon={<SearchOutlinedIcon />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignContent="flex-end">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box display="flex" flexDirection="row" pb={1} style={{ marginTop: -20, }}>
                                <Box flexGrow={1}>
                                    <h3 className={classes.results}>3 Results</h3>
                                </Box>
                                <Box className={classes.downloadWrapper} display="flex" flexDirection="row">
                                    <Box pt={2} mr={1} className={classes.download}>
                                        <span className="dark-blue">
                                            <Link
                                                href="#"
                                                onClick={() => {
                                                    // handleDownloadReport(
                                                    //   "CSV" ==
                                                    //   userState.properties.get("downloadFormat")
                                                    // );
                                                }}
                                            >
                                                Download report
                                            </Link>
                                        </span>
                                    </Box>
                                    <Box style={{ marginTop: 11, }}>
                                        <Autocomplete
                                            // value={userState.properties.get("downloadFormat")}
                                            // onChange={(
                                            //     event: any,
                                            //     newValue: string | undefined
                                            // ) => {
                                            //     let before = userState.properties.get(
                                            //         "downloadFormat"
                                            //     );
                                            //     if (newValue == "ZIP") {
                                            //         userState.updateProperties("downloadFormat", "ZIP");
                                            //     } else if (newValue == "CSV") {
                                            //         userState.updateProperties("downloadFormat", "CSV");
                                            //     } else {
                                            //         return;
                                            //     }
                                            //     let after = userState.properties.get(
                                            //         "downloadFormat"
                                            //     );
                                            // }}
                                            className={classes.filetype}
                                            // inputValue={userState.properties.get("downloadFormat")}
                                            id="report-filetype"
                                            style={{ width: 70 }}
                                            options={fileType}
                                            disableClearable
                                            autoSelect
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-label="Hotel vendor table"
                                    size="small"
                                    id="table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Code</TableCell>
                                            <TableCell className={classes.headersWithIcon}
                                            // onClick={() => handleSortIconClick(VendorSortType.ByName)}
                                            >Name <SortIcon /></TableCell>
                                            <TableCell>Brand/Group</TableCell>
                                            <TableCell align="center">Display tier</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Edit</TableCell>
                                            <TableCell align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody id="tableBody">
                                        <HotelVendorDataRow code="CN" extraCodes={true} name="Conrad Hotels" brandGroup="Hilton" displayTier={5} isActive={true} />
                                        <HotelVendorDataRow code="DT" extraCodes={false} name="Doubletree" brandGroup="Hilton" displayTier={1} isActive={true} />
                                        <HotelVendorDataRow code="ES" extraCodes={false} name="Embassy Suites" brandGroup="Hilton" displayTier={5} isActive={false} />
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            {/* TODO: Pagination */}
                                            {/* <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        colSpan={12}
                                        count={vendorList.totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={pageNumber}
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={tablePagination}
                                        /> */}
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>}
        </>
    )
}

// Please remove this once hooked up
const hotelTestData = [
    { hotelName: 'Conrad Hotels' },
    { hotelName: 'Doubletree' },
    { hotelName: 'Embassy Suites' },
]
const hotelCodeData = [
    { hotelCode: 'CN' },
    { hotelCode: 'DT' },
    { hotelCode: 'ES' },
]


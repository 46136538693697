import { UserType } from '@cbtravel/common/lib/shared/common/enumerations/user-type';
import { iUserContext } from "@cbtravel/common/lib/shared/interfaces/iUserContext";
import { CustomFieldListFields } from '@cbtravel/common/lib/shared/messages/approval/responses/custom/custom-field-list-fields';
import { CustomFieldValueRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-value-rs";
import { InputDataType } from '@cbtravel/common/lib/web/common/enumerations/input-data-type';
import { InputType } from '@cbtravel/common/lib/web/common/enumerations/input-type';
import { Box, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useContext, useState } from 'react';
import { Control, Controller, DeepMap, FieldErrors } from "react-hook-form";
import { UserFormInputs } from '../../pages/CompanyAdmin/Users/UserForm';
import { ProfileFormInputs } from '../../pages/User/Profile/Profile';
import { RequiredFormInputs } from '../../pages/User/RequiredFields';
import { EnrollFormInputs } from '../../routes/Enroll/Enroll';
import { formUtils } from '../../util/form-utils';
import { UserContext } from "../shared/UserContext";
import { RegexUtils } from '@cbtravel/common/lib/shared/common/regex-utils';
import { DataEntryFlow } from '@cbtravel/common/lib/shared/common/enumerations/data-entry-flow';
import { customFieldUtil } from '../../util/custom-field-util';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltipLabel: { fontSize: 16, pointerEvents: "auto" },
        list: {
            width: "100%",
            height: "100%"
        },
        errorTextField: {
            paddingTop: '5px',
        },
    })
);
/**
 * this is a re-useable component, all 3 of the below types needs to be updated if you import and use this component
 */
type FormInputsType = Control<RequiredFormInputs> | Control<ProfileFormInputs> | Control<UserFormInputs> | Control<EnrollFormInputs>
type FormInputsExportType = Control<ProfileFormInputs | RequiredFormInputs | UserFormInputs | EnrollFormInputs>
type ForminputsErrorType = DeepMap<ProfileFormInputs | RequiredFormInputs | EnrollFormInputs | UserFormInputs, FieldErrors>
interface CustomFFArray<T extends FormInputsType> {
    control: T,
    errors: ForminputsErrorType,
    field: CustomFieldListFields & { id: string },
    index: number,
    customFieldValueList: CustomFieldValueRS[],
    small?: boolean,
    flow: DataEntryFlow,
    viewingOtherProfile?: boolean,
    requireCustomFields?: boolean, /* this is a hacky way to get around updating rhf, remove when past rhf 7.34 */
}

export default function CustomFieldFieldArray<T extends FormInputsType>(props: CustomFFArray<T>) {
    const classes = useStyles();
    const { userContext } = useContext<iUserContext>(UserContext);
    const { index, field, customFieldValueList, control, errors, requireCustomFields } = props;

    let canEdit = false;
    let canView = false;

    // Decide if whoever is viewing this component can edit/view.
    if (!props?.viewingOtherProfile) {
        canEdit = customFieldUtil.canDataEntryEdit(field.accessibility, userContext.userType, props.flow);
        canView = customFieldUtil.canDataEntryView(field.accessibility, userContext.userType);
    }
    else {
        canEdit = customFieldUtil.canEditForOthers(field.accessibility, userContext.userType, props.flow);
        canView = customFieldUtil.canViewForOthers(field.accessibility, userContext.userType, props.flow);
    }

    if (field.inputType === InputType.TextBox && field.customFieldId > -1 && canView) {
        return (
            <Grid item xs={12} sm={12} md={props.small ? 12 : 6} lg={props.small ? 12 : 6} key={field.number} >
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`customFieldList.${index}.value` as const}
                        control={control as FormInputsExportType}
                        rules={{
                            ...(requireCustomFields && field.isRequired && { required: "This field is required" }),
                            ...(field.inputMaxLength > 0 && { maxLength: { value: field.inputMaxLength, message: `Max length is ${field.inputMaxLength} characters.` } }),
                            ...(field.inputMinLength > 0 && { minLength: { value: field.inputMinLength, message: `Min length of ${field.inputMinLength} characters is requried.` } }),

                            // InputDataType Checks, it should only get one.
                            ...(field.inputDataType === InputDataType.AlphaNumeric && { pattern: { value: RegexUtils.ALPHANUMERIC_SPECIAL_CHARS, message: `Only letters and numbers are allowed in this field` } }),
                            ...(field.inputDataType === InputDataType.Numeric && { pattern: { value: RegexUtils.NUMERIC, message: `Only numbers are allowed in this field` } }),
                            ...(field.inputDataType === InputDataType.Currency && { pattern: { value: RegexUtils.CURRENCY, message: `Only numbers, periods, and commas are allowed in this field` } }),
                            ...(field.inputDataType === InputDataType.Alpha && { pattern: { value: RegexUtils.ALPHA, message: `Only letters are allowed in this field` } }),
                        }}
                        render={({
                            field: { onChange, value: rhfValue, ref, onBlur }
                        }) => (
                            <TextField
                                id={`ff-fieldvalue-freeform-${index}`}
                                variant="outlined"
                                size="small"
                                error={errors.customFieldList?.[index] ? true : false}
                                placeholder={"EX: " + field.example}
                                fullWidth
                                value={rhfValue ? rhfValue : ""}
                                ref={ref}
                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                onChange={(e) => onChange(e.target.value)}
                                disabled={!canEdit}
                                label={
                                    <Box mr={-0.5}>
                                        {field.name}{" "}{requireCustomFields && props.flow === DataEntryFlow.NewUserCreation && "*"}
                                        {field.noteExternal &&
                                            <Tooltip arrow
                                                title={field.noteExternal}
                                                placement="top"
                                            >
                                                <InfoOutlinedIcon className={classes.tooltipLabel} />
                                            </Tooltip>
                                        }
                                    </Box>
                                }
                                InputProps={{ autoComplete: "off" }}
                            />
                        )} />
                </form>
                <Typography variant='body2' color='error' className={classes.errorTextField}>
                    {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                    {props.errors?.customFieldList?.[index]?.value && (props?.errors?.customFieldList?.[index]?.value as any).message}
                </Typography>
            </Grid>
        )
    }
    else if (field.inputType === InputType.List && field.customFieldId > -1 && canView) {
        return (
            <Grid item xs={12} sm={12} md={props.small ? 12 : 6} lg={props.small ? 12 : 6} key={field.number}>
                <Controller
                    name={`customFieldList.${index}.value` as const}
                    control={control as FormInputsExportType}
                    rules={{
                        ...(requireCustomFields && field.isRequired && { required: "This field is required" }),

                        // InputDataType Checks, it should only get one.
                        ...(field.inputDataType === InputDataType.AlphaNumeric && { pattern: { value: RegexUtils.ALPHANUMERIC_SPECIAL_CHARS, message: `Only alpha numeric characters are allowed in this field` } }),
                        ...(field.inputDataType === InputDataType.Numeric && { pattern: { value: RegexUtils.NUMERIC, message: `Only numbers are allowed in this field` } }),
                        ...(field.inputDataType === InputDataType.Currency && { pattern: { value: RegexUtils.CURRENCY, message: `Only currency is allowed in this field` } }),
                        ...(field.inputDataType === InputDataType.Alpha && { pattern: { value: RegexUtils.ALPHA, message: `Only Letters allowed in this field` } }),
                    }}
                    render={({ field: { onChange, value: rhfValue, ref, onBlur } }) => {
                        return <Autocomplete
                            id={`fieldvalue-${index}-list`}
                            options={customFieldValueList}
                            getOptionLabel={(option) => option.name === "" ? option.value : option.name}
                            getOptionSelected={(option, val) => option.value.toUpperCase() == val?.value.toUpperCase()}
                            fullWidth
                            autoSelect
                            autoHighlight
                            value={customFieldValueList.find(f => f.value === (rhfValue as CustomFieldValueRS).value)}
                            ref={ref}
                            onBlur={onBlur}
                            disabled={!canEdit}
                            onChange={(_, val) => val === null ? onChange(new CustomFieldValueRS()) : onChange(val)} // if the value is null, return an empty string
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id={`ff-fieldvalue-noteExternal-${index}`}
                                    label={
                                        <Box mr={-0.5}>
                                            {/* display asterisk if required or if we're displaying Rule class */}
                                            {field.name}{" "}{requireCustomFields || field.number === 56 && props.flow === DataEntryFlow.NewUserCreation && "*"}
                                            {field.noteExternal &&
                                                <Tooltip arrow
                                                    title={field.noteExternal}
                                                    placement="top"
                                                >
                                                    <InfoOutlinedIcon className={classes.tooltipLabel} />
                                                </Tooltip>
                                            }
                                        </Box>
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    error={errors.customFieldList?.[index] ? true : false}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            )}
                        />
                    }
                    } />

                <Typography variant='body2' color='error' className={classes.errorTextField}>
                    {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                    {props.errors?.customFieldList?.[index]?.value && (props?.errors?.customFieldList?.[index]?.value as any).message}
                </Typography>
            </Grid>)
    } else {
        return (<span />)
    }
}

import React, { useState, useEffect } from "react";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
} from "@material-ui/core";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientSearchResults: {
      marginTop: "32px",
      "@media screen and (-ms-high-contrast: none)": {
        marginTop: "-45px",
      },
      "& .MuiListItemSecondaryAction-root": {
        top: "3.5px",
        right: "24px",
        transform: "none",
      },
      "& .MuiTypography-body2": {
        fontSize: ".9rem",
        fontWeight: 700,
        lineHeight: 1.5,
        color: "#4D4D4D",
        width: "250px",
      },
      "& .MuiTypography-body2.MuiTypography-colorTextSecondary": {
        color: "#808080",
        fontSize: ".75rem",
        fontWeight: 400,
      },
      "& .MuiIconButton-root": {},
      "& .MuiIconButton-label": {
        fontSize: ".7rem",
        textTransform: "uppercase",
      },
    },
  })
);

/**
 * Properties for the ClientSelectorList component.
 */
interface ClientSelectorListProps {
  /** Array of `ClientRS` objects to display when searching. */
  searchDisplay: ClientRS[] | null,
  /** Action to perform when a Client is selected from the list. */
  onClick: (client: ClientRS) => void,
  /** Array of `Clients` objects to display when `searchDisplay` is null. */
  recentDisplay: ClientRS[] | null
}

/**
 * The `ClientSelectorList` component is used within the `ClientSelector` component to display the 
 * Client history as well as current Client search results.
 * 
 * @param props {@link ClientSelectorListProps Properties} for the ClientSelectorList component.
 * @param props.searchDisplay test
 * @returns A JSX element used to display a list of Clients in the ClientSelector.
 */
export default function ClientSelectorList(props: ClientSelectorListProps) {

  const classes = useStyles();
  const { searchDisplay, onClick, recentDisplay } = props;
  const [displayClients, setDisplayClients] = useState<ClientRS[] | null>(null);

  // if we're searching, display that list, otherwise display recent clients
  useEffect(() => {
    searchDisplay ? setDisplayClients(searchDisplay) : setDisplayClients(recentDisplay)
  }, [props]);

  return (
    <div>
      <Box mt={2} mb={-1}>
        <List dense={true} className={classes.clientSearchResults}>

          { //this maps each client in the displayList into a ListItem element
            displayClients && displayClients.map(function (client: ClientRS) {
              return (
                <ListItem
                  button
                  component="a"
                  key={client.clientId}
                  href="#"
                  onClick={() => onClick(client)}
                >
                  <ListItemText
                    primary={client.name}
                    secondary={`${client.clientType.name}: ${client.accountNumber}, ID: ${client.clientId}`}
                  />

                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" disabled>
                      {client.clientStatus.activeStatus ? (
                        <span className="status-active">Active</span>
                      ) : (
                        <span className="status-inactive">Inactive</span>
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          }
        </List>
      </Box>
    </div>
  );
}

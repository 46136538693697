import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { Checkbox, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      "& .MuiChip-label": {
        paddingLeft: "8px",
        paddingRight: "12px",
        paddingBottom: "2px",
      },
      "& .MuiChip-root": {
        backgroundColor: "#00467E",
        fontSize: ".75rem",
        height: "24px",
      },
      "& .MuiChip-deleteIcon": {
        color: "white",
        width: "15px",
      },
    },
    checkBox: { color: "#00467E" },
    "@global": {
      ".MuiAutocomplete-listbox": {
        padding: "0 0 8px",
      },
      ".MuiAutocomplete-option": {
        fontSize: ".8rem",
        padding: "4px 16px 4px 8px",
      },
    },
  })
);

export interface Option {
  label: string;
  value: number;
}

/**
 * Properties for a MultipleSelectChips component.
 */
interface MultipleSelectChipsProps {
  /** Text to display as a label for the input element. */
  label: string,
  /** Array of Options available to the component.. */
  options: Option[],
  /** Array of selected values held by the component. */
  value: Option[],
  /** True if there is an error related to the values. */
  error: boolean,
  /** Message to display if `error` is true. */
  errorMessage: string,
  /** Unique ID for each multi select chips */
  id: string,
  /** Handler for when the selected values have changed. */
  onChange: (value: Option[]) => void,
  /** Handler for when the user switches focus away */
  onBlur?: () => void
}

/**
 * This component is designed to be used with an external state.
 * Set the value prop to the 'getter' of the state variable and the onChange prop
 * to the 'setter' of the state variable.
 * 
 * @param props {@link MultipleSelectChipsProps Properties} for the `MultipleSelectChips` component.
 * @returns A JSX element used for a multiple-select autocomplete component.
 */
export default function MultipleSelectChips(props: MultipleSelectChipsProps) {
  const classes = useStyles();
  const { label, options, value, error, errorMessage, id } = props;
  const [inputValue, setInputValue] = React.useState("");
  const filterOptions = createFilterOptions<Option>({
    limit: 50
  });

  return (
    <Autocomplete
      id={id}
      className={classes.formControl}
      multiple={true}
      options={options}
      filterOptions={filterOptions}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (reason !== "reset") {
          setInputValue(value);
        }
      }}
      disableCloseOnSelect={true}
      getOptionLabel={(option: Option) => {
        return option.label;
      }}
      getOptionSelected={(option: Option, value: Option) => {
        return option.value == value.value;
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checked={selected}
            checkedIcon={
              <CheckBoxIcon className={classes.checkBox} fontSize="small" />
            }
          />
          {option && option.label}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          InputLabelProps={{ shrink: true }}
          margin="dense"
          error={error}
          helperText={errorMessage}
        />
      )}
      onChange={(event, value) => props.onChange(value)}
      onBlur={() => props.onBlur?.()}

      onClose={(event, reason) => {
        if (reason === "escape" || reason === "blur" || (reason === "toggleInput" && event.type === "click")) {
          setInputValue("");
        }
      }}
    />
  );
}


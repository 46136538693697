import { CustomFieldAccessibility } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-accessibility";
import { CustomFieldCreationPermission } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-creation-permission";
import { CustomFieldSource } from '@cbtravel/common/lib/shared/common/enumerations/custom-field-source';
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";
import { ExceptionLevel } from "@cbtravel/common/lib/shared/common/exceptions/exception-level";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { RegexUtils } from "@cbtravel/common/lib/shared/common/regex-utils";
import { Configuration } from "@cbtravel/common/lib/shared/config/client-config";
import { CustomFieldRS } from '@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs';
import { InputDataType } from '@cbtravel/common/lib/web/common/enumerations/input-data-type';
import { InputType } from '@cbtravel/common/lib/web/common/enumerations/input-type';
import {
  Box, Button,
  Grid, Table,
  TableBody, TableContainer, TableHead, TableRow, TextField,
  Tooltip, Typography
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from '@material-ui/core/InputBase';
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { RadioButtonUnchecked } from "@material-ui/icons";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForever from "@material-ui/icons/DeleteForever";
import FirstPage from "@material-ui/icons/FirstPage";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPage from "@material-ui/icons/LastPage";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import SearchIcon from '@material-ui/icons/Search';
import clsx from "clsx";
import React, { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import { UserContext } from '../../../components/shared/UserContext';
import ActionDialog from "../../../components/ui/Dialog/ActionDialog";
import Trashcan from '../../../icons/Trashcan';
import { formUtils } from "../../../util/form-utils";
import BulkList from "./BulkListDialog";
import CancelBtn from "./CancelBtn";
import SaveBtn from "./SaveBtn";
import SaveActivateDialog from "./SaveDialog";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles(() => ({
  rowHeader: {
    borderBottom: '1px solid #000000',
    marginTop: 5,
    paddingBottom: 10,
  },
  sectionTitle: {
    color: '#000000',
    fontSize: '16px',
    lineHeight: 1,
    fontWeight: 400,
  },
  fieldPreview: {
    minHeight: 300,
    padding: '14px 20px 40px 20px',
    backgroundColor: "#F9F9F9",
    borderRadius: 4,
    borderWidth: 1,
    border: "solid",
    borderColor: "rgba(201, 199, 199, 0.55)",
  },
  previewBox: {
    position: "relative",
    left: "26%",
    top: "20%",
  },
  toolTipPreview: {
    background: 'rgba(0, 0, 0, .85)',
    padding: 6,
    color: 'white',
    borderRadius: 4,
    fontSize: '11px',
    lineHeight: '14px',
  },
  toolTipPrieviewTip: {
    color: 'rgba(0, 0, 0, .85)',
    marginTop: '-2px',
    marginLeft: 15,
  },
  dropDownPreview: {
    backgroundColor: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
    borderRight: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    color: '#00467f',
    padding: '10px 0px',
  },
  selectFieldType: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
  },
  fieldType: {
    marginTop: 20,
    maxWidth: 200,
  },
  fab: {
    position: "absolute",
    bottom: "4%",
    right: "4%",
  },
  h2: {
    fontSize: 20,
  },
  tooltipLabel: {
    fontSize: 12,
    pointerEvents: "auto"
  },
  dialogContent: {
    lineHeight: 1.5,
  },
  fieldPreviewHelperText: {
    fontSize: '14px',
    color: '#808080',
    marginBottom: '50px',
  },
  fieldPreviewCleanFormText: {
    color: '#808080',
    padding: '0px 0px 20px',
    lineHeight: '24px',
  },
  flexRowEnd: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-between',
    "& .MuiIconButton-root:hover": {
      backgroundColor: 'transparent',
    },
  },
  flexRowTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  borderBottom: {
    borderBottom: '1px solid #ccc',
    marginBottom: '15px',
  },
  table: {
    "& a": {
      color: "#00467E",
      textDecoration: "none",
    },
    "& .MuiTableCell-head": {
      fontWeight: 700,
      lineHeight: 0,
    },
    "& .MuiTableCell-head.MuiTableCell-sizeSmall": {
      padding: "0 0px 0 8px",
      whiteSpace: "nowrap",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "8px",
      marginTop: "2px",
    },
    "& .moreInfoTable .MuiTableCell-sizeSmall": {
      padding: "8px",
    },
  },
  th: {
    "& .MuiIconButton-root": {
      padding: '0px',
    },
  },
  floatRight: {
    float: 'right',
  },
  tableTitle: {
    fontSize: '13px',
    color: '#808080',
  },
  listIteminput: {
    backgroundColor: 'rgba(201, 199, 199, .2)',
    padding: '1px 10px',
    "&.Mui-error": {
      border: "1px solid #bc2c2f",
      borderRadius: "4px",
    }
  },
  validation: {
    paddingTop: '5px',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  deleteAll: {
    fontSize: 13,
    marginBottom: 0,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  dialog: {
    "& .MuiDialog-paper": {
      textAlign: "center",
      width: 340,
    },
    "& .MuiDialogTitle-root": {
      padding: "16px 24px 0",
    },
    "& .MuiDialogContent-root": {
      padding: "0 24px",
      lineHeight: 1.5,
    },
    "& .MuiTypography-body1": {
      lineHeight: "unset",
      fontSize: "13px",
      color: "#4D4D4D",
    },
    "& .MuiTypography-h6": {
      fontWeight: 600,
    },
  },
  strong: {
    fontWeight: 600,
    lineHeight: 1.5,
  },
  iconAction: {
    margin: "25px auto 14px",
    background: "#808080",
    borderRadius: 40,
    width: "44px",
    height: "44px",
    "& .MuiSvgIcon-root": {
      color: "#fff",
      marginTop: "10px",
    },
  },
  dialogActions: {
    padding: "16px 32px",
    "& .MuiButton-contained:hover": {
      backgroundColor: "rgba(0,166,207, .8)",
    },
  },
  cancelFab: {
    position: 'relative',
    color: "#ffffff",
    backgroundColor: "#808080",
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 1%), 0px 1px 18px 0px rgb(0 0 0 / 11%)",
  },
  customFieldNumberDisabled: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: '#999999'
    }
  },
  checkBox: {
    marginBottom: '4px'
  },
  inactiveBadge: {
    borderRadius: 4, background: 'rgba(201, 199, 199, .2)', padding: '2px 6px', marginTop: 3, height: 'auto', textAlign: 'center', display: 'inline-flex',
    '& .MuiTypography-body1': {
      color: '#4d4d4d', fontSize: 11, textTransform: 'uppercase',
    }
  },
  activeBadge: {
    borderRadius: 4, background: '#ECF1E6', padding: '2px 6px', marginTop: 3, height: 'auto', textAlign: 'center', display: 'inline-flex',
    '& .MuiTypography-body1': {
      color: '#417505', fontSize: 11, textTransform: 'uppercase',
    }
  },
  lockIcon: {
    width: 16,
    color: '#999999',
  },
  unlockedIcon: {
    width: 16,
    color: '#808080',
  },
  iconDropDown: {
    marginRight: 12,
  },
  red: {
    color: '#bc2c2f',
  },
  additionalFields: {
    marginTop: 20,
  },
}));

export type listValueFields = {
  customFieldId: number,
  customFieldValueId: number,
  name: string,
  value: string,
  markedForDeletion: boolean,
  defaultListItem: boolean,
}

export type CustomFieldFormInputs = {
  fieldType: FieldTypeEnum,
  fieldName: string,
  CustomField: string, // inputs that aren't number boxes will always be strings
  tooltip: string,
  generalNotes: string,
  activeStatus: boolean,
  fieldPermissions: CustomFieldAccessibility,

  //optional
  requiredFor?: string,
  example?: string,
  fieldValue?: string,
  minCharLength: number | null,
  maxCharLength: number | null,
  customFieldValueList: Array<listValueFields>,
  defaultValue: string,
}

enum FieldTypeEnum {
  Freeform = "Freeform",
  DropdownList = "Dropdown list"
}

interface DeleteAllDialogProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  positiveAction: () => void,
}

/**
 * Component for the delete all confirmation dialog on the custom field form.
 * 
 * @param props {@link DeleteAllDialogProps Properties} for the `DeleteAllDialog` component.
 * @returns A JSX element for displaying the delete all dialog.
 */
function DeleteAllDialog(props: DeleteAllDialogProps) {
  const classes = useStyles();

  return (
    <ActionDialog
      title="Delete all?"
      icon={<DeleteForever />}
      positiveText="Delete all"
      negativeText="Cancel"
      open={props.open}
      setOpen={props.setOpen}
      positiveAction={props.positiveAction}
      dialogType="delete"
    >
      <p className={classes.dialogContent}>
        This will remove all list item values.</p>
    </ActionDialog>
  );
}

interface CustomFieldFormProps {
  /** Object representing the custom field being edited. */
  curCustomField: CustomFieldRS | undefined,
  /** The source of the custom field. */
  customFieldSource: CustomFieldSource,
  /** Function to execute when sending custom field to the API. */
  results: (customFieldRS: CustomFieldRS, isUpdate: boolean, fieldValuesToDelete: number[]) => void,
  /** Async function to execute when leaving the form. */
  cancel: () => Promise<void>,
  /** Array of custom field ID numbers that are in use. */
  customFieldsNotInUse: number[],
  /** React state setter for showing the spinner. */
  setShowSpinner: React.Dispatch<SetStateAction<boolean>>
  /** The value's location of the custom fields array  */
  index: number,
}

/**
 * Component for editing the details of a custom field.
 * 
 * @param props {@link CustomFieldFormProps Properties} for the `CustomFieldForm` component.
 * @returns 
 */
export default function CustomFieldForm(props: CustomFieldFormProps) {
  const classes = useStyles();
  const { index } = props;
  const { userContext } = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [isEditForm, setIsEditForm] = useState<boolean>(props.curCustomField !== undefined); //if undefined we are making a new form.
  const [showNavSaveDialog, setShowNavSaveDialog] = useState<boolean>(false);
  const [navigateOnSave, setNavigateOnSave] = useState<string>("");
  const previewRef = useRef<HTMLDivElement>(null);
  const [fieldValuesToDelete, setFieldValuesToDelete] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchPage, setSearchPage] = useState<number>(1);
  const [openReset, setOpenReset] = useState<boolean>(false);
  const maxPageSize = 25;

  const isFirstRun = useRef(true); // determine if it's first landing of the page 
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const snackbar = useCustomSnackbar();

  /**
   * Used to filter the values in the backing field array to get an array that only includes matches
   * for the text in the search box.
   * 
   * @returns An array of custom field values that matches the text in the search box.
   */
  function searchFilterList() {
    return controlFieldValueArray.filter((v) => v.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      v.value.toLowerCase().includes(searchValue.toLowerCase()));
  }

  /**
   * Used to get text which represents the current pagination state.
   * 
   * @returns A string that represents the current pagination state.
   */
  function paginationText() {
    // Needs to match the search filter so we have an accurate total count
    let list = searchFilterList();
    return `${maxPageSize * (searchPage - 1) + 1}-${Math.min(list.length, maxPageSize * searchPage)}`;
  }

  // RHF
  const { handleSubmit, formState: { errors, isDirty, dirtyFields }, control, reset, setValue, getValues, clearErrors, setError, trigger, watch, }
    = useForm<CustomFieldFormInputs>(props.curCustomField !== undefined ?
      {
        mode: "onBlur",
        defaultValues: {
          fieldType: props.curCustomField.inputType === InputType.TextBox ? FieldTypeEnum.Freeform : FieldTypeEnum.DropdownList,
          fieldName: props.curCustomField.name,
          CustomField: props.curCustomField.number.toString(),
          fieldValue: props.curCustomField.inputDataType,
          minCharLength: props.curCustomField.inputMinLength < 0 ? null : props.curCustomField.inputMinLength,
          maxCharLength: props.curCustomField.inputMaxLength < 0 ? null : props.curCustomField.inputMaxLength,
          example: props.curCustomField.example,
          tooltip: props.curCustomField.noteExternal,
          generalNotes: props.curCustomField.noteInternal,
          requiredFor: "",
          activeStatus: props.curCustomField.activeStatus,
          defaultValue: props.curCustomField.defaultValue,
          customFieldValueList: props.curCustomField.customFieldValueList.map(v => (
            {
              customFieldId: v.customFieldId,
              customFieldValueId: v.customFieldValueId,
              name: v.name,
              value: v.value,
              markedForDeletion: false,
              defaultListItem: v.value === props.curCustomField?.defaultValue ? true : false,
            }
          )),
          fieldPermissions: props.curCustomField.accessibility
        }
      }
      :
      {
        mode: "onBlur",
        defaultValues: {
          fieldType: FieldTypeEnum.Freeform,
          fieldName: "",
          CustomField: "",
          fieldValue: InputDataType.NotApplicable,
          minCharLength: null,
          maxCharLength: null,
          example: "",
          tooltip: "",
          generalNotes: "",
          requiredFor: "",
          activeStatus: false,
          defaultValue: "",
          customFieldValueList: [{
            customFieldId: -1,
            customFieldValueId: -1,
            name: "",
            value: "",
            markedForDeletion: true,
            defaultListItem: false,
          }],
          fieldPermissions: CustomFieldAccessibility.Open
        }
      });

  /**
   * Maps FormInputs to CustomFieldRS to be sent 
   * 
   * @param inputs The values contained in the react hook form object.
   * @returns A {@link CustomFieldRS} populated with data from the form.
   */
  function FormInputsToCustomFieldRS(inputs: CustomFieldFormInputs): CustomFieldRS {
    let customFields
    if (props.curCustomField !== undefined) {
      customFields = props.curCustomField;
    } else {
      customFields = new CustomFieldRS();
    }

    customFields.noteExternal = inputs.tooltip;
    customFields.noteInternal = inputs.generalNotes;
    customFields.inputDataType = inputs.fieldValue as InputDataType;

    if (inputs.fieldType) {
      if (inputs.fieldType === FieldTypeEnum.Freeform) {
        customFields.inputType = InputType.TextBox;

      } else if (inputs.fieldType === FieldTypeEnum.DropdownList) {
        customFields.inputType = InputType.List;
      }
    }
    if (inputs.fieldName) {
      customFields.name = inputs.fieldName;
    }
    if (inputs.CustomField) {
      customFields.number = Number(inputs.CustomField);
    }

    if (inputs.example) {
      customFields.example = inputs.example;
    } else {
      customFields.example = "";

    }
    if (!inputs.minCharLength) {
      customFields.inputMinLength = -1;
    } else {
      let min = Number(inputs.minCharLength);
      customFields.inputMinLength = min < 0 ? -1 : min;
    }

    if (!inputs.maxCharLength) {
      customFields.inputMaxLength = -1;
    } else {
      let max = Number(inputs.maxCharLength);
      customFields.inputMaxLength = max < 0 ? -1 : max;
    }
    customFields.activeStatus = inputs.activeStatus;
    customFields.isRequired = true;
    customFields.customFieldSource = props.customFieldSource;
    customFields.accessibility = inputs.fieldPermissions;


    //dropdown list specifics.
    if (inputs.fieldType === FieldTypeEnum.DropdownList) {
      customFields.inputMinLength = -1;
      customFields.inputMaxLength = -1;
      customFields.example = "";
      customFields.inputDataType = InputDataType.NotApplicable;

      customFields.customFieldValueList = inputs.customFieldValueList.map(v => (
        {
          customFieldId: v.customFieldId,
          customFieldValueId: v.customFieldValueId,
          name: v.name,
          value: v.value,
          defaultListItem: v.defaultListItem,
          number: Number(inputs.CustomField)
        }
      ));
    }

    // set the default value to an empty string in case there are no selected default values
    customFields.defaultValue = "";
    // if custom fields defaultListItem is true then the defaultValue is equal to that list item's value
    for (let i = 0; i < inputs.customFieldValueList.length; i++) {
      if (inputs.customFieldValueList[i].defaultListItem) {
        customFields.defaultValue = inputs.customFieldValueList[i].value
      }
    }



    return customFields;
  }

  const formRules = {
    fieldType: { required: "Please select a field type" },
    fieldName: { required: "Please enter a field name" },
    CustomField: {
      required: "Please enter a valid UDID #"
    },
    fieldValue: {
      required: false,
      validate: {
        setMinMax: (value: string | undefined) => {
          if (value === InputDataType.Numeric.toString()) {
            setValue("minCharLength", null)
            setValue("maxCharLength", null)
            return true;
          }
        }
      }
    },
    minCharLength: {
      required: false,
      validate: {
        tooSmall: (value: number | null) => {
          if (watchMax && value && Number(watchMax) < Number(value)) {
            return "Max characters cannot be smaller than min.";
          } else {
            if (errors.minCharLength && errors.maxCharLength) { //if both have an error but we fixed one, fix both 
              clearErrors('minCharLength');
              trigger('maxCharLength')
            }
            return true;
          }
        }
      },
      pattern: { value: RegexUtils.NUMBERS_ONLY, message: 'Please use numbers only' }
    },
    maxCharLength: {
      required: false,
      validate: {
        tooSmall: (value: number | null) => {
          if (watchMin && value && Number(watchMin) > Number(value)) {
            return "Min characters cannot be larger than max.";
          }
          else if (value && value <= 0) {
            return "Max character count must be greater than 0"
          }
          else {
            if (errors.minCharLength && errors.maxCharLength) { //if both have an error but we fixed one, fix both 
              clearErrors('maxCharLength')
              trigger('minCharLength')
            }
            return true;
          }
        }
      },
      pattern: { value: RegexUtils.NUMBERS_ONLY, message: 'Please use numbers only' }
    },
    example: { required: false },
    tooltip: { required: false },
    generalNotes: { required: false },
    requiredFor: { required: false },
    cfName: { required: "Please enter a list item name" },
    cfValue: { required: "Please enter a value for this item" },
    fieldPermissions: {
      required: "Please set permissions for data entry",
      validate: {
        checkDefaultValue: (value: CustomFieldAccessibility) => {
          let form = getValues();
          if (value !== CustomFieldAccessibility.Open) {
            if (!form.customFieldValueList.find(val => val.defaultListItem)) {
              setError(`defaultValue`, {
                type: 'required',
                message: `Default value is required for ${value} custom fields`
              })
            }
          } else {

            clearErrors('defaultValue');
            return true;
          }
        }
      }
    },
    customFieldValueList: {
      validate: {
        requiredDefaultValue: (value: boolean) => {
          let form = getValues();
          // if the field permission isn't open and we don't have a default value
          if (form.fieldPermissions !== CustomFieldAccessibility.Open && !form.customFieldValueList.find(val => val.defaultListItem)) {
            setError('defaultValue', {
              type: 'required',
              message: `Default value is required for ${form.fieldPermissions} custom fields`
            })
          } else {

            // clear any errors that might exist on the default value
            clearErrors('defaultValue');
            return true;
          }
        }
      }
    }
  }


  /**
   * Called by the save dialog. Collects the field values as a ProfileRS
   * and submits it to the Profile API for updating.
   * 
   * @param data The form data to submit.
   */
  async function onSubmit(data: CustomFieldFormInputs) {
    props.setShowSpinner(true);
    let profileRs: CustomFieldRS = FormInputsToCustomFieldRS(data);
    props.results(profileRs, isEditForm, fieldValuesToDelete);

    // In the onSubmit, you'll need to populate customFields.defaultValue (the object we send to the backend) with the value of the custom-field-list-value that is set to true. You'll probably have to write a for loop or use Array.filter or similar to find it. I can explain both these in better detail if you need me to, as well

    // Reset form state with current values so no longer considered dirty
    reset(data);

    // remove "#form" from url 
    routerHistory.push("reportingfields");

    return false;
  }

  /**
   * Used as the second parameter of handleSubmit. Called when the first
   * parameter throws an error.
   * 
   * @param e The exception to catch.
   */
  function catchSubmitError(e: JsonException) {
    snackbar.error(e);
    setNavigateOnSave("");
  }

  /**
   * Called when handleSubmit is called and the form state has validation errors.
   */
  function onSubmitWithDirtyForm(err: any) {
    throw {
      message: "Unable to submit while the form has errors:",
      exceptionDetailList: Object.entries(err).map((e: any) => {
        return { message: e[1].message, key: e[1].type, property: e[0] }
      }),
      exceptionLevel: ExceptionLevel.Critical,
      uniqueID: ""
    } as JsonException;
  }
  /**
 * Reset the list value when changing filed type from drop-down to freeform
 */
  async function restListValue() {
    fieldValueArray.remove()
    setOpenReset(false)
  }

  /**
   * Saves the router location the user tried to navigate to, and throws a save dialog before actually allowing the navigation
   * 
   * @param location the `Route` clicked on when the form was dirty
   * @returns True if naviagation navigation continues, false if stops.
   */
  function handleBlockedNavigation(location: typeof routerLocation) {

    if (navigateOnSave === "") {
      setNavigateOnSave(location.pathname)
      setShowNavSaveDialog(true);
      return false;
    } else if (navigateOnSave === "/companyadmin/reportingfields") {
      props.cancel();
    }

    return true;
  }

  //// Watch FieldType fieldName
  const watchMin = watch("minCharLength");
  const watchMax = watch("maxCharLength");
  const watchFieldType = watch("fieldType");
  const watchFieldName = watch("fieldName");
  const watchFieldValue = watch("fieldValue");
  const watchTooltip = watch("tooltip");
  const watchExample = watch("example");
  const watchCustomField = watch("CustomField");

  // set up field array for valueItems information
  const fieldValueArray = useFieldArray({ control: control, name: "customFieldValueList" });
  const watchFieldValueArray = useWatch({ control: control, name: "customFieldValueList" });
  let controlFieldValueArray = fieldValueArray.fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldValueArray[index],
      originalIndex: index // Save index to maintain data integrity after filtering with search
    };
  });

  // onMount; adding a hash to the path makes it easier to handle the back button on this page
  useEffect(() => {
    routerHistory.push("/companyadmin/reportingfields#form")

    // If we do have a default value on the current custom field then we want to append it to the field array.
    if (fieldValueArray.fields.filter(v => v.defaultListItem === true).length > 0) {
      return;
    }
    if (props.curCustomField?.defaultValue) {
      fieldValueArray.append({
        customFieldId: props.curCustomField ? props.curCustomField.customFieldId : -1,
        customFieldValueId: -1,
        name: "Default Value",
        value: props.curCustomField?.defaultValue,
        markedForDeletion: false,
        defaultListItem: true,
      });
    }
    else if (watchFieldValueArray.length === 0) {
      // Otherwise initialize the field array with an empty custom field list value.
      handleAddValue();
    }
  }, [])

  useEffect(() => {
    // Reset search page when text changes
    setSearchPage(1);
  }, [searchValue]);

  useEffect(() => {
    if (watchFieldValueArray.length === 0) { //if we have no fieldValues, add one.
      handleAddValue();
    }
  }, [watchFieldValueArray])

  // If fieldName is selected from dropdown.
  useEffect(() => {
    //if we don't have a udid in the form currently, break.
    if (watchCustomField === undefined) {
      return;
    }
  }, [watchFieldName]);

  // handles the back button if the form is clean
  useEffect(() => {
    if (routerHistory.action === "POP") {
      routerHistory.push("/companyadmin/reportingfields")
      props.cancel()
    }
  }, [routerLocation])

  // handles the Field Type changes
  useEffect(() => {
    // if it's first render of the page not displaying the confirm dialog
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    setOpenReset(true)
  }, [watchFieldType]);

  /** Open cancel modal if cancel button is clicked. */
  async function handleCancel() {
    // await useStates being used in cancel 
    await props.cancel();

    // remove "#form" from url
    routerHistory.push("reportingfields");
  }

  /** Open save modal if save button is clicked. */
  function handleSave(activeStatus: boolean) {
    setValue("activeStatus", activeStatus);
    handleSubmit(onSubmit)();
  }

  /** Handles adding new values to the value list. */
  function handleAddValue() {
    fieldValueArray.append({
      customFieldId: props.curCustomField ? props.curCustomField.customFieldId : -1,
      customFieldValueId: -1,
      name: "",
      value: "",
      markedForDeletion: false,
      defaultListItem: false,
    });

    // Go to second page if new field may not be visible
    if (fieldValueArray.fields.length >= maxPageSize) {
      setSearchPage(2);
    }
  }
  /** 
   * When selecting the default list item checkbox for the dropdown list, we need to make sure that any other 
   * checkboxes are unchecked.
   * 
   * @param checked Represents whether this checkbox is marked as defaultValue or not.
   * @param onChange React hook form onChange function supplied by the Controller component.
   */
  function handleMakeDefault(checked: boolean, onChange: (...event: any[]) => void) {
    // If marking this list item as the default, we need to make sure all others are unchecked
    if (checked) {
      controlFieldValueArray.forEach((value, i) => { //  for each of these checkboxes
        if (i !== index && value.defaultListItem) { // that are not the default check box
          fieldValueArray.update(i, { defaultListItem: false }); // make them unchecked
        }
      });
    }

    // Apply change to this list item
    onChange(checked);

  }


  /**
   * deletes all custom field values that have markedForDeletion checkboxes checked
   */
  function deleteSelectedValues() {
    const indicesToRemove: number[] = []

    // populate array to hold indices so rhf can remove them
    controlFieldValueArray.forEach((f, idx) => {
      if (f.markedForDeletion === true) {
        indicesToRemove.push(idx)
      }
    });

    fieldValueArray.remove(indicesToRemove);

    // Go to first page if second page is now empty
    if (fieldValueArray.fields.length - 1 <= maxPageSize) {
      setSearchPage(1);
    }

  }

  /**
   * Deletes all custom field dropdown values and returns to the first page of pagination.
   */
  async function deleteAllValues() {
    setValue("customFieldValueList", [{
      customFieldId: props.curCustomField?.customFieldId || -1,
      customFieldValueId: -1,
      name: "",
      value: "",
      markedForDeletion: false,
      defaultListItem: false
    }]);
    setSearchPage(1);
  }

  /**
   * checks the rhf formState isDirty and dirtyFields objects and determines if we should display explanatory text in the field preview box, or the live preview logic
   * @returns true if we should display the explanatory text; false if we should display the live preview
   */
  function checkDisplayCleanFormText() {
    // note: rhf will remove/exclude a field from its formState.dirtyFields object, if the field's current value === its default value defined in useForm.defaultValues
    // isDirty will continue to register as true after a field has been changed, even if it is ultimately reset (by the user) back to its default

    // form's not dirty, definitely show the text
    if (!isDirty && !isEditForm) {
      return true;
    }

    // an array is much easier to work with 
    const dirtyList = Object.keys(dirtyFields);

    // we have zero dirty fields or exactly one dirty field and that is fieldType (which we want to ignore in this specific context); 
    // for new custom fields, this means we should display the text
    if (!isEditForm && (dirtyList.length === 0 || dirtyList.length === 1 && dirtyList.find(f => f === "fieldType"))) {
      return true;
    }

    // anything besides the above cases means the form is genuinely dirty, and we want to show the live preview logic
    return false;
  }

  /**
   * uses the user type, config and udid number to determine whether the user has permission to edit a field and set the disabled prop of the text field
   * @param userType the current user's user type
   * @param customFieldNumber the number of the current custom field being edited
   * @param isFieldEditPermissioned whether edit access is limited based on permission type of the current custom field
   * @returns whether the user has access to the field
   */
  function checkFormEditPermissions(userType: UserType, customFieldNumber: number | null, isFieldEditPermissioned: boolean = true): boolean {
    if (!customFieldNumber) return true;

    const customFieldNumbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20];
    const configPermissions: { number: number, fieldCreation: string }[] = Configuration.UdidConfiguration;
    let configField = configPermissions.find(f => f.number === customFieldNumber);
    let isOpen = customFieldNumbers.includes(customFieldNumber);

    switch (userType) {
      case UserType.Administrator: // administrator should be able to edit all fields
        return true;
      case UserType.AccountManager:
      case UserType.TravelManager:
        if (isOpen) return true;
        if (configField && configField.fieldCreation === CustomFieldAccessibility.Internal) return false;
        if (!isFieldEditPermissioned) return true;
        break;
      case UserType.SalesManager: // sales advisor should only have view access to all fields
      case UserType.Advisor: // traveler and advisor should not be able to view the page
      case UserType.Traveler:
        break;
    }

    return false;
  }

  /**
   * checks the user's type and returns the available permission options for that user type
   * @param userType current user's user type
   * @returns the field permissions available to that user
   */
  function populateFieldPermissions(userType: UserType) {
    if (userType === UserType.AccountManager || userType === UserType.Administrator || userType === UserType.SalesManager) {
      return [
        <MenuItem value={CustomFieldAccessibility.Open}>Everyone can view and edit</MenuItem>,
        <MenuItem value={CustomFieldAccessibility.Permissioned}>Everyone can view, editable by travel manager and above</MenuItem>,
        <MenuItem value={CustomFieldAccessibility.Hidden}>Travel manager and above can view and edit</MenuItem>,
        <MenuItem value={CustomFieldAccessibility.Internal}>Internal Only</MenuItem>
      ];
    }
    else
      return [
        <MenuItem value={CustomFieldAccessibility.Open}>Everyone can view and edit</MenuItem>,
        <MenuItem value={CustomFieldAccessibility.Permissioned}>Everyone can view, editable by travel manager and above</MenuItem>
      ];
  }
  function isAdmin(userType: UserType) {
    if (userType === UserType.Administrator) {
      return true;
    }
    return false;
  }
  /**
   * Displays Edit profile field and active or inactive badge
   */

  const editStatus =
    (
      <Box className={classes.flexRow}>
        <Box style={{ paddingRight: 10 }}>
          <Typography>Edit profile field</Typography>
        </Box>

        {getValues("activeStatus") && <Box className={classes.activeBadge}>
          <Typography component="span">Active</Typography>
        </Box>}

        {!getValues("activeStatus") && <Box className={classes.inactiveBadge}>
          <Typography component="span">Inactive</Typography>
        </Box>}
      </Box>
    );

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box justifyContent={'space-between'} alignItems={'flex-end'}
                  className={clsx(classes.flexRow, classes.rowHeader)}
                >
                  <Box style={{ marginBottom: 4, }}>
                    <Typography variant="h2" className={classes.sectionTitle}>
                      {/* {props.customFieldSource === CustomFieldSource.Profile ?
                      isEditForm ? editStatus : "Add profile field"
                      :
                      props.customFieldSource === CustomFieldSource.Booking ?
                        isEditForm ? "Edit trip field" : "Add trip field"
                        :
                        isEditForm ? "Edit system field" : "Add system field"
                      // System Udids are a third type we expect to add some day. 
                      // I'm not actually sure if they'll be a part of this page or not, but we may as well plan ahead
                    } */}
                      Required Fields
                    </Typography>
                  </Box>
                  {!getValues("activeStatus") && !isAdmin(userContext.userType) ? <Box flexDirection='row' display='flex' alignItems='center'>
                    <LockOpenIcon className={classes.lockIcon} />
                    <Typography style={{ marginLeft: 2, }} variant='body2' color='error'>These values cannot be changed after activated</Typography>
                  </Box> :
                    <Box flexDirection='row' display='flex' alignItems='center'>
                      {/* <LockOpenIcon className={classes.lockIcon} style={{ color: '#4D4D4D'}} />
                      <Typography style={{ marginLeft: 2, color: '#4D4D4D'}} variant='body2'>Unlocked</Typography> */}
                    </Box>
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box marginTop={-1}>
                <Controller
                  name="fieldName"
                  control={control}
                  rules={formRules.fieldName}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={errors.fieldName ? true : false}
                      id="ff-field-name"
                      className={classes.customFieldNumberDisabled}
                      // autoHighlight
                      fullWidth
                      size="small"
                      onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                      onChange={(change) => onChange(change)}
                      value={value}
                      ref={ref}
                      placeholder="Select or create field name"
                      disabled={!isAdmin(userContext.userType) && getValues("activeStatus") || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number)}
                      label={
                        <Box mr={-0.5}>
                          Field name {" "}
                          {/* <Tooltip arrow
                                title="Select or create field name"
                                placement="top"
                              >
                                <InfoOutlinedIcon
                                  className={classes.tooltipLabel}
                                />
                              </Tooltip> */}
                        </Box>
                      }
                      InputProps={!isAdmin(userContext.userType) && getValues("activeStatus") || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number) ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutlinedIcon className={classes.lockIcon} />
                          </InputAdornment>
                        )
                      } : {
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOpenIcon className={classes.unlockedIcon} />
                          </InputAdornment>
                        )
                      }}

                      variant="outlined"
                    />
                  )} />

              </Box>
              <Typography className={classes.validation} variant='body2' color='error'>
                {errors.fieldName && errors.fieldName.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name="CustomField"
                control={control}
                rules={formRules.CustomField}
                render={({ field: { onChange, value, ref, onBlur } }) => (
                  <TextField
                    id="ff-udid-number"
                    className={classes.customFieldNumberDisabled}
                    disabled={getValues("activeStatus") && !isAdmin(userContext.userType) || !checkFormEditPermissions(userContext.userType, Number(value))}
                    select
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    label={
                      <Box mr={-0.5}>
                        Field #{" "}
                        <Tooltip arrow
                          // title="UDID will be autopopulated with the next available number"
                          title="The numbers assigned to the field name"
                          placement="top"
                        >
                          <InfoOutlinedIcon className={classes.tooltipLabel} />
                        </Tooltip>
                      </Box>
                    }
                    InputProps={getValues("activeStatus") && !isAdmin(userContext.userType) ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutlinedIcon className={clsx(classes.lockIcon, classes.iconDropDown)} />
                        </InputAdornment>
                      )
                    } : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOpenIcon className={clsx(classes.unlockedIcon, classes.iconDropDown)} />
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={errors.CustomField ? true : false}
                  >
                    {props.customFieldsNotInUse.map(customFieldNum => (
                      <MenuItem key={customFieldNum} value={customFieldNum}>{customFieldNum}</MenuItem>
                    ))}
                  </TextField>
                )} />
              <Typography className={classes.validation} variant='body2' color='error'>
                {errors.CustomField && errors.CustomField.message}
              </Typography>

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name="fieldType"
                control={control}
                rules={formRules.fieldType}
                render={({ field: { onChange, value, ref, onBlur } }) => (
                  <TextField
                    id="ff-field-type"
                    select
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    className={classes.customFieldNumberDisabled}
                    disabled={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number)}
                    ref={ref}
                    label={
                      <Box mr={-0.5}>
                        Field type{" "}
                        <Tooltip arrow title="Dropdown list: Series of values in words or numerical format (i.e. list of countries). Freeform: Open-ended response that is unstructured (i.e. favorite movie)" placement="top">
                          <InfoOutlinedIcon className={classes.tooltipLabel} />
                        </Tooltip>
                      </Box>
                    }
                    InputProps={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number) ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutlinedIcon className={clsx(classes.lockIcon, classes.iconDropDown)} />
                        </InputAdornment>
                      )
                    } : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOpenIcon className={clsx(classes.unlockedIcon, classes.iconDropDown)} />
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    defaultValue="Freeform"
                    fullWidth
                    size="small"
                  >
                    <MenuItem value={FieldTypeEnum.Freeform}>Freeform</MenuItem>
                    <MenuItem value={FieldTypeEnum.DropdownList}>Drop-down list</MenuItem>
                  </TextField>
                )} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name="fieldPermissions"
                control={control}
                rules={formRules.fieldPermissions}
                render={({ field: { onChange, value, ref, onBlur } }) => (
                  <TextField
                    id="ff-field-permissions"
                    select
                    label={
                      <Box mr={-0.5}>
                        Field Permissions{" "}
                        <Tooltip arrow title="Define which users can view and/or edit" placement="top">
                          <InfoOutlinedIcon className={classes.tooltipLabel} />
                        </Tooltip>
                      </Box>}
                    variant="outlined"
                    defaultValue="Freeform"
                    className={classes.customFieldNumberDisabled}
                    disabled={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number) || getValues("fieldType") === FieldTypeEnum.Freeform}
                    fullWidth
                    size="small"
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    ref={ref}
                    InputProps={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number) || getValues("fieldType") === FieldTypeEnum.Freeform ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutlinedIcon className={clsx(classes.lockIcon, classes.iconDropDown)} />
                        </InputAdornment>
                      )
                    } : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOpenIcon className={clsx(classes.lockIcon, classes.iconDropDown)} />
                        </InputAdornment>
                      )
                    }}
                  >
                    {populateFieldPermissions(userContext.userType)}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box marginBottom={4}
              className={clsx(classes.flexRow, classes.rowHeader, classes.additionalFields)}
            >
              <Typography variant="h2" className={classes.sectionTitle}>
                Additional Fields
              </Typography>
            </Box>
          </Grid>

          <Box mb={2}>
            {watchFieldType !== FieldTypeEnum.DropdownList ?
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Controller
                    name="fieldValue"
                    control={control}
                    rules={formRules.fieldValue}
                    render={({ field: { onChange, value, ref, onBlur } }) => (
                      <TextField
                        id="ff-field-value"
                        select
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        className={classes.customFieldNumberDisabled}
                        disabled={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number)}
                        error={errors.fieldValue ? true : false}
                        label={
                          <Box mr={-0.5}>
                            Field value{" "}
                            <Tooltip arrow
                              title="Specify constraints for the input value "
                              placement="top"
                            >
                              <InfoOutlinedIcon className={classes.tooltipLabel} />
                            </Tooltip>
                          </Box>
                        }
                        InputProps={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number) ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutlinedIcon className={clsx(classes.lockIcon, classes.iconDropDown)} />
                            </InputAdornment>
                          )
                        } : {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOpenIcon className={clsx(classes.lockIcon, classes.iconDropDown)} />
                            </InputAdornment>
                          )
                        }}
                        variant="outlined"
                        placeholder="Any entry"
                        fullWidth
                        size="small"
                      >
                        <MenuItem value={InputDataType.NotApplicable}>Any</MenuItem>
                        <MenuItem value={InputDataType.Alpha}>Letters Only</MenuItem>
                        <MenuItem value={InputDataType.Numeric}>Numbers Only</MenuItem>
                        <MenuItem value={InputDataType.AlphaNumeric}>Letters and numbers</MenuItem>
                        <MenuItem value={InputDataType.Currency}>Currency</MenuItem>
                      </TextField>
                    )} />
                  <Typography variant='body2' color='error'>
                    {errors.fieldValue && errors.fieldValue.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Controller
                    name="minCharLength"
                    control={control}
                    rules={formRules.minCharLength}
                    render={({ field: { onChange, value, ref, onBlur } }) => (
                      <TextField
                        id="ff-min-character-length"
                        className={classes.customFieldNumberDisabled}
                        disabled={(getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)) || (watchFieldValue === InputDataType.Numeric)}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        error={errors.minCharLength ? true : false}
                        label={
                          <Box mr={-0.5}>
                            Min. Character Count {" "}
                            <Tooltip arrow
                              title="The minimum characters specified for the value"
                              placement="top"
                            >
                              <InfoOutlinedIcon className={classes.tooltipLabel} />
                            </Tooltip>
                          </Box>
                        }
                        InputProps={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false) ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutlinedIcon className={classes.lockIcon} />
                            </InputAdornment>
                          )
                        } : {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOpenIcon className={classes.lockIcon} />
                            </InputAdornment>
                          )
                        }}
                        helperText=""
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )} />
                  <Typography className={classes.validation} variant='body2' color='error'>
                    {errors.minCharLength && errors.minCharLength.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Controller
                    name="maxCharLength"
                    control={control}
                    rules={formRules.maxCharLength}
                    render={({ field: { onChange, value, ref, onBlur } }) => (
                      <TextField
                        id="ff-max-character-length"
                        className={classes.customFieldNumberDisabled}
                        disabled={(getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)) || watchFieldValue === InputDataType.Numeric}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        error={errors.maxCharLength ? true : false}
                        label={
                          <Box mr={-0.5}>
                            Max. Character Count {" "}
                            <Tooltip arrow
                              title="The maximum characters specified for the value "
                              placement="top"
                            >
                              <InfoOutlinedIcon className={classes.tooltipLabel} />
                            </Tooltip>
                          </Box>
                        }
                        InputProps={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false) ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutlinedIcon className={classes.lockIcon} />
                            </InputAdornment>
                          )
                        } : {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOpenIcon className={classes.lockIcon} />
                            </InputAdornment>
                          )
                        }}
                        helperText=""
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )} />
                  <Typography className={classes.validation} variant='body2' color='error'>
                    {errors.maxCharLength && errors.maxCharLength.message}
                  </Typography>
                </Grid>
              </Grid>
              : ""}
          </Box>

          <Box mb={2}>
            {watchFieldType !== FieldTypeEnum.DropdownList ?
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Controller
                    name="example"
                    control={control}
                    rules={formRules.example}
                    render={({ field: { onChange, value, ref, onBlur } }) => (
                      <TextField
                        id="ff-placeholder-example"
                        onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                        onChange={onChange}
                        value={value}
                        className={classes.customFieldNumberDisabled}
                        disabled={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)}
                        ref={ref}
                        label={
                          <Box mr={-0.5}>
                            Example{" "}
                            <Tooltip arrow
                              title="Examples of what this field value could be to help the user (if Field value is ‘numerical’, then only numbers are accepted; i.e. 8015554567)"
                              placement="top"
                            >
                              <InfoOutlinedIcon className={classes.tooltipLabel} />
                            </Tooltip>
                          </Box>
                        }
                        InputProps={getValues("activeStatus") && !isAdmin(userContext.userType) || props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false) ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutlinedIcon className={classes.lockIcon} />
                            </InputAdornment>
                          )
                        } : {
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOpenIcon className={classes.lockIcon} />
                            </InputAdornment>
                          )
                        }}
                        placeholder="Enter example field value"
                        helperText=""
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )} />
                </Grid>
              </Grid>
              : ""}
          </Box>

          <Box mb={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Controller
                  name="tooltip"
                  control={control}
                  rules={formRules.tooltip}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <TextField
                      id="ff-tooltip-notes"
                      onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                      onChange={onChange}
                      value={value}
                      disabled={props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)}
                      ref={ref}
                      label={
                        <Box mr={-0.5}>
                          Tooltip notes{" "}
                          <Tooltip arrow
                            title="The informative message or instructions that appear when a mouse hovers over the (i) icon"
                            placement="top"
                          >
                            <InfoOutlinedIcon className={classes.tooltipLabel} />
                          </Tooltip>
                        </Box>
                      }
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  )} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Controller
                  name="generalNotes"
                  control={control}
                  rules={formRules.generalNotes}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <TextField
                      id="ff-general-notes"
                      onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                      onChange={onChange}
                      disabled={props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)}
                      value={value}
                      ref={ref}
                      label={
                        <Box mr={-0.5}>
                          Internal notes{" "}
                          <Tooltip arrow
                            title="Any background information for the specified field and its values."
                            placement="top"
                          >
                            <InfoOutlinedIcon className={classes.tooltipLabel} />
                          </Tooltip>
                        </Box>
                      }
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  )} />
              </Grid>

            </Grid>
          </Box>

          <Box mb={2}>
            {watchFieldType == FieldTypeEnum.DropdownList ?
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box marginBottom={4}
                    className={clsx(classes.flexRow, classes.rowHeader)}
                  >
                    <Typography variant="h2" className={classes.sectionTitle}>
                      Create list
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <TextField
                      id="ff-search-value"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={searchValue}
                      onBlur={e => setSearchValue(e.target.value.trim())}
                      onChange={e => setSearchValue(e.target.value)}
                      disabled={getValues("activeStatus")}
                      label={
                        <Box mr={-0.5}>
                          Search and filter list{" "}
                          <Tooltip arrow
                            title="Search for a specific list item"
                            placement="top"
                          >
                            <InfoOutlinedIcon className={classes.tooltipLabel} />
                          </Tooltip>
                        </Box>
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {(getValues("activeStatus") || searchValue) &&
                              <IconButton size="small" onClick={() => setSearchValue("")}>
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            }
                            <IconButton disableRipple={true} color="default" aria-label="search" style={{ paddingRight: '0px', paddingLeft: '0px', backgroundColor: 'transparent', }} >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              : ""}
          </Box>
          {watchFieldType === FieldTypeEnum.DropdownList &&
            <>
              {/* check for a default list item if field is not open */}
              <Box>
                <Typography className={classes.red} style={{ fontSize: 12 }}>
                  {errors.defaultValue && errors.defaultValue.message}
                </Typography>
              </Box>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="list items"
                  size="small"
                  id="table"
                >
                  <TableHead className={clsx(classes.flexRowCenter, classes.borderBottom)}>
                    <Box width="6%" className={classes.flexColumn}>
                      {errors.defaultValue ?
                        <Typography className={clsx(classes.tableTitle, classes.red)} style={{ fontWeight: 'bold', }}>
                          Default
                        </Typography>
                        : <Typography className={classes.tableTitle}>
                          Default
                        </Typography>}
                    </Box>
                    <Box width="34%" className={classes.flexColumn} style={{ marginLeft: '-20px', }}>
                      <Tooltip title="This is what will appear in the dropdown" placement="top">
                        <Typography className={classes.tableTitle}>
                          Display Name
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box width="21%" className={classes.flexColumn} style={{ marginLeft: '0px', }}>
                      <Tooltip title="This is the field value that will be input" placement="top">
                        <Typography className={classes.tableTitle}>
                          Report value
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box width="14%" className={classes.flexColumn}>
                      {/* conditionally render as disabled if the user doesn't have edit access */}
                      {userContext.userType !== UserType.SalesManager && // sales manager does not have access to edit drop down options
                        (!Configuration.UdidConfiguration.some(field => field.number === props.curCustomField?.number && field.fieldCreation === CustomFieldCreationPermission.Internal) ||
                          userContext.userType === UserType.Administrator) ?
                        <Box className={classes.flexRowCenter}>
                          <Typography style={{ color: '#00467F' }} onClick={() => {
                            if (!Configuration.UdidConfiguration.some(field => field.number === props.curCustomField?.number && field.fieldCreation === CustomFieldCreationPermission.Internal)) { setOpenDeleteDialog(true) }
                          }
                          }
                            className={clsx(classes.deleteAll, classes.flexColumn)}>
                            Delete all
                          </Typography> <Divider className={clsx(classes.divider, classes.flexColumn)} orientation="vertical" />
                          <IconButton className={classes.flexColumn} style={{ paddingRight: 0, paddingLeft: 0, }} disableRipple disableFocusRipple onClick={() => {
                            if (!Configuration.UdidConfiguration.some(field => field.number === props.curCustomField?.number && field.fieldCreation === CustomFieldCreationPermission.Internal)) { deleteSelectedValues() }
                          }}>
                            <Trashcan width={"24px"} height={"24px"} color={'#00467F'} />
                          </IconButton>
                        </Box>
                        :
                        <Box className={classes.flexRowCenter}>
                          <Typography style={{ color: '#C9C7C7' }}
                            className={clsx(classes.deleteAll, classes.flexColumn)}>
                            Delete all
                          </Typography> <Divider className={clsx(classes.divider, classes.flexColumn)} orientation="vertical" />
                          <IconButton className={classes.flexColumn} style={{ paddingRight: 0, paddingLeft: 0, }} disableRipple disableFocusRipple>
                            <Trashcan width={"24px"} height={"24px"} color={'#C9C7C7'} />
                          </IconButton>
                        </Box>}
                    </Box>
                  </TableHead>
                  <TableBody>
                    {searchFilterList().slice((searchPage - 1) * 25, searchPage * maxPageSize)
                      .map((field) =>
                        <TableRow style={{ paddingBottom: '10px', }} className={clsx(classes.flexRowCenter, classes.borderBottom)} key={field.id}>
                          <Box width="6%" className={classes.flexColumn} style={{ marginRight: '34px' }}>
                            <Controller
                              name={`customFieldValueList.${field.originalIndex}.defaultListItem` as const}
                              rules={formRules.customFieldValueList}
                              control={control}
                              render={({ field: { onChange, value, ref, onBlur } }) => (
                                <Checkbox
                                  id={`li-default-${field.customFieldValueId}-${field.originalIndex}`}
                                  checked={value}
                                  ref={ref}
                                  onBlur={onBlur}
                                  value={value}
                                  disabled={props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)}
                                  onChange={(event, checked) => { handleMakeDefault(checked, onChange); onBlur(); }}
                                  icon={<RadioButtonUnchecked />}
                                  checkedIcon={<CheckCircleOutlineIcon />}
                                  style={{ padding: 0, }}
                                  color="primary"
                                  size="small"
                                />
                              )} />
                          </Box>
                          <Box width='40%' className={classes.flexColumn}>
                            <Controller
                              name={`customFieldValueList.${field.originalIndex}.name` as const}
                              control={control}
                              rules={formRules.cfName}
                              render={({ field: { onChange, value, ref, onBlur } }) => (
                                <InputBase
                                  id={`li-value-${field.customFieldValueId}-${field.originalIndex}`}
                                  fullWidth
                                  className={classes.listIteminput}
                                  placeholder="List item"
                                  disabled={props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)}
                                  value={value}
                                  ref={ref}
                                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                  onChange={event => {
                                    let newVal = event.target.value;
                                    if (field.name === field.value) {
                                      setValue(`customFieldValueList.${field.originalIndex}.value` as `customFieldValueList.0.value`, newVal);
                                    }
                                    onChange(newVal);
                                  }
                                  }
                                  error={errors.customFieldValueList?.[field.originalIndex]?.name ? true : false}
                                />

                              )} />
                            <Typography className={classes.validation} variant='body2' color='error'>
                              {errors.customFieldValueList?.[field.originalIndex]?.name && errors.customFieldValueList?.[field.originalIndex]?.name?.message}
                            </Typography>
                          </Box>
                          <Box width='40%' className={classes.flexColumn}>
                            <Controller
                              name={`customFieldValueList.${field.originalIndex}.value` as const}
                              control={control}
                              rules={formRules.cfValue}
                              render={({ field: { onChange, value, ref, onBlur } }) => (
                                <InputBase
                                  id={`li-report-value-${field.customFieldValueId}-${field.originalIndex}`}
                                  fullWidth
                                  className={classes.listIteminput}
                                  placeholder="Report value"
                                  disabled={props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)}
                                  value={value}
                                  ref={ref}
                                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                  onChange={value => onChange(value)}
                                  onKeyDown={e => {
                                    if (e.key === "Tab") {
                                      if ((controlFieldValueArray.length - 1) === field.originalIndex) {
                                        handleAddValue();
                                      }
                                    }
                                  }
                                  }
                                  error={errors.customFieldValueList?.[field.originalIndex]?.value ? true : false}
                                />
                              )} />
                            <Typography className={classes.validation} variant='body2' color='error'>
                              {errors.customFieldValueList?.[field.originalIndex]?.value && errors.customFieldValueList?.[field.originalIndex]?.value?.message}
                            </Typography>
                          </Box>
                          <Box width='2%' className={classes.flexColumn}>
                            <Box className={classes.flexRowEnd}>
                              <Box className={classes.flexColumn}>
                                <Controller
                                  name={`customFieldValueList.${field.originalIndex}.markedForDeletion` as const}
                                  control={control}
                                  // rules={formRules.cfValue}
                                  render={({ field: { onChange, value, ref, onBlur } }) => (
                                    <Checkbox
                                      id={`li-checkbox-${field.customFieldValueId}-${field.originalIndex}`}
                                      size="small"
                                      className={clsx(classes.floatRight, classes.checkBox)}
                                      // onClick={(e) => toggleListItemForDeletion(e, index)}
                                      onChange={(event) => onChange(event)}
                                      ref={ref}
                                      onBlur={onBlur}
                                      disabled={props.curCustomField && !checkFormEditPermissions(userContext.userType, props.curCustomField.number, false)}
                                      style={{ padding: 0, }}
                                      color="primary"
                                      value={value}
                                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                  )} />
                              </Box>
                            </Box>
                          </Box>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>

                <Box style={{ marginTop: '-10px' }} className={clsx(classes.borderBottom, classes.flexRowEnd)}>
                  <Typography variant="body2" style={{ marginRight: "6em" }}>
                    Rows per page: <span style={{ fontWeight: "bolder", marginLeft: "1em" }}>25</span>
                  </Typography>
                  <Typography variant="body2">
                    {paginationText()} of {fieldValueArray.fields.length}
                  </Typography>
                  <IconButton className={classes.flexColumn} style={{ paddingRight: 12, }} onClick={() => { setSearchPage(searchPage - 1) }} disabled={searchPage === 1}>
                    <FirstPage />
                  </IconButton>
                  <IconButton className={classes.flexColumn} style={{ paddingRight: 12, }} onClick={() => { setSearchPage(searchPage - 1) }} disabled={searchPage === 1}>
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton className={classes.flexColumn} style={{ paddingRight: 12, }} onClick={() => { setSearchPage(searchPage + 1) }} disabled={searchPage * maxPageSize >= searchFilterList().length}>
                    <KeyboardArrowRight />
                  </IconButton>
                  <IconButton className={classes.flexColumn} style={{ paddingRight: 0, }} onClick={() => { setSearchPage(searchPage + 1) }} disabled={searchPage * maxPageSize >= searchFilterList().length}>
                    <LastPage />
                  </IconButton>
                </Box>
              </TableContainer>
              <DeleteAllDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                positiveAction={deleteAllValues}
              />
            </>
          }

          <Box>
            {watchFieldType == FieldTypeEnum.DropdownList ?
              <Grid container spacing={3} className={classes.flexRowTop}>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.flexColumn}>
                  <Button
                    style={{ width: 100, }}
                    variant="contained"
                    color="primary"
                    onClick={handleAddValue}
                    disabled={fieldValueArray.fields.length >= 50 ||
                      (props.curCustomField
                        && !checkFormEditPermissions(userContext.userType, props.curCustomField.number))}
                  >
                    Add item
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.flexColumn}>
                  <BulkList
                    getValues={getValues}
                    setValue={setValue}
                    fieldValueArray={fieldValueArray}
                    customFieldId={props.curCustomField ? props.curCustomField.customFieldId : -1}
                    canEdit={userContext.userType !== UserType.SalesManager && // sales manager does not have access to edit drop down options
                      (!Configuration.UdidConfiguration.some(field => field.number === props.curCustomField?.number && field.fieldCreation === CustomFieldCreationPermission.Internal) ||
                        userContext.userType === UserType.Administrator)} />
                </Grid>
              </Grid>
              : ""}
          </Box>
        </Grid>

        {/* Field preview */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box mt={-1} style={{ position: 'relative', }} className={classes.fieldPreview}>
            <Typography style={{ fontSize: '20px', marginBottom: 15, }}>Field preview</Typography>
            {checkDisplayCleanFormText() ?
              <>
                {/* <Typography className={classes.fieldPreviewCleanFormText}>
                  {watchFieldType == FieldTypeEnum.DropdownList ?
                    "Name the field and define its properties, then create its dropdown list below. Use the search and filter function to find and display specific list items."
                    :
                    "Choose a common field name or create a new one, then define its properties."
                  }
                </Typography> */}

                <Typography className={classes.fieldPreviewCleanFormText}>An example of your completed field will display here.</Typography>
              </>
              :
              <>
                <Box mt={4} className={classes.toolTipPreview}>
                  {watchTooltip ? watchTooltip : "Tooltip notes shows here"}
                </Box>
                <Box className={classes.toolTipPrieviewTip}>
                  &#9660;
                </Box>
                <Box mt={1}>
                  {watchFieldType === FieldTypeEnum.Freeform ?
                    <TextField
                      id="ff-field-preview"
                      disabled
                      // select={watchFieldType === FieldTypeEnum.DropdownList ? true : false}
                      placeholder={watchExample ? "EX: " + watchExample : ""}
                      label={
                        <Box mr={-0.5}>
                          {watchFieldName}{" "}
                          <InfoOutlinedIcon className={classes.tooltipLabel} />
                        </Box>
                      }
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ backgroundColor: 'white', }}
                    >
                    </TextField>
                    :
                    <>
                      <TextField
                        id="ff-field-preview-list"
                        ref={previewRef}
                        variant="outlined"
                        label={
                          <Box mr={-0.5}>
                            {watchFieldName}{" "}
                            <InfoOutlinedIcon className={classes.tooltipLabel} />
                          </Box>
                        }
                        value="text"
                        select
                        // disabled
                        fullWidth
                        size="small"
                        style={{ backgroundColor: 'white', }}
                        children={null}
                      />
                      {watchFieldValueArray.map((v, i) => {
                        if (v.defaultListItem === true) {
                          return <MenuItem style={{ marginTop: -36, }}>{v.name}</MenuItem>
                        } else return null
                      })
                      }
                      <Box className={classes.dropDownPreview}>
                        {watchFieldValueArray.map((v, i) => {
                          if (i < 5 && v.name) {
                            return <MenuItem>{v.name}</MenuItem>
                          } else return null
                        })
                        }
                      </Box>
                    </>
                  }
                  {watchFieldType !== FieldTypeEnum.DropdownList ?
                    <Box mt={1}>
                      {watchFieldName ?
                        <Typography className={classes.fieldPreviewHelperText}>The value entered for <b>{watchFieldName}</b>
                          {watchMax && watchMin ?
                            watchMax === watchMin ?
                              <> must be <b>{watchMin}</b> characters</>
                              : <> must be between <b>{watchMin}-{watchMax}</b> characters</>
                            :
                            (watchMax && watchMax > 0) ? <> must be <b>{watchMax}</b> or fewer characters</> :
                              (watchMin && watchMin > 0) ? <> must be <b>{watchMin}</b> or more characters</> : ""}
                        </Typography>
                        : null}
                    </Box> : ""}
                  {watchFieldType == FieldTypeEnum.DropdownList ?
                    <Box pt={.5}>
                      {<Typography style={{ fontSize: 13, color: '#808080' }}>Note: Preview only displays up to 5 items</Typography>}
                    </Box>
                    : ""}
                </Box>

              </>}
          </Box>
          {/* <SaveActivateDialog /> */}
        </Grid>

        {/* End field preview */}
      </Grid>
      {watchFieldType === FieldTypeEnum.Freeform &&
        <>
          <ActionDialog
            title="Remove level?"
            positiveText="Confirm"
            open={openReset}
            icon={<DeleteIcon />}
            setOpen={setOpenReset}
            positiveAction={() => restListValue()}
            negativeAction={() => (setValue("fieldType", FieldTypeEnum.DropdownList))}
            dialogType="delete"
          >
            <Typography variant="body1">Do you wish to save changes?</Typography>
            <Typography variant="body1">Changing the field type will <strong> clear </strong> any created list values.</Typography>
          </ActionDialog>
        </>
      }


      <Grid className={classes.fab}>
        <Box mb={0.5}>
          <Grid item>
            {/* Handles regular save/discard dialog functions; we pass errors in because isValid causes the page to crash for some reason */}
            <SaveBtn
              open={openDialog}
              handleSave={handleSave}
              isActive={getValues("activeStatus")}
              errors={errors}
            />

            {/* Handles Navigation away from page when form state is dirty */}
            <Prompt
              when={Object.keys(dirtyFields).length > 0}
              message={handleBlockedNavigation}
            />
            <SaveActivateDialog
              open={showNavSaveDialog}
              setOpen={setShowNavSaveDialog}
              positiveAction={() => {
                handleSubmit(onSubmit, onSubmitWithDirtyForm)()
                  .then(() => {
                    routerHistory.push(navigateOnSave);
                  }, catchSubmitError);
              }}
              isActive={getValues("activeStatus")}
            />

          </Grid>
        </Box>
        <Grid item>
          <CancelBtn handleCancel={handleCancel} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export class dateUtil {
  /**
 * Formats the Date Created to the correct display format: (05/20/2021, 08:27 AM)
 * @param date a Date Object representing the DateCreated of the ApprovalRequestApprovalRS
 */
  static formatDate(date: Date, timezone: string) {
    const newDate = new Date(date);
    return new Intl.DateTimeFormat('en-US', {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      hour12: true,
      timeZone: timezone,
      minute: "2-digit"
    }).format(newDate);
  }

  /**
   * Formats a date to the format of MM/yy for use with credit cards
   * @param date a Date Object representing the Credit Card Expiration Date
   */
  static dateToCreditCardExpirationString(date: Date) {
    const newDate = new Date(date);
    let dateStr = new Intl.DateTimeFormat('en-US', {
      month: "2-digit",
      year: "2-digit",
    }).format(newDate);
    return dateStr;
  }

  /**
   * create a date from a string of format MM/yy for use with credit cards
   * @param date a string representing the Credit Card Expiration Date
   */
  static creditCardExpireStringToDate(date: string) {
    let year = parseInt('20' + date.slice(-2));
    let month = parseInt(date.slice(0, 2)) - 1;
    let newDate = new Date(year, month);
    return newDate;
  }

  /**
 * Compare a date to Date.MinValue in Javascript and C#
 * @param date to compare
 */
  static compareToMinDate(date: Date) {
    return new Date(date).getTime() === new Date("0001-01-01T00:00:00").getTime()
      ? true
      : new Date(date).getTime() === new Date("0001-01-01T00:00:00Z").getTime();
    
  }
}
import React, { useState, useEffect, SetStateAction } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { ApprovalRequestController } from '@cbtravel/common/lib/shared/api/approval/controllers/approval-request-controller'
import { ApprovalRequestRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-request-rs'
import { ApprovalRequestApprovalRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-request-approval-rs'
import { ApprovalRequestStatusChangeRQ as StatusChangeRQ } from '@cbtravel/common/lib/shared/messages/approval/requests/custom/approval-request-status-change-rq'
import { ApprovalRequestCancelRQ as CancelRQ } from '@cbtravel/common/lib/shared/messages/approval/requests/custom/approval-request-cancel-rq'
import { ApprovalStatus } from '@cbtravel/common/lib/shared/common/enumerations/approval-status'
import HistoryDialog from "./HistoryDialog";
import ApprovalDialog from "./ApprovalDialog";
import CancelDialog from "./CancelDialog";
import { Configuration } from "@cbtravel/common/lib/shared/config/client-config";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { ExceptionDetail } from '@cbtravel/common/lib/shared/common/exceptions/exception-detail';
import CircularProgress from "@material-ui/core/CircularProgress";
import { dateUtil } from "../../../util/date-util"
import { ApprovalRequestInputRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-request-input-rs'
import { IApprovalRequestInput } from '@cbtravel/common/lib/shared/messages/general/responses/iApprovalRequestInput';
import { PreTripAuthorizationInput } from '@cbtravel/common/lib/shared/messages/approval/responses/custom/pre-trip-authorization-input'
import { PostBookApprovalInput } from '@cbtravel/common/lib/shared/messages/approval/responses/custom/post-book-approval-input'
import { UserEnrollmentInput } from '@cbtravel/common/lib/shared/messages/approval/responses/custom/user-enrollment-input'
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  CheckRounded as ApproveIcon,
  CloseRounded as DenyIcon,
  Delete as CancelIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  ErrorOutline as BulkErrorIcon,
} from "@material-ui/icons";
import { ApprovalType } from '@cbtravel/common/lib/shared/common/enumerations/approval-type';

interface DataRowProps {
  showCheckbox: boolean,
  selected: boolean,
  row: ApprovalRequestRS,
  loggedInUserId: number,
  loggedInUserEmail: string,
  token: string,
  getApprovalRequests: () => void
  onSelectToggle: (requestId: number) => void,
  inBulkRequest: boolean,
  bulkAction: string,
  bulkComment: string,
  timeZone: string | undefined,
  setShowMainSpinner: React.Dispatch<SetStateAction<boolean>>,
  checkShowButtons: (req: ApprovalRequestRS) => boolean,
  getThisApprover: (req: ApprovalRequestRS) => ApprovalRequestApprovalRS | undefined,
  tallyBulkResults: (didSucceed: boolean, requestId: number) => void,
  showTimedInfoSnackbar: (message: string) => void,
  expandSingle: boolean
}

const useStyles = makeStyles({
  actionButton: {
    whiteSpace: "nowrap",
    marginRight: "8px",
    "& .MuiButton-root": {
      minWidth: "auto",
    },
    "& .MuiButton-contained": {
      marginRight: "8px",
    },
    "& .MuiButton-contained.approve": {
      backgroundColor: "#ECF1E6",
      "&.MuiButton-contained.Mui-disabled": {
        backgroundColor: "#C9C7C7",
      },
    },
    "& .MuiButton-contained.approve .MuiSvgIcon-root": {
      color: "#417505",
    },
    "& .MuiButton-contained.approve.MuiButton-contained.Mui-disabled .MuiSvgIcon-root": {
      color: "#fff",
    },

    "& .MuiButton-contained.cancel": {
      backgroundColor: "#F8EAEA",
      padding: "3px",
      "&.MuiButton-contained.Mui-disabled": {
        backgroundColor: "#C9C7C7",
      },
    },
    "& .MuiButton-contained.cancel .MuiSvgIcon-root": {
      color: "#bc2c2f",
      fontSize: "1rem",
    },
    "& .MuiButton-contained.cancel.MuiButton-contained.Mui-disabled .MuiSvgIcon-root": {
      color: "#fff",
    },

    "& .MuiButton-contained.deny": {
      backgroundColor: "#F8EAEA",
      "&.MuiButton-contained.Mui-disabled": {
        backgroundColor: "#C9C7C7",
      },
    },
    "& .MuiButton-contained.deny .MuiSvgIcon-root": {
      color: "#bc2c2f",
    },
    "& .MuiButton-contained.deny.MuiButton-contained.Mui-disabled .MuiSvgIcon-root": {
      color: "#fff",
    },

    "& .MuiButton-containedSizeSmall": {
      padding: "1px 1px 1.5px 1.5px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
  tableRowErrorHighlight: {
    backgroundColor: "#F8EAEA",
  },
  bulkErrorInfo: {
    top: "7px !important",
    position: "relative",
    cursor: "help",
    marginRight: "8px"
  },
  bulkErrorText: {
    color: "#fff",
    padding: "6px 16px",
    fontSize: "0.8rem",
    fontFamily: "Open Sans, Arial, sans-serif",
    fontWeight: 400,
    "& span": {
      display: "block",
      marginBottom: "5px",
    },
  },
  fixedWidthCell: {
    width: "92px",
  },

  noWrapTooltip: {
    backgroundColor: "#4D4D4D", // snackbar default background color
    maxWidth: "none",
    borderRadius: "5px",
  },
});

export default function DataRow(props: DataRowProps) {
  const classes = useStyles();
  const snackbar = useCustomSnackbar();
  const { showCheckbox: checkboxEnabled, selected, row, loggedInUserId, loggedInUserEmail, checkShowButtons, getThisApprover, showTimedInfoSnackbar } = props;
  const [open, setOpen] = useState<boolean>(props.expandSingle); // handles the Collapse Menu details opening/closing for each individual row instead of re-rendering the whole table
  const [showButtons, setShowButtons] = useState<boolean>(false)  // whether or not to display accept/deny buttons. set in checkShowButtons()
  const [requestApprover, setRequestApprover] = useState<ApprovalRequestApprovalRS | undefined>() //the approvalRequestApprovalRS for the logged in User for this request (if applicable); set in checkShowButtons; used in approveRequest()
  const [openActionDialog, setOpenActionDialog] = useState<boolean>(false); //whether or not the dialog displays for single approve/deny
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false); //whether or not the dialog displays to cancel a request
  const [approvalAction, setApprovalAction] = useState<string>("Approve"); //string representing type of action, displayed by the dialog
  const [showMiniSpinner, setShowMiniSpinner] = useState<boolean>(false); // whether to show the mini spinners on each row in a bulk action request
  const [rowBulkError, setRowBulkError] = useState<JsonException | undefined>(undefined) //stores the error object if this row fails a bulk request
  const formattedApprovalInput: IApprovalRequestInput = formatApprovalInputList(row.approvalRequestInputList, row.approvalType)
  //* note: formattedApprovalInput is stored as its interface type for simplicity, but to use it in this file, you need to cast it to the appropriate specialized class first

  //ensures we always update the buttons whenever we get a fresh list of approval requests
  useEffect(() => {
    updateButtonsAndApprover(row)
  }, [row]);

  /**
   * calls the appropriate functions in the parent to set the update the necessary information
   * @param currentRequest an ApprovalRequestRS either mapped down from the parent, or updated from the HistoryDialog
   */
  function updateButtonsAndApprover(currentRequest: ApprovalRequestRS) {
    let approver = getThisApprover(currentRequest)
    let show = checkShowButtons(currentRequest)
    setRequestApprover(approver)
    setShowButtons(show)
  }

  //triggers individual DataRows calling to services when a bulk request is made in the parent
  useEffect(() => {
    if (selected && props.inBulkRequest) {
      let wasApproved = props.bulkAction === "Approve" ? true : false
      setShowMiniSpinner(true)
      approveRequest(wasApproved, props.bulkComment)
    }
  }, [props.inBulkRequest, props.bulkComment])

  /**
   * creates a StatusChangeRQ object then calls the services to update the appropriate approval request values
   * @param wasApproved - a boolean based on which button was clicked; true for "approved", false for "denied"
   * @param comment - a string value of the approver's comment
   */
  async function approveRequest(wasApproved: boolean, comment: string) {  //makes use of requestApprover state object so that we don't have to pass more information to the approval dialogs than necessary
    const changeRQ = new StatusChangeRQ();
    if (requestApprover) {
      changeRQ.approvalRequestId = requestApprover.approvalRequestId;
      changeRQ.approvalGroupId = requestApprover.approvalGroupId;
      changeRQ.emailAddress = loggedInUserEmail;
      changeRQ.userId = loggedInUserId;
      changeRQ.comment = comment;
      changeRQ.approvalStatus = wasApproved ? ApprovalStatus.Approved : ApprovalStatus.Denied;

      try {
        const response: Response = await ApprovalRequestController.ChangeStatus(props.token, changeRQ);

        if (response.ok && !props.inBulkRequest) {
          showTimedInfoSnackbar(`Request ${changeRQ.approvalStatus}.`)
          setRowBulkError(undefined)
        }

        if (response.ok && props.inBulkRequest) {
          //add bulk to success count
          props.tallyBulkResults(true, row.approvalRequestId);
          setRowBulkError(undefined);

        }

      } catch (err) {
        if (props.inBulkRequest) {
          //add to bulk failure count and set individual error
          props.tallyBulkResults(false, row.approvalRequestId)
          setRowBulkError(err as JsonException)
        } else {
          snackbar.error(err as JsonException);
          props.setShowMainSpinner(false);
        }
      } finally {
        setShowMiniSpinner(false)
        if (!props.inBulkRequest) {
          props.getApprovalRequests();
        }
      }
    }
  }

  /**
   * creates an ApprovalRequestCancelRQ and sends it to the services to delete the request
   * @param comment the comment string generated inside the CancelDialog
   */
  async function cancelRequest(comment: string) {
    let cancelRQ = new CancelRQ();
    // leverages the ApprovalRequestRS already passed to this DataRow, so that we don't have to pass the id to the dialog just to pass it back.
    cancelRQ.approvalRequestId = row.approvalRequestId;
    cancelRQ.comment = comment;

    try {
      const response = await ApprovalRequestController.Cancel(props.token, cancelRQ);

      if (response.ok) {
        showTimedInfoSnackbar("Request Canceled.")
      }

    } catch (e) {
      snackbar.error(e as JsonException);
      props.setShowMainSpinner(false);
    } finally {
      props.getApprovalRequests();
    }
  }

  //Parse child arrays for the actual values we want to display
  const approvers = row.approvalRequestApprovalList;
  const approversCol = approvers.length > 1 ? "Multiple" : `${approvers[0].user.firstName} ${approvers[0].user.lastName}`

  /**
   * Takes the ApprovalRequestInputList and reformats it into one of the specialized classes that implement the IApprovalRequestInput interface utilizing their overloaded constructors
   * @param inputs the ApprovalRequestInputList of this DataRow's Request
   * @param approvalType the ApprovalType of this DataRow
   * @returns an IApprovalRequestInput that matches one of its implementing classes
   */
  function formatApprovalInputList(inputs: ApprovalRequestInputRS[], approvalType: ApprovalType) {
    let approvalInput: IApprovalRequestInput;
    switch (approvalType) {
      case ApprovalType.PreTripAuthorization:
        approvalInput = new PreTripAuthorizationInput(inputs);
        break;
      case ApprovalType.PostBookApproval:
        approvalInput = new PostBookApprovalInput(inputs);
        break;
      case ApprovalType.UserEnrollment:
        approvalInput = new UserEnrollmentInput(inputs);
        break;
    }

    return approvalInput!;
  }

  //other value formatting
  let approvalType: string;
  let travelerName: string;
  let destination = "";
  if (row.approvalType === ApprovalType.PreTripAuthorization) {
    approvalType = "Pre-Trip Authorization";
    travelerName = `${row.user.firstName} ${row.user.lastName}`;
    let input = (formattedApprovalInput as PreTripAuthorizationInput);
    destination = input.DESTINATION;
  }
  else if (row.approvalType === ApprovalType.PostBookApproval) {
    approvalType = "Post-Booking Approval";
    travelerName = `${row.user.firstName} ${row.user.lastName}`;
    let input = (formattedApprovalInput as PostBookApprovalInput);
    destination = input.DESTINATION;
  }
  else if (row.approvalType === ApprovalType.UserEnrollment) {
    approvalType = "User Enrollment"
    let input = (formattedApprovalInput as UserEnrollmentInput)
    travelerName = `${input.FIRSTNAME} ${input.LASTNAME}`
  }

  /**
   * the tooltip/error icon to render on rows that fail during a bulk request
   * @param props a JsonException
   * @returns the tooltip/error icon to render on rows that fail during a bulk request; null if no error is passed
   */
  function ErrorTooltip(props: { err: JsonException }) {
    const { err } = props
    if (err) {
      return (
        <Tooltip
          id={`tool-tip-${err.message}`}
          placement='right'
          interactive={true}
          classes={{ tooltip: classes.noWrapTooltip }}
          title={
            <React.Fragment>
              <Typography classes={{ root: classes.bulkErrorText }}>
                <span>{err.message}</span>
                {
                  err.exceptionDetailList.length > 0 &&
                  err.exceptionDetailList.map((e: ExceptionDetail) => <span>{e.message}</span>)
                }
                {(err.uniqueID && Configuration.DisplayRaygunGUID) && <span>Unique ID: {err.uniqueID}</span>}
              </Typography>
            </React.Fragment>
          }>
          <BulkErrorIcon color="error" classes={{ root: classes.bulkErrorInfo }} />
        </Tooltip>
      )
    } else {
      return null;
    }
  }

  /**
   *A functional Component customized for the Approvals page that displays different buttons under different conditions
   * @returns a TableCell with the appropriate buttons based on other conditions on the page (or an empty cell)
   */
  function ActionButtons() {
    let actionsCell = null;
    let classObject = {};
    let cellAlignment: "left" | "center" = "left";
    if (showButtons) {
      if (checkboxEnabled && !showMiniSpinner) {
        // checkboxes
        actionsCell =
          <React.Fragment>
            {rowBulkError && <ErrorTooltip err={rowBulkError} />}
            <Checkbox
              id="checkbox-approval-status"
              checked={selected}
              onChange={(event) =>
                props.onSelectToggle(row.approvalRequestId)
              }
              disabled={row.approvalStatus !== ApprovalStatus.Pending || props.inBulkRequest}
              size="small"
            />
          </React.Fragment>;
        classObject = { root: classes.fixedWidthCell };
        cellAlignment = "center";

      } else if (showMiniSpinner) {
        // mini spinners
        actionsCell =
          <React.Fragment>
            <CircularProgress size={20} color={'secondary'} />
          </React.Fragment>;
        classObject = { root: classes.fixedWidthCell };
        cellAlignment = "center";
      } else {
        // single action buttons
        actionsCell =
          <React.Fragment>
            {rowBulkError && <ErrorTooltip err={rowBulkError} />}
            <Tooltip title="Approve request" placement="right">
              <Button
                id="btn-approve-request"
                variant="contained"
                size="small"
                className="approve"
                onClick={() => {
                  setApprovalAction("Approve");
                  setOpenActionDialog(true);
                }}
                disabled={props.inBulkRequest}
              >
                <ApproveIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Deny request" placement="right">
              <Button
                id="btn-deny-request"
                variant="contained"
                size="small"
                className="deny"
                onClick={() => {
                  setApprovalAction("Deny");
                  setOpenActionDialog(true);
                }}
                disabled={props.inBulkRequest}
              >
                <DenyIcon />
              </Button>
            </Tooltip>
            {props.loggedInUserId === row.user.userId &&
              <Tooltip title="Cancel request" placement="right">
                <Button
                  id="btn-cancel-request"
                  variant="contained"
                  size="small"
                  className="cancel"
                  onClick={() => {
                    setOpenCancelDialog(true);
                  }}
                  disabled={props.inBulkRequest}
                >
                  <CancelIcon />
                </Button>
              </Tooltip>
            }
            <ApprovalDialog
              openActionDialog={openActionDialog}
              setOpenActionDialog={setOpenActionDialog}
              approvalType={approvalType}
              travelerName={travelerName}
              destination={destination}
              action={approvalAction}
              onConfirm={approveRequest}
              bulkApprovalCount={0}
              setShowSpinner={props.setShowMainSpinner}
            />
            <CancelDialog
              openActionDialog={openCancelDialog}
              setOpenActionDialog={setOpenCancelDialog}
              onConfirm={cancelRequest}
              setShowSpinner={props.setShowMainSpinner}
            />
          </React.Fragment>;
        classObject = { root: classes.actionButton };
        ;
      }
    } else if (props.loggedInUserId === row.user.userId && !checkboxEnabled && row.approvalStatus === ApprovalStatus.Pending) {
      // single cancel button only
      actionsCell =
        <React.Fragment>
          <Tooltip title="Cancel request" placement="right">
            <Button
              id="btn-cancel-request-2"
              variant="contained"
              size="small"
              className="cancel"
              onClick={() => {
                setOpenCancelDialog(true);
              }}
            >
              <CancelIcon />
            </Button>
          </Tooltip>
          <CancelDialog
            openActionDialog={openCancelDialog}
            setOpenActionDialog={setOpenCancelDialog}
            onConfirm={cancelRequest}
            setShowSpinner={props.setShowMainSpinner}
          />
        </React.Fragment>
      classObject = { root: classes.actionButton }
    } else {
      // no buttons (empty cell)
      actionsCell = null;
      classObject = {};
    }
    return <TableCell align={cellAlignment} classes={classObject}>{actionsCell}</TableCell>
  }

  interface CollapseProps {
    input: IApprovalRequestInput,
    approvalType: ApprovalType,
    comment: string,
  }


  /**
   * Component used to represent a row in the table depending one what type of approval
   * is being used.
   * 
   * @param props {@link CollapseProps Properties} for the `CollapseProps` component.
   */
  function RowCollapse(props: CollapseProps) {
    let collapseInput = null;

    if (props.approvalType === ApprovalType.PreTripAuthorization) {
      // listing the prop type as the interface when we pass it and casting to a specific class here makes all the typechecks simpler
      let input = (props.input as PreTripAuthorizationInput)

      collapseInput =
        <React.Fragment>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Primary Passenger Name
            </TableCell>
            <TableCell id={`approvals-pre-trip-primary-passenger-name-${input.PRMPASSFIRST}-${input.PRMPASSLAST}`}>
              {`${input.PRMPASSFIRST} ${input.PRMPASSLAST}`}
            </TableCell>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Primary Passenger Email
            </TableCell>
            <TableCell id={`approvals-pre-trip-primary-passenger-email-${input.PRMPASSEMAIL}`}>{input.PRMPASSEMAIL}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
              width="180"
            >
              Leaving from
            </TableCell>
            <TableCell id={`approvals-pre-trip-orgin-${input.ORIGIN}`} width="250">{input.ORIGIN}</TableCell>
            <TableCell
              component="th"
              width="180"
              className="tableHeader"
            >
              Going to
            </TableCell>
            <TableCell id={`approvals-pre-trip-destination-${input.DESTINATION}`}>{input.DESTINATION}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Departure Date
            </TableCell>
            <TableCell id={`approvals-pre-trip-departure-date-${input.DEPARTDATE}`}>{input.DEPARTDATE}</TableCell>
            <TableCell component="th" className="tableHeader">
              Return Date
            </TableCell>
            <TableCell id={`approvals-pre-trip-return-date-${input.RETURNDATE}`}>{input.RETURNDATE}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Purpose
            </TableCell>
            <TableCell id={`approvals-pre-trip-purpose-${input.PURPOSE}`}>{input.PURPOSE}</TableCell>
            <TableCell
              component="th"
              width="180"
              className="tableHeader"
            >
              Estimated Total
            </TableCell>
            <TableCell id={`approvals-pre-trip-estimated-total-${input.TOTALEST}`}>{input.TOTALEST}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Comments
            </TableCell>
            <TableCell id={`approvals-pre-trip-comment-${props.comment}`}>{props.comment}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </React.Fragment>

    } else if (props.approvalType === ApprovalType.PostBookApproval) {
      let input = (props.input as PostBookApprovalInput)

      collapseInput =
        <React.Fragment>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Primary Passenger Name
            </TableCell>
            <TableCell id={`approvals-post-book-primary-passenger-name-${input.PRMPASSFIRST}-${input.PRMPASSLAST}`}>
              {`${input.PRMPASSFIRST} ${input.PRMPASSLAST}`}
            </TableCell>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Primary Passenger Email
            </TableCell>
            <TableCell id={`approvals-post-book-primary-passenger-email-${input.PRMPASSEMAIL}`}>{input.PRMPASSEMAIL}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
              width="180"
            >
              Leaving from
            </TableCell>
            <TableCell id={`approvals-post-book-orgin-${input.ORIGIN}`} width="250">{input.ORIGIN}</TableCell>
            <TableCell
              component="th"
              width="180"
              className="tableHeader"
            >
              Going to
            </TableCell>
            <TableCell id={`approvals-post-book-destination-${input.DESTINATION}`}>{input.DESTINATION}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Departure Date
            </TableCell>
            <TableCell id={`approvals-post-book-departure-date-${input.DEPARTDATE}`}>{input.DEPARTDATE}</TableCell>
            <TableCell component="th" className="tableHeader">
              Return Date
            </TableCell>
            <TableCell id={`approvals-post-book-return-date-${input.RETURNDATE}`}>{(input.RETURNDATE) ? input.RETURNDATE : "N/A"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Comments
            </TableCell>
            <TableCell id={`approvals-post-book-comment-${props.comment}`}>{props.comment}</TableCell>
            <TableCell
              component="th"
              width="180"
              className="tableHeader"
            >
              Total Price
            </TableCell>
            <TableCell id={`approvals-post-book-total-price-${input.TOTALPRICE}`}>{input.TOTALPRICE}</TableCell>
          </TableRow>

        </React.Fragment>

    } else if (props.approvalType === ApprovalType.UserEnrollment) {
      let input = (props.input as UserEnrollmentInput);

      collapseInput =
        <React.Fragment>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
              width="180"
            >
              Account Number
            </TableCell>
            <TableCell id={`approvals-user-enroll-clientactnbr-${input.CLIENTACTNBR}`}>{input.CLIENTACTNBR}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              User Full Name
            </TableCell>
            <TableCell id={`approvals-user-enroll-userfn-${input.FIRSTNAME}-${input.LASTNAME}`}>{`${input.FIRSTNAME} ${input.LASTNAME}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              User Email
            </TableCell>
            <TableCell id={`approvals-user-enroll-email-${input.EMAILADDRESS}`}>{input.EMAILADDRESS}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              City
            </TableCell>
            <TableCell id={`approvals-user-enroll-city-${input.CITY}`}>{input.CITY}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              className="tableHeader"
              scope="row"
            >
              Mobile Phone
            </TableCell>
            <TableCell id={`approvals-user-enroll-phone-${input.PHONECELL}`}>{input.PHONECELL}</TableCell>
          </TableRow>
        </React.Fragment>
    }
    return (
      <Box margin={1}>
        <Table
          aria-label="More info for approvals"
          className="moreInfoTable"
          size="small"
        >
          <TableBody>
            {collapseInput}
          </TableBody>
        </Table>
      </Box>
    )
  }


  //highlights row if it failed on a bulk action
  let bulkErrorClass = rowBulkError ? { root: classes.tableRowErrorHighlight } : {};
  // let url = row.approvalType === ApprovalType.PostBookApproval ? "/airtinerary/view/?recloc=" : row.approvalType === ApprovalType.UserEnrollment ? row.user.firstName : "aa";
  let url = row.approvalType === ApprovalType.PostBookApproval ? "/airtinerary/view/?recloc=" : "/user/settings?emailAddress=";
  return (
    <React.Fragment>
      <TableRow key={row.approvalRequestId} classes={bulkErrorClass}>
        <ActionButtons />

        <TableCell id={`approvals-status-${row.approvalStatus}`} className={row.approvalStatus.toLowerCase()}>
          {row.approvalStatus}
        </TableCell>
        <TableCell id={`approvals-date-created-${dateUtil.formatDate(new Date(row.dateCreated + "+00:00"), props.timeZone ? props.timeZone : "UTC")}`}>
          {dateUtil.formatDate(
            new Date(row.dateCreated + "+00:00"),
            props.timeZone ? props.timeZone : "UTC"
          )}
        </TableCell>
        <TableCell id={`approvals-requester-name-${row.user.firstName}-${row.user.lastName}`}>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
        <TableCell id={`approvals-requester-email-${row.user.emailAddress}`}>{row.approvalType === ApprovalType.UserEnrollment ? row.user.firstName + " " + row.user.lastName :
          row.approvalType === ApprovalType.PostBookApproval ? (formattedApprovalInput as PostBookApprovalInput).PRMPASSFIRST + " " + (formattedApprovalInput as PostBookApprovalInput).PRMPASSLAST :
            (formattedApprovalInput as PreTripAuthorizationInput).PRMPASSFIRST + " " + (formattedApprovalInput as PreTripAuthorizationInput).PRMPASSLAST}</TableCell>
        <TableCell id={`approvals-requester-client-name-${row.client.name}`}>{row.client.name}</TableCell>
        {row.approvalType === ApprovalType.PostBookApproval ? (
          <TableCell>
            <a
              href={
                Configuration.AirPortalUrl +
                url + `${row.externalIdentifier}`
              }
              target="_blank"
            >
              {row.externalIdentifier ? row.externalIdentifier : ""}
            </a>
          </TableCell>
        ) :
          row.approvalType === ApprovalType.UserEnrollment ? (<TableCell>{row.user.firstName + " " + row.user.lastName}</TableCell>)
            :
            row.approvalType === ApprovalType.PreTripAuthorization ? (<TableCell>{(formattedApprovalInput as PreTripAuthorizationInput).PURPOSE}</TableCell>)
              : (
                <TableCell></TableCell>
              )}
        <TableCell id={`approvals-approval-type-${approvalType!}`}>{approvalType!}</TableCell>
        <TableCell align="center">
          <HistoryDialog
            token={props.token}
            timeZone={props.timeZone ? props.timeZone : "UTC"}
            approvalReqId={row.approvalRequestId}
            prevStatus={row.approvalStatus}
            updateButtonsAndApprover={updateButtonsAndApprover}
            getApprovalRequests={props.getApprovalRequests}
          />
        </TableCell>
        <TableCell>
          <IconButton
            id="btn-expand"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* Collapse info starts here  */}
      <TableRow className="moreInfoRow">
        <TableCell className="moreInfoCell" colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RowCollapse
              input={formattedApprovalInput}
              approvalType={row.approvalType}
              comment={row.comment}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
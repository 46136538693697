import React, { useContext, useState } from 'react';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  CheckCircleOutlineRounded as Sent,
  HighlightOffRounded as NotSent,
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { ApprovalRequestNudgeRQ } from '@cbtravel/common/lib/shared/messages/approval/requests/approval-request-nudge-rq';
import { ApprovalRequestApprovalRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-request-approval-rs";
import { ApprovalRequestController } from '@cbtravel/common/lib/shared/api/approval/controllers/approval-request-controller';
import { ApprovalGroupType } from '@cbtravel/common/lib/shared/common/enumerations/approval-group-type';
import { ApprovalStatus } from '@cbtravel/common/lib/shared/common/enumerations/approval-status';
import { JsonException } from '@cbtravel/common/lib/shared/common/exceptions/json-exception';

import { UserContext } from '../../../components/shared/UserContext';
import Spinner from '../../../components/shared/Spinner';
import CommentTooltip from './CommentTooltip';
import { dateUtil } from '../../../util/date-util';
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';


const useStyles = makeStyles({
  tableContainer: {
    padding: "0 16px 16px",
    marginTop: "-16px",
    "& .MuiTypography-h3": {
      fontSize: ".9rem",
      fontWeight: 700,
      margin: "32px 0 12px 8px",
    },
  },
  table: {
    "& .MuiTableCell-head": {
      fontWeight: 700,
    },
    "& .MuiTableCell-root": {
      lineHeight: 2,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      top: ".2em",
      position: "relative",
    },
    "& .MuiButton-outlinedSizeSmall": {
      padding: "1px 0 3px",
      fontSize: "0.75rem",
      fontWeight: 700,
      margin: "5px 0",
    },
  },
  statusCell: {
    lineHeight: 2,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  approved: {
    color: "#417505",
  },
  pending: {
    color: "#ff8401",
  },
  denied: {
    color: "#bc2c2f",
  },
  required: {
    color: "#00467E",
    fontWeight: 700,
  },
  noReminderYet: {
    color: "#bc2c2f",
    fontWeight: 700,
  },
  requiredLegend: {
    color: "#00467E",
    paddingLeft: "16px",
    fontSize: ".7rem",
    fontWeight: 700,
    marginTop: "4px",
  },
});

interface HistoryLevelProps {
  approvals: ApprovalRequestApprovalRS[],
  prevLevelApprovals: ApprovalRequestApprovalRS[]
  nextReminder: string | null,
  timezone: string,
}

export default function HistoryLevel(props: HistoryLevelProps) {
  const classes = useStyles();
  const { approvals } = props;
  const prevLevelStatus: ApprovalStatus = doApprovalsCompleteLevel(props.prevLevelApprovals); //used in a check to display nudge button
  const approvalLevelStatus: ApprovalStatus = doApprovalsCompleteLevel(props.approvals); //used in a check to display nudge button
  const { userContext } = useContext(UserContext);
  const snackbar = useCustomSnackbar();

  /**
   * Under the assumption that all ApprovalRequestApprovals in 'approvals' are in the same level
   * and consists of the full group, returns true if the level is approved/denied, false if pending.
   */
  function doApprovalsCompleteLevel(levelApprovals: ApprovalRequestApprovalRS[]): ApprovalStatus {
    let currentStatus: ApprovalStatus = ApprovalStatus.Pending;

    //make sure prevLevelComplete gets set correctly. It will be empty on level 1, so <NudgeElement/> should treat it as Complete.
    if (levelApprovals.length === 0) { return ApprovalStatus.Approved };

    // Make the assumption that all in 'approvals' are of the same group type (they should be the same group anyway in this dialog)
    switch (levelApprovals[0]!.approvalGroupType) {

      // There only needs to be one approval that isn't pending to complete 'Any'
      case ApprovalGroupType.Any:
        for (const approval of levelApprovals) {
          if (approval.approvalStatus === ApprovalStatus.Denied) {
            currentStatus = ApprovalStatus.Denied;
            break;
          } else if (approval.approvalStatus === ApprovalStatus.Approved) {
            currentStatus = ApprovalStatus.Approved;
            break;
          }
        }
        break;

      // There can be no pending approvals in 'All' or 'Ordered'
      case ApprovalGroupType.All:
      case ApprovalGroupType.Ordered:
        let approvedCount = 0;
        for (const approval of levelApprovals) {
          // any approver's denial means the entire request is Denied
          if (approval.approvalStatus === ApprovalStatus.Denied) {
            currentStatus = ApprovalStatus.Denied;
            break;
          } else if (approval.approvalStatus === ApprovalStatus.Approved) {
            approvedCount++
          }
        }
        // all approvers must have approved for the level to be complete
        if (approvedCount === levelApprovals.length) {
          currentStatus = ApprovalStatus.Approved;
        }
        break;
    } //switch end

    return currentStatus;
  }


  /**
   * custom component that returns a table cell with the appropriate text/classes. 
   * When using multiple, nested-object-classes, this is easier than just using conditional JSX in the html
   * @param props the ApprovalStatus of the ApprovalRequestApproval for the given row represented as a string
   */
  function StatusLogic(props: { status: string, comment: string }) {
    const { status, comment } = props;
    let statusCell;
    if (status.toLowerCase() === "pending") {
      statusCell = (
        <TableCell className={clsx(classes.statusCell, classes.pending)}>
          Pending
        </TableCell>
      );
    } else if (status.toLowerCase() === "approved") {
      statusCell = (
        <TableCell className={clsx(classes.statusCell, classes.approved)}>
          Approved
          {/* Shows comment tooltip only if the approver left a comment */}
          {comment.length > 0 && <CommentTooltip comment={comment} />}
        </TableCell>
      );
    } else if (status.toLowerCase() === "denied") {
      statusCell = (
        <TableCell className={clsx(classes.statusCell, classes.denied)}>
          Denied
          {/* Shows comment tooltip only if the approver left a comment */}
          {comment.length > 0 && <CommentTooltip comment={comment} />}
        </TableCell>
      );
    } else {
      statusCell = <TableCell>{status}</TableCell>;
    }
    return statusCell;
  }

  interface nudgeProps {
    thisApproval: ApprovalRequestApprovalRS,  // the approval this nudge element applies to
    approvalLevelStatus: ApprovalStatus, //whether or not this level is entirely approved/denied (false if still pending)
    prevLevelStatus: ApprovalStatus, //whether or not the previous level is entirely approved/denied (will be set to true if this is Level 1)

  }
  /**
   * Returns a JSX element to put into the Nudge column
   */
  function NudgeElement(props: nudgeProps) {
    const { thisApproval, approvalLevelStatus, prevLevelStatus } = props;
    const [showNudgeSpinner, setShowNudgeSpinner] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    let statusCell;

    /**
     * Sends a request to the API to send a nudge email based on a given ApprovalRequestApprovalRS
     */
    async function sendNudgeRequest(approval: ApprovalRequestApprovalRS) {
      var request = new ApprovalRequestNudgeRQ();
      request.approvalGroupId = approval.approvalGroupId;
      request.approvalRequestId = approval.approvalRequestId;
      request.recipientUserId = approval.user.userId;

      try {
        setDisabled(true);
        setShowNudgeSpinner(true);

        var response: Response = await ApprovalRequestController.Nudge(userContext.accessToken, request);
        if (response.status === 204) {
          snackbar.info("Successfully nudged!");
        }

      } catch (e) {
        snackbar.error(e as JsonException);
      } finally {
        setDisabled(false);
        setShowNudgeSpinner(false);
      }
    }

    // only show nudge button if this approver hasn't responded, this level is still pending, and the level before it is complete
    if (thisApproval.approvalStatus === ApprovalStatus.Pending && approvalLevelStatus === ApprovalStatus.Pending && prevLevelStatus === ApprovalStatus.Approved) {
      statusCell = (
        <Button id="btn-nudge" color="primary" variant="outlined" size="small" disabled={disabled} onClick={() => sendNudgeRequest(thisApproval)}>
          Nudge
          {showNudgeSpinner && <Spinner size="1.5rem" />}
        </Button>
      );
    } else {
      statusCell = <></>;
    }
    return statusCell;
  }

  interface ReminderProps {
    nextReminder: string | null,
    isSent: boolean,
    thisApproval: ApprovalRequestApprovalRS,
    prevLevelStatus: ApprovalStatus,
    approvalLevelStatus: ApprovalStatus  //whether or not this level is entirely approved/denied (false if still pending),
    timezone: string
  }

  /**
   * A component that renders a TableCell populated according to if Email Reminders are configured or not
   * @param props the props object of the component
   * @returns a <TableCell> element populated according to email reminder settings
   */
  function NextEmailReminder(props: ReminderProps) {
    let tableCell;
    if (props.nextReminder && props.thisApproval.approvalStatus === ApprovalStatus.Pending && approvalLevelStatus === ApprovalStatus.Pending) {
      if (props.prevLevelStatus === ApprovalStatus.Approved) {
        //reminders are enabled, and this is the level we currently are waiting on
        tableCell = <TableCell>{props.nextReminder}</TableCell>
      } else {
        //reminders are enabled but previous levels have not approved yet
        tableCell = <TableCell className={classes.noReminderYet}>Waiting for Level {approvals[0].level - 1} approval</TableCell>
      }
    } else if (!props.nextReminder) {
      //reminders are not configured for this approval
      tableCell = <TableCell>{dateUtil.formatDate(new Date(props.thisApproval.dateModified + "+00:00"), props.timezone)}</TableCell>
    } else {
      //reminders are enabled, this level has already approved
      tableCell = <TableCell></TableCell>
    }
    return tableCell;
  }


  return (
    <TableContainer className={classes.tableContainer}>
      <Typography variant="h3">
        Level {approvals[0].level}: {approvals[0].approvalGroupType}
      </Typography>
      <Table
        aria-label="Approval history table"
        size="small"
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell width="22%">{props.nextReminder ? "Next email reminder" : "Last modified"}</TableCell>
            <TableCell width="15%">Approver</TableCell>
            <TableCell width="15%">Status</TableCell>
            <TableCell width="20%" align="center">
              Approval requested
            </TableCell>
            <TableCell width="20%" align="center">
              Send reminder
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* creating each row of the level */}
          {approvals.map((approval) => (
            <TableRow key={approval.user.userId}>
              <NextEmailReminder
                nextReminder={props.nextReminder}
                isSent={approval.isSent}
                thisApproval={approval}
                prevLevelStatus={prevLevelStatus}
                approvalLevelStatus={approvalLevelStatus}
                timezone={props.timezone}
              />
              <TableCell id={`approval-history-approver-${approval.user.firstName}-${approval.user.lastName}`} component="th" scope="row">
                {approval.user.firstName} {approval.user.lastName}
              </TableCell>
              <StatusLogic
                status={approval.approvalStatus}
                comment={approval.comment}
              />
              <TableCell align="center">
                {approval.isSent ? (
                  <React.Fragment>
                    <Sent className={classes.approved} /> Email sent
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <NotSent className={classes.denied} /> Email not sent
                  </React.Fragment>
                )}
              </TableCell>
              <TableCell align="center">
                <NudgeElement
                  thisApproval={approval}
                  approvalLevelStatus={approvalLevelStatus}
                  prevLevelStatus={prevLevelStatus}
                ></NudgeElement>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
import React, { useState, useEffect, SetStateAction } from "react";
import { Grid, Typography, Box, TextField, Paper, Button, createStyles, makeStyles, Theme, Link, } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ApprovalStatus } from "@cbtravel/common/lib/shared/common/enumerations/approval-status";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      "#pendo-image-badge-5c3c4f83": {
        display: "none !important",
      },
    },
    root: {
      "& .MuiButton-containedSecondary": {
        color: "#fff",
        padding: "6px 20px 9px",
      },
      "& .MuiGrid-container": {
        margin: "0 !important",
      },
      "& .MuiGrid-grid-xs-12": {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      "& .MuiTypography-h1": {
        lineHeight: '1.3',
        fontSize: '30px',
        fontWeight: 300,
      },
    },
    container: {
      "& .MuiGrid-container": {
        margin: "0 !important",
      },
      "& .MuiGrid-spacing-xs-3": {
        width: '100%',
      },
    },
    headerLineHeight: {
      lineHeight: '36px',
    },
    terms: {
      fontSize: '12px',
      color: '#585B64',
    },
    sticky: {
      bottom: '0',
      position: 'absolute',
      padding: 20,
    },
    loginBtn: {
      width: 250,
    }
  })
);
interface EnrollMessageProps {
  messageType: ApprovalStatus | undefined
}

export default function EnrollMessage(props: EnrollMessageProps) {
  const classes = useStyles();
  const routerHistory = useHistory();
  let headerText = "";
  let message1 = "";
  let message2 = "";
  let message3 = "";
  switch (props.messageType) {
    case ApprovalStatus.Approved:
      headerText = "Success!"
      message1 = "Your enrollment request has been sent to your travel manager. "
      message2 = "Upon approval, we'll send you instructions to log in to AirPortal® where you can complete your full travel profile, including TSA information and flight and hotel loyalty numbers."
      message3 = "You may now close this window."
      break;
    case ApprovalStatus.Pending:
      headerText = "Your enrollment request is pending approval."
      message1 = "Your travel manager is reviewing a previous request that matches your information."
      message2 = "Upon approval, we'll send you instructions to log in to AirPortal® where you can complete your full travel profile, including TSA information and flight and hotel loyalty numbers."
      message3 = "Thank you for your patience."
      break;
    case ApprovalStatus.Denied:
      headerText = "You already have an AirPortal® account."
      message1 = "Click below to log in to AirPortal where you can create a password and complete your full travel profile, including TSA information and flight and hotel loyalty numbers."
      break;
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Box mt={4}>
                  <Typography className={classes.headerLineHeight} variant="h1">
                    {headerText}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography>{message1}</Typography>
                </Box>
                <Box mt={2}>
                  <Typography>
                    {message2}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography>{message3}</Typography>
                </Box>
                {props.messageType === ApprovalStatus.Denied &&
                  <Box mt={5}>
                    <Button className={classes.loginBtn} variant="contained" color="primary" id="saveButton" onClick={() => { routerHistory.push("/login") }}>
                      Login
                    </Button>
                  </Box>}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.sticky}>
          <Typography className={classes.terms} component="p">
            <Box fontSize={12} py={2}>
              By enrolling, you agree to the{" "}
              <Link
                href="https://app.cbtat.com/legal/terms"
                color="inherit"
                target="_blank"
                underline="always"
              >
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link
                href="https://app.cbtat.com/legal/privacy"
                color="inherit"
                target="_blank"
                underline="always"
              >
                Privacy Policy
              </Link>
              .
            </Box>
          </Typography>
        </Box>
      </Grid>
    </>
  );
}

import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Box, Fab, Tab, Tabs } from "@material-ui/core";
import Spinner from "../../../components/shared/Spinner";

import AirTab from "./AirTab";
import CarTab from "./CarTab";
import HotelTab from "./HotelTab";

import AddIcon from '@material-ui/icons/Add';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface ReferenceDataProps {
  /** Object representing the currently active client. */
  activeClient?: ClientRS,
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

function tabProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTab-root": {
      minWidth: 'auto',
    },
    "& .MuiTabScrollButton-root": {
      width: '20px',
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      marginTop: 1, 
      color: "#00467E",
    },
    backgroundColor: '#ffffff',
    borderBottomWidth: '1px',
    borderBottomColor: '#ddd',
    borderBottomStyle: 'solid',
  },
  tab: {
    textTransform: 'none',
    paddingLeft: 25,
    paddingRight: 25,
  },
  tabRow: {
    marginTop: 17,
    boxShadow: 'none',
  },
  fab: {
    "& .MuiSvgIcon-root": {
      fontSize: '2rem',
    },
    position: "absolute",
    bottom: 32, right: 32,
    width: 50, height: 50,
    color: "#fff",
    background: "#00467E",
    boxShadow:
        "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
},
  }));

/** Reference data page (service options like amenities, attributes for air, car, hotel) */
export default function ReferenceData(props: ReferenceDataProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [displayReferenceDataForm, setDisplayReferenceDataForm] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  /** Opens up add form */
  const handleAddForm = () => {
    setDisplayReferenceDataForm(true);
  }

  /** When discard button is clicked in dialog, it hides the VendorForm */
  const handleDiscardChanges = () => { 
    setDisplayReferenceDataForm(false);
  }

  return (
    <>
      <AppBar className={classes.tabRow} position="static" color="default">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Reference data tab navigation"
        >
          <Tab className={classes.tab} label="Air" {...tabProps(0)} />
          <Tab className={classes.tab} label="Hotel" {...tabProps(1)} />
          <Tab className={classes.tab} label="Car" {...tabProps(2)} />
        </Tabs>
      </AppBar>

      <Box ml={.5} mr={-.5}>
        <TabPanel value={value} index={0}>
          <AirTab displayReferenceDataForm={displayReferenceDataForm} discardChanges={handleDiscardChanges} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HotelTab displayReferenceDataForm={displayReferenceDataForm} discardChanges={handleDiscardChanges}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CarTab displayReferenceDataForm={displayReferenceDataForm} discardChanges={handleDiscardChanges}/>
        </TabPanel>
      </Box>

      {!displayReferenceDataForm &&
        <Fab
          color="primary"
          aria-label="Add offering"
          className={classes.fab}
          onClick={handleAddForm}
        >
          <AddIcon />
        </Fab>
      }
    </>

  )
}
let permissions = {
  Administrator: {
    static: [
      "dashboard-menu:visit",
      "dashboard-manager:visit",
      "dashboard-agent:visit",
      "dashboard-mytravel:visit",
      "trips-menu:visit",
      "airtinerary-lookup:visit",
      "current-future-trips:visit",
      "past-trips:visit",
      "unknownstays:visit",
      "hotel-checkins:visit",
      "book-travel-menu:visit",
      "travel-request:visit",
      "approval-request:visit",
      "reporting-fields:visit",
      "hrfeedupload:visit",
      "user-search:visit",
      "manage-online-trips:visit",
      "groups-menu:visit",
      "groups-request:visit", //who gets this?
      "groups-inprocess:visit", //who gets this?
      "groups-finalized:visit", //who gets this?
      "groups-manage:visit", //who gets this?
      "travel-approval-menu:visit",
      "airtinerary-approval:visit",
      "airtinerary-approved:visit",
      "airtinerary-canceled:visit",
      "approval-beta:visit",
      "risk-management-menu:visit",
      "securitylogic-map:visit",
      "securitylogic-beta:visit", //who gets this?
      "securitylogic-assistance:visit",
      "securitylogic-alerts:visit",
      "securitylogic-notifcations:visit",
      "reporting-menu:visit",
      "unused-tickets:visit", //who gets this?
      "airbank-tickets:visit",
      "airbank-lookup:visit",
      "savingstracker:visit",
      "savingstracker-scorecard:visit",
      "vpay-lookup:visit",
      "domo:visit", //who gets this?
      "ibank:visit",
      "primeanalytics:visit",
      "company-admin-menu:visit",
      "incomplete-profiles:visit",
      "client-information:visit",
      "configure-approval:visit",
      "client-people:visit",
      "client-contracts:visit",
      "client-people-contacts:visit",
      "helpdesk:visit",
      "travelpolicy:visit",
      "client-servicesfees-payment:visit",
      "client-servicesfees:visit",
      "client-reporting-fields:visit",
      "client-report:visit",
      "client-technology-general:visit",
      "client-airtinerary:visit",
      "client-vpayhotel-card:visit",
      "client-user-requests:visit", //who gets this?
      "client-integrations:visit",
      "client-consult:visit",
      "client-notes:visit",
      "client-accountnotes:visit",
      "client-log:visit",
      "employee-tools-menu:visit",
      "admin-search:visit",
      "agents-search:visit",
      "airtinerary-agent-approval-mytasks:visit",
      "agents-team:visit",
      "andavo-admin:visit", //who gets this?
      "employee-carrier-contracts:visit",
      "client-directory:visit",
      "employee-directory:visit", //who gets this?
      "gds-scripts:visit",
      "resource-center:visit",
      "tmu:visit",
      "commissions-statement-collected:visit", //who gets this?
      "commissions-unclaimed:visit", //who gets this?
      "vpy-billing:visit",
      "voidlogger:visit",
      "admin-menu:visit",
      "airtinerary-import:visit",
      "vendors:visit",
      "locations:visit",
      "reference-data:visit",
      "vendor-profiles-airvendor:visit",
      "location-profiles-airport:visit",
      "service-profiles-aircraft:visit",
      "office:visit",
      "tmc:visit",
      "analytics-site-analytics:visit",
      "analytics-profile-export:visit",
      "analytics-tripit:visit",
      "permissions:visit",
      "sap:visit",
      "queue-manager:visit",
      "user-profile:visit",
      "required-fields:visit",
      //Profile masked field visibility permissions for Administrator:
      "ownProfileField:unmask",
      "dateOfBirth:unmask",
      "idCard.idCardNumber:unmask",
      "passports.passportNumber:unmask",
      "travelerNumber:unmask",
      "redressNumber:unmask"
    ],
  },
  AccountManager: {
    static: [
      "ibank:visit",
      "primeanalytics:visit",
      "savingstracker-scorecard:visit",
      "dashboard-menu:visit",
      "dashboard-manager:visit",
      "dashboard-agent:visit",
      "dashboard-mytravel:visit",
      "reporting-fields:visit",
      "trips-menu:visit",
      "airtinerary-lookup:visit",
      "current-future-trips:visit",
      "past-trips:visit",
      "unknownstays:visit",
      "hotel-checkins:visit",
      "book-travel-menu:visit",
      "travel-request:visit",
      "approval-request:visit",
      "manage-online-trips:visit",
      "travel-approval-menu:visit",
      "airtinerary-approval:visit",
      "airtinerary-approved:visit",
      "airtinerary-canceled:visit",
      "approval-beta:visit",
      "risk-management-menu:visit",
      "securitylogic-map:visit",
      "securitylogic-assistance:visit",
      "securitylogic-alerts:visit",
      "securitylogic-notifcations:visit",
      "reporting-menu:visit",
      "unused-tickets:visit",
      "airbank-tickets:visit",
      "airbank-lookup:visit",
      "savingstracker:visit",
      "vpay-lookup:visit",
      "domo:visit",
      "company-admin-menu:visit",
      "client-information:visit",
      "configure-approval:visit",
      "client-people:visit",
      "client-contracts:visit",
      "client-people-contacts:visit",
      "helpdesk:visit",
      "travelpolicy:visit",
      "client-servicesfees-payment:visit",
      "client-servicesfees:visit",
      "client-report:visit",
      "client-technology-general:visit",
      "client-airtinerary:visit",
      "client-vpayhotel-card:visit",
      "client-integrations:visit",
      "client-consult:visit",
      "client-notes:visit",
      "client-log:visit",
      "employee-tools-menu:visit",
      "admin-search:visit",
      "agents-search:visit",
      "incomplete-profiles:visit",
      "agents-team:visit",
      "employee-carrier-contracts:visit",
      "client-client-directory:visit",
      "resource-center:visit",
      "vpy-billing:visit",
      "user-profile:visit",
      "required-fields:visit",
      "user-search:visit",
      //Profile masked field visibility permissions for Account Manager:
      "ownProfileField:unmask",
      "dateOfBirth:unmask",
      "idCard.idCardNumber:unmask",
      "passports.passportNumber:unmask",
      "travelerNumber:unmask",
      "redressNumber:unmask"
    ],
  },
  Advisor: {
    static: [
      "dashboard-menu:visit",
      "dashboard-agent:visit",
      "dashboard-mytravel:visit",
      "trips-menu:visit",
      "airtinerary-lookup:visit",
      "current-future-trips:visit",
      "past-trips:visit",
      "unknownstays:visit",
      "hotel-checkins:visit",
      "book-travel-menu:visit",
      "travel-request:visit",
      "approval-request:visit",
      "travel-approval-menu:visit",
      "airtinerary-approval:visit",
      "airtinerary-approved:visit",
      "airtinerary-canceled:visit",
      "approval-beta:visit",
      "risk-management-menu:visit",
      "securitylogic-map:visit",
      "securitylogic-assistance:visit",
      "securitylogic-alerts:visit",
      "securitylogic-notifcations:visit",
      "reporting-menu:visit",
      "unused-tickets:visit",
      "airbank-tickets:visit",
      "airbank-lookup:visit",
      "savingstracker:visit",
      "vpay-lookup:visit",
      "ibank:visit",
      "company-admin-menu:visit",
      "client-information:visit",
      "client-people:visit",
      "client-contracts:visit",
      "helpdesk:visit",
      "client-servicesfees:visit",
      "client-report:visit",
      "client-vpayhotel-card:visit",
      "client-integrations:visit",
      "employee-tools-menu:visit",
      "admin-search:visit",
      "incomplete-profiles:visit",
      "airtinerary-agent-approval-mytasks:visit",
      "employee-carrier-contracts:visit",
      "gds-scripts:visit",
      "resource-center:visit",
      "user-profile:visit",
      "required-fields:visit",
      "user-search:visit",
      //Profile masked field visibility permissions for Advisor:
      "ownProfileField:unmask",
      "dateOfBirth:unmask",
      "idCard.idCardNumber:unmask",
      "passports.passportNumber:unmask",
      "travelerNumber:unmask",
      "redressNumber:unmask"
    ],
  },
  SalesManager: {
    static: [
      "dashboard-menu:visit",
      "dashboard-manager:visit",
      "dashboard-agent:visit",
      "dashboard-mytravel:visit",
      "trips-menu:visit",
      "airtinerary-lookup:visit",
      "current-future-trips:visit",
      "past-trips:visit",
      "unknownstays:visit",
      "hotel-checkins:visit",
      "book-travel-menu:visit",
      "travel-request:visit",
      "approval-request:visit",
      "reporting-fields:visit",
      "manage-online-trips:visit",
      "travel-approval-menu:visit",
      "airtinerary-approval:visit",
      "airtinerary-approved:visit",
      "airtinerary-canceled:visit",
      "approval-beta:visit",
      "risk-management-menu:visit",
      "securitylogic-map:visit",
      "securitylogic-assistance:visit",
      "securitylogic-alerts:visit",
      "securitylogic-notifcations:visit",
      "reporting-menu:visit",
      "unused-tickets:visit",
      "airbank-tickets:visit",
      "airbank-lookup:visit",
      "savingstracker:visit",
      "savingstracker-scorecard:visit",
      "vpay-lookup:visit",
      "ibank:visit",
      "primeanalytics:visit",
      "company-admin-menu:visit",
      "client-information:visit",
      "client-people:visit",
      "client-contracts:visit",
      "client-people-contacts:visit",
      "helpdesk:visit",
      "client-servicesfees-payment:visit",
      "client-servicesfees:visit",
      "client-report:visit",
      "client-technology-general:visit",
      "client-vpayhotel-card:visit",
      "client-integrations:visit",
      "client-consult:visit",
      "client-notes:visit",
      "client-log:visit",
      "employee-tools-menu:visit",
      "admin-search:visit",
      "agents-search:visit",
      "incomplete-profiles:visit",
      "airtinerary-agent-approval-mytasks:visit",
      "employee-carrier-contracts:visit",
      "gds-scripts:visit",
      "resource-center:visit",
      "tmu:visit",
      "user-profile:visit",
      "required-fields:visit",
      "user-search:visit",
      //Profile masked field visibility permissions for Sales Manager:
      "ownProfileField:unmask"
    ],
  },
  TravelManager: {
    static: [
      "savingstracker-scorecard:visit",
      "domo:visit", //who gets this?
      "primeanalytics:visit",
      "dashboard-menu:visit",
      "dashboard-manager:visit",
      "reporting-fields:visit",
      "dashboard-mytravel:visit",
      "trips-menu:visit",
      "airtinerary-lookup:visit",
      "current-future-trips:visit",
      "past-trips:visit",
      "unknownstays:visit",
      "hotel-checkins:visit",
      "book-travel-menu:visit",
      "travel-request:visit",
      "approval-request:visit",
      "manage-online-trips:visit",
      "travel-approval-menu:visit",
      "airtinerary-approval:visit",
      "airtinerary-approved:visit",
      "airtinerary-canceled:visit",
      "approval-beta:visit",
      "risk-management-menu:visit",
      "securitylogic-map:visit",
      "securitylogic-assistance:visit",
      "securitylogic-alerts:visit",
      "securitylogic-notifcations:visit",
      "reporting-menu:visit",
      "unused-tickets:visit",
      "airbank-tickets:visit",
      "airbank-lookup:visit",
      "savingstracker:visit",
      "vpay-lookup:visit",
      "ibank:visit",
      "incomplete-profiles:visit",
      "company-admin-menu:visit",
      "client-information:visit",
      "configure-approval:visit",
      "client-people:visit",
      "client-contracts:visit",
      "employee-tools-menu:visit",
      "helpdesk:visit",
      "client-servicesfees:visit",
      "client-report:visit",
      "client-vpayhotel-card:visit",
      "client-integrations:visit",
      "employee-tools-menu:visit",
      "user-profile:visit",
      "required-fields:visit",
      "user-search:visit",
       //Profile masked field visibility permissions for Traveler Manager:
       "ownProfileField:unmask",
       "dateOfBirth:unmask",
       "idCard.idCardNumber:unmask",
       "passports.passportNumber:unmask",
       "travelerNumber:unmask",
       "redressNumber:unmask"
    ],
  },
  Traveler: {
    static: [
      "dashboard-menu:visit",
      "dashboard-mytravel:visit",
      "trips-menu:visit",
      "current-future-trips:visit",
      "past-trips:visit",
      "unknownstays:visit",
      "book-travel-menu:visit",
      "travel-request:visit",
      "approval-request:visit",
      "manage-online-trips:visit",
      "travel-approval-menu:visit",
      "airtinerary-approval:visit",
      "airtinerary-approved:visit",
      "airtinerary-canceled:visit",
      "approval-beta:visit",
      "reporting-menu:visit",
      "airbank-tickets:visit",
      "user-profile:visit",
      "required-fields:visit",
      //Profile masked field visibility permissions for Traveler:
      "ownProfileField:unmask",
      "dateOfBirth:unmask",
      "idCard.idCardNumber:unmask",
      "passports.passportNumber:unmask",
      "travelerNumber:unmask",
      "redressNumber:unmask"
    ],
  },
};

export default permissions;
import { PhoneInput } from "../Input/PhoneInput";
import { phoneUtil } from "../../../util/phone-util";
import { Dispatch, SetStateAction } from "react";
import { Dialog, DialogTitle, Box, Typography, DialogContent, IconButton, DialogContentText, Button, makeStyles, Theme, createStyles } from "@material-ui/core";
import { Control, DeepMap, FieldErrors, Controller } from "react-hook-form";
import CloseIcon from "../CloseIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        textAlign: "center",
        padding: 10,
        width: 350,
      },
      "& .MuiDialogTitle-root": {
        marginTop: 6,
        padding: "16px 24px 0",
        fontSize: 24,
      },
      "& .MuiDialogContent-root": {
        padding: "0 24px",
      },
      "& .MuiTypography-body1": {
        lineHeight: 1.6,
        fontSize: "13px",
        color: "#4D4D4D",
      },
      "& .MuiTypography-h6": {
        fontWeight: 600,
      },
    },
    resendCode: {
      fontSize: 12,
      color: "#808080",
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

/**
 * Form definition so we can validate the phone the user enters
 */
 type VerificationInputs = {
    phone: string
  }

type ChangePhoneDialogProps = {
    openDialog: boolean,
    setOpenDialog: Dispatch<SetStateAction<boolean>>,
    control: Control<VerificationInputs>,
    errors: DeepMap<VerificationInputs, FieldErrors>,
    sendMobilePhoneVerification: () => Promise<void>
}

export function ChangePhoneDialog(props: ChangePhoneDialogProps) {
    const classes = useStyles();

    return <Dialog
        open={props.openDialog}
        onClose={() => {
        }}
        aria-labelledby="Verification dialog"
        aria-describedby="Verification dialog"
        className={classes.dialog}
    >
        <DialogTitle id="action-dialog">
            <Box pt={4} pb={1}>
                <Typography
                    component="p"
                    style={{ fontSize: 24, letterSpacing: -0.2 }}
                >
                    Edit your number
                </Typography>

            </Box>
        </DialogTitle>
        <DialogContent>
            {/* The 'x' icon on the modal. */}
            <IconButton id="closeIcon" aria-label="close" className={classes.closeButton} onClick={() => {
                props.setOpenDialog(false);
            }}>
                <CloseIcon />
            </IconButton>
            <DialogContentText id="action-dialog-text">
                <Box mt={3}>
                    <Controller
                        name="phone"
                        control={props.control}
                        rules={{
                            validate: {
                                value: (value: string) => {
                                    if (value && !phoneUtil.isPossibleNumber(value))
                                        return "Please enter a valid phone number"
                                }
                            }
                        }}
                        render={({ field: { onChange, value, onBlur } }) =>
                        (< PhoneInput
                            id="ff-phone-number"
                            label="New mobile number"
                            variant="outlined"
                            size="small"
                            defaultCountry="US"
                            international={false}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            error={props.errors.phone ? true : false}
                            InputProps={{ autoComplete: "off" }}
                        />)}
                    />
                </Box>
                <Box mt={4}>
                    <Button
                        onClick={() => { props.setOpenDialog(false); props.sendMobilePhoneVerification() }}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Submit
                    </Button>
                    <Box mb={3.5}>
                        <Typography className={classes.resendCode}>
                            <Box>
                                When you click “Submit”, your mobile number will
                                be updated in your profile and a new verification code will
                                be sent to you.
                            </Box>
                        </Typography>
                    </Box>
                </Box>
            </DialogContentText>
        </DialogContent>
    </Dialog>
}
 import "react-app-polyfill/ie9";
 import "react-app-polyfill/ie11";
 import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';


import { ThemeProvider } from '@material-ui/core/styles';
import Theme from './Theme';
import App from './App';
import * as FullStory from '@fullstory/browser';
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';

// Initialize FullStory with our orgId so that sessions are recorded and sent to the CBT FS dashboard
// devMode set to true means that a single event will be sent to FullStory indicating that the session is in devmode and that recording will not occur
// More info on initialization parameters can be found on the FullStory SDK GitHub: https://github.com/fullstorydev/fullstory-browser-sdk
FullStory.init({ orgId: '18PSHQ', devMode: Configuration.Environment === "Development" });

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <ThemeProvider theme={Theme}>
        <App/>
    </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

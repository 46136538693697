import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from "clsx";
import { Box, Button, Collapse, IconButton, Link, Typography } from '@material-ui/core';
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, } from "@material-ui/core";

import { EditIcon } from "../../../icons/Icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SortIcon from "../../../icons/SortIcon";
import Spinner from "../../../components/shared/Spinner";

const useStyles = makeStyles((theme: Theme) => ({
    activeBadge: {
        borderRadius: 4, background: '#ECF1E6', padding: '2px 6px', height: 'auto', textAlign: 'center', display: 'inline-flex',
        '& .MuiTypography-body1': {
            color: '#417505', fontSize: 11, textTransform: 'uppercase',
        }
    },
    inactiveBadge: {
        borderRadius: 4, background: 'rgba(201, 199, 199, .2)', padding: '2px 6px', height: 'auto', textAlign: 'center', display: 'inline-flex',
        '& .MuiTypography-body1': {
            color: '#808080', fontSize: 11, textTransform: 'uppercase',
        }
    },
    check: { marginTop: 4,},
    logo: {
        width: 20,
        height: 20,
        marginTop: 4,
    },
    dropdownTable: {
        "& .MuiTableCell-root": {
            borderTop: 'none',
            borderBottom: '1px solid #E0E0E0 !important',
            padding: '2px 0 !important',
        },
    },
    dropdownRow: {
        background: "#f9f9f9",
        borderBottom: '1px solid #E0E0E0',
        "& .MuiTableCell-sizeSmall": { 
            padding: 0, // this gets rid of the random padding the collapse row shows 
        },
    },
    borderTop: {
        borderTop: '1px solid #E0E0E0', // shows border top of dropdown row
    },
    noBorderTop: {
        borderTop: 'none', // hides it for the dropdown is collapsed, otherwise it shows double borders
    },
    dropdownHeader: { textTransform: 'uppercase', color: "#808080", fontSize: '.8rem', letterSpacing: -.05 },
    ellipses: {
        color: '#00467E',
        paddingLeft: 2,
        fontSize: '18px', 
        lineHeight: '15px',
        cursor: 'pointer',
    },
}));

interface CarDataRowProps {
    /** Code for the data */
    code: string,
    /** Car data name */
    name: string,
    /** Shows display position in dropdowns. 5 is default, 1 pushes it to the top  */
    displayTier: number,
    /** Type of data for Car (SIPP, Add-on) */
    referenceDataType: string,
    /** Car status (Active or Inactive) */
    isActive: boolean,
    /** Shows if GDS/third party codes are mapped to the Car */
    extraCodes: boolean,
}

/**
 * Component used to display information about the car data page.
 * 
 * @param props {@link CarDataRowProps Properties} for the `CarDataRow` component.
 * @returns A JSX element for displaying data information on the ReferenceData page.
 */
export default function CarDataRow(props: CarDataRowProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { code, name, displayTier, referenceDataType, isActive, extraCodes } = props;

    return (
        <>
            <TableRow key={name}>
                <TableCell>
                    <Box display="flex" flexDirection="row">
                        {code}{extraCodes ? <Box className={classes.ellipses} onClick={() => setOpen(!open)}>...</Box> : " "}
                    </Box>
                </TableCell>
                <TableCell component="th" scope="vendor">{name}</TableCell>
                <TableCell align="center">{displayTier}</TableCell>
                <TableCell>{referenceDataType}</TableCell>
                <TableCell align="center">
                    {isActive ?
                        (<Box className={classes.activeBadge}><Typography component="span">Active</Typography></Box>) : (<Box className={classes.inactiveBadge}><Typography component="span">Inactive</Typography></Box>)}
                </TableCell>
                <TableCell align="center">
                    <Box mt={-.5}>
                        <EditIcon color="#00467E" height="22" width="20" />
                    </Box>
                </TableCell>
                <TableCell align="center">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton></TableCell>
            </TableRow>

            {/* Dropdown row  */}
            <TableRow className={clsx(classes.dropdownRow, open ? classes.borderTop : classes.noBorderTop)}>
                <TableCell colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box py={2.5} px={3}>
                            <Table
                                aria-label="More info about the reference data"
                                className={classes.dropdownTable}
                                size="small"
                            >
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" width="175" className={classes.dropdownHeader} scope="row">
                                            Third party provider
                                        </TableCell>
                                        <TableCell component="th" className={classes.dropdownHeader} scope="row">
                                            Code
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{extraCodes ? "Amadeus" : "N/A"}</TableCell>
                                        <TableCell>{extraCodes ? "COMPACT" : "--"}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


import React from "react";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/HighlightOff";
import { useSnackbar, SnackbarKey } from "notistack";
import { ExceptionDetail } from '@cbtravel/common/lib/shared/common/exceptions/exception-detail';
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";

// https://iamhosseindhv.com/notistack

/**
 * A hook for accessing the Snackbar used for displaying messages to the user.
 * 
 * @returns Functions to interact with the Snackbar.
 */
export function useCustomSnackbar() {

  const { closeSnackbar: clear, enqueueSnackbar } = useSnackbar();

  /**
   * A formatted action button to add to the error snackbar options
   * 
   * @param key Unique identifier to reference a snackbar.
   * @returns A JSX element to act as a close button.
   */
  function closeActionButton(key: SnackbarKey) {
    return (
      <Button onClick={() => clear(key)}>
        <CloseIcon />
      </Button>
    );
  }

  /**
   * Triggers an error snackbar.
   * 
   * @param error A JsonException to be formatted into a snackbar
   * @returns (void) 
   */
  function error(error: JsonException) {
    if (!error) return;
    const { exceptionLevel, uniqueID, message, exceptionDetailList } = error
    let errorDetail: string = "";

    //format exceptionDetailList into a readable string
    if (exceptionDetailList && exceptionDetailList.length > 0) {
      exceptionDetailList.forEach(function (err: ExceptionDetail) {
        //only display err.message => we'll fix the ones that aren't unique in the back end
        return errorDetail += `\n ${err.message}`
      })
    }

    // put all the formatted strings together and update error.message for easier snackbar handling
    let snackMessage =
      `${message} ${errorDetail}`;


    if (Configuration.DisplayRaygunGUID && uniqueID) {
      snackMessage += `\n Unique ID: ${uniqueID}`
    }

    let variant: "default" | "error" | "success" | "warning" | "info" | undefined = "default"; //this is how variant is typed in notistack so you have to define it that way
    if (exceptionLevel && exceptionLevel.code < 1000) {
      variant = "default"   // not an exception (black)
    } else if (exceptionLevel && exceptionLevel.code < 2000) {
      variant = "info"      // informational exceptions (blue)
    } else if (exceptionLevel && exceptionLevel.code < 3000) {
      variant = "warning"   // warning exceptions (yellow)
    } else if (exceptionLevel && exceptionLevel.code < 4000) {
      variant = "error"     // critical exceptions (red)
    }

    enqueueSnackbar(snackMessage, // Outputs error message
      {
        // persist: true, // This keeps the snackbar persistent and doesn't autohide after X time
        id: `Snackbar-${variant}-${exceptionLevel.code}`,
        variant: variant,
        // preventDuplicate: true,
        style: { whiteSpace: 'pre-line' },  // this makes the newline's work right
        autoHideDuration: Configuration.SnackTime.error,
        action: closeActionButton
      }
    );

  }

  /**
   * Triggers an info snackbar.
   * 
   * @param message A string message to populate the snackbar with
   * @returns void
   */
  function info(message: string) {
    if (!message) return;
    enqueueSnackbar(message, // Outputs error message
      {
        id: `Snackbar-info-${message}`,
        autoHideDuration: Configuration.SnackTime.info,
        style: { whiteSpace: 'pre-line' }  // this makes the newline's work right
      }
    );
  }

  // Calling clear with no argument is the same as calling closeSnackbar(), i.e. it will close all open snackbars
  return { error, clear, info };
};
import { Box, Divider, Grid, Link, MenuItem, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { RemoveCircleOutline as RemoveIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useContext, useEffect, useState } from "react";
import { Control, Controller, DeepMap, FieldErrors, UseFieldArrayReturn, UseFormClearErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";

import { RegionController } from '@cbtravel/common/lib/shared/api/general/controllers/region-controller';
import { RegionTypeSearchType } from "@cbtravel/common/lib/shared/common/enumerations/search-types";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { PagedList } from '@cbtravel/common/lib/shared/common/paged-list';
import { RegionRQ } from "@cbtravel/common/lib/shared/messages/general/requests/region-rq";
import { RegionRS } from "@cbtravel/common/lib/shared/messages/general/responses/region-rs";
import { useCustomSnackbar } from '../../../../components/shared/customHooks/useCustomSnackbar';
import Spinner from '../../../../components/shared/Spinner';
import ActionDialog from "../../../../components/ui/Dialog/ActionDialog";
import { formUtils } from "../../../../util/form-utils";
import { AddressFields, ProfileFormInputs } from "../Profile";
import { UserContext } from "../../../../components/shared/UserContext";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        inputWrapper: {
            "& .MuiDivider-root": {
                marginBottom: theme.spacing(3),
            },
        },
        h3: {
            fontSize: 12,
            fontWeight: 300,
            textTransform: "uppercase",
            paddingTop: 10,
            marginBottom: -4,
            display: "block",
        },
        accordion: {
            "& .MuiFormHelperText-root": {
                fontSize: 11,
                color: "#808080",
            },
            "& .MuiInputBase-input": {
                background: "#fff",
            },
            "& .MuiInputBase-root": {
                background: "#fff",
            },
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: 0
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
            },
        },
        addIcon: {
            color: "#00467E",
            marginTop: -4,
        },
        removeIcon: {
            color: "#BC2C2F",
        },
        addText: {
            "& .MuiLink-button": {
                fontSize: 12,
                fontFamily: "Open Sans, Arial, sans-serif",
                fontWeight: 600,
                color: "#00467E",
                marginTop: 0,
                textDecoration: "none",
            },
            "& .MuiSvgIcon-root": {
                marginBottom: -1.5,
                marginRight: -1,
                fontSize: 13,
            },
        },
        removeWrapper: {
            marginTop: -36,
            marginBottom: -36,
            "& .MuiLink-button": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "Open Sans, Arial, sans-serif",
                color: "#BC2C2F",
                textDecoration: "none",
            },
        },
        removeText: {
            "& .MuiSvgIcon-root": {
                marginRight: -1,
                marginBottom: -1.5,
                fontSize: "12px !important",
            },
        },
        whiteBG: {
            background: "#fff",
        },
        flex: {
            display: "flex",
            flexDirection: "column",
            marginLeft: 3,
        },
        h2: {
            fontSize: 13,
            fontWeight: 600,
            lineHeight: 1.5,
        },
        summary: {
            fontSize: 12,
        },
        errorTextField: {
            paddingTop: '5px',
        },
        errorTextCalendar: {
            paddingTop: '2px',
        },
    })
);

interface AddressFieldsProps {
    field: AddressFields & { id: string },
    index: number,
    control: Control<ProfileFormInputs>,
    countries: RegionRS[],
    controlAddresses: Array<AddressFields & { id: string }>,
    addressFieldArray: UseFieldArrayReturn<ProfileFormInputs, "addresses", "id">,
    errors: DeepMap<ProfileFormInputs, FieldErrors>,
    register: UseFormRegister<ProfileFormInputs>,
    addressTypes: Array<string>,
    updateAddressTypes: () => void,
    setValue: UseFormSetValue<ProfileFormInputs>,
    getValues: UseFormGetValues<ProfileFormInputs>,
    clearErrors: UseFormClearErrors<ProfileFormInputs>,
    viewingOtherProfile: boolean
}


export default function AddressFormFields(props: AddressFieldsProps) {
    const classes = useStyles();
    const snackbar = useCustomSnackbar();
    const { field, index, control, controlAddresses, addressFieldArray, errors, register, viewingOtherProfile } = props;
    const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
    const [stateProvinces, setStateProvinces] = useState<RegionRS[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const { userContext } = useContext(UserContext);

    const addressValidationRules = {
        address1: {
            validate: {
                required: (value: string) => {
                    if ((viewingOtherProfile && userContext.userType < UserType.TravelManager) && !areFieldsEmpty() && value.trim().length === 0) {
                        return "Please enter a valid address";
                    }
                    return true;
                }
            },
            maxLength: { value: 255, message: 'Max 255 characters' }
        },
        address2: {
            maxLength: { value: 255, message: 'Max 255 characters' }
        },
        country: {
            validate: {
                required: (value: RegionRS) => {
                    if ((props.viewingOtherProfile && userContext.userType < UserType.TravelManager) && !areFieldsEmpty() && (!value || value.regionId === -1)) {
                        return "Please select a valid country";
                    }
                }
            }
        },
        city: {
            validate: {
                required: (value: string) => {
                    if ((props.viewingOtherProfile && userContext.userType < UserType.TravelManager) && !areFieldsEmpty() && value.trim().length === 0)
                        return "Please enter a city";
                }
            },
            maxLength: { value: 255, message: 'Max 255 characters' }
        },
        state: {
            validate: {
                required: (value: RegionRS) => {
                    if ((props.viewingOtherProfile && userContext.userType < UserType.TravelManager) && !areFieldsEmpty() && !value) {
                        return "Please select a valid state/province for selected country" // invalid!
                    } else {
                        const a = props.getValues(`addresses.${index}` as `addresses.0`);
                        if (!a.country)
                            return true;
                        const country = a.country as RegionRS;
                        const v = value as RegionRS;
                        if (country.regionId > 0 && stateProvinces && stateProvinces.length > 0) {  // we have a country and a list of provinces
                            if (stateProvinces?.some(p => country.regionId === p.parentRegionId) // and at least one state/province recognizes that country as its parent region
                                && v.parentRegionId // and we have a parentRegionId on our selected state
                                && v.parentRegionId > 0 // and it's a valid parentRegionId
                                && v.parentRegionId === country.regionId // and that parentRegionId matches the selected country
                            ) {
                                return true; // outcome still true
                            } else {
                                return "Please select a valid state/province for selected country" // invalid!
                            }
                        }
                    }
                }
            }
        },
        postalCode: {
            maxLength: {
                value: 10, message: 'Max 10 characters'
            }
        }
    };

    /**
     * Determines if any part of this address has been filled out.
     * 
     * @returns True if the address has no filled out fields, false otherwise.
     */
    function areFieldsEmpty(): boolean {
        let address = controlAddresses[index];

        return (address.address1.length === 0 &&
            address.address2.length === 0 &&
            address.city.length === 0 &&
            address.postalCode.length === 0 &&
            (!address.stateProvince ||
                address.stateProvince.regionId === -1) &&
            (!address.country ||
                address.country.regionId === -1));
    }

    /**
     * Handles changing the addresss's country in the form.
     * @param value The new country to store in the form object.
     */
    async function handleChangeCountry(value: RegionRS | null) {
        if (value) {
            setShowSpinner(true);
            setStateProvinces([]);

            props.setValue(`addresses.${index}.country` as `addresses.0.country`, value)

            let regionRQ = new RegionRQ();
            regionRQ.regionTypes.push(RegionTypeSearchType.Subdivision);
            regionRQ.regionTypes.push(RegionTypeSearchType.District);
            regionRQ.parentRegionId = value.regionId;
            try {
                const provinceList: PagedList<RegionRS> = await RegionController.Find(regionRQ);

                if (provinceList.list.length <= 0) {
                    // remove possible existing errors if there are no provinces to choose from
                    props.clearErrors(`addresses.${index}.stateProvince` as `addresses.0.stateProvince`);
                }

                setStateProvinces(provinceList.list);
                props.setValue(`addresses.${index}.stateProvince` as `addresses.0.stateProvince`, new RegionRS())
            }
            catch (e) {
                snackbar.error(e as JsonException);
            }
            finally {
                setShowSpinner(false);
            }
        }
    }

    useEffect(() => {
        //on mount: populates the state/province drop down with the correct values if this field has a country
        async function getFirstStateProvinces(parentRegionId: number) {
            let region = new RegionRQ();
            region.regionTypes.push(RegionTypeSearchType.Subdivision);
            region.regionTypes.push(RegionTypeSearchType.District);
            region.parentRegionId = parentRegionId;
            try {
                const provinceList: PagedList<RegionRS> = await RegionController.Find(region);
                setStateProvinces(provinceList.list);
            }
            catch (e) {
                snackbar.error(e as JsonException);
            }

        }
        if (props.field.country) {
            const country = props.field.country as RegionRS;
            if (country.regionId && country.regionId > 0) {
                getFirstStateProvinces(country.regionId);
            }
        }
    }, [])

    return (
        <Grid container spacing={3} key={field.id} id={`grid-container-${index}`}>
            {showSpinner && <Spinner />}
            {/* Display dialog to remove item */}
            <ActionDialog
                id={`dialog-discard-${index}`}
                title={`Remove ${field.addressType} address?`}
                icon={<div className={useStyles().removeIcon}><RemoveIcon /></div>}
                positiveText="Remove"
                negativeText="Cancel"
                dialogType="delete"
                open={showRemoveDialog}
                setOpen={setShowRemoveDialog}
                positiveAction={() => addressFieldArray.remove(index)}
            >
                <p>You will be permanently removing this address from your profile.</p>
            </ActionDialog>
            <Grid item xs={12} sm={12} md={4} lg={4} id={`grid-item-${index}`}>
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`addresses.${index}.addressType` as const}
                        control={props.control}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <TextField
                                id={`ff-address-type-${index}`}
                                select
                                label="Address type"
                                defaultValue={field.addressType}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(e) => { onChange(e); props.updateAddressTypes(); }}
                                value={value}
                                ref={ref}
                                onBlur={onBlur}
                                InputProps={{ autoComplete: "off" }}
                            >
                                {props.addressTypes?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )} />
                </form>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} id={`grid-item-${index}`}>
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`addresses.${index}.address1` as const}
                        control={control}
                        rules={addressValidationRules.address1}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <TextField
                                id={`ff-address1-${index}`}
                                label="Street address 1"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                                ref={ref}
                                error={props.errors.addresses?.[index]?.address1 ? true : false}
                                InputProps={{ autoComplete: "off" }}
                            />
                        )} />
                </form>
                <Typography variant='body2' color='error' className={classes.errorTextField} id={`typo-2`}>
                    {props.errors?.addresses && props?.errors?.addresses?.[index]?.address1?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} id={`grid-item-${index}`}>
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`addresses.${index}.address2` as const}
                        control={control}
                        rules={addressValidationRules.address2}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <TextField
                                id={`ff-address2-${index}`}
                                label="Street address 2"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                                ref={ref}
                                error={props.errors.addresses?.[index]?.address2 ? true : false}
                                InputProps={{ autoComplete: "off" }}
                            />
                        )} />
                </form>
                <Typography variant='body2' color='error' className={classes.errorTextField} id={`typo-2`}>
                    {props.errors?.addresses && props?.errors?.addresses?.[index]?.address2?.message}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} id={`grid-item-${index}`}>
                <Controller
                    name={`addresses.${index}.country` as const}
                    control={control}
                    rules={addressValidationRules.country}
                    render={({
                        field: { onChange, value, ref, onBlur }
                    }) => (
                        <Autocomplete
                            id={`ac-address-country-${index}`}
                            options={props.countries}
                            getOptionLabel={(option) => option.name ? option.name : ""}
                            getOptionSelected={(option, value) => option.regionId === value.regionId || value.regionId == -1}
                            fullWidth
                            autoSelect
                            autoHighlight
                            value={value ? value : new RegionRS()}
                            ref={ref}
                            onBlur={onBlur}
                            onChange={(q, change) => {
                                handleChangeCountry(change)
                                return onChange(change)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id={`ff-address-country-${index}`}
                                    label="Country"
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.whiteBG}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={props.errors.addresses?.[index]?.country ? true : false}
                                />
                            )}
                        />
                    )} />
                <Typography variant='body2' color='error' className={classes.errorTextCalendar} id={`typo-2`}>
                    {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                    {props.errors?.addresses && (props?.errors?.addresses?.[index]?.country as any)?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} id={`grid-item-${index}`}>
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`addresses.${index}.city` as const}
                        control={control}
                        rules={addressValidationRules.city}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <TextField
                                id={`ff-address-city-${index}`}
                                label="City"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                                ref={ref}
                                error={props.errors.addresses?.[index]?.city ? true : false}
                                InputProps={{ autoComplete: "off" }}
                            />
                        )} />
                </form>
                <Typography variant='body2' color='error' className={classes.errorTextField} id={`typo-2`}>
                    {props.errors?.addresses && props?.errors?.addresses?.[index]?.city?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} id={`grid-item-${index}`}>
                <Box mt={-1} id={`box-${index}`}>
                    <Controller
                        name={`addresses.${index}.stateProvince` as const}
                        control={control}
                        rules={addressValidationRules.state}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <Autocomplete
                                disabled={(stateProvinces && stateProvinces.length <= 0) ? true : false}
                                id={`ac-address-state-${index}`}
                                options={stateProvinces}
                                getOptionLabel={(option) => option.name ? option.name : ""}
                                // getOptionSelected={(option, value) => option.regionId === value.regionId || value.regionId == -1}
                                getOptionSelected={(option, value) => option.regionId === value?.regionId}
                                fullWidth
                                autoSelect
                                autoHighlight
                                defaultValue={new RegionRS()}
                                value={value ? (value as RegionRS) : new RegionRS()}
                                ref={ref}
                                onBlur={onBlur}
                                onChange={(q, change) => onChange(change)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id={`ff-address-state-${index}`}
                                        label="State/Province"
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.whiteBG}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        error={props.errors.addresses?.[index]?.stateProvince ? true : false}
                                    />
                                )}
                            />
                        )} />
                </Box>
                <Typography variant='body2' color='error' id={`typo-2`}>
                    {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                    {props.errors?.addresses && (props?.errors?.addresses?.[index]?.stateProvince as any)?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} id={`grid-item-${index}`}>
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`addresses.${index}.postalCode` as const}
                        control={control}
                        rules={addressValidationRules.postalCode}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <TextField
                                id={`ff-address-postal-code-${index}`}
                                label="Postal code"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                                ref={ref}
                                error={props.errors.addresses?.[index]?.postalCode ? true : false}
                                InputProps={{ autoComplete: "off" }}
                            />
                        )} />
                </form>
                <Typography variant='body2' color='error' id={`typo-2`}>
                    {props.errors?.addresses && props?.errors?.addresses?.[index]?.postalCode?.message}
                </Typography>
            </Grid>

            {/* Don't allow removal of final element */}
            {controlAddresses.length > 1 &&
                <Grid
                    container
                    justify="flex-end"
                    className={classes.removeWrapper}
                    id={`grid-container-${index}`}
                >
                    <Box component={"div"} className={classes.removeText} id={`box-${index}`}>
                        <Typography align="right" id={`typo-${index}`}>
                            <Link href="#" component="button" color="error" id={`link-removeDialog`}
                                onClick={() => {
                                    setShowRemoveDialog(true);
                                }}>
                                <RemoveIcon className={classes.removeIcon} /> Remove
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
            }
            {controlAddresses.length > 1 &&
                index != controlAddresses.length - 1 &&
                <Grid className={classes.inputWrapper} item xs={12} sm={12} md={12} lg={12} id={`grid-item-${index}`}>
                    <Divider />
                </Grid>
            }
        </Grid>
    )

}


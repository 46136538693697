import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, FormControlLabel, Grid, InputAdornment, OutlinedInput, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { Delete } from "@material-ui/icons";

import UserSearchBox from '../../../components/shared/UserSearchBox';
import { useStyles } from './ConfigureApprovalTypes';
import RemoveLevelDialog from "./dialogs/RemoveLevelDialog";
import { ApprovalGroupRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-group-rs';
import { ApprovalGroupType } from '@cbtravel/common/lib/shared/common/enumerations/approval-group-type';
import { UserLT } from '@cbtravel/common/lib/shared/messages/general/responses/lite/user-lt';
import { UserRS } from '@cbtravel/common/lib/shared/messages/general/responses/user-rs';
import { ApprovalGroupApproverRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-group-approver-rs';

import AddManagerDialog from './dialogs/AddManagerDialog';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { ApprovalRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-rs';
import { ApprovalType } from "@cbtravel/common/lib/shared/common/enumerations/approval-type";

interface ApproverEmailSearchBoxProps {
  /** Whether or not this component should autofocus. */
  focus?: boolean,
  /** The number of users in this group. */
  groupCount: number,
  /** Object representing the user held by this component. */
  user: UserLT,
  /** Function to execute when the value held by this component changes. */
  onChange: (oldUserId: number, newUser: UserLT) => void,
  /** Function to execute when this user should be removed as an approver. */
  removeApprover: (userId: number) => void,
}

interface ConfigureApprovalTypesLevelProps {
  /** The total number of approval group levels. */
  levelCount: number,
  /** The index of this specific approval group in the approval group array. */
  groupIndex: number,
  /** Object representing this approval group. */
  group: ApprovalGroupRS,
  /** Function to execute when this group has been modified. */
  setGroup: (group: ApprovalGroupRS, index: number) => void,
  /** Function to execute when this group should be removed. */
  removeLevel: () => void,
  /** Boolean props to inform if a manager approver is applied to any of the approval group */
  isManagerApproverApplied: boolean,
  /** Boolean prop that contains Active Status of the manager (udid 38) custom field. Undefined means it's never configured*/
  isManagerCustomFieldActive: boolean | undefined,
  /** Object representing the approval configuration being modified. */
  currentApproval: ApprovalRS | undefined,
  /** Boolean prop to track whether the manager dialog has popped and accepted in this current progress */
  isManagerFieldApplied: boolean
}

export default function ConfigureApprovalTypesLevel(props: ConfigureApprovalTypesLevelProps): JSX.Element {
  const classes = useStyles();
  const [showRemoveLevelDialog, setShowRemoveLevelDialog] = useState<boolean>(false);

  // create a map of users using the approvalGroupList from the group prop
  let groupUsers: Map<number, UserLT> = new Map<number, UserLT>();
  props.group.approvalGroupApproverList.forEach((approver) => {
    groupUsers.set(approver.user.userId, approver.user);
  });

  /**
   * Component used to edit and/or remove a user in an approval level.
   * 
   * @param props {@link ApproverEmailSearchBoxProps Properties} for an `ApproverEmailSearchBox`.
   * @returns A JSX element used for approval group users.
   */
  function ApproverEmailSearchBox(props: ApproverEmailSearchBoxProps): JSX.Element {

    // populate with existing user if given one, otherwise make a new one
    const [currentUser, setCurrentUser] = useState<UserLT | UserRS>(props.user);

    /**
     * Executes when the user held by this `ApprovalEmailSearchBox` changes.
     */
    function handleUserChange(newUser: UserLT | UserRS | null) {
      if (!newUser) {
        newUser = new UserLT();
      }
      if (currentUser.userId !== newUser.userId) {
        props.onChange(currentUser.userId, newUser);
        setCurrentUser(newUser)
      }
    }

    return (
      <Box display="flex" flexDirection="row">
        <Grid item lg={8} md={8} sm={8} xs={8}>
          <UserSearchBox
            focus={props.focus}
            label="Designated approver"
            user={currentUser}
            onChange={handleUserChange}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Button
            variant="contained"
            className={classes.confirmationUserRemove}
            onClick={() => props.removeApprover(currentUser.userId)}
          >
            <Delete />
          </Button>
        </Grid>
      </Box>
    );
  }
  /**
   * Used to determine if the list approvalGroupApprovers in props.group matches the list
   * of UserLTs being managed by this component.
   * 
   * @returns True if the users in `props.approvalGroupApprovers` are equal to the current values. False otherwise.
   */
  function groupUsersEqualsGroup(): boolean {
    if (groupUsers.size !== props.group.approvalGroupApproverList.length) {
      return false;
    }

    var userMatches = 0;
    props.group.approvalGroupApproverList.forEach((g) => {
      if (!groupUsers.has(g.user.userId)) {
        return false;
      } else {
        userMatches++;
      }

    });

    if (userMatches !== groupUsers.size) {
      return false;
    }

    return true;
  }

  /**
   * Collects the users in this component and returns an ApprovalGroupRS populated with
   * the users and group data.
   * 
   * @param users Map of users to convert to an `ApprovalGroupRS`.
   * @returns An `ApprovalGroupRS` populated with data from `users`.
   */
  function usersToApprovalGroupRS(users: Map<number, UserLT>): ApprovalGroupRS {
    let approvalGroupRS: ApprovalGroupRS = Object.assign({}, props.group);
    let approvers: ApprovalGroupApproverRS[] = [];
    approvalGroupRS.approvalGroupType = props.group.approvalGroupType;

    // constructing approvalGroupRS from the users in the map being passed
    for (let user of users.values()) {

      let approver = new ApprovalGroupApproverRS();
      approver.approvalGroupId = approvalGroupRS.approvalGroupId;
      approver.user = user;
      approver.order = 0;
      approvers.push(approver);
    }

    approvalGroupRS.approvalGroupApproverList = approvers;
    return approvalGroupRS;
  }

  /**
   * Adds a new user for the group.
   */
  function addApprover() {

    // make new, uninitialized user
    let newUser: UserLT = new UserLT();

    // add it to a new clone of the user map
    let newGu: Map<number, UserLT> = new Map<number, UserLT>(groupUsers);
    newGu.set(newUser.userId, newUser);

    // create an approvalGroupRS from the new user map and update backing state
    let approvalGroupRS: ApprovalGroupRS = usersToApprovalGroupRS(newGu);
    props.setGroup(approvalGroupRS, props.groupIndex);
  }

  /**
   * Adds Traveler's manager as an approver at this level
   */
  function addManagerApprover() {
    //update ApprovalGroupRS with 
    let approvalGroupRS: ApprovalGroupRS = props.group;
    approvalGroupRS.isManagerApprover = true;
    props.setGroup(approvalGroupRS, props.groupIndex);
  }

  /**
   * Remove Traveler's manager as an approver at this level
   */
  function removeManagerApprover() {
    let approvalGroupRS = props.group;
    approvalGroupRS.isManagerApprover = false;
    props.setGroup(approvalGroupRS, props.groupIndex);
  }

  /**
   * Removes a user from the group.
   */
  function removeApprover(userId: number) {
    let newGu: Map<number, UserLT> = new Map<number, UserLT>(groupUsers);
    newGu.delete(userId);

    // create an approvalGroupRS from the new user map and update backing state
    let approvalGroupRS: ApprovalGroupRS = usersToApprovalGroupRS(newGu);
    props.setGroup(approvalGroupRS, props.groupIndex);
  }

  /**
   * When a user changes the value of an approver search box, ensure that the change is reflected
   * in the state object that backs the users for this group.
   */
  function onApproverChange(oldUserId: number, newUser: UserLT) {

    // make a clone of the current group users and delete user with oldUserId, add new user
    let newGu: Map<number, UserLT> = new Map<number, UserLT>(groupUsers);
    newGu.delete(oldUserId);
    newGu.set(newUser.userId, newUser);

    // create an approvalGroupRS from the new user map and update backing state
    let approvalGroupRS: ApprovalGroupRS = usersToApprovalGroupRS(newGu);
    props.setGroup(approvalGroupRS, props.groupIndex);
  }

  /**
   * Used when a user changes the value of the group type to ensure that the change is reflected
   * in the state object that backs the group type.
   */
  function onGroupTypeChange(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    let approvalGroupTypeKey = value as keyof typeof ApprovalGroupType;

    // create an approvalGroupRS from the users and change the approval group type before updating backing state
    let approvalGroupRS: ApprovalGroupRS = usersToApprovalGroupRS(groupUsers);
    approvalGroupRS.approvalGroupType = ApprovalGroupType[approvalGroupTypeKey];

    props.setGroup(approvalGroupRS, props.groupIndex);
  }

  /**
   * populate current users with any that already exist in the configuration
   */
  useEffect(() => {

    // if group prop approvers aren't equivalent to approver UserLTs on this level component, pull them in
    if (!groupUsersEqualsGroup()) {

      let currentUsers: Map<number, UserLT> = new Map<number, UserLT>();

      // if there are users in the group pull them in, otherwise initialize a new new user
      if (props.group.approvalGroupApproverList.length > 0) {
        props.group.approvalGroupApproverList.forEach((a) => {
          currentUsers.set(a.user.userId, a.user);
        });
      } else {
        let newUser = new UserLT();
        currentUsers.set(newUser.userId, newUser);
      }
    }
  }, [props]);

  return (
    <Grid container={true} spacing={2} alignContent="flex-end" className={classes.root}>
      {/* TODO: only show this section for User Enrollment: START*/}
      <Grid item={true} lg={9} md={9} sm={12} xs={12}>
        {/* <div className="header-aligned">
          <Typography variant="h2" className={classes.levelHeader}>
            Define domains for automatic approval
          </Typography>
        </div>
        <Box pt={1}>
          <div style={{ borderBottom: ".5px solid #000", display: "block" }}></div>
        </Box>
        <Box pt={2} pb={1}>
          <label className={classes.legend}>What email domains should be automatically approved?</label>
        </Box> */}
        {/* //TODO: Show when user clicks "add another" btn
        <Box display="flex" flexDirection="row" mb={1}>
          <Grid lg={8} md={8} sm={8} xs={8}>
            <OutlinedInput
              fullWidth margin="dense"
              value="Email domain" />
          </Grid>

          <Grid lg={2} md={2} sm={2} xs={2}>
            <Box mt={-1}>
              <Button
                variant="contained"
                className={classes.confirmationUserRemove}
                onClick={removeManagerApprover}
              >
                <Delete />
              </Button>
            </Box>
          </Grid>
        </Box> */}
        {/* <Box pb={4}>
          <Button
            variant="outlined"
            className={classes.btnAddAnother}
            // onClick={addApprover}
          //TODO: change onClick to add another email domain
          >
            Add another
          </Button>
        </Box> */}
      </Grid>
      {/* TODO: only show this section for User Enrollment: END .*/}
      {/* <Grid item={true} lg={9} md={9} sm={12} xs={12}>
        <Box>
          <label className={classes.legend}>Define approvers for domains not specified above.</label>
        </Box>
      </Grid> */}
      <Grid item={true} lg={9} md={9} sm={12} xs={12}>
        <div className="header-aligned">
          <Box mt={-1}>
          <Typography variant="h2" className={classes.levelHeader}>
            Level {props.group.level}
          </Typography>
          </Box>
          {props.levelCount > 1 && (
            <>
              <Button
                size="small"
                className={classes.levelRemove}
                onClick={() => setShowRemoveLevelDialog(true)}
              >
                Remove
              </Button>
              <RemoveLevelDialog
                open={showRemoveLevelDialog}
                positiveAction={props.removeLevel}
                setOpen={setShowRemoveLevelDialog}
              />
            </>
          )}
        </div>
        <Box pt={1}>
          <div style={{ borderBottom: ".5px solid #000", display: "block" }}></div>
        </Box>
      </Grid>



      {/* Add approvers */}
      <Grid item={true} lg={12} md={12} sm={12} xs={12}>
        <Box pt={1} pb={2}>
          <label className={classes.legend}>Who are the approvers?</label>
        </Box>
        {/* Manager Approver Box */}
        {props.group.isManagerApprover && props.isManagerApproverApplied && props.currentApproval?.approvalType !== ApprovalType.UserEnrollment &&
          <Box display="flex" flexDirection="row" mb={1}>
            <Grid lg={8} md={8} sm={8} xs={8}>
              <OutlinedInput
                style={{ background: "#f3f3f3" }}
                fullWidth margin="dense"
                value="Traveler's manager"
                endAdornment={<InputAdornment position="end"><LockOutlinedIcon style={{ width: 20 }} /></InputAdornment>}
                disabled />
            </Grid>

            <Grid lg={2} md={2} sm={2} xs={2}>
              <Box mt={-1}>
                <Button
                  variant="contained"
                  className={classes.confirmationUserRemove}
                  onClick={removeManagerApprover}
                >
                  <Delete />
                </Button>
              </Box>
            </Grid>
          </Box>
        }
        {[...groupUsers.values()].map((user) => {
            return (
              <ApproverEmailSearchBox
                key={user.userId}
                user={user}
                groupCount={groupUsers.size}
                onChange={onApproverChange}
                removeApprover={removeApprover}
              />
            );
        })}
        <Box display="flex" flexDirection="row">
          <Box mr={1}>
            <Button
              variant="outlined"
              className={classes.btnAddAnother}
              onClick={addApprover}
            >
              Add
            </Button>
          </Box>
          {!props.isManagerApproverApplied && props.currentApproval?.approvalType !== ApprovalType.UserEnrollment &&
            <AddManagerDialog
              isManagerFieldApplied={props.isManagerApproverApplied || props.isManagerFieldApplied}
              isManagerCustomFieldActive={props.isManagerCustomFieldActive}
              onClickPositiveAction={addManagerApprover} />}
        </Box>
      </Grid>

      {/* Group type */}
      <Box pt={3.5} pl={1}>
        <Grid item={true} lg={12} md={12} sm={12} xs={12}>
          <Box pb={1}>
            <label className={classes.legend}>{`Which approvers from level ${props.group.level} are required?`}</label>
          </Box>

          <RadioGroup
            aria-label="Approval configuration"
            name="approvalConfiguration"
            value={props.group.approvalGroupType}
            onChange={onGroupTypeChange}
          >
            <FormControlLabel
              label="Any single approver"
              value={ApprovalGroupType.Any}
              control={<Radio size="small" />}
            />
            <FormControlLabel
              label="Each approver"
              value={ApprovalGroupType.All}
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>
      </Box>
    </Grid>
  );
}


import { useEffect, useState } from 'react';

import { UserType } from '@cbtravel/common/lib/shared/common/enumerations/user-type';
import { IconButton, TextField, TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { check } from '../../shared/Can';
import permissions from '../../shared/Permissions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputDisabled: {
            "& .MuiSelect-icon": {
                display: 'none',
            },
            "& .MuiInputBase-input.Mui-disabled": {
                color: '#808080',
            },
            "& .MuiIconButton-root.Mui-disabled": {
                marginRight: -13,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
                color: '#999999'
            },
        },
    }));

/**
 * MaskedTextFieldProps includes all the properties needed from TextFieldProps 
 * and 
 * visibility permission props which require a UserType and the action `perform` to determine and control visibility of the field.
 */
type MaskedTextFieldProps = TextFieldProps & {
    /** The name of the role. */
    userType: UserType,
    /** The name of the action to check permission for. */
    action: string,
}

/**
 * MaskedTextField component is a wrapper around the MUI TextField component 
 * that also provides UI ability to mask and unmask the field value determined by the type of user 
 * and a set of predefined visibility permissions.
 * @param props -- combined visibility permission props and MUI TextFieldProps,
 */
export default function MaskedTextField(props: MaskedTextFieldProps) {
    const classes = useStyles();
    /** boolean state to control whether the content of the field is masked (Visible)-- 
     * by default, it is masked (not visible) if value exists
     * if the a default value doesn't exist the field is visible */
    const [fieldVisibility, setFieldVisibility] = useState<boolean>(() => {
        if (!props.value)
            return true;
        return false;
    });
    /** boolean state whether the user can unmask (reveal) the field */
    const [canReveal, setCanReveal] = useState<boolean>(() => check(permissions, props.userType, props.action));

    /** Omit props that require modifications for masking */
    const { InputProps, disabled, className, type, ...textFieldProps } = props;

    // UseEffect to update visibility state when userType or action props changed
    useEffect(() => {
        setCanReveal(check(permissions, props.userType, props.action));
    }, [props.userType, props.action]);
    
    return (
        <>
            <TextField
                {...textFieldProps}
                type={fieldVisibility ? "text" : "password"}
                disabled={canReveal ? false : true}
                className={canReveal ? "" : classes.inputDisabled}
                InputProps={{
                    autoComplete: "off",
                    endAdornment: (
                        <>
                            {canReveal &&
                                <>
                                    {/* Visibility Button*/}
                                    <IconButton
                                        onClick={() => setFieldVisibility(!fieldVisibility)}
                                    >
                                        {fieldVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>

                                </>
                            }
                            {!canReveal &&
                                <>
                                    <LockOutlinedIcon
                                        className={classes.inputDisabled}
                                        style={{ marginRight: 12 }}
                                    />
                                </>
                            }
                        </>
                    )
                }}

            />
        </>);
}
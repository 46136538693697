import React, { useEffect, useState, useContext } from "react";
import CustomFieldDataRow from './CustomFieldDataRow';
import clsx from "clsx";
import {
  Box,
  Typography,
  Tooltip,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from '../../../components/shared/UserContext'
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import Spinner from '../../../components/shared/Spinner'
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { PagedList } from "@cbtravel/common/lib/shared/common/paged-list";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { EntityDepth } from '@cbtravel/common/lib/shared/common/enumerations/entity-depth'
import { CustomFieldController } from '@cbtravel/common/lib/shared/api/general/controllers/custom-field-controller'
import { CustomFieldRQ } from '@cbtravel/common/lib/shared/messages/general/requests/custom-field-rq'
import { CustomFieldRS } from '@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs'
import { ClientRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-rs";
import { CustomFieldSortType } from "@cbtravel/common/lib/shared/common/enumerations/sort-types";
import { CustomFieldAccessibilitySearchType, CustomFieldSourceSearchType } from "@cbtravel/common/lib/shared/common/enumerations/search-types";
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";
import { CustomFieldSource } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-source";
import MultipleSelectChips, { Option } from "../../../components/ui/Input/MultipleSelectChips";
import { Configuration } from "@cbtravel/common/shared/config/client-config";
import { useHistory, useLocation } from 'react-router-dom';

import CustomFieldForm from "./CustomFieldForm";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";
import { CustomFieldAccessibility } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-accessibility";
import { CustomFieldCreationPermission } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-creation-permission";
import { InputType } from '@cbtravel/common/lib/web/common/enumerations/input-type';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  rowHeader: {
    borderBottom: '1px solid #000000',
    marginTop: '2rem',
    marginBottom: '1rem',
    paddingBottom: '10px',
  },
  sectionTitle: {
    color: '#000000',
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  infoIcon: {
    color: '#808080',
    fontSize: '22px',
    marginLeft: '4px',
    marginBottom: '-2px',
    cursor: 'pointer',
  },
  addFieldTitle: {
    color: '#00467E',
    fontSize: '16px',
    cursor: 'pointer',
  },
  addIcon: {
    color: '#00467E',
    fontSize: '20px',
    marginLeft: '6px',
    marginBottom: '-2px',
    cursor: 'pointer',
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  tooltipLabel: { marginLeft: 2, marginTop: -5, fontSize: '11px', pointerEvents: "auto" },
  internalUse: {
    color: "#808080", fontSize: 12, marginLeft: 6,
  }
}));

export default function ReportingFields(props: { activeClient: ClientRS | undefined }) {
  const classes = useStyles();
  const { userContext } = useContext(UserContext);
  const snackbar = useCustomSnackbar();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [displayCustomFieldForm, setDisplayCustomFieldForm] = useState<boolean>(false);
  const [curCustomField, setCurCustomField] = useState<CustomFieldRS | undefined>();// if set then open up that udid in formFields.
  const [addFieldCustomFieldSource, setAddFieldCustomFieldSource] = useState<CustomFieldSource>(CustomFieldSource.Profile);// if set then open up that udid in formFields.
  const [profileFields, setProfileFields] = useState<CustomFieldRS[]>([]); // contains the employee profile custom fields
  const [tripFields, setTripFields] = useState<CustomFieldRS[]>([]); // contains trip custom fields
  const [fieldTypeOptions, setFieldTypeOptions] = useState<Option[]>([]);
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);
  const routerLocation = useLocation(); //react-router's tool for grabbing url location
  const routerHistory = useHistory();


  // when active client is changed (will also run on mount, as activeClient is a prop passed to this component)
  useEffect(() => {

    if (props.activeClient && props.activeClient.clientId > 0 && userContext.userId !== "") {
      getAllClientCustomFields(props.activeClient.clientId);
    }
  }, [props.activeClient, userContext])

  useEffect(() => {
    if (routerLocation.hash === "#form" && !displayCustomFieldForm) {
      routerHistory.replace("/companyadmin/reportingfields");
    }
  }, [routerLocation.hash])

  /**
   * checks the user's UserType and the accessibility of the field to determine whether to display the given field
   * @param field the field that we're giving permission to view
   * @returns true if the user can see the field, false if they are not allowed
   */
  function checkFieldConfigViewPermissions(field: CustomFieldRS): boolean {
    const customFieldNumbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20];
    let isOpen = customFieldNumbers.includes(field.number);

    switch (userContext.userType) {
      case UserType.Administrator: // admin and AM should be able to see all custom field configurations
      case UserType.AccountManager:
      case UserType.SalesManager: // sales manager should have read only permissions on all custom field configurations
        return true;
      case UserType.TravelManager:
        let configField = Configuration.UdidConfiguration.find(f => f.number === field.number)
        if (configField && configField.fieldCreation !== CustomFieldCreationPermission.Internal) // travel managers can only see open and permissioned fields
          return true;
        else
          return isOpen; // if the field is not in the config, travel managers should only see open custom fields
      case UserType.Advisor: // traveler and travel advisor shouldn't be able to edit any report settings configs
      case UserType.Traveler:
        break;
    }

    return false;
  }

  /**
   * performs a GET for all custom fields associated with a client that are marked to display in the traveler's profile or in a trip booking
   * @param clientId the id number of the client to pull custom fields for
   * @returns void (or undefined if client id is invalid)
   */
  async function getAllClientCustomFields(clientId: number | undefined) {
    if (!clientId || clientId <= 0) {
      return;
    }

    setShowSpinner(true);

    const request = new CustomFieldRQ();
    request.sortType = CustomFieldSortType.ByPosition;
    request.activeSearchType = ActiveSearchType.Both;
    request.clientId = clientId;
    request.customFieldSourceSearchType = CustomFieldSourceSearchType.Profile;

    try {
      const customProfileList: PagedList<CustomFieldRS> = await CustomFieldController.Find(userContext.accessToken, request);

      const profileCustomFields: CustomFieldRS[] = [];

      customProfileList.list.forEach(field => {
        if (checkFieldConfigViewPermissions(field)) {
          profileCustomFields.push(field);
        }
      })

      setProfileFields(profileCustomFields);

      request.customFieldSourceSearchType = CustomFieldSourceSearchType.Booking

      const customTripList: PagedList<CustomFieldRS> = await CustomFieldController.Find(userContext.accessToken, request);

      const tripCustomFields: CustomFieldRS[] = [];

      customTripList.list.forEach(field => {
        if (checkFieldConfigViewPermissions(field)) {
          tripCustomFields.push(field);
        }
      })

      setTripFields(tripCustomFields);

    } catch (e) {
      snackbar.error(e as JsonException);

    } finally {
      setShowSpinner(false);

    }
  }


  /**
   * sends udid update to backend.
   * @param clientId the id number of the client to pull custom fields for
   * @returns void (or undefined if client id is invalid)
   */
  async function sendCustomFieldInfoToBackEnd(customFieldRS: CustomFieldRS, isUpdate: boolean, fieldValuesToDelete: number[] = []) {
    setShowSpinner(true);
    let clientId;

    if (props.activeClient && props.activeClient.clientId > 0) {
      clientId = props.activeClient.clientId;
      customFieldRS.clientId = clientId;

      if (!props.activeClient.clientId || props.activeClient.clientId <= 0) {
        return;
      }
    }
    // if the InputType of customFieldRS is TextBox,customFieldValueList should be empty
    if (customFieldRS.inputType === InputType.TextBox) {
      customFieldRS.customFieldValueList = []
    }

    try {
      let response: CustomFieldRS = new CustomFieldRS();

      if (isUpdate) {

        response = await CustomFieldController.Update(userContext.accessToken, customFieldRS, EntityDepth.Deep);

      } else {
        response = await CustomFieldController.Add(userContext.accessToken, customFieldRS, EntityDepth.Deep);
      }

      if (response.customFieldId > 0) {
        setDisplayCustomFieldForm(false);
        setCurCustomField(undefined);

        snackbar.info("UDID Saved");

        if (response.customFieldSource === CustomFieldSource.Profile && checkFieldConfigViewPermissions(response)) { // if we're able to view the response
          let tempList = profileFields;
          let index = tempList.findIndex(u => u.customFieldId === response.customFieldId)
          if (index !== -1) {
            // if the id is already in the list, it's an update and we just want to replace it with the current version
            tempList.splice(index, 1, response);
          } else {
            // otherwise it's a new custom field, add to list then sort by position, lowest to highest
            tempList.push(response)
            tempList.sort((a, b) => a.position - b.position)
          }
          setProfileFields(tempList);
        }
        if (response.customFieldSource === CustomFieldSource.Booking && checkFieldConfigViewPermissions(response)) {
          let tempList = tripFields;
          let index = tempList.findIndex(u => u.customFieldId === response.customFieldId)

          if (index !== -1) {
            // if the id is already in the list, it's an update and we just want to replace it with the current version
            tempList.splice(index, 1, response);
          } else {
            // otherwise it's a new custom field, add to list then sort by position, lowest to highest
            tempList.push(response)
            tempList.sort((a, b) => a.position - b.position)
          }
          setTripFields(tempList);
        }
      }

    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setShowSpinner(false);
    }
  }

  /** Open up CustomFieldForm page. */
  const handleAddFields = (fieldSource: CustomFieldSource) => {
    setDisplayCustomFieldForm(true);
    setAddFieldCustomFieldSource(fieldSource);
  }

  /**
   * Make call to backend when options are updated
   */
  const updateFilters = async (options: Option[]) => {
    try {
      setShowSpinner(true);

      const request: CustomFieldRQ = new CustomFieldRQ();

      if (props.activeClient && props.activeClient.clientId > 0) {
        request.clientId = props.activeClient.clientId;
      }
      request.sortType = CustomFieldSortType.ByPosition;

      if (options.length === 0) {
        request.sortType = CustomFieldSortType.ByPosition;
        request.activeSearchType = ActiveSearchType.Both;
        request.customFieldSourceSearchType = CustomFieldSourceSearchType.Profile;
      }
      else {

        // TODO: update to new options for accessibility
        /* if (options.find(o => o.label === "Standard") && !options.find(o => o.label === "Custom")) {
          request.numberList = [38, 56, 99];
        }
        else if (options.find(o => o.label === "Custom") && !options.find(o => o.label === "Standard")) {
          request.numberList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20];
        } */

        request.customFieldSourceSearchType = CustomFieldSourceSearchType.Profile;

        if (options.find(o => o.label === "Active") && !options.find(o => o.label === "Inactive")) {
          request.activeSearchType = ActiveSearchType.Active;
        }
        else if (options.find(o => o.label === "Inactive") && !options.find(o => o.label === "Active")) {
          request.activeSearchType = ActiveSearchType.Inactive;
        }
        else {
          request.activeSearchType = ActiveSearchType.Both;
        }
      }

      const response: PagedList<CustomFieldRS> = await CustomFieldController.Find(userContext.accessToken, request);

      const updatedProfileFields: CustomFieldRS[] = [];

      // put results from find call into proper state variables
      response.list.forEach((customField) => {
        if (checkFieldConfigViewPermissions(customField)) { // TODO: we may not need to do this check depending on how accessibility is added to db queries
          updatedProfileFields.push(customField);
        }
      });

      setProfileFields(updatedProfileFields);

      request.customFieldSourceSearchType = CustomFieldSourceSearchType.Booking;

      const tripResponse: PagedList<CustomFieldRS> = await CustomFieldController.Find(userContext.accessToken, request);

      const updatedTripFields: CustomFieldRS[] = [];

      tripResponse.list.forEach((tripField) => {
        if (checkFieldConfigViewPermissions(tripField)) {
          updatedTripFields.push(tripField);
        }
      })

      setTripFields(updatedTripFields);
    }
    catch (e) {
      snackbar.error(e as JsonException);
    }
    finally {
      setShowSpinner(false);
    }
  }

  /**
   * an UPDATE call that changes the udid's activeStatus when toggled
   * @param event the toggle event for the switch (checkbox)
   * @param oldCustomField the udid that is being enabled/disabled (pre-change)
   */
  const changeCustomFieldActiveStatus = async (event: React.ChangeEvent<HTMLInputElement>, oldCustomField: CustomFieldRS) => {
    setShowSpinner(true);
    const CustomFieldRS = oldCustomField;
    CustomFieldRS.activeStatus = event.target.checked;

    try {
      const response = await CustomFieldController.Update(userContext.accessToken, CustomFieldRS)

    } catch (e) {
      snackbar.error(e as JsonException)
    } finally {
      setShowSpinner(false);
    }
  }

  /** Open the CustomFieldForm page to edit the current udid. */
  const openCustomField = (customField: CustomFieldRS) => {
    setCurCustomField(customField);
    setDisplayCustomFieldForm(true);
  }

  /**
   * checks the user type stored in the user context and filters out any custom fields in use
   * @returns The numbers that we populate in the custom field form number selector
   */
  function populateCustomFieldsNotInUse(): number[] {
    let customFieldNumbers = Array.from(Array(201).keys()).splice(1, 200); // create an array from 1-200

    if (userContext.userType !== UserType.Administrator && userContext.userType !== UserType.AccountManager)
      customFieldNumbers = Array.from(Array(21).keys()).splice(1, 20).filter(n => n !== 19); // create an array from 1-20

    // filter out profile fields
    customFieldNumbers = customFieldNumbers.filter(f => !profileFields.find(field => f === field.number));
    // filter out trip fields
    customFieldNumbers = customFieldNumbers.filter(f => !tripFields.find(field => f === field.number));

    // if there's a current custom field, add the number to the options and sort it
    if (curCustomField) {
      customFieldNumbers.push(curCustomField.number);
      customFieldNumbers.sort();
    }

    return customFieldNumbers;
  }

  return (
    <React.Fragment>
      {showSpinner && <Spinner />}
      {displayCustomFieldForm ?
        <Box>
          <CustomFieldForm
            curCustomField={curCustomField}
            customFieldsNotInUse={populateCustomFieldsNotInUse()}
            results={sendCustomFieldInfoToBackEnd}
            customFieldSource={curCustomField === undefined ? addFieldCustomFieldSource : curCustomField.customFieldSource}
            cancel={async () => {
              setCurCustomField(undefined);
              setDisplayCustomFieldForm(false);
            }}
            setShowSpinner={setShowSpinner}
            index={-1} />
        </Box>
        :
        <Box>
          <Box marginTop={4}>
            <Grid container spacing={2} >
              {/* TODO: update to the new options */}
              {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                <MultipleSelectChips
                  //TODO: Show chips when selected. example: unused tickets.
                  id="ff-field-type"
                  label="Field Type"
                  options={[
                    { label: "Custom", value: 1 },
                    { label: "Standard", value: 2 },]}
                  value={fieldTypeOptions}
                  error={false}
                  errorMessage={""}
                  onChange={(value) => {
                    setFieldTypeOptions(value);
                    updateFilters(value.concat(statusOptions));
                  }}
                />
              </Grid> */}
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <MultipleSelectChips
                  //TODO: Show chips when selected. example: unused tickets.
                  id="ff-status"
                  label={"Status"}
                  options={[{ label: "Active", value: 1 },
                  { label: "Inactive", value: 2 },]}
                  value={statusOptions}
                  error={false}
                  errorMessage={""}
                  onChange={(value: Option[]) => {
                    setStatusOptions(value);
                    updateFilters(value.concat(fieldTypeOptions));
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            style={{ minWidth: "50rem" }}
            className={clsx(classes.flexRow, classes.rowHeader)}
          >
            <Box className={classes.flexColumn}>
              <Box className={classes.flexRow}>
                <Typography className={classes.sectionTitle}>
                  Profile Fields
                </Typography>
              </Box>
            </Box>
            <Box className={classes.flexColumn}>
              <Typography className={classes.addFieldTitle} onClick={() => handleAddFields(CustomFieldSource.Profile)} >
                Add profile fields
                <img
                  className={classes.addIcon}
                  src={require("../../../icons/addIcon.svg").default}
                  alt="add icon"
                />
              </Typography>
            </Box>
          </Box>
          {profileFields.length === 0 && <Box>
            <Typography style={{ color: "#C9C7C7" }}>
              No fields to display
            </Typography>
          </Box>}
          <Box>
            {profileFields.map((u, index) => (
              <CustomFieldDataRow
                customField={u}
                displayPriority={index + 1}
                // deleteCustomField={deleteCustomField}
                updateStatus={changeCustomFieldActiveStatus}
                openCustomField={() => openCustomField(u)}
              />
            ))}
          </Box>

          <Box
            style={{ minWidth: "50rem" }}
            className={clsx(classes.flexRow, classes.rowHeader)}
          >
            <Box className={classes.flexColumn}>
              <Box className={classes.flexRow}>
                <Typography className={classes.sectionTitle}>
                  Trip Fields
                </Typography>
              </Box>
            </Box>
            <Box className={classes.flexColumn}>
              <Typography className={classes.addFieldTitle}
                onClick={() => handleAddFields(CustomFieldSource.Booking)}>
                Add trip field
                <img
                  className={classes.addIcon}
                  src={require("../../../icons/addIcon.svg").default}
                  alt="add icon"
                />
              </Typography>
            </Box>
          </Box>
          {tripFields.length === 0 && <Box>
            <Typography style={{ color: "#C9C7C7" }}>
              No fields to display
            </Typography>
          </Box>}
          <Box>
            {tripFields.map((u, index) => (
              <CustomFieldDataRow
                customField={u}
                displayPriority={index + 1}
                // deleteCustomField={deleteCustomField}
                updateStatus={changeCustomFieldActiveStatus}
                openCustomField={() => openCustomField(u)}
              />
            ))}
          </Box>
        </Box>
      }

    </React.Fragment>

  );
}
import { AppBar, Box, Fab, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState, useRef } from "react";
import { useForm, useFormState } from "react-hook-form";
import { Prompt, useHistory, useLocation } from "react-router-dom";

import { CustomFieldController } from '@cbtravel/common/lib/shared/api/general/controllers/custom-field-controller';
import { LocationController } from "@cbtravel/common/lib/shared/api/general/controllers/location-controller";
import { RegionController } from "@cbtravel/common/lib/shared/api/general/controllers/region-controller";
import { ProfileController } from "@cbtravel/common/lib/shared/api/profiles/profile-controller";
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";
import { AddressType } from "@cbtravel/common/lib/shared/common/enumerations/address-type";
import { CarTransmissionType } from "@cbtravel/common/lib/shared/common/enumerations/car-transmission-type";
import { CarType } from "@cbtravel/common/lib/shared/common/enumerations/car-type";
import { CustomFieldSource } from '@cbtravel/common/lib/shared/common/enumerations/custom-field-source';
import { EntityDepth } from "@cbtravel/common/lib/shared/common/enumerations/entity-depth";
import { ProfileEntry } from "@cbtravel/common/lib/shared/common/enumerations/profile-entry";
import { MealType } from "@cbtravel/common/lib/shared/common/enumerations/meal-type";
import { PhoneType } from "@cbtravel/common/lib/shared/common/enumerations/phone-type";
import { RoomType } from "@cbtravel/common/lib/shared/common/enumerations/room-type";
import { CustomFieldSourceSearchType, RegionTypeSearchType } from "@cbtravel/common/lib/shared/common/enumerations/search-types";
import { SeatType } from "@cbtravel/common/lib/shared/common/enumerations/seat-type";
import { ServiceType } from "@cbtravel/common/lib/shared/common/enumerations/service-type";
import { CustomFieldSortType, RegionSortType } from "@cbtravel/common/lib/shared/common/enumerations/sort-types";
import { ExceptionLevel } from "@cbtravel/common/lib/shared/common/exceptions/exception-level";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { PagedList } from "@cbtravel/common/lib/shared/common/paged-list";
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { iUserContext } from "@cbtravel/common/lib/shared/interfaces/iUserContext";
import { CustomFieldRQ } from "@cbtravel/common/lib/shared/messages/general/requests/custom-field-rq";
import { LocationRQ } from "@cbtravel/common/lib/shared/messages/general/requests/location-rq";
import { RegionRQ } from "@cbtravel/common/lib/shared/messages/general/requests/region-rq";
import { CustomFieldRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs";
import { CustomFieldValueRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-value-rs";
import { VendorLT } from "@cbtravel/common/lib/shared/messages/general/responses/lite/vendor-lt";
import { LocationRS } from "@cbtravel/common/lib/shared/messages/general/responses/location-rs";
import { RegionRS } from "@cbtravel/common/lib/shared/messages/general/responses/region-rs";
import { ProfileRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/profile-rs";
import { TravelDocumentRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/travel-document-rs";
import { InputDataType } from '@cbtravel/common/lib/web/common/enumerations/input-data-type';
import { InputType } from '@cbtravel/common/lib/web/common/enumerations/input-type';
import { TravelDocumentType } from "@cbtravel/common/lib/web/common/enumerations/travel-document-type";

import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import Spinner from "../../../components/shared/Spinner";
import { UserContext } from "../../../components/shared/UserContext";
import ActionDialog from "../../../components/ui/Dialog/ActionDialog";
import VerificationDialog from "../../../components/ui/Dialog/VerificationDialog";

/* Individual tab content pages */
import { UserController } from "@cbtravel/common/lib/shared/api/general/controllers/user-controller";
import { Suffix } from "@cbtravel/common/lib/shared/common/enumerations/suffix";
import { UserType } from '@cbtravel/common/lib/shared/common/enumerations/user-type';
import * as VendorConfiguration from '@cbtravel/common/lib/shared/config/profile-vendors.json';
import { MobilePhoneVerificationRQ } from "@cbtravel/common/lib/shared/messages/general/requests/custom/mobile-phone-verification-rq";
import { ProfileCustomFieldRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/profile-custom-field-rs";
import { ProfilePaymentRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/profile-payment-rs";
import { UserRS } from "@cbtravel/common/shared/messages/general/responses/user-rs";
import { phoneUtil } from "../../../../../../CBTravel.Web.AirPortal/ClientApp/src/util/phone-util";
import { dateUtil } from "../../../util/date-util";
import RequiredFields from "../RequiredFields";
import CompanyInfoTab from "./CompanyInfoTab";
import PaymentInfoTab from "./PaymentInfoTab";
import PersonalInfoTab from "./PersonalInfoTab";
import TravelerIdentificationTab from "./TravelerIdentificationTab";
import TravelPreferencesTab from "./TravelPreferencesTab";
import { CustomFieldListFields } from "@cbtravel/common/lib/shared/messages/approval/responses/custom/custom-field-list-fields";
import { CustomFieldAccessibility } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-accessibility";

import { RegexUtils } from '@cbtravel/common/lib/shared/common/regex-utils';
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={3}>
                    <Typography component={"div"}>{children}</Typography>
                </Box>
            )}

        </div>
    );
}

interface SaveDialogProps {
    id: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((v: boolean) => void),
    positiveAction: () => void,
    negativeAction: () => void,
    negativeText: string,
    children: React.ReactNode
}

// The save dialog that pops up when clicking the save button.
function SaveDialog(props: SaveDialogProps) {
    return (
        <ActionDialog
            id={props.id}
            title="Save changes"
            icon={<div className={useStyles().iconAction}><SaveIcon /></div>}
            positiveText="Save"
            negativeText={props.negativeText}
            open={props.open}
            setOpen={props.setOpen}
            positiveAction={props.positiveAction}
            negativeAction={props.negativeAction}
            dialogType="save"
        >
            {props.children}
        </ActionDialog>
    );
}

function tabProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,

    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiAppBar-colorPrimary": {
            backgroundColor: "transparent",
        },
        "& .MuiTab-wrapper": {
            color: "#4D4D4D",
            textTransform: "none",
        },
        "& .MuiTabs-root": {
            borderBottom: ".5px solid #C9C7C7",
        },
        "& .MuiTab-root": {
            minWidth: 100,
        },

        "& .MuiTab-textColorInherit": {
            opacity: 1,
        },
        "& .MuiTab-textColorInherit.Mui-selected": {
            fontWeight: 600,
            color: "#00467E",
        },
        "&$selected": {
            color: "#00467E",
        },
    },
    fab: {
        position: "absolute",
        bottom: "4%",
        right: "4%",
        color: "#ffffff",
        background: "#00467E",
        boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 1%), 0px 1px 18px 0px rgb(0 0 0 / 11%)",
    },

    // Start dialog styles
    dialog: {
        "& .MuiDialog-paper": {
            textAlign: "center",
            width: 340,
        },
        "& .MuiDialogTitle-root": {
            padding: "16px 24px 0",
        },
        "& .MuiDialogContent-root": {
            padding: "0 24px",
            lineHeight: 1.5,
        },
        "& .MuiTypography-body1": {
            lineHeight: "unset",
            fontSize: "13px",
            color: "#4D4D4D",
        },
        "& .MuiTypography-h6": {
            fontWeight: 600,
        },
    },

    dialogContent: {
        lineHeight: 1.5,
    },

    strong: {
        fontWeight: 600,
        lineHeight: 1.5,
    },
    iconAction: {
        margin: "8px auto 14px",
        background: "#00467E",
        borderRadius: 40,
        width: "44px",
        height: "44px",
        "& .MuiSvgIcon-root": {
            color: "#fff",
            marginTop: "10px",
        },
    },
    dialogActions: {
        padding: "16px 32px",
        "& .MuiButton-contained:hover": {
            backgroundColor: "rgba(0,166,207, .8)",
        },
    },
    btnSave: {
        background: "#00A6CF",
    },
    btnCancel: {
        color: "#00467E",
        marginLeft: "0 !important",
        fontSize: "13px",
    },
}));

export type SelectOption = {
    label: string,
    value: number | string
}

export type AddressFields = {
    addressType: AddressType,
    address1: string,
    address2: string,
    city: string,
    country: RegionRS,
    stateProvince: RegionRS,
    postalCode: string
}

export type PhoneFields = {
    phoneType: PhoneType,
    phoneNumber: string,
}

export type PassportFields = {
    passportNumber: string,
    country: SelectOption,
    issueDate: Date | null,
    expireDate: Date | null,
    primaryPassport: boolean
}

export type IdentificationCardFields = {
    idCardNumber: string,
    country: SelectOption,
    issueDate: Date | null,
    expireDate: Date | null
}

export type LoyaltyInfoFields = {
    vendor: VendorLT,
    loyaltyNumber: string,
    serviceType: ServiceType
}

export type CreditCardFields = {
    cardType: string,
    nameOnCard: string,
    cardNumber: string,
    expireDate: Date | null,
    displayName: string,
    defaultForAir: boolean,
    defaultForCar: boolean,
    defaultForRail: boolean,
    defaultForHotel: boolean,
    isSaved: boolean
}


export type ProfileFormInputs = {
    addresses: Array<AddressFields>,
    passports: Array<PassportFields>,
    idCard: IdentificationCardFields,
    phoneNumbers: Array<PhoneFields>,
    loyaltyInfosFlight: Array<LoyaltyInfoFields>,
    loyaltyInfosCar: Array<LoyaltyInfoFields>,
    loyaltyInfosHotel: Array<LoyaltyInfoFields>,
    creditCards: Array<CreditCardFields>,
    customFieldList: Array<CustomFieldListFields>,
    u99Field: CustomFieldListFields,
    airSeatRequest: SeatType,
    carRentalType: CarType,
    carRentalTransmissionType: CarTransmissionType,
    dateOfBirth: Date | null,
    departingAirport: LocationRS,
    emailAddress: string,
    emergencyContactEmail: string,
    emergencyContactFirstName: string,
    emergencyContactLastName: string,
    emergencyContactPhone: string,
    firstName: string,
    middleName: string,
    mealRequest: MealType,
    lastName: string,
    gender: string,
    hotelRoomType: RoomType,
    redressNumber: string,
    suffix: Suffix,
    travelerNumber: string,
    username: string
}


/**
 * Definitions for validation of form fields.
 */
export const formRules = {
    firstName: {
        validate: {
            required: (value: string) => {
                if (value.trim().length === 0) return "Please enter your first name";
                if (value.length > 50) return "Max 50 characters";
            }
        }
    },
    lastName: {
        validate: {
            required: (value: string) => {
                if (value.trim().length === 0) return "Please enter your last name";
                if (value.length > 50) return "Max 50 characters";
            }
        }
    },
    nonRequiredName: { maxLength: { value: 50, message: 'Max 50 characters' } },
    dateOfBirth: {
        validate: {
            required: (value: Date | null) => {
                if (!value) return "Please enter your date of birth";
            },
            DateInFuture: (value: Date | null) => {
                let currentDate = new Date();
                if (value && value > currentDate) {
                    return "date can not be in the future"
                }
            },
            MinDate: (value: Date | null) => {
                let minDate = new Date("1900-01-01T00:00:00");
                if (value && value <= minDate) {
                    return "Date should not be before minimal date";
                }
            },
            pattern: (value: Date | null) => {
                let date: Date | null = null;
                if (value != null) {
                    date = new Date(value);
                }
                if (!(date instanceof Date)
                    || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
                ) {
                    return "Invalid date format"
                }
            },
        }
    },
    nonRequiredDate: {
        validate: {
            pattern: (value: Date | null) => {
                let date: Date | null = null;
                if (value != null) {
                    date = new Date(value);
                    if (!(date instanceof Date)
                        || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
                    ) {
                        return "Invalid date format"
                    }
                }
                return true;
            },
        }
    },
    requiredDate: {
        validate: {
            required: (value: Date | null) => {
                if (!value) return "Please enter valid date";
            },
            pattern: (value: Date | null) => {
                let date: Date | null = null;
                if (value != null) {
                    date = new Date(value);
                    if (!(date instanceof Date)
                        || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
                    ) {
                        return "Invalid date format"
                    }
                }
                return true;
            },
        }
    },
    gender: {
        validate: {
            required: (value: string) => {
                if (value.trim().length === 0) return "Please select a gender";
            }
        }
    },
    phoneNumbersMobile: {
        validate: {
            required: (value: string) => {
                if (value === undefined)
                    return "Please enter a valid mobile phone";
                else if (value.trim().length === 0)
                    return "Please enter a valid mobile phone";
            },
            value: (value: string) => {
                if (value && !phoneUtil.isPossibleNumber(value)) {
                    return "Please enter a valid phone number"
                }
            }
        },
    },
    phoneNumbers: {
        validate: {
            value: (value: string) => {
                if (value && !phoneUtil.isPossibleNumber(value)) {
                    return "Please enter a valid phone number"
                }
            }
        }
    },
    emergencyContactEmail: {
        pattern: { value: RegexUtils.EMAIL_ADDRESS, message: 'Please enter a valid email address' },
        validate: {
            value: (value: string) => { if (value) { if ((/\s/).test(value)) { return "Please enter a valid email" } } },
        },
        maxLength: { value: 255, message: 'Max 255 characters' }
    },
    idNumber: {
        maxLength: { value: 100, message: "Max 100 characters" }
    },
    redressNumber: {
        minLength: { value: 7, message: "Min 7 characters" },
        maxLength: { value: 11, message: "Max 11 characters" },
        pattern: { value: RegexUtils.ALPHANUMERIC, message: "Alphanumeric characters only" }
    },
};

/**
 * Definitions for validation of form fields.
 */
export const formRulesBasic = {
    firstName: {
        validate: {
            required: (value: string) => {
                if (value.trim().length === 0) return "Please enter your first name";
                if (value.length > 50) return "Max 50 characters";
            }
        }
    },
    lastName: {
        validate: {
            required: (value: string) => {
                if (value.trim().length === 0) return "Please enter your last name";
                if (value.length > 50) return "Max 50 characters";
            }
        }
    },
    dateOfBirth: {
        validate: {
            DateInFuture: (value: Date | null) => {
                let currentDate = new Date();
                if (value && value > currentDate) {
                    return "date can not be in the future"
                }
            },
            MinDate: (value: Date | null) => {
                let minDate = new Date("1900-01-01T00:00:00");
                if (value && value <= minDate) {
                    return "Date should not be before minimal date";
                }
            },
            pattern: (value: Date | null) => {
                let date: Date | null = null;
                if (value != null) {
                    date = new Date(value);
                }
                if (!(date instanceof Date)
                    || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
                ) {
                    return "Invalid date format"
                }
            },
        }
    },
    phoneNumbersMobile: {
        validate: {
            value: (value: string) => {
                if (value && !phoneUtil.isPossibleNumber(value)) {
                    return "Please enter a valid phone number"
                }
            }
        },
    }
};

interface profileProps {
    destination: string,
    setDestination: Dispatch<SetStateAction<string>>,
    setTopBannerMessage: Dispatch<SetStateAction<string>>,
    setTopBannerFirstAndLastName: Dispatch<SetStateAction<string>>,
}

export default function Profile(props: profileProps) {

    const classes = useStyles();
    const { userContext, setUserContext } = useContext<iUserContext>(UserContext);

    const [isPageBusy, setIsPageBusy] = useState<boolean>(false);
    /** 
     * why there are 2 country lists:
     * selectOptions are easier to use with Umbrella in general, 
     * but when states/provinces are also involved and we need a handleChangeCountry function, it's easier to use RegionRS and translate later 
     * (also we set up the selectOption version first and I don't want to re-write a ton of working code unless we have to) 
     */
    const [selectOptionCountries, setSelectOptionCountries] = useState<SelectOption[]>(new Array<SelectOption>()) // used in TravelIdentificationTab/PassportFormFields
    const [countries, setCountries] = useState<RegionRS[]>([new RegionRS()]) // used in PersonalInfoTab/AddressFormFields (along with stateProvinces)
    const [stateProvinces, setStateProvinces] = useState<RegionRS[]>([new RegionRS()])
    const [airlineVendors, setAirlineVendors] = useState<VendorLT[]>([]);
    const [hotelVendors, setHotelVendors] = useState<VendorLT[]>([]);
    const [carVendors, setCarVendors] = useState<VendorLT[]>([]);
    const [customFields, setCustomFields] = useState<CustomFieldRS[]>([new CustomFieldRS()]);
    const [userProfile, setUserProfile] = useState<ProfileRS | null>(null);
    const [profileIsMissingOrIncomplete, setProfileIsMissingOrIncomplete] = useState<boolean>(false);
    // React boolean state to distinguish between initial custom field retrieval vs resetting custom field during U99 selection
    const [isFirstRequiredFieldsCheckCalled, setIsFirstRequiredFieldsCheckCalled] = useState<boolean>();
    const relevantUserEmail = getUserEmail();

    /**
     * Maps a ProfileRS to ProfileFormInputs which can be passed to the reset function
     * to populate the form inputs.
     * 
     * @param profileRs The ProfileRS object to map to the form inputs.
     * @returns Object used to back the react hook form values.
     */
    function ProfileRsToFormInputs(profileRs: ProfileRS, currentValue: CustomFieldValueRS = new CustomFieldValueRS()): ProfileFormInputs {
        let defaultAddress: AddressFields = {
            address1: "",
            address2: "",
            addressType: AddressType.Home,
            city: "",
            country: new RegionRS(),
            postalCode: "",
            stateProvince: new RegionRS()
        };

        let defaultPhone: PhoneFields = {
            phoneNumber: "",
            phoneType: PhoneType.Mobile
        }

        let defaultLoyaltyInfo: LoyaltyInfoFields = {
            loyaltyNumber: "",
            serviceType: ServiceType.Flight,
            vendor: { name: "", code: "", vendorId: -1 }
        }

        let defaultPassport: PassportFields = {
            country: { label: "", value: "" },
            expireDate: null,
            issueDate: null,
            passportNumber: "",
            primaryPassport: true
        }

        let defaultIdCard: IdentificationCardFields = {
            country: { label: "", value: "" },
            expireDate: null,
            issueDate: null,
            idCardNumber: "",
        }

        let defaultCard: CreditCardFields = {
            cardType: "",
            nameOnCard: "",
            cardNumber: "",
            expireDate: null,
            displayName: "",
            defaultForAir: false,
            defaultForCar: false,
            defaultForHotel: false,
            defaultForRail: false,
            isSaved: false
        }

        let foundIdCard: TravelDocumentRS = profileRs.travelDocumentList.find((d) => d.travelDocumentType == TravelDocumentType.Identification) || new TravelDocumentRS();
        foundIdCard.travelDocumentType = TravelDocumentType.Identification;

        // Collect values from a ProfileRS and map them to the form inputs.
        let form: ProfileFormInputs = {
            creditCards: profileRs.profilePaymentList
                .map(payment => ({
                    cardType: payment.paymentNetwork.code,
                    nameOnCard: payment.nameOnCard,
                    cardNumber: payment.accountNumber,
                    expireDate: dateUtil.creditCardExpireStringToDate(payment.expirationDate),
                    displayName: payment.displayName,
                    defaultForAir: payment.defaultForAir,
                    defaultForCar: payment.defaultForCar,
                    defaultForRail: payment.defaultForRail,
                    defaultForHotel: payment.defaultForHotel,
                    isSaved: true
                })),
            addresses: profileRs.addressList.map((a) => (
                {
                    addressType: a.addressType,
                    address1: a.address1,
                    address2: a.address2,
                    city: a.city,
                    country: findCountryAndState(a.countryCode),
                    postalCode: a.postalCode,
                    stateProvince: findCountryAndState(a.stateCode, a.countryCode)
                }
            )),
            passports: profileRs.travelDocumentList
                .filter((d) => d.travelDocumentType == TravelDocumentType.Passport)
                .map((doc) => (
                    {
                        country: { label: findCountryAndState(doc.nationalityCountryCode).name, value: doc.nationalityCountryCode },
                        passportNumber: doc.documentNumber,
                        expireDate: dateUtil.compareToMinDate(new Date(doc.expireDate)) ? null : doc.expireDate,
                        issueDate: dateUtil.compareToMinDate(new Date(doc.issueDate)) ? null : doc.issueDate,
                        primaryPassport: doc.primaryPassport
                    }
                )),
            idCard: {
                country: { label: findCountryAndState(foundIdCard.nationalityCountryCode).name, value: foundIdCard.nationalityCountryCode },
                idCardNumber: foundIdCard.documentNumber,
                issueDate: dateUtil.compareToMinDate(new Date(foundIdCard.issueDate)) ? null : foundIdCard.issueDate,
                expireDate: dateUtil.compareToMinDate(new Date(foundIdCard.expireDate)) ? null : foundIdCard.expireDate
            },
            phoneNumbers: profileRs.phoneList
                // sort so mobile displays first
                .sort((a, b) => (a.phoneType.toString() < b.phoneType.toString()) ? 1 : -1)
                .map((p) => (
                    {
                        phoneNumber: p.number,
                        phoneType: p.phoneType
                    }
                )),
            loyaltyInfosCar: profileRs.profileLoyaltyInfoList
                .filter((i) => i.serviceType == ServiceType.Car)
                .map((info) => (
                    {
                        loyaltyNumber: info.memberNumber,
                        serviceType: info.serviceType,
                        vendor: { name: info.vendor.name, code: info.vendor.code, vendorId: info.vendor.vendorId }
                    }
                )),
            loyaltyInfosHotel: profileRs.profileLoyaltyInfoList
                .filter((i) => i.serviceType == ServiceType.Hotel)
                .map((info) => (
                    {
                        loyaltyNumber: info.memberNumber,
                        serviceType: info.serviceType,
                        vendor: { name: info.vendor.name, code: info.vendor.code, vendorId: info.vendor.vendorId }
                    }
                )),
            loyaltyInfosFlight: profileRs.profileLoyaltyInfoList
                .filter((i) => i.serviceType == ServiceType.Flight)
                .map((info) => (
                    {
                        loyaltyNumber: info.memberNumber,
                        serviceType: info.serviceType,
                        vendor: { name: info.vendor.name, code: info.vendor.code, vendorId: info.vendor.vendorId }
                    }
                )),
            u99Field: fillU99FieldList(profileRs, customFields, currentValue),
            customFieldList: customFields.filter(e => e.number !== 99).map((field: CustomFieldRS) => {
                let cfConfig: ProfileCustomFieldRS | undefined = profileRs.customFieldList.find(f => f.number === field.number);
                if (cfConfig === undefined) cfConfig = new ProfileCustomFieldRS();
                return {
                    customFieldNumber: field.number,
                    value: field.inputType === InputType.TextBox ? cfConfig.value : (field.customFieldValueList.find(f => f.value.toUpperCase() === cfConfig?.value.toUpperCase()) || ""),
                    customFieldId: field.customFieldId,
                    customFieldSource: cfConfig.customFieldSource,
                    example: field.example,
                    inputDataType: field.inputDataType as InputDataType,
                    inputMaxLength: field.inputMaxLength,
                    inputMinLength: field.inputMinLength,
                    inputType: field.inputType,
                    activeStatus: field.activeStatus,
                    isRequired: field.isRequired,
                    name: field.name,
                    noteInternal: field.noteInternal,
                    noteExternal: field.noteExternal,
                    number: field.number,
                    position: field.position,
                    reportingCodeId: field.reportingCodeId,
                    customFieldValueList: field.customFieldValueList,
                    accessibility: field.accessibility
                }
            }),
            airSeatRequest: profileRs.preferences.airSeatRequest,
            carRentalType: profileRs.preferences.carType,
            carRentalTransmissionType: profileRs.preferences.carTransmissionType,
            dateOfBirth: dateUtil.compareToMinDate(new Date(profileRs.dateOfBirth)) ? null : profileRs.dateOfBirth,
            departingAirport: new LocationRS(),
            emailAddress: profileRs.emailAddress,
            emergencyContactEmail: profileRs.emergencyContact.emailAddress,
            emergencyContactFirstName: profileRs.emergencyContact.firstName,
            emergencyContactLastName: profileRs.emergencyContact.lastName,
            emergencyContactPhone: profileRs.emergencyContact.phone.number,
            firstName: profileRs.firstName,
            middleName: profileRs.middleName,
            lastName: profileRs.lastName,
            mealRequest: profileRs.preferences.airMealRequest,
            gender: profileRs.gender,
            hotelRoomType: profileRs.preferences.hotelRoomType,
            suffix: profileRs.suffix,
            redressNumber: profileRs.redressNumber,
            travelerNumber: profileRs.knownTravelerNumber,
            username: profileRs.username
        };

        form.departingAirport.code = profileRs.preferences.airDepartureAirport;

        // Defaults values for empty arrays
        if (form.addresses.length == 0) form.addresses = [defaultAddress];
        if (form.phoneNumbers.length == 0) form.phoneNumbers = [defaultPhone];
        if (form.loyaltyInfosCar.length == 0) form.loyaltyInfosCar = [defaultLoyaltyInfo];
        if (form.loyaltyInfosFlight.length == 0) form.loyaltyInfosFlight = [defaultLoyaltyInfo];
        if (form.loyaltyInfosHotel.length == 0) form.loyaltyInfosHotel = [defaultLoyaltyInfo];
        if (form.passports.length == 0) form.passports = [defaultPassport];
        if (!form.idCard.idCardNumber) form.idCard = defaultIdCard;
        if (form.creditCards.length == 0) form.creditCards = [defaultCard];


        return form;
    }

    /**
     * Maps FormInputs to ProfileRS to be sent to Umbrella and updated
     * 
     * @param inputs Object representing the form values.
     * @returns A ProfileRS populated with the values in the form and user state.
     */
    function FormInputsToProfileRs(inputs: ProfileFormInputs): ProfileRS {
        let profile = new ProfileRS();
        let minDate = new Date("0001-01-01T00:00:00Z");

        if (inputs.addresses) {
            profile.addressList = inputs.addresses.filter((a) => a.address1 != "").map((a) => (
                {
                    address1: a.address1,
                    address2: a.address2,
                    address3: "",
                    addressType: a.addressType,
                    city: a.city,
                    countryCode: a.country.code,
                    stateCode: (a.stateProvince as RegionRS).isO31662,
                    postalCode: a.postalCode
                }
            ));
        }
        if (inputs.passports) {
            // If one passport, mark it as primary
            if (inputs.passports.length === 1) {
                inputs.passports[0].primaryPassport = true;
            }

            inputs.passports.filter((p) => p.passportNumber != "").forEach((p) => {
                profile.travelDocumentList.push({
                    externalDocumentId: "",
                    documentNumber: p.passportNumber,
                    expireDate: p.expireDate || minDate,
                    issueDate: p.issueDate || minDate,
                    nationalityCountryCode: p.country.value as string,
                    nationalityRegionId: findCountryAndState(p.country.value as string).regionId,
                    primaryPassport: p.primaryPassport,
                    travelDocumentType: TravelDocumentType.Passport
                });
            });
        }

        if (inputs.idCard.idCardNumber) {
            profile.travelDocumentList.push({
                externalDocumentId: "",
                documentNumber: inputs.idCard.idCardNumber,
                expireDate: inputs.idCard.expireDate || minDate,
                issueDate: inputs.idCard.issueDate || minDate,
                nationalityCountryCode: inputs.idCard.country.value as string,
                nationalityRegionId: findCountryAndState(inputs.idCard.country.value as string).regionId,
                primaryPassport: false,
                travelDocumentType: TravelDocumentType.Identification
            });
        }

        if (inputs.phoneNumbers) {
            inputs.phoneNumbers.filter((n) => n.phoneNumber != "").forEach((p) => {
                profile.phoneList.push(({
                    number: p.phoneNumber,
                    phoneType: p.phoneType
                }));
            });
        }

        if (inputs.loyaltyInfosCar) {
            inputs.loyaltyInfosCar.filter((i) => i.loyaltyNumber != "").forEach((info) => {
                profile.profileLoyaltyInfoList.push(({
                    externalId: "",
                    memberNumber: info.loyaltyNumber,
                    serviceType: ServiceType.Car,
                    vendor: { code: info.vendor.code, name: info.vendor.name, vendorId: info.vendor.vendorId },
                }));
            });
        }

        if (inputs.loyaltyInfosHotel) {
            inputs.loyaltyInfosHotel.filter((i) => i.loyaltyNumber != "").forEach((info) => {
                profile.profileLoyaltyInfoList.push(({
                    externalId: "",
                    memberNumber: info.loyaltyNumber,
                    serviceType: ServiceType.Hotel,
                    vendor: { code: info.vendor.code, name: info.vendor.name, vendorId: info.vendor.vendorId },
                }));
            });
        }

        if (inputs.loyaltyInfosFlight) {
            inputs.loyaltyInfosFlight.filter((i) => i.loyaltyNumber != "").forEach((info) => {
                profile.profileLoyaltyInfoList.push(({
                    externalId: "",
                    memberNumber: info.loyaltyNumber,
                    serviceType: ServiceType.Flight,
                    vendor: { code: info.vendor.code, name: info.vendor.name, vendorId: info.vendor.vendorId },
                }));
            });
        }

        if (inputs.customFieldList) {
            inputs.customFieldList.forEach(field => {
                profile.customFieldList.push(({
                    number: field.customFieldNumber,
                    value: typeof field.value === 'string' ? field.value : field.value.value,
                    customFieldSource: field.customFieldSource
                }));
            });
        }

        if (inputs.u99Field.customFieldId !== -1) {
            profile.customFieldList.push(({
                number: 99,
                value: typeof inputs.u99Field.value === 'string' ? inputs.u99Field.value : inputs.u99Field.value.value,
                customFieldSource: inputs.u99Field.customFieldSource
            }));
        }
        if (inputs.creditCards) {
            inputs.creditCards.filter(c => c.cardNumber !== "")
                .forEach(field => {
                    let card: ProfilePaymentRS = new ProfilePaymentRS();
                    card.nameOnCard = field.nameOnCard;
                    card.displayName = field.displayName;
                    card.paymentNetwork.code = field.cardType;
                    card.accountNumber = field.cardNumber;
                    card.expirationDate = dateUtil.dateToCreditCardExpirationString(field.expireDate ? field.expireDate : new Date());
                    card.defaultForAir = field.defaultForAir;
                    card.defaultForCar = field.defaultForCar;
                    card.defaultForHotel = field.defaultForHotel;
                    card.defaultForRail = field.defaultForRail;

                    profile.profilePaymentList.push(card);
                });
        }

        profile.preferences.airSeatRequest = inputs.airSeatRequest;
        profile.preferences.airDepartureAirport = inputs.departingAirport.code;
        profile.preferences.airMealRequest = inputs.mealRequest;
        profile.preferences.carType = inputs.carRentalType;
        profile.preferences.carTransmissionType = inputs.carRentalTransmissionType;
        profile.preferences.hotelRoomType = inputs.hotelRoomType;
        profile.knownTravelerNumber = inputs.travelerNumber;
        profile.redressNumber = inputs.redressNumber;
        profile.dateOfBirth = inputs.dateOfBirth || minDate;
        profile.emailAddress = inputs.emailAddress;
        profile.username = inputs.username;
        profile.firstName = inputs.firstName;
        profile.middleName = inputs.middleName;
        profile.lastName = inputs.lastName;
        profile.gender = inputs.gender;
        profile.suffix = inputs.suffix;
        profile.emergencyContact.firstName = inputs.emergencyContactFirstName;
        profile.emergencyContact.lastName = inputs.emergencyContactLastName;
        profile.emergencyContact.emailAddress = inputs.emergencyContactEmail;
        profile.emergencyContact.phone.number = inputs.emergencyContactPhone;
        profile.client.clientId = userProfile ? userProfile.client.clientId : Number(userContext.clientId);
        profile.userId = userProfile ? userProfile.userId : Number(userContext.userId);
        profile.userType = userProfile?.userType ? userProfile.userType : userContext.userType;
        profile.activeStatus = userProfile?.activeStatus ? userProfile?.activeStatus : true;
        profile.dateCreated = userProfile ? userProfile.dateCreated : new Date();

        return profile;
    }

    /**
     * Finds the RegionRS for a country or state based on its 2-letter country/state code (i.e. from Address objects)
     * @param code string: the 2-letter country or state code
     * @param parentCode string: the 2-letter country code for the state's parent region (prevents issues when states from different countries have the same code), empty string if we want to return a country
     * @returns a RegionRS of the desired country or state
     */
    function findCountryAndState(code: string, parentCode: string = "") {
        let found: RegionRS = new RegionRS();

        if (!parentCode && code) {
            let country = (countries?.find(c => c.code === code));
            if (country) found = country;
        }
        if (parentCode && code) {
            let parent = (countries?.find(c => c.code === parentCode));
            let state = (stateProvinces?.find(s => (s.code === code || s.isO31662 === code) && s.parentRegionId === parent?.regionId));
            if (state) found = state;
        }
        return found;
    }

    const { handleSubmit, formState: { errors }, control, reset, register, setValue, getValues, setError, clearErrors, trigger }
        = useForm<ProfileFormInputs>({
            mode: "onBlur",
            // these are default values for the form before getting a response from the API
            defaultValues: {
                addresses: new Array<AddressFields>({
                    addressType: AddressType.Home,
                    address1: "",
                    address2: "",
                    city: "",
                    country: new RegionRS(),
                    stateProvince: new RegionRS(),
                    postalCode: ""
                }),
                passports: new Array<PassportFields>({
                    passportNumber: "",
                    country: { label: "", value: "" },
                    issueDate: null,
                    expireDate: null,
                    primaryPassport: true
                }),
                phoneNumbers: new Array<PhoneFields>({
                    phoneType: PhoneType.Home,
                    phoneNumber: ""
                }),
                loyaltyInfosFlight: new Array<LoyaltyInfoFields>({
                    serviceType: ServiceType.Flight,
                    vendor: { name: "", code: "", vendorId: -1 },
                    loyaltyNumber: ""
                }),
                loyaltyInfosHotel: new Array<LoyaltyInfoFields>({
                    serviceType: ServiceType.Hotel,
                    vendor: { name: "", code: "", vendorId: -1 },
                    loyaltyNumber: ""
                }),
                loyaltyInfosCar: new Array<LoyaltyInfoFields>({
                    serviceType: ServiceType.Car,
                    vendor: { name: "", code: "", vendorId: -1 },
                    loyaltyNumber: ""
                }),
                creditCards: new Array<CreditCardFields>({
                    cardType: "",
                    nameOnCard: "",
                    cardNumber: "",
                    expireDate: null,
                    displayName: "",
                    defaultForAir: false,
                    defaultForCar: false,
                    defaultForRail: false,
                    defaultForHotel: false,
                    isSaved: false
                }),
                customFieldList: new Array<CustomFieldListFields>({
                    customFieldNumber: -1,
                    value: "",
                    customFieldId: -1,
                    customFieldSource: CustomFieldSource.Profile,
                    example: "",
                    inputDataType: InputDataType.NotApplicable,
                    inputMaxLength: -1,
                    inputMinLength: -1,
                    inputType: InputType.TextBox,
                    activeStatus: true,
                    isRequired: false,
                    name: "",
                    noteInternal: "",
                    noteExternal: "",
                    number: -1,
                    position: -1,
                    reportingCodeId: -1,
                    customFieldValueList: [],
                    accessibility: CustomFieldAccessibility.Open
                }),
                u99Field: {
                    customFieldNumber: -1,
                    value: "",
                    customFieldId: -1,
                    customFieldSource: CustomFieldSource.Profile,
                    example: "",
                    inputDataType: InputDataType.NotApplicable,
                    inputMaxLength: -1,
                    inputMinLength: -1,
                    inputType: InputType.TextBox,
                    activeStatus: true,
                    isRequired: false,
                    name: "",
                    noteInternal: "",
                    noteExternal: "",
                    number: -1,
                    position: -1,
                    reportingCodeId: -1,
                    customFieldValueList: [],
                    accessibility: CustomFieldAccessibility.Open
                },
                airSeatRequest: SeatType.NotApplicable,
                carRentalType: CarType.NotApplicable,
                carRentalTransmissionType: CarTransmissionType.NotApplicable,
                dateOfBirth: null,
                departingAirport: new LocationRS(),
                emailAddress: "",
                emergencyContactEmail: "",
                emergencyContactFirstName: "",
                emergencyContactLastName: "",
                emergencyContactPhone: "",
                firstName: "",
                middleName: "",
                lastName: "",
                gender: "",
                mealRequest: MealType.NotApplicable,
                hotelRoomType: RoomType.NotApplicable,
                redressNumber: "",
                suffix: Suffix.NotApplicable,
                travelerNumber: ""
            }
        });

    // Used by React Hook Form to determine if the form has been altered
    const { isDirty, dirtyFields } = useFormState({ control });

    const routerHistory = useHistory();
    const routerLocation = useLocation();
    const snackbar = useCustomSnackbar();

    const [tabValue, setTabValue] = React.useState(0);
    const [verificationSuccessAction, setVerificationSuccessAction] = useState<() => void>();
    const [openVerificationDialog, setOpenVerificationDialog] = useState<boolean>(false);
    const [verificationRequest, setVerificationRequest] = useState<MobilePhoneVerificationRQ>();
    const [showTabChangeSaveDialog, setShowTabChangeSaveDialog] = useState<{ show: boolean, tabValue: number }>({ show: false, tabValue: tabValue });
    const [showNavSaveDialog, setShowSaveNavSaveDialog] = useState<boolean>(false);
    const [navigateOnSave, setNavigateOnSave] = useState<string>("");

    /**
     * Submit the form values but stays on the page.
     */
    function saveBtnPositiveAction() {
        handleSubmit(onSubmit, onSubmitWithDirtyForm)()
            .catch(catchSubmitError)
            .finally(() => setIsPageBusy(false))

    }

    /**
     * Submit the form values then continue to new tab.
     */
    function tabChangePositiveSaveAction() {
        handleSubmit(onSubmit, onSubmitWithDirtyForm)()
            .then(() => {
                setTabValue(showTabChangeSaveDialog.tabValue);
            }, catchSubmitError)
            .finally(() => setIsPageBusy(false));
    }

    /**
     * Submit changes then continue with navigation away from this page.
     */
    function navigatePositiveSaveAction() {
        handleSubmit(onSubmit, onSubmitWithDirtyForm)()
            .then(() => {
                routerHistory.push(navigateOnSave);
            }, catchSubmitError);
    }

    /**
     * Handles changing the current tab of the form. Will intercept the change with dialogs
     * as required.
     * 
     * @param event The event causing this function to be called.
     * @param newValue The number representing the tab to change to.
     */
    function handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
        if (isDirty) {
            if (isMobilePhoneDirty()) {
                setVerificationSuccessAction(() => tabChangePositiveSaveAction);
                handleOpenVerificationDialog();
            } else {
                setShowTabChangeSaveDialog({ show: true, tabValue: newValue });
            }
        } else {
            setTabValue(newValue);
        }
    };

    /**
     * Handler for when the save button is clicked. Shows the phone verification if the mobile phone
     * field has changed, otherwise just shows the save dialog.
     */
    function handleSaveBtnClick() {

        // Show mobile verification dialog if mobile phone changed, go straight to save dialog otherwise
        if (isMobilePhoneDirty()) {
            setVerificationSuccessAction(() => saveBtnPositiveAction);
            handleOpenVerificationDialog();
        } else {
            saveBtnPositiveAction();
        }
    };

    /**
     * Handler for when user wants to navigate away from this page. If there are unsaved changes in the
     * form then navigation is blocked by save dialogs.
     * 
     * @param location The router location that the user is trying to navigate to.
     * @returns False if navigation is halted, true if navigation continues.
     */
    function handleBlockedNavigation(location: typeof routerLocation) {

        if (navigateOnSave === "") {
            setNavigateOnSave(location.pathname)

            if (isMobilePhoneDirty()) {
                setVerificationSuccessAction(() => navigatePositiveSaveAction);
                handleOpenVerificationDialog();
            } else {
                setShowSaveNavSaveDialog(true);
            }
            return false;
        }
        return true;
    }

    /**
     * Called by the save dialog. Collects the field values as a ProfileRS
     * and submits it to the Profile API for updating.
     * 
     * @param data The object containing the data in the form.
     */
    async function onSubmit(data: ProfileFormInputs) {
        setIsPageBusy(true);
        let profileRs = FormInputsToProfileRs(data);
        let result = await ProfileController.Update(userContext.accessToken, profileRs, EntityDepth.Infinite, ProfileEntry.UserProfileUpdate);
        setUserProfile(profileRs);
        let returnedProfile = ProfileRsToFormInputs(result);

        snackbar.info("Profile updated successfully!");

        // make all cards saved cards
        returnedProfile.creditCards.forEach(cc => {
            if (cc.cardNumber !== "" &&
                cc.cardType !== "" &&
                cc.displayName !== "" &&
                cc.expireDate !== null &&
                cc.nameOnCard !== "") {
                cc.isSaved = true;
            }
        });

        // Clean up addresses to prevent UI issues
        let cleanedAddresses: AddressFields[] = new Array();

        // if there are any filled-out address fields, retain them only
        if (getValues("addresses").some((address: AddressFields) => { return address.address1 !== ""; })) {
            cleanedAddresses = getValues("addresses").filter((address: AddressFields) => { return address.address1 !== ""; });
        } else { // otherwise retain only one empty address so the form is not "hidden"
            let firstEmpty = getValues("addresses").find((address: AddressFields) => { return address.address1 === ""; });
            if (firstEmpty !== undefined) {
                cleanedAddresses.push(firstEmpty);
            }
        }

        setValue("addresses", cleanedAddresses);

        // Reset form state with current values so no longer considered dirty
        reset(returnedProfile);

        // if logged in user is not editing another profile
        if (userProfile && (Number(userContext.userId) === userProfile.userId)) {
            // Update first and last names in the user object in user context
            userContext.userState.firstName = profileRs.firstName;
            userContext.userState.lastName = profileRs.lastName;

            // commit changes to user context to localStorage, so that faces menu updates
            // calling setUser() here so that the FaceMenu only updates after the snackbar message has appeared and spinner has stopped
            setUserContext(userContext);
        }
        //avoid selected country overwrite the countries list
        getCountries();
    }

    /**
     * Used as the second parameter of handleSubmit. Called when the first
     * parameter throws an error.
     * 
     * @param e The error provided by react hook form when it prevents submission.
     */
    function catchSubmitError(e: any) {
        snackbar.error(e as JsonException);
        setNavigateOnSave("");
    }

    /**
     * Called when handleSubmit is called and the form state has validation errors.
     * 
     * @param err The error provided by react hook form when submitting a form with errors.
     */
    function onSubmitWithDirtyForm(err: any) {
        const fieldArrays = ["addresses", "loyaltyInfosCar", "loyaltyInfosFlight", "loyaltyInfosHotel", "passports", "phoneNumbers", "creditCards", "customFieldList", "idCard"];
        throw {
            message: "Unable to submit while the form has errors:",
            exceptionDetailList: Object.entries(err).map((e: any) => {
                if (fieldArrays.includes(e[0])) {
                    let section: string = "unknown"
                    switch (e[0]) {
                        case "addresses":
                            section = "address"
                            break;
                        case "loyaltyInfosCar":
                            section = "car loyalty program"
                            break;
                        case "loyaltyInfosFlight":
                            section = "airline loyalty program"
                            break;
                        case "loyaltyInfosHotel":
                            section = "hotel loyalty program"
                            break;
                        case "passports":
                            section = "passport"
                            break;
                        case "phoneNumbers":
                            section = "phone number"
                            break;
                        case "creditCards":
                            section = "credit card"
                            break;
                        case "customFieldList":
                            section = "company info"
                            break;
                        case "idCard":
                            section = "id card"
                            break;
                    }
                    let message = `Incorrect formatting. Please ensure all ${section} fields are filled out or removed if incomplete.`
                    return { message: message, key: "", property: "" }
                } else {
                    return { message: e[1].message, key: "", property: "" }

                }
            }),
            exceptionLevel: ExceptionLevel.Critical,
            uniqueID: ""
        } as JsonException;
    }

    function fillU99FieldList(profileRs: ProfileRS, customFields: CustomFieldRS[], currentValue: CustomFieldValueRS = new CustomFieldValueRS()): CustomFieldListFields {
        let u99FieldListField: CustomFieldListFields = {
            customFieldNumber: -1,
            value: "",
            customFieldId: -1,
            customFieldSource: CustomFieldSource.Profile,
            example: "",
            inputDataType: InputDataType.NotApplicable,
            inputMaxLength: -1,
            inputMinLength: -1,
            inputType: InputType.TextBox,
            activeStatus: true,
            isRequired: false,
            name: "",
            noteInternal: "",
            noteExternal: "",
            number: -1,
            position: -1,
            reportingCodeId: -1,
            customFieldValueList: [],
            accessibility: CustomFieldAccessibility.Open
        };

        let u99 = customFields.find(e => e.number === 99);
        let cfConfig: ProfileCustomFieldRS | undefined = profileRs.customFieldList.find(f => f.number === u99?.number);
        if (cfConfig === undefined) cfConfig = new ProfileCustomFieldRS();
        let u99Value = (currentValue.customFieldId !== -1) ? currentValue : u99?.inputType === InputType.TextBox ? cfConfig.value : (u99?.customFieldValueList.find(f => f.value.toUpperCase() === cfConfig?.value.toUpperCase()) || "");
        if (u99) {
            u99FieldListField = {
                customFieldNumber: u99.number,
                value: u99Value,
                customFieldId: u99.customFieldId,
                customFieldSource: cfConfig.customFieldSource,
                example: u99.example,
                inputDataType: u99.inputDataType as InputDataType,
                inputMaxLength: u99.inputMaxLength,
                inputMinLength: u99.inputMinLength,
                inputType: u99.inputType,
                activeStatus: u99.activeStatus,
                isRequired: u99.isRequired,
                name: u99.name,
                noteInternal: u99.noteInternal,
                noteExternal: u99.noteExternal,
                number: u99.number,
                position: u99.position,
                reportingCodeId: u99.reportingCodeId,
                customFieldValueList: u99.customFieldValueList,
                accessibility: u99.accessibility
            }
        }
        return u99FieldListField;

    }
    /**
             * Gets and sets the list of countries for dropdown options.
             */
    async function getCountries() {
        const regionRQ = new RegionRQ();
        regionRQ.regionTypes.push(RegionTypeSearchType.Country);
        regionRQ.sortType = RegionSortType.ByPosition;
        try {
            const countryList: PagedList<RegionRS> = await RegionController.Find(regionRQ);
            setCountries(countryList.list);
            const displayList: SelectOption[] = [];
            countryList.list.forEach(c => {
                let dc: SelectOption = { label: "", value: "" };
                dc.label = c.name;
                dc.value = c.code;
                displayList.push(dc);
            })
            setSelectOptionCountries(displayList);
        } catch (e) {
            snackbar.error(e as JsonException);
        }
    }
    // On component mount
    useEffect(() => {
        /**
         * Gets and sets the list of states/provinces for the dropdown options.
         */
        async function getStateProvinces() {
            const regionRQ = new RegionRQ();
            regionRQ.regionTypes.push(RegionTypeSearchType.Subdivision);
            regionRQ.regionTypes.push(RegionTypeSearchType.District);
            regionRQ.sortType = RegionSortType.ByPosition;
            try {
                const provinceList: PagedList<RegionRS> = await RegionController.Find(regionRQ);
                setStateProvinces(provinceList.list);
            } catch (e) {
                snackbar.error(e as JsonException);
            }
        }

        /**
         * Gets and sets the list of airline vendors for the dropdown options.
         */
        async function getAirlineVendors() {
            const displayList: VendorLT[] = [];
            VendorConfiguration.Airline.Vendors.forEach(v => {
                displayList.push({ name: v.AIRLINE, code: v.CODE, vendorId: Number(v.VendorCode) });
            });
            setAirlineVendors(displayList);
        }

        /**
         * Gets and sets the list of hotel vendors for the dropdown options.
         */
        async function getHotelVendors() {
            const displayList: VendorLT[] = [];
            VendorConfiguration.Hotel.Vendors.forEach(v => {
                displayList.push({ name: v.HOTEL, code: v["DEFAULT CODE"], vendorId: Number(v.VendorCode) });
            });
            setHotelVendors(displayList);
        }

        /**
         * Gets and sets the list of rental car vendors for the dropdown options.
         */
        async function getCarVendors() {
            const displayList: VendorLT[] = [];
            VendorConfiguration.Car.Vendors.forEach(v => {
                displayList.push({ name: v.RENTALCARS, code: v.CODE, vendorId: v.VendorCode });
            })
            setCarVendors(displayList);
        }



        /**
         * Set the userProfile State to the userId in the URL. If there's no userId in profile, set it to the logged in user.
         * Also calls getCustomFields()
         */
        async function getProfile() {

            let relevantClientId = -1;
            let isProfileMissing = false;
            try {
                let getProfile: ProfileRS = await ProfileController.GetByEmail(userContext.accessToken, relevantUserEmail, EntityDepth.Infinite);
                // if logged in user is not editing another profile
                if (Number(userContext.userId) === getProfile.userId) {
                    // Update the user state as well, so that changes reflect in local storage.
                    userContext.userState.firstName = getProfile.firstName;
                    userContext.userState.lastName = getProfile.lastName;

                    // commit changes to user context to localStorage, so that faces menu updates
                    // calling setUser() here so that the FaceMenu only updates after the snackbar message has appeared and spinner has stopped
                    setUserContext(userContext);
                }
                relevantClientId = getProfile.client.clientId;
                setUserProfile(getProfile);
            } catch (e) {
                let profileException = e as JsonException;
                if (profileException.message.includes("404")) {
                    isProfileMissing = true;
                } else {
                    snackbar.error(profileException)
                }
            }

            if (isProfileMissing) {
                // profile is missing, check if user exists
                try {
                    let missingProfileUser: UserRS = await UserController.GetByEmail(userContext.accessToken, relevantUserEmail, EntityDepth.Infinite);
                    let createdProfileFromUserRS = new ProfileRS(missingProfileUser);
                    relevantClientId = missingProfileUser.client.clientId;

                    // Profiles casted from Users include empty strings in phone and addresses
                    // which will cause errors when trying to save later. 
                    createdProfileFromUserRS.addressList = createdProfileFromUserRS.addressList.filter((address) => {
                        return address.address1 !== "";
                    });
                    createdProfileFromUserRS.phoneList = createdProfileFromUserRS.phoneList.filter((phone) => {
                        return phone.number !== "";
                    });

                    setUserProfile(createdProfileFromUserRS);
                } catch (e) {
                    // profile doesn't exist, and user doesn't exist
                    snackbar.error(e as JsonException)
                }
            }

            getCustomFields(relevantClientId);
        }

        /**
         * Check if the logged in user has the correct userType for editing another user's profile. 
         */
        function checkPermission() {

            let urlUserEmail = window.location.search.includes("email=")
            if (urlUserEmail && userContext.userType === UserType.Traveler) {

                // Traveler is trying to edit another profile
                setIsPageBusy(false)
                routerHistory.push(Configuration.DefaultLandingPage);
            }
        }
        //start spinner on page mount
        setIsPageBusy(true);

        checkPermission();

        getCountries();
        getStateProvinces();
        getAirlineVendors();
        getHotelVendors();
        getCarVendors();

        getProfile();
    }, []);

    // update top banner after changing first or last name
    useEffect(() => {
        if (userProfile) {
            let editingAnotherUser = window.location.search.substring(1);
            if (editingAnotherUser) {
                props.setTopBannerMessage("Editing");
                props.setTopBannerFirstAndLastName(userProfile.firstName + ' ' + userProfile.lastName);
            }
        }
    }, [userProfile?.firstName, userProfile?.lastName]);

    //addresses won't work unless we ensure the existing profile full happens after the region api pulls
    useEffect(() => {

        async function checkRequiredFields() {

            let profileCheck: Response = await ProfileController.ProfileRequiredFieldsCheck(userContext.accessToken, relevantUserEmail);
            if (profileCheck.status === 400) {
                setProfileIsMissingOrIncomplete(true);
            } else {
                setProfileIsMissingOrIncomplete(false);
            }
            setIsFirstRequiredFieldsCheckCalled(true)
        }

        // custom fields check determines that we have something other than default array value; i.e. if there's only one blank CustomFieldRS, then we haven't gotten the correct data yet
        if (countries.length > 1 && stateProvinces.length > 1 && !(customFields.length === 1 && customFields[0].customFieldId === -1)) {
            if ((!isFirstRequiredFieldsCheckCalled && Number(userContext.userId) === userProfile?.userId))
                checkRequiredFields();
            populateFormData();
        }
    }, [countries, stateProvinces, customFields]);

    /**
    * Gets and sets the list of custom fields for the user's client.
    */
    async function getCustomFields(clientId: number) {
        // Get customFields
        let request: CustomFieldRQ = new CustomFieldRQ();

        request.clientId = clientId;
        request.sortType = CustomFieldSortType.ByPosition;
        request.activeSearchType = ActiveSearchType.Active;
        request.customFieldSourceSearchType = CustomFieldSourceSearchType.Profile;

        try {

            let newCustomFields = (await CustomFieldController.Find(userContext.accessToken, request, EntityDepth.Infinite)).list;
            //if new client has u99, replace the old one. We want to keep the old one otherwise
            let newU99 = newCustomFields.find(e => e.number === 99);
            let oldU99 = customFields.find(e => e.number === 99);

            if (!newU99 && oldU99) {
                newCustomFields.push(oldU99);
            }
            setCustomFields(newCustomFields); //set customField hook.
        }

        catch (e) {
            snackbar.error(e as JsonException)
        }

    }
    /**
     * Gets the existing profile for the user and populates the form object with the data.
     */
    async function populateFormData() {
        try {
            setIsPageBusy(true);

            if (userProfile) {
                // for some reason it won't properly render ALL addresses on the first pass unless we do this
                // this doesn't happen with the phones, which work the same way
                // so this is hacky, but it ensures they populate correctly on the page right away
                if (userProfile.addressList?.length > 0) {
                    setValue("addresses", userProfile.addressList.map((a) => (
                        {
                            addressType: a.addressType,
                            address1: a.address1,
                            address2: a.address2,
                            city: a.city,
                            country: findCountryAndState(a.countryCode),
                            postalCode: a.postalCode,
                            stateProvince: findCountryAndState(a.stateCode, a.countryCode)
                        })));
                } else {
                    setValue("addresses", new Array<AddressFields>({
                        addressType: AddressType.Home,
                        address1: "",
                        address2: "",
                        city: "",
                        country: new RegionRS(),
                        stateProvince: new RegionRS(),
                        postalCode: ""
                    }))
                }
                let inputValues = undefined;
                // Convert ProfileRS to form values and apply to the form
                let currentValue = getValues('u99Field');
                //insert the current u99 values
                if (currentValue && typeof currentValue.value !== 'string')
                    inputValues = ProfileRsToFormInputs(userProfile, currentValue.value);
                else
                    inputValues = ProfileRsToFormInputs(userProfile);

                // Get LocationRS for use in LocationSearchBox if we don't have a location id but do have a code
                if (inputValues.departingAirport.locationId === -1 && inputValues.departingAirport.code !== "") {
                    inputValues.departingAirport = await getAirportLocation(inputValues.departingAirport.code);
                }
                reset(inputValues);
            }
        } catch (e) {
            let error = e as JsonException;

            // If not found then they haven't made a profile yet, don't need to show error
            if (error.message.indexOf("404") < 0) {
                snackbar.error(error);
            }
        } finally {
            setIsPageBusy(false);
        }
    }

    /**
     * Used to get an airport location by its code. Useful because ProfileRS stores the departing
     * airport code in a string but the location search box requires a LocationRS.
     * 
     * @param code The airports's code.
     * @returns A LocationRS for the airports location.
     */
    async function getAirportLocation(code: string) {
        let locationRQ = new LocationRQ();
        locationRQ.code = code;
        locationRQ.limit = 1;

        if (code === "") return new LocationRS();

        let results = await LocationController.Find(locationRQ);
        if (results.list.length > 0) {
            return results.list[0];
        }

        return new LocationRS();
    }


    /**
     * Handles opening the verification dialog.
     */
    function handleOpenVerificationDialog() {
        let mobilePhone = getValues("phoneNumbers").find(p => p.phoneType === PhoneType.Mobile);
        if (mobilePhone && phoneUtil.isPossibleNumber(mobilePhone.phoneNumber)) {

            let mobileVerificationRq: MobilePhoneVerificationRQ = new MobilePhoneVerificationRQ();
            mobileVerificationRq.clientId = userProfile ? userProfile.client.clientId : Number(userContext.clientId);
            mobileVerificationRq.mobilePhoneNumber = mobilePhone.phoneNumber || "";

            setVerificationRequest(mobileVerificationRq);
            setOpenVerificationDialog(true);
        } else {
            snackbar.error({
                message: "Unable to submit while the form has errors:",
                exceptionDetailList: [{ message: "Incorrect formatting. Please ensure all phone number fields are filled out or removed if incomplete.", key: "", property: "" }],
                exceptionLevel: ExceptionLevel.Critical,
                uniqueID: ""
            } as JsonException);
        }
    }

    /**
     * Used to determine if the mobile phone field is dirty. RHFs 'dirtyFields' will contain an array that
     * mirrors the order of the field array, but not the actual value of the field so we need to look up
     * the index of the mobile phone in the field array and compare to the index of the dirty field so
     * we can see that it is actually a PhoneType.Mobile that is dirty.
     * 
     * @returns True if the mobile phone field is dirty, false otherwise.
     */
    function isMobilePhoneDirty(): boolean {
        // Get index of mobile phone in field array
        let mobilePhoneIndex = getValues("phoneNumbers").findIndex(p => p.phoneType === PhoneType.Mobile);

        // avoid bypassing phone verification when editing by proxy
        let editingOwnProfile: boolean = relevantUserEmail === userContext.email;

        // Return true if mobile phone is a dirty field and editing their own profile
        if (dirtyFields.phoneNumbers && mobilePhoneIndex !== -1 && dirtyFields.phoneNumbers[mobilePhoneIndex] && editingOwnProfile) {
            return true;
        }

        return false;
    }

    /**
     * Gets the user id from the URL. If none exists, returns logged in user's userId
     */
    function getUserEmail(): string {
        let userEmail = userContext.email;
        if (window.location.search.includes("email=")) {
            const searchString: string = window.location.search;
            const userEmailLoc = searchString.indexOf("email=") + 6;
            userEmail = searchString.slice(userEmailLoc);
        }
        return userEmail;
    }

    return (<div className={classes.root}>
        {isPageBusy && <Spinner />}
        <Prompt
            when={isDirty}
            message={handleBlockedNavigation}
        />
        <Box mt={2} mb={4}>
            <AppBar position="static" elevation={0}>
                <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Profile"
                    indicatorColor="primary"
                >
                    <Tab label="Personal Info" {...tabProps(0)} />
                    <Tab label="Travel Documents" {...tabProps(1)} />
                    <Tab label="Travel Preferences" {...tabProps(2)} />
                    <Tab label="Payment Info" {...tabProps(3)} />
                    <Tab label="Company Info" {...tabProps(4)} />
                </Tabs>
            </AppBar>

            <Box my={2}>
                {/* AddressFormFields displays under the required fields popup when populating form with your browser's autofill,
                so just avoid rendering this whole component while the profile is incomplete. */}
                {!profileIsMissingOrIncomplete &&
                    <TabPanel value={tabValue} index={0} >
                        <PersonalInfoTab
                            control={control}
                            errors={errors}
                            register={register}
                            countries={countries}
                            getValues={getValues}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            viewingOtherProfile={userProfile?.userId !== Number(userContext.userId)}
                        />
                    </TabPanel>
                }
                <TabPanel value={tabValue} index={1} >
                    <TravelerIdentificationTab
                        control={control}
                        errors={errors}
                        register={register}
                        countries={selectOptionCountries}
                        setError={setError}
                        clearErrors={clearErrors}
                        getValues={getValues}
                        trigger={trigger}
                        setValue={setValue}
                        viewingOtherProfile={userProfile?.userId !== Number(userContext.userId)}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={2} >
                    <TravelPreferencesTab
                        control={control}
                        errors={errors}
                        register={register}
                        airlineVendors={airlineVendors}
                        hotelVendors={hotelVendors}
                        carVendors={carVendors}
                        setError={setError}
                        clearErrors={clearErrors}
                        getValues={getValues}
                        trigger={trigger}
                        viewingOtherProfile={userProfile?.userId !== Number(userContext.userId)}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={3} >
                    <PaymentInfoTab
                        control={control}
                        errors={errors}
                        register={register}
                        trigger={trigger}
                        setError={setError}
                        getValues={getValues}
                        viewingOtherProfile={userProfile?.userId !== Number(userContext.userId)}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={4} >
                    <CompanyInfoTab
                        control={control}
                        errors={errors}
                        customFields={customFields}
                        // if the user is an account manager and editing another user's profile, disable editing of custom fields
                        disableFieldForAM={(userProfile?.userId !== Number(userContext.userId) && userContext.userType === UserType.AccountManager)}
                        viewingOtherProfile={userProfile?.userId !== Number(userContext.userId)}
                        getValues={getValues}
                        getCustomFields={getCustomFields}
                        setValue={setValue}
                    />
                </TabPanel>
            </Box>
        </Box>
        <Fab
            id="btn-save"
            color="primary"
            aria-label="save"
            className={classes.fab}
            onClick={handleSaveBtnClick}
        >
            <SaveIcon />
        </Fab>

        {/* Change tab when form state is dirty */}
        <SaveDialog
            id={`dialog-discard-profile-save`}
            negativeText="Discard"
            open={showTabChangeSaveDialog.show}
            setOpen={(open: boolean) => {
                setShowTabChangeSaveDialog({ show: open, tabValue: showTabChangeSaveDialog.tabValue });
            }}
            positiveAction={() => tabChangePositiveSaveAction()}
            negativeAction={() => {
                // Restore form to previous form state before continuing to tab
                reset();
                setTabValue(showTabChangeSaveDialog.tabValue);
            }}
        >
            <p className={classes.dialogContent}>
                There are unsaved changes.
                Do you wish to save them?
            </p>
        </SaveDialog>

        {/* Navigate away from page when form state is dirty */}
        <SaveDialog
            id={`dialog-cancel-profile-save`}
            negativeText="Discard and continue"
            open={showNavSaveDialog}
            setOpen={setShowSaveNavSaveDialog}
            positiveAction={() => navigatePositiveSaveAction()}
            negativeAction={() => {
                // Continue with navigation without submitting
                routerHistory.push(navigateOnSave);
            }}
        >
            <p className={classes.dialogContent}>
                There are unsaved changes.
                Do you wish to save them?
            </p>
        </SaveDialog>

        {/* Mobile phone verification dialog */}
        <VerificationDialog
            openActionDialog={openVerificationDialog}
            setOpenActionDialog={setOpenVerificationDialog}
            positiveText="Verify and save"
            request={verificationRequest}
            successAction={verificationSuccessAction}
        />

        {profileIsMissingOrIncomplete && <RequiredFields
            existingUserProfile={userProfile}
            customFields={customFields}
            profileIsMissingOrIncomplete={profileIsMissingOrIncomplete}
            setProfileIsMissingOrIncomplete={setProfileIsMissingOrIncomplete}
            populateFormData={populateFormData}
            destination={props.destination}
            setDestination={props.setDestination}
        />}
    </div>
    );
}

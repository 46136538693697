import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      margin: "auto",
      position: "absolute",
      top: "-10%",
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1000000
    },
  })
);

/**
 * Properties used for the `Spinner` component.
 */
interface SpinnerProps {
  size?: number | string;
}

/**
 * The `Spinner` component is used to communicate that an ongoing action is in progress.
 * 
 * @param props {@link SpinnerProps Properties} for the `Spinner` component.
 * @returns A JSX element which displays an animated circular pattern.
 */
export default function Spinner(props: SpinnerProps) {
  const classes = useStyles();

  return (
    <div className={classes.root} id={"spinner"}>
      <CircularProgress color="secondary" size={props.size} />
    </div>
  );
}

import React, { useContext, useState } from "react";
import clsx from "clsx";
import {
    Box,
    Typography,
    FormControlLabel,
    Switch,
    Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomFieldRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs";
import { InputType } from '@cbtravel/common/lib/web/common/enumerations/input-type';
import ConfirmActiveDialog from "./ConfirmActiveDialog";
import { UserContext } from '../../../components/shared/UserContext';
import { ViewIcon } from '../../../icons/Icons';
import Pencil from '../../../icons/Pencil';
import { Configuration } from "@cbtravel/common/shared/config/client-config";
import { CustomFieldCreationPermission } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-creation-permission";

const useStyles = makeStyles(() => ({
    fieldType: {
        color: '#1F2532',
        fontSize: '14px',
    },
    customFieldNumber: {
        color: '#808080',
        fontSize: '.8rem',
        textTransform: 'uppercase',
    },
    primaryField: {
        fontSize: '1.4rem',
        color: '#1F2532',
        overflowWrap: 'break-word',
        '& .MuiSvgIcon-root': {
            marginBottom: 7,
        },
    },
    primaryFieldBox: {
        float: 'left',
        width: '24rem',
    },
    displayPriority: {
        textAlign: 'right',
        color: "#1F2532",
        // float: 'right',
        paddingRight: '18px',
        fontSize: '13px',
    },
    greyRow: {
        backgroundColor: '#F4F4F4',
        marginBottom: '1rem',
        padding: '16px 15px 16px 25px',
        minWidth: '50rem',
    },
    borderLeft: {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        paddingRight: '1rem',
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
    },
    activeBadge: {
        borderRadius: 4, background: '#E4EADE', padding: '2px 6px', marginTop: 3, height: 'auto', textAlign: 'center', display: 'inline-flex',
        '& .MuiTypography-body1': { color: '#417505', fontSize: 11, textTransform: 'uppercase', },
    },
    customFieldActive: {
        marginTop: 3, marginLeft: -4,
        "& .MuiFormControlLabel-label.MuiTypography-body1": {
            color: "#808080",
            fontSize: 13,
        },
    },
    customFieldInactive: {
        marginTop: 3, marginLeft: -3,
        "& .MuiFormControlLabel-label.MuiTypography-body1": {
            color: "#808080",
            fontSize: 13,
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    nonActionIcon: {
        cursor: 'default',
    },
    tooltipLabel: { fontSize: '11px', pointerEvents: "auto", },
    active: {
        marginLeft: 5,
        color: "#00467E",
    },
    inactive: {
        color: "#C9C7C7",
    },
    customStandardLabel: {
        color: "#1F2532",
    },
    //internally viewable field
    triangle: {
        // position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #00467F",
        borderRight: "15px solid transparent",
        marginTop: '-75px',
        marginLeft: '-25px',
        marginRight: '12px',
    },
}));

interface CustomFieldRowProps {
    customField: CustomFieldRS;
    displayPriority: number;
    openCustomField: () => void;
    // deleteCustomField: (udid: CustomFieldRS) => void;
    updateStatus: (event: React.ChangeEvent<HTMLInputElement>, customField: CustomFieldRS) => void;
}

export default function CustomFieldDataRow(props: CustomFieldRowProps) {
    const classes = useStyles();
    const userState = useContext(UserContext);
    // const snackbar = useCustomSnackbar();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(props.customField.activeStatus);
    const [event, setEvent] = useState<React.ChangeEvent<HTMLInputElement>>();
    const { customField: customField } = props;
    const customFieldNumbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20];

    /**
     * Handle the event when user toggle the button
     * @param event 
     */
    const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(true);
        setShowDialog(true);
        setEvent(event);
    }
    /**
     * When user click confirm button, it should save the data
     */
    const handleConfirmation = () => {
        if (event !== undefined) {
            props.updateStatus(event, props.customField);
        }
        setShowDialog(false);
    }
    /**
     * When user close the dialog it should close and reset toggle
     */
    const handleClose = () => {
        setShowDialog(false);
        setChecked(false);
    }

    return (
        <React.Fragment>

            <Box className={clsx(classes.flexRow, classes.greyRow,)}>
                <Box className={classes.flexRow}>
                    {Configuration.UdidConfiguration.find(config => config.number === props.customField.number && config.fieldCreation === CustomFieldCreationPermission.Internal) &&
                        <Tooltip title="Internally viewable field" placement="top">
                            <Box className={classes.triangle} />
                        </Tooltip>}
                    <Box pr={2}>
                        <Typography className={classes.customFieldNumber}>Field {customField.number}</Typography>
                        {(customFieldNumbers.includes(customField.number)) ?
                            <Typography className={classes.customStandardLabel}>Custom</Typography>
                            : <Typography className={classes.customStandardLabel}>Standard</Typography>}
                    </Box>
                    <Box className={clsx(classes.flexColumn, classes.borderLeft,)}>
                        <Box className={classes.primaryFieldBox} ml={2.5}>
                            <Box className={classes.nonActionIcon}>
                                <Typography className={classes.primaryField}>{customField.name}
                                    {customField.noteInternal && <Tooltip title={props.customField.noteInternal} placement="top">
                                        <InfoOutlinedIcon className={classes.tooltipLabel} />
                                    </Tooltip>}
                                </Typography>
                            </Box>
                            <Typography className={classes.fieldType}>{customField.inputType === InputType.TextBox ? "Freeform" : "Drop-down"}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.flexRow}>
                    {/* This should trigger the ConfirmActiveDialog when switched to active */}
                    <Box style={{ width: 100, }}>
                        {!customField.activeStatus ? (<Typography className={classes.inactive}>Inactive</Typography>) : (
                            <Box >
                                <Typography className={classes.active} component="span">Active</Typography>
                            </Box>)}
                    </Box>
                    <Box p={2} className={clsx(classes.borderLeft, classes.flexRow)}>
                        {!customField.activeStatus ? (<Box className={classes.pointer} onClick={props.openCustomField}>
                            <Pencil width="24" height="24" color="#808080" />
                        </Box>) : (<Box pt={.5} className={classes.pointer} onClick={props.openCustomField}>
                            <Pencil width="24" height="24" color="#808080" />
                        </Box>)}
                    </Box>
                </Box>
            </Box>

            <ConfirmActiveDialog
                setOpen={setShowDialog}
                handleSave={handleConfirmation}
                handleNegativeAction={props.openCustomField}
                open={showDialog}
                handleClose={handleClose}
            />
        </React.Fragment>
    );
}
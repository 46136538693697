import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";
import { VendorLT } from "@cbtravel/common/lib/shared/messages/general/responses/lite/vendor-lt";
import { Box, Divider, Grid, Link, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { RemoveCircleOutline as RemoveIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useContext, useState } from "react";
import { Control, Controller, DeepMap, FieldErrors, UseFieldArrayReturn, UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormTrigger } from "react-hook-form";
import { UserContext } from "../../../../components/shared/UserContext";

import ActionDialog from "../../../../components/ui/Dialog/ActionDialog";
import { formUtils } from "../../../../util/form-utils";
import { LoyaltyInfoFields, ProfileFormInputs } from "../Profile";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        inputWrapper: {
            "& .MuiDivider-root": {
                marginBottom: 24,
            },
        },
        h3: {
            fontSize: 12,
            fontWeight: 300,
            textTransform: "uppercase",
            paddingTop: 10,
            marginBottom: -4,
            display: "block",
        },
        accordion: {
            "& .MuiFormHelperText-root": {
                fontSize: 11,
                color: "#808080",
            },
            "& .MuiInputBase-input": {
                background: "#fff",
            },
            "& .MuiInputBase-root": {
                background: "#fff",
            },
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: 0
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
            },
        },
        addIcon: {
            color: "#00467E",
            marginTop: -4,
        },
        removeIcon: {
            color: "#BC2C2F",
        },
        addText: {
            "& .MuiLink-button": {
                fontSize: 12,
                fontFamily: "Open Sans, Arial, sans-serif",
                fontWeight: 600,
                color: "#00467E",
                marginTop: 0,
                textDecoration: "none",
            },
            "& .MuiSvgIcon-root": {
                marginBottom: -1.5,
                marginRight: -1,
                fontSize: 13,
            },
        },
        removeWrapper: {
            marginTop: -36,
            marginBottom: -36,
            "& .MuiLink-button": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "Open Sans, Arial, sans-serif",
                color: "#BC2C2F",
                textDecoration: "none",
            },
        },
        removeText: {
            "& .MuiSvgIcon-root": {
                marginRight: -1,
                marginBottom: -1.5,
                fontSize: 12,
            },
        },
        whiteBG: {
            background: "#fff",
        },
        flex: {
            display: "flex",
            flexDirection: "column",
            marginLeft: 3,
        },
        h2: {
            fontSize: 13,
            fontWeight: 600,
            lineHeight: 1.5,
        },
        summary: {
            fontSize: 12,
        },
        errorTextField: {
            paddingTop: '5px',
        },
        errorTextCalendar: {
            paddingTop: '2px',
        }
    })
);

interface LoyaltyInfoHotelFormFieldsProps {
    field: LoyaltyInfoFields & { id: string },
    index: number,
    control: Control<ProfileFormInputs>,
    controlLoyaltyInfoHotel: Array<LoyaltyInfoFields & { id: string }>,
    loyaltyInfoHotelFieldArray: UseFieldArrayReturn<ProfileFormInputs, "loyaltyInfosHotel", "id">,
    errors: DeepMap<ProfileFormInputs, FieldErrors>,
    hotelVendors: VendorLT[],
    getValues: UseFormGetValues<ProfileFormInputs>,
    clearErrors: UseFormClearErrors<ProfileFormInputs>,
    setError: UseFormSetError<ProfileFormInputs>,
    trigger: UseFormTrigger<ProfileFormInputs>,
    viewingOtherProfile: boolean
}

export default function LoyaltyInfoHotelFormFields(props: LoyaltyInfoHotelFormFieldsProps) {
    const classes = useStyles();
    const { field, index, control, controlLoyaltyInfoHotel, loyaltyInfoHotelFieldArray, errors, viewingOtherProfile } = props;
    const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
    const { userContext } = useContext(UserContext);

    return (
        <Grid container spacing={3} key={field.id}>
            {/* Display dialog when this item is removed */}
            <ActionDialog
                id={"dialog-discard-${index}"}
                title="Remove hotel?"
                icon={<div className={useStyles().removeIcon}><RemoveIcon /></div>}
                positiveText="Remove"
                negativeText="Cancel"
                dialogType="delete"
                open={showRemoveDialog}
                setOpen={setShowRemoveDialog}
                positiveAction={() => loyaltyInfoHotelFieldArray.remove(index)}
            >
                <p>You will be permanently removing this preferred hotel chain from your profile.</p>
            </ActionDialog>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                    name={`loyaltyInfosHotel.${index}.vendor` as const}
                    control={control}
                    rules={{
                        validate: {
                            vendorRequired: (value: VendorLT) => {
                                if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                    const v = props.getValues(`loyaltyInfosHotel.${index}` as `loyaltyInfosHotel.0`)
                                    const vArr = props.getValues(`loyaltyInfosHotel` as `loyaltyInfosHotel`)
                                    if (vArr.filter(loyalty => loyalty.vendor && loyalty.vendor.vendorId === v.vendor?.vendorId).length > 1)
                                        return "Cannot have multiple Loyalty numbers from the same Vendor."
                                    if (v.loyaltyNumber && (!value || value?.vendorId < 0) && (v.vendor?.vendorId < 0 || !v.vendor)) {
                                        return "Please enter a preferred hotel chain"
                                    }
                                    return true;
                                }
                            },
                            numberRequired: (value: VendorLT) => {
                                if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                    const v = props.getValues(`loyaltyInfosHotel.${index}` as `loyaltyInfosHotel.0`)
                                    if ((value?.vendorId > 0 || v.vendor?.vendorId > 0) && !v.loyaltyNumber) {
                                        props.setError(`loyaltyInfosHotel.${index}.loyaltyNumber` as `loyaltyInfosHotel.0.loyaltyNumber`, {
                                            type: "required",
                                            message: "Please enter a loyalty member number"
                                        })
                                    }
                                    else if ((!value || value?.vendorId < 0) && errors?.loyaltyInfosHotel?.[index]?.loyaltyNumber) {
                                        props.trigger(`loyaltyInfosHotel.${index}.loyaltyNumber` as `loyaltyInfosHotel.0.loyaltyNumber`)
                                    }
                                    return true;
                                }
                            },
                        }
                    }}
                    render={({
                        field: { onChange, ref, onBlur }
                    }) => (
                        <Autocomplete
                            id={`loyalty-hotel-vendor-${index}`}
                            options={props.hotelVendors}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.code == value.code}
                            fullWidth
                            autoSelect
                            autoHighlight
                            value={field.vendor}
                            ref={ref}
                            onBlur={onBlur}
                            onChange={(_, value) => onChange(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id={`ff-hotel-vendor-${index}`}
                                    label="Hotel chain"
                                    placeholder="Select a hotel chain"
                                    variant="outlined"
                                    margin="dense"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={props.errors.loyaltyInfosHotel?.[index]?.vendor ? true : false}
                                />
                            )}
                        />
                    )} />
                <Typography variant='body2' color='error' className={classes.errorTextField}>
                    {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                    {props.errors?.loyaltyInfosHotel?.[index]?.vendor && (props?.errors?.loyaltyInfosHotel?.[index]?.vendor as any).message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box mt={1}>
                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                        <Controller
                            name={`loyaltyInfosHotel.${index}.loyaltyNumber` as const}
                            control={control}
                            rules={{
                                validate: {
                                    vendorRequired: (value: string) => {
                                        if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                            const v = props.getValues(`loyaltyInfosHotel.${index}` as `loyaltyInfosHotel.0`);
                                            if ((value || v.loyaltyNumber) && (v.vendor?.vendorId < 0 || !v.vendor)) {
                                                props.setError(`loyaltyInfosHotel.${index}.vendor` as `loyaltyInfosHotel.0.vendor`, {
                                                    type: "required",
                                                    message: "Please enter a preferred hotel chain"
                                                })
                                            }
                                            else if (!value && errors?.loyaltyInfosHotel?.[index]?.vendor) {
                                                // else {
                                                props.trigger(`loyaltyInfosHotel.${index}.vendor` as `loyaltyInfosHotel.0.vendor`)
                                            }
                                            return true;
                                        }
                                    },
                                    numberRequired: (value: string) => {
                                        if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                            const v = props.getValues(`loyaltyInfosHotel.${index}` as `loyaltyInfosHotel.0`)
                                            if (v.vendor?.vendorId > 0 && !value && !v.loyaltyNumber) {
                                                return "Please enter a loyalty member number";
                                            }
                                            return true;
                                        }
                                    },
                                },
                                maxLength: { value: 50, message: "Max 50 characters" }
                            }}
                            render={({
                                field: { onChange, value, ref, onBlur }
                            }) => (
                                <TextField
                                    id={`ff-loyalty-hotel-number-${index}`}
                                    label="Loyalty member #"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                    onChange={(e) => onChange(e.target.value)}
                                    value={value}
                                    ref={ref}
                                    error={props.errors.loyaltyInfosHotel?.[index]?.loyaltyNumber ? true : false}
                                    InputProps={{ autoComplete: "off" }}
                                />
                            )} />
                    </form>
                </Box>
                <Typography variant='body2' color='error' className={classes.errorTextField}>
                    {props.errors?.loyaltyInfosHotel && props?.errors?.loyaltyInfosHotel?.[index]?.loyaltyNumber?.message}
                </Typography>
            </Grid>

            {/* Don't allow removal of final element */}
            {controlLoyaltyInfoHotel.length > 1 &&
                <Grid
                    container
                    justify="flex-end"
                    className={classes.removeWrapper}
                >
                    <Box component={"div"} className={classes.removeText}>
                        <Typography align="right">
                            <Link href="#" component="button" color="error" id={`link-removeDialog-${index}`}
                                onClick={() => {
                                    setShowRemoveDialog(true);
                                }}>
                                <RemoveIcon className={classes.removeIcon} /> Remove
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
            }
            {controlLoyaltyInfoHotel.length > 1 &&
                index != controlLoyaltyInfoHotel.length - 1 &&
                <Grid className={classes.inputWrapper} item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                </Grid>
            }
        </Grid>

    );
}


import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Collapse,
  IconButton,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { TicketHistoryRS } from "@cbtravel/common/lib/web/messages/general/responses/ticket-history-rs";

import { dateUtil } from "../../../util/date-util"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-head": {
      fontWeight: 700,
    },
    "& .MuiTableRow-root.moreInfoRow": {
      background: "#f9f9f9",
    },
    "& .moreInfoCell": {
      borderBottom: "none",
      padding: 0,
    },
  },
  moreInfoRow: {
    background: "#f9f9f9",
  },
  moreInfoCell: {
    borderBottom: "none",
    padding: 0,
  },
  moreInfoTable: {
    marginBottom: "12px",
    "& .MuiTableCell-root": {
      fontSize: ".75rem",
      lineHeight: 1.4,
    },
  },
  tableHeader: {
    fontWeight: 700,
    fontSize: ".75rem",
  },
});

interface HistoryDataRowProps {
  /** The object containing the data to display. */
  row: TicketHistoryRS,
  /** The timezone to use for times in the ticket history. */
  timeZone: string
}

/**
 * Component for displaying ticket history.
 * 
 * @param props {@link HistoryDataRowProps Properties} for a `HistoryDataRow` component.
 * @returns A JSX element for displaying ticket history.
 */
export function HistoryDataRow(props: HistoryDataRowProps) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow key={row.ticketNumber}>
        <TableCell id={`unused-tickets-history-ticket-number-${row.ticketNumber}`} component="th" scope="row">
          {row.ticketNumber}
        </TableCell>
        <TableCell id={`unused-tickets-history-ticket-status-${row.ticketStatus}`}>{row.ticketStatus}</TableCell>
        <TableCell id={`unused-tickets-history-modified-by-${row.modifiedBy}`}>{row.modifiedBy}</TableCell>
        <TableCell id={`unused-tickets-history-date-${dateUtil.formatDate(new Date(row.dateModified.toString() + "+00:00"), props.timeZone)}`}>{dateUtil.formatDate(new Date(row.dateModified.toString() + "+00:00"), props.timeZone)}</TableCell>
        <TableCell>
          {row.comparisonPropertyList.length > 0 && <IconButton
            id="btn-icon-dropdown"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
      </TableRow>

      <TableRow className={classes.moreInfoRow}>
        <TableCell className={classes.moreInfoCell} colSpan={11}>
          {/* Collapse starts here */}
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table
                aria-label="More info for approvals"
                className={classes.moreInfoTable}
                size="small"
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      className={classes.tableHeader}
                      scope="row"
                    >
                      Field
                    </TableCell>
                    <TableCell
                      component="th"
                      className={classes.tableHeader}
                      scope="row"
                    >
                      Changed from
                    </TableCell>
                    <TableCell
                      component="th"
                      className={classes.tableHeader}
                      scope="row"
                    >
                      Changed to
                    </TableCell>
                  </TableRow>
                  {row.comparisonPropertyList.map((comparisonProperty) => (
                    <TableRow>
                      <TableCell id={`unused-tickets-history-property-name-${comparisonProperty.propertyName}`} width="10%">{comparisonProperty.propertyName}</TableCell>
                      <TableCell id={`unused-tickets-history-changed-from-${comparisonProperty.changedFrom}`} width="200">{comparisonProperty.changedFrom}</TableCell>
                      <TableCell id={`unused-tickets-history-changed-to-${comparisonProperty.changedTo}`} width="200">{comparisonProperty.changedTo}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ClientRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-rs";
import { makeStyles } from "@material-ui/core/styles";
import HRFeedUploadStepper from './HRFeedUploadStepper';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },

}));
export default function HRFeedUpload(props: { activeClient: ClientRS | undefined }) {
    const classes = useStyles();

    return (
        <>
            <HRFeedUploadStepper />
        </>

    );
}
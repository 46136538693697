import { Box, Grid, Link, MenuItem, Paper, Typography, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { ClientController } from "@cbtravel/common/lib/shared/api/general/controllers/client-controller";
import { CustomFieldController } from "@cbtravel/common/lib/shared/api/general/controllers/custom-field-controller";
import { ProfileController } from "@cbtravel/common/lib/shared/api/profiles/profile-controller";
import { ProfileEntry } from "@cbtravel/common/lib/shared/common/enumerations/profile-entry";
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";
import { EntityDepth } from "@cbtravel/common/lib/shared/common/enumerations/entity-depth";
import { PhoneType } from "@cbtravel/common/lib/shared/common/enumerations/phone-type";
import { CustomFieldSourceSearchType } from "@cbtravel/common/lib/shared/common/enumerations/search-types";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { RegexUtils } from '@cbtravel/common/lib/shared/common/regex-utils';
import { CustomFieldListFields } from "@cbtravel/common/lib/shared/messages/approval/responses/custom/custom-field-list-fields";
import { CustomFieldRQ } from "@cbtravel/common/lib/shared/messages/general/requests/custom-field-rq";
import { ClientRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-rs";
import { CustomFieldRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs";
import { CustomFieldValueRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-value-rs";
import { ProfileRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/profile-rs";
import { InputDataType } from "@cbtravel/common/lib/web/common/enumerations/input-data-type";
import { Gender } from "@cbtravel/common/lib/shared/common/enumerations/gender";
import DateFnsUtils from "@date-io/date-fns";
import { InputType } from '@cbtravel/common/lib/web/common/enumerations/input-type';
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from '@material-ui/core/InputAdornment';
import { TodayOutlined } from "@material-ui/icons";
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Control, Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import CustomFieldFieldArray from "../../../components/shared/CustomFieldFieldArray";
import { useCustomSnackbar } from "../../../components/shared/customHooks/useCustomSnackbar";
import Spinner from "../../../components/shared/Spinner";
import { UserContext } from "../../../components/shared/UserContext";
import { PhoneInput } from "../../../components/ui/Input/PhoneInput";
import { phoneUtil } from "../../../util/phone-util";
import { formUtils } from "../../../util/form-utils";
import { DataEntryFlow } from "@cbtravel/common/lib/shared/common/enumerations/data-entry-flow";
import { ClientSettingRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-setting-rs";
import { ClientSettingsController } from "@cbtravel/common/lib/shared/api/general/controllers/client-settings-controller";
import { CustomFieldSource } from '@cbtravel/common/lib/shared/common/enumerations/custom-field-source';
import { CustomFieldAccessibility } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-accessibility";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";

const useStyles = makeStyles((theme) => ({
    root: {
        form: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: 'fit-content',
        },
        "& .MuiGrid-container": {
            margin: "0!important",
        },
        "& .MuiFormControl-marginDense": {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    tooltipLabel: { fontSize: 16, pointerEvents: "auto" },
    bold: {
        fontWeight: 'bold',
    },
    dialogPadding: {
        "& .MuiDialogContent-root:first-child": {
            paddingTop: 0,
        },
        "& .MuiTypography-h1": {
            lineHeight: '1',
        },
        "& .MuiTypography-body2": {
            lineHeight: '1.5',
        },
    },
    instructions: {
        "& .MuiTypography-body2": {
            lineHeight: '1.5',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    errorText: {
        // paddingLeft: '13px',
        paddingTop: 4,
        paddingBottom: '2px'
    },
    errorTextCalendar: {
        paddingTop: '2px',
    },
    errorTextField: {
        paddingTop: '5px',
    },
    disabledLink: {
        cursor: 'default',
        color: '#00000061',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    enabledLink: {
        color: '#00467E',
        '&:hover': {
            textDecoration: "underline"
        }
    }
}));

export type UserFormInputs = {
    UserEmail: string,
    MobilePhone: string,
    LastName: string,
    MiddleName: string,
    FirstName: string,
    DateOfBirth: Date | null,
    Gender: string,
    u99Field: CustomFieldListFields,
    customFieldList: CustomFieldListFields[]
};

interface UserFormProps {
    linkActive: boolean,
    clientId: number | undefined,
    parentName: string,
}

/**
     * Map the custom fields we found on the client to the react hook form input.
     * Export so it can be used in Enroll flow. This method will exclude u99
     * @param customFields the list of custom fields that comes from the passed client id.
     * @returns 
     */
export function getCustomFieldsListFields(customFields: CustomFieldRS[], setCustomFieldsToFix?: Dispatch<SetStateAction<number[]>>): CustomFieldListFields[] {
    // If there are no custom fields then don't try to return any.
    if (!customFields) return [];

    let customFieldValueList = new Array<CustomFieldListFields>();
    customFields = customFields.filter(e => e.number !== 99);

    let customFieldsToFix: number[] = [];

    // Map retrieved custom fields onto type CustomFieldListFields. The reason for using a loop instead of .map
    // is to easily include the logic to assign CustomFieldValueRS or string to the value property depending on the inputType.
    // Getting the type correct will affect if the default values populate in CustomFieldFormFields.
    for (let i = 0; i < customFields.length; i++) {
        if (!customFields[i] || customFields[i].number < 1) {
            continue;
        }
        let newCustomFieldListField: CustomFieldListFields = {
            customFieldNumber: customFields[i].number,
            customFieldId: customFields[i].customFieldId,
            customFieldSource: customFields[i].customFieldSource,
            value: "",
            example: customFields[i].example,
            inputDataType: customFields[i].inputDataType as InputDataType,
            inputMaxLength: customFields[i].inputMaxLength,
            inputMinLength: customFields[i].inputMinLength,
            inputType: customFields[i].inputType,
            activeStatus: customFields[i].activeStatus,
            isRequired: customFields[i].isRequired,
            name: customFields[i].name,
            noteInternal: customFields[i].noteInternal,
            noteExternal: customFields[i].noteExternal,
            number: customFields[i].number,
            position: customFields[i].position,
            reportingCodeId: customFields[i].reportingCodeId,
            customFieldValueList: customFields[i].customFieldValueList,
            accessibility: customFields[i].accessibility
        }

        // Check the input type and assign the appropriate default value.
        if (customFields[i].inputType.toLowerCase() === "list") {
            const matchingDefaultValue = customFields[i].customFieldValueList.find(x => x.value === customFields[i].defaultValue);
            // if we have a default value in the custom field value list or the default value is empty
            if (matchingDefaultValue || customFields[i].defaultValue === '') {
                newCustomFieldListField.value = matchingDefaultValue ?? new CustomFieldValueRS();
            }
            else {
                customFieldsToFix.push(customFields[i].number);
            }
        }
        else {
            newCustomFieldListField.value = customFields[i].defaultValue ? customFields[i].defaultValue : "";
        }

        // only push to the custom field value list if we haven't found any invalid custom field values
        if (customFieldsToFix.length == 0)
            customFieldValueList.push(newCustomFieldListField);
    }

    // if there are more than 0 custom fields found to be invalid, we want to throw an error which will be caught by a snackbar
    if (setCustomFieldsToFix && customFieldsToFix.length > 0) {
        setCustomFieldsToFix && setCustomFieldsToFix(customFieldsToFix);
        let err = new JsonException();
        err.message = `The following custom fields are configured incorrectly, please navigate to the Reporting Fields screen to correct them: ${customFieldsToFix.toString()}`;
        throw err;
    }

    return customFieldValueList
}

export function getU99FieldsListFields(customFields: CustomFieldRS[], currentValue: CustomFieldValueRS = new CustomFieldValueRS()): CustomFieldListFields {
    let u99FieldListField: CustomFieldListFields = {
        customFieldNumber: -1,
        value: "",
        customFieldId: -1,
        customFieldSource: CustomFieldSource.Profile,
        example: "",
        inputDataType: InputDataType.NotApplicable,
        inputMaxLength: -1,
        inputMinLength: -1,
        inputType: InputType.TextBox,
        activeStatus: true,
        isRequired: false,
        name: "",
        noteInternal: "",
        noteExternal: "",
        number: -1,
        position: -1,
        reportingCodeId: -1,
        customFieldValueList: [],
        accessibility: CustomFieldAccessibility.Open
    };

    let u99 = customFields.find(e => e.number === 99);

    // Check the input type and assign the appropriate default value.
    if (u99) {
        if (currentValue.customFieldId === -1) {
            let defaultValue = u99.defaultValue ?
                u99.customFieldValueList.find(cfv => cfv.value === u99?.defaultValue) as CustomFieldValueRS
                : new CustomFieldValueRS();
            currentValue = defaultValue;
        }
        u99FieldListField = {
            customFieldNumber: u99.number,
            customFieldId: u99.customFieldId,
            customFieldSource: u99.customFieldSource,
            value: currentValue,
            example: u99.example,
            inputDataType: u99.inputDataType as InputDataType,
            inputMaxLength: u99.inputMaxLength,
            inputMinLength: u99.inputMinLength,
            inputType: u99.inputType,
            activeStatus: u99.activeStatus,
            isRequired: u99.isRequired,
            name: u99.name,
            noteInternal: u99.noteInternal,
            noteExternal: u99.noteExternal,
            number: u99.number,
            position: u99.position,
            reportingCodeId: u99.reportingCodeId,
            customFieldValueList: u99.customFieldValueList,
            accessibility: u99.accessibility
        }
    }
    return u99FieldListField;
}

export default function UserForm(props: UserFormProps) {
    const classes = useStyles();
    const snackbar = useCustomSnackbar();
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
    const [openFormDialog, setOpenFormDialog] = React.useState(false);
    const [isPageBusy, setIsPageBusy] = useState<boolean>(false);
    const [newUser, setNewUser] = useState<string>("");
    const [client, setClient] = useState<ClientRS>();
    const [clientSettings, setClientSettings] = useState<ClientSettingRS>();
    const [customFields, setCustomFields] = useState<CustomFieldRS[]>([new CustomFieldRS()]);
    const { userContext } = useContext(UserContext);
    const routerHistory = useHistory();
    const [email, setEmail] = useState<string>("");
    const [customFieldsToFix, setCustomFieldsToFix] = useState<number[]>([]);
    type FormInputsExportType = Control<UserFormInputs>

    const {
        handleSubmit,
        formState: { errors, isDirty, isValid, dirtyFields, },
        control,
        setValue,
        getValues,
        reset,
        trigger,
    } = useForm<UserFormInputs>({
        mode: "onBlur",
        defaultValues: {
            FirstName: "",
            MiddleName: "",
            LastName: "",
            UserEmail: "",
            MobilePhone: "",
            DateOfBirth: null,
            Gender: "",
            customFieldList: new Array<CustomFieldListFields>()
        }
    });

    // When "continue to profile" is clicked, it takes you to the user's profile edit page.
    function handleOnClick() {
        routerHistory.push("/user/profile?email=" + email);
    }
    // Set up field array for custom fields. This will be used to render only the necessary required field entries.
    const customFieldArray = useFieldArray({ control: control, name: "customFieldList" });
    const watchU99 = useWatch({ control: control, name: "u99Field" });
    const formRules = {
        userEmail: {
            required: "Please enter a valid email",
            pattern: {
                value: RegexUtils.EMAIL_ADDRESS,
                message: "Please enter a valid email address",
            },
            validate: {
                value: (value: string) => { if (value) { if ((/\s/).test(value)) { return "Please enter a valid email" } } },
            },
            maxLength: { value: 255, message: "Max 255 characters" },
        },
        mobilePhone: {
            validate: {
                required: (value: string) => {
                    if (value === undefined)
                        return "Please enter a valid Mobile Phone";
                    else if (value.trim().length === 0)
                        return "Please enter a valid Mobile Phone";
                },
                value: (value: string) => {
                    if (value && !phoneUtil.isPossibleNumber(value)) {
                        return "Please enter a valid phone number"
                    }
                }
            },
        },
        firstName: {
            required: "Please enter a valid first name",
            maxLength: { value: 50, message: "Max 50 characters" },
        },
        middleName: { maxLength: { value: 50, message: "Max 50 characters" } },
        lastName: {
            required: "Please enter a valid last name",
            maxLength: { value: 50, message: "Max 50 characters" },
        },
        dateOfBirth: {
            validate: {
                required: (value: Date | null) => {
                    if (!value) return "Please enter your date of birth";
                },
                DateInFuture: (value: Date | null) => {
                    let currentDate = new Date();
                    if (value && value > currentDate) {
                        return "Date can not be in the future";
                    }
                },
                MinDate: (value: Date | null) => {
                    let minDate = new Date("1900-01-01T00:00:00");
                    if (value && value <= minDate) {
                        return "Date should not be before minimal date";
                    }
                },
                pattern: (value: Date | null) => {
                    let date: Date | null = null;
                    if (value != null) {
                        date = new Date(value);
                    }
                    if (!(date instanceof Date)
                        || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
                    ) {
                        return "Invalid date format"
                    }
                },
            }
        },
        gender: {
            validate: {
                required: (value: string) => {
                    // we only want to validate if complete profile emails are enabled
                    if (!clientSettings?.enableWelcomeToAirportalEmail && userContext.userType <= UserType.TravelManager && (!value || value.trim().length === 0)) return "Please select a gender";
                }
            }
        }
    };

    const formRulesBasic = {
        userEmail: {
            required: "Please enter a valid email",
            pattern: {
                value: RegexUtils.EMAIL_ADDRESS,
                message: "Please enter a valid email address",
            },
            validate: {
                value: (value: string) => { if (value) { if ((/\s/).test(value)) { return "Please enter a valid email" } } },
            },
            maxLength: { value: 255, message: "Max 255 characters" },
        },
        mobilePhone: {
            validate: {
                value: (value: string) => {
                    if (value && !phoneUtil.isPossibleNumber(value)) {
                        return "Please enter a valid phone number"
                    }
                }
            },
        },
        firstName: {
            required: "Please enter a valid first name",
            maxLength: { value: 50, message: "Max 50 characters" },
        },
        lastName: {
            required: "Please enter a valid last name",
            maxLength: { value: 50, message: "Max 50 characters" },
        },
        dateOfBirth: {
            validate: {
                DateInFuture: (value: Date | null) => {
                    let currentDate = new Date();
                    if (value && value > currentDate) {
                        return "Date can not be in the future";
                    }
                },
                MinDate: (value: Date | null) => {
                    let minDate = new Date("1900-01-01T00:00:00");
                    if (value && value <= minDate) {
                        return "Date should not be before minimal date";
                    }
                },
                pattern: (value: Date | null) => {
                    let date: Date | null = null;
                    if (value != null) {
                        date = new Date(value);
                    }
                    if (date && (!(date instanceof Date)
                        || (date.getTime() !== date.getTime())) // if this is invalid, it will be NaN, which is never equal to NaN
                    ) {
                        return "Invalid date format"
                    }
                },
            }
        }
    };



    // Get the client using the passed client id. This will get populated on the profile when the form is submitted.
    useEffect(() => {
        if (!props.clientId) {
            return;
        }
        GetClient(props.clientId);
        GetClientSettings(props.clientId);
        getCustomFields(props.clientId, true);

    }, [props.clientId])

    function handleU99Change(val: CustomFieldValueRS) {
        if (val && val.value) {
            setValue('u99Field.value', val, { shouldValidate: true });
            getCustomFields(+val.value); //tricky way to convert to number  
            // Clear out the current customFields array so since we are retrieving new custom fields.
            customFieldArray.remove();
        }
    }

    // Initialize the custom fields in the form, so that the data we send to CustomFieldFormFields is compatible.
    useEffect(() => {
        if (!openFormDialog) {
            return;
        }
        if (customFields) {
            let inputs = undefined;
            // if U99 was there, then we need to keep it
            if (watchU99 && typeof watchU99?.value !== 'string')
                inputs = customFieldsToFormInputs(watchU99.value);
            else inputs = customFieldsToFormInputs();
            reset(inputs, { keepDirty: true });
        }
    }, [props.clientId, customFields, openFormDialog])



    /**
    * Gets and sets the list of custom fields for the user's client.
    */
    async function getCustomFields(clientId: number, newRootClient: boolean = false) {
        if (!isPageBusy) {
            setIsPageBusy(true);

        }
        // Get customFields
        let request: CustomFieldRQ = new CustomFieldRQ();
        request.clientId = clientId;
        request.activeSearchType = ActiveSearchType.Active;
        request.customFieldSourceSearchType = CustomFieldSourceSearchType.Profile;

        try {
            let newCustomFields = (await CustomFieldController.Find(userContext.accessToken, request, EntityDepth.Deep)).list;
            // If we are not switching root clients 
            if (!newRootClient) {
                //if new U99-selected-client has u99 configured, replace the old one. We want to keep the old one otherwise
                let newU99 = newCustomFields.find(e => e.number === 99);
                let oldU99 = customFields.find(e => e.number === 99);
                if (!newU99 && oldU99) {
                    newCustomFields.push(oldU99);
                }
            }

            setCustomFields(newCustomFields); //set customField hook.
        }
        catch (e) {
            snackbar.error(e as JsonException)
        }
        finally {
            setIsPageBusy(false);
        }

    }


    // Really just a wrapper for getCustomFields()
    function customFieldsToFormInputs(currentValue: CustomFieldValueRS = new CustomFieldValueRS()) {
        try {
            let currentFormValues = getValues();
            let form: UserFormInputs = {
                ...currentFormValues,
                customFieldList: getCustomFieldsListFields(customFields, setCustomFieldsToFix),
                u99Field: getU99FieldsListFields(customFields, currentValue)
            }
            return form;
        } catch (e) {
            snackbar.error(e as JsonException);
        }
    }

    // Used in form submission, map the inputs on a ProfileRS to send to the backend
    function FormInputsToProfileRS(formInputs: UserFormInputs) {
        let profile = new ProfileRS();

        profile.firstName = formInputs.FirstName;
        profile.middleName = formInputs.MiddleName;
        profile.lastName = formInputs.LastName;
        profile.gender = formInputs.Gender;
        profile.phoneList.push(({ number: formInputs.MobilePhone, phoneType: PhoneType.Mobile }));
        profile.emailAddress = formInputs.UserEmail;
        profile.username = formInputs.UserEmail;

        // The form input for dob needs to be nullable to have the empty default value.
        if (formInputs.DateOfBirth) {
            profile.dateOfBirth = formInputs.DateOfBirth;
        }

        // If the client didn't have any active custom fields, then we don't want to run into any undefined type errors.
        if (formInputs.customFieldList) {
            // Map custom field inputs onto the profile.
            formInputs.customFieldList.forEach(field => {
                profile.customFieldList.push(({
                    number: field.customFieldNumber,
                    value: typeof field.value === 'string' ? field.value : field.value.value,
                    customFieldSource: field.customFieldSource
                }));
            });
        }
        if (formInputs.u99Field.customFieldId !== -1) {
            profile.customFieldList.push(({
                number: 99,
                value: typeof formInputs.u99Field.value === 'string' ? formInputs.u99Field.value : formInputs.u99Field.value.value,
                customFieldSource: formInputs.u99Field.customFieldSource
            }));
        }
        // Map the ClientRS state onto the profile's ClientLT
        if (client) {
            profile.client.accountNumber = client?.accountNumber;
            profile.client.clientId = client?.clientId;
            profile.client.name = client?.name;
        }

        // Set the name of the new user so that we can display the name on save.
        setNewUser(profile.firstName + " " + profile.lastName);

        return profile;
    }

    // Call the Client controller to get the entire ClientRS object, we'll use this to populate the profile on form submission.
    async function GetClient(clientId: number) {
        if (!clientId) {
            return;
        }
        try {
            const clientResponse: ClientRS = await ClientController.Get(userContext.accessToken, clientId, EntityDepth.Shallow);
            if (clientResponse) {
                setClient(clientResponse);
            }
        }
        catch (err) {
            snackbar.error(err as JsonException);
        }
    }
    // use this to check if user is set to recieve welcome emails 'enableWelcomeToAirportalEmail'
    async function GetClientSettings(clientId: number) {
        if (!clientId) {
            return;
        }
        try {
            const clientSettingResponse: ClientSettingRS = await ClientSettingsController.GetRootByClientId(userContext.accessToken, clientId, EntityDepth.Shallow);
            if (clientSettingResponse) {
                setClientSettings(clientSettingResponse);
            }
        }
        catch (err) {
            snackbar.error(err as JsonException);
        }
    }
    async function onSubmit(data: UserFormInputs) {
        setIsPageBusy(true);
        let newProfile = FormInputsToProfileRS(data);
        try {
            let result = await ProfileController.Initialize(userContext.accessToken, newProfile, EntityDepth.Deep, ProfileEntry.ProfileByProxy);
            if (!result) {
                let err = new JsonException();
                err.message = "Failed to add traveler.";
                snackbar.error(err as JsonException);
            }
            setEmail(newProfile.emailAddress); // we need this user email to link to the user's profile when user continues to edit their profile
            setIsPageBusy(false);
            setOpenSaveDialog(true);
            reset({}, { keepValues: true });
        }
        catch (err) {
            setIsPageBusy(false);
            snackbar.error(err as JsonException);
        }
    }

    // Determines whether or not to prompt the user if they want to lose their progress. 
    const handleDirtyClose = () => {
        // isDirty is behaving strangely on this component. It will be set to true despite dirtyFields being empty.
        // So we'll just check both just to be sure that the form is ACTUALLY dirty. The underlying issue is probably something with RHF making a deep comparison
        // to the form default values.
        if (isDirty && Object.keys(dirtyFields).length > 0) {
            setOpenCancelDialog(true);
        }
        else {
            handleCloseAll();
        }
    }

    const handleCloseSaveDialog = () => {
        setOpenSaveDialog(false)
        reset({}, { keepValues: true }); //keep u99
    };

    const handleCloseAll = () => {
        setOpenFormDialog(false);
        setOpenSaveDialog(false);
        setOpenCancelDialog(false);

        // Only notify that user was not added if the form was being typed into then canceled.
        if (isDirty) {
            snackbar.info(`No new user has been added to ${props.parentName}`);
        }

        reset();
    };

    return (
        <div>
            {isPageBusy && <Spinner />}
            {/* The link in the Users page that uses this functional component. */}
            {
                <Button className={props.linkActive ? classes.enabledLink : classes.disabledLink} id="addUserLink"
                    onClick={() => setOpenFormDialog(true)}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box><Typography className={props.linkActive ? classes.enabledLink : classes.disabledLink} variant="body1">Add new user</Typography></Box><Box ml={.5}><AddIcon className={props.linkActive ? classes.enabledLink : classes.disabledLink} /></Box>
                    </Box>
                </Button>
            }
            <Dialog
                maxWidth='lg'
                open={openFormDialog}
                onClose={handleDirtyClose}
                aria-labelledby="form-dialog-title"
            >
                {/* The 'x' icon on the modal. */}
                <IconButton id="closeIcon" aria-label="close" className={classes.closeButton} onClick={handleDirtyClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box ml={-1} mb={4} className={classes.root}>
                        <Grid spacing={2} container >

                            <Grid item xs={12}>
                                <Box mb={2}>
                                    <Typography variant="h1">Add new user</Typography>
                                    <Typography>Current account: {props.parentName}</Typography>
                                </Box>
                            </Grid>
                            <Grid container spacing={2} xs={12} sm={10} md={10} lg={10}>
                                <Grid item xs={12}>
                                    <Typography className={classes.bold} variant="body2">New user's full legal name as it appears on government-issued I.D.:</Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        name="FirstName"
                                        control={control}
                                        defaultValue=""
                                        rules={clientSettings?.enableWelcomeToAirportalEmail && userContext.userType < UserType.TravelManager ? formRulesBasic.firstName : formRules.firstName}
                                        render={({ field: { onChange, value, ref, onBlur } }) => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                autoFocus
                                                id="fName"
                                                label="First *"
                                                onChange={onChange}
                                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                ref={ref}
                                                value={value}
                                                error={errors.FirstName ? true : false}
                                                fullWidth
                                                type="text"
                                            />
                                        )}
                                    />
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        className={classes.errorText}
                                    >
                                        {errors.FirstName && errors.FirstName.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        name="MiddleName"
                                        control={control}
                                        defaultValue=""
                                        rules={formRules.middleName}
                                        render={({ field: { onChange, value, ref, onBlur } }) => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                id="mName"
                                                label="Middle (if applicable)"
                                                onChange={onChange}
                                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                ref={ref}
                                                value={value}
                                                error={errors.MiddleName ? true : false}
                                                fullWidth
                                                type="text"
                                            />
                                        )}
                                    />
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        className={classes.errorText}
                                    >
                                        {errors.MiddleName && errors.MiddleName.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        name="LastName"
                                        control={control}
                                        defaultValue=""
                                        rules={clientSettings?.enableWelcomeToAirportalEmail && userContext.userType < UserType.TravelManager ? formRulesBasic.lastName : formRules.lastName}
                                        render={({ field: { onChange, value, ref, onBlur } }) => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                id="lName"
                                                label="Last *"
                                                onChange={onChange}
                                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                ref={ref}
                                                value={value}
                                                error={errors.LastName ? true : false}
                                                fullWidth
                                                type="text"
                                            />
                                        )}
                                    />
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        className={classes.errorText}
                                    >
                                        {errors.LastName && errors.LastName.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controller
                                        name="Gender"
                                        control={control}
                                        rules={formRules.gender}
                                        render={({
                                            field: { onChange, value, ref, onBlur }
                                        }) => (
                                            <TextField
                                                select
                                                variant="outlined"
                                                margin="dense"
                                                id="legalGender"
                                                label={`Legal gender`}
                                                fullWidth
                                                onChange={onChange}
                                                ref={ref}
                                                onBlur={onBlur}
                                                value={value}
                                                error={errors.Gender ? true : false}
                                                InputProps={{ autoComplete: "off" }}
                                            >
                                                {Object.values(Gender).map((g, i) => (
                                                    <MenuItem key={g} value={g} style={{ minHeight: "2em" }}>
                                                        {g}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {errors.Gender && errors.Gender.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Controller
                                        name="DateOfBirth"
                                        control={control}
                                        rules={clientSettings?.enableWelcomeToAirportalEmail && userContext.userType <= UserType.TravelManager ? formRulesBasic.dateOfBirth : formRules.dateOfBirth}
                                        render={({
                                            field: { onChange, value, ref, onBlur }
                                        }) => (
                                            <>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableFuture
                                                        margin="dense"
                                                        id="dob-picker"
                                                        label={`Date of birth`}
                                                        openTo="year"
                                                        format="MM/dd/yyyy"
                                                        placeholder="MM/DD/YYYY"
                                                        maxDateMessage={""}
                                                        minDateMessage={""}
                                                        invalidDateMessage=""
                                                        views={["year", "month", "date"]}
                                                        inputVariant="outlined"
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onClose={onBlur}
                                                        onChange={onChange}
                                                        fullWidth
                                                        InputProps={{
                                                            autoComplete: "off",
                                                            endAdornment: (
                                                                <InputAdornment position={'end'}>
                                                                    <IconButton color="primary" aria-label="Date of birth">
                                                                        <TodayOutlined />
                                                                    </IconButton>
                                                                </InputAdornment>)
                                                        }}
                                                        error={errors.DateOfBirth ? true : false}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <Typography variant='body2' color='error' className={classes.errorTextCalendar}>
                                                    {errors.DateOfBirth && errors.DateOfBirth.message}
                                                </Typography>
                                            </>
                                        )} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="MobilePhone"
                                        control={control}
                                        rules={clientSettings?.enableWelcomeToAirportalEmail && userContext.userType <= UserType.TravelManager ? formRulesBasic.mobilePhone : formRules.mobilePhone}
                                        render={({
                                            field: { onChange, value, ref, onBlur }
                                        }) => (
                                            <PhoneInput
                                                id="mobilePhone"
                                                label={`Mobile phone number`}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                defaultCountry="US"
                                                international={false}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                value={value}
                                                error={errors.MobilePhone ? true : false}
                                                InputProps={{ autoComplete: "off" }}
                                            />
                                        )} />
                                    <Typography variant='body2' style={{ margin: '5px 0px 0px 45px' }} color='error'>
                                        {errors.MobilePhone && errors.MobilePhone.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Controller
                                        name="UserEmail"
                                        control={control}
                                        defaultValue=""
                                        rules={clientSettings?.enableWelcomeToAirportalEmail && userContext.userType < UserType.TravelManager ? formRulesBasic.userEmail : formRules.userEmail}
                                        render={({ field: { onChange, value, ref, onBlur } }) => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                id="usersEmail"
                                                label="User's work email *"
                                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                ref={ref}
                                                error={errors.UserEmail ? true : false}
                                                onChange={onChange}
                                                value={value}
                                                fullWidth
                                                InputProps={{ autoComplete: "off" }}
                                                type="text"
                                            />
                                        )}
                                    />
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        className={classes.errorText}
                                    >
                                        {errors.UserEmail && errors.UserEmail.message}
                                    </Typography>
                                </Grid>
                                {watchU99 && watchU99.customFieldId !== -1 && watchU99.customFieldValueList &&
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Controller
                                            name={`u99Field.value` as const}
                                            control={control as FormInputsExportType}
                                            rules={{
                                                validate: {
                                                    required: (value: string | CustomFieldValueRS) => {
                                                        if (!clientSettings?.enableWelcomeToAirportalEmail && userContext.userType <= UserType.TravelManager)
                                                            if (typeof value === 'string')
                                                                return value ? true : "This field is required "
                                                            else
                                                                return value.customFieldId === -1 ? "This field is required. " : true;
                                                    }
                                                }
                                            }}
                                            render={({ field: { onChange, value: rhfValue, ref, onBlur } }) => {
                                                return <Autocomplete
                                                    id={`u99fieldvalue`}
                                                    options={watchU99.customFieldValueList}
                                                    getOptionLabel={(option) => option.name === "" ? option.value : option.name}
                                                    getOptionSelected={(option, val) => option.value.toUpperCase() == val?.value.toUpperCase()}
                                                    fullWidth
                                                    autoSelect
                                                    autoHighlight
                                                    value={watchU99.customFieldValueList?.find(f => {

                                                        return f.value === (rhfValue as CustomFieldValueRS).value
                                                    })
                                                    }
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={(_, val) => val === null ? onChange(new CustomFieldValueRS()) : handleU99Change(val)} // if the value is null, return an empty string
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            id={`ff-u99fieldvalue-noteExternal`}
                                                            label={
                                                                <Box mr={-0.5}>
                                                                    {watchU99.name}{" "}
                                                                    {watchU99.noteExternal &&
                                                                        <Tooltip arrow
                                                                            title={watchU99.noteExternal}
                                                                            placement="top"
                                                                        >
                                                                            <InfoOutlinedIcon className={classes.tooltipLabel} />
                                                                        </Tooltip>
                                                                    }
                                                                </Box>
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            error={errors.u99Field ? true : false}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}

                                                        />
                                                    )}
                                                />

                                            }
                                            } />
                                        <Typography variant='body2' color='error' className={classes.errorTextField}>
                                            {errors?.u99Field?.value && (errors?.u99Field?.value as any).message}
                                        </Typography>
                                    </Grid>
                                }


                                {/* Custom component that's meant to be used with a field array and a RHF control object. Used for custom field inputs. */}
                                {customFieldArray.fields.map((field, index) => {
                                    return <CustomFieldFieldArray
                                        key={field.id}
                                        field={field}
                                        index={index}
                                        control={control}
                                        errors={errors}
                                        customFieldValueList={field.customFieldValueList}
                                        requireCustomFields={!clientSettings?.enableWelcomeToAirportalEmail && userContext.userType < UserType.TravelManager}
                                        flow={DataEntryFlow.NewUserCreation}
                                        small={index !== customFieldArray.fields.length - 1 ? false :
                                            customFieldArray.fields.length % 2 === 0 ? false : true}
                                    />
                                })}
                            </Grid>
                            <Grid spacing={2} item xs={12} sm={2} md={2} lg={2}>
                                <Box display="flex" flexDirection="column">
                                    {/* Text on the right of modal that explains to user what the expected functionality is. */}
                                    <Paper>
                                        <Box className={classes.instructions} p={2}>
                                            <Box mt={1}>
                                                <Typography variant="body2">
                                                    Use this form to add a new user to the account.
                                                </Typography>
                                            </Box>
                                            <Box my={1}>
                                                <Typography variant="body2">
                                                    When you click “Save”, an email will be sent to the new user with directions to log in to AirPortal and complete their full travel profile.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                    {/* Save and cancel buttons here. */}
                                    <Box mt={10}>
                                        <Button fullWidth onClick={handleSubmit(onSubmit)} variant="contained" color="primary" disabled={(!(Object.keys(dirtyFields).length > 0 && Object.keys(errors).length === 0)) || customFieldsToFix.length > 0} id="saveButton">
                                            Save
                                        </Button>
                                    </Box>
                                    <Box mt={1}>
                                        <Button fullWidth variant="outlined" color="primary" onClick={handleDirtyClose} id="cancelButton">
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Save dialog box */}
            <Dialog
                maxWidth='xs'
                // style={{width: "35%", marginLeft: "32.5%"}}
                open={openSaveDialog}
                onClose={handleCloseSaveDialog}
                aria-labelledby="form-dialog-title"
            >
                {/* The 'x' icon on the modal. */}
                <IconButton id="closeIcon" aria-label="close" className={classes.closeButton} onClick={handleDirtyClose}>
                    <CloseIcon />
                </IconButton>
                <Box className={classes.dialogPadding} p={1} display="flex" flexDirection="column" justifyContent="center">
                    <DialogContent>
                        <DialogContentText>
                            <Box mt={4} mb={6}>
                                <Typography align="center" variant="h1">Success!</Typography>
                                <Box mt={2} mb={1}>
                                    <Typography align="center" variant="body2">
                                        {newUser} has been added as a new user.
                                    </Typography>
                                </Box> {clientSettings?.enableWelcomeToAirportalEmail && userContext.userType < UserType.TravelManager &&
                                    <Box mb={1}>
                                        <Typography align="center" variant="body2">
                                            An email has been sent with directions to log in to AirPortal where they can complete their full travel profile.
                                        </Typography>

                                    </Box>}
                            </Box>
                        </DialogContentText>
                        <Box my={1}>
                            <Button fullWidth variant="contained" onClick={handleOnClick} color="primary">
                                Continue to profile
                            </Button>
                        </Box>
                        <Box>
                            <Button fullWidth onClick={handleCloseSaveDialog} variant="outlined" color="primary">
                                Add another user
                            </Button>
                        </Box>

                    </DialogContent>
                </Box>
            </Dialog>

            {/* Cancel dialog box */}
            <Dialog
                // style={{width: "35%", marginLeft: "32.5%"}}
                maxWidth='xs'
                open={openCancelDialog}
                onClose={handleCloseAll}
                aria-labelledby="form-dialog-title"
            >
                <Box className={classes.dialogPadding} p={2} display="flex" flexDirection="column" justifyContent="center">
                    <DialogContent>
                        <DialogContentText>
                            <Box mt={4} mb={6}>
                                <Typography align="center" variant="h1">Cancel adding <br />new user?</Typography>
                                <Box mt={2} mb={1}>
                                    <Typography align="center" variant="body2">
                                        You have unsaved user information. If you choose to cancel now, all unsaved information will be lost.
                                    </Typography>
                                </Box>
                            </Box>
                        </DialogContentText>
                        <Box>
                            <Button fullWidth onClick={() => { setOpenCancelDialog(false); }} variant="contained" color="primary">
                                No, continue adding new user
                            </Button>
                        </Box>
                        <Box my={1}>
                            <Button fullWidth onClick={handleCloseAll} variant="outlined" color="primary">
                                Yes, cancel and close window
                            </Button>
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        </div >
    );
}
import React from "react";

import permissions from "./Permissions";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";

/**
 * Checks whether a given role has permission for an action.
 * 
 * @param listOfPermissions This list of permissions to check.
 * @param role The name of the role to check permission for.
 * @param action The name of the action to check for.
 * @returns True if the role can perform the action, false otherwise.
 */
export function check(listOfPermissions: typeof permissions, role: keyof typeof permissions, action: string) {
  const permissionsForRole = listOfPermissions[role];
  if (!permissionsForRole) {
    // role is not present in the permissions
    return false;
  }

  let staticPermissions: string[] = permissionsForRole.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static permissions not provided for action
    return true;
  }

  /*const dynamicPermissions = permissions.dynamic;
 
  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic permissions not provided for action
      return false;
    }
 
    return permissionCondition(data);
  }*/
  return false;
};

/**
 * Properties for the Can component.
 */
interface CanProps {
  /** The name of the role. */
  role: keyof typeof UserType,
  /** The name of the action to check permission for. */
  perform: string,
  /** Function to perform when `role` has permission for the `perform` action. */
  yes: () => JSX.Element | null,
  /** Function to perform when `role` does not have permission for the `perform` action. */
  no: () => JSX.Element | null
}

/**
 * Function for conditionally rendering a component based on whether or not a provided role 
 * has the required permissions.
 * 
 * @param props {@link CanProps Properties} for the Can component.
 * @returns The result of `props.yes()` or `props.no()` depending on whether or not `props.role` has permissions for `props.perform`.
 */
export default function Can2(props: CanProps) {
  return (check(permissions, props.role, props.perform)
    ? props.yes()
    : props.no());
}

import { Dispatch, SetStateAction } from "react";
import Accordion from "./Accordion";
import { iNavLocationState } from '@cbtravel/common/lib/web/interfaces/iNavLocationState';
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';

/**
 * Properties for the `Drawer` component.
 */
interface DrawerProps {
    /** React state setter for navigation status. */
    setNavLocation: Dispatch<SetStateAction<iNavLocationState>>
}

/**
 * Component that wraps the {@link Accordion Accordion} component for navigation.
 * 
 * @param props {@link DrawerProps Properties} for the Drawer component.
 * @returns A JSX element for displaying the Drawer.
 */
export default function Drawer(props: DrawerProps) {

    return (
        <div className="sidebar">
            <div className="sidebar-inner">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <img
                            src={`${process.env.PUBLIC_URL}/img/icon-airportal.png`}
                            className="airportal-icon"
                        />
                        <a href={`${Configuration.AirPortalUrl}`}>
                            <img
                                src={`${process.env.PUBLIC_URL}/img/airportal-logo.png`}
                                className="logo"
                                id="logo"
                            />
                        </a>
                    </div>

                    <div className="sidebar-content">
                        <Accordion setNavLocation={props.setNavLocation} />
                    </div>

                    {/* Removing for now and replacing with Pendo badge icon */}
                    {/* <div className="sidebar-footer">
                        <div className="pendo">
                            <a className="pendo-resource-center" href="#">
                                <i className="fa fa-question-circle fa-lg" />
                                <span>Help</span>
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

import { ClientController } from '@cbtravel/common/lib/shared/api/general/controllers/client-controller';
import { TimeZoneController } from '@cbtravel/common/lib/shared/api/general/controllers/time-zone-controller';
import { UserController } from '@cbtravel/common/lib/shared/api/general/controllers/user-controller';
import { ProfileController } from '@cbtravel/common/lib/shared/api/profiles/profile-controller';
import { ClientSettingsController } from '@cbtravel/common/lib/shared/api/general/controllers/client-settings-controller';
import { JWTUtil } from '@cbtravel/common/lib/shared/auth/index';
import { PhoneType } from '@cbtravel/common/lib/shared/common/enumerations/phone-type';
import { UserType } from '@cbtravel/common/lib/shared/common/enumerations/user-type';
import { JsonException } from '@cbtravel/common/lib/shared/common/exceptions/json-exception';
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { ClientRQ } from "@cbtravel/common/lib/shared/messages/general/requests/client-rq";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { ClientSettingRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-setting-rs';
import { iNavLocationState } from '@cbtravel/common/lib/web/interfaces/iNavLocationState';
import { UserContextRS } from '@cbtravel/common/lib/web/messages/general/responses/custom/user-context-rs';
import { UserState } from '@cbtravel/common/lib/web/messages/general/responses/custom/user-state';
import { PagedList } from '@cbtravel/common/shared/common/paged-list';
import { TimeZoneRS } from '@cbtravel/common/shared/messages/general/responses/time-zone-rs';
import { UserRS } from '@cbtravel/common/shared/messages/general/responses/user-rs';
import { identify } from '@fullstory/browser';
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createStyles,
  makeStyles,
  Theme
} from "@material-ui/core/styles";
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useReducer, useState } from 'react';
import { Redirect, Route, Router, Switch, useHistory, useLocation } from 'react-router-dom';
import Can from '../components/shared/Can';
import ClientSelector from "../components/shared/clientSelector/ClientSelector";
import { useCustomSnackbar } from '../components/shared/customHooks/useCustomSnackbar';
import Spinner from '../components/shared/Spinner';
import { UserContext } from '../components/shared/UserContext';
import { ClientSettingsContext } from '../components/shared/ClientSettingsContext';
import Appbar from '../components/ui/Navigation/Appbar';
import Drawer from "../components/ui/Navigation/Drawer";
import TopBanner from '../components/ui/TopBanner';
import Locations from "../pages/AirPortalAdmin/Locations/Locations";
import ReferenceData from "../pages/AirPortalAdmin/ReferenceData/ReferenceData";
import Vendors from "../pages/AirPortalAdmin/Vendors/Vendors";
import ApprovalRequest from "../pages/BookTravel/ApprovalRequest/ApprovalRequest";
import ConfigureApprovalTypes from "../pages/CompanyAdmin/ConfigureApprovalTypes/ConfigureApprovalTypes";
import ReportingFields from "../pages/CompanyAdmin/ReportingFields/ReportingFields";
import Users from "../pages/CompanyAdmin/Users/Users";
import HRFeedUpload from "../pages/CompanyAdmin/HRFeedUpload/HRFeedUpload";
import UnusedTickets from "../pages/Reporting/UnusedTickets/UnusedTickets";
import Approvals from "../pages/TravelApproval/Approvals/Approvals";
import Profile from "../pages/User/Profile/Profile";
import IncompleteProfiles from '../pages/CompanyAdmin/IncompleteProfiles/IncompleteProfiles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      minHeight: "100vh",
      width: "100%",
      background: "#fff",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  })
);

export default function Home(props: any) {
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const [userContext, setUserContext] = useReducer(userReducer, new UserContextRS());
  const [navLocation, setNavLocation] = useState<iNavLocationState>({ title: "", clientSelector: true });
  const routerHistory = useHistory();
  const routerLocation = useLocation(); //react-router's tool for grabbing url location
  const [activeClient, setActiveClient] = useState<ClientRS | undefined>();
  const [unauthorizedVisit, setUnauthorizedVisit] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const snackbar = useCustomSnackbar();
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [topBannerMessage, setTopBannerMessage] = useState<string>(''); // set the message for the TopBanner
  const [topBannerFirstAndLastName, setTopBannerFirstAndLastName] = useState<string>(''); // set the First and Last name for the TopBanner
  const [clientSettingsContext, setClientSettingsContext] = useReducer(clientSettingsReducer, undefined);

  //access token from login
  const token = authState.accessToken;

  routerHistory.listen((location, action) => {
    if (topBannerMessage) {
      // after navigating, remove TopBanner
      setTopBannerMessage('');
      setTopBannerFirstAndLastName('');
    }
  });

  /**
   * Reducer to use when updating parts of the user state.
   * @param prevState The entire current user state.
   * @param newState Object containing user state properties to update.
   * @returns New user state with updated properties.
   */
  function userReducer(prevState: UserContextRS, newState: Partial<UserContextRS> | UserContextRS) {
    return { ...prevState, ...newState }
  }

  /**
   * Reducer to use when updating parts of the client settings state.
   * @param prevState The entire current client settings state.
   * @param newState Object containing client settings state properties to update.
   * @returns New client settings state with updated properties.
   */
  function clientSettingsReducer(prevState: ClientSettingRS | undefined, newState: Partial<ClientSettingRS> | ClientSettingRS | undefined): ClientSettingRS | undefined {
    if (newState && !prevState) {
      let newPrev: ClientSettingRS = new ClientSettingRS();
      return { ...newPrev, ...newState };
    } else if (newState && prevState) {
      return { ...prevState, ...newState };
    }
  }

  /**
   * handler method for updating the user context
   * @param userContext the new userState to update the context with
   */
  function updateUserContext(userContext: UserContextRS) {
    setUserContext(userContext);
  }

  /**
   * handler method for updating client settings context
   * @param clientSettingsContext new client settings state to update context with
   */
  function updateClientSettingsContext(clientSettingsContext: ClientSettingRS | undefined) {
    setClientSettingsContext(clientSettingsContext);
  }

  //watches for and triggers unauthorized message if a user manually inputs a url they are not permissioned for
  //since we trigger a redirect right after setting this string, it needs to be a state variable/useEffect or we'd lose the value
  //todo confirm the above claim and make sure this can't just be a normal function call.
  useEffect(() => {
    if (unauthorizedVisit.length > 0) {
      snackbar.info(`User Unauthorized: You do not have permission to visit the ${unauthorizedVisit} page.`)
      setUnauthorizedVisit('');
    }
  }, [unauthorizedVisit])

  // this useEffect + the eventListener in the onMount useEffect is for updating user context rs across multiple tabs 
  useEffect(() => {
    // empty first and last name means we've only just mounted the component and created our empty UserContextRS i.e. this is probably a new tab
    // we don't want to overwrite localStorage in this case
    if (!(userContext.userState.firstName && userContext.userState.lastName)) {
      return;
    }
    localStorage.setItem(`session-${userContext.userId}`, JSON.stringify(userContext.userState));

  }, [userContext])

  //* on mount
  // set up contexts and event listener so we can persist them across multiple tabs when applicable
  useEffect(() => {
    /**
    * Gets the client settings of the client specified by clientId and sets the ClientSettingsContext; called in initializeRequiredData
    * @param token the current user's access token
    * @param clientId the client that the current user is under
    */
    async function setUpClientSettingsContext(token: string, clientId: string) {
      try {
        let clientSettings: ClientSettingRS = await ClientSettingsController.GetRootByClientId(token, Number(clientId));

        // if we didn't find anything, return a new clientsettingsRS with defaulted values
        if (clientSettings == null) {
          setClientSettingsContext(new ClientSettingRS());
        }
        else {
          setClientSettingsContext(clientSettings);
        }
      }
      catch (err) {
        snackbar.error(err as JsonException);
      }
    }

    /**
   * Used to setup and persist all initial contexts and set an initial activeClient using Okta token information received from login and data stored in local storage
   * 
   * @param token OKTA token to decode and populate context with the claims
   */
    async function initializeRequiredData(emptyContext: UserContextRS, token: any) {
      // we need an initial UserContext before we can do most other things 
      const initialContext: UserContextRS = emptyContext;

      const decodedToken = JWTUtil.decodeAccessToken(token);
      let userType: UserType = UserType.Traveler;
      switch (decodedToken.UserType) {
        case "1": {
          userType = UserType.Administrator;
          break;
        }
        case "2": {
          userType = UserType.AccountManager;
          break;
        }
        case "3": {
          userType = UserType.Advisor;
          break;
        }
        case "4": {
          userType = UserType.SalesManager;
          break;
        }
        case "5": {
          userType = UserType.TravelManager;
          break;
        }
        case "6": {
          userType = UserType.Traveler;
          break;
        }
        default: {
          userType = UserType.Traveler;
          break;
        }
      }

      initialContext.userState.firstName = decodedToken.FirstName;
      initialContext.userState.lastName = decodedToken.LastName;
      initialContext.userId = decodedToken.UserId;
      initialContext.clientId = decodedToken.ClientId;
      initialContext.accessToken = token;
      initialContext.email = decodedToken.sub;
      initialContext.userType = userType;

      // Check if we have items saved in local storage and populate the appropriate fields based on stored values if so
      const currentState = localStorage.getItem(`session-${initialContext.userId}`);
      if (currentState) {
        // Parse local storage "session" key and update the user context with it
        let localState = JSON.parse(currentState) as UserState;
        initialContext.userState.firstName = localState.firstName;
        initialContext.userState.lastName = localState.lastName;
        initialContext.userState.downloadFormat = localState.downloadFormat;
      }

      try {
        const userResponse: UserRS = await UserController.Get(token, Number(decodedToken.UserId));

        if (userResponse.defaultTimeZoneId == -1) {
          const region = new Intl.DateTimeFormat();
          const options = region.resolvedOptions();
          if (options && options.timeZone) {
            initialContext.timeZone = options.timeZone;
          }
        }
        else {
          const timezone: TimeZoneRS = await TimeZoneController.Get(token, userResponse.defaultTimeZoneId);
          initialContext.timeZone = timezone.name;
        }

        const req = new ClientRQ();
        req.typeIdList = [1, 2, 3, 4]
        const clientResponse: PagedList<ClientRS> = await ClientController.Find(token, req);

        initialContext.clientName = (await clientResponse.list.find(client => client.clientId == Number(decodedToken.ClientId)))!.name;
        let userClient = clientResponse.list.find(client => client.clientId == Number(decodedToken.ClientId));
        if (!userClient) {
          let quickError = new JsonException();
          quickError.message = "User client not found in client hierarchy.";
          snackbar.error(quickError);
          return;
        }
        initialContext.clientName = userClient.name;
        initialContext.viewableClients = clientResponse.list;

      }
      catch (err) {
        // if we can't get the user context set up, there's no use keeping them logged in, nothing will work
        snackbar.error(err as JsonException);
        setTimeout(async () => {
          // Close the user session and sign out
          await oktaAuth.closeSession();
          await oktaAuth.signOut(); 
          
          oktaAuth.tokenManager.clear();
        }, 5000);
      }

      //overwrite the empty user context in state
      setUserContext(initialContext);

      // begin setting up next context: client settings
      setUpClientSettingsContext(token, initialContext.clientId)

      // set initial active client
      let recentClients: ClientRS[] = [];
      // first check local storage 
      if (window.localStorage[`recent-clients-${initialContext.userId}`]) {
        recentClients = JSON.parse(String(window.localStorage.getItem(`recent-clients-${initialContext.userId}`)));
      }
      if (recentClients.length > 0) {
        setActiveClient(recentClients[0]);
      } else {
        // recentClients doesn't exist yet or was empty; use the user's own client id
        const foundClient = initialContext.viewableClients.find(c => c.clientId === Number(initialContext.clientId)) || new ClientRS();
        setActiveClient(foundClient);
      }

    }

    /**
     * e is an event type that does a check on the local storage to see if the storage item that changed is "session"
     * And if it is, update the user context to reflect the change in local storage. This allows us to sync changes to the user state on multiple tabs/windows
     * @param e a local storage update event caught by the event listener
     *
     */
    function onReceiveMesssage(e: StorageEvent) {
      const { key, newValue } = e;
      if (key === `session-${userContext.userId}` && newValue) {
        setUserContext({ userState: JSON.parse(newValue) as UserState });
      }
    }

    // Prevent initializations if existing token is expired
    if (oktaAuth.tokenManager.hasExpired(token))
      return;
    else {
      // add an event listener watching local storage. This allows us to update userContext across multiple tabs
      window.addEventListener('storage', onReceiveMesssage);
      initializeRequiredData(userContext, token.accessToken);
    }

    return () =>
      window.removeEventListener('storage', onReceiveMesssage);
  }, [])

  const screenScript: string = "var scrn = screen.width + ' x ' + screen.height;" + "\n" +
    "var w = window," + "\n" +
    "d = document," + "\n" +
    "e = d.documentElement," + "\n" +
    "g = d.getElementsByTagName('body')[0]," + "\n" +
    "x = w.innerWidth || e.clientWidth || g.clientWidth," + "\n" +
    "y = w.innerHeight|| e.clientHeight|| g.clientHeight;" + "\n" +
    "var brwsr = x + ' × ' + y;"

  useEffect(() => {
    let script: HTMLScriptElement | undefined = undefined;
    if (Configuration.Environment === "Production" && authState.isAuthenticated && userContext.userId && userContext.email) {
     
      // escapes single and double quote characters
      // adapted from http://locutus.io/php/strings/addslashes/
      const escapeQuotes = (str: string) => {
        return (str + '').replace(/[\\"']/g, '\\$&');
      }

      const setUpPendo = async () => {
        const token = userContext.accessToken;
        try {
          const detailedUser = await UserController.Get(token, Number(userContext.userId));
          script = document.createElement('script');
          script.id = 'pendo-script';
          const pendoScript: string = screenScript +
            "(function (apiKey) {" + "\n" +
            "(function (p, e, n, d, o) {" + "\n" +
            "var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = [];" + "\n" +
            "v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) {" + "\n" +
            "o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };" + "\n" +
            "})(v[w]);" + "\n" +
            "y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';" + "\n" +
            "z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);" + "\n" +
            "})(window, document, 'script', 'pendo');" + "\n" +

            "pendo.initialize({" + "\n" +
            "visitor: {" + "\n" +
            "id: '" + detailedUser.userId + "', \n" +
            "name:'" + escapeQuotes(detailedUser.firstName) + " " + escapeQuotes(detailedUser.lastName) + "', \n" +
            "email:'" + escapeQuotes(detailedUser.emailAddress) + "', \n" +
            "phone:'" + detailedUser.phoneList.find(p => p.phoneType == PhoneType.Mobile)?.number + "', \n" +
            "role:'" + detailedUser.userType + "', \n" +
            "created:'" + detailedUser.dateCreated + "', \n" +
            "screenSize:   scrn," + "\n" +
            "browserSize:  brwsr" + "\n" +
            "}," +

            "account: {" + "\n" +
            "id: '" + detailedUser.client.clientId + "', \n" +
            "name: '" + escapeQuotes(detailedUser.client.name) + "' \n" +

            "}" + "\n" +
            "});" + "\n" +
            "})('95c6e244-8c81-4d89-4a61-9ec2c60cb063');";
          script.text = pendoScript;
          document.head.appendChild(script);
        }
        catch (err) {
          //need to handle the error here
          snackbar.error(err as JsonException)  // not sure if our regular error handler will handle the vanilla js stuff gracefully
        }
      }
      setUpPendo();
    }
    // Cleanup script when exiting
    return () => {
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [authState.isAuthenticated, userContext.userId]);

  //todo: Remove this entire UseEffect once v2 is sunset
  useEffect(() => {
    if (userContext.viewableClients.length === 0) return;
    //check url for cid, which means they linked from v2 and we want to set that as their active client
    if (window.location.search.includes("cid=")) {
      const searchString: string = window.location.search;
      const address: number = searchString.indexOf("cid=") + 4
      let endAddress: number = searchString.indexOf('&', address)
      const v2Default = endAddress === -1 ? searchString.slice(address) : searchString.slice(address, endAddress) //if there is no parameter following cid, do not include an end address
      const foundClient = userContext.viewableClients.find(function (c: ClientRS) { return c.clientId === Number(v2Default) }) || new ClientRS();
      if (foundClient.clientId !== -1) {
        setActiveClient(foundClient) // set active client for any other components that need this information
      }
    }
  }, [userContext.viewableClients])

  // once the client settings context and user context have been loaded and set, we can check the user profile
  useEffect(() => {
    /**
     * Checks a user's profile for required fields. If any required fields are missing then sets the 
     * state flag to show the required fields page.    
     * @param token The user's access token so they can access the API.
     * @param userId The user's id to be checked.
     */
    async function checkUserProfile(token: string, userEmail: string) {

      try {
        let profileCheck: Response = await ProfileController.ProfileRequiredFieldsCheck(token, userEmail);

        if (profileCheck.status === 400) {
          setDestination(routerHistory.location.pathname);
          routerHistory.push("/user/profile");
        }
      } catch (err) {
        snackbar.error(err as JsonException);
      }
    }

    if (userContext.userId != "" && clientSettingsContext?.isProfilesEnabled) {
      checkUserProfile(userContext.accessToken, userContext.email);
    }

    // at this point, if we pass these checks, everything should be good to go
    if (userContext.userId != "" && clientSettingsContext?.clientId && activeClient?.clientId !== -1) {
      // identify user for fullstory only after we've authenticated
      identify(userContext.userId, {
        displayName: userContext.userState.firstName + " " + userContext.userState.lastName,
        email: userContext.email
      });

      // finally turn off the loading spinner
      setShowSpinner(false);
    }

  }, [userContext, clientSettingsContext, activeClient])


  return !authState.isAuthenticated ? (
    <Redirect to="/login" />
  ) : !showSpinner ? (
    <UserContext.Provider value={{
      userContext: userContext,
      setUserContext: updateUserContext
    }}>
      <ClientSettingsContext.Provider value={{
        clientSettingsContext: clientSettingsContext,
        setClientSettingsContext: updateClientSettingsContext
      }}>
        <Router history={routerHistory}>
          <CssBaseline />
          <div className="app" >
            {<Drawer setNavLocation={setNavLocation} />}
            <main className={classes.content}>
              {topBannerMessage !== '' ?
                <TopBanner
                  message={topBannerMessage}
                  firstAndLastName={topBannerFirstAndLastName}
                />
                : <></>}
              <Appbar pageTitle={navLocation.title} />
              <ClientSelector
                activeClient={activeClient}
                setActiveClient={setActiveClient}
                display={navLocation.clientSelector && routerLocation.hash !== "#form"}
              />

              <Switch>
                {/* Book Travel */}
                <Redirect exact from="/" to={Configuration.DefaultLandingPage} />

                {/* Book Travel */}
                <Route path="/booktravel/approvalrequest">
                  <Can
                    role={userContext.userType}
                    perform="travel-request:visit"
                    yes={() => <ApprovalRequest activeClient={activeClient} />}
                    no={() => {
                      setUnauthorizedVisit("Approval Request");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>

                {/* Travel Approvals */}
                <Route path="/travelapproval/approvals">
                  <Can
                    role={userContext.userType}
                    perform="approval-beta:visit"
                    yes={() => <Approvals activeClient={activeClient} />}
                    no={() => {
                      setUnauthorizedVisit("Approvals");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>

                {/* Reporting */}
                <Route path="/reporting/unusedtickets">
                  <Can
                    role={userContext.userType}
                    perform="savingstracker:visit"
                    yes={() => <UnusedTickets activeClient={activeClient} />}
                    no={() => {
                      setUnauthorizedVisit("Unused Tickets");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>

                {/* Company Admin */}
                <Route path="/companyadmin/configureapprovaltypes">
                  <Can
                    role={userContext.userType}
                    perform="configure-approval:visit"
                    yes={() => (
                      <ConfigureApprovalTypes activeClient={activeClient} />
                    )}
                    no={() => {
                      setUnauthorizedVisit("Configure Approval Types");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>
                {/* Report Settings */}
                <Route path="/companyadmin/reportingfields">
                  <Can
                    role={userContext.userType}
                    perform="reporting-fields:visit"
                    yes={() => (
                      <ReportingFields activeClient={activeClient} />
                    )}
                    no={() => {
                      setUnauthorizedVisit("Reporting Fields (Beta)");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>
                {/* User Search */}
                <Route path="/companyadmin/users">
                  <Can
                    role={userContext.userType}
                    perform="user-search:visit"
                    yes={() => {
                      return <Users activeClient={activeClient} />
                    }}
                    no={() => {
                      setUnauthorizedVisit("Users (Beta)");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                  </Route>
                    {/* Incomplete Profiles */}
                <Route path="/companyadmin/incompleteprofiles">
                  <Can
                    role={userContext.userType}
                    perform="incomplete-profiles:visit"
                    yes={() => {
                      return <IncompleteProfiles activeClient={activeClient} />
                    }}
                    no={() => {
                      setUnauthorizedVisit("Incomplete Profiles");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>
                {/* HR feed upload */}
                <Route path="/companyadmin/hrfeedupload">
                  <Can
                    role={userContext.userType}
                    perform="hrfeedupload:visit"
                    yes={() => {
                      return <HRFeedUpload activeClient={undefined} />
                    }}
                    no={() => {
                      setUnauthorizedVisit("HR feed upload (Beta)");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>

                {/* User */}
                <Route path="/user/profile">
                  <Can
                    role={userContext.userType}
                    perform="user-profile:visit"
                    yes={() =>
                      <Profile
                        destination={destination}
                        setDestination={setDestination}
                        setTopBannerMessage={setTopBannerMessage}
                        setTopBannerFirstAndLastName={setTopBannerFirstAndLastName}
                      />
                    }
                    no={() => {
                      setUnauthorizedVisit("User Profile");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>

                {/* AirPortal Admin */}
                <Route path="/airportaladmin/vendors">
                  <Can
                    role={userContext.userType}
                    perform="vendors:visit"
                    yes={() => (
                      <Vendors activeClient={activeClient} />
                    )}
                    no={() => {
                      setUnauthorizedVisit("Vendors (Beta)");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>
                <Route path="/airportaladmin/locations">
                  <Can
                    role={userContext.userType}
                    perform="locations:visit"
                    yes={() => (
                      <Locations activeClient={activeClient} />
                    )}
                    no={() => {
                      setUnauthorizedVisit("Locations (Beta)");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>
                <Route path="/airportaladmin/referencedata">
                  <Can
                    role={userContext.userType}
                    perform="reference-data:visit"
                    yes={() => (
                      <ReferenceData activeClient={activeClient} />
                    )}
                    no={() => {
                      setUnauthorizedVisit("Reference Data (Beta)");
                      return <Redirect to={Configuration.DefaultLandingPage} />;
                    }}
                  />
                </Route>

                {/* v2 Routes */}
                <Route path="/dashboard/manager" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/dashboard/manager?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/dashboard/agent" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/dashboard/agent?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/dashboard/mytravel" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/dashboard/mytravel?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/airtinerary/airtineraries/lookup" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/airtinerary/airtineraries/lookup?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/airtinerary/airtineraries/present" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/airtinerary/airtineraries/present?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/airtinerary/airtineraries/past" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/airtinerary/airtineraries/past?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/hotelattachments/reporting/unknownstays" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/hotelattachments/reporting/unknownstays?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/vpay/hotel/checkins" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/vpay/hotel/checkins?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/travelrequest/index/online" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/travelrequest/index/online?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/travelrequest" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/travelrequest?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/groups/request/travel" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/groups/request/travel?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/groups/inprocess/submitted" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/groups/inprocess/submitted?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/groups/finalize/future" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/groups/finalize/future?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/groups/manage/groups" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/groups/manage/groups?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/airtinerary/approval" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/airtinerary/approval?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/airtinerary/approval/approved" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/airtinerary/approval/approved?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/travelapproval/canceledtrips" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/airtinerary/approval/canceled?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/securitylogic/map" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/securitylogic/map?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/securitylogic/report/insight" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/securitylogic/report/insight?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/securitylogic/assistance" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/securitylogic/assistance?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/securitylogic/travelalerts" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/securitylogic/travelalerts?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/securitylogic/notifications" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/securitylogic/notifications?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/savingstracker/index2" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/savingstracker/index2?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/savingstracker/scorecard" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/savingstracker/scorecard?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/vpay/lookup" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/vpay/lookup?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/domo" component={() => {
                  window.location.replace(`https://cbtravel.domo.com`);
                  return null;
                }} />
                <Route path="/ibank" component={() => {
                  window.location.replace(`https://apps.ciswired.com/?christoph`);
                  return null;
                }} />
                <Route path="/manager/travelgpa" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/manager/travelgpa?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/information" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/information?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/people" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/people?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/contracts/air" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/contracts/air?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/people/contacts" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/people/contacts?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/helpdesk" component={() => {
                  window.location.replace(`https://cbtravel.atlassian.net/servicedesk/customer/portals`);
                  return null;
                }} />
                <Route path="/travelpolicy" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/travelpolicy?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/servicefees/payment" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/servicefees/payment?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/servicefees" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/servicefees?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/reporting/fields" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/reporting/fields?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/report" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/report?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/technology/general" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/technology/general?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/airtinerary" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/airtinerary?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/vpayhotel/card" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/vpayhotel/card?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/userrequests" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/userrequests?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/integrations" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/integrations?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/consult" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/consult?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/notes" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/notes?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/accountnotes" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/accountnotes?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/log" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/log?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/agents/search" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/agents/search?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/airtinerary/agentapproval/mytasks" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/airtinerary/agentapproval/mytasks?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/agents/team" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/agents/team?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/andavo/admin" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/andavo/admin?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/employee/carriercontracts2" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/employee/carriercontracts2?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/client/clientdirectory" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/client/clientdirectory?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/employee/directory" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/employee/directory?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/gdsscripts" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/gdsscripts?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/employee/index/forwardtotmu" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/employee/index/forwardtotmu?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/commissions/statement/collected" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/commissions/statement/collected?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/commissions/unclaimed" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/commissions/unclaimed?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/vpay/billing" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/vpay/billing?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/voidlogger" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/voidlogger?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/airtinerary/import" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/airtinerary/import?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/vendorprofiles/airvendor" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/vendorprofiles/airvendor?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/locationprofiles/airport" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/locationprofiles/airport?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/serviceprofiles/aircraft" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/serviceprofiles/aircraft?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/office" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/office?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/tmc" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/tmc?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/analytics/siteanalytics2" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/analytics/siteanalytics2?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/analytics/profile2/export" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/analytics/profile2/export?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/analytics/tripit2" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/analytics/tripit2?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/permissions/permission2" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/permissions/permission2?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/sap2" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/sap2?cid=${activeClient?.clientId}`);
                  return null;
                }} />
                <Route path="/qmanager2" component={() => {
                  window.location.replace(`${Configuration.AirPortalUrl}/qmanager2?cid=${activeClient?.clientId}`);
                  return null;
                }} />


              </Switch>
            </main>
          </div>
        </Router>
      </ClientSettingsContext.Provider>
    </UserContext.Provider>
  ) : (
    <Spinner />
  );
}


import React, { Dispatch, SetStateAction, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle
 } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        textAlign: "center",
        width: 400,
      },
      "& .MuiDialogTitle-root": {
        padding: "16px 24px 0",
      },
      "& .MuiDialogContent-root": {
        padding: "0 24px",
      },
      "& .MuiTypography-body1": {
        lineHeight: 1.6,
        fontSize: "13px",
        color: "#4D4D4D",
      },
      "& .MuiTypography-h6": {
        fontWeight: 600,
      },
    },
    strong: { fontWeight: 600 },
    iconApprove: {
      margin: "8px auto 14px",
      color: "#417505",
      width: "44px",
      height: "44px",
      "& .MuiSvgIcon-root": {
        color: "#fff",
        marginTop: "10px",
      },
      "& svg": {
        fontSize: "30px",
      },
    },
    iconDeny: {
      margin: "8px auto 14px",
      color: "#bc2c2f",
      width: "44px",
      height: "44px",
      "& .MuiSvgIcon-root": {
        color: "#fff",
        marginTop: "10px",
      },
      "& svg": {
        fontSize: "30px",
      },
    },
    dialogActions: {
      padding: "16px 24px",
      "& .MuiButton-contained.btnApprove": {
        backgroundColor: "#417505",
      },
      "& .MuiButton-contained.btnDeny": {
        backgroundColor: "#bc2c2f",
      },
    },
    btnCancel: {
      color: "#00467E",
      marginLeft: "0 !important",
      fontSize: "13px",
    },
  })
);

interface ApprovalDialogProps {
  openActionDialog: boolean;
  setOpenActionDialog: Dispatch<SetStateAction<boolean>>;
  approvalType: string;
  travelerName: string;
  destination: string;
  action: string;
  onConfirm: (wasApproved:boolean, comment:string) => void;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  bulkApprovalCount:number;
}

export default function ApprovalDialog(props: ApprovalDialogProps) {
  const classes = useStyles();
  const {
    openActionDialog,
    setOpenActionDialog,
    approvalType,
    travelerName,
    destination,
    action,
    onConfirm,
    bulkApprovalCount
  } = props;
  const [commentValue, setCommentValue] = useState<string>("");


  /**
   * Handles the actions when clicking the confirm button in the dialog.
   */
  function handleConfirm(){
    action === "Approve" ? onConfirm(true, commentValue) : onConfirm(false, commentValue);
    //start main spinner if this is not a bulk request
    bulkApprovalCount === 0 && props.setShowSpinner(true)
    setOpenActionDialog(false);
  }

  return (
    <React.Fragment>
      <Dialog
        id="dialog-approval"
        open={openActionDialog}
        onClose={() => {
          setOpenActionDialog(false);
        }}
        aria-labelledby="Approval action dialog"
        aria-describedby="Approval action dialog"
        className={classes.dialog}
      >
        <DialogTitle id="dialog-title">
          <Box>
            <div
              className={
                action === "Approve" ? classes.iconApprove : classes.iconDeny
              }
            >
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
          </Box>
          { bulkApprovalCount > 0 ?
            `Bulk ${action} ${bulkApprovalCount} requests?`
           : `${action} ${approvalType}?`
          }
        </DialogTitle>
        <DialogContent id="dialog-content">
          <DialogContentText>
            <Typography variant="body1">
              { bulkApprovalCount === 0 && 
                <p>
                <strong>{travelerName}</strong> is requesting authorization for {approvalType !== "User Enrollment" ?
                  <> an upcoming trip to <strong>{destination}</strong> </>
                  : "their user enrollment"}
                </p> 
              }
              <Box mt={4}>
                <TextField
                  id="ff-approver-comments"
                  label="Approver comments (optional)"
                  multiline
                  fullWidth
                  rows={2}
                  variant="outlined"
                  inputProps={{ maxLength: 500 }}
                  onChange={(event) => setCommentValue(event.target.value)}
                  value={commentValue}
                />
              </Box>
            </Typography>
          </DialogContentText>
        </DialogContent>

        <div className={classes.dialogActions}>
          <Button
            id="btn-approve"
            onClick={handleConfirm}
            variant="contained"
            className={
              action === "Approve" ? "btnApprove" : "btnDeny"
            }
            fullWidth
          >
            {action}
          </Button>
          <Button
            id="btn-cancel"
            className={classes.btnCancel}
            onClick={() => {
              setOpenActionDialog(false);
            }}
            fullWidth
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

import React, { useEffect, useState, } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ActionDialog from "../../../components/ui/Dialog/ActionDialog";
import WarningIcon from '@material-ui/icons/Warning';
import CloseDialogIcon from '../../../components/ui/CloseIcon';

const useStyles = makeStyles(() => ({

    dialogContent: {
        lineHeight: 1.5,
    },

    warningAction: {
        margin: "25px auto 35px",
        background: "#fff",
        width: 50,
        height: 50,
        "& .MuiSvgIcon-root": {
            color: "#00467F",
            fontSize: 50,
            marginTop: -10,
        },
    },
    dialogActions: {
        padding: "16px 32px 20px",
        "& .MuiButton-contained:hover": {
            backgroundColor: "rgba(0,166,207, .8)",
        },
    },
    saveFab: {
        position: 'relative',
        color: "#ffffff",
        backgroundColor: "#00467F",
        boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 1%), 0px 1px 18px 0px rgb(0 0 0 / 11%)",
    },


}));

export default function ConfirmActiveDialog(props: { setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((v: boolean) => void), handleSave: () => void, open: boolean, handleClose: () => void, handleNegativeAction: () => void }) {

    const classes = useStyles();
    return (
        <React.Fragment>
            <ActionDialog
                id="dialog-save"
                title='Confirm activation'
                icon={<div className={classes.warningAction}><WarningIcon /></div>}
                positiveText="Mark active"
                negativeText="Edit custom field"
                open={props.open}
                setOpen={props.setOpen}
                positiveAction={() => { props.handleSave() }}
                negativeAction={props.handleNegativeAction}
                dialogType="save"
            >
                <p className={classes.dialogContent}>
                    Once active, you cannot edit the field type, field name, or UDID #. Make sure to double check for any errors or typos.
                </p>
                <CloseDialogIcon onClick={() => props.handleClose()} />
            </ActionDialog>
        </React.Fragment>
    );
}
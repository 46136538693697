import React, { useState, useContext } from "react";
import { useOktaAuth } from '@okta/okta-react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ExpandMore, ExpandLess, Person } from "@material-ui/icons";
import { Button, Divider, Menu, MenuItem, TextField, Typography } from "@material-ui/core";

import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { decodeAccessToken } from '@cbtravel/common/lib/shared/auth/jwt-util';
import { iUserContext } from "@cbtravel/common/lib/shared/interfaces/iUserContext";
import { UserContext } from "../../../components/shared/UserContext";
import { ClientSettingsContext } from "../../../components/shared/ClientSettingsContext";
import { iClientSettingsContext } from "@cbtravel/common/lib/shared/interfaces/iClientSettingsContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        faceMenu: {
            marginTop: theme.spacing(1),
            marginRight: "-18px",
        },
        faceName: {
            paddingLeft: "6px",
            textTransform: "initial",
        },
    })
);

function FaceMenu() {
    const { authState, oktaAuth } = useOktaAuth();

    // Used to update first and last name after onSubmit() in Profile.tsx, so that faces menu will update from user context in the same login session
    const { userContext } = useContext<iUserContext>(UserContext);
    // Used to check whether to take the user to v3 Profiles or v2 Concur
    const { clientSettingsContext } = useContext<iClientSettingsContext>(ClientSettingsContext);

    const fullName = `${userContext.userState.firstName} ${userContext.userState.lastName}`;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const internalProfileGroup = 294;

    /**
     * Handler for when the FaceMenu should open.
     * 
     * @param event Mouse click event.
     */
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    /**
     * Handler for when the FaceMenu closes.
     */
    function handleClose() {
        setAnchorEl(null);
    };

    /**
     * Clear the user's auth state and logs them out.
     */
    async function logout() {
        // sign out of current okta session and redirect to login page
        await oktaAuth.closeSession();
        await oktaAuth.signOut({ revokeAccessToken: true });

        oktaAuth.tokenManager.clear();
    }

    let useInternalProfile = false;
    if (clientSettingsContext?.isProfilesEnabled) {
        useInternalProfile = clientSettingsContext?.defaultProfileUserGroupId === internalProfileGroup ? false : true;
    }

    return (
        <div className={classes.faceMenu}>
            <Button
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleClick}
                id='userMenu'
            >
                <Person />
                <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.faceName}
                >
                    {fullName}
                </Typography>
            </Button>

            <Menu
                id="user-settings"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <a href={Configuration.AirPortalUrl + "/user/settings"}>
                    <MenuItem id="userMenuItemMySettings" onClick={handleClose}>My settings</MenuItem>
                </a>
                <a
                    href={
                        Configuration.AirPortalUrl + "/client/agency/travelercontacts"
                    }
                >
                    <MenuItem id="userMenuItemTraveleAgencyContacts" onClick={handleClose}>Travel agency contacts</MenuItem>
                </a>
                {/* <a href="#">
                        <MenuItem onClick={handleClose}>Log consulting time</MenuItem>
                    </a> */}
                    {/** 
                    * There is more to the v3 profiles being enabled that was built into this logic... The state is not just dependent upon this flag. It is also dependent on a `defaultProfileUserGroup`.
                    * If the default profile user group is 300, they should be using an OBE external to airportal If the group is set to 294, it should be using internal 
                    **/}
                { clientSettingsContext?.isProfilesEnabled && useInternalProfile ?
                    <a
                        href={
                            "/user/profile"
                        }>
                        <MenuItem id="userMenuItemViewTravelProfile" onClick={handleClose}>View travel profile</MenuItem>
                    </a>
                    :
                    <a
                        href={
                            "/travelrequest/index/online"
                        }
                    >
                        <MenuItem id="userMenuItemViewTravelProfile" onClick={handleClose}>View travel profile</MenuItem>
                    </a>}
                <Divider />
                <MenuItem id="userMenuItemGiveFeedback" onClick={handleClose}>
                    <a href="#" id="giveFeedback">
                        Give feedback
                    </a>
                </MenuItem>
                <Divider />
                <MenuItem id="userMenuItemSignOut" onClick={logout}>Sign out</MenuItem>
            </Menu>
        </div>
    );
};

export default FaceMenu;

import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import FormatLineSpacingRoundedIcon from "@material-ui/icons/FormatLineSpacingRounded";
import TransferableDialog from "./TransferableDialog";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "absolute",
      bottom: "4%",
      right: "40%",
      color: "#ffffff",
      fontWeight: 600,
      textTransform: "initial",
    },
    fabIcon: {
      height: ".8em",
      marginRight: "4px",
    },
  })
);

interface FloatingButtonProps {
  /** The size of the button. */
  size?: number | string;
  /** React state setter for showing a spinner. */
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  /** Name of the action that this button should perform. */
  action: "transferable" | "non-transferable";
  /** The number of tickets selected for bulk action. */
  bulkActionCount: number;
  /** The function to execute when the user confirms in the dialog. */
  onConfirm: (isTransferable: boolean, comment: string) => void;
}

/**
 * Component used to create a floating action button for the UnusedTickets page.
 * 
 * @param props {@link FloatingButtonProps Properties} for the FloatingButton component.
 * @returns A JSX element used for the floating action button in the UnusedTickets page.
 */
export default function FloatingButton(props: FloatingButtonProps) {
  const classes = useStyles();
  const [openTransferableDialog, setOpenTransferableDialog] = useState(false);

  return (
    <Box>
      <Fab
        onClick={() => {
          setOpenTransferableDialog(true);
        }}
        variant="extended"
        color="secondary"
        aria-label="add"
        className={classes.fab}
      >
        <FormatLineSpacingRoundedIcon className={classes.fabIcon} />
        Bulk set {props.action === "transferable" ? "transferable" : "non-transferable"}
      </Fab>
      <TransferableDialog
        openActionDialog={openTransferableDialog}
        setOpenActionDialog={setOpenTransferableDialog}
        action={props.action}
        setShowSpinner={props.setShowSpinner}
        bulkActionCount={props.bulkActionCount}
        onConfirm={props.onConfirm}
      />
    </Box>
  );
}

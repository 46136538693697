import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import FormatLineSpacingRoundedIcon from "@material-ui/icons/FormatLineSpacingRounded";
import ApprovalDialog from "./ApprovalDialog";
import { Box } from "@material-ui/core";

// Floating Action Button = FAB. This might make more sense to move to the shared folder

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "absolute",
      bottom: "4%",
      right: "40%",
      color: "#ffffff",
      fontWeight: 600,
      textTransform: "initial",
    },
    fabIcon: {
      height: ".8em",
      marginRight: "4px",
    },
  })
);

interface FloatingButtonProps {
  size?: number | string;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  action: string;
  bulkApprovalCount: number;
  onConfirm: (wasApproved: boolean, comment: string) => void;
}

export default function FloatingButton(props: FloatingButtonProps) {
  const classes = useStyles();

  // approval dialog box (approve/deny)
  const [openActionDialog, setOpenActionDialog] = useState(false);

  return (
    <Box>
      <Fab
        onClick={() => {
          // setApprovalAction("Approve");
          setOpenActionDialog(true);
        }}
        variant="extended"
        color="secondary"
        aria-label="add"
        className={classes.fab}
      >
        <FormatLineSpacingRoundedIcon className={classes.fabIcon} />
        Bulk {props.action}
      </Fab>
      <ApprovalDialog
        openActionDialog={openActionDialog}
        setOpenActionDialog={setOpenActionDialog}
        approvalType={""}
        travelerName={""}
        destination={""}
        action={props.action}
        setShowSpinner={props.setShowSpinner}
        bulkApprovalCount={props.bulkApprovalCount}
        onConfirm={props.onConfirm}
      />
    </Box>
  );
}


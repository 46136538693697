import { useState } from "react";

/**
 * Properties for the Pencil component.
 */
interface SvgProps {
  /** Width of the icon. */
  width: string;
  /** Height of the icon. */
  height: string;
  /** Color of the icon. */
  color: string;
  /** Color of the icon when hovering over it. */
  hoverColor?: string;
}

/**
 * A component for displaying a pencil icon.
 * 
 * @param props {@link SvgProps Properties} for a `Pencil` component.
 * @returns A JSX element for displaying a pencil icon.
 */
export default function Pencil(props: SvgProps) {

  const [hover, setHover] = useState<boolean>(false);

  const hvrColor = props.hoverColor === undefined ? props.color : props.hoverColor;//if we have no hoverColor, default to color.
  return (
    <svg
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 22"
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
    >
      <path
        stroke={hover ? hvrColor : props.color}
        strokeWidth="1.5"
        d="M5.75 20.202v-3.609l7.298-7.298 3.657 3.657-7.294 7.294-3.661-.044z"
      ></path>
      <path
        fill={hover ? hvrColor : props.color}
        d="M15.892 5.39a1.334 1.334 0 011.887 0l2.83 2.831a1.334 1.334 0 010 1.887l-1.415 1.415-4.717-4.717 1.415-1.415z"
      ></path>
    </svg>
  );
}


import { PagedList } from '@cbtravel/common/lib/shared/common/paged-list';
import { CustomFieldRS } from '@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs';
import { ProfileIncompleteRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/profile-incomplete-rs";
import { ProfileCustomFieldRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/profile-custom-field-rs";
import { ProfileRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/profile-rs";
import { Box, TableCell, TableRow } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// Icon imports
import { CheckIcon, CloseIcon } from "../../../icons/Icons";
import Pencil from "../../../icons/Pencil";
import { hasUncaughtExceptionCaptureCallback } from 'process';

interface UserSearchDataRowProps {
    profileIncompleteRS: ProfileIncompleteRS,
    requiredUDIDList: PagedList<CustomFieldRS>, // required UUID should be in the exact order listed in the Headers.
}

export default function IncompleteProfileSearchDataRow(props: UserSearchDataRowProps) {

    const { profileIncompleteRS, requiredUDIDList } = props;
    const routerHistory = useHistory();

    function handleOnClick() {
        routerHistory.push("/user/profile?email=" + profileIncompleteRS.emailAddress);
    }

    let hasUDIDs: Array<Boolean> = [];

    requiredUDIDList.list.forEach(cf => {
        // requiredCustomFields are 'false' if they are complete and valid. They are true if they are incomplete.
        if (profileIncompleteRS.requiredCustomFields[cf.name] !== undefined && profileIncompleteRS.requiredCustomFields[cf.name] === "false") {
            hasUDIDs.push(true);
        }
        else
            hasUDIDs.push(false);
    });

    return (
        <>
            <TableRow>
                <TableCell onClick={handleOnClick}><Pencil width={"20"} height={"20"} color={"#585B64"} /> </TableCell>
                <TableCell component="th">{profileIncompleteRS.firstName} {profileIncompleteRS.lastName}</TableCell>
                <TableCell component="th">{profileIncompleteRS.emailAddress}</TableCell>
                <TableCell align="center">{profileIncompleteRS.hasMobilePhone ? <CheckIcon width={"16"} height={"16"} color={"#4A8B2C"} /> : <CloseIcon width={"14"} height={"14"} color={"#BC2C2F"} />} </TableCell>
                <TableCell align="center">{profileIncompleteRS.hasDateOfBirth ? <CheckIcon width={"16"} height={"16"} color={"#4A8B2C"} /> : <CloseIcon width={"14"} height={"14"} color={"#BC2C2F"} />} </TableCell>
                <TableCell align="center">{profileIncompleteRS.hasGender ? <CheckIcon width={"16"} height={"16"} color={"#4A8B2C"} /> : <CloseIcon width={"14"} height={"14"} color={"#BC2C2F"} />} </TableCell>
                {hasUDIDs.map((hasUUID) => {
                    if (hasUUID) {
                        return (<TableCell align="center"><CheckIcon width={"16"} height={"16"} color={"#4A8B2C"} /></TableCell>)
                    } else {
                        return (<TableCell align="center"><CloseIcon width={"14"} height={"14"} color={"#BC2C2F"} /></TableCell>)
                    }
                })}

            </TableRow >
        </>
    );
}


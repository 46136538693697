import React, { useEffect, useState, useContext } from "react";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Step, Stepper, StepLabel } from "@material-ui/core";

import { UserContext } from "../../../components/shared/UserContext";
import Spinner from "../../../components/shared/Spinner";

import { ApprovalRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-rs";
import { ApprovalGroupRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-group-rs";
import { ApprovalController } from "@cbtravel/common/lib/shared/api/approval/controllers/approval-controller";
import { ClientRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-rs";
import { EntityDepth } from "@cbtravel/common/lib/shared/common/enumerations/entity-depth";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { UserLT } from "@cbtravel/common/lib/shared/messages/general/responses/lite/user-lt";
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';

import {
  ApprovalTypeForm,
  LevelsForm,
  RecipientEmailsForm,
  ReviewForm,
} from "./ConfigureApprovalWizardSteps";
import { ApprovalRecipientRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-recipient-rs";
import { ApprovalRecipientType } from "@cbtravel/common/lib/shared/common/enumerations/approval-recipient-type";
import { CustomFieldRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs";
import { CustomFieldRQ } from "@cbtravel/common/lib/shared/messages/general/requests/custom-field-rq";
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";
import { CustomFieldController } from "@cbtravel/common/lib/shared/api/general/controllers/custom-field-controller";
import { ApprovalRuleSetRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-rule-set-rs";
import { ApprovalRuleRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-rule-rs";
import { AutoApprovalType } from "@cbtravel/common/lib/shared/common/enumerations/auto-approval-type";
import { ApprovalType } from "@cbtravel/common/lib/shared/common/enumerations/approval-type";
import { AutoApprovalComparisonOperator } from "@cbtravel/common/lib/shared/common/enumerations/auto-approval-comparison-operator";
import { AutoApprovalLogicalOperator } from "@cbtravel/common/lib/shared/common/enumerations/auto-approval-logical-operator";
import { ApprovalGroupApproverRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-group-approver-rs";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "0 !important",
      marginTop: "8px !important",
      "& .MuiFormControl-root": {
      },
      "& .MuiButton-outlinedSizeSmall": {
        padding: "4px 9px 6px",
      },
      "& .MuiTypography-body1": {
        fontSize: "13px",
      },
    },
    formControl: {
      "& .MuiFormGroup-root": {
        marginTop: "10px",
      },
      "& .MuiFormControlLabel-label": {
        fontSize: ".9rem",
        fontWeight: 600,
        marginLeft: "-4px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem",
      },
    },
    buttonRow: {
      "& > *": {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    btnAddAnother: {
      marginTop: "9px",
      padding: "5px 15px !important",
      background: "transparent !important",
    },
    stepper: {
      maxWidth: 750,
    },
    table: {
      minWidth: 600,
      maxWidth: 750,
    },
    dialog: {
      "& .MuiDialog-paper": {
        textAlign: "center",
      },
    },
    legend: {
      fontSize: 13,
      fontWeight: 600,
    },
    levelHeader: {
      fontSize: "1rem",
      display: "inline-block",
    },
    approverRemove: {
      marginLeft: theme.spacing(1),
      marginTop: "0.5rem",
    },
    users: {
      "& .MuiButton-root": {
        minWidth: "auto",
      },
    },
    confirmationUserRemove: {
      minWidth: "30px !important",
      padding: "7px 9px 8px",
      marginTop: 8,
      marginLeft: "12px",
      background: "rgba(201, 199, 199, .2)",
      "& .MuiSvgIcon-root": {
        color: "#808080",
      },
    },
    levelRemove: {
      marginLeft: theme.spacing(1),
      paddingTop: 5,
      color: '#00467E',
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    subheader: {
      fontSize: "20px",
      fontWeight: 600,
    },
    reviewActions: {
      marginLeft: theme.spacing(1),
      fontWeight: 600,
      "& .MuiLink-underlineHover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    textfield: {
      minWidth: "300px",
    },
    existingConfig: {
      marginLeft: "23px",
      marginBottom: "10px",
      maginTop: "4px",
    },

    switchSelections: {
      "& .MuiSwitch-sizeSmall": {
        marginTop: "2px",
        marginLeft: "15px",
      },
      "& .MuiFormControlLabel-root": {
        maxWidth: "350px",
      },
    },
    switchHeader: {
      marginLeft: theme.spacing(1),
      display: "block",
      fontWeight: 600,
      fontSize: "14px",
    },
    switchDescription: {
      marginLeft: "52px",
      marginBottom: theme.spacing(2),
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: 1.6,
    },
    startEmailReminder: {
      width: "50px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 0,
      },
    },
    countStepper: {
      paddingLeft: "5px",
      "& .MuiButtonGroup-root": {
        marginRight: "12px",
      },
      "& .MuiButton-outlined": {
        padding: "6px 8px 6px",
      },
    },
    duration: {
      fontSize: "14px",
    },
    reminderSelections: {
      marginLeft: "52px",
      marginBottom: theme.spacing(2),
      "& .MuiTypography-body1": {
        fontSize: "13px",
      },
      "& .MuiButton-outlined": {
        padding: "5px",
      },
      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
        cursor: "pointer",
      },
      "& .MuiButton-root": {
        background: "#F9F9F9",
      },
    },
    reminderSummary: {
      "& .MuiTypography-body1": {
        fontSize: "12px",
        color: "#808080",
      },
      "& strong": {
        color: "#4d4d4d",
      },
    },
    reviewReminderNotifications: {
      "& ul": {
        marginTop: theme.spacing(1),
      },
      "& ul li": {
        padding: "0 0 4px 0",
        lineHeight: 1.5,
      },
    },
    stickyNav: {
      bottom: 0,
      right: 0,
      background: '#fafafa',
      boxShadow: '0px -2px rgba(0, 0, 0, 0.1)',
      padding: "20px 20px 25px 0px",
      justifyContent: 'flex-end',
      width: '100%',
      zIndex: 2,
    },
    mediumGray: {
      color: "#4d4d4d",
    },
    activeButton: {
      borderColor: "#00467F",
      color: "#00467F",
    },
    nextBtn: {
      padding: "8px 40px",
      lineHeight: 1,
    },
    addApproval: {
      color: '#00467E',
      fontSize: 14,
      cursor: 'pointer',
      paddingTop: 8,
      "& .MuiSvgIcon-root": {
        marginBottom: -6,
      },
    },
    addIcon: {
      color: '#00467E',
      fontSize: '20px',
      marginLeft: '6px',
      marginBottom: '-2px',
      cursor: 'pointer',
    },
  })
);

enum ConfigurationWizardSteps {
  SelectApprovalType = 0,
  GroupsAndLevels = 1,
  ConfirmationUsers = 2,
  Review = 3,
}

export interface ireminderState {
  /** Whether or not reminders are enabled. */
  enabled: boolean,
  /** Number of minutes to delay the start of reminder emails. */
  startMin: number,
  /** The number of minutes between reminder emails sending. */
  frequencyMin: number
}

export interface ireminderCountDuration {
  /** The number of `duration`. */
  count: number,
  /** The unit of time. */
  duration: string
}

export interface irecipientUsers {
  /** Array of users to receive confirmation emails. */
  confirmationUsers: Array<UserLT>,
  /** Array of users to receive automatic reminder emails. */
  reminderUsers: Array<UserLT>,
}

interface ApprovalTypeConfigurationProps {
  /** Object representing the currently active client. */
  activeClient?: ClientRS
}

/**
 * A multi-step form wizard for configuring approvals
 * 
 * @param props {@link ApprovalTypeConfigurationProps Properties} for ApprovalTypeConfigWizard.
 * @returns A JSX element used for manipulating approval configurations.
 */
export default function ApprovalTypeConfigWizard(props: ApprovalTypeConfigurationProps) {
  const { userContext } = useContext(UserContext);
  const snackbar = useCustomSnackbar();
  const classes = useStyles();

  const [isPageBusy, setIsPageBusy] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<ConfigurationWizardSteps>(ConfigurationWizardSteps.SelectApprovalType);

  // this variable is used to track an existing approval configuration and some information across the form steps
  const [currentApproval, setCurrentApproval] = useState<ApprovalRS>();

  // these variables track the values in the form and are used in the final submission
  const [approvalGroups, setApprovalGroups] = useState<ApprovalGroupRS[]>([new ApprovalGroupRS()]);
  const [approvalRecipients, setApprovalRecipients] = useState<irecipientUsers>({
    confirmationUsers: new Array<UserLT>(),
    reminderUsers: new Array<UserLT>(),
  });

  // Variables for approval rules will get passed into LevelsForm as props and enable us to set new approval rules.
  const [approvalRules, setApprovalRules] = useState<ApprovalRuleRS[]>();
  // Stateful variables for approval rule sets so that we can initialize a rule set if the list is empty. This allows us to add rules to the approval object when we submit the configuration.
  const [approvalRuleSets, setApprovalRuleSets] = useState<ApprovalRuleSetRS[]>();
  const [reminderState, setReminderState] = useState<ireminderState>({ enabled: false, startMin: 0, frequencyMin: 0 });

  // these are the "untranslated" states of the reminder notification variables. they're set in the emailConfirmationForm, but we need to pass them to the review form for display
  const [startReminderState, setStartReminderState] = useState<ireminderCountDuration>({ count: 4, duration: "hour(s)" })
  const [frequencyReminderState, setFrequencyReminderState] = useState<ireminderCountDuration>({ count: 4, duration: "hour(s)" })

  // A boolean state that tracks whether the manager custom is activated or not. Used for manager dialogs and buttons
  const [isManagerCustomFieldActive, setIsManagerCustomFieldActive] = useState<boolean>();
  // Boolean states to track the manager email recipient state.
  const [isManagerReminder, setIsManagerReminder] = useState<boolean>(false);
  const [isManagerConfirmation, setIsManagerConfirmation] = useState<boolean>(false);

  /**
   * If the user changes the client in the client selector, put them back on step 1 and reset.
   */
  useEffect(() => {
    resetWizard();
    // additionally, get traveler's manager CustomField status
    if (props.activeClient)
      getManagerCustomFieldStatus();
  }, [props.activeClient]);

  useEffect(() => {
    if (currentApproval) {
      
      // if the approval has approval groups, pull them into the form state. otherwise initialize an empty group.
      if (currentApproval.approvalGroupList.length > 0) {
        setApprovalGroups(currentApproval.approvalGroupList);
      } else {
        let newGroup = new ApprovalGroupRS();
        newGroup.level = 1;
        newGroup.dateCreated = new Date();
        newGroup.isEnabled = true;
        newGroup.name = `${currentApproval.client.name} (Group ${newGroup.level})`;
        newGroup.approvalGroupApproverList.push(new ApprovalGroupApproverRS());
        setApprovalGroups([newGroup]);
      }

      // if the approval has confirmation recipients, pull them into the form state. otherwise initialize a new user.
      if (currentApproval.approvalRecipientList.length > 0) {
        setApprovalRecipients({
          confirmationUsers: currentApproval.approvalRecipientList.reduce((accumulator: UserLT[], currentUser: ApprovalRecipientRS) => {
            if (currentUser.approvalRecipientType === ApprovalRecipientType.Confirmation) {
              accumulator.push(currentUser.user);
            }
            return accumulator;
          }, []),
          reminderUsers: currentApproval.approvalRecipientList.reduce((accumulator: UserLT[], currentUser: ApprovalRecipientRS) => {
            if (currentUser.approvalRecipientType === ApprovalRecipientType.Reminder) {
              accumulator.push(currentUser.user);
            }
            return accumulator;
          }, []),
        })
      } else {
        setApprovalRecipients({
          confirmationUsers: [new UserLT()],
          reminderUsers: [new UserLT()],
        })
      }

      if (currentApproval.approvalRuleSetList.length > 0) {
        setApprovalRuleSets(currentApproval.approvalRuleSetList);
        setApprovalRules(currentApproval.approvalRuleSetList[0].approvalRuleList);
      }
      else {
        // If the new fetched approval config doesn't have any rules/rulesets
        // Reset state to default = undefined
        setApprovalRuleSets(undefined);
        setApprovalRules(undefined);
      }

      // if the reminders are configured, pull start and frequency information into form state, otherwise set reminderEnabled: false
      if (currentApproval.reminderEnabled) {
        setReminderState({
          enabled: true,
          startMin: currentApproval.reminderStartMin,
          frequencyMin: currentApproval.reminderFrequencyMin
        });
        setStartReminderState(translateMinutesToCountDuration(currentApproval.reminderStartMin))
        setFrequencyReminderState(translateMinutesToCountDuration(currentApproval.reminderFrequencyMin))
      } else {
        setReminderState({ enabled: false, startMin: 0, frequencyMin: 0 });
      }
      // Set manager email recipient states 
      setIsManagerConfirmation(currentApproval.isManagerConfirmation);
      setIsManagerReminder(currentApproval.isManagerReminder);

      // if creating a new approval, move to the next step. if editing existing, move to review.
      if (currentApproval.approvalId === -1) {
        setActiveStep(ConfigurationWizardSteps.GroupsAndLevels);
      } else {
        setActiveStep(ConfigurationWizardSteps.Review);
      }
    }
  }, [currentApproval]);

  /**
   * Cleans the form data and assembles an ApprovalRS to submit to the API.
   */
  async function submitConfiguration() {
    try {
      setIsPageBusy(true);

      let approval: ApprovalRS = currentApproval!;

      // filter out any approval groups that are empty or only contain uninitialized users.
      approval.approvalGroupList = approvalGroups.reduce<ApprovalGroupRS[]>((total, group) => {
        if (group.approvalGroupApproverList.filter((a) => a.user.userId !== -1).length > 0 || group.isManagerApprover) {
          return [...total, group];
        }
        return total;
      }, []);

      // filter out any uninitialized users from the remaining approvalGroupList
      approval.approvalGroupList = currentApproval!.approvalGroupList.map((group) => {
        let newGroup = group;
        newGroup.approvalGroupApproverList = newGroup.approvalGroupApproverList.filter((approver) => approver.user.userId !== -1);
        return newGroup;
      });

      // filter out any recipients with an id of -1, format into ApprovalRecipientRS[], and add approvalRecipientType attribute
      let confirmationRecipients = approvalRecipients.confirmationUsers.reduce<ApprovalRecipientRS[]>((total, user) => {
        if (user.userId !== -1) {
          let recipient = new ApprovalRecipientRS();
          recipient.user = user;
          recipient.approvalId = approval.approvalId;
          recipient.approvalRecipientType = ApprovalRecipientType.Confirmation;
          return [...total, recipient];
        }
        return total;
      }, []);

      let reminderRecipients = approvalRecipients.reminderUsers.reduce<ApprovalRecipientRS[]>((total, user) => {
        if (user.userId !== -1) {
          let recipient = new ApprovalRecipientRS();
          recipient.user = user;
          recipient.approvalId = approval.approvalId;
          recipient.approvalRecipientType = ApprovalRecipientType.Reminder;
          return [...total, recipient];
        }
        return total;
      }, []);

      // combine formatted arrays into recipientList
      approval.approvalRecipientList = [...confirmationRecipients, ...reminderRecipients]

      // populate reminder notification settings
      approval.reminderEnabled = reminderState.enabled;
      approval.reminderStartMin = reminderState.startMin;
      approval.reminderFrequencyMin = reminderState.frequencyMin;

      // Populate auto approval rules
      if (approvalRules && approval.approvalRuleSetList.length === 0) {
        if (approvalRules.length > 0) {
          let approvalRuleSet = new ApprovalRuleSetRS();
          approvalRuleSet.approvalId = approval.approvalId;
          approvalRuleSet.autoApprovalType = AutoApprovalType.Approve;
          approvalRuleSet.name = "AutoUserApproval";
          approval.approvalRuleSetList.push(approvalRuleSet);
          setApprovalRuleSets(approval.approvalRuleSetList);
        }
        
      }

      if (approvalRules && approval.approvalRuleSetList.length > 0) {
        // If the approval rules array is empty then we want to set the rule set list to empty.
        // This is so that the service layer doesn't attempt to auto approve the request with no rules.
        if (approvalRules.length === 0) {
          approval.approvalRuleSetList = [];
        }
        else {
          // Otherwise we have approval rules that we want to send to the backend.
          approval.approvalRuleSetList[0].approvalRuleList = approvalRules;
        }
       
      }




      // Save manager email recipients boolean states
      approval.isManagerConfirmation = isManagerConfirmation;
      approval.isManagerReminder = isManagerReminder;
      // if approvalId != -1 then this an edit to an existing approval config, otherwise we are adding a new one
      if (approval.approvalId !== -1) {
        approval.dateModified = new Date();
        await ApprovalController.Update(userContext.accessToken, approval, EntityDepth.Infinite, true);
      } else {

        // populate new config details with data relevant to the active client
        approval.name = `${props.activeClient!.name} (${approval.approvalType})`;
        approval.dateCreated = new Date();
        approval.dateModified = new Date();
        approval.client = {
          accountNumber: props.activeClient!.accountNumber,
          clientId: props.activeClient!.clientId,
          name: props.activeClient!.name,
        };

        await ApprovalController.Add(userContext.accessToken, approval, EntityDepth.Infinite);
      }

      snackbar.info("Your Approval Configuration has been submitted successfully!");
      setActiveStep(ConfigurationWizardSteps.SelectApprovalType);
      resetWizard();
    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setIsPageBusy(false);
    }
  }

  /**
   * Function that fetches managercustomfield (udid38) into a react state
   */
  async function getManagerCustomFieldStatus() {
    try {
      setIsPageBusy(true);

      //Set up find request 
      let customFieldRQ = new CustomFieldRQ();
      customFieldRQ.activeSearchType = ActiveSearchType.Both;
      customFieldRQ.clientId = -1;
      if (props.activeClient)
        customFieldRQ.clientId = props.activeClient?.clientId;

      customFieldRQ.numberList = [38]; //UDID 38 is the traveler's manager custom field 

      let response = await CustomFieldController.Find(userContext.accessToken, customFieldRQ, EntityDepth.Shallow);
      // In case the managerCustomField is not retrievable
      // set the state to undefined (which is also default). 
      // This indicates that the managerCustomField(udid 38) needs to be configured for the client
      if (response.list.length == 0)
        setIsManagerCustomFieldActive(undefined);
      else
        setIsManagerCustomFieldActive(response.list[0].activeStatus);
    }
    catch (e) {
      setIsManagerCustomFieldActive(undefined);
      snackbar.error(e as JsonException);
    }
    finally {
      setIsPageBusy(false);
    }
  }


  /**
   * Translates a given number of minutes into the most convenient count/duration pair e.g. 60 = 1 hour.
   * @param minutes The number of minutes to convert to a conversational count/duration pair format.
   * @returns An object representing a count/duration pair e.g. { count: 1, duration: "hour(s)" }.
   */
  function translateMinutesToCountDuration(minutes: number) {
    let countDuration: ireminderCountDuration = { count: 4, duration: "hour(s)" };

    if (minutes % 1440 === 0) {
      countDuration.duration = "day(s)";
      countDuration.count = minutes / 1440;

    } else if (minutes % 60 === 0) {
      countDuration.duration = "hour(s)";
      countDuration.count = minutes / 60;

    }

    return countDuration;
  }

  /**
   * Clears backing values and returns user to the first step.
   */
  function resetWizard() {
    setActiveStep(ConfigurationWizardSteps.SelectApprovalType);
    setApprovalGroups([new ApprovalGroupRS()]);
    setApprovalRuleSets(undefined);
    setApprovalRules(undefined);
    setApprovalRecipients({ confirmationUsers: [new UserLT()], reminderUsers: [new UserLT()] });
    setCurrentApproval(undefined);
    setReminderState({ enabled: false, startMin: 0, frequencyMin: 0 });
    setStartReminderState({ count: 4, duration: "hour(s)" });
    setFrequencyReminderState({ count: 4, duration: "hour(s)" });
    setIsManagerConfirmation(false);
    setIsManagerReminder(false);
  }

  /**
   * Determines which step of the wizard to render.
   * 
   * @returns A JSX element to be used for the current wizard step.
   */
  function renderFormStep(): JSX.Element {
    switch (activeStep) {
      case ConfigurationWizardSteps.SelectApprovalType:
        return (
          <ApprovalTypeForm
            activeClient={props.activeClient}
            setCurrentApproval={setCurrentApproval}
            setActiveStep={setActiveStep}
          />
        );
      case ConfigurationWizardSteps.GroupsAndLevels:
        return (
          <LevelsForm
            activeClient={props.activeClient}
            currentApproval={currentApproval}
            approvalGroups={approvalGroups}
            setApprovalGroups={setApprovalGroups}
            setActiveStep={setActiveStep}
            isManagerCustomFieldActive={isManagerCustomFieldActive}
            isManagerFieldApplied={isManagerReminder || isManagerConfirmation || approvalGroups.some(g => g.isManagerApprover)}
            approvalRules={approvalRules}
            setApprovalRules={setApprovalRules}
          />
        );
      case ConfigurationWizardSteps.ConfirmationUsers:
        return (
          <RecipientEmailsForm
            currentApproval={currentApproval}
            approvalRecipients={approvalRecipients}
            setApprovalRecipients={setApprovalRecipients}
            setActiveStep={setActiveStep}
            reminderState={reminderState}
            setReminderState={setReminderState}
            startReminderState={startReminderState}
            setStartReminderState={setStartReminderState}
            frequencyReminderState={frequencyReminderState}
            setFrequencyReminderState={setFrequencyReminderState}
            isManagerCustomFieldActive={isManagerCustomFieldActive}
            isManagerReminder={isManagerReminder}
            isManagerConfirmation={isManagerConfirmation}
            setIsManagerReminder={setIsManagerReminder}
            setIsManagerConfirmation={setIsManagerConfirmation}
            isManagerFieldApplied={approvalGroups.some(g => g.isManagerApprover) || isManagerConfirmation || isManagerReminder}
          />
        );
      case ConfigurationWizardSteps.Review:
        return (
          <ReviewForm
            currentApproval={currentApproval}
            approvalGroups={approvalGroups}
            setApprovalGroups={setApprovalGroups}
            approvalRecipients={approvalRecipients}
            setActiveStep={setActiveStep}
            submit={submitConfiguration}
            startReminderState={startReminderState}
            frequencyReminderState={frequencyReminderState}
            reminderState={reminderState}
            isManagerReminder={isManagerReminder}
            isManagerConfirmation={isManagerConfirmation}
            approvalRules={approvalRules}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <React.Fragment>
      {isPageBusy && <Spinner />}
      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        alternativeLabel={true}
      >
        <Step key={ConfigurationWizardSteps.SelectApprovalType}>
          <StepLabel>Approval Type</StepLabel>
        </Step>

        <Step key={ConfigurationWizardSteps.GroupsAndLevels}>
          <StepLabel>Levels and Groups</StepLabel>
        </Step>

        <Step key={ConfigurationWizardSteps.ConfirmationUsers}>
          <StepLabel>Notifications</StepLabel>
        </Step>

        <Step key={ConfigurationWizardSteps.Review}>
          <StepLabel>Review</StepLabel>
        </Step>
      </Stepper>
      {renderFormStep()}
    </React.Fragment>
  );
}

export { ConfigurationWizardSteps, useStyles };

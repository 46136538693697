import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
} from "@material-ui/core";
import {
  ArrowDownward as DescendingIcon,
  ArrowUpward as AscendingIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  sortIcon: {
    color: "#C9C7C7",
    "& .MuiSvgIcon-root": {
      fontSize: 16,
      marginTop: -3,
      marginLeft: -2,
    },
    "& .MuiIconButton-sizeSmall": {
      padding: '0 0 0 1px',
    },
  },
}));

interface SortIconProps {
  /** Whether the icon should represent an ascending or descending direction. */
  sortAscending?: boolean
}

/**
 * Icon button for displaying sort direction on certain columns on the UnusedTickets page.
 * 
 * @param props {@link SortIconProps Properties} for the `SortIcon` component.
 * @returns A JSX element for displaying a sort direction arrow icon.
 */
export default function SortIcon(props: SortIconProps) {
  const classes = useStyles();
  return (

    <IconButton
      aria-label="sort"
      size="small"
      className={classes.sortIcon}
      disableRipple
      disableFocusRipple
    >
      {props.sortAscending ? <DescendingIcon /> : <AscendingIcon />}
    </IconButton>
  );
}
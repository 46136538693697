import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import originalMoment from "moment";
import { DateRange, extendMoment } from "moment-range";
import "./DateRangePicker.css";
import "react-datepicker/dist/react-datepicker.css";

import { Box, Popover, TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  calendarRangeOptions: {
    background: 'none',
    border: 'none',
    padding: '6px 8px',
    textAlign: 'left',
    width: '100px',
    outline: 'none',
    cursor: 'pointer',
    color: 'white',
    fontWeight: 'normal',
    fontFamily: 'Open Sans',
    '&:hover': {
      border: 'none',
      outline: 'none',
      fontWeight: 'bold',
    },
    '&:focus': {
      border: 'none',
      outline: 'none',
      fontWeight: 'bold',
    }
  },
  displayFlex: {
    display: "flex",
    margin: 0,
  }
}));

const extendedMoment = extendMoment(originalMoment as any);

/**
 * Properties for the `DatePickerRange` component.
 */
interface DateRangeProps {
  /* id of the component */
  title: string,
  /** The number of months to show at a time. */
  numberOfCalendars: number,
  /** Text to display as a label for the component. */
  textFieldLabel: string,
  /** Handler for when the value held by the component changes. */
  onChange?: (value: DateRange | null) => void,
  /** Whether or not the component should display controls for future dates. */
  displayFutureFilters: boolean,
  /** Whether or not the component should display controls for past dates. */
  displayPreviousFilters: boolean,
  /** The value held by the component. */
  value?: DateRange | null,
  /** Whether the component is open or collapsed. */
  open?: boolean
  /** For showing errors when the date is required */
  error?: boolean
  /** Optional argument for setting a minimum date that can be picked */
  minDate?: Date | null
  /** For RNF if we want to use onBlur */
  onBlur?: () => void
}

/**
 * A component used for picking a date range.
 * 
 * @param props {@link DateRangeProps Properties} for the DatePickerRange component.
 * @returns A JSX element used for picking a date range.
 */
export default function DatePickerRange(props: DateRangeProps) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState<boolean>(props.open ? props.open : false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (props.value === undefined) {
      setStartDate(null);
      setEndDate(null);
    }
    return;
  }, [props.value]);

  const onRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      let dateRange = new DateRange(start, end);
      props.onChange && props.onChange(dateRange);
      setIsOpen(false);
    }
  };

  /**
   * Handles the first click when the component is selected.
   * 
   * @param event Mouse click
   */
  function handleClick(event: React.MouseEvent) {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  }

  /**
   * Closes the component.
   */
  function handleClose() {
    setIsOpen(false);
    setAnchorEl(null);
  }

  /**
   * Filters for future
   * 
   * @param number The number of `duration` to add.
   * @param duration The unit of time to add.
   */
  function onNextRange(number: originalMoment.DurationInputArg1, duration: originalMoment.unitOfTime.DurationConstructor) {
    let today = extendedMoment();
    props.onChange && props.onChange(extendedMoment.range(today.clone().startOf("day"), today.clone().add(number, duration).endOf("day")));
    setIsOpen(false);
  }

  /**
   *  Filters for previous 
   * 
   * @param number The number of `duration` to subtract.
   * @param duration The unit of time to subtract.
   */
  function onPreviousRange(number: originalMoment.DurationInputArg1, duration: originalMoment.unitOfTime.DurationConstructor) {
    let today = extendedMoment();
    props.onChange && props.onChange(extendedMoment.range(today.clone().subtract(number, duration).startOf("day"), today.clone().endOf("day")));
    setIsOpen(false);
  }

  /**
   * Returns date range in TextField 
   * 
   * @returns the new date range
   */
  function getSelectionValue() {
    if (!props.value || !props.value.start || !props.value.end) {
      return " ";
    }
    return (
      props.value.start.format("MM/DD/YYYY") +
      " 🠦 " +
      props.value.end.format("MM/DD/YYYY")
    );
  }

  /**
   * Clears the underlying DateRange value by setting it to null.
   */
  function clearDateSelection() {
    props.onChange && props.onChange(null);
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <TextField
        id={`ff-selector-${props.title}`}
        label={props.textFieldLabel}
        variant="outlined"
        margin="dense"
        value={getSelectionValue()}
        onClick={handleClick}
        fullWidth
        error={props.error ? props.error : false}
      />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={classes.displayFlex} my={2}>
          {/* Boolean if we should display the future filters */}

          
          {props.displayFutureFilters && (
            <div
            style={{
              float: "left",
              padding: "12px 10px",
              backgroundColor: '#00467E',
              color: 'white',
            }}
          >
              <div>
                <input
                  type="button"
                  id={`btn-next-30-${props.title}`}
                  className={classes.calendarRangeOptions}
                  value="Next 30 days"
                  onClick={() => {
                    onNextRange(30, "day");
                  }}
                />
                <br />
                <input
                  type="button"
                  id={`btn-next-60-${props.title}`}
                  value="Next 60 days"
                  className={classes.calendarRangeOptions}
                  onClick={() => {
                    onNextRange(60, "day");
                  }}
                />
                <br />
                <input
                  type="button"
                  id={`btn-next-year-${props.title}`}
                  value="Next 1 year"
                  className={classes.calendarRangeOptions}
                  onClick={() => {
                    onNextRange(1, "year");
                  }}
                />
                <br />
                <input
                  type="button"
                  id={`btn-next-2-years-${props.title}`}
                  value="Next 2 years"
                  className={classes.calendarRangeOptions}
                  onClick={() => {
                    onNextRange(2, "year");
                  }}
                />
                <br />
                <input
                  type="button"
                  id={`btn-clear-${props.title}`}
                  value="Clear"
                  className={classes.calendarRangeOptions}
                  onClick={clearDateSelection}
                />
              </div>
            </div>
          )}
          

          {/* Boolean if we should display the previous filters */}
          {props.displayPreviousFilters && (
            <div
            style={{
              float: "left",
              width: "150px",
              padding: "12px 10px",
              backgroundColor: '#00467E',
              color: 'white',
            }}
          >
              <input
                type="button"
                id={`btn-previous-30-${props.title}`}
                className={classes.calendarRangeOptions}
                style={{padding:'6px 0px'}}
                value="Previous 30 days"                
                onClick={() => {
                  onPreviousRange(30, "day");
                }}
              />
              <br />
              <input
                type="button"
                id={`btn-previous-60-${props.title}`}
                value="Previous 60 days"
                className={classes.calendarRangeOptions}
                style={{padding:'6px 0px'}}
                onClick={() => {
                  onPreviousRange(60, "day");
                }}
              />
              <br />
              <input
                type="button"
                id={`btn-previous-year-${props.title}`}
                value="Previous year"
                className={classes.calendarRangeOptions}
                style={{padding:'6px 0px'}}
                onClick={() => {
                  onPreviousRange(1, "year");
                }}
              />
              <br />
              <input
                type="button"
                id={`btn-previous-2-years-${props.title}`}
                value="Previous 2 years"
                className={classes.calendarRangeOptions}
                style={{padding:'6px 0px'}}
                onClick={() => {
                  onPreviousRange(2, "year");
                }}
              />
              <br />
              <input
                type="button"
                id={`btn-previous-clear-${props.title}`}
                value="Clear"
                className={classes.calendarRangeOptions}
                style={{padding:'6px 0px'}}
                onClick={clearDateSelection}
              />
            </div>
          )}
          <DatePicker
            id={`dp-${props.title}`}
            fixedHeight={true}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onRangeChange}
            monthsShown={props.numberOfCalendars}
            minDate={props.minDate}
            selectsRange
            inline
            peekNextMonth={false}
          />
        </Box>
      </Popover>
    </React.Fragment>
  );
}

import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tab, Tabs, MenuItem, TextField, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import clsx from "clsx";
import { SwitchCameraSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      "& .MuiTypography-body2": {
        lineHeight: 1.2,
        marginTop: 10,
      },
      "& .MuiTab-root": {
        textTransform: 'none',
        lineHeight: '1.2',
        padding: 40,
        border: '1px solid #c9c9c9',
        borderRadius: 4,
        margin: 10,
        backgroundColor: '#f9f9f9',
      },
    },
    stepLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
    },
    formControl: {
      "& .MuiFormLabel-root": {
        fontSize: ".9rem",
        fontWeight: 600,
        color: "#4d4d4d"
      },
      "& .MuiFormControlLabel-label": {
        fontSize: ".9rem",
        color: "#4d4d4d"
      },
    },
    loginTypeBox: {
      paddingTop: 30,
      paddingBottom: 30,
      borderRadius: 4,
      border: "1px solid #C9C7C7",
      backgroundColor: "#f9f9f9",
      cursor: 'pointer',
    },
    loginTypeBoxTitle: {
      fontSize: ".9rem",
      fontWeight: 600,
      color: "#4d4d4d",
      textAlign: "center",
    },
    center: {
      textAlign: 'center',
    },
    png: {
      width: 40,
      height: 40,
    },
    //table styles
    tableHead: {
      backgroundColor: "#F2F2F2",
    },
    tableStyles: {
      "& .MuiTableCell-root": {
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    rowFocus: {
      fontWeight: 'bold',
    },
    lightGreyBg: {
      backgroundColor: "#f2f2f2",
    },
    medGreyBg: {
      backgroundColor: "#8F9299",
      color: "#FFF"
    },
    darkGreyBg: {
      backgroundColor: "#4D4D4D",
      color: "#FFF"
    },
    justifyContent: {
      justifyContent: 'space-between',
    },
    tbsm: {
      width: '1%',
    }
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


export default function OBELogin() {
  const classes = useStyles();
  //Set show wrapper selection based on user selection yes/no
  const [yesNoConcurUser, setShowConcurUser] = React.useState('YesConcurUser');
  const handleYesNoConcurUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowConcurUser((event.target as HTMLInputElement).value);
  };

  //Map to imported column dropdown
  const [importedColumnNewEmail, setImportedColumnNewEmail] = React.useState('');
  const handleColumnName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImportedColumnNewEmail(event.target.value);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <div className={classes.root}>
        <Box pt={4}>
          <Typography className={classes.stepLabel}>Step 3</Typography>
          <Typography>OBE Login</Typography>
        </Box>
        <Box mt={3}>
          <FormControl className={classes.formControl} component="fieldset">
            <FormLabel component="legend">Does the client have an existing instance with Concur?</FormLabel>
            <RadioGroup defaultValue="YesConcurUser" aria-label="PGP" name="customized-radios" onChange={handleYesNoConcurUser}>
              <Box display="flex" flexDirection="row">
                <FormControlLabel value="YesConcurUser" control={<Radio />} label="Yes" />
                <FormControlLabel value="NoConcurUser" control={<Radio />} label="No " />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
        {yesNoConcurUser === "YesConcurUser" &&
          <>
            <Box my={4}>
              <Typography>Create unique login</Typography>
              <Typography variant="body2">Concur requires a unique login. If an email has already been used as a login previously, <strong>it cannot be reused for another instance.</strong> To create a unique login,
                select an option below.</Typography>
            </Box>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="icon label tabs example"
            >
              <Tab icon={<img alt="email plus" className={classes.png}
                src={`${process.env.PUBLIC_URL}/img/emailPlus.png`} />} label="Generate new email" />
              <Tab icon={<img alt="email swap" className={classes.png}
                src={`${process.env.PUBLIC_URL}/img/emailSwap.png`} />} label="Swap to secondary email" />
              <Tab icon={<img alt="email plus" className={classes.png}
                src={`${process.env.PUBLIC_URL}/img/emailDomain.png`} />} label="Swap email domain" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container direction="row" alignItems="flex-start" className={classes.justifyContent} spacing={3}>
                <Grid item xs={6}>
                  <Typography>Generate new email</Typography>
                  <Typography variant="body2">The column headers from your upload are imported below. Select the column
                    you want the unique login to be and enter an email domain.</Typography>
                  <Box mt={4}>
                    <TextField
                      id="column-name"
                      fullWidth
                      select
                      label="Map to imported column"
                      onChange={handleColumnName}
                      value={importedColumnNewEmail}
                      variant="outlined"
                    >
                      {importedMappedItems.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography variant='body2'>Check the preview to ensure this is the correct column</Typography>
                  </Box>
                  <Box mt={4}>
                   <TextField
                      id="column-name"
                      fullWidth
                      size='small'
                      label="New email domain"
                      variant="outlined"
                    />
                    <Typography variant='body2'>Check the preview to ensure this is the correct email domain</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Preview</Typography>
                  <Box mt={2}>
                    <Table size="small" className={classes.tableStyles}>
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)} >1</TableCell>
                          <TableCell className={classes.medGreyBg}>Imported column</TableCell>
                          <TableCell className={classes.darkGreyBg}>New email domain</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>2</TableCell>
                          <TableCell>ID123</TableCell>
                          <TableCell rowSpan={3}>@company.com</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>3</TableCell>
                          <TableCell>ID456</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>4</TableCell>
                          <TableCell>ID789</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <Typography variant="body2">Note: Preview only displays the first couple rows</Typography>

                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container direction="row" alignItems="flex-start" className={classes.justifyContent} spacing={3}>
                <Grid item xs={6}>
                  <Typography>Swap to a secondary email</Typography>
                  <Typography variant="body2">The column headers from your upload are imported below. Select the column
                    you want the unique login to be and enter an email domain.</Typography>
                  <Box mt={4}>
                    <TextField
                      id="column-name"
                      fullWidth
                      select
                      label="Map to imported column"
                      onChange={handleColumnName}
                      value={importedColumnNewEmail}
                      variant="outlined"
                    >
                      {importedMappedItems.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography variant='body2'>Check the preview to ensure this is the correct column</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Preview</Typography>
                  <Box mt={2}>
                    <Table size="small" className={classes.tableStyles}>
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)} >1</TableCell>
                          <TableCell className={classes.medGreyBg}>Secondary email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>2</TableCell>
                          <TableCell>user1@company.com</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>3</TableCell>
                          <TableCell>user2@company.com</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>4</TableCell>
                          <TableCell>user3@company.com</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <Typography variant="body2">Note: Preview only displays the first couple rows</Typography>

                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container direction="row" alignItems="flex-start" className={classes.justifyContent} spacing={3}>
                <Grid item xs={6}>
                  <Typography>Swap email domain</Typography>
                  <Typography variant="body2">The column headers from your upload are imported below. Select the column
                    that contains emails and enter a new email domain to swap with the existing
                    domain.</Typography>
                  <Box mt={4}>
                    <TextField
                      id="column-name"
                      fullWidth
                      select
                      label="Select imported email column"
                      onChange={handleColumnName}
                      variant="outlined"
                      value={importedColumnNewEmail}
                    >
                      {importedMappedItems.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography variant='body2'>Check the preview to ensure this is the correct column</Typography>
                  </Box>
                  <Box mt={4}>
                    <TextField
                      id="column-name"
                      fullWidth
                      size='small'
                      label="New email domain"
                      variant="outlined"
                    />
                    <Typography variant='body2'>Check the preview to ensure this is the correct email domain</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Preview</Typography>
                  <Box mt={2}>
                    <Table size="small" className={classes.tableStyles}>
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)} >1</TableCell>
                          <TableCell className={classes.medGreyBg}>Email</TableCell>
                          <TableCell className={classes.darkGreyBg}>New email domain</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>2</TableCell>
                          <TableCell>user1<strong>@company.com</strong></TableCell>
                          <TableCell>user1<strong>@xyz.inc</strong></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>3</TableCell>
                          <TableCell>user2<strong>@company.com</strong></TableCell>
                          <TableCell>user2<strong>@xyz.inc</strong></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={clsx(classes.lightGreyBg, classes.tbsm)}>4</TableCell>
                          <TableCell>user3<strong>@company.com</strong></TableCell>
                          <TableCell>user3<strong>@xyz.inc</strong></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <Typography variant="body2">Note: Preview only displays the first couple rows</Typography>

                </Grid>
              </Grid>
            </TabPanel>
            <Box my={20} />

          </>
        }
      </div>
    </>
  );
}

const importedMappedItems = [
  {
    value: 'employeeID',
    label: 'Employee ID',
  },
  {
    value: 'legalFN',
    label: 'First name',
  },
  {
    value: 'legalLN',
    label: 'Last name',
  },
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'secondaryEmail',
    label: 'Secondary Email',
  },
  {
    value: 'DOB',
    label: 'Date of Birth',
  },
  {
    value: 'gender',
    label: 'Gender',
  },
  {
    value: 'mobilePhone',
    label: 'Mobile Phone',
  },
  {
    value: 'ruleClass',
    label: 'Rule Class',
  },
  {
    value: 'department',
    label: 'Department',
  },
  {
    value: 'position',
    label: 'Position',
  },
]

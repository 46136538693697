import { ApprovalController } from "@cbtravel/common/lib/shared/api/approval/controllers/approval-controller";
import { ApprovalRequestController } from "@cbtravel/common/lib/shared/api/approval/controllers/approval-request-controller";
import { ApprovalTypeSearchType } from "@cbtravel/common/lib/shared/common/enumerations/approval-type-search-type";
import { EntityDepth } from "@cbtravel/common/lib/shared/common/enumerations/entity-depth";
import { JsonException } from '@cbtravel/common/lib/shared/common/exceptions/json-exception';
import { PagedList } from '@cbtravel/common/lib/shared/common/paged-list';
import { ApprovalRQ } from "@cbtravel/common/lib/shared/messages/approval/requests/approval-rq";
import { ApprovalRequestInitializeRQ } from "@cbtravel/common/lib/shared/messages/approval/requests/custom/approval-request-initialize-rq";
import { ApprovalRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-rs";
import { PreTripAuthorizationInput } from "@cbtravel/common/lib/shared/messages/approval/responses/custom/pre-trip-authorization-input";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateRange } from "moment-range";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import Spinner from "../../../components/shared/Spinner";
import { UserContext } from "../../../components/shared/UserContext";
import DateRangePicker from "../../../components/ui/DatePicker/DateRangePicker";
import { formUtils } from "../../../util/form-utils";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(0.5),
    },
    "& .MuiTableCell-root": {
      lineHeight: 1.4,
    },
    "& .MuiButton-contained": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  displayBlock: {
    display: "flex",
    paddingBottom: 32,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  disabledNote: {
    fontSize: 14,
    paddingLeft: 16,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 16,
      paddingLeft: 0,
    },
  },
  errorText: {
    paddingTop: '5px',
  },
  dateErrorText: {
    paddingTop: '2px',
  },
}));

type ApprovalRequestFormImputs = {
  passengerFirstName: string,
  passengerLastName: string,
  passengerEmail: string,
  leavingFrom: string,
  goingTo: string,
  purposeOfTrip: string,
  departureAndReturnDates: [Date, Date],
  estimatedTotal: string,
  additionalComments: string,
}

interface ApprovalRequestsProps {
  /** Object representing the currently active client. */
  activeClient?: ClientRS,
}

/**
 * The Approval Requests page is used for submitting pre-trip authorization trip approval requests.
 * 
 * @param props {@link ApprovalRequestsProps Properties} for the ApprovalRequest component.
 * @returns A JSX element used for the Approval Requests page.
 */
export default function ApprovalRequest(props: ApprovalRequestsProps) {
  const classes = useStyles();
  const { userContext } = useContext(UserContext)
  const snackbar = useCustomSnackbar();

  const [approvalId, setApprovalId] = useState<number>(-1)  // also used to determine if submit button should be disabled
  const [showSpinner, setShowSpinner] = useState<boolean>(false) // used to render spinner
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)

  const commentRef = useRef<HTMLInputElement | undefined>()
  const purposeRef = useRef<HTMLInputElement | undefined>()
  const originRef = useRef<HTMLInputElement | undefined>();
  const destinationRef = useRef<HTMLInputElement | undefined>()
  const estimateRef = useRef<HTMLInputElement | undefined>()
  const passengerFirstNameRef = useRef<HTMLInputElement | undefined>();
  const passengerLastNameRef = useRef<HTMLInputElement | undefined>();
  const passengerEmailRef = useRef<HTMLInputElement | undefined>();

  /**
   * Definitions for validation of form fields.
   */
  const formRules = {
    passengerFirstName: {
      required: "Please enter a first name",
    },
    passengerLastName: {
      required: "Please enter a last name",
    },
    passengerEmail: {
      required: "Please enter an email",
    },
    leavingFrom: {
      required: "Please enter a destination",
    },
    goingTo: {
      required: "Please enter a destination",
    },
    departureAndReturnDates: {
      required: "Please enter a date",
    },
    purposeOfTrip: {
      required: "Please enter a purpose of trip",
      maxLength: { value: 255, message: "Max 255 characters" },
    },
    additionalComments: {
      maxLength: { value: 500, message: "Max 500 characters" },
    },
  };

  const {
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
    control,
  } = useForm<ApprovalRequestFormImputs>({ mode: "onBlur" });

  useEffect(() => {
    if (!props.activeClient) return;
    const approvalRQ: ApprovalRQ = new ApprovalRQ();
    approvalRQ.clientId = props.activeClient.clientId;
    approvalRQ.approvalType = ApprovalTypeSearchType.PreTripAuthorization;
    setApprovalId(-1);

    /**
     * Checks for an existing PreTripAuthorization approval configuration.
     */
    async function checkApprovalRecord() {
      const token = userContext.accessToken;
      try {
        const result: PagedList<ApprovalRS> = await ApprovalController.Find(token, approvalRQ, EntityDepth.Shallow);
        if (result.list.length > 0) {
          if (result.list[result.list.length - 1].isEnabled) {
            setApprovalId(result.list[result.list.length - 1].approvalId)
          }

        }
      }
      catch (err) {
        snackbar.error(err as JsonException);
      }
    }

    checkApprovalRecord();

  }, [props.activeClient])


  /**
   * Collects values from the form and submits them to the ApprovalRequest API.
   * 
   * @param form The object which holds the values that are in the form.
   */
  async function onSubmit(form: ApprovalRequestFormImputs) {
    const token = userContext.accessToken;
    const approvalRequestInitializeRQ: ApprovalRequestInitializeRQ = new ApprovalRequestInitializeRQ();
    approvalRequestInitializeRQ.emailAddress = userContext.email;
    approvalRequestInitializeRQ.userId = parseInt(userContext.userId);
    approvalRequestInitializeRQ.comment = commentRef.current?.value ? commentRef.current.value : "";
    approvalRequestInitializeRQ.approvalId = approvalId;
    var input = new PreTripAuthorizationInput();

    let dates = new DateRange(form.departureAndReturnDates)

    input.PURPOSE = form.purposeOfTrip;
    input.DESTINATION = form.goingTo;
    input.DEPARTDATE = dates.start.format("MM/DD/YYYY");
    input.RETURNDATE = dates.end.format("MM/DD/YYYY");
    input.TOTALEST = form.estimatedTotal ? form.estimatedTotal : "";
    input.PRMPASSFIRST = form.passengerFirstName;
    input.PRMPASSLAST = form.passengerLastName;
    input.PRMPASSEMAIL = form.passengerEmail;
    input.ORIGIN = form.leavingFrom;

    approvalRequestInitializeRQ.approvalRequestInputList = input;
    let success: boolean = false;
    try {
      setShowSpinner(true);
      setSubmitDisabled(true);
      const rs: Response = await ApprovalRequestController.Initialize(token, approvalRequestInitializeRQ);
      success = rs.ok;
    }
    catch (err) {
      snackbar.error(err as JsonException);
    }
    finally {
      setSubmitDisabled(false);
      setShowSpinner(false)
    }

    if (success) {
      reset({
        passengerFirstName: "",
        passengerLastName: "",
        passengerEmail: "",
        goingTo: "",
        leavingFrom: "",
        additionalComments: "",
        purposeOfTrip: "",
        estimatedTotal: "",
        departureAndReturnDates: undefined,
      });
      snackbar.info("Your request has been submitted and is awaiting approval");
    }
  };

  return (
    <div className={classes.root}>
      {showSpinner && <Spinner />}
      <Typography variant="h2">Primary passenger info</Typography>
      <Box pb={1}>
        <Typography variant="body2">
          Details of the primary passenger you're requesting this trip for
        </Typography>
      </Box>

      <Box pb={2}>
        <Grid container spacing={3} alignContent="flex-end">
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box mt={2}>
              <Controller
                name="passengerFirstName"
                control={control}
                rules={formRules.passengerFirstName}
                render={({
                  field: { onChange, value, ref, onBlur }
                }) => (
                  <TextField
                    id="ff-first-name"
                    label="Passenger first name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                    onChange={(e) => {
                      return onChange(e.target.value);
                    }}
                    value={value}
                    ref={ref}
                    error={errors?.passengerFirstName ? true : false}
                    inputRef={passengerFirstNameRef}
                  />
                )} />
              <Typography
                variant="body2"
                color="error"
                className={classes.errorText}
              >
                {errors.passengerFirstName && errors.passengerFirstName.message}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box mt={2}>
              <Controller
                name="passengerLastName"
                control={control}
                rules={formRules.passengerLastName}
                render={({
                  field: { onChange, value, ref, onBlur }
                }) => (
                  <TextField
                    id="ff-last-name"
                    label="Passenger last name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                    onChange={(e) => {
                      return onChange(e.target.value);
                    }}
                    value={value}
                    ref={ref}
                    error={errors?.passengerLastName ? true : false}
                    inputRef={passengerLastNameRef}
                  />
                )} />
              <Typography
                variant="body2"
                color="error"
                className={classes.errorText}
              >
                {errors.passengerLastName && errors.passengerLastName.message}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box mt={2}>
              <Controller
                name="passengerEmail"
                control={control}
                rules={formRules.passengerEmail}
                render={({
                  field: { onChange, value, ref, onBlur }
                }) => (
                  <TextField
                    id="ff-email"
                    label="Passenger email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                    onChange={(e) => {
                      return onChange(e.target.value);
                    }}
                    value={value}
                    ref={ref}
                    error={errors?.passengerEmail ? true : false}
                    inputRef={passengerEmailRef}
                  />
                )} />
              <Typography
                variant="body2"
                color="error"
                className={classes.errorText}
              >
                {errors.passengerEmail && errors.passengerEmail.message}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h2">Trip info</Typography>

      <Grid container spacing={3} alignContent="flex-end">
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box mt={2}>
            <Controller
              name="leavingFrom"
              control={control}
              rules={formRules.leavingFrom}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <TextField
                  id="ff-origin"
                  label="Leaving from"
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputRef={originRef}
                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                  onChange={(e) => {
                    return onChange(e.target.value);
                  }}
                  value={value}
                  ref={ref}
                  error={errors?.leavingFrom ? true : false}
                />
              )} />
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              {errors.leavingFrom && errors.leavingFrom.message}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box mt={2}>
            <Controller
              name="goingTo"
              control={control}
              rules={formRules.goingTo}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <TextField
                  id="ff-destination"
                  label="Going to"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                  onChange={(e) => {
                    return onChange(e.target.value);
                  }}
                  value={value}
                  ref={ref}
                  error={errors?.goingTo ? true : false}
                  inputRef={destinationRef}
                />
              )} />
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              {errors.goingTo && errors.goingTo.message}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box mt={1}>
            <Controller
              name="departureAndReturnDates"
              control={control}
              rules={formRules.departureAndReturnDates}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <DateRangePicker
                  title="departure-return"
                  numberOfCalendars={2}
                  textFieldLabel="Departure and return dates"
                  onChange={(e) => {
                    if (e && e.start !== null && e.end !== null) {
                      onChange([e.start, e.end])
                    }
                    onBlur()
                  }}
                  minDate={new Date()}
                  displayFutureFilters={false}
                  displayPreviousFilters={false}
                  value={value && new DateRange(value)}
                  error={errors?.departureAndReturnDates ? true : false}
                  onBlur={onBlur}
                />
              )} />
            <Typography
              variant="body2"
              color="error"
              className={classes.dateErrorText}
            >
              {errors.departureAndReturnDates && 'Please enter a date'}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box mt={1}>
            <Controller
              name="estimatedTotal"
              control={control}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <TextField
                  id="ff-estimated-total"
                  label="Estimated total"
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputRef={estimateRef}
                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                  onChange={(e) => {
                    return onChange(e.target.value);
                  }}
                  value={value}
                  ref={ref}
                />
              )} />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box mt={1}>
            <Controller
              name="purposeOfTrip"
              control={control}
              rules={formRules.purposeOfTrip}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <TextField
                  id="ff-purpose"
                  label="Purpose of trip"
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                  onChange={(e) => {
                    return onChange(e.target.value);
                  }}
                  value={value}
                  ref={ref}
                  error={errors?.purposeOfTrip ? true : false}
                  inputRef={purposeRef}
                />
              )} />
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              {errors.purposeOfTrip && errors.purposeOfTrip.message}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignContent="flex-end">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box mt={2}>
            <Controller
              name="additionalComments"
              control={control}
              rules={formRules.additionalComments}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <TextField
                  id="ff-comment"
                  label="Additional comments"
                  multiline
                  fullWidth
                  rows={5}
                  variant="outlined"
                  inputProps={{ maxLength: 500 }}
                  inputRef={commentRef}
                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                  error={errors?.additionalComments ? true : false}
                  onChange={(e) => {
                    return onChange(e.target.value);
                  }}
                  value={value}
                  ref={ref}
                />
              )} />
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              {errors.additionalComments && errors.additionalComments.message}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box alignItems="center" className={classes.displayBlock}>
            <Button
              id="btn-submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={submitDisabled || approvalId < 0 || !isValid}
            >
              Submit
            </Button>
            {approvalId < 0 && <Box>
              <Typography component="p" className={classes.disabledNote}>
                <strong>Note:</strong> Approval requests are currently not configured or enabled
                for this client
              </Typography>
            </Box>}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

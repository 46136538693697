import React, { Fragment } from 'react';
import { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Button, Fab, Grid, Link, Tab, Tabs, Typography } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import LocationsTab from './LocationsTab';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;

}
interface LocationsProps {
  /** Object representing the currently active client. */
  activeClient?: ClientRS,

}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography>{children}</Typography>
      )}
    </div>
  );
}

function tabProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
  },
  tabs: {
    "& .MuiTab-root": {
      minWidth: 'auto',
    },
    "& .MuiTabScrollButton-root": {
      width: '20px',
    },
    backgroundColor: '#ffffff',
    borderBottomWidth: '1px',
    borderBottomColor: '#ddd',
    borderBottomStyle: 'solid',
  },
  tab: {
    textTransform: 'none',
    paddingLeft: 25,
    paddingRight: 25,
  },
  // appbarStyle: {
  //   marginTop: '17px',
  //   boxShadow: 'none',
  // },
  tabRow: {
    marginTop: 17,
    marginLeft: -4,
    boxShadow: 'none',
  },
  fab: {
    "& .MuiSvgIcon-root": {
      fontSize: '2rem',
    },
    position: "absolute",
    bottom: 32, right: 32,
    width: 50, height: 50,
    color: "#fff",
    background: "#00467E",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  },
  downloadWrapper: {
    paddingTop: 33,
  },
  download: {
    paddingTop: 15,
    paddingRight: "4px",
    fontSize: '.8rem',
  },
 
}));

export default function Locations(props: LocationsProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);


  
 

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box>
        <div className={classes.root}>
          <AppBar className={classes.tabRow} position="static" color="default">
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="locations tab navigation"
            >
              <Tab className={classes.tab} label="Locations" {...tabProps(0)} />
              <Tab className={classes.tab} label="Reference data" {...tabProps(1)} />
              <Tab className={classes.tab} label="Vendors" {...tabProps(2)} />
            </Tabs>
          </AppBar>
          <Box>
      </Box>

          <TabPanel value={value} index={0}>
            <LocationsTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
           <Box> <Typography>Reference data</Typography></Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <Box><Typography>Vendors</Typography></Box>
          </TabPanel>
        </div>
      </Box>
      {/* {!displayLocationsForm &&
        <Fab
          color="primary"
          aria-label="Add location"
          className={classes.fab}
          onClick={handleAddLocation}
        >
          <AddIcon />
        </Fab>
      } */}
    </>
  );
}
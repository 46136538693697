import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Box, Grid, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import { Delete, Visibility, VisibilityOff } from '@material-ui/icons';
import UserSearchBox from '../../../components/shared/UserSearchBox';
import { UserLT } from '@cbtravel/common/lib/shared/messages/general/responses/lite/user-lt';
import SearchIcon from '@material-ui/icons/Search';


interface ApproverEmailSearchBoxProps {
  /** Whether or not this component should autofocus. */
  focus?: boolean,
  /** The number of users in this group. */
  groupCount: number,
  /** Object representing the user held by this component. */
  user: UserLT,
  /** Function to execute when the value held by this component changes. */
  onChange: (oldUserId: number, newUser: UserLT) => void,
  /** Function to execute when this user should be removed as an approver. */
  removeApprover: (userId: number) => void,
}



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      "& .MuiTypography-body2": {
        lineHeight: 1.5,
      },
    },
    stepLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
    },
    confirmationUserRemove: {
      minWidth: "30px !important",
      padding: "7px 9px 8px",
      marginTop: 8,
      marginLeft: "12px",
      background: "rgba(201, 199, 199, .2)",
      "& .MuiSvgIcon-root": {
        color: "#808080",
      },
    },
  }),
);



export default function Notifications() {
  const classes = useStyles();
  const [inputList, setInputList] = useState([] as any);
  const [inputList2, setInputList2] = useState([] as any);

  const Input = () => {
    return (
      <Box display="flex" flexDirection="row" my={1}>
      <Grid lg={8} md={8} sm={8} xs={8}>
        <OutlinedInput
          fullWidth
          margin="dense"
          endAdornment={<InputAdornment position="end"><SearchIcon style={{ width: 20 }} /></InputAdornment>}
           />
      </Grid>
      <Grid lg={2} md={2} sm={2} xs={2}>
        <Box mt={-1}>
          <Button
            variant="contained"
            className={classes.confirmationUserRemove}
          >
            <Delete />
          </Button>
        </Box>
      </Grid>
    </Box> 
    );
  };
  const Input2 = () => {
    return (
      <Box display="flex" flexDirection="row" my={1}>
      <Grid lg={8} md={8} sm={8} xs={8}>
        <OutlinedInput
          fullWidth
          margin="dense"
          endAdornment={<InputAdornment position="end"><SearchIcon style={{ width: 20 }} /></InputAdornment>}
           />
      </Grid>
      <Grid lg={2} md={2} sm={2} xs={2}>
        <Box mt={-1}>
          <Button
            variant="contained"
            className={classes.confirmationUserRemove}
          >
            <Delete />
          </Button>
        </Box>
      </Grid>
    </Box> 
    );
  };
  //adds new row of additonal fields to table 
  const onAddBtnClick = (event: any) => {
    setInputList(inputList.concat(<Input key={inputList.length} />));
  };
   //adds new row of additonal fields to table 
   const onAddBtnClick2 = (event: any) => {
    setInputList2(inputList2.concat(<Input2 key={inputList2.length} />));
  };




  return (
    <div className={classes.root}>
      <Box pt={4}>
        <Typography className={classes.stepLabel}>Step 4</Typography>
        <Typography>Notifications (optional)</Typography>
        <Box mt={1} />
        <Typography variant='body2'>Notify users by email if the HR feed upload fails or succeeds</Typography>
      </Box>
      <Box my={3}>
      <Box mt={1}><Typography variant="body2"><strong>If the upload succeeds:</strong> </Typography></Box>
        {inputList2}
        <Box my={1}><Button variant="outlined" onClick={onAddBtnClick2} >Add email </Button></Box>
        </Box>
       
       <Box my={3}>
      <Box mt={1}><Typography variant="body2"><strong>If the upload fails:</strong> </Typography></Box>
        {inputList}
        <Box my={1}><Button variant="outlined" onClick={onAddBtnClick} >Add email </Button></Box>
      </Box>
      
    </div>
  );
}
import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from "clsx";
import { Box, Collapse, IconButton, Typography } from '@material-ui/core';
import { Table, TableBody, TableCell, TableRow, } from "@material-ui/core";

// Icon imports
import { CheckIcon, EditIcon } from "../../../icons/Icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
interface HotelLocationsDataRowProps {
    /** Hotel chain code */
    code: string,
    /** Hotel name */
    hotelName: string,
    /** Hotel chain */
    hotelChain: string,
    /** Hotel city  */
    city: string,
    /** Hotel state/province  */
    stateProvince: string,
    /** Hotel country  **/
    country: string,
    /** Hotel postal code  **/
    postalCode: string,
    /** Hotel status (Active or Inactive) */
    isActive: boolean,
    /** Shows if GDS codes are mapped to the hotel */
    extraCodes: boolean,
    /** Hotel address 1 */
    streetAddress1: string,
    /** Hotel address 2 */
    streetAddress2: string,
    /** Hotel timezone */
    timeZone: string,
    /** Hotel latitude */
    latitude: string,
    /** Hotel longitude */
    longitude: string,
    /** Hotel phone */
    phone: string,
    /** Hotel fax */
    fax: string,
}

const useStyles = makeStyles((theme: Theme) => ({
    "@global": {
        '.MuiTableCell-sizeSmall': {
            padding: "6px 24px 6px 22px",
        },
    },
    activeBadge: {
        borderRadius: 4, background: '#ECF1E6', padding: '2px 6px', height: 'auto', textAlign: 'center', display: 'inline-flex',
        '& .MuiTypography-body1': {
            color: '#417505', fontSize: 11, textTransform: 'uppercase',
        }
    },
    inactiveBadge: {
        borderRadius: 4, background: 'rgba(201, 199, 199, .2)', padding: '2px 6px', height: 'auto', textAlign: 'center', display: 'inline-flex',
        '& .MuiTypography-body1': {
            color: '#808080', fontSize: 11, textTransform: 'uppercase',
        }
    },
    dropdownTable: {
        "& .MuiTableCell-root": {
            borderTop: 'none',
            borderBottom: '1px solid #E0E0E0 !important',
            padding: '2px 0 !important',
        },
    },
    dropdownRow: {
        background: "#f9f9f9",
        borderBottom: '1px solid #E0E0E0',
        "& .MuiTableCell-sizeSmall": {
            padding: 0, // this gets rid of the random padding the collapse row shows 
        },
    },
    borderTop: {
        borderTop: '1px solid #E0E0E0', // shows border top of dropdown row
    },
    noBorderTop: {
        borderTop: 'none', // hides it for the dropdown is collapsed, otherwise it shows double borders
    },
    dropdownHeader: { textTransform: 'uppercase', color: "#808080", fontSize: '.8rem', letterSpacing: -.05 },
    ellipses: {
        color: '#00467E',
        paddingLeft: 2,
        fontSize: '18px',
        lineHeight: '15px',
        cursor: 'pointer',
    },
}));

/**
 * Component used to display information about a Hotel on the Locations page.
 * 
 * @param props {@link HotelLocationsDataRowProps Properties} for the `HotelLocationsDataRow` component.
 * @returns A JSX element for displaying location information on the Locations page.
 */
export default function HotelLocationsDataRow(props: HotelLocationsDataRowProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { code, hotelName, hotelChain, city, stateProvince, isActive, extraCodes, streetAddress1, streetAddress2, country, postalCode, timeZone, latitude, longitude, phone, fax, } = props;

    return (
        <>
            <TableRow key={hotelName}>
                <TableCell>
                    Hotel
                </TableCell>
                <TableCell>
                    <Box display="flex" flexDirection="row">
                        {code}{extraCodes ? <Box className={classes.ellipses} onClick={() => setOpen(!open)}>...</Box> : " "}
                    </Box>
                </TableCell>
                <TableCell component="th" scope="vendor">{hotelName}</TableCell>
                <TableCell>{phone}</TableCell>
                <TableCell>{hotelChain}</TableCell>
                <TableCell>{city}</TableCell>
                <TableCell align="center">
                    {isActive ?
                        (<FiberManualRecordIcon style={{ color: "#4A8B2C" }}  />) : (<FiberManualRecordIcon  color="disabled"  />)}
                </TableCell>
                <TableCell align="center">
                    <Box mt={-.5}>
                        <EditIcon color="#00467E" height="22" width="20" />
                    </Box>
                </TableCell>
                <TableCell align="center">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon style={{ color: "#1A304E", }} /> : <KeyboardArrowDownIcon style={{ color: "#1A304E", }}/>}
                    </IconButton></TableCell>
            </TableRow>
            {/* Dropdown row  */}
            <TableRow className={clsx(classes.dropdownRow, open ? classes.borderTop : classes.noBorderTop)}>
                <TableCell colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box py={2.5} px={3}>
                        <Table
                                aria-label="More info about the Hotel location"
                                className={classes.dropdownTable}
                                size="small"
                            >
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={4} component="th" className={classes.dropdownHeader} scope="row">
                                            Address
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width='20%'><strong>Street address 1</strong></TableCell>
                                        <TableCell width='20%'>{streetAddress1}</TableCell>
                                        <TableCell width='20%'><strong>State/Province</strong></TableCell>
                                        <TableCell width='40%'>{stateProvince}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Street address 2</strong></TableCell>
                                        <TableCell>{streetAddress2}</TableCell>
                                        <TableCell><strong>Country</strong></TableCell>
                                        <TableCell>{country}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>City</strong></TableCell>
                                        <TableCell>{city}</TableCell>
                                        <TableCell><strong>Postal code</strong></TableCell>
                                        <TableCell>{postalCode}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell><strong> Latitude</strong></TableCell>
                                        <TableCell colSpan={3}>{latitude}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell><strong>Longitude</strong></TableCell>
                                        <TableCell colSpan={3}>{longitude}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell><strong>Timezone</strong></TableCell>
                                        <TableCell colSpan={3}>{timeZone}</TableCell>
                                        </TableRow>
                                    <Box mt={2.5}></Box>
                                    <TableRow>
                                        <TableCell  colSpan={4} component="th" className={classes.dropdownHeader} scope="row">
                                          Contact
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell><strong>Primary phone:</strong></TableCell>
                                        <TableCell>{phone}</TableCell>
                                        <TableCell><strong>Fax:</strong></TableCell>
                                        <TableCell>{fax}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell><strong>GDS provider:</strong></TableCell>
                                        <TableCell>{extraCodes ? "Amadeus" : "N/A"}</TableCell>
                                        <TableCell><strong>GDS code:</strong></TableCell>
                                        <TableCell colSpan={3}>{extraCodes ? "4805" : "--"}</TableCell>
                                    </TableRow>
                                </TableBody>
                                <Box mb={1}></Box>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


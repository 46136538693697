import { CustomFieldController } from "@cbtravel/common/lib/shared/api/general/controllers/custom-field-controller";
import { ProfileIncompleteController } from "@cbtravel/common/lib/shared/api/profiles/profile-incomplete-controller";
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";
import { EntityDepth } from "@cbtravel/common/lib/shared/common/enumerations/entity-depth";
import { ReturnCount } from "@cbtravel/common/lib/shared/common/enumerations/return-count";
import { SortDirection } from "@cbtravel/common/lib/shared/common/enumerations/sort-direction";
import { CustomFieldSortType, ProfileIncompleteSortType } from "@cbtravel/common/lib/shared/common/enumerations/sort-types";
import { PagedList } from "@cbtravel/common/lib/shared/common/paged-list";
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { CustomFieldRQ } from "@cbtravel/common/lib/shared/messages/general/requests/custom-field-rq";
import { ClientRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-rs";
import { CustomFieldRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs";
import { ProfileIncompleteRQ } from "@cbtravel/common/lib/shared/messages/profiles/requests/profile-incomplete-rq";
import { ProfileIncompleteRS } from "@cbtravel/common/lib/shared/messages/profiles/responses/profile-incomplete-rs";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { UserContext } from "../../../components/shared/UserContext";
import IncompleteProfileSearchDataRow from "./IncompleteProfileSearchDataRow";
import { formUtils } from '../../../util/form-utils';

//MUI
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MultipleSelectChips, { Option } from "../../../components/ui/Input/MultipleSelectChips";

//icons
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { useCustomSnackbar } from "../../../components/shared/customHooks/useCustomSnackbar";
import SortIcon from "../../../icons/SortIcon";
import { CustomFieldSourceSearchType } from "@cbtravel/common/lib/shared/common/enumerations/search-types";
import Spinner from "../../../components/shared/Spinner";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "& .MuiTableCell-root": {
            lineHeight: 1.4,
        },
        "& .MuiButton-contained": {
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
    },
    results: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
        marginBottom: 0,
    },
    pagination: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    headersWithIcon: {
        padding: '0px 24px 0px 16px !important',
    },
    table: {
        marginBottom: 50,
        "& .MuiTableCell-root": {
            borderBottom: 'none',
            lineHeight: 1.5,
        },
        "& .MuiTableCell-head": {
            borderTop: '1px solid #E0E0E0 !important',
            borderBottom: '1px solid #E0E0E0 !important',
            padding: '3px 24px 5px 16px',
            whiteSpace: 'nowrap',
        },
        "& .MuiTableRow-root": {
            borderTop: '1px solid #E0E0E0 !important',
            borderBottom: '1px solid #E0E0E0 !important',
        },
    },
}));

type ProfileIncompleteSearchFormInputs = {
    searchTerm: string,
    accountOptions: Array<Option>,
}

interface sortManagerI {
    emailAddress: boolean,
    firstName: boolean,
    hasMobilePhone: boolean,
    hasDateOfBirth: boolean,
    hasGender: boolean,
}

export default function IncompleteProfiles(props: { activeClient?: ClientRS, }) {
    const classes = useStyles();
    const theme = useTheme();
    const { userContext } = useContext(UserContext);
    const snackbar = useCustomSnackbar();
    const [isPageBusy, setIsPageBusy] = useState<boolean>(false);

    const [customFieldRSList, setCustomFieldRSList] = useState<PagedList<CustomFieldRS>>(new PagedList<CustomFieldRS>());
    const [profileIncompleteRSList, setProfileIncompleteRSList] = useState<PagedList<ProfileIncompleteRS>>(new PagedList<ProfileIncompleteRS>());
    const [sortBy, setSortBy] = useState<ProfileIncompleteSortType>(ProfileIncompleteSortType.ByEmailAddress);
    const [sortAscending, setSortAscending] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [selectedClients, setSelectedClients] = useState<Option[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);

    const [sortManager, setSortManager] = useState<sortManagerI>({
        emailAddress: true,
        firstName: true,
        hasMobilePhone: true,
        hasDateOfBirth: true,
        hasGender: true,
    });

    const clientOptions: Option[] = userContext.viewableClients.map((c) => {
        return { label: c.name + (c.accountNumber ? " (" + c.accountNumber + ")" : ""), value: c.clientId };
    });

    const formRules = {
        searchTerm: {
        },
        accountOptions: {
            validate: {
                required: (value: Option[]) => {
                    if (value) {
                        if (value.length > 1)
                            return "You may only include 1 item. Please reduce your selection.";
                        if (value.length < 1)
                            return "Please select at least one account.";
                    }
                }
            }
        }
    }

    const {
        formState: { errors, isValid },
        control,
        getValues,
        setValue,
    } = useForm<ProfileIncompleteSearchFormInputs>({
        mode: "onChange",
        defaultValues: {
            searchTerm: "",
            accountOptions: new Array<Option>(),
        }
    });


    useEffect(() => {

        if (searchTerm.length > 1) {

            //don't search until we know the user's done typing (1.5 seconds after they stop)
            //search until the user type on either last/first name with minimum 2 characters
            const timeOut = setTimeout(function () {

                profileIncompleteSearch(0);

                //set the page number to first page when initialize a search
                setPageNumber(0);
            }, Configuration.SearchWaitConfigTime)
            return () => clearTimeout(timeOut)
        }
        else if (selectedClients.length > 0) {

            profileIncompleteSearch(0);

            //set the page number to first page when initialize a search
            setPageNumber(0);
        }
    }, [searchTerm, sortBy, sortAscending, selectedClients, rowsPerPage]);

    useEffect(() => {

        // Watch the user's active client so we that we can select that client by default when this page is rendered.
        if (props.activeClient) {
            let clientName: string = props.activeClient.name;
            let clientId: number = props.activeClient.clientId;

            setValue("accountOptions", [{ label: clientName, value: clientId }]);
            setSelectedClients([{ label: clientName, value: clientId }]);
        }
    }, [props.activeClient]);

    async function profileIncompleteSearch(inputPageNumber: number) {

        getCustomFields();
        getIncompleteProfiles(inputPageNumber);
    }


    async function getCustomFields(): Promise<void> {

        if (selectedClients.length === 1) {

            setIsPageBusy(true);
            let customFieldRQ: CustomFieldRQ = new CustomFieldRQ();
            customFieldRQ.activeSearchType = ActiveSearchType.Active;
            customFieldRQ.returnCount = ReturnCount.ReturnRecordsOnly;
            customFieldRQ.sortType = CustomFieldSortType.ByNumber;
            customFieldRQ.customFieldSourceSearchType = CustomFieldSourceSearchType.Profile;

            customFieldRQ.clientId = selectedClients[0].value;

            try {
                let results: PagedList<CustomFieldRS> = await CustomFieldController.Find(userContext.accessToken, customFieldRQ, EntityDepth.Shallow);
                setCustomFieldRSList(results);
            } catch (err) {
                snackbar.error(err as JsonException);
            } finally {
                setIsPageBusy(false);
            }
        }
    }

    async function getIncompleteProfiles(inputPageNumber: number): Promise<void> {
        if (selectedClients.length === 1) {

            setIsPageBusy(true);
            let profileIncompleteRQ: ProfileIncompleteRQ = new ProfileIncompleteRQ();
            profileIncompleteRQ.limit = rowsPerPage;
            profileIncompleteRQ.skip = inputPageNumber * rowsPerPage;
            profileIncompleteRQ.sortType = sortBy;
            profileIncompleteRQ.sortDirection = sortAscending ? SortDirection.Ascending : SortDirection.Descending;
            profileIncompleteRQ.returnCount = ReturnCount.ReturnRecordsAndCount;
            searchTerm.length > 1 ? profileIncompleteRQ.wildcard = searchTerm : profileIncompleteRQ.emailAddress = '';

            profileIncompleteRQ.clientId = selectedClients[0].value;

            try {
                let results: PagedList<ProfileIncompleteRS> = await ProfileIncompleteController.Find(userContext.accessToken, profileIncompleteRQ, EntityDepth.Deep);

                setProfileIncompleteRSList(results);
            } catch (err) {
                snackbar.error(err as JsonException);
            }
            finally {
                setIsPageBusy(false);
            }
        }
    }

    /**
     * Handles the download of incomplete profiles. 
     */
    async function handleDownloadReport() {

        if (selectedClients.length === 1) {

            let profileIncompleteRQ = new ProfileIncompleteRQ();
            profileIncompleteRQ.limit = 0;
            profileIncompleteRQ.skip = 0;
            profileIncompleteRQ.returnCount = ReturnCount.ReturnRecordsOnly;
            profileIncompleteRQ.activeSearchType = ActiveSearchType.Active;

            profileIncompleteRQ.clientId = selectedClients[0].value;

            // columns that we want in the CSV. RequiredCustomFields includes all custom fields
            let defaultColumnList = ["EmailAddress", "FirstName", "LastName", "HasMobilePhone", "HasDateOfBirth", "HasGender", "RequiredCustomFields"];

            try {
                setIsPageBusy(true);
                // get blob from API
                let fileBlob = await ProfileIncompleteController.FindAsCsv(
                    userContext.accessToken,
                    profileIncompleteRQ,
                    defaultColumnList
                );

                // make download
                let filename = "incomplete-profiles.csv";

                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) { // for IE
                    (window.navigator as any).msSaveOrOpenBlob(fileBlob, filename);
                } else {
                    let blobUrl = window.URL.createObjectURL(fileBlob);
                    let link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);

                    link.click();
                    link.parentNode?.removeChild(link);
                    window.URL.revokeObjectURL(blobUrl);
                }
            } catch (e) {
                snackbar.error(e as JsonException);
            } finally {
                setIsPageBusy(false);
            }
        }
    }

    function handleChangeSort(sortBy: ProfileIncompleteSortType) {
        let newSortManager = { emailAddress: true, firstName: true, hasMobilePhone: true, hasDateOfBirth: true, hasGender: true };

        setSortBy(sortBy);

        switch (sortBy) {
            case ProfileIncompleteSortType.ByEmailAddress:
                newSortManager.emailAddress = !sortManager.emailAddress;
                setSortAscending(sortManager.emailAddress);
                break;
            case ProfileIncompleteSortType.ByFirstName:
                newSortManager.firstName = !sortManager.firstName;
                setSortAscending(sortManager.firstName);
                break;
            case ProfileIncompleteSortType.ByHasMobilePhone:
                newSortManager.hasMobilePhone = !sortManager.hasMobilePhone;
                setSortAscending(sortManager.hasMobilePhone);
                break;
            case ProfileIncompleteSortType.ByHasDateOfBirth:
                newSortManager.hasDateOfBirth = !sortManager.hasDateOfBirth;
                setSortAscending(sortManager.hasDateOfBirth);
                break;
            case ProfileIncompleteSortType.ByHasGender:
                newSortManager.hasGender = !sortManager.hasGender;
                setSortAscending(sortManager.hasGender);
                break;
        }

        setSortManager(newSortManager);
    }

    /**
     * Handles the updating of pagination.
     * @param event is provided but not used. 
     * @param newPage is the new page number.
     */
    function handleChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
        setPageNumber(newPage);
    }

    /**
     * Handles changing the number of incomplete profiles that are displayed in the table.
     * @param event the new number of incomplete profiles to be displayed by the table.
     */
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageNumber(0);
    }

    return (
        <>
            {isPageBusy && <Spinner />}
            <Box mt={1} mx={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3 className={classes.results}>
                            Search by
                        </h3>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Controller
                            name="searchTerm"
                            control={control}
                            rules={{}}
                            render={({
                                field: { onChange, value, ref, onBlur }
                            }) => (
                                <TextField
                                    id="search-term"
                                    label="Search by"
                                    value={value}
                                    onBlur={(e) => {
                                        formUtils.trimOnBlur(onBlur, onChange, e.target.value);
                                        setSearchTerm(e.target.value.trim())
                                    }}
                                    onChange={event => { setSearchTerm(event.target.value); onChange(event); }}
                                    margin="dense"
                                    variant="outlined"
                                    inputProps={{ minLength: 2 }}
                                    placeholder={"Search by name or email"}
                                    error={errors?.searchTerm ? true : false}
                                    fullWidth
                                    InputProps={{ startAdornment: <SearchOutlinedIcon /> }}
                                />
                            )}
                        />

                    </Grid >
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Controller
                            name="accountOptions"
                            control={control}
                            rules={formRules.accountOptions}
                            render={({
                                field: { onChange }
                            }) => (
                                <MultipleSelectChips
                                    id="ff-accounts"
                                    label="Account"
                                    options={clientOptions}
                                    value={selectedClients}
                                    onChange={(value: Option[]) => {
                                        setSelectedClients(value);
                                        onChange(value);
                                    }}
                                    errorMessage={(errors?.accountOptions as any)?.message}
                                    error={errors.accountOptions ? true : false}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Box mb={3}>
                                <h3 className={classes.results}>
                                    {profileIncompleteRSList.totalCount} Results
                                </h3>
                            </Box>
                            <Box>
                                <a style={{ color: '#00467F', }} href="#" onClick={() => { handleDownloadReport() }}> Download Report</a>
                            </Box>
                        </Box>
                    </Grid>
                    <TableContainer style={{ marginTop: -10, }}>
                        <Table
                            className={classes.table}
                            aria-label="User Search Table"
                            size="small"
                            id="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" className={classes.headersWithIcon}>Edit</TableCell>
                                    <TableCell component="th" className={classes.headersWithIcon} onClick={() => { handleChangeSort(ProfileIncompleteSortType.ByFirstName) }}>
                                        Name<SortIcon sortAscending={!sortManager.firstName} />
                                    </TableCell>
                                    <TableCell className={classes.headersWithIcon} onClick={() => { handleChangeSort(ProfileIncompleteSortType.ByEmailAddress) }}>
                                        Email<SortIcon sortAscending={!sortManager.emailAddress} />
                                    </TableCell>
                                    <TableCell align="center" component="th" className={classes.headersWithIcon} onClick={() => { handleChangeSort(ProfileIncompleteSortType.ByHasMobilePhone) }}>
                                        Mobile Phone<SortIcon sortAscending={!sortManager.hasMobilePhone} />
                                    </TableCell>
                                    <TableCell align="center" component="th" className={classes.headersWithIcon} onClick={() => { handleChangeSort(ProfileIncompleteSortType.ByHasDateOfBirth) }} >
                                        Date of Birth<SortIcon sortAscending={!sortManager.hasDateOfBirth} />
                                    </TableCell>
                                    <TableCell align="center" component="th" className={classes.headersWithIcon} onClick={() => { handleChangeSort(ProfileIncompleteSortType.ByHasGender) }} >
                                        Gender<SortIcon sortAscending={!sortManager.hasGender} />
                                    </TableCell>
                                    {/* For each custom field, create a new column */}
                                    {customFieldRSList.list.map((e) => {
                                        return (<TableCell align="center" component="th" className={classes.headersWithIcon}>{e.name}</TableCell>)
                                    })}
                                </TableRow>
                            </TableHead>

                            {profileIncompleteRSList.totalCount > 0 ?
                                <TableBody>
                                    {profileIncompleteRSList.list.map((profileIncompleteRS) => {
                                        return <IncompleteProfileSearchDataRow profileIncompleteRS={profileIncompleteRS} requiredUDIDList={customFieldRSList}></IncompleteProfileSearchDataRow>
                                    })}
                                </TableBody>
                                :
                                < TableBody >
                                    <TableRow>
                                        <TableCell style={{ padding: 60, }} colSpan={12}><Typography variant='body2' align="center">No data available in this table</Typography></TableCell>
                                    </TableRow>
                                </TableBody>
                            }

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        colSpan={12}
                                        count={profileIncompleteRSList.totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={pageNumber}
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={tablePagination}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid >
            </Box >
        </>
    );

    /**
     * Component for table pagination actions.
     * 
     * @returns the footer that handles the table Pagination buttons.
     */
    function tablePagination() {
        let count = profileIncompleteRSList.totalCount;
        let upperRange = Math.ceil(count / rowsPerPage)

        return (
            <Box className={classes.pagination}>
                <IconButton
                    onClick={() => { getIncompleteProfiles(0); setPageNumber(0); }}
                    disabled={pageNumber === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={() => { getIncompleteProfiles(pageNumber - 1); setPageNumber(pageNumber - 1); }}
                    disabled={pageNumber === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={() => { getIncompleteProfiles(pageNumber + 1); setPageNumber(pageNumber + 1); }}
                    disabled={pageNumber >= upperRange - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={() => { getIncompleteProfiles(Math.max(0, upperRange - 1)); setPageNumber(Math.max(0, upperRange - 1)); }}
                    disabled={pageNumber >= upperRange - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box >
        )
    }
}

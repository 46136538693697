import { useContext, useEffect, useRef, useState } from "react";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { UserContext } from "../../../components/shared/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, CircularProgress, Grid, InputAdornment, Link, Typography, } from "@material-ui/core";
import { TableContainer, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField } from "@material-ui/core";
import AirportLocationsDataRow from "./AirportLocationsDataRow";
import CarRentalLocationsDataRow from "./CarRentalLocationsDataRow";
import HotelLocationsDataRow from "./HotelLocationsDataRow";
import RailLocationsDataRow from "./RailLocationsDataRow";
import LocationsShortForm from "./LocationsShortForm";
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from "clsx";

// Icon imports
import SortIcon from "../../../icons/SortIcon";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FilterIcon } from "../../../icons/Icons";
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import AddIcon from '@material-ui/icons/Add';

interface LocationsTabProps {
    /** Object representing the currently active client. */
    activeClient?: ClientRS,

}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number
    ) => void;
}

const useStyles = makeStyles((theme) => ({
    "@global": {
        ".MuiAutocomplete-option": {
            display: "block",
        },
        ".MuiAutocomplete-popupIndicatorOpen": {
            transform: "rotate(0)"
        },
        ".MuiFilledInput-root": {
            backgroundColor: "#F9F9F9"
        },
    },
    h3: {
        paddingBottom: theme.spacing(1.5),
        marginBottom: 0,
        fontSize: '1rem',
    },
    results: {
        paddingTop: theme.spacing(3.5),
        paddingBottom: theme.spacing(0.5),
        marginBottom: 0,
        fontSize: '1rem',
    },
    pagination: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    download: {
        paddingTop: 15,
        paddingRight: "4px",
        fontSize: '.8rem',
    },
    filetype: {
        padding: 0,
        "& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense":
        {
            padding: "0 4px 2px",
        },
    },
    headersWithIcon: {
        padding: '0px 2px 0px 16px !important',
    },
    table: {
        "& .MuiTableCell-root": {
            borderBottom: 'none',
            lineHeight: 1.5,
        },
        "& .MuiTableCell-head": {
            // borderTop: '1px solid #E0E0E0 !important',
            // borderBottom: '1px solid #E0E0E0 !important',
            padding: '3px 2px 5px 16px',
            whiteSpace: 'nowrap',
        },
    },
    link: {
        cursor: "pointer",
    }
}));

/** Airport tab for Locations page */
export default function LocationsTab(props: LocationsTabProps) {
    const userState = useContext(UserContext);
    const classes = useStyles();
    const fileType = ["CSV", "ZIP"];
    const [loading, setLoading] = useState<boolean>(false);
    const [displayLocationsForm, setDisplayLocationsForm] = useState<boolean>(false);
    const [displayAirport, setDisplayAirport] = useState<boolean>(false);
    const [displayCar, setDisplayCar] = useState<boolean>(false);
    const [displayHotel, setDisplayHotel] = useState<boolean>(false);
    const [displayRail, setDisplayRail] = useState<boolean>(false);
    //** displays airport  */
    const handleDisplayAirport = () => {
        setDisplayAirport(true);
    };
    //** displays car */
    const handleDisplayCar = () => {
        setDisplayCar(true);
    };
    //** displays hotel  */
    const handleDisplayHotel = () => {
        setDisplayHotel(true);
    };
    //** displays rail  */
    const handleDisplayRail = () => {
        setDisplayRail(true);
    };
    /** Opens up Location form */
    const handleAddLocation = () => {
        setDisplayLocationsForm(true);
    }
    /** When discard button is clicked in dialog, it hides the Locations Form */
    const handleDiscardChanges = () => {
        setDisplayLocationsForm(false);
    }
    return (
        <>
            <Box mt={1}>
                {/* ADD Location / DOWNLOAD report */}
                <Grid>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" mb={2}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box pt={2} mr={1} className={classes.download}>
                                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                                    <span className="dark-blue">
                                        <Link
                                            onClick={handleAddLocation}
                                            className={classes.link}
                                        >
                                            Add location
                                        </Link>
                                    </span>
                                    <span className="dark-blue">
                                        <AddIcon />
                                    </span>
                                </Box>
                            </Box>
                            <Box pt={2} mr={1} className={classes.download}>
                                <Button
                                    id="btn-submit"
                                    variant="outlined"
                                    color="primary"
                                    endIcon={<GetAppOutlinedIcon />}
                                >
                                    Download CSV
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {/* Grid CONTAINER*/}
                <Grid container spacing={2} alignContent="flex-end">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-label="Airport Locations table"
                                size="small"
                                id="table"
                            >
                                {/* START - Blank header*/}
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Type"
                                                    options={LocationType}
                                                    getOptionLabel={(option) => option.LocationType}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Type"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <TextField
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "100px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.headersWithIcon}
                                        // onClick={() => handleSortIconClick(VendorSortType.ByName)}
                                        >
                                            <TextField
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "200px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <ArrowDownwardOutlinedIcon style={{ color: "#9E9DA0", }} />
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "100px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "140px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Status"
                                                    style={{ minWidth: "130px" }}
                                                    options={Status}
                                                    getOptionLabel={(option) => option.Status}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon style={{ color: "#9E9DA0", }} />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell colSpan={2} align="center">Edit/Expand</TableCell>
                                    </TableRow>
                                </TableHead>
                                {/* AIRPORT header*/}
                                {displayAirport && <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Type"
                                                    options={LocationType}
                                                    getOptionLabel={(option) => option.LocationType}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Type"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <TextField
                                                label="Code"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "100px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.headersWithIcon}
                                        // onClick={() => handleSortIconClick(VendorSortType.ByName)}
                                        >
                                            <TextField
                                                label="Airport name"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "200px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <ArrowDownwardOutlinedIcon style={{ color: "#9E9DA0", }} />
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="City"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "100px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="State/Province"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "140px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Status"
                                                    style={{ minWidth: "130px" }}
                                                    options={Status}
                                                    getOptionLabel={(option) => option.Status}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Status"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon style={{ color: "#9E9DA0", }} />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell colSpan={2} align="center">Edit/Expand</TableCell>
                                    </TableRow>
                                </TableHead>}
                                {/* CAR header */}
                                {displayCar && <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Code"
                                                    options={LocationType}
                                                    getOptionLabel={(option) => option.LocationType}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Type"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <TextField
                                                label="Code"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "100px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.headersWithIcon}
                                        // onClick={() => handleSortIconClick(VendorSortType.ByName)}
                                        >
                                            <TextField
                                                label="Car rental name"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "200px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <ArrowDownwardOutlinedIcon style={{ color: "#9E9DA0", }} />
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Street address"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "200px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="City"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "100px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Status"
                                                    style={{ minWidth: "130px" }}
                                                    options={Status}
                                                    getOptionLabel={(option) => option.Status}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Status"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon style={{ color: "#9E9DA0", }} />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell colSpan={2} align="center">Edit/Expand</TableCell>
                                    </TableRow>
                                </TableHead>}
                                {/* HOTEL header */}
                                {displayHotel && <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Code"
                                                    options={LocationType}
                                                    getOptionLabel={(option) => option.LocationType}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Type"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <TextField
                                                label="Code"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "120px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.headersWithIcon}
                                        // onClick={() => handleSortIconClick(VendorSortType.ByName)}
                                        >
                                            <TextField
                                                label="Hotel name"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "200px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <ArrowDownwardOutlinedIcon style={{ color: "#9E9DA0", }} />
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Phone"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "130px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Hotel chain"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "140px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="City"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "140px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Status"
                                                    style={{ minWidth: "130px" }}
                                                    options={Status}
                                                    getOptionLabel={(option) => option.Status}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Status"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon style={{ color: "#9E9DA0", }} />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell colSpan={2} align="center">Edit/Expand</TableCell>
                                    </TableRow>
                                </TableHead>}
                                {/* RAIL header */}
                                {displayRail && <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Code"
                                                    options={LocationType}
                                                    getOptionLabel={(option) => option.LocationType}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Type"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <TextField
                                                label="Code"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "100px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.headersWithIcon}
                                        // onClick={() => handleSortIconClick(VendorSortType.ByName)}
                                        >
                                            <TextField
                                                label="Rail name"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "200px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <ArrowDownwardOutlinedIcon style={{ color: "#9E9DA0", }} />
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="City"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "140px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="State/Province"
                                                variant="filled"
                                                fullWidth
                                                margin="dense"
                                                style={{ minWidth: "140px" }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <FilterIcon color="#9E9DA0" width="24px" height="24px" />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <Autocomplete
                                                    id="Status"
                                                    style={{ minWidth: "100px" }}
                                                    options={Status}
                                                    getOptionLabel={(option) => option.Status}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Status"
                                                        variant="filled"
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            autoComplete: 'off',
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                    popupIcon={<ArrowDropDownIcon style={{ color: "#9E9DA0", }} />}
                                                />
                                            </form>
                                        </TableCell>
                                        <TableCell colSpan={2} align="center">Edit/Expand</TableCell>
                                    </TableRow>
                                </TableHead>}
                                {/*  AIRPORT body */}
                                <TableBody id="tableBody">
                                    {displayAirport &&
                                        <>
                                            <AirportLocationsDataRow
                                                type="Aiport"
                                                code="UAM"
                                                extraCodes={true}
                                                AirportName="Andersen Air Force Base"
                                                city="Yigo"
                                                isActive={true}
                                                streetAddress1="96929, Andersen Air Force Base"
                                                streetAddress2="--"
                                                stateProvince="Guam"
                                                country="Guam"
                                                postalCode="908090"
                                                phone="8909097878"
                                                fax="--"
                                                timeZone="Chamorro Standard Time"
                                                longitude="17.13417744980014"
                                                latitude="144.40084086869211"
                                            />
                                            <AirportLocationsDataRow
                                                type="Aiport"
                                                code="CAN"
                                                extraCodes={false}
                                                AirportName="Guangzhou Baiyun International Airport"
                                                city="Guangzhou"
                                                stateProvince="Guangdong"
                                                country="China"
                                                postalCode="709020"
                                                isActive={true}
                                                streetAddress1="Baiyun, Guangzhou"
                                                streetAddress2="--"
                                                phone="9930403048"
                                                fax="--"
                                                timeZone="China Standard Time"
                                                longitude="19.134"
                                                latitude="444.868694211"
                                            />
                                            <AirportLocationsDataRow
                                                type="Aiport"
                                                code="LHR"
                                                extraCodes={false}
                                                AirportName="Heathrow Airport"
                                                city="London"
                                                stateProvince="UK"
                                                country="England"
                                                postalCode="03772"
                                                isActive={true}
                                                streetAddress1="Longford TW6, United Kingdom"
                                                streetAddress2="--"
                                                phone="448443351801"
                                                fax="--"
                                                timeZone="GMT+1"
                                                longitude="57.1341774"
                                                latitude="54.40084086"
                                            />
                                        </>}
                                    {displayCar &&
                                        <>
                                            <CarRentalLocationsDataRow
                                                code="AL 102"
                                                extraCodes={false}
                                                carRentalName="Alamo Rent A Car"
                                                carVendor="Alamo"
                                                city="Orange"
                                                stateProvince="CA"
                                                isActive={true}
                                                country="United States of America"
                                                streetAddress1="711 W Katella Ave"
                                                streetAddress2="--"
                                                postalCode="92802"
                                                latitude="33.80388933382111"
                                                longitude="-117.91726118341508"
                                                timeZone="Pacific Standard Time"
                                                phone="18888266893"
                                                fax="--"
                                            />
                                            <CarRentalLocationsDataRow
                                                code="AV 203"
                                                extraCodes={false}
                                                carRentalName="Avis Car Rental"
                                                carVendor="Avis"
                                                city="Garden Grove"
                                                stateProvince="CA"
                                                isActive={false}
                                                country="United States of America"
                                                streetAddress1="12015 Harbor Blvd"
                                                streetAddress2="--"
                                                postalCode="92840"
                                                latitude="33.788696337871"
                                                longitude="-117.91638210085009"
                                                timeZone="Pacific Standard Time"
                                                phone="+16573331751"
                                                fax="--"
                                            />
                                            <CarRentalLocationsDataRow
                                                code="EP 404"
                                                extraCodes={false}
                                                carRentalName="Enterprise-Rent-A-Car"
                                                carVendor="Enterprise"
                                                city="Santa Ana"
                                                stateProvince="CA"
                                                isActive={false}
                                                country="United States of America"
                                                streetAddress1="1714 E McFadden Ave L"
                                                streetAddress2="--"
                                                postalCode="92705"
                                                latitude="33.741904432484354"
                                                longitude="-117.82613322466273"
                                                timeZone="Pacific Standard Time"
                                                phone="+17145414862"
                                                fax="--"
                                            />
                                        </>}
                                    {displayHotel &&
                                        <>
                                            <HotelLocationsDataRow
                                                code="CN LGB552"
                                                extraCodes={true}
                                                hotelName="Conrad Hotels"
                                                hotelChain="Hilton"
                                                city="Long Beach"
                                                stateProvince="CA"
                                                isActive={true}
                                                country="United States"
                                                postalCode="90831"
                                                streetAddress1="701 W Ocean Blvd"
                                                streetAddress2="--"
                                                phone="5629833400"
                                                fax="--"
                                                timeZone="Pacific Standard Time"
                                                latitude="33.77106688229699"
                                                longitude="-118.20103763681684"
                                            />
                                            <HotelLocationsDataRow
                                                code="HL A90934"
                                                extraCodes={false}
                                                hotelName="Hilton"
                                                hotelChain="Hilton"
                                                city="Anaheim"
                                                stateProvince="CA"
                                                isActive={true}
                                                country="United States"
                                                postalCode="92804"
                                                streetAddress1="293 Dinseyland Dr."
                                                streetAddress2="--"
                                                phone="8096749889"
                                                fax="--"
                                                timeZone="Pacific Standard Time"
                                                latitude="33.842635448770714"
                                                longitude="-118.25803215954672"
                                            />
                                            <HotelLocationsDataRow
                                                code="DT CA3940"
                                                extraCodes={false}
                                                hotelName="Double Tree"
                                                hotelChain="Hilton"
                                                city="Carson"
                                                stateProvince="CA"
                                                isActive={false}
                                                country="United States"
                                                postalCode="90745"
                                                streetAddress1="2 Civic Plaza Dr"
                                                streetAddress2="--"
                                                timeZone="Pacific Standard Time"
                                                phone="13108309200"
                                                fax="--"
                                                latitude="33.842635448770714"
                                                longitude="-118.25803215954672"
                                            />
                                        </>}
                                    {displayRail &&
                                        <>
                                            <RailLocationsDataRow
                                                isActive={true}
                                                code="ASD"
                                                extraCodes={false}
                                                railName="Amsterdam Centraal Station"
                                                city="Amsterdam"
                                                streetAddress1="Stationsplein, 1012"
                                                streetAddress2="--"
                                                stateProvince="Amsterdam"
                                                country="Netherlands"
                                                postalCode="1102"
                                                phone="+31302357822"
                                                fax="--"
                                                latitude="52.37934389750179"
                                                longitude="4.900266227465782"
                                                timeZone="Central European Time"
                                            />
                                            <RailLocationsDataRow
                                                isActive={true}
                                                code="ZYR"
                                                extraCodes={false}
                                                railName="Brussels Midi Railway Station"
                                                city="Brussels"
                                                streetAddress1="1060 Bruxelles"
                                                streetAddress2="--"
                                                stateProvince="Amsterdam"
                                                country="Netherlands"
                                                postalCode="1102"
                                                phone="+3225282828"
                                                fax="--"
                                                latitude="50.8361"
                                                longitude="4.3355"
                                                timeZone="--"
                                            />
                                            <RailLocationsDataRow
                                                isActive={true}
                                                code="GCJ"
                                                extraCodes={false}
                                                railName="Grand Central Terminal"
                                                city="New York"
                                                streetAddress1="89 E 42nd S"
                                                streetAddress2="--"
                                                stateProvince="New York"
                                                country="United States of America"
                                                postalCode="10017"
                                                phone="2123402583"
                                                fax="--"
                                                latitude="40.75299438487184"
                                                longitude="-73.97737960577912"
                                                timeZone="Eastern Standard Time"
                                            />
                                        </>}
                                </TableBody>
                                {/*  Footer */}
                                <TableFooter>
                                    <TableRow>
                                        {/* TODO: Pagination */}
                                        {/* <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        colSpan={12}
                                        count={locationsList.totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={pageNumber}
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={tablePagination}
                                        /> */}
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

// Please remove this once hooked up
const Status = [
    { Status: 'Active' },
    { Status: 'Inactive' },
]
const LocationType = [
    { LocationType: 'Airport' },
    { LocationType: 'Car' },
    { LocationType: 'Hotel' },
    { LocationType: 'Rail' },
]


import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Button, IconButton, Link, Snackbar, TextField, Typography } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useOktaAuth } from '@okta/okta-react';
import { Control, Controller, DeepMap, FieldErrors, useForm } from 'react-hook-form';

import Spinner from '../../shared/Spinner'
import { useCustomSnackbar } from '../../shared/customHooks/useCustomSnackbar';
import { MobilePhoneVerificationRQ } from '@cbtravel/common/lib/shared/messages/general/requests/custom/mobile-phone-verification-rq';
import { MobilePhoneVerificationRS } from '@cbtravel/common/lib/shared/messages/general/responses/custom/mobile-phone-verification-rs';
import { MobilePhoneVerificationController } from '@cbtravel/common/lib/shared/api/general/controllers/mobile-phone-verification-controller';
import { UserChangePasswordRQ } from "@cbtravel/common/lib/shared/messages/general/requests/user-change-password-rq";
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { UserController } from "@cbtravel/common/lib/shared/api/general/controllers/user-controller";
import CloseIcon from "../CloseIcon";
import { PhoneInput } from "../Input/PhoneInput";
import { RegexUtils } from "@cbtravel/common/lib/shared/common/regex-utils";
import { phoneUtil } from "../../../util/phone-util";
import { UserSendMobileTokenRQ } from "@cbtravel/common/lib/shared/messages/general/requests/custom/user-send-mobile-token-rq";
import { ChangePhoneDialog } from "./ChangePhoneDialog";
import { ProfileController } from "@cbtravel/common/lib/shared/api/profiles/profile-controller";
import { VerificationStatus } from "@cbtravel/common/lib/web/common/enumerations/verification-status";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        textAlign: "center",
        padding: 10,
        width: 350,
      },
      "& .MuiDialogTitle-root": {
        marginTop: 6,
        padding: "16px 24px 0",
        fontSize: 24,
      },
      "& .MuiDialogContent-root": {
        padding: "0 24px",
      },
      "& .MuiTypography-body1": {
        lineHeight: 1.6,
        fontSize: "13px",
        color: "#4D4D4D",
      },
      "& .MuiTypography-h6": {
        fontWeight: 600,
      },
    },
    strong: { fontWeight: 600 },
    iconApprove: {
      margin: "8px auto 14px",
      color: "#417505",
      width: "44px",
      height: "44px",
      "& .MuiSvgIcon-root": {
        color: "#fff",
        marginTop: "10px",
      },
      "& svg": {
        fontSize: "30px",
      },
    },
    btnCancel: {
      color: "#00467E",
      marginLeft: "0 !important",
      marginTop: 6,
      fontSize: "13px",
    },
    resendCode: {
      fontSize: 12,
      color: "#808080",
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogPadding: {
      "& .MuiDialogContent-root:first-child": {
        paddingTop: 0,
      },
      "& .MuiTypography-h1": {
        lineHeight: '1.3',
      },
      "& .MuiTypography-body2": {
        lineHeight: '1.5',
      },
    },
  })
);

/**
 * Properties for the `VerificationDialog` component.
 */
interface VerificationDialogProps {
  /** Whether the dialog should be displayed or not. */
  openActionDialog: boolean;
  /** React state setter for open status. */
  setOpenActionDialog: Dispatch<SetStateAction<boolean>>;
  /** Text to display on the dialog's button. */
  positiveText: string;
  /** Optional additional action to perform after successful verification. */
  successAction?: () => void;
  /** Request with the data to send to the API. */
  request?: MobilePhoneVerificationRQ | UserChangePasswordRQ;
  /** set state of Verify Token used in /enroll process */
  setVerifyToken?: React.Dispatch<React.SetStateAction<MobilePhoneVerificationRS>>;
}

/**
 * Form definition so we can validate the phone the user enters
 */
type VerificationInputs = {
  phone: string
}

/**
 * This component is used for mobile phone number verification.
 * 
 * @param props {@link VerificationDialogProps Properties} for the `VerificationDialog` component.
 * @returns A JSX element used for displaying the mobile phone verification dialog.
 */
export default function VerificationDialog(props: VerificationDialogProps) {
  const classes = useStyles();
  const { authService, oktaAuth } = useOktaAuth();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const snackbar = useCustomSnackbar();
  const [openPhoneDialog, setOpenPhoneDialog] = useState<boolean>(false);

  const {
    openActionDialog,
    setOpenActionDialog,
    positiveText,
    request,
    successAction
  } = props;

  const {
    formState: { errors },
    control,
    getValues
  } = useForm<VerificationInputs>(
    {
      mode: "onBlur",
      defaultValues: {
        phone: ""
      }
    });

  /**
   * Adds a pause in functionality.
   * 
   * @param milliseconds Number of milliseconds to wait.
   * @returns A Promise that will resolve after `milliseconds` have passed.
   */
  function sleep(milliseconds: any) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  /**
   * Sends request for sending a mobile verification code based on the type
   * of request that was passed to the dialog.
   */
  async function sendMobileVerificationCode() {
    try {
      setShowSpinner(true);

      if (request instanceof MobilePhoneVerificationRQ) {
        await MobilePhoneVerificationController.SendToken(request);
      } else if (request instanceof UserChangePasswordRQ) {
        const userSendVerificationTextRQ: UserSendMobileTokenRQ = new UserSendMobileTokenRQ();
        userSendVerificationTextRQ.userToken = request.userToken;

        // attach the mobile phone number so we can send a verification text to it
        const phone = getValues("phone");

        // only set the value on the request if it's filled out
        if (phone) {
          userSendVerificationTextRQ.phone = phone;
          // update the request phone number so we can show the new phone number
          request.mobilePhoneNumber = phone;
        }

        await UserController.SendVerificationTextByUsername(request.emailAddress, userSendVerificationTextRQ);
      } else {
        snackbar.info("Unable to create mobile phone verification request.");
      }
    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setShowSpinner(false);
    }
  }

  /**
   * Sends the code to the API for validation. If valid, performs the success action.
   * If invalid, then display errors.
   */
  async function validateVerificationCode() {
    try {
      setShowSpinner(true);
      let isValid: Boolean = false;
      let reason: string = "The code provided is not valid.";
      let oktaSessionToken: string | undefined = undefined;

      if (request instanceof MobilePhoneVerificationRQ) {
        let verifyToken: MobilePhoneVerificationRS = new MobilePhoneVerificationRS();
        verifyToken.mobileToken = verificationCode;
        verifyToken.clientId = request.clientId;
        verifyToken.accountNumber = request.accountNumber;
        verifyToken.mobilePhoneNumber = request.mobilePhoneNumber;

        if (props.setVerifyToken) {
          props.setVerifyToken(verifyToken);
        }

        let verificationStatus = await MobilePhoneVerificationController.VerifyToken(verifyToken);
        if (verificationStatus === VerificationStatus.Success) {
          isValid = true;
        } else {
          switch (verificationStatus) {
            case VerificationStatus.Missing:
              reason = "A code was not provided.";
              break;
            case VerificationStatus.Expired:
              reason = "The mobile token has expired.";
              break;
          }
        }

        // If the request comes from password change/creation we go through UserController
      } else if (request instanceof UserChangePasswordRQ) {
        let changePasswordRequest: UserChangePasswordRQ = request;
        changePasswordRequest.mobileToken = verificationCode;
        let sessionToken: string;

        sessionToken = await ProfileController.ChangePassword(changePasswordRequest);

        if (sessionToken) {
          isValid = true;
          oktaSessionToken = sessionToken;
        }
      }

      // If code verification succeeded
      if (isValid) {
        snackbar.info("Phone number verified!");
        setOpenActionDialog(false);

        // Redirect for when change password provides session token
        if (oktaSessionToken) {
          snackbar.info("Password successfully changed! You will be logged in momentarily.");
          oktaAuth.token.getWithoutPrompt({
            sessionToken: oktaSessionToken,
            scopes: Configuration.Okta.scopes,
            state: "state",
            nonce: "nonce",
            responseType: Configuration.Okta.responseType
          }).then((res: any) => {
            oktaAuth.tokenManager.setTokens(res.tokens);
          }).catch((err: any) => snackbar.error(err))

          // Perform success action if provided one
          await sleep(5000).then(() => {
            if (successAction) {
              successAction();
            }
          })
        } else {
          // If not a change password flow -> just perform success action
          if (successAction) {
            successAction();
          }
        }
      } else {
        snackbar.info(reason);
      }
    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setShowSpinner(false);
    }
  }

  useEffect(() => {
    // Send mobile verification code when the dialog opens
    if (openActionDialog && request) {
      sendMobileVerificationCode();
    } else {
      // Clear verification code box on close
      setVerificationCode("");
    }

  }, [openActionDialog, request]);

  /**
   * Conditionally renders the phone field
   */
  function handlePhoneChangeClick() {
    if (request instanceof MobilePhoneVerificationRQ)
      setOpenActionDialog(false);
    else if (request instanceof UserChangePasswordRQ)
      setOpenPhoneDialog(true);
  }

  return (
    <React.Fragment>
      {showSpinner && <Spinner />}
      <Dialog
        open={openActionDialog}
        onClose={() => {
        }}
        aria-labelledby="Verification dialog"
        aria-describedby="Verification dialog"
        className={classes.dialog}
      >
        <DialogTitle id="action-dialog">
          <Box pt={4} pb={1}>
            <Typography
              component="p"
              style={{ fontSize: 24, letterSpacing: -0.2 }}
            >
              Verify that it's you
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* The 'x' icon on the modal. */}
          <IconButton id="closeIcon" aria-label="close" className={classes.closeButton} onClick={() => {
            setOpenActionDialog(false);
          }}>
            <CloseIcon />
          </IconButton>
          <DialogContentText id="action-dialog-text">
            <Typography variant="body1">
              {/* Enter the verification code sent to your mobile phone that you
                provided during enrollment */}
              We texted a verification code to (***) ***-{request?.mobilePhoneNumber.slice(-4)}
            </Typography>
            <Box pt={1.5}>
              <Typography>
                Please enter the code here:
              </Typography>
            </Box>
            <Box mt={3}>
              {/* // Verification input 1: */}
              <TextField
                id="verification-code"
                label="Verification code"
                fullWidth
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 500 }}
                onChange={(event) => setVerificationCode(event.target.value)}
                value={verificationCode}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && verificationCode.length >= 6) {
                    validateVerificationCode();
                  }
                }}
              />
            </Box>
            <Box mt={4}>
              <Button
                onClick={validateVerificationCode}
                variant="contained"
                color="primary"
                fullWidth
                disabled={verificationCode.length < 6}
              >
                {positiveText}
              </Button>
              <Box mb={3.5}>
                <Typography className={classes.resendCode}>
                  <Box>It may take a little bit to receive your code.</Box>
                  <Box>
                    Haven’t received it yet?{" "}
                    <Link
                      onClick={sendMobileVerificationCode}
                      color="primary"
                      style={{ cursor: "pointer" }}
                    >
                      Resend a new code.
                    </Link>
                  </Box>
                  {request?.mobilePhoneNumber && request.mobilePhoneNumber.length > 4 && <Box>
                    <Box>
                      Not your number?
                    </Box>
                    <Link
                      onClick={handlePhoneChangeClick}
                      style={{ cursor: "pointer" }}
                    >
                      Edit your number here.
                    </Link>
                  </Box>}
                </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <ChangePhoneDialog
        openDialog={openPhoneDialog}
        setOpenDialog={setOpenPhoneDialog}
        control={control}
        errors={errors}
        sendMobilePhoneVerification={sendMobileVerificationCode}
      />


      {/* Cancel Verification */}
      <Dialog
        maxWidth='xs'
        aria-labelledby="form-dialog-title" open={false}>
        <Box className={classes.dialogPadding} p={2} display="flex" flexDirection="column" justifyContent="center">
          <DialogContent>
            {/* The 'x' icon on the modal. */}
            <IconButton id="closeIcon" aria-label="close" className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
            <DialogContentText>
              <Box mt={6} mb={6}>
                <Typography align="center" variant="h1">Cancel verification?</Typography>
                <Box mt={2} mb={1}>
                  <Typography align="center" variant="body2">
                    If you close this screen before verifying your phone number, you won't be able to continue with your enrollment request.
                  </Typography>
                </Box>
              </Box>
            </DialogContentText>
            <Box>
              <Button fullWidth variant="contained" color="primary">
                Continue verification?
              </Button>
            </Box>
            <Box my={1}>
              <Button fullWidth variant="outlined" color="primary">
                Cancel Verification
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>

    </React.Fragment>
  );
}


import React, { useState } from "react";
import HistoryLevel from './HistoryLevel'
import { ApprovalRequestController } from '@cbtravel/common/lib/shared/api/approval/controllers/approval-request-controller'
import { EntityDepth } from '@cbtravel/common/lib/shared/common/enumerations/entity-depth'
import { JsonException } from '@cbtravel/common/lib/shared/common/exceptions/json-exception'
import Spinner from '../../../components/shared/Spinner'
import { ApprovalStatus } from '@cbtravel/common/lib/shared/common/enumerations/approval-status'
import moment from "moment"
import { dateUtil } from "../../../util/date-util"
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {
  CheckCircleOutlineRounded as Sent,
  Close as CloseIcon,
  EventAvailableRounded as RequiredApproverIcon,
  HighlightOffRounded as NotSent,
} from "@material-ui/icons";

import Typography from "@material-ui/core/Typography";
import HistoryRoundedIcon from "@material-ui/icons/HistoryRounded";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { ApprovalRequestRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-request-rs";
import { ApprovalRequestApprovalRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-request-approval-rs";
import { ApprovalRecipientType } from "@cbtravel/common/lib/shared/common/enumerations/approval-recipient-type";
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: "0 16px 16px",
    marginTop: "-16px",
    "& .MuiTypography-h3": {
      fontSize: ".9rem",
      fontWeight: 700,
      margin: "32px 0 12px 8px",
    },
  },
  table: {
    "& .MuiTableCell-head": {
      fontWeight: 700,
    },
    "& .MuiTableCell-root": {
      lineHeight: 2,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      top: ".2em",
      position: "relative",
    },
    "& .MuiButton-outlinedSizeSmall": {
      padding: "1px 0 3px",
      fontSize: "0.75rem",
      fontWeight: 700,
      margin: "5px 0",
    },
  },
  statusCell: {
    lineHeight: 2,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  approved: {
    color: "#417505",
  },
  pending: {
    color: "#ff8401",
  },
  denied: {
    color: "#bc2c2f",
  },
  required: {
    color: "#00467E",
    fontWeight: 700,
  },
  requiredLegend: {
    color: "#00467E",
    paddingLeft: "16px",
    fontSize: ".7rem",
    fontWeight: 700,
    marginTop: "4px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

/**
 * Component for displaying a title and close button in the dialog.
 * 
 * @param props {@link DialogTitleProps Properties} for the `DialogTitle` component.
 * @returns JSX Element used for displaying a title and close button in the dialog.
 */
export function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          id="btn-close"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

interface HistoryDialogProps {
  token: string,
  approvalReqId: number,
  prevStatus: ApprovalStatus,
  timeZone: string,
  updateButtonsAndApprover: (req: ApprovalRequestRS) => void,
  getApprovalRequests: () => void,
}

export default function HistoryDialog(props: HistoryDialogProps) {
  const classes = useStyles();
  const snackbar = useCustomSnackbar();

  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<ApprovalRequestRS>()
  const [spinner, setSpinner] = useState<boolean>(false)
  const [levelArrays, setLevelArrays] = useState<Array<ApprovalRequestApprovalRS[]>>(); // inner arrays are sorted, ordered approval levels. each gets mapped into a <HistoryLevel/>

  /**
   * Handles opening the history dialog and getting the approval request information.
   */
  async function handleClickOpen() {
    setSpinner(true)
    try {
      //get the most up-to-date version of this request to use
      const currentRequest = await ApprovalRequestController.Get(props.token, props.approvalReqId, EntityDepth.Deep)
      setRequest(currentRequest)
      createLevelArrays(currentRequest.approvalRequestApprovalList) //levelArrays state object set here

      //check if we should still display accept/deny buttons (e.g. if someone else on user's approval level has already approved)
      props.updateButtonsAndApprover(currentRequest)

      setOpen(true);
    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setSpinner(false)
    }

  };

  /**
   * Handles closing the approval history dialog.
   */
  function handleClose() {
    //compare the version of this request in this dialog, to the one currently displayed on the user's screen
    if (request?.approvalStatus !== props.prevStatus) {
      //if the overall status has changed since the last render, tell it to get up to date
      props.getApprovalRequests();
    }
    setOpen(false);
  };

  /**
   * takes the request.approvalRequestApprovalList and repackages it into an array containing separate child arrays of each approval level
   * sets the levelArrays state object with this new array
   * @param approvalList - the approvalRequestApprovalList from the Approval Request we're looking at
   */
  function createLevelArrays(approvalList: ApprovalRequestApprovalRS[]) {
    let levelArray: number[] = [];
    const levelsToMap: Array<ApprovalRequestApprovalRS[]> = [];

    //find how many levels this approval has. store in levelArray
    approvalList.forEach(list => {
      if (!levelArray.includes(list.level)) {
        levelArray.push(list.level)
      }
    })
    //sort in ascending numerical order
    levelArray.sort((a, b) => a - b)

    //create new arrays for each level that only include relevant entries, orders them, then pushes each finished array to levelsToMap array
    levelArray.forEach(level => {
      const levelArr = approvalList.filter(ap => ap.level === level)
      const sortedArr = levelArr.sort((a, b) => a.order - b.order)
      return levelsToMap.push(sortedArr)
    })

    setLevelArrays(levelsToMap);
  }

  /**
   * looks at the most recent approval request and, if email reminders are enabled, calculates when the next one will send and translates that to an actual date
   * @returns a string representation of a date/time or null if reminders are not enabled
   */
  function calculateNextReminderDate() {
    if (request && request.reminderEnabled) {
      const creation = moment(request.dateCreated); // this is already coming from the server in UTC and does not need to be translated
      let now = moment();
      let utcOffset = moment().utcOffset();
      // utcOffset is a number of minutes representing the difference between our local timezone and UTC
      // it will be positive in timezones ahead of UTC and negative in timezones behind UTC, so we'll translate "now" accordingly
      now.subtract(utcOffset, 'minutes');

      const startMin = request.reminderStartMin;
      let freqMin = request.reminderFrequencyMin;

      let nextReminder = creation.add(startMin, 'minutes');

      if (nextReminder.isSameOrAfter(now)) {
        return dateUtil.formatDate(new Date((nextReminder.add(utcOffset, 'minutes').toDate())), props.timeZone);
      } else {
        while (nextReminder.isBefore(now)) {
          nextReminder = nextReminder.add(freqMin, 'minutes')
        }
        return dateUtil.formatDate(new Date((nextReminder.add(utcOffset, 'minutes').toDate())), props.timeZone);
      }
    } else {
      return null;
    }
  }

  return (
    <div>
      {spinner && <Spinner />}
      <IconButton aria-label="History" onClick={handleClickOpen}>
        <HistoryRoundedIcon />
      </IconButton>
      <Dialog
        id="dialog-close"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="dialog-title" onClose={handleClose}>
          Approval History
        </DialogTitle>
        <TableContainer className={classes.tableContainer}>
          {levelArrays && levelArrays.map((arr, i) => <HistoryLevel
            key={arr[0].approvalRequestId + arr[0].level}
            approvals={arr}
            prevLevelApprovals={i > 0 ? levelArrays[i - 1] : []}
            nextReminder={calculateNextReminderDate()}
            timezone={props.timeZone}
          />)}
          <Typography variant="h3">Confirmation Email</Typography>
          <Table
            aria-label="Confirmation email table"
            size="small"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell width="20%">Name</TableCell>
                <TableCell width="25%">Last Modified</TableCell>
                <TableCell align="center" width="15%">
                  Email sent
                </TableCell>
                <TableCell width="40%"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {request && request.approvalRequestRecipientList.filter(r => r.approvalRecipientType === ApprovalRecipientType.Confirmation).map((recipient) => (
                <TableRow key={recipient.user.userId}>
                  <TableCell id={`history-dialog-recipient-${recipient.user.firstName}-${recipient.user.lastName}`} component="th" scope="row">
                    {recipient.user.firstName} {recipient.user.lastName}
                  </TableCell>

                  <TableCell>{dateUtil.formatDate(new Date(recipient.dateModified + "+00:00"), props.timeZone)}</TableCell>
                  <TableCell align="center">
                    {recipient.isSent ? (
                      <Sent className={classes.approved} />
                    ) : (
                      <NotSent className={classes.denied} />
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </div>
  );
}

import { useState, useEffect, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Link, MenuItem, Divider, TextField, Typography } from "@material-ui/core";
import { Control, Controller, DeepMap, FieldErrors, UseFieldArrayReturn, UseFormRegister, UseFormGetValues } from "react-hook-form";
import { AddCircleOutline as AddIcon, RemoveCircleOutline as RemoveIcon } from "@material-ui/icons";

import { PhoneType } from "@cbtravel/common/lib/shared/common/enumerations/phone-type";
import ActionDialog from "../../../../components/ui/Dialog/ActionDialog";
import { PhoneFields, ProfileFormInputs, formRules, formRulesBasic } from "../Profile";
import { PhoneInput } from "../../../../components/ui/Input/PhoneInput";
import { UserContext } from "../../../../components/shared/UserContext";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        inputWrapper: {
            "& .MuiDivider-root": {
                marginBottom: 24,
            },
        },
        h3: {
            fontSize: 12,
            fontWeight: 300,
            textTransform: "uppercase",
            paddingTop: 10,
            marginBottom: -4,
            display: "block",
        },
        accordion: {
            "& .MuiFormHelperText-root": {
                fontSize: 11,
                color: "#808080",
            },
            "& .MuiInputBase-input": {
                background: "#fff",
            },
            "& .MuiInputBase-root": {
                background: "#fff",
            },
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: 0
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
            },
        },
        addIcon: {
            color: "#00467E",
            marginTop: -4,
        },
        removeIcon: {
            color: "#BC2C2F",
        },
        addText: {
            "& .MuiLink-button": {
                fontSize: 12,
                fontFamily: "Open Sans, Arial, sans-serif",
                fontWeight: 600,
                color: "#00467E",
                marginTop: 0,
                textDecoration: "none",
            },
            "& .MuiSvgIcon-root": {
                marginBottom: -1.5,
                marginRight: -1,
                fontSize: 13,
            },
        },
        removeWrapper: {
            marginTop: -36,
            marginBottom: -36,
            "& .MuiLink-button": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "Open Sans, Arial, sans-serif",
                color: "#BC2C2F",
                textDecoration: "none",
            },
        },
        removeText: {
            "& .MuiSvgIcon-root": {
                marginRight: -1,
                marginBottom: -1.5,
                fontSize: "12px !important",
            },
        },
        whiteBG: {
            background: "#fff",
        },
        flex: {
            display: "flex",
            flexDirection: "column",
            marginLeft: 3,
        },
        h2: {
            fontSize: 13,
            fontWeight: 600,
            lineHeight: 1.5,
        },
        summary: {
            fontSize: 12,
        },
    })
);

interface PhoneFieldsProps {
    field: PhoneFields & { id: string },
    index: number,
    control: Control<ProfileFormInputs>,
    controlPhones: Array<PhoneFields & { id: string }>,
    phoneFieldArray: UseFieldArrayReturn<ProfileFormInputs, "phoneNumbers", "id">,
    errors: DeepMap<ProfileFormInputs, FieldErrors>,
    phoneTypes: Array<string>,
    updatePhoneTypes: () => void,
    getValues: UseFormGetValues<ProfileFormInputs>,
    viewingOtherProfile: boolean

}

export default function PhoneFormFields(props: PhoneFieldsProps) {
    const classes = useStyles();
    const { field, index, control, controlPhones, phoneFieldArray } = props;
    const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const { userContext } = useContext(UserContext);

    useEffect(() => {
        //on mount, check if this is the required Mobile Phone field
        setIsMobile(props.getValues(`phoneNumbers.${index}.phoneType` as const) === PhoneType.Mobile);
    }, [])

    return (
        <Grid container spacing={3} key={field.id}>
            {/* Display dialog to remove item */}
            <ActionDialog
                id={`dialog-discard-${index}`}
                title={`Remove ${field.phoneType} phone?`}
                icon={<div className={useStyles().removeIcon}><RemoveIcon /></div>}
                positiveText="Remove"
                negativeText="Cancel"
                dialogType="delete"
                open={showRemoveDialog}
                setOpen={setShowRemoveDialog}
                positiveAction={() => {
                    phoneFieldArray.remove(index)
                    props.updatePhoneTypes();
                }}
            >
                <p>You will be permanently removing this phone from your profile.</p>
            </ActionDialog>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`phoneNumbers.${index}.phoneType` as const}
                        control={props.control}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <TextField
                                id={`ff-phone-type-${index}`}
                                select
                                disabled={isMobile}
                                defaultValue={"Mobile"}
                                label="Phone type"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={(e) => { onChange(e); props.updatePhoneTypes(); }}
                                value={value}
                                ref={ref}
                                onBlur={onBlur}
                                InputProps={{ autoComplete: "off" }}
                            >
                                {props.phoneTypes?.map((t) => (
                                    <MenuItem key={t} value={t}>
                                        {t}
                                    </MenuItem>
                                ))};
                            </TextField>
                        )} />
                </form>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`phoneNumbers.${index}.phoneNumber` as const}
                        control={control}
                        /* if it's mobile, we want to check if we're viewing another profile and the user type to determine validation */
                        rules={isMobile ? (props.viewingOtherProfile && userContext.userType < UserType.TravelManager) ? formRulesBasic.phoneNumbersMobile : formRules.phoneNumbersMobile : formRules.phoneNumbers}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <PhoneInput
                                id={`phone-number-${index}`}
                                label="Phone number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultCountry="US"
                                international={false}
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                error={props.errors.phoneNumbers?.[index]?.phoneNumber ? true : false}
                                InputProps={{ autoComplete: "off" }}
                            />
                        )} />
                </form>
                {!(props.viewingOtherProfile && userContext.userType < UserType.TravelManager) && isMobile &&
                    <Typography variant='body2' style={{ color: 'gray', margin: '5px 0px 0px 45px' }} >
                        Updating your mobile phone will require verification
                    </Typography>
                }
                <Typography variant='body2' style={{margin: '5px 0px 0px 45px'}} color='error'>
                    {props.errors?.phoneNumbers && props?.errors?.phoneNumbers?.[index]?.phoneNumber?.message}
                </Typography>
            </Grid>

            {/* Don't allow removal of final element */}
            {controlPhones.length > 1 && !isMobile &&
                <Grid
                    container
                    justify="flex-end"
                    className={classes.removeWrapper}
                >
                    <Box component={"div"} className={classes.removeText}>
                        <Typography align="right">
                            <Link href="#" component="button" color="error" id={`link-removeDialog-${index}`}
                                onClick={() => {
                                    setShowRemoveDialog(true);
                                }}>
                                <RemoveIcon className={classes.removeIcon} /> Remove
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
            }
            {controlPhones.length > 1 &&
                index != controlPhones.length - 1 &&
                <Grid className={classes.inputWrapper} item xs={12} sm={12} md={12} lg={12} >
                    <Divider />
                </Grid>
            }
        </Grid>
    )
}

import React, { Dispatch, SetStateAction, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        textAlign: "center",
        width: 400,
      },
      "& .MuiDialogTitle-root": {
        padding: "16px 24px 0",
      },
      "& .MuiDialogContent-root": {
        padding: "0 24px",
      },
      "& .MuiTypography-body1": {
        lineHeight: 1.6,
        fontSize: "13px",
        color: "#4D4D4D",
      },
      "& .MuiTypography-h6": {
        fontWeight: 600,
      },
    },
    strong: { fontWeight: 600 },
    iconNonTransferable: {
      margin: "8px auto 14px",
      background: "#bc2c2f",
      borderRadius: 40,
      width: "44px",
      height: "44px",
      "& .MuiSvgIcon-root": {
        color: "#fff",
        marginTop: "10px",
        fontSize: "1.5rem",
      },
      "& svg": {
        color: "#fff",
        marginTop: "11px",
      },
    },
    iconTransferable: {
      margin: "8px auto 14px",
      background: "#417505",
      borderRadius: 40,
      width: "44px",
      height: "44px",
      "& .MuiSvgIcon-root": {
        color: "#fff",
        marginTop: "10px",
        fontSize: "1.5rem",
      },
      "& svg": {
        color: "#fff",
        marginTop: "11px",
      },
    },
    dialogActions: {
      padding: "16px 24px",
      "& .MuiButton-contained.btnTransferable": {
        backgroundColor: "#417505",
      },
      "& .MuiButton-contained.btnNonTransferable": {
        backgroundColor: "#bc2c2f",
      },
    },
    btnCancel: {
      color: "#00467E",
      marginLeft: "0 !important",
      fontSize: "13px",
    },
    errorText: {
      paddingLeft: "13px",
      paddingTop: "6px",
      textAlign: "left",
      fontSize: "12px",
    }
  })
);

interface TransferableDialogProps {
  /** Whether or not the dialog should be displayed. */
  openActionDialog: boolean;
  /** React state setter for `openActionDialog`. */
  setOpenActionDialog: Dispatch<SetStateAction<boolean>>;
  /** The name of the  */
  action: "transferable" | "non-transferable";
  /** Function to execute when user confirms change to transferable status. */
  onConfirm: (isTransferable: boolean, comment: string) => void;
  /** React state setter for displaying the spinner. */
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  /** The number of tickets being changed during a bulk action. */
  bulkActionCount: number;
}

/**
 * Component for use as a dialog on the UnusedTickets page when changing the transferable
 * state of a ticket.
 * 
 * @param props {@link TransferableDialogProps Properties} for the `TransferableDialog`.
 * @returns A JSX element for displaying a dialog when changing a ticket's transferable status.
 */
export default function TransferableDialog(props: TransferableDialogProps) {
  const classes = useStyles();
  const {
    openActionDialog,
    setOpenActionDialog,
    action,
    onConfirm,
    bulkActionCount,
  } = props;
  const [commentValue, setCommentValue] = useState<string>("");

  const { formState: { errors }, control } = useForm({ mode: "onChange" });
  const formRules = {
    transferableCommentBox: { maxLength: { value: 254, message: 'Max 255 characters' } },
  }

  function handleConfirm() {
    action === "transferable"
      ? onConfirm(true, commentValue)
      : onConfirm(false, commentValue);
    //start main spinner if this is not a bulk request
    bulkActionCount === 0 && props.setShowSpinner(true);
    setOpenActionDialog(false);
    setCommentValue("")
  }

  return (
    <React.Fragment>
      <Dialog
        open={openActionDialog}
        onClose={() => {
          setOpenActionDialog(false);
        }}
        aria-labelledby="Transferable action dialog"
        aria-describedby="Transferable action dialog"
        className={classes.dialog}
      >
        <DialogTitle id="dialog-transferable">
          <Box>
            <div
              className={
                action === "transferable"
                  ? classes.iconTransferable
                  : classes.iconNonTransferable
              }
            >
              <FontAwesomeIcon icon={faExchangeAlt} />
            </div>
          </Box>
          {bulkActionCount > 0
            ? `Set ${bulkActionCount} tickets ${action}?`
            : `Set ${action}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="transferable-dialog-text">
            <Typography variant="body1">
              {/* {bulkActionCount === 0 && <p>Set ticket as ${action}</p>} */}
              <Box mt={4}>
                <Controller
                  name="TransferableCommentBox"
                  control={control}
                  defaultValue=""
                  rules={formRules.transferableCommentBox}
                  render={({ field: { onChange, onBlur } }) => (
                    <TextField
                      id="ff-comment"
                      label="Comment (optional)"
                      multiline
                      fullWidth
                      rows={2}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                      onBlur={onBlur}
                      onChange={(event) => {
                        setCommentValue(event.target.value);
                        onChange(event);
                      }}
                      value={commentValue}
                      error={errors.TransferableCommentBox}
                    />
                  )}
                />
                <Typography variant="body2" color="error" className={classes.errorText}>
                  {errors.TransferableCommentBox && errors.TransferableCommentBox.message}
                </Typography>
              </Box>
            </Typography>
          </DialogContentText>
        </DialogContent>

        <div className={classes.dialogActions}>
          <Button
            id="btn-transferable"
            onClick={handleConfirm}
            variant="contained"
            className={
              action === "transferable"
                ? "btnTransferable"
                : "btnNonTransferable"
            }
            fullWidth
          >
            Set {action}
          </Button>
          <Button
            id="btn-cancel"
            className={classes.btnCancel}
            onClick={() => {
              setOpenActionDialog(false);
            }}
            fullWidth
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from "react";
import { UserContext } from '../UserContext';
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import ClientSelectorList from "./ClientSelectorList";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid,
  TextField, Popover
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popup: {
      "& .MuiPopover-paper": {
        width: "375px",
        maxWidth: "375px",
        maxHeight: "475px",
        minHeight: "150px",
        scrollbarWidth: "thin",
      },
      "& ::-webkit-scrollbar": {
        background: "transparent",
        width: "5px",
      },
      "& ::-webkit-scrollbar-track": {
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        background: "white",
        borderRadius: "10px",
      },
      "& ::-webkit-scrollbar-thumb": {
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        background: "#c9c7c7",
        borderRadius: "10px",
      },
      "& .sticky": {
        "@media screen and (-ms-high-contrast: none)": {
          position: "relative",
        },
        position: "fixed",
        width: "369px",
        background: "#fff",
        zIndex: 1000,
        marginTop: "-16px",
        marginLeft: "-16px",
        borderTopLeftRadius: "4px",
        padding: "22px 16px 16px",
      },
      "& .MuiInputLabel-outlined": {
        paddingTop: "28px",
        paddingLeft: "21px",
      },
      "& .MuiOutlinedInput-adornedStart": {
        paddingLeft: "5px",
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      outline: 0,
    },
    btn: {
      marginLeft: theme.spacing(-1),
      fontSize: "16px",
    },
  })
);

/**
 * Properties for the ClientSelector component.
 */
interface ClientSelectorProps {
  /** Object representing the currently active Client. */
  activeClient: ClientRS | undefined,
  /** React state setter for the currently active Client. */
  setActiveClient: Dispatch<SetStateAction<ClientRS | undefined>>,
  /** Determines whether the ClientSelector should be rendered. */
  display?: boolean
}

/**
 * The ClientSelector component is used to search for and change the active Client being used throughout
 * the web application.
 * 
 * @param props {@link ClientSelectorProps Properties} for the ClientSelector component.
 * @returns A JSX element used to control the active client being used in the web application.
 */
export default function ClientSelector(props: ClientSelectorProps) {
  //* styles
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //* end styles

  const { userContext } = useContext(UserContext);
  const { activeClient, setActiveClient } = props; // used to select and display the active Client; stored in Home.tsx so other components have access
  const [searchText, setSearchText] = useState<string>(""); // the text being typed in the search bar by the user
  const [searchDisplay, setSearchDisplay] = useState<ClientRS[] | null>(null); // the search results being returned as the user types
  const [recentDisplay, setRecentDisplay] = useState<ClientRS[] | null>(null); // the user's recent client list; displayed when the search bar is clicked and not actively searching
  //todo: reconfigure searchDisplay/recentDisplay into one list so that we don't need to use a useEffect in ClientSelectorList.tsx and it can just re-render when props change

  // update the recent clients to include the active client
  useEffect(() => {
    if (activeClient) {
      updateRecentClients(activeClient)
    }
  }, []);

  //search logic
  useEffect(() => {
    const { viewableClients } = userContext;
    let trimmed = searchText.trim();
    if (trimmed) {
      const timeOut = setTimeout(function () {
        let casedText: string = trimmed.toLowerCase();
        let filterResults = viewableClients.filter(function (c: ClientRS) {
          return (
            c.name.toLowerCase().startsWith(casedText) || c.accountNumber.toLowerCase().startsWith(casedText) || c.clientId.toString() === casedText
          )
        });
        setSearchDisplay(filterResults);
      }, 500);

      return () => clearTimeout(timeOut);
    } else {
      setSearchDisplay(null);
    }
  }, [searchText]);

  /**
   * Handles the event when clicking on the ClientSelector component to expand its display.
   * @param event onClick event.
   */
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  /**
   * Handles the closing of the ClientSelector's expanded display.
   */
  function handleClose() {
    setAnchorEl(null);
    setSearchDisplay(null);
    setSearchText("");
  }

  /**
   * adds recently selected clients to the recentDisplay list and maintains list limit of ten
   * @param clientToAdd a clientRS that we've just selected from the client selector
   */
  function updateRecentClients(clientToAdd: ClientRS) {
    let recentClients: ClientRS[] = [];
    if (window.localStorage[`recent-clients-${userContext.userId}`]) {
      recentClients = JSON.parse(String(window.localStorage.getItem(`recent-clients-${userContext.userId}`)));
    }
    if (recentClients && recentClients.length > 0) {
      // recentClients = JSON.parse(String(window.localStorage.getItem(`recent-clients-${userContext.userId}`)));
      let index = recentClients.findIndex(c => c.clientId === clientToAdd.clientId);
      if (index >= 0) {
        // the client was already in recentClients, pull it out so it can be re-added at the start
        recentClients.splice(index, 1);
      }
    }
    //rare circumstances exist where recentClients could have been set to null from localstorage, in that case we want to initialize a new array
    recentClients ? recentClients.unshift(clientToAdd) : recentClients = [clientToAdd];
    if (recentClients.length > 10) {
      //remove any older elements and set length to 10
      recentClients.length = 10;
    }
    window.localStorage.setItem(`recent-clients-${userContext.userId}`, JSON.stringify(recentClients));
    setRecentDisplay(recentClients);
  }

  /**
   * Handles the event when the text in the search box is changed.
   * @param event onChange event from a TextField component.
   */
  function handleSearch(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchText(event?.target.value);
  }

  /**
   * Updates the current active client and client selector states.
   * @param client The Client to set as the active client.
   */
  function handleSelect(client: ClientRS) {
    setActiveClient(client); // set active client for any other components that need this information
    handleClose();
    updateRecentClients(client);
  }

  return !(props.display) ? <></> : (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            aria-controls="client-selector"
            aria-haspopup="true"
            onClick={handleClick}
            disableRipple={true}
            disableFocusRipple={true}
            className={classes.btn}
          >
            <span className="client-active">
              {activeClient && activeClient.name}
            </span>
            <ExpandMore />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            className={classes.popup}
          >
            <form onSubmit={(e) => e.preventDefault()} className={classes.popup}>
              <Box p={2}>
                <TextField
                  id="outlined-helperText"
                  label="Search accounts"
                  autoFocus={false}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchText}
                  onChange={handleSearch}
                  className="sticky"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <Search /> */}
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Box>
            </form>
            <ClientSelectorList
              searchDisplay={searchDisplay}
              onClick={handleSelect}
              recentDisplay={recentDisplay}
            />
          </Popover>
        </Grid>
      </Grid>
    </div>
  );
}
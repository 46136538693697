import React, {useState} from "react";
interface IconProps {
    width: string;
    height: string;
    color: string;
    /** Optional hover color */
    hoverColor?: string;
}


/**
 * The checkmark icon displayed throughout the app. 
 * @param props - Svg props for width, height, and color
 * @returns 
 */
 function CheckIcon(props: IconProps) {
    const [hover, setHover] = useState<boolean>(false); 
    const hoverColor = props.hoverColor === undefined ? props.color : props.hoverColor; //if we have no hoverColor, default to color.
     const icon = (
         <svg
             xmlns="http://www.w3.org/2000/svg"
             width={props.width}
             height={props.height}
             fill="none"
             viewBox="0 0 15 10"
             onMouseEnter={() => { setHover(true) }}
             onMouseLeave={() => { setHover(false) }}
         >
             <path
                 fill={hover ? hoverColor : props.color}
                 d="M14.025 1.051L5.076 10 .975 5.899l1.051-1.052 3.05 3.043L12.973 0l1.052 1.051z"
             ></path>
         </svg>
    );
    return icon;
};


/**
 * The X (close) icon displayed throughout the app. 
 * @param props - Svg props for width, height, and color
 * @returns 
 */
function CloseIcon(props: IconProps) {
  const [hover, setHover] = useState<boolean>(false);
  const hoverColor = props.hoverColor === undefined ? props.color : props.hoverColor; //if we have no hoverColor, default to color.
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        stroke={hover ? hoverColor : props.color}
        strokeWidth="2"
        d="M19.143 1.527l-18 17.98m0-17.98l18 17.98"
      ></path>
    </svg>
  );
  return icon;
};


/**
 * The edit (pencil) icon displayed throughout the app. 
 * @param props - Svg props for width, height, and color
 * @returns 
 */
function EditIcon(props: IconProps) {
    const [hover, setHover] = useState<boolean>(false); 
    const hoverColor = props.hoverColor === undefined ? props.color : props.hoverColor; 
    const icon = (
        <svg
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 22"
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
    >
      <path
        stroke={hover ? hoverColor : props.color}
        strokeWidth="1.5"
        d="M5.75 20.202v-3.609l7.298-7.298 3.657 3.657-7.294 7.294-3.661-.044z"
      ></path>
      <path
        fill={hover ? hoverColor : props.color}
        d="M15.892 5.39a1.334 1.334 0 011.887 0l2.83 2.831a1.334 1.334 0 010 1.887l-1.415 1.415-4.717-4.717 1.415-1.415z"
      ></path>
    </svg>
    );
    return icon;
};

/**
 * The filter icon displayed throughout the app. 
 * @param props - Svg props for width, height, and color
 * @returns 
 */
 function FilterIcon(props: IconProps) {
  const [hover, setHover] = useState<boolean>(false); 
  const hoverColor = props.hoverColor === undefined ? props.color : props.hoverColor; 
   const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_1106_11329"
        // style={{ maskType: "alpha" }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_1106_11329)">
        <path
          fill="#9E9DA0"
          d="M11.374 19.5a.848.848 0 01-.625-.25.848.848 0 01-.25-.625v-5.8l-5.6-7.1c-.183-.267-.208-.538-.075-.813.134-.275.359-.412.675-.412h13c.317 0 .542.137.675.412.134.275.109.546-.075.813l-5.6 7.1v5.8c0 .25-.083.458-.25.625a.848.848 0 01-.625.25h-1.25zM12 12.3L16.95 6h-9.9L12 12.3z"
        ></path>
      </g>
    </svg>
  );
  return icon;
};


/**
 * The view (eye) icon displayed throughout the app. 
 * @param props - Svg props for width, height, and color
 * @returns 
 */
function ViewIcon(props: IconProps) {
    const [hover, setHover] = useState<boolean>(false); 
    const hoverColor = props.hoverColor === undefined ? props.color : props.hoverColor; 
    const icon = (
        <svg
            width={props.width}
            height={props.height}
            color={hover ? hoverColor : props.color}
            fill="none"
            viewBox="0 0 20 14"
        >
            <path
                fill={hover ? hoverColor : props.color}
                d="M10.25 3.982a2.6 2.6 0 110 5.2 2.6 2.6 0 010-5.2zm0-3.9c4.334 0 8.034 2.695 9.534 6.5-1.5 3.805-5.2 6.5-9.534 6.5-4.333 0-8.034-2.695-9.533-6.5 1.5-3.805 5.2-6.5 9.533-6.5zm-7.644 6.5a8.512 8.512 0 0015.288 0 8.512 8.512 0 00-15.288 0z"

            ></path>
        </svg>
    );
    return icon;
};

/**
 * The edit (pencil) icon displayed throughout the app. 
 * @param props - Svg props for width, height, and color
 * @returns 
 */
 function FaxIcon(props: IconProps) {
    const [hover, setHover] = useState<boolean>(false); 
    const hoverColor = props.hoverColor === undefined ? props.color : props.hoverColor; 
     const icon = (
        <svg
        width={props.width}
        height={props.height}
        color={hover ? hoverColor : props.color}
        fill="none"
        viewBox="0 0 20 14"
    >
  <rect
        width="2.55"
        height="7.12"
        x="0.25"
        y="2.18"
        fill="none"
        stroke="#231f20"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        rx="0.48"
      ></rect>
      <path
        fill="none"
        stroke="#231f20"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M8.35 2.93h.77a.47.47 0 01.47.47h0v5.18a.48.48 0 01-.47.48H3.3a.48.48 0 01-.47-.48V3.4a.47.47 0 01.47-.47h.77"
      ></path>
      <path
        fill="none"
        stroke="#231f20"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        d="M4.55.25h3.37a.47.47 0 01.43.51h0v3.29a.48.48 0 01-.43.48H4.55a.47.47 0 01-.48-.48h0V.77a.47.47 0 01.42-.52z"
      ></path>
      <circle cx="4.55" cy="6.16" r="0.35" fill="#231f20"></circle>
      <circle cx="6.33" cy="6.16" r="0.35" fill="#231f20"></circle>
      <circle cx="8.12" cy="6.16" r="0.35" fill="#231f20"></circle>
      <circle cx="4.55" cy="7.38" r="0.35" fill="#231f20"></circle>
      <circle cx="6.33" cy="7.38" r="0.35" fill="#231f20"></circle>
      <circle cx="8.12" cy="7.38" r="0.35" fill="#231f20"></circle>
      </svg>
    );
    return icon;
};


export { CheckIcon, CloseIcon, EditIcon, FaxIcon, FilterIcon, ViewIcon };


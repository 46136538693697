import React, { useState, } from "react";
import { Box, Checkbox, Fab, FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ActionDialog from "../../../components/ui/Dialog/ActionDialog";
import SaveActivateDialog from "./SaveDialog";
import SaveIcon from "@material-ui/icons/Save";
import CloseDialogIcon from '../../../components/ui/CloseIcon';
import { DeepMap, FieldError } from "react-hook-form";
import { CustomFieldFormInputs } from "./CustomFieldForm";

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            textAlign: "center",
            width: 340,
        },
        "& .MuiDialogTitle-root": {
            padding: "16px 24px 0",
        },
        "& .MuiDialogContent-root": {
            padding: "0 24px",
            lineHeight: 1.5,
        },
        "& .MuiTypography-body1": {
            lineHeight: "unset",
            fontSize: "13px",
            color: "#4D4D4D",
        },
        "& .MuiTypography-h6": {
            fontWeight: 600,
        },
    },
    dialogContent: {
        lineHeight: 1.5,
    },
    strong: {
        fontWeight: 600,
        lineHeight: 1.5,
    },
    iconAction: {
        margin: "25px auto 14px",
        background: "#00467F",
        borderRadius: 40,
        width: "44px",
        height: "44px",
        "& .MuiSvgIcon-root": {
            color: "#fff",
            marginTop: "10px",
        },
    },
    dialogActions: {
        padding: "16px 32px",
        "& .MuiButton-contained:hover": {
            backgroundColor: "rgba(0,166,207, .8)",
        },
    },
    saveFab: {
        position: 'relative',
        color: "#ffffff",
        backgroundColor: "#00467F",
        boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 1%), 0px 1px 18px 0px rgb(0 0 0 / 11%)",
    },
    closeImg: {
        cursor: 'pointer',
        float: 'right',
        marginTop: '5px',
        width: '20px',
    },
    closeIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
    },
    customFieldPrompt: {
        background: '#F9F9F9', border: '1px solid #C9C7C7', borderRadius: 4, padding: '12px 16px 24px', margin: '20px 9px 10px',
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
            marginBottom: 12,
            marginTop: 10,
        },
        '& .MuiCheckbox-root': {
            paddingRight: 4,
            paddingTop: 1,
            paddingBottom: 0,
        },
    },


}));

export default function SaveBtn(props: { handleSave: (activeStatus: boolean) => void, open: boolean, isActive: boolean, errors: DeepMap<CustomFieldFormInputs, FieldError>}) {
    const classes = useStyles();
    const [showClickSaveDialog, setShowClickSaveDialog] = useState<boolean>(props.open);
    const [state, setState] = React.useState({
        activeCustomField: false,
    });

    /**
     * Shows the save dialog for when clicking the save button
     */
    function handleSaveBtnClick() {
        setShowClickSaveDialog(true);
    }

    return (
        <React.Fragment>
            <Fab
                aria-label="save"
                className={classes.saveFab}
                disabled={Object.keys(props.errors).length > 0}
                onClick={handleSaveBtnClick}
            >
                <SaveIcon />
            </Fab>
            <SaveActivateDialog
                open={showClickSaveDialog}
                setOpen={setShowClickSaveDialog}
                positiveAction={props.handleSave}
                isActive={props.isActive} />

        </React.Fragment>
    );
}
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";

const useStyles = makeStyles({
  commentIcon: {
    fontSize: "1rem",
    top: "4px !important",
    left: ".2em",
    cursor: "pointer",
  },
  tooltip: {
    fontSize: "12px",
    lineHeight: 1.7,
  },
});

interface CommentTooltipProps {
  comment: string;
}

/**
 * Displays a comment icon. When hovering over it, 
 * a tooltip appears with the comments from an approver 
 * when they  have either approved/denied a request
 */
export default function CommentTooltip(props: CommentTooltipProps) {
  const classes = useStyles();
  const { comment } = props;

  return (
    <React.Fragment>
      <Tooltip
        id={`tooltip-${comment}`}
        placement="right"
        arrow
        title={
          <React.Fragment>
            <Typography className={classes.tooltip} color="inherit">
              {comment}
            </Typography>
          </React.Fragment>
        }
      >
        <CommentOutlinedIcon className={classes.commentIcon} />
      </Tooltip>
    </React.Fragment>
  );
}

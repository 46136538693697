import DateFnsUtils from "@date-io/date-fns";
import { Box, Checkbox, Divider, FormControlLabel, Grid, Link, TextField, Typography } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { RemoveCircleOutline as RemoveIcon, TodayOutlined } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useState, useContext } from "react";
import { Control, Controller, DeepMap, FieldErrors, UseFieldArrayReturn, UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormTrigger, UseFormSetValue } from "react-hook-form";
import { UserContext } from "../../../../components/shared/UserContext";

import ActionDialog from "../../../../components/ui/Dialog/ActionDialog";
import { formUtils } from "../../../../util/form-utils";
import { PassportFields, ProfileFormInputs, SelectOption } from "../Profile";
import { iUserContext } from "@cbtravel/common/lib/shared/interfaces/iUserContext";
import MaskedTextField from "../../../../components/ui/Input/MaskedTextField";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";
import { dateUtil } from "../../../../util/date-util";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        inputWrapper: {
            "& .MuiDivider-root": {
                marginBottom: 24,
            },
        },
        h3: {
            fontSize: 12,
            fontWeight: 300,
            textTransform: "uppercase",
            paddingTop: 10,
            marginBottom: -4,
            display: "block",
        },
        accordion: {
            "& .MuiFormHelperText-root": {
                fontSize: 11,
                color: "#808080",
            },
            "& .MuiInputBase-input": {
                background: "#fff",
            },
            "& .MuiInputBase-root": {
                background: "#fff",
            },
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: 0
            },
        },
        addIcon: {
            color: "#00467E",
            marginTop: -4,
        },
        removeIcon: {
            color: "#BC2C2F",
        },
        addText: {
            "& .MuiLink-button": {
                fontSize: 12,
                fontFamily: "Open Sans, Arial, sans-serif",
                fontWeight: 600,
                color: "#00467E",
                marginTop: 0,
                textDecoration: "none",
            },
            "& .MuiSvgIcon-root": {
                marginBottom: -1.5,
                marginRight: -1,
                fontSize: 13,
            },
        },
        removeWrapper: {
            marginTop: -36,
            marginBottom: -36,
            "& .MuiLink-button": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "Open Sans, Arial, sans-serif",
                color: "#BC2C2F",
                textDecoration: "none",
            },
        },
        removeText: {
            "& .MuiSvgIcon-root": {
                marginRight: -1,
                marginBottom: -1.5,
                fontSize: "12px !important",
            },
        },
        whiteBG: {
            background: "#fff",
        },
        flex: {
            display: "flex",
            flexDirection: "column",
            marginLeft: 3,
        },
        h2: {
            fontSize: 13,
            fontWeight: 600,
            lineHeight: 1.5,
        },
        summary: {
            fontSize: 12,
        },
        error: {
            color: "#BC2C2F"
        },
        errorTextField: {
            paddingTop: '5px',
        },
        errorTextCalendar: {
            paddingTop: '2px',
        }
    })
);

interface PassportFieldsProps {
    field: PassportFields & { id: string },
    index: number,
    control: Control<ProfileFormInputs>,
    controlPassports: Array<PassportFields & { id: string }>,
    passportFieldArray: UseFieldArrayReturn<ProfileFormInputs, "passports", "id">,
    errors: DeepMap<ProfileFormInputs, FieldErrors>,
    countries: SelectOption[],
    getValues: UseFormGetValues<ProfileFormInputs>,
    clearErrors: UseFormClearErrors<ProfileFormInputs>,
    setError: UseFormSetError<ProfileFormInputs>
    trigger: UseFormTrigger<ProfileFormInputs>,
    setValue: UseFormSetValue<ProfileFormInputs>,
    /* Boolean prop indicating whether the user is viewing other's profile (true) or his/her profile (false) */
    viewingOtherProfile: boolean
}


export default function PassportFormFields(props: PassportFieldsProps) {
    const classes = useStyles();
    const { userContext } = useContext<iUserContext>(UserContext);
    const { field, index, control, controlPassports, passportFieldArray, errors, viewingOtherProfile } = props;
    const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);

    /**
     * Clears the fields if only one, deletes if more than one
     */
    function handleRemove() {
        if (controlPassports.length > 1) {
            passportFieldArray.remove(index);
        } else {
            passportFieldArray.update(index, {
                country: { label: "", value: "" },
                expireDate: null,
                issueDate: null,
                passportNumber: ""
            });
            let fields = [
                `passports.${index}.passportNumber` as const,
                `passports.${index}.country` as const,
                `passports.${index}.issueDate` as const,
                `passports.${index}.expireDate` as const
            ]
            props.trigger(fields);
        }
    }

    /**
     * When checking the primary passport checkbox, we need to make sure that any other 
     * passports marked as primary are unchecked.
     * 
     * @param checked Represents whether this passport is marked as primary or not.
     * @param onChange React hook form onChange function supplied by the Controller component.
     */
    function handleMakePrimary(checked: boolean, onChange: (...event: any[]) => void) {
        // If marking this passport as primary, we need to make sure all others are unchecked
        if (checked) {
            controlPassports.forEach((value, i) => {
                if (i !== index && value.primaryPassport) {
                    props.setValue(`passports.${i}.primaryPassport` as any, false);
                }
            });
        }

        // Apply change to this passport
        onChange(checked);
    }

    return (
        <Grid container spacing={3} id={`grid-container-.${index}`}>
            {/* Display dialog when this item is removed */}
            <ActionDialog
                id={`dialog-discard-.${index}`}
                title="Remove passport?"
                icon={<div className={useStyles().removeIcon}><RemoveIcon /></div>}
                positiveText="Remove"
                negativeText="Cancel"
                dialogType="delete"
                open={showRemoveDialog}
                setOpen={setShowRemoveDialog}
                positiveAction={handleRemove}
            >
                <p>This action is permanent. After removing, click "Save" at the bottom of the page to ensure deletion.</p>
            </ActionDialog>

            {props.controlPassports.length > 1 &&
                <Grid item xs={12} sm={12} md={12} lg={12} id={`grid-item-.${index}`}>
                    <Controller
                        name={`passports.${index}.primaryPassport` as const}
                        control={control}
                        rules={{
                            validate: {
                                primaryRequired: (value: boolean) => {
                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                        // We require that exactly one passport is marked as primary
                                        if (value) {
                                            controlPassports.forEach((value, i) => {
                                                props.clearErrors(`passports.${i}.primaryPassport` as const);
                                            });
                                        } else {
                                            let passports = props.getValues(`passports`);
                                            if (passports.findIndex(p => p.primaryPassport) < 0) {
                                                passports.forEach((v, i) => {
                                                    props.setError(`passports.${i}.primaryPassport` as const, {
                                                        type: "required",
                                                        message: "You must select a primary passport"
                                                    });
                                                });
                                                return "You must select a primary passport";
                                            }
                                        }

                                        return true;
                                    }
                                },
                            }
                        }}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`box-.${index}`}
                                        checked={value}
                                        onChange={(event, checked) => { handleMakePrimary(checked, onChange); onBlur(); }}
                                        ref={ref}
                                        onBlur={onBlur}
                                        disabled={props.controlPassports.length < 2}
                                        className={props?.errors?.passports?.[index]?.primaryPassport ? classes.error : ""}
                                    />}
                                label={<span className={props?.errors?.passports?.[index]?.primaryPassport ? classes.error : ""}>Make primary</span>}
                            />
                        )}
                    />
                    <Typography variant='body2' color='error' className={classes.errorTextField} id={`typo-2`}>
                        {props.errors?.passports && props?.errors?.passports?.[index]?.primaryPassport?.message}
                    </Typography>
                </Grid>
            }

            <Grid item xs={12} sm={12} md={6} lg={6} id={`grid-item-.${index}`}>
                <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                    <Controller
                        name={`passports.${index}.passportNumber` as const}
                        control={control}
                        rules={{
                            validate: {
                                dateRequired: (value: string) => {
                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                        let p = props.getValues(`passports.${index}` as `passports.0`)
                                        // if (value && (p.country.value || p.passportNumber) && !p.expireDate) {

                                        if (value && !p.expireDate) {
                                            props.setError(`passports.${index}.expireDate` as `passports.0.expireDate`, {
                                                type: "required",
                                                message: "Passport must have an expiration date"
                                            })
                                        }
                                        else if (!value && errors?.passports?.[index]?.expireDate) {
                                            props.trigger(`passports.${index}.expireDate` as `passports.0.expireDate`)
                                        }

                                        return true;
                                    }
                                },
                                countryRequired: (value: string) => {
                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                        const p = props.getValues(`passports.${index}` as `passports.0`)
                                        // if (value && (p.passportNumber || p.expireDate) && !p.country.value) {
                                        if (value && !p.country?.value) {
                                            props.setError(`passports.${index}.country` as `passports.0.country`, {
                                                type: "required",
                                                message: "Please select a country"
                                            })
                                        }
                                        else if (!value && errors?.passports?.[index]?.country) {
                                            props.trigger(`passports.${index}.country` as `passports.0.country`)
                                        }
                                        return true;
                                    }
                                },
                                required: (value: string) => {
                                    if ((viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                        const p = props.getValues(`passports.${index}` as `passports.0`)
                                        if (!value && (p.country?.label.length > 0 || p.expireDate || p.issueDate)) {
                                            return "Please enter a passport number"
                                        }
                                        return true;
                                    }
                                },
                            },
                            maxLength: { value: 100, message: "Max 100 characters" }
                        }}
                        render={({
                            field: { onChange, value, ref, onBlur }
                        }) => (
                            <MaskedTextField
                                userType={userContext.userType}
                                action={viewingOtherProfile ? "passports.passportNumber:unmask" : "ownProfileField:unmask"}
                                id={`ff-passport-number-${index}`}
                                label="Passport #"
                                variant="outlined"
                                size="small"
                                fullWidth
                                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                                ref={ref}
                                error={props.errors.passports?.[index]?.passportNumber ? true : false}
                                InputProps={{ autoComplete: "off" }}
                            />
                        )} />
                </form>
                <Typography variant='body2' color='error' className={classes.errorTextField} id={`typo-2`}>
                    {props.errors?.passports && props?.errors?.passports?.[index]?.passportNumber?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} id={`grid-item-.${index}`}>
                <Box mt={-1} id={`box-.${index}`}>
                    <Controller
                        name={`passports.${index}.country` as const}
                        control={control}
                        rules={{
                            validate: {
                                dateRequired: (value: SelectOption) => {
                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                        const p = props.getValues(`passports.${index}` as `passports.0`)

                                        if (value && !p.expireDate) {
                                            props.setError(`passports.${index}.expireDate` as `passports.0.expireDate`, {
                                                type: "required",
                                                message: "Passport must have an expiration date"
                                            })
                                        }
                                        else if (!value && errors?.passports?.[index]?.expireDate) {
                                            props.trigger(`passports.${index}.expireDate` as `passports.0.expireDate`)
                                        }
                                        return true;
                                    }
                                },
                                numberRequired: (value: SelectOption) => {
                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                        const p = props.getValues(`passports.${index}` as `passports.0`)
                                        if (value && value.label.length > 0 && !p.passportNumber) {
                                            props.setError(`passports.${index}.passportNumber` as `passports.0.passportNumber`, {
                                                type: "required",
                                                message: "Please enter a passport number"
                                            })
                                        }
                                        else if (!value && errors?.passports?.[index]?.passportNumber) {
                                            props.trigger(`passports.${index}.passportNumber` as `passports.0.passportNumber`)
                                        }
                                        return true;
                                    }
                                },
                                required: (value: SelectOption) => {
                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                        const p = props.getValues(`passports.${index}` as `passports.0`)
                                        if ((!value || value.label === "") && (p.passportNumber || p.expireDate || p.issueDate)) {
                                            return "Please select a country"
                                        }
                                        return true;
                                    }
                                },
                            },
                        }}
                        render={({
                            field: { onChange, ref, onBlur }
                        }) => (
                            <Autocomplete
                                id={`ac-passport-country-${index}`}
                                options={props.countries}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.value == value.value || value.value == ""}
                                fullWidth
                                autoSelect
                                autoHighlight
                                defaultValue={field.country}
                                value={field.country}
                                ref={ref}
                                onBlur={onBlur}
                                onChange={(_, value) => onChange(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id={`ff-passport-country-${index}`}
                                        label="Issuing country"
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.whiteBG}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={props.errors.passports?.[index]?.country ? true : false}
                                    />
                                )}
                            />
                        )} />
                </Box>
                <Typography variant='body2' color='error' className={classes.errorTextField} id={`typo-2`}>
                    {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                    {props.errors?.passports && (props?.errors?.passports?.[index]?.country as any)?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} id={`grid-item-.${index}`}>
                <Controller
                    name={`passports.${index}.issueDate` as const}
                    control={control}
                    rules={{
                        validate: {
                            countryRequired: (value: Date | null) => {
                                const p = props.getValues(`passports.${index}` as `passports.0`)
                                // if (value && (p.passportNumber || p.expireDate) && !p.country.value) {
                                if (value && !p.country?.value) {
                                    props.setError(`passports.${index}.country` as `passports.0.country`, {
                                        type: "required",
                                        message: "Please select a country"
                                    })
                                }
                                else if (!value && errors?.passports?.[index]?.country) {
                                    props.trigger(`passports.${index}.country` as `passports.0.country`)
                                }
                                return true;
                            },
                            numberRequired: (value: Date | null) => {
                                if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                    const p = props.getValues(`passports.${index}` as `passports.0`)
                                    if (value && !p.passportNumber) {
                                        props.setError(`passports.${index}.passportNumber` as `passports.0.passportNumber`, {
                                            type: "required",
                                            message: "Please enter a passport number"
                                        })
                                    }
                                    else if (!value && errors?.passports?.[index]?.passportNumber) {
                                        props.trigger(`passports.${index}.passportNumber` as `passports.0.passportNumber`)
                                    }
                                    return true;
                                }
                            },
                        }
                    }}
                    defaultValue={undefined}
                    render={({
                        field: { onChange, value, onBlur }
                    }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableFuture
                                margin="dense"
                                id={`passport-issue-date-${index}`}
                                label="Issue date"
                                openTo="year"
                                format="MM/dd/yyyy"
                                placeholder="MM/DD/YYYY"
                                invalidDateMessage=""
                                minDateMessage=""
                                maxDateMessage=""
                                views={["year", "month", "date"]}
                                inputVariant="outlined"
                                value={value}
                                onBlur={onBlur}
                                onClose={onBlur}
                                onChange={onChange}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <IconButton color="primary" aria-label="ID Issue Date" id={`btn-icon-passport-issue-date`}>
                                                <TodayOutlined />
                                            </IconButton>
                                        </InputAdornment>)
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} id={`grid-item-.${index}`}>
                <Controller
                    name={`passports.${index}.expireDate` as const}
                    control={control}
                    defaultValue={undefined}
                    rules={{
                        validate: {
                            required: (value: Date | null) => {
                                if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                    const p = props.getValues(`passports.${index}` as `passports.0`)
                                    if ((p.country?.value || p.passportNumber) && !p.expireDate && (value === null || value === undefined)) {
                                        return "Passport must have an expiration date"
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            pattern: (value: Date | null) => {
                                let date: Date | null = null;
                                if (value != null && !dateUtil.compareToMinDate(new Date(value))) {
                                    date = new Date(value);
                                    if (!(date instanceof Date)
                                        // || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
                                    ) {
                                        return "Invalid date format"
                                    }
                                }
                                return true;
                            },
                            countryRequired: (value: Date | null) => {
                                if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                    const p = props.getValues(`passports.${index}` as `passports.0`)
                                    // if (value && (p.passportNumber || p.expireDate) && !p.country.value) {
                                    if (value && !p.country?.value) {
                                        props.setError(`passports.${index}.country` as `passports.0.country`, {
                                            type: "required",
                                            message: "Please select a country"
                                        })
                                    }
                                    else if (!value && errors?.passports?.[index]?.country) {
                                        props.trigger(`passports.${index}.country` as `passports.0.country`)
                                    }
                                    return true;
                                }
                            },
                            numberRequired: (value: Date | null) => {
                                if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                    const p = props.getValues(`passports.${index}` as `passports.0`)
                                    if (value && !p.passportNumber) {
                                        props.setError(`passports.${index}.passportNumber` as `passports.0.passportNumber`, {
                                            type: "required",
                                            message: "Please enter a passport number"
                                        })
                                    }
                                    else if (!value && errors?.passports?.[index]?.passportNumber) {
                                        props.trigger(`passports.${index}.passportNumber` as `passports.0.passportNumber`)
                                    }
                                    return true;
                                }
                            },
                        }
                    }}
                    render={({
                        field: { onChange, value, onBlur }
                    }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="dense"
                                id={`passport-exp-date-${index}`}
                                label="Expiration date"
                                openTo="year"
                                format="MM/dd/yyyy"
                                placeholder="MM/DD/YYYY"
                                invalidDateMessage=""
                                minDateMessage=""
                                maxDateMessage=""
                                views={["year", "month", "date"]}
                                inputVariant="outlined"
                                value={value}
                                onBlur={onBlur}
                                onClose={onBlur}
                                onChange={onChange}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <IconButton color="primary" aria-label="Passport expiration date" id={`btn-icon-passport-exp-date`}>
                                                <TodayOutlined />
                                            </IconButton>
                                        </InputAdornment>)
                                }}
                                error={props?.errors?.passports?.[index]?.expireDate ? true : false}
                            />
                        </MuiPickersUtilsProvider>
                    )} />
                <Typography variant='body2' color='error' id={`typo-2`}>
                    {props.errors?.passports && props?.errors?.passports?.[index]?.expireDate?.message}
                </Typography>
            </Grid>

            {/* Don't allow removal of final element */}
            <Grid
                container
                justify="flex-end"
                className={classes.removeWrapper}
                id={`grid-container-.${index}`}
            >
                <Box component={"div"} className={classes.removeText} id={`box-.${index}`}>
                    <Typography align="right" id={`typo-removeDialog`}>
                        <Link href="#" component="button" color="error" id={`link-removeDialog`}
                            onClick={() => {
                                setShowRemoveDialog(true);
                            }}>
                            <RemoveIcon className={classes.removeIcon} /> Remove
                        </Link>
                    </Typography>
                </Box>
            </Grid>
            {
                controlPassports.length > 1 &&
                index !== controlPassports.length - 1 &&
                <Grid className={classes.inputWrapper} item xs={12} sm={12} md={12} lg={12} id={`grid-container-.${index}`}>
                    <Divider />
                </Grid>
            }
        </Grid >
    );
}
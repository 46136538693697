import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Link, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  AddCircleOutline as AddIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import { Control, DeepMap, FieldErrors, useFieldArray, UseFormRegister, UseFormTrigger, useWatch, UseFormSetError, UseFormGetValues } from "react-hook-form";

import { ProfileFormInputs, CreditCardFields } from "./Profile";
import CreditCardFormFields from "./FieldArrayComponents/CreditCardFieldArray";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTypography-subtitle1": {
        fontSize: 12,
        marginBottom: theme.spacing(1),
      },
      "& .MuiFormHelperText-root": {
        fontSize: 12,
        color: "#808080",
      },
    },
    accordion: {
      "& .MuiFormHelperText-root": {
        fontSize: 11,
        color: "#808080",
      },
      "& .MuiInputBase-input": {
        background: "#fff",
      },
      "& .MuiInputBase-root": {
        background: "#fff",
      },
      border: "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    accordionDetails: {
      padding: theme.spacing(2),
    },
    h3: {
      fontSize: 12,
      fontWeight: 300,
      textTransform: "uppercase",
      paddingTop: 10,
      marginBottom: -4,
      display: "block",
    },
    addIcon: {
      color: "#00467E",
      marginTop: -4,
    },
    removeIcon: {
      color: "#BC2C2F",
    },
    addText: {
      marginTop: 0,
      "& .MuiLink-button": {
        fontSize: 12,
        fontFamily: "Open Sans, Arial, sans-serif",
        fontWeight: 600,
        color: "#00467E",
        marginTop: 0,
        textDecoration: "none",
      },
      "& .MuiSvgIcon-root": {
        marginBottom: -1.5,
        marginRight: -1,
        fontSize: 13,
      },
    },
    removeWrapper: {
      marginTop: -63,
      marginBottom: -63,
      "& .MuiLink-button": {
        fontSize: 12,
        fontWeight: 600,
        fontFamily: "Open Sans, Arial, sans-serif",
        color: "#BC2C2F",
        textDecoration: "none",
      },
    },
    removeText: {
      "& .MuiSvgIcon-root": {
        marginRight: -1,
        marginBottom: -1.5,
        fontSize: 12,
      },
    },
    card: {
      padding: "8px 24px 12px",
      marginBottom: 16,
    },
    h2: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: 1.5,
      paddingBottom: 4,
    },
    summary: {
      backgroundColor: "rgba(0, 0, 0, .03)",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: -1,
      minHeight: 44,
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 44,
      },
    },
    content: {
      "&$expanded": {
        margin: "12px 0",
      },
    },
    defaultUse: {
      width: 21,
      margin: "4px 4px 0 4px",
    },
  })
);

export const cardTypes = [
  { name: "", value: "" },
  { name: "AirPlus", value: "TP" },
  { name: "American Express", value: "AX" },
  { name: "Diners Club", value: "DC" },
  { name: "Discover", value: "DS" },
  { name: "Mastercard", value: "CA" },
  { name: "Visa", value: "VI" }
];

interface PaymentInfoTabProps {
  control: Control<ProfileFormInputs>,
  errors: DeepMap<ProfileFormInputs, FieldErrors>,
  register: UseFormRegister<ProfileFormInputs>,
  trigger: UseFormTrigger<ProfileFormInputs>,
  setError: UseFormSetError<ProfileFormInputs>,
  getValues: UseFormGetValues<ProfileFormInputs>,
  viewingOtherProfile: boolean
}

export default function PaymentInfoTab(props: PaymentInfoTabProps) {
  const classes = useStyles();

  // set up field array for credit cards
  const cardFieldArray = useFieldArray({ control: props.control, name: "creditCards" });
  const watchCreditCards = useWatch({ control: props.control, name: "creditCards" });
  const controlCreditCards = cardFieldArray.fields.map((field, index) => {
    return {
      ...field,
      ...watchCreditCards[index]
    };
  });

  /**
   * Used for making the text that displays on the accordion summaries for the cards.
   * 
   * @param cardTypeCode The two-character code for the payment network.
   * @param cardNumber The number on the card.
   * @returns A string with text to place in the accordion summaries on this tab.
   */
  function summaryText(cardTypeCode: string, cardNumber: string) {
    let newCC = cardNumber === "";

    if (newCC) {
      return "New card";
    } else {
      let cardTypeName = cardTypes.find((t) => t.value === cardTypeCode)?.name || "Card";
      return `${cardTypeName} ending in ...${cardNumber.slice(-4)}`;
    }

  }

  return (
    <Box component={"div"} className={classes.root}>
      <Typography variant="h2">Payment info</Typography>
      <Typography variant="subtitle1">
        Add and update your payment details
      </Typography>

      {/* Start of saved credit cards */}
      <Box mt={3} mb={4}>
        {controlCreditCards.map((field, index) => {


          return (
            <CreditCardFormFields
              field={field}
              index={index}
              control={props.control}
              controlCreditCards={controlCreditCards}
              cardFieldArray={cardFieldArray}
              errors={props.errors}
              register={props.register}
              trigger={props.trigger}
              setError={props.setError}
              getValues={props.getValues}
              viewingOtherProfile={props.viewingOtherProfile}
            />
          )
        })
        }


      </Box>
      {/* End saved credit cards */}
      <Box component="p" display="flex" py={0.5} className={classes.addText}>
        <Link
          href="#"
          component="button"
          color="primary"
          id={`link-credit-card-fields`}
          onClick={() => {
            let ccF: CreditCardFields = {
              cardNumber: "",
              cardType: "",
              nameOnCard: "",
              displayName: "",
              expireDate: null,
              defaultForAir: false,
              defaultForCar: false,
              defaultForHotel: false,
              defaultForRail: false,
              isSaved: false
            };
            cardFieldArray.append(ccF);
          }}
        >
          <AddIcon className={classes.addIcon} /> Add another credit card
        </Link>
      </Box>
    </Box>
  );
}

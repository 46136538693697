import React, { SetStateAction } from 'react';

import { Box, Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            "& .MuiDialog-paper": {
                textAlign: "center",
                width: 350,
            },
            "& .MuiDialogTitle-root": {
                padding: "16px 24px 0",
            },
            "& .MuiDialogContent-root": {
                padding: "0 24px",
            },
            "& .MuiTypography-body1": {
                lineHeight: "unset",
                fontSize: "13px",
                color: "#4D4D4D",
            },
            "& .MuiTypography-h6": {
                fontWeight: 600,
            },
        },
        strong: {
            fontWeight: 600,
            lineHeight: 1.5,
        },
        iconAction: {
            margin: "8px auto 14px",
            background: "#bc2c2f",
            borderRadius: 40,
            width: "44px",
            height: "44px",
            "& .MuiSvgIcon-root": {
                color: "#fff",
                marginTop: "10px",
            },
        },
        dialogActions: {
            padding: "16px 32px 24px",
        },
        btnSave: {
            background: "#00467F",
            "&:hover": {
                backgroundColor: "#00467F" // I don't know why these buttons change on hover when others in the app don't, but this hides that behavior
            },
        },
        btnRemove: {
            background: "#bc2c2f",
            "&:hover": {
                backgroundColor: "#bc2c2f"
            },
        },
        btnCancel: {
            color: "#00467E",
            marginLeft: "0 !important",
            fontSize: "13px",
        },
    })
);

/**
 * Properties for the `ActionDialog` component.
 */
interface ActionDialogProps {
    /** Tcomponment id for QA */
    id: string,
    /** Text display as the title of the dialog. */
    title: string,
    /** Text to display on the positive action button. */
    positiveText: string,
    /** Text to display on the negative action button. */
    negativeText: string,
    /** Element to display as an icon. */
    icon: React.ReactNode | null
    /** Additional elements to display inside the dialog. */
    children: React.ReactNode,
    /** Decides the type of theming used for the dialog. */
    dialogType: "save" | "delete",
    /** Whether or not the dialog is open or not. */
    open: boolean,
    /** React state setter for dialog open status.*/
    setOpen: React.Dispatch<SetStateAction<boolean>> | ((v: boolean) => void),
    /** Action to perform when selecting the positive action button. */
    positiveAction: () => void,
    /** Action to perform when selecting the negative action button. */
    negativeAction: () => void
}

ActionDialog.defaultProps = {
    id:"",
    title: "",
    positiveText: "Okay",
    negativeText: "Cancel",
    icon: <></>,
    open: false,
    children: <></>,
    dialogType: "save",
    setOpen: (a: SetStateAction<boolean>) => { },
    positiveAction: () => { },
    negativeAction: () => { }
} as Partial<ActionDialogProps>;

/**
 * This component is designed to be a flexible and reusable dialog box with two buttons.
 * You can customize the actions, text, icon, and title. Additionally, you can wrap
 * child components inside of ActionDialog to display custom content inside of it.
 * 
 * @param props {@link ActionDialogProps Properties} for the `ActionDialog` component.
 * @returns A JSX element for use as a dialog box with two actions.
 */
export default function ActionDialog(props: ActionDialogProps) {
    const classes = useStyles();

    function onClickPositiveAction() {
        props.setOpen(false);
        props.positiveAction();
    }

    function onClickNegativeAction() {
        props.negativeAction();
        props.setOpen(false);
    }

    // Set the style of the button based on what type of dialog this is
    let positiveActionButtonStyle = classes.btnRemove;
    switch (props.dialogType) {
        case "delete":
            positiveActionButtonStyle = classes.btnRemove;
            break;
        case "save":
            positiveActionButtonStyle = classes.btnSave;
            break;
    }

    return (
        <Dialog
            id ={props.id}
            open={props.open}
            onClose={() => {
                props.setOpen(false);
            }}
            className={classes.dialog}
        >
            <DialogTitle>
                <Box>
                    {props.icon && <div className={classes.iconAction}>{props.icon}</div>}
                </Box>
                {props.title}
            </DialogTitle>

            <DialogContent>{props.children}</DialogContent>

            <div className={classes.dialogActions}>
                <Button
                    variant="contained"
                    className={positiveActionButtonStyle}
                    fullWidth={true}
                    onClick={onClickPositiveAction}
                >
                    {props.positiveText}
                </Button>
                <Button
                    className={classes.btnCancel}
                    fullWidth={true}
                    onClick={onClickNegativeAction}
                >
                    {props.negativeText}
                </Button>
            </div>
        </Dialog>
    );
}

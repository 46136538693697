import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Box, Button, Fab, Grid, Tab, Tabs, TextField, Tooltip, Typography } from "@material-ui/core";
import Spinner from "../../../components/shared/Spinner";

import AirTab from "./AirTab";
import BusTab from "./BusTab";
import CarTab from "./CarTab";
import CruiseTab from "./CruiseTab";
import HotelTab from "./HotelTab";
import InsuranceTab from "./InsuranceTab";
import MiscTab from "./MiscTab";
import RideshareTransferTab from "./RideshareTransferTab";
import TourActivityTab from "./TourActivityTab";
import TrainTab from "./TrainTab";

import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface VendorsProps {
  /** Object representing the currently active client. */
  activeClient?: ClientRS,
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

function tabProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTab-root": {
      minWidth: 'auto',
    },
    "& .MuiTabScrollButton-root": {
      width: '20px',
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      marginTop: 1, 
      color: "#00467E",
    },
    backgroundColor: '#ffffff',
    borderBottomWidth: '1px',
    borderBottomColor: '#ddd',
    borderBottomStyle: 'solid',
  },
  tab: {
    textTransform: 'none',
    paddingLeft: 25,
    paddingRight: 25,
  },
  tabRow: {
    marginTop: 17,
    marginLeft: -4,
    boxShadow: 'none',
  },
  tooltip: {
    fontSize: '.7rem',
    marginTop: -4,
  },
  tooltipLabel: {
    fontSize: '11px', pointerEvents: "auto",
    // marginLeft: -22, marginTop: 12,
  },
  fab: {
    "& .MuiSvgIcon-root": {
      fontSize: '2rem',
    },
    position: "absolute",
    bottom: 32, right: 32,
    width: 50, height: 50,
    color: "#fff",
    background: "#00467E",
    boxShadow:
        "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
},
  }));

/** Vendor page */
export default function Vendors(props: VendorsProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [displayVendorForm, setDisplayVendorForm] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  /** Opens up vendor form */
  const handleAddVendor = () => {
    setDisplayVendorForm(true);
  }

  /** When discard button is clicked in dialog, it hides the VendorForm */
  const handleDiscardChanges = () => { 
    setDisplayVendorForm(false);
  }

  const RideshareLabel = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <span style={{fontSize: 14}}>Rideshare/Transfer</span>
      <Tooltip title="Includes limos, shuttles, taxis, Uber, Lyft, etc" placement="top-end" arrow><InfoOutlinedIcon className={classes.tooltip} /></Tooltip>
    </Box>
  );

  const TourLabel = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <span style={{fontSize: 14}}>Tour/Activity</span>
      <Tooltip title="Includes tickets and packages" placement="top-end" arrow><InfoOutlinedIcon className={classes.tooltip} /></Tooltip>
    </Box>
  );

  return (
    <>
      <AppBar className={classes.tabRow} position="static" color="default">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Vendors tab navigation"
        >
          <Tab className={classes.tab} label="Air" {...tabProps(0)} />
          <Tab className={classes.tab} label="Hotel" {...tabProps(1)} />
          <Tab className={classes.tab} label="Car" {...tabProps(2)} />
          <Tab className={classes.tab} label="Train" {...tabProps(3)} />
          <Tab className={classes.tab} label="Bus" {...tabProps(4)} />
          <Tab className={classes.tab} label={RideshareLabel} {...tabProps(5)} />
          <Tab className={classes.tab} label={TourLabel} {...tabProps(6)} />
          <Tab className={classes.tab} label="Cruise"  {...tabProps(7)} />
          <Tab className={classes.tab} label="Insurance"  {...tabProps(8)} />
          <Tab className={classes.tab} label="Miscellaneous"  {...tabProps(9)} />
        </Tabs>
      </AppBar>
 
      <TabPanel value={value} index={0}>
        <AirTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HotelTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CarTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TrainTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BusTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <RideshareTransferTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <TourActivityTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <CruiseTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <InsuranceTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <MiscTab displayVendorForm={displayVendorForm} discardChanges={handleDiscardChanges} />
      </TabPanel>

      {!displayVendorForm &&
        <Fab
          color="primary"
          aria-label="Add vendor"
          className={classes.fab}
          onClick={handleAddVendor}
        >
          <AddIcon />
        </Fab>
      }
    </>

  )
}
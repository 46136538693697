import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Box, Grid, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Table, TableBody, TableHead, TableCell, TableRow, MenuItem, TextField, AppBar, Tab, Tabs } from "@material-ui/core";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    stepLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
    },
    formControl: {
      "& .MuiFormLabel-root": {
        fontSize: ".9rem",
        fontWeight: 600,
        color: "#4d4d4d"
      },
      "& .MuiFormControlLabel-label": {
        fontSize: ".9rem",
        color: "#4d4d4d"
      },
    },
    //table styles
    tableHead: {
      backgroundColor: "#F2F2F2",
    },
    tableStyles: {
      "& .MuiTableCell-root": {
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    rowFocus: {
      fontWeight: 'bold',
    },
    tableCellHighlight: {
      "& .MuiTableCell-root": {
        border: "2px solid #00467E",
      },
    },
    lightGreyBg: {
      backgroundColor: "#f2f2f2",
    },
    //tab styles 
    tabs: {
      "& .MuiTab-root": {
        minWidth: 'auto',
      },
      "& .MuiTabScrollButton-root": {
        width: '20px',
        border: 'none',
      },
      "& .MuiSvgIcon-fontSizeSmall": {
        marginTop: 1,
        color: "#00467E",
      },
      backgroundColor: '#ffffff',
      borderBottomWidth: '1px',
      borderBottomColor: '#ddd',
      borderBottomStyle: 'solid',
    },
    tab: {
      textTransform: 'none',
      paddingLeft: 25,
      paddingRight: 25,
    },
    tabRow: {
      marginTop: 17,
      marginLeft: -4,
      boxShadow: 'none',
    },
    // add new item
    addFieldTitle: {
      color: '#00467E',
      fontSize: '16px',
      cursor: 'pointer',
    },
    addIcon: {
      color: '#00467E',
      fontSize: '20px',
      marginLeft: '6px',
      marginBottom: '-2px',
      cursor: 'pointer',
    },
    exampleText: {
      fontStyle: 'italic',
    }
  }),
);
function tabProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

export default function MapFields() {
  const classes = useStyles();
  const tableLetters = [' ', 'A', 'B', 'C', 'D', 'E', 'F']
  const exampleDataRow1 = ['Employee ID', 'First name', 'Middle name', 'Last name', 'Email', 'Date of birth']
  const exampleDataRow2 = ['1', 'Jane', 'Jo', 'Doe', 'jdoe@email.com', '11/01/1989']
  const [columName, setColumnName] = React.useState('');
  const [columAdditionalField, setAdditionalField] = React.useState('');
  const [value, setValue] = React.useState(0);
  const [inputList, setInputList] = useState([] as any);
  const Input = () => {
    return (
      <TableRow>
        <TableCell>
          <TextField
            id="column-name2"
            fullWidth
            select
            label="Column Name"
            value={columAdditionalField}
            onChange={handleAdditionalField}
            variant="outlined"
          >
            {importedAdditionalFields.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell>
        <TextField
            id="column-name3"
            fullWidth
            select
            label="Column Name"
            value={columAdditionalField}
            onChange={handleAdditionalField}
            variant="outlined"
          >
            {importedAdditionalFields.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
      </TableRow>
    );
  };

  //Set default value for column wrapper
  const handleColumnName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColumnName(event.target.value);
  };

  //Set additional fields
  const handleAdditionalField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalField(event.target.value);
  };

  //Switch tabs
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  //adds new row of additonal fields to table 
  const onAddBtnClick = (event: any) => {
    setInputList(inputList.concat(<Input key={inputList.length} />));
  };

  //Set table example
  const [yesNoColumnHeader, setColumnHeader] = React.useState('YesColumnHeader');
  const handleYesNoColumnHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColumnHeader((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <Box my={4}>
        <Typography className={classes.stepLabel}>Step 2</Typography>
        <Typography>Map Fields</Typography>
      </Box>
      <Box className={classes.formControl} my={1}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Does the file contain column headers? </FormLabel>
          <RadioGroup defaultValue="YesColumnHeader" aria-label="column header" name="customized-radios" onChange={handleYesNoColumnHeader}>
            <Box display="flex" flexDirection="row">
              <FormControlLabel value="YesColumnHeader" control={<Radio />} label="Yes" id="yesColumnHeader"
              />
              <FormControlLabel value="NoColumnHeader" control={<Radio />} label="No" id="noColumnHeader" />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
      {yesNoColumnHeader === "YesColumnHeader" &&
        <>
          <Box my={2}>
            <Typography variant="body2" className={classes.exampleText}> Example: Row 1 contains column headers </Typography>
          </Box>
          <Box mb={4}>
            <Table size="small" className={classes.tableStyles}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {tableLetters.map(letter => (
                    <TableCell align="center">{letter}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.tableCellHighlight}>
                  <TableCell className={classes.lightGreyBg} align="center"><span className={classes.rowFocus}>1</span></TableCell>
                  {exampleDataRow1.map(example => (
                    <TableCell align="center"><span className={classes.rowFocus}>{example}</span></TableCell>))}
                </TableRow>
                <TableRow>
                  <TableCell className={classes.lightGreyBg} align="center">2</TableCell>
                  {exampleDataRow2.map(example => (
                    <TableCell align="center">{example}</TableCell>))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </>
      }
      {yesNoColumnHeader === "NoColumnHeader" &&
        <>
          <Box my={2}>
            <Typography variant="body2" className={classes.exampleText}> Example: Table with no column headers </Typography>
          </Box>
          <Box mb={4}>
            <Table size="small" className={classes.tableStyles}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {tableLetters.map(letter => (
                    <TableCell align="center">{letter}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>

                <TableRow>
                  <TableCell className={classes.lightGreyBg} align="center">1</TableCell>
                  {exampleDataRow2.map(example => (
                    <TableCell align="center">{example}</TableCell>))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </>
      }
      <Box my={2}>
        <Typography variant="h2">Map Imported Fields</Typography>
      </Box>
      <AppBar className={classes.tabRow} position="static" color="default">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Vendors tab navigation"
        >
          <Tab className={classes.tab} label="Required Fields" {...tabProps(0)} />
          <Tab className={classes.tab} label="Additional Fields" {...tabProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Box my={2}>
          <Typography variant="body2">These fields <strong>must be mapped</strong> in order to create a profile</Typography>
        </Box>
        <Box my={2}>
          <Table className={classes.tableStyles}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Database column name</TableCell>
                <TableCell>Imported column name</TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell>First name</TableCell>
              <TableCell>
                <TextField
                  id="column-name"
                  fullWidth
                  select
                  label="Column Name"
                  value={columName}
                  onChange={handleColumnName}
                  variant="outlined"
                >
                  {importedMappedItems.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell>
                <TextField
                  id="column-name"
                  fullWidth
                  select
                  label="Column Name"
                  value={columName}
                  onChange={handleColumnName}
                  variant="outlined"
                >
                  {importedMappedItems.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email address</TableCell>
              <TableCell>
                <TextField
                  id="column-name"
                  fullWidth
                  select
                  label="Column Name"
                  value={columName}
                  onChange={handleColumnName}
                  variant="outlined"
                >
                  {importedMappedItems.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date of birth</TableCell>
              <TableCell>
                <TextField
                  id="column-name"
                  fullWidth
                  select
                  label="Column Name"
                  value={columName}
                  onChange={handleColumnName}
                  variant="outlined"
                >
                    {importedMappedItems.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
            </TableRow>
          </Table>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box my={2}>
          <Typography variant="body2">These fields are optional and not required to create a profile. <strong>Note:</strong> The more fields that are mapped, the less fields the user has to fill out on their profile.</Typography>
        </Box>
        <Box my={2}>
          <Table className={classes.tableStyles}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Database column name</TableCell>
                <TableCell>Imported column name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    id="column-name"
                    fullWidth
                    select
                    label="Column Name"
                    value={columAdditionalField}
                    onChange={handleAdditionalField}
                    variant="outlined"
                  >
                    {importedAdditionalFields.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    id="column-name"
                    fullWidth
                    select
                    label="Column Name"
                    value={columAdditionalField}
                    onChange={handleAdditionalField}
                    variant="outlined"
                  >
                    {importedAdditionalFields.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
              </TableRow>
              {inputList}
            </TableBody>

          </Table>
        </Box>
        <Box>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Typography className={classes.addFieldTitle} onClick={onAddBtnClick}>
              Add profile fields
              <img
                className={classes.addIcon}
                src={require("../../../icons/addIcon.svg").default}
                alt="add icon"
              />
            </Typography>
          </Box>
        </Box>
      </TabPanel>
      <Box my={20} />
    </>
  );
}

const importedMappedItems = [
  {
    value: 'employeeID',
    label: 'Employee ID',
  },
  {
    value: 'legalFN',
    label: 'First name',
  },
  {
    value: 'legalLN',
    label: 'Last name',
  },
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'secondaryEmail',
    label: 'Secondary Email',
  },
  {
    value: 'DOB',
    label: 'Date of Birth',
  },
  {
    value: 'gender',
    label: 'Gender',
  },
  {
    value: 'mobilePhone',
    label: 'Mobile Phone',
  },
  {
    value: 'department',
    label: 'Department',
  },
  {
    value: 'position',
    label: 'Position',
  },
]
const importedAdditionalFields = [
  {
    value: 'employeeID',
    label: 'Employee ID/Unique ID',
  },
  {
    value: 'managerEmail',
    label: 'Manager Email',
  },
  {
    value: 'namePrefix',
    label: 'Name prefix',
  },
  {
    value: 'nameSuffix',
    label: 'Name suffix',
  },
  {
    value: 'middleName',
    label: 'Middle name',
  },
  {
    value: 'language',
    label: 'Language',
  },
  {
    value: 'travelRuleClass',
    label: 'Travel/Rule class',
  },
  {
    value: 'orgUnitDivision',
    label: 'Org. Unit/Division',
  },
]
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import UploadFile from './UploadFile';
import MapFields from './MapFields';
import OBELogin from './OBELogin';
import Notifications from './Notifications';
import Review from './Review';
import { Box, StepLabel } from '@material-ui/core';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepConnector from '@material-ui/core/StepConnector';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CancelDialog from './dialogs/CancelDialog';
import ChangeClientDialog from './dialogs/ChangeClientDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    stickyNav: {
      bottom: 32, right: 32, background: '#fff',
    },
    mediumGray: {
      color: "#4d4d4d",
    },
    completed: {
      display: 'inline-block',
      backgroundColor: "#00467F",
      color: "#fff",
      border: '1px solid #fff',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    step: {
      padding: 24,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      "& .MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
        flexDirection: "row",
        float: "left",
        paddingRight: 10,
      },
      "& .MuiStepLabel-label": {
        textAlign: 'left',
        marginLeft: 50,
        color: 'inherit',
      },
      "& .MuiTypography-body2": {
        color: 'inherit',
      },
      "& .MuiStepButton-root": {
        marginTop: "-64px",
        paddingBottom: 30,
        justifyContent: 'left',
      },
      // "& .MuiStepButton-root:hover": {
      //   backgroundColor: "#00467F",
      //   color: 'white',
      // },
    },
    stepHover: {
      backgroundColor: "#00467F",
      color: "#fff",
      padding: 24,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      "& .MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
        flexDirection: "row",
        float: "left",
        paddingRight: 10,
      },
      "& .MuiStepLabel-label": {
        textAlign: 'left',
        marginLeft: 50,
      },
      "& .MuiStepButton-root": {
        marginTop: "-64px",
        paddingBottom: 30,
        justifyContent: 'left',
      },
    },
    stepLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
      marginTop: -7,
    },
    stepLabelParent: {
      "& .MuiPaper-root": {
        backgroundColor: "#f9f9f9",
        border: "1px solid #C9C7C7",
        borderRadius: 6,
        padding: 0,
      },
      "& .MuiStepIcon-root": {
        display: 'none',
      }
    }
  }),
);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#f9f9f9",
    zIndex: 1,
    color: '#808080',
    border: '1px solid #808080',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: "#00467F",
    color: "#fff",
    border: '1px solid #fff',
  },
  completed: {
    backgroundColor: "#f9f9f9",
    color: '#808080',
    border: '1px solid #808080',
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: "transparent",
    },
  },
  completed: {
    '& $line': {
      backgroundColor: "transparent",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: 'transparent',
    borderRadius: 1,
  },
})(StepConnector);

function getSteps() {
  return ['Upload a file', 'Map Fields', 'OBE Login', 'Notifications', 'Review'];
}
function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <UploadFile />;
    case 1:
      return <MapFields />;
    case 2:
      return <OBELogin />;
    case 3:
      return <Notifications />;
    case 4:
      return <Review />;
    default:
      return <Review />;
  }
}

{/* <TestBulk width='20px' height='20px' /> */ }
function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <CreateNewFolderOutlinedIcon />,
    2: <SwapHorizIcon />,
    3: <PersonOutlineOutlinedIcon />,
    4: <MailOutlineOutlinedIcon />,
    5: <PlaylistAddCheckIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export default function HRFeedUploadStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set<number>());

  const steps = getSteps();
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [showCSDialog, setShowCSDialog] = useState<boolean>(false);

  const totalSteps = () => {
    return getSteps().length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.stepLabelParent}>
        <Stepper activeStep={activeStep} alternativeLabel nonLinear connector={<ColorlibConnector />}>
          {steps.map((label, index) => {
            return (
              <Step className={classes.step} key={label} >
                <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                <StepButton
                  onClick={handleStep(index)}
                >

                  <Box className={classes.stepLabel}>Step: 1</Box>
                  <Box style={{ fontWeight: 'bold' }}>{label}</Box>
                </StepButton>
              </Step>

            );
          })}
        </Stepper>
      </Box>
      <div>
        {activeStep === steps.length - 1 ? (
          <div>
            <Box>{getStepContent(activeStep)}</Box>
            <Box position="fixed" className={classes.stickyNav}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1.5}>
                  <Box mr={1.5}>
                    <Button variant="outlined" className={classes.mediumGray} style={{ width: 142 }}
                      disabled={activeStep === 0}
                      onClick={() => setShowCancelDialog(true)}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <CancelDialog
                    open={showCancelDialog}
                    setOpen={setShowCancelDialog}
                  />
                  <Box mr={1.5}>
                    <Button variant="outlined" className={classes.mediumGray} style={{ width: 142 }}
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box>
                    <Button variant="contained" color="primary" style={{ width: 145 }} >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        ) : (
          <div>
            <Box>{getStepContent(activeStep)}</Box>
            <Box position="fixed" className={classes.stickyNav}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1.5}>
                  <Box mr={1.5}>
                    <Button variant="outlined" className={classes.mediumGray} style={{ width: 142 }}
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box>
                    <Button variant="contained" color="primary" style={{ width: 145 }} onClick={handleNext}>
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        )}
      </div>
      {/* Change Client Dialog */}
      <ChangeClientDialog
        open={showCSDialog}
        setOpen={setShowCSDialog}
      />
    </div>
  );
}

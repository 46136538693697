import React, { useEffect, useState, useContext } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import { UserLT } from '@cbtravel/common/lib/shared/messages/general/responses/lite/user-lt';
import { UserRS } from '@cbtravel/common/lib/shared/messages/general/responses/user-rs';
import { UserRQ } from '@cbtravel/common/lib/shared/messages/general/requests/user-rq';
import { UserController } from '@cbtravel/common/lib/shared/api/general/controllers/user-controller'
import { UserContext } from './UserContext'
import { useCustomSnackbar } from './customHooks/useCustomSnackbar'
import { JsonException } from '@cbtravel/common/lib/shared/common/exceptions/json-exception'
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";
import { UserSearchType } from "@cbtravel/common/lib/shared/common/enumerations/search-types";
import { UserSortType } from "@cbtravel/common/lib/shared/common/enumerations/sort-types";
import { Configuration } from "@cbtravel/common/lib/shared/config/client-config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      ".MuiAutocomplete-option": {
        display: "block",
      },
      ".MuiAutocomplete-popupIndicatorOpen": {
        transform: "rotate(0)"
      }
    },
    approverPopout: {
      "& .MuiAutocomplete-option": {
        display: "block",
      },
    },
    approverName: {
      fontSize: ".9rem",
      fontWeight: 600,
      display: "block",
    },
    emailAddress: {
      fontSize: ".8rem",
    },
  })
);

/**
 * Properties for the `UserSearchBox` component.
 */
interface UserSearchBoxProps {
  /** Text to display as a label for the `UserSearchBox`. */
  label: string,
  /** The value being held by the `UserSearchBox`. */
  user?: UserRS | UserLT,
  /** Whether or not the `UserSearchBox` should autofocus. */
  focus?: boolean,
  activeSearchType?: ActiveSearchType,
  /** Handler for when the value held by the `UserSearchBox` changes. */
  onChange: (value: UserRS | UserLT | null) => void
}

/**
 * The `UserSearchBox` component is a UI element used for searching for Users.
 * 
 * @param props {@link UserSearchBoxProps Properties} for the UserSearchBox component.
 * @returns A JSX element used for searching for Users.
 */
export default function UserSearchBox({activeSearchType = ActiveSearchType.Active, ...props }: UserSearchBoxProps) {
  const classes = useStyles();
  const { userContext } = useContext(UserContext);
  const snackbar = useCustomSnackbar();

  //state variables
  const [searchResults, setSearchResults] = React.useState<(UserRS | UserLT)[]>([]); //the results of the user search, will be spread into the autocomplete options array
  const [loading, setLoading] = useState<boolean>(false); //whether or not the autocomplete should display its loading settings
  const [searchText, setSearchText] = useState<string>(""); //the text the user has typed into the autocomplete input
  const [currentValue, setCurrentValue] = useState<UserRS | UserLT | null | undefined>(props.user?.userId !== -1 ? props.user : null) // the current value of the autocomplete

  //registers changes in searchText and executes userSearch only after 1.5 seconds of no change so we know the user is done typing
  useEffect(() => {

    if (searchText.length > 2) {
      setLoading(true)

      //don't search until we know the user's done typing (1.5 seconds after they stop)
      const timeOut = setTimeout(function () {
        userSearch(searchText.toLowerCase(), activeSearchType);
      }, Configuration.SearchWaitConfigTime)

      return () => clearTimeout(timeOut)
    }
    else {
      setLoading(false)
    }

  }, [searchText]);

  //for clear filter purpose
  useEffect(() => {
    if (props.user?.userId === -1) {
      setCurrentValue(null);
    }
  }, [props.user]);

  /**
   * Gets the value of what's being typed in the input field for searching for a user, sets the searchText value.
   * 
   * @param event The event that triggers the change (not used, but in the mui signature).
   * @param value The value of the input inside the autocomplete.
   * @param reason The reason for the change "reset" for programmatic change, "input" for user input.
   */
  function handleInputChange(event: object, value: string, reason: string) {
    //only change searchText if the input is changing from user input (not props changing or being cleared)
    if (reason !== "reset") {
      setSearchText(value);
      //when clear the user
      if (value.length === 0) {
        props.onChange(null);
      }
      else if (value.length < 3) {
        // clear this array so that we don't keep results when folks delete search text
        setSearchResults([])
      }
    }
  };

  /**
   * Creates a UserRQ and sends to services to execute a search on first/last name + email address.
   * 
   * @param text The value to search the database with.
   */
  async function userSearch(text: string, activeSearchType:ActiveSearchType) {
    const userRQ = new UserRQ();
    userRQ.activeSearchType = activeSearchType;
    userRQ.skip = 0;
    userRQ.limit = 15;
    userRQ.searchType = UserSearchType.ByAll;
    userRQ.sortType = UserSortType.ByLastName;
    userRQ.wildcard = text;

    try {
      const resultList = await UserController.Find(userContext.accessToken, userRQ);
      setSearchResults(resultList.list)
    } catch (err) {
      snackbar.error(err as JsonException);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Fires when the Autocomplete has an option selected; calls props.onChange then clears searchText and options.
   * 
   * @param event The event that triggers the onSelect  (not used, but in the mui signature).
   * @param value The value of the autocomplete, i.e. the option selected.
   */
  function handleSelect(event: object, value: (UserRS | UserLT) | null, reason: string) {
    setCurrentValue(value)
    value && props.onChange(value);
    setSearchText("");
    setSearchResults(value ? [value] : []);
  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <Autocomplete
          openOnFocus={props.user?.userId !== -1}
          value={currentValue}
          blurOnSelect={true}
          onInputChange={handleInputChange}
          loading={loading}
          // autoSelect
          autoHighlight
          options={currentValue ? [currentValue, ...searchResults] : searchResults} // adding the current value to the options prevents MUI warnings that will display entire objects that sometimes contain sensitive information
          classes={{ option: classes.approverPopout }}
          filterOptions={(options) => options}
          getOptionLabel={(option) => option.emailAddress}
          getOptionSelected={(option: UserRS | UserLT, value: UserRS | UserLT) => option.userId === value.userId || value.userId == -1}
          popupIcon={<SearchOutlinedIcon />}
          renderOption={(option) => (
            <React.Fragment>
              <span className={classes.approverName}>
                {option.firstName} {option.lastName}
              </span>
              <span className={classes.emailAddress}>{option.emailAddress}</span>
            </React.Fragment>
          )}
          fullWidth
          renderInput={(params) => (
            <TextField
              autoFocus={props.focus}
              {...params}
              label={props.label}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                autoComplete: 'off',
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, value, reason) => handleSelect(event, value, reason)}
        />
      </form>
    </React.Fragment>
  );
}

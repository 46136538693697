import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from "react";
import { NavLink, useLocation, useHistory, Link } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { iNavLocationState } from '@cbtravel/common/lib/web/interfaces/iNavLocationState';
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import navLocations from './NavLocations'
import { useCustomSnackbar } from '../../shared/customHooks/useCustomSnackbar';
import { UserContext } from '../../shared/UserContext';
import Can from '../../shared/Can';

import {
  BarChart,
  Build,
  Check,
  ExpandMore as ExpandMoreIcon,
  Flight,
  Home,
  People,
  Settings,
  WarningRounded,
  Work,
} from "@material-ui/icons";
import { iClientSettingsContext } from "@cbtravel/common/lib/shared/interfaces/iClientSettingsContext";
import { ClientSettingsContext } from "../../shared/ClientSettingsContext";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionWrapper: {
      "& a": {
        textDecoration: "none",
      },
      // height: "100%",
      // overflowY: "auto",
      flexGrow: 1,
    },
    accordion: {
      "& .MuiAccordionSummary-root.Mui-expanded": {
        margin: 0,
        minHeight: "48px",
        height: "48px",
        background: "#565A64",
      },
      backgroundColor: "transparent",
      color: "#fff",
      boxShadow: "none",
    },
    accordionDetails: {
      "& .MuiList-padding": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    accordionExpand: {
      // "& .MuiIconButton-edgeEnd": {
      //   marginRight: 0,
      // },
      justifyContent: "unset",
    },
    fa: {
      fontSize: ".9rem",
      height: "auto",
      margin: "0 1px 0 2px",
    },
    iconStyles: {
      "& .svg-inline--fa.fa-w-16": {
        width: "1.25em",
      },
      "& .icon-rotate": {
        transform: "rotate(45deg)",
        fontSize: "1.3rem",
      },
      color: "#fff",
      minWidth: "unset",
    },
    iconSize: {
      fontSize: "1.3rem",
    },
    subnavHeader: {
      paddingLeft: 25,
      "@media screen and (-ms-high-contrast: none)": {
        paddingLeft: 0,
        marginLeft: "-10px",
      },
    },
    subnav: {
      "& .MuiListItem-root.Mui-selected": {
        background: "#00a6cf",
      },
      "& .MuiListItem-root:hover": {
        background: "#00a6cf",
      },
      "& .MuiListItemText-root": {
        margin: 0,
        padding: "2px 8px 2px 46px",
      },

      "& .MuiTypography-body1": {
        color: "#fff",
        fontSize: ".8rem",
        lineHeight: 1.5,
      },

      width: "100%",
    },

  })
);

/**
 * Properties for the `SimpleAccordion` component.
 */
interface SimpleAccordionProps {
  /** React state setter for nav location status. */
  setNavLocation: Dispatch<SetStateAction<iNavLocationState>>
}

/**
 * Component used for the sidebar navigation.
 * 
 * @param props {@link SimpleAccordionProps Properties} for the `SimpleAccordion` component.
 * @returns A JSX element used for navigation within the application.
 */
export default function SimpleAccordion(props: SimpleAccordionProps) {
  const classes = useStyles();

  const snackbar = useCustomSnackbar();
  const { userContext } = useContext(UserContext);
  const { clientSettingsContext } = useContext<iClientSettingsContext>(ClientSettingsContext);
  const routerLocation = useLocation(); //react-router's tool for grabbing url location
  const routeHistory = useHistory();
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [invalidRoute, setInvalidRoute] = useState<string>(""); // stored for the invalid route snackbar-- have to do some song and dance or else it gets batched into the same render that calls snackbar.clear when the page changes

  const noOnlineGroup = 292;
  const noAgentBETAGroup = 355;


  useEffect(() => {
    //changes the location useState inside of <Home> so that we can pass the page title/client selector view to <AppBar>

    // any time we navigate to a new page, clear all existing snackbars.
    snackbar.clear();

    if (navLocations[routerLocation.pathname]) {
      props.setNavLocation(navLocations[routerLocation.pathname]);

    } else {
      routeHistory.replace(Configuration.DefaultLandingPage)
      setInvalidRoute(Configuration.AirPortalV3Url + routerLocation.pathname);
    }

  }, [routerLocation]);

  // this use effect prevents the snackbar that throws when a user going to an invalid route
  // from being batched into the same render that we clear previous snackbars in
  // and ensures we actually see this message for a reasonable amount of time
  useEffect(() => {
    if (invalidRoute) {
      snackbar.info(`${invalidRoute} is not a valid url.`)
      setInvalidRoute("")
    }
  }, [invalidRoute])

  /**
   * Note: if we determine that this is too strict/limiting to match future paths, 
   * look at implementing react-router-dom's useRouteMatch hook to match a path
   * before selecting a location from our locations array.
   * Using query strings will not impact path matching and is perfectly safe.
   * 
   * Docs on how react-router-dom matches paths: https://reactrouter.com/web/api/Route/exact-bool
   * see also:  match, matchPath, and Hooks > useRouteMatch (in the same docs) for actual implementation tools
   */

  /**
   * Event handler for when a list item gets clicked.
   * 
   * @param event Mouse click event.
   * @param index Index of the item in the list.
   * @param url URL to navigate to if linking outside of this application's navigation.
   */
  function handleListItemClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, url: string) {
    setSelectedIndex(index);
    if (url)
      window.location.href = url;
  };

  /**
   * Handler for when an accordian secion gets toggled.
   * @param panel Name of the panel being changed.
   * @returns A function used by the Accordian onChange handler.
   */
  function handleChange(panel: string) {
    return (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  }

  function getObeSsoLink() {
    // Not using the clientSettings because this logic doesn't build out the SAML response required for SSO
    // reverting to using v2 logic for now
    return "/travelrequest/index/online";
    // if (clientSettingsContext?.obeId === 3) {
    //   // ObeId = 3 -- Concur
    //   return Configuration.SingleSignOn.Concur.SignOnUrl;
    // }
    // else if (clientSettingsContext?.obeId === 2) {
    //   // ObeId = 2 -- Deem
    //   return `${Configuration.SingleSignOn.Deem.SignOnUrl}?RelayState=${clientSettingsContext.obeSite}`;
    // }
  }

  return (
    <div className={classes.accordionWrapper}>
      <Can
        role={userContext.userType}
        perform="dashboard-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel0"}
            onChange={handleChange("panel0")}
            id="menuItemDashboards"
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown0"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <Home className={classes.iconSize} />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                Dashboards
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="Trips"
                className={classes.subnav}
              >
                <Can
                  role={userContext.userType}
                  perform="dashboard-manager:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/dashboard/manager" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 1}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 1, "")}
                      id="subMenuItemAirPortal360Manager"
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel0");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    // component={(props) => <NavLink {...props} exact to="/manager" />}
                    // activeClassName="Mui-selected"
                    // isActive={(match: boolean, location: string) => {
                    //   if (!match) {
                    //     return false;
                    //   }
                    //   if (firstLoad) {
                    //     setExpanded("panel0");
                    //     setFirstLoad(false);
                    //   }
                    //   return true;
                    // }}
                    >
                      <ListItemText primary="AirPortal 360&trade; Manager" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="dashboard-agent:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/dashboard/agent" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 0}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 0, "")}
                      id="subMenuItemAirPortal360Agent"
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel0");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="AirPortal 360&trade; Agent" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="dashboard-mytravel:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/dashboard/mytravel" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 2}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 2, "")}
                      id="subMenuItemMyTravelDashboard"
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel0");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="My Travel Dashboard" />
                    </ListItem>
                  )}
                  no={() => null}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />
      <Can
        role={userContext.userType}
        perform="trips-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            id="menuItemTrips"
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown1"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <Flight className="icon-rotate" />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                Trips
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="Trips"
                className={classes.subnav}
              >
                <Can
                  role={userContext.userType}
                  perform="airtinerary-lookup:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/airtinerary/airtineraries/lookup" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 3}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 3, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel1");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Airtinerary&reg; Lookup" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="current-future-trips:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/airtinerary/airtineraries/present" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 4}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 4, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel1");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Current / Future Trips" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="past-trips:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/airtinerary/airtineraries/past" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 5}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 5, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel1");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Past Trips" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="unknownstays:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/hotelattachments/reporting/unknownstays" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 6}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 6, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel1");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Trips Missing Hotel" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="hotel-checkins:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/vpay/hotel/checkins" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 7}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 7, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel1");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Hotel Check-ins" />
                    </ListItem>
                  )}
                  no={() => null}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />
      <Can
        role={userContext.userType}
        perform="book-travel-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            id="menuItemBookTravel"
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown2"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <Work className={classes.iconSize} />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                Book Travel
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="Book Travel"
                className={classes.subnav}
              >
                {userContext.userType === 'Traveler' && clientSettingsContext?.defaultAgentUserGroupId === noAgentBETAGroup ? null : <Can
                  role={userContext.userType}
                  perform="travel-request:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/travelrequest" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 8}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 8, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel2");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Request Agent Booking" />
                    </ListItem>
                  )}
                  no={() => null}
                />}
                <Can
                  role={userContext.userType}
                  perform="approval-request:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        // <NavLink {...props} to="/approvalrequest" />
                        <NavLink
                          {...props}
                          to="/booktravel/approvalrequest"
                        />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 101}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 101, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel2");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Approval Request (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                {userContext.userType === 'Traveler' && clientSettingsContext?.defaultOnlineUserGroupId === noOnlineGroup ? null : <Can
                  role={userContext.userType}
                  perform="manage-online-trips:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <a {...props} href={getObeSsoLink()} target="_blank" rel="noopener noreferrer"/>
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 9}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 9, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel2");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Book/Manage Online Trips" />
                    </ListItem>
                  )}
                  no={() => null}
                />}
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />
      <Can
        role={userContext.userType}
        perform="travel-approval-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            id="menuItemTravelApproval"
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown4"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <Check className={classes.iconSize} />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                Travel Approval
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="Travel approval"
                className={classes.subnav}
              >
                <Can
                  role={userContext.userType}
                  perform="airtinerary-approval:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/airtinerary/approval" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 14}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 14, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel4");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Pending Trip Approvals" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="airtinerary-approved:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/airtinerary/approval/approved" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 15}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 15, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel4");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Approved Trips" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="airtinerary-canceled:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/travelapproval/canceledtrips" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 16}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 16, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel4");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Canceled Trips" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="approval-beta:visit"
                  yes={() => (
                    <ListItem
                      button
                      // component={(props) => <NavLink {...props} to="/approvals" />}
                      component={(props) => (
                        <NavLink {...props} to="/travelapproval/approvals" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 102}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 102, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel4");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Approvals (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />

      <Can
        role={userContext.userType}
        perform="risk-management-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            id="menuItemRiskManagement"
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown5"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <WarningRounded className={classes.iconSize} />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                Risk Management
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="Risk Management"
                className={classes.subnav}
              >
                <Can
                  role={userContext.userType}
                  perform="securitylogic-map:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/securitylogic/map" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 17}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 17, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel5");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="SecurityLogic&reg; Map" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="securitylogic-beta:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/securitylogic/report/insight" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 18}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 18, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel5");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="SecurityLogic&reg; Map Reporting Beta" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="securitylogic-assistance:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/securitylogic/assistance" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 19}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 19, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel5");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Assistance Requests" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="securitylogic-alerts:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/securitylogic/travelalerts" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 20}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 20, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel5");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Travel Alerts" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="securitylogic-notifcations:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/securitylogic/notifications" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 21}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 21, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel5");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Notifications" />
                    </ListItem>
                  )}
                  no={() => null}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />

      <Can
        role={userContext.userType}
        perform="reporting-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            id="menuItemReporting"
          >
            {/* Reporting */}
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown6"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <BarChart className={classes.iconSize} />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                Reporting
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="Reporting"
                className={classes.subnav}
              >
                <Can
                  role={userContext.userType}
                  perform="unused-tickets:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/reporting/unusedtickets" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 100}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 100, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel6");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                      id="subMenuItemUnusedTickets"
                    >
                      <ListItemText primary="Unused Tickets" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="savingstracker:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/savingstracker/index2" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 24}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 24, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel6");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Savings Tracker" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="savingstracker-scorecard:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/savingstracker/scorecard" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 25}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 25, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel6");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Value Scorecard" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="vpay-lookup:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/vpay/lookup" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 26}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 26, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel6");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Virtual Pay Lookup" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="domo:visit"
                  yes={() => (
                    // <a href="https://cbtravel.domo.com" target="_blank">                        
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/domo" />
                      )}
                      selected={selectedIndex === 27}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 27, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel6");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Domo" />
                    </ListItem>
                    // </a>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="ibank:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/ibank" />
                      )}
                      selected={selectedIndex === 28}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 28, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel6");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="IBank" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="primeanalytics:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/manager/travelgpa" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 29}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 29, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel6");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Prime Analytics" />
                    </ListItem>
                  )}
                  no={() => null}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />

      <Can
        role={userContext.userType}
        perform="company-admin-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            id="menuItemCompanyAdmin"
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown7"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <Build className={classes.iconSize} />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                Company Admin
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="Company Admin"
                className={classes.subnav}
              >
                <Can
                  role={userContext.userType}
                  perform="client-information:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/information" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 30}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 30, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Company Information" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="configure-approval:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink
                          {...props}
                          to="/companyadmin/configureapprovaltypes"
                        />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 103}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 103, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Configure Approval Types (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-people:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/people" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 31}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 31, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="People" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="user-search:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/companyadmin/users" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 77}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 77, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Users (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                 <Can
                  role={userContext.userType}
                  perform="incomplete-profiles:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/companyadmin/incompleteprofiles" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 78}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 78, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Incomplete Profiles" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                  {/* <Can
                  role={userContext.userType}
                  perform="hrfeedupload:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/companyadmin/hrfeedupload" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 78}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 78, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="HR feed upload (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                /> */}
                <Can
                  role={userContext.userType}
                  perform="client-contracts:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/contracts/air" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 32}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 32, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Vendor Contracts" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-people-contacts:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/people/contacts" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 33}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 33, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Travel Agency Contacts" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="helpdesk:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/helpdesk" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 34}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 34, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Help Desk" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="travelpolicy:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/travelpolicy" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 35}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 35, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Travel Policy" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-servicesfees-payment:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/servicefees/payment" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 36}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 36, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Forms of Payment" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-servicesfees:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/servicefees" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 37}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 37, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Service Fees" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="reporting-fields:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/companyadmin/reportingfields" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 38}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 38, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Reporting Fields (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-report:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/report" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 39}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 39, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Report Settings" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-technology-general:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/technology/general" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 40}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 40, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Technology Settings" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-airtinerary:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/airtinerary" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 41}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 41, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Airtinerary&reg; Settings" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-vpayhotel-card:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/vpayhotel/card" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 42}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 42, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Virtual Pay Settings" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-consult:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/consult" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 45}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 45, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Consultative Services" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-notes:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/notes" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 46}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 46, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Notes" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-log:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/log" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 48}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 48, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel7");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Activity Log" />
                    </ListItem>
                  )}
                  no={() => null}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />
      {/* Company admin */}

      <Can
        role={userContext.userType}
        perform="employee-tools-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
            id="menuItemEmployeeTools"
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown8"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <People className={classes.iconSize} />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                Employee Tools
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="Employee Tools"
                className={classes.subnav}
              >
                <Can
                  role={userContext.userType}
                  perform="admin-search:visit"
                  yes={() => (
                    <ListItem
                      button
                      selected={selectedIndex === 49}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 49, "")}
                    >
                      <a title="airpotal search" href="#airportal-search">
                        <ListItemText primary="Admin Search (CTRL + S)" />
                      </a>
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="agents-search:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/agents/search" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 50}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 50, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Agent Lookup" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="airtinerary-agent-approval-mytasks:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/airtinerary/agentapproval/mytasks" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 51}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 51, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Agent Tasks" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="agents-team:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/agents/team" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 52}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 52, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Advisor Teams" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="andavo-admin:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/andavo/admin" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 53}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 53, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="AndavoVacations Admin" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="employee-carrier-contracts:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/employee/carriercontracts2" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 54}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 54, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Carrier Contracts" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="client-directory:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/client/clientdirectory" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 55}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 55, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Client Management" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="employee-directory:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/employee/directory" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 56}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 56, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Employee Directory" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="gds-scripts:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/gdsscripts" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 57}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 57, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="GDS Scripts" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="helpdesk:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/helpdesk" />
                      )}
                      selected={selectedIndex === 58}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 58, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Help Desk" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="tmu:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/employee/index/forwardtotmu" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 60}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 60, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="TMU" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="commissions-statement-collected:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/commissions/statement/collected" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 61}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 61, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Vendor Commissions" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="commissions-unclaimed:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/commissions/unclaimed" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 62}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 62, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel8");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Unclaimed Commissions" />
                    </ListItem>
                  )}
                  no={() => null}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />

      <Can
        role={userContext.userType}
        perform="admin-menu:visit"
        yes={() => (
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
            id="menuItemAirPortalAdmin"
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon className={classes.iconStyles} />}
              aria-controls="dropdown9"
              className={classes.accordionExpand}
            >
              <ListItemIcon className={classes.iconStyles}>
                <Settings className={classes.iconSize} />
              </ListItemIcon>
              <Typography variant="caption" className={classes.subnavHeader}>
                AirPortal&reg; Admin
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List
                component="nav"
                aria-label="AirPortal&reg; Admin"
                className={classes.subnav}
              >
                <Can
                  role={userContext.userType}
                  perform="airtinerary-import:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/airtinerary/import" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 65}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 65, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Airtinerary&reg; Utilities" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                {/* <Can
                  role={userState.userType}
                  perform="vendors:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink
                          {...props}
                          to="/airportaladmin/vendors"
                        />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 106}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 106, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Vendors (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                /> */}
                {/* <Can
                  role={userState.userType}
                  perform="locations:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink
                          {...props}
                          to="/airportaladmin/locations"
                        />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 107}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 107, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Locations (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                /> */}
                {/* <Can
                  role={userState.userType}
                  perform="reference-data:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink
                          {...props}
                          to="/airportaladmin/referencedata"
                        />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 108}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 108, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Reference Data (Beta)" />
                    </ListItem>
                  )}
                  no={() => null}
                /> */}
                
                <Can
                  role={userContext.userType}
                  perform="vendor-profiles-airvendor:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/vendorprofiles/airvendor" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 66}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 66, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Vendor Profiles" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="location-profiles-airport:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/locationprofiles/airport" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 67}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 67, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Location Profiles" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="service-profiles-aircraft:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/serviceprofiles/aircraft" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 68}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 68, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Service Profiles" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="office:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/office" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 69}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 69, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Office Management" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="tmc:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/tmc" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 70}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 70, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="TMC Management" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="permissions:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/permissions/permission2" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 74}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 74, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Permissions" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="sap:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/sap2" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 75}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 75, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="SAP" />
                    </ListItem>
                  )}
                  no={() => null}
                />
                <Can
                  role={userContext.userType}
                  perform="queue-manager:visit"
                  yes={() => (
                    <ListItem
                      button
                      component={(props) => (
                        <NavLink {...props} to="/qmanager2" />
                      )}
                      activeClassName="Mui-selected"
                      selected={selectedIndex === 76}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleListItemClick(event, 76, "")}
                      isActive={(match: boolean, location: string) => {
                        if (!match) {
                          return false;
                        }
                        if (firstLoad) {
                          setExpanded("panel9");
                          setFirstLoad(false);
                        }
                        return true;
                      }}
                    >
                      <ListItemText primary="Queue Manager" />
                    </ListItem>
                  )}
                  no={() => null}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        no={() => null}
      />
    </div>
  );
}

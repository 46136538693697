import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Box, Grid, TextField, Dialog, IconButton, TableHead, TableRow, TableCell, Table, InputAdornment, FormControl, FormControlLabel, FormLabel, RadioGroup, MenuItem } from "@material-ui/core";
import { CloseIcon } from '../../../icons/Icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { green } from '@material-ui/core/colors';
import { useDropzone } from 'react-dropzone';
import LoopIcon from '@material-ui/icons/Loop';
import BulkImportIcon from '../../../icons/BulkImportIcon';
import { CustomFieldFormInputs, listValueFields } from '../ReportingFields/CustomFieldForm';
import { UseFieldArrayReturn, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import clsx from "clsx";
import Radio from '@material-ui/core/Radio';
import { BorderBottom } from '@material-ui/icons';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    formControl: {
      "& .MuiFormLabel-root": {
        fontSize: ".9rem",
        fontWeight: 600,
        color: "#4d4d4d"
      },
      "& .MuiFormControlLabel-label": {
        fontSize: ".9rem",
        color: "#4d4d4d"
      },
    },
    divider: {
      border: "1px solid #ddd",
    },
    stepLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    pointer: {
      cursor: 'pointer',
    },
    floatRight: {
      float: 'right',
    },
    tableTitle: {
      fontSize: '14px',
      color: '#4d4d4d',
      fontWeight: 'bold',
    },
    table: {
      minWidth: 400,
      border: '1px solid #C9C7C7',
      width: "100%",
      "& a": {
        color: "#00467E",
        textDecoration: "none",
      },
      "& .MuiTableCell-head": {
        fontWeight: 700,
        lineHeight: 0,
      },
      "& .MuiTableCell-head.MuiTableCell-sizeSmall": {
        padding: "5px 0px 5px 10px",
        whiteSpace: "nowrap",
      },
      "& .MuiTableCell-sizeSmall": {
        padding: "5px 0px 5px 10px",
        marginTop: "2px",
        borderRight: '1px solid #c9c7c7'
      },
      "& .moreInfoTable .MuiTableCell-sizeSmall": {
        padding: "8px",
      },
      "& .MuiIconButton-sizeSmall": {
        padding: 0,
      },
      "& .MuiIconButton-root": {
        padding: '0px',
      },
    },
    center: {
      textAlign: 'center',
    },
    th: {
      "& .MuiIconButton-root": {
        padding: '0px',
      }
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    flexGrow1: {
      flexGrow: 1,
    },
    flexGrow3: {
      flexGrow: 3,
    },
    flexRowRight: {
      display: "flex",
      flexDirection: "row",
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      fontSize: '14px',
    },
    dropArea: {
      border: '1px dotted #c9c7c7',
      width: '100%',
      height: '284px',
      marginTop: '20px',
    },
    dropAreaActive: {
      border: '1px dotted #00467e',
      width: '100%',
      height: '284px',
      marginTop: '20px',
    },
    dropHeader: {
      color: '#1F2532',
      textAlign: 'center',
      fontSize: '18px',
    },
    dropText: {
      color: '#4d4d4d',
      textAlign: 'center',
      fontSize: '14px',
      lineHeigt: '16px',
    },
    validation: {
      paddingTop: '5px',
    }
  }),

);



export default function UploadFile() {
  const classes = useStyles();
  const [uploadError, setUploadError] = useState<string>();
  const [fileUpload, setFileUpload] = useState<File>();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadValues, setUploadValues] = useState<Array<listValueFields>>([]);




  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".csv",
    multiple: false,
    noClick: true,
    maxSize: 1000 * 1000,
    onDrop: acceptedFiles => { onChangeFile(acceptedFiles[0]) },
    onDropRejected: rejected => {
      setFileUpload(undefined);
      setUploadValues([]);
      setUploadError(rejected[0].errors[0].message)
    }
  });


  //Set default value for delimiter
  const [delimiter, setdelimiter] = React.useState('comma');
  const handleDelimeter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setdelimiter(event.target.value);
  };

  //Set show wrapper selection based on user selection yes/no
  const [wrapperValue, setWrapperValue] = React.useState('');
  const handleWrapperValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWrapperValue((event.target as HTMLInputElement).value);
  };
  //Set default value for column wrapper
  const [wrapper, setWrapper] = React.useState('doubleQ');
  const handleWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWrapper(event.target.value);
  };

  //Set show pgp selection based on user selection yes/no
  const [PGPvalue, setPGPValue] = React.useState('');
  const handlePGP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPGPValue((event.target as HTMLInputElement).value);
  };




  /**
   * Clears any state related to a file upload.
   */
  function clearFile() {
    setFileUpload(undefined);
    setUploadValues([]);
    setUploadError(undefined);
  }
  /**
      * When the file is changed, verifies that base criteria is met before
      * updating the state.
      * 
      * @param file The file being uploaded.
      */
  function onChangeFile(file: File | undefined) {

    if (file) {
      // Only accept csv file extensions
      if (file.name.toLowerCase().indexOf(".csv") < 0) {
        setUploadError("CSV files only");
        setFileUpload(undefined);
        setUploadValues([]);
      }
      // Only accept files less than 1MB in size
      else if (file.size > 1000 * 1000) {
        setUploadError("File must be less than 1MB");
        setFileUpload(undefined);
        setUploadValues([]);
      } else {
        setFileUpload(file);
        setUploadValues([]);
        setUploadError(undefined);
      }
    }
  }


  /**
   * When browse computer is clicked. Reset the value 
   * @param event 
   */
  function onClickFile(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    event.currentTarget.value = ""; // reset value of filepath to allow submission of the same filename. 
  }

  return (
    <>
      <Box pt={4}>
        <Typography className={classes.stepLabel}>Step 1</Typography>
        <Typography>Upload a File</Typography>
        <Box mt={1} />
        <Typography variant='body2'>Download our recommended template (.csv or .txt) to ensure a successful upload. </Typography>
      </Box>
      <Box>
        <Box {...getRootProps({ className: (isDragActive ? classes.dropAreaActive : classes.dropArea) })}>
          <Box mt={3} style={{ textAlign: 'center', }}>
            <BulkImportIcon width='44px' height='53px' color='#00467F' />
          </Box>
          <Box className={classes.center} mt={1}>
            <Typography className={classes.dropHeader}>
              Drag and drop your file here
            </Typography>
            <Box mt={.5}>
              <Typography className={classes.dropHeader}>
                OR
              </Typography>
            </Box>
            <Box mt={2}>
              <Button variant="outlined" color="primary" component="label">
                Browse computer
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  {...getInputProps()}
                  onChange={(e) => onChangeFile(e.target.files?.[0])}
                  onClick={(e) => onClickFile(e)}
                />
              </Button>
            </Box>
            <Box mt={2}>
              <Typography className={classes.dropText}>
                Only supports CSV or TXT files
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.flexRow} mt={2}>
          <TextField
            size="small"
            id="outlined-basic"
            placeholder="example-upload.csv"
            value={fileUpload?.name || ''}
            variant="outlined"
            fullWidth
            error={uploadError !== undefined}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <React.Fragment>

                  {uploadInProgress &&
                    <InputAdornment position="end">
                      <IconButton disableRipple={true} color="default" aria-label="search" style={{ paddingRight: '0px', backgroundColor: 'transparent', }} >
                        <LoopIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  {/* NOTE: This Loop icon will appear while importing and will disappear when done importing.  */}

                
                  <InputAdornment position="end">
                    <IconButton onClick={clearFile} disableRipple={true} color="default" aria-label="search" style={{ paddingRight: '0px', paddingLeft: '0px', backgroundColor: 'transparent', }} >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                  {/* Note: The clearIcon will be used to clear the text field. */}
                </React.Fragment>
              ),
            }}
          />
                   
        </Box>
        <Box>
        {
                    (fileUpload && !uploadError) &&
            <>
              <Box mt={2} display="flex" flexDirection="row" > 
              <Box><CheckIcon style={{ color: green[500], marginRight: 2, }} /></Box><Box><Typography variant="body1" style={{ color: green[500], }}>Success!</Typography> </Box>
                      </Box>
                       </>   
                   
                  }
                  {/* Note: The checkIcon will appear after and will indicate to the user that import is complete. */}


        </Box>
        {uploadError &&
          <Box className={classes.flexRow}>
            <Typography className={classes.validation} variant='body2' color='error'>
              {uploadError}
            </Typography>

          </Box>
        }

      </Box>
      <Box mt={3}>
        <hr className={classes.divider} />
      </Box>
      <Box mt={2}>
        <Typography variant="h2">Delimiter</Typography>
        <Box mt={1}>
          <Typography style={{ lineHeight: '1.5' }} variant='body2'>A delimiter is a character that separates text strings. The comma (,) is the most common delimiter.  </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Grid lg={3}>
          <TextField
            id="delimiter"
            fullWidth
            select
            label="Select Delimiter"
            value={delimiter}
            onChange={handleDelimeter}
            variant="outlined"
          >
            {delimiters.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Box>
      <Box mt={3}>
        <hr className={classes.divider} />
      </Box>
      <Box mt={2}>
        <Typography variant="h2">Column Wrapper</Typography>
        <Grid lg={8}>
          <Box mt={1}>
            <Typography style={{ lineHeight: '1.5' }} variant='body2'>A column wrapper is a character that encloses a single field value that may contain commas or other special characters. The double quotatation mark (“ “) is the most common type of column wrapper. <strong>Example: </strong>“Velma R. Dinkley, PhD”  </Typography>
          </Box>
        </Grid>
        <Box mt={3}>
          <FormControl className={classes.formControl} component="fieldset">
            <FormLabel component="legend">Does the file use a column wrapper?</FormLabel>
            <RadioGroup defaultValue="No" aria-label="PGP" name="customized-radios" onChange={handleWrapperValue}>
              <Box display="flex" flexDirection="row">
                <FormControlLabel value="YesColumnWrapper" control={<Radio />} label="Yes" />
                <FormControlLabel value="NoColumnWrapper" control={<Radio />} label="No " />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      {wrapperValue === "YesColumnWrapper" &&
        <Box my={3}>
          <Grid lg={3}>
            <TextField
              id="column-wrapper"
              fullWidth
              select
              label="Select Wrapper"
              value={wrapper}
              onChange={handleWrapper}
              variant="outlined"
            >
              {columnWrappers.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Box>
      }
      <Box my={2}>
        <hr className={classes.divider} />
      </Box>
      <Box my={2}>
        <Typography variant="h2">Security</Typography>
        <Box className={classes.formControl} mt={1}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Does the file use PGP (Pretty Good Privacy) encryption? </FormLabel>
            <RadioGroup defaultValue="No" aria-label="PGP" name="customized-radios" onChange={handlePGP}>
              <Box display="flex" flexDirection="row">
                <FormControlLabel value="YesPrivateKey" control={<Radio />} label="Yes" name="yesno" id="yesCheck"
                />
                <FormControlLabel value="NoPrivateKey" control={<Radio />} label="No " />
              </Box>
            </RadioGroup>
          </FormControl>

        </Box>
      </Box>
      {PGPvalue === "YesPrivateKey" &&
        <Box mt={3} display="flex" flexDirection="row">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                id="privateKey"
                label="Private Key"
                multiline
                rows={4}
                // defaultValue="Default Value"
                variant="outlined"
              /></Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                id="password"
                label="Password"
                multiline
                rows={4}
                // defaultValue="Default Value"
                variant="outlined"
              /></Grid>
          </Grid>
        </Box>}
      <Box my={20} />

    </>
  );
}
const columnWrappers = [
  {
    value: 'doubleQ',
    label: '“ “  double quotes',
  },
  {
    value: 'singleQ',
    label: '‘ ‘   single quotes',
  },
  {
    value: 'brackets',
    label: '{ }  brackets',
  },
]
const delimiters = [
  {
    value: 'comma',
    label: ',  comma',
  },
  {
    value: 'semicolon',
    label: ';  semicolon ',
  },
  {
    value: 'pipe',
    label: '| pipe',
  },
  {
    value: 'backward',
    label: '\ backward slash',
  },
  {
    value: 'forward',
    label: '/ forward slash',
  },
]


import { UserController } from '@cbtravel/common/lib/shared/api/general/controllers/user-controller';
import { UserChangePasswordRQ } from '@cbtravel/common/lib/shared/messages/general/requests/user-change-password-rq';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid, IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper, Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CheckCircle as CheckIcon, Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { useCustomSnackbar } from '../components/shared/customHooks/useCustomSnackbar';
import Spinner from '../components/shared/Spinner';
import VerificationDialog from "../components/ui/Dialog/VerificationDialog";
import { CloseIcon } from '../icons/Icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      "#pendo-image-badge-5c3c4f83": {
        display: "none !important",
      },
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "red",
    },
    title: {
      flexGrow: 1,
      paddingTop: "2px",
    },
    sidebar: {
      height: "100%",
      minWidth: "300px",
      width: "300px",
      position: "relative",
      zIndex: 1009,
    },
    topBanner: {
      backgroundColor: '#1f2532',
    },
    content: {
      flexGrow: 1,
      overflowY: "auto",
      minHeight: "100vh",
      width: "100%",
      background: "#fff",
    },
    logo: {
      height: 24,
    },
    iconography: {
      height: 150,
    },
    sidebarFooter: {
      backgroundImage: "url(../../img/enroll/bg-wave.png)",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "50%",
      backgroundPositionY: "calc(bottom)",
      backgroundSize: "auto",
      height: 530,
      color: "#fff",
      "& .MuiTypography-body1": {
        color: "#fff",
      },
    },
    root: {
      "& .MuiButton-containedSecondary": {
        color: "#fff",
        padding: "6px 20px 9px",
      },
      "& .MuiGrid-container": {
        margin: "0 !important",
      },
      "& .MuiGrid-grid-xs-12": {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      "& .MuiTypography-body2": {
        lineHeight: '1.5',
      },
      "& .MuiTypography-h1": {
        lineHeight: '1.3',
        fontSize: '30px',
        fontWeight: 300,
      },
    },
    passwordInput: {
      width: "100%",
      "& .MuiIconButton-root": {
        padding: "6px",
      },
      "& .MuiIconButton-edgeEnd": {
        marginTop: 0,
        marginRight: "-10px",
      },
      "& .MuiSvgIcon-root": {
        width: "1em",
        height: "1em",
        fontSize: "1.2rem",
      },
    },
    validationGuidelines: {
      color: "#808080",
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
      "& .MuiSvgIcon-root": {
        fontSize: ".8rem",
        marginRight: "3px",
        marginBottom: "-3px",
      },
    },
    validationFailed: {
      color: "#C9C7C7",
    },
    validationPassed: {
      color: "#00a6cf",
    },
    createPassword: {

    },
    createPasswordHidden: {
      display: "none"
    },
    passwordSuccess: {
      "& .MuiTypography-colorPrimary": {
        color: "#fff",
      },
      "& .MuiLink-underlineHover:hover": {
        textDecoration: "none",
      },
    },
    passwordSuccessHidden: {
      display: "none",
      "& .MuiTypography-colorPrimary": {
        color: "#fff",
      },
      "& .MuiLink-underlineHover:hover": {
        textDecoration: "none",
      },
    },
    dialogPadding: {
      "& .MuiDialogContent-root:first-child": {
        paddingTop: 0,
      },
      "& .MuiTypography-h1": {
        lineHeight: '1.3',
      },
      "& .MuiTypography-body2": {
        lineHeight: '1.5',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

type FormInputs = {
  Password: string,
  ConfirmPassword: string,
}

interface urlMetadata {
  token: string,
  firstName: string,
  lastName: string,
  email: string,
  expirationDate: Date,
  phone: string,
}

export default function ChangePassword() {
  const classes = useStyles();
  const [showVerificationDialog, setShowVerificationDialog] = useState<boolean>(false); //whether or not the dialog displays for single approve/deny
  const [verificationRequest, setVerificationRequest] = useState<UserChangePasswordRQ>();
  const snackbar = useCustomSnackbar();
  const [urlMetadata, setUrlMetadata] = useState<urlMetadata>({
    token: "",
    firstName: "",
    lastName: "",
    email: "",
    expirationDate: new Date(),
    phone: "",
  });
  const [passwordVisiblity, setPasswordVisiblity] = useState<boolean>(false);
  const [firstLetterPressedPassword, setFirstLetterPressedPassword] = useState<boolean>(false);
  const [firstLetterPressedConfirmPassword, setFirstLetterPressedConfirmPassword] = useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const routerHistory = useHistory();


  /** Close cancel dialog  */
  function handleCloseCancelDialog() {
    setOpenCancelDialog(false)
  }

  /** Display cancel dialog  */
  function handleOpenCancelDialog() {
    setOpenCancelDialog(true)
  }

  /** close and clear form */
  function handleCancel() {
    setOpenCancelDialog(false);
    reset({
      Password: "",
      ConfirmPassword: ""
    });
  }

  const {
    formState: { errors },
    reset,
    control,
    getValues
  } = useForm<FormInputs>({ criteriaMode: "all", mode: "onChange" });

  useEffect(() => {
    let metaData = "";

    const search = window.location.search;
    let token = null;
    const tokenStart = search.indexOf("token=") + 6;
    const metaDataStart = search.indexOf("metadata=") + 9;
    const tokenEnd = search.indexOf("&", tokenStart);

    let metaDataEncoded = search.substring(metaDataStart, search.length);
    tokenEnd != -1
      ? (token = search.substring(tokenStart, tokenEnd))
      : (token = search.substring(tokenStart));

    if (metaDataEncoded !== null) {
      metaData = atob(metaDataEncoded);
    } else {
      snackbar.info("No metadata found.");
    }

    if (token !== null) {
      //metadata stores firstname, lastname, email, and phone all in one string with double pipes to seperate each element.
      const metaDataArr = metaData.split("||");
      setUrlMetadata({
        token: token,
        firstName: metaDataArr[0],
        lastName: metaDataArr[1],
        email: metaDataArr[2],
        expirationDate: new Date(metaDataArr[3] + " GMT"),
        phone: metaDataArr[4],
      });

      if (new Date(metaDataArr[3] + " GMT").getTime() < new Date().getTime()) {
        UserController.HandleTokenExpiration(metaDataArr[2]);
      }
    } else {
      snackbar.info("Email token not found.");
    }
  }, []);

  /**
   * Handles opening the verification dialog.
   */
  function handleOpenVerificationDialog() {
    let request = new UserChangePasswordRQ();
    request.emailAddress = urlMetadata.email;
    request.userToken = urlMetadata.token;
    request.newPassword = getValues("Password");
    request.mobilePhoneNumber = urlMetadata.phone;

    setVerificationRequest(request);
    setShowVerificationDialog(true);
  }

  /**
   * Toggles the masking of password characters.
   */
  function handleClickShowPassword() {
    setPasswordVisiblity(!passwordVisiblity);
  }

  /** 
   * Handles the mouse down event when click the password visiblity icon.
   */
  function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }

  function checkPasswordMatch() {
    if (getValues("ConfirmPassword") === getValues("Password")) {
      setPasswordsMatch(true);
      return true;
    } else {
      setPasswordsMatch(false);
      return false;
    }
  }

  return (
    <React.Fragment>
      <div className="app">

        <div className={classes.content}>
          <div className={classes.root}>
            <div className={classes.topBanner}>
              <Box display="flex" flexDirection="row" py={2.5} ml={2}>
                <img
                  src={`${process.env.PUBLIC_URL}/img/Christopherson_logo_rev.png`}
                  className={classes.logo}
                  id="logo"
                />
              </Box>
            </div>
            <div className={classes.createPassword}>

              {/* Display different components based on expirationDate */}
              {urlMetadata.expirationDate.getTime() > new Date().getTime() ?
                <>
                  <Grid container>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography variant="h1">
                            <Box pt={5}>
                              Create a password
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box mt={4}>
                                <FormControl
                                  className={classes.passwordInput}
                                  variant="outlined"
                                >
                                  <InputLabel
                                    htmlFor="outlined-adornment-password"
                                    shrink={true}
                                  >
                                    Password
                                  </InputLabel>
                                  <Controller
                                    name="Password"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      validate: {
                                        minLen: (val: string) => {
                                          return /.{8,}/g.test(val);
                                        },
                                        lowerCase: (val: string) => {
                                          return /[a-z]/g.test(val);
                                        },
                                        upperCase: (val) => {
                                          return /[A-Z]/g.test(val);
                                        },
                                        number: (val) => {
                                          return /[\d]/g.test(val);
                                        },
                                        specialChar: (val) => {
                                          return /[-@#\!$%^&*()_+|~=`{}[\]:";'<>?,.\\\/]/g.test(
                                            val
                                          );
                                        },
                                        firstOrLastName: (val) => {
                                          return !(
                                            val
                                              .toUpperCase()
                                              .includes(urlMetadata.firstName.toUpperCase()) ||
                                            val
                                              .toUpperCase()
                                              .includes(urlMetadata.lastName.toUpperCase())
                                          );
                                        },
                                        emailOrUsername: (val) => {
                                          return !val
                                            .toUpperCase()
                                            .includes(
                                              urlMetadata.email.split("@")[0].toUpperCase()
                                            );
                                        },
                                      },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                      <OutlinedInput
                                        id="create-password"
                                        type={passwordVisiblity ? "text" : "password"}
                                        value={value}
                                        onChange={(val) => {
                                          setFirstLetterPressedPassword(true);
                                          onChange(val);
                                          checkPasswordMatch();
                                        }}
                                        notched
                                        margin="dense"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {passwordVisiblity ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        onBlur={() => {
                                          checkPasswordMatch();
                                        }}
                                        labelWidth={70}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Box>
                              <Box my={2}>
                                <FormControl
                                  className={classes.passwordInput}
                                  variant="outlined"
                                >
                                  <InputLabel
                                    htmlFor="outlined-adornment-password"
                                    shrink={true}
                                  >
                                    Confirm Password
                                  </InputLabel>
                                  <Controller
                                    name="ConfirmPassword"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      validate: {
                                        minLen: (val: string) => {
                                          return /.{8,}/g.test(val);
                                        },
                                        lowerCase: (val: string) => {
                                          return /[a-z]/g.test(val);
                                        },
                                        upperCase: (val) => {
                                          return /[A-Z]/g.test(val);
                                        },
                                        number: (val) => {
                                          return /[\d]/g.test(val);
                                        },
                                        specialChar: (val) => {
                                          return /[-@#\!$%^&*()_+|~=`{}[\]:";'<>?,.\\\/]/g.test(
                                            val
                                          );
                                        },
                                        firstOrLastName: (val) => {
                                          return !(
                                            val
                                              .toUpperCase()
                                              .includes(urlMetadata.firstName.toUpperCase()) ||
                                            val
                                              .toUpperCase()
                                              .includes(urlMetadata.lastName.toUpperCase())
                                          );
                                        },
                                        emailOrUsername: (val) => {
                                          return !val
                                            .toUpperCase()
                                            .includes(
                                              urlMetadata.email.split("@")[0].toUpperCase()
                                            );
                                        },
                                      },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                      <OutlinedInput
                                        id="create-password"
                                        type={passwordVisiblity ? "text" : "password"}
                                        value={value}
                                        onChange={(val) => {
                                          setFirstLetterPressedConfirmPassword(true);
                                          onChange(val);
                                          checkPasswordMatch()
                                        }}
                                        notched
                                        margin="dense"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {passwordVisiblity ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        labelWidth={135}
                                        fullWidth
                                        onBlur={() => {
                                          checkPasswordMatch();
                                        }}
                                      />

                                    )}
                                  />
                                </FormControl>
                              </Box>
                              <Box my={2} fontSize={12}>
                                <Grid
                                  container
                                  alignContent="flex-end"
                                  className={classes.validationGuidelines}
                                >
                                  <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <Box mb={1}>
                                      <CheckIcon
                                        className={
                                          (firstLetterPressedPassword || firstLetterPressedConfirmPassword) && !errors.Password?.types?.minLen
                                            ? classes.validationPassed
                                            : classes.validationFailed
                                        }
                                      />
                                      8 characters minimum
                                    </Box>
                                    <Box>
                                      <CheckIcon
                                        className={
                                          (firstLetterPressedPassword || firstLetterPressedConfirmPassword) && !errors.Password?.types?.number
                                            ? classes.validationPassed
                                            : classes.validationFailed
                                        }
                                      />
                                      1 number
                                    </Box>
                                  </Grid>
                                  <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <Box mb={1}>
                                      <CheckIcon
                                        className={
                                          (firstLetterPressedPassword || firstLetterPressedConfirmPassword) &&
                                            !errors.Password?.types?.lowerCase
                                            ? classes.validationPassed
                                            : classes.validationFailed
                                        }
                                      />
                                      1 lowercase character
                                    </Box>
                                    <Box>
                                      <CheckIcon
                                        className={
                                          (firstLetterPressedPassword || firstLetterPressedConfirmPassword) &&
                                            !errors.Password?.types?.upperCase
                                            ? classes.validationPassed
                                            : classes.validationFailed
                                        }
                                      />
                                      1 uppercase character
                                    </Box>
                                  </Grid>
                                  <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box>
                                      <CheckIcon
                                        className={
                                          (firstLetterPressedPassword || firstLetterPressedConfirmPassword) &&
                                            !errors.Password?.types?.specialChar
                                            ? classes.validationPassed
                                            : classes.validationFailed
                                        }
                                      />
                                      1 symbol (e.g. !@#$%^&*)
                                    </Box>
                                    <Box>
                                      <CheckIcon
                                        className={
                                          (firstLetterPressedPassword || firstLetterPressedConfirmPassword) &&
                                            (!errors.Password?.types?.firstOrLastName && !errors.Password?.types?.emailOrUsername)
                                            ? classes.validationPassed
                                            : classes.validationFailed
                                        }
                                      />
                                      Doesn't contain name or email address
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                              <VerificationDialog
                                openActionDialog={showVerificationDialog}
                                setOpenActionDialog={setShowVerificationDialog}
                                positiveText="Verify and enroll"
                                request={verificationRequest}
                                successAction={() => {
                                  routerHistory.push('/user/profile');
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Box display="flex" flexDirection="column" pr={2}>
                            <Paper>
                              <Box className={classes.instructions} p={2}>
                                <Box mt={1}>
                                  <Typography variant="body2">
                                    Create a password, then click “Submit and log in” to log in to AirPortal and complete your travel profile.
                                  </Typography>
                                </Box>
                              </Box>
                            </Paper>
                            {/* Save and cancel buttons here. */}
                            <Box id="box_1" mt={10}>
                              <Button fullWidth variant="contained" color="primary" id="saveButton"
                                disabled={!firstLetterPressedPassword || !firstLetterPressedConfirmPassword || errors.Password || errors.ConfirmPassword || !passwordsMatch
                                  ? true
                                  : false}
                                onClick={handleOpenVerificationDialog}>
                                Submit and Login
                              </Button>
                            </Box>
                            <Box id="box_2" mt={1}>
                              <Button fullWidth variant="outlined" color="primary" id="cancelButton" onClick={handleOpenCancelDialog}>
                                Cancel and close
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
                :
                <>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Box pl={6} >
                        <Typography variant='h1'>
                          <Box pt={6}>
                            Password link expired
                          </Box>
                        </Typography>
                        <Typography component="h2" style={{ paddingTop: 15 }}>
                          Our apologies! New password links expire after 12 hours. A new link is on its way. Please check for an email from donotreply@cbtravel.com.
                          Then click on the new link to reset your password.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              }
            </div>
          </div>
        </div>
      </div >

      {/* Cancel dialog box */}
      <Dialog
        maxWidth='xs'
        open={openCancelDialog}
        aria-labelledby="form-dialog-title"
      >
        <Box className={classes.dialogPadding} p={2} display="flex" flexDirection="column" justifyContent="center">
          <DialogContent>
            {/* The 'x' icon on the modal. */}
            <IconButton id="closeIcon" aria-label="close" className={classes.closeButton}
              onClick={handleCloseCancelDialog}
            >
              <CloseIcon width={"20"} height={"20"} color={"#00467E"} />
            </IconButton>
            <DialogContentText>
              <Box mt={6} mb={6}>
                <Typography align="center" variant="h1">Are you sure you<br /> want to cancel?</Typography>
                <Box mt={2} mb={1}>
                  <Typography align="center" variant="body2">
                    If you close this screen before creating a password, you won't be able to log into AirPortal.
                  </Typography>
                </Box>
                <Box mt={2} mb={1}>
                  <Typography align="center" variant="body2">
                    To create a password later, please contact your travel manager.
                  </Typography>
                </Box>
              </Box>
            </DialogContentText>
            <Box>
              <Button fullWidth onClick={handleCloseCancelDialog} variant="contained" color="primary">
                Continue creating password
              </Button>
            </Box>
            <Box my={1}>
              <Button fullWidth onClick={handleCancel} variant="outlined" color="primary">
                Cancel and close
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>

    </React.Fragment >
  );
}

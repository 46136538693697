import DateFnsUtils from "@date-io/date-fns";
import {
    Box,
    Grid,
    Link,
    TextField,
    Typography
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import IconButton from '@material-ui/core/IconButton';
import { useState, useContext } from "react";
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    createStyles,
    makeStyles,
    Theme
} from "@material-ui/core/styles";
import {
    AddCircleOutline as AddIcon, TodayOutlined,
} from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Control, Controller, DeepMap, FieldErrors, useFieldArray, UseFormClearErrors, UseFormGetValues, UseFormRegister, UseFormSetError, UseFormTrigger, useWatch, UseFormSetValue } from "react-hook-form";
import { formUtils } from "../../../util/form-utils";
import PassportFormFields from "./FieldArrayComponents/PassportFieldArray";
import { formRules, IdentificationCardFields, ProfileFormInputs, SelectOption } from "./Profile";
import MaskedTextField from "../../../components/ui/Input/MaskedTextField";
import { UserContext } from "../../../components/shared/UserContext";
import { iUserContext } from "@cbtravel/common/lib/shared/interfaces/iUserContext";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";
import { dateUtil } from "../../../util/date-util";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        h2: {
            fontSize: 13,
            fontWeight: 600,
            lineHeight: 1.5,
        },
        summary: {
            fontSize: 12,
        },
        accordion: {
            border: "1px solid rgba(0, 0, 0, .125)",
            boxShadow: "none",
            "&:not(:last-child)": {
                borderBottom: 0,
            },
            "&:before": {
                display: "none",
            },
            "&$expanded": {
                margin: "auto",
            },
            "& .MuiFormHelperText-root": {
                fontSize: 12,
                color: "#808080",
            },
            "& .MuiInputBase-input": {
                background: "#fff",
            },
            "& .MuiFormControlLabel-label": {
                fontSize: 13,
                fontWeight: 600,
                marginLeft: "-4px",
            },
            "& .MuiInputBase-root": {
                background: "#fff",
            },
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: 0,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
            },
        },
        accordionDetails: {
            padding: theme.spacing(2)
        },
        accordionSummary: {
            backgroundColor: "rgba(0, 0, 0, .03)",
            borderBottom: "1px solid rgba(0, 0, 0, .125)",
            marginBottom: -1,
            minHeight: 60,
            "&.MuiAccordionSummary-root.Mui-expanded": {
                minHeight: 60
            }
        },
        accordionSummaryContent: {
            "&$expanded": {
                margin: "12px 0"
            }
        },
        addIcon: {
            color: "#00467E",
            marginTop: -4,
        },
        removeIcon: {
            color: "#BC2C2F",
        },
        addText: {
            "& .MuiLink-button": {
                fontSize: 12,
                fontFamily: "Open Sans, Arial, sans-serif",
                fontWeight: 600,
                color: "#00467E",
                marginTop: 0,
                textDecoration: "none",
            },
            "& .MuiSvgIcon-root": {
                marginBottom: -1.5,
                marginRight: -1,
                fontSize: 13,
            },
        },
        removeWrapper: {
            marginTop: -36,
            marginBottom: -36,
            "& .MuiLink-button": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "Open Sans, Arial, sans-serif",
                color: "#BC2C2F",
                textDecoration: "none",
            },
        },
        removeText: {
            "& .MuiSvgIcon-root": {
                marginRight: -1,
                marginBottom: -1.5,
                fontSize: 12,
            },
        },
        whiteBG: {
            background: "#fff",
        },
        flex: {
            display: "flex",
            flexDirection: "column",
            marginLeft: 3,
        },
        errorTextField: {
            paddingTop: '5px',
        },
        errorTextCalendar: {
            paddingTop: '2px',
        }
    })
);

interface TravelerIdentificationTabProps {
    control: Control<ProfileFormInputs>,
    errors: DeepMap<ProfileFormInputs, FieldErrors>,
    register: UseFormRegister<ProfileFormInputs>,
    countries: SelectOption[],
    getValues: UseFormGetValues<ProfileFormInputs>,
    setError: UseFormSetError<ProfileFormInputs>,
    clearErrors: UseFormClearErrors<ProfileFormInputs>,
    trigger: UseFormTrigger<ProfileFormInputs>,
    setValue: UseFormSetValue<ProfileFormInputs>,
    /* Boolean prop indicating whether the user is viewing other's profile (true) or his/her profile (false) */
    viewingOtherProfile: boolean
}

export default function TravelerIdentificationTab(props: TravelerIdentificationTabProps) {
    let { viewingOtherProfile } = props;
    const classes = useStyles();
    const { userContext } = useContext<iUserContext>(UserContext);
    // Set up field array for passport information
    const passportFieldArray = useFieldArray({ control: props.control, name: "passports" });
    const watchPassports = useWatch({ control: props.control, name: "passports" });
    const controlPassports = passportFieldArray.fields.map((field, index) => {
        return {
            ...field,
            ...watchPassports[index]
        };
    });

    function requireIdNumber(required: boolean, fields: IdentificationCardFields) {
        if (required && fields.idCardNumber.trim() === "") {
            props.setError("idCard.idCardNumber", {
                type: "required",
                message: "ID number is required"
            });
        } else if (props.errors?.idCard?.idCardNumber) {
            props.clearErrors("idCard.idCardNumber");
        }
    }

    /**
     * Sets errors for country when the ID card has been partially filled out from an 
     * input other than country.
     * 
     * @param required True if country is required.
     * @param fields Object representing the fields of this ID card.
     */
    function requireCountry(required: boolean, fields: IdentificationCardFields) {
        if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager) &&
            required && (!fields.country || fields.country.label.length === 0)) {
            props.setError("idCard.country", {
                type: "required",
                message: "Please select a country"
            });
        } else if (props.errors?.idCard?.country) {
            props.clearErrors("idCard.country");
        }
    }

    /**
     * Sets errors for issue date when the ID card has been partially filled out from an 
     * input other than issue date.
     * 
     * @param required True if issue date is required.
     * @param fields Object representing the fields of this ID card.
     */
    function requireIssueDate(required: boolean, fields: IdentificationCardFields) {
        let minDate = new Date("1900-01-01T00:00:00");
        if (required && fields.issueDate === null) {
            props.setError("idCard.issueDate", {
                type: "required",
                message: "Issue date is required"
            });
        } else if (fields.issueDate && fields.expireDate && new Date(fields.issueDate).getTime() >= new Date(fields.expireDate).getTime()) {
            props.setError("idCard.issueDate", {
                type: "max",
                message: "Issue date must be before expiration date"
            });
        } else if (fields.issueDate && new Date(fields.issueDate).toString() == "Invalid Date") {
            props.setError("idCard.issueDate", {
                type: "pattern",
                message: "Invalid date format"
            });
        }
        else if (fields.issueDate && fields.issueDate <= minDate) {
            props.setError("idCard.issueDate", {
                type: "pattern",
                message: "Date should not be before minimal date"
            });
        } else if (props.errors?.idCard?.issueDate) {
            props.clearErrors("idCard.issueDate");
        }
    }

    /**
     * Sets errors for expire date when the ID card has been partially filled out from an 
     * input other than expire date.
     * 
     * @param required True if expire date is required.
     * @param fields Object representing the fields of this ID card.
     */
    function requireExpireDate(required: boolean, fields: IdentificationCardFields) {
        let minDate = new Date("1900-01-01T00:00:00");
        if (required && fields.expireDate === null) {
            props.setError("idCard.expireDate", {
                type: "required",
                message: "Expiration date is required"
            });
        } else if (fields.issueDate && fields.expireDate && new Date(fields.issueDate).getTime() >= new Date(fields.expireDate).getTime()) {
            props.setError("idCard.expireDate", {
                type: "min",
                message: "Expiration date must be after issue date"
            });
        } else if (fields.expireDate && new Date(fields.expireDate).toString() == "Invalid Date") {
            props.setError("idCard.expireDate", {
                type: "pattern",
                message: "Invalid date format"
            });
        } else if (fields.expireDate && fields.expireDate <= minDate) {
            props.setError("idCard.expireDate", {
                type: "pattern",
                message: "Date should not be before minimal date"
            });
        } else if (props.errors?.idCard?.expireDate) {
            props.clearErrors("idCard.expireDate");
        }
    }

    return (
        <div>
            <Box mt={2}>
                <Accordion defaultExpanded className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="id-content"
                        id="id-header"
                        className={classes.accordionSummary}
                    >
                        <div className={`flex ${classes.accordionSummaryContent}`}>
                            <Typography variant="h2" className={classes.h2}>
                                Identification card
                            </Typography>
                            <Typography variant="body1" className={classes.summary}>
                                REAL ID, driver’s license, or any valid identification
                            </Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Box mt={1} mx={1} style={{ width: "100%" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Box mt={1}>
                                        <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                            <Controller
                                                name={"idCard.idCardNumber"}
                                                control={props.control}
                                                rules={{
                                                    validate: {
                                                        required: (value: string) => {
                                                            if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                                let c = props.getValues("idCard");

                                                                // If other fields are filled but not this one, it is required
                                                                if (!value && (c.country?.label.length > 0 || c.expireDate || c.issueDate)) {
                                                                    return "ID number is required";
                                                                }
                                                                return true;
                                                            }
                                                        },
                                                        requireOthers: (value: string) => {
                                                            if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                                let c = props.getValues("idCard");

                                                                // If this or another field has been filled, the others are required
                                                                let required = value.trim().length > 0 || c.expireDate !== null || c.issueDate !== null || (c.country && c.country.label !== "");
                                                                requireCountry(required, c);
                                                                requireIssueDate(required, c);
                                                                requireExpireDate(required, c);
                                                                return true;
                                                            }
                                                        }
                                                    },
                                                    maxLength: { value: 100, message: "Max 100 characters" }
                                                }}
                                                render={({
                                                    field: { onChange, value, ref, onBlur }
                                                }) => (
                                                    <MaskedTextField
                                                        userType={userContext.userType}
                                                        action={props.viewingOtherProfile ? "idCard.idCardNumber:unmask" : "ownProfileField:unmask"}
                                                        id="ff-identification-card"
                                                        label="ID #"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                        onChange={(e) => onChange(e.target.value)}
                                                        value={value}
                                                        ref={ref}
                                                        error={props.errors.idCard?.idCardNumber ? true : false}
                                                        InputProps={{ autoComplete: "off" }}
                                                    />
                                                )} />
                                        </form>
                                        <Typography variant='body2' color='error' className={classes.errorTextField}>
                                            {props.errors?.idCard && props.errors?.idCard?.idCardNumber?.message}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Controller
                                        name={"idCard.country"}
                                        control={props.control}
                                        rules={{
                                            validate: {
                                                required: (value: SelectOption) => {
                                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                        let c = props.getValues("idCard");
                                                        if ((!value || value.label === "") && (c.expireDate || c.issueDate || c.idCardNumber.trim() !== "")) {
                                                            return "Please select a country";
                                                        }
                                                        return true;
                                                    }
                                                },
                                                requireOther: (value: SelectOption) => {
                                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                        let c = props.getValues("idCard");

                                                        // If this or another field has been filled, the others are required
                                                        let required = (value && value.label !== "") || c.idCardNumber.trim().length > 0 || c.expireDate !== null || c.issueDate !== null;
                                                        requireIdNumber(required, c);
                                                        requireExpireDate(required, c);
                                                        requireIssueDate(required, c);
                                                        return true;
                                                    }
                                                }
                                            }
                                        }}
                                        render={({
                                            field: { onChange, value, ref, onBlur }
                                        }) => (
                                            <Autocomplete
                                                id="country"
                                                options={props.countries}
                                                getOptionLabel={(option) => option.label}
                                                getOptionSelected={(option, value) => option.value == value.value || value.value == ""}
                                                fullWidth
                                                autoSelect
                                                autoHighlight
                                                value={value}
                                                ref={ref}
                                                onBlur={onBlur}
                                                onClose={onBlur}
                                                onChange={(_, value) => onChange(value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        id="ff-country"
                                                        label="Country"
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={classes.whiteBG}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={props.errors.idCard?.country ? true : false}
                                                    />
                                                )}
                                            />
                                        )} />
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {(props.errors?.idCard && props.errors?.idCard?.country as any)?.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Controller
                                        name={"idCard.issueDate"}
                                        control={props.control}
                                        rules={{
                                            validate: {
                                                required: (value: Date | null) => {
                                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                        let c = props.getValues("idCard");
                                                        if (value === null && (c.idCardNumber.trim() !== "" || c.expireDate || (c.country !== null && c.country.label !== ""))) {
                                                            return "Issue date is required";
                                                        }
                                                        return true;
                                                    }
                                                },
                                                requireOther: (value: Date | null) => {
                                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                        let c = props.getValues("idCard");
                                                        let required = value !== null || c.idCardNumber.trim() !== "" || c.expireDate !== null || (c.country !== null && c.country.label !== "");
                                                        requireIdNumber(required, c);
                                                        requireCountry(required, c);
                                                        requireExpireDate(required, c);
                                                        return true;
                                                    }
                                                },
                                                pattern: (value: Date | null) => {
                                                    let date: Date | null = null;
                                                    if (value != null && !dateUtil.compareToMinDate(value)) {
                                                        date = new Date(value);
                                                        if (!(date instanceof Date)
                                                            || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
                                                        ) {
                                                            return "Invalid date format"
                                                        }
                                                    }
                                                    return true;
                                                }
                                            }
                                        }}
                                        render={({
                                            field: { onChange, value, onBlur }
                                        }) => (
                                            <>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        // disableFuture
                                                        margin="dense"
                                                        id={`dp-id-issue-date`}
                                                        label="Issue date"
                                                        openTo="year"
                                                        format="MM/dd/yyyy"
                                                        placeholder="MM/DD/YYYY"
                                                        views={["year", "month", "date"]}
                                                        inputVariant="outlined"
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onClose={onBlur}
                                                        onChange={onChange}
                                                        fullWidth
                                                        invalidDateMessage=""
                                                        minDateMessage=""
                                                        maxDateMessage=""
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position={'end'}>
                                                                    <IconButton id={`btn-id-issue-date`} color="primary" aria-label="ID Issue Date">
                                                                        <TodayOutlined />
                                                                    </IconButton>
                                                                </InputAdornment>)
                                                        }}
                                                        error={props.errors.idCard?.issueDate ? true : false}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <Typography variant='body2' color='error' className={classes.errorTextCalendar}>
                                                    {props.errors?.idCard && props.errors?.idCard?.issueDate?.message}
                                                </Typography>
                                            </>
                                        )} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Controller
                                        name={"idCard.expireDate"}
                                        control={props.control}
                                        rules={{
                                            validate: {
                                                required: (value: Date | null) => {
                                                    if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                        let c = props.getValues("idCard");
                                                        if (value === null && (c.idCardNumber.trim() !== "" || c.issueDate || (c.country !== null && c.country.label !== ""))) {
                                                            return "Expiration date is required";
                                                        }
                                                        return true;
                                                    }
                                                },
                                                requireOthers: (value: Date | null) => {
                                                    let c = props.getValues("idCard");
                                                    let required = value !== null || c.idCardNumber.trim() !== "" || c.issueDate !== null || (c.country !== null && c.country.label !== "");
                                                    requireIdNumber(required, c);
                                                    requireCountry(required, c);
                                                    requireIssueDate(required, c);
                                                    return true;
                                                },
                                                pattern: (value: Date | null) => {
                                                    let date: Date | null = null;
                                                    if (value != null) {
                                                        date = new Date(value);
                                                        if (!(date instanceof Date)
                                                            || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
                                                        ) {
                                                            return "Invalid date format"
                                                        }
                                                    }
                                                    return true;
                                                }
                                            }
                                        }}
                                        render={({
                                            field: { onChange, value, onBlur }
                                        }) => (
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    margin="dense"
                                                    id="dp-id-exp-date"
                                                    label="Expiration date"
                                                    openTo="year"
                                                    format="MM/dd/yyyy"
                                                    placeholder="MM/DD/YYYY"
                                                    invalidDateMessage=""
                                                    minDateMessage=""
                                                    maxDateMessage=""
                                                    views={["year", "month", "date"]}
                                                    inputVariant="outlined"
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onClose={onBlur}
                                                    onChange={onChange}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position={'end'}>
                                                                <IconButton id={`btn-id-exp-date`} color="primary" aria-label="ID expiration date">
                                                                    <TodayOutlined />
                                                                </IconButton>
                                                            </InputAdornment>)
                                                    }}
                                                    error={props.errors.idCard?.expireDate ? true : false}
                                                />
                                            </MuiPickersUtilsProvider>
                                        )} />
                                    <Typography variant='body2' color='error' className={classes.errorTextCalendar}>
                                        {props.errors?.idCard && props.errors?.idCard?.expireDate?.message}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="Passport"
                        id="passport-summary"
                        className={classes.accordionSummary}
                    >
                        <div className={`flex ${classes.accordionSummaryContent}`}>
                            <Typography variant="h2" className={classes.h2}>
                                Passport
                            </Typography>
                            <Typography variant="body1" className={classes.summary}>
                                Official passport or passport card
                            </Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Box mt={1} mx={1} style={{ width: "100%" }}>

                            {/* Map field array to form fields */}
                            {controlPassports.map((field, index) => {
                                return (
                                    <PassportFormFields
                                        key={field.id}
                                        field={field}
                                        index={index}
                                        control={props.control}
                                        controlPassports={controlPassports}
                                        passportFieldArray={passportFieldArray}
                                        errors={props.errors}
                                        countries={JSON.parse(JSON.stringify(props.countries))}
                                        getValues={props.getValues}
                                        setError={props.setError}
                                        clearErrors={props.clearErrors}
                                        trigger={props.trigger}
                                        setValue={props.setValue}
                                        viewingOtherProfile={viewingOtherProfile}
                                    />
                                );
                            })}
                            {controlPassports.length < 2 &&
                                <Box
                                    component="p"
                                    display="flex"
                                    py={0.5}
                                    className={classes.addText}
                                >
                                    <Link href="#" component="button" id={`link-add-another-passport`} color="primary" onClick={() => passportFieldArray.append({ country: { label: "", value: "" }, expireDate: null, issueDate: null, passportNumber: "", primaryPassport: false })}>
                                        <AddIcon className={classes.addIcon} /> Add another passport
                                    </Link>
                                </Box>}
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="Passport"
                        id="passport-summary1"
                        className={classes.accordionSummary}
                    >
                        <div className={`flex ${classes.accordionSummaryContent}`}>
                            <Typography variant="h2" className={classes.h2}>
                                TSA secure flight information
                            </Typography>
                            <Typography variant="body1" className={classes.summary}>
                                Known traveler number and redress number
                            </Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Box mt={1} mx={1} style={{ width: "100%" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="travelerNumber"
                                            control={props.control}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <MaskedTextField
                                                    userType={userContext.userType}
                                                    action={viewingOtherProfile ? "travelerNumber:unmask" : "ownProfileField:unmask"}
                                                    id="ff-ktn"
                                                    label="Known Traveler Number (KTN)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    ref={ref}
                                                    helperText="TSA PreCheck, Global Entry, NEXUS, SENTRI"
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="redressNumber"
                                            control={props.control}
                                            rules={formRules.redressNumber}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <MaskedTextField
                                                    userType={userContext.userType}
                                                    action={viewingOtherProfile ? "redressNumber:unmask" : "ownProfileField:unmask"}
                                                    id="ff-redress"
                                                    label="Redress Number"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    ref={ref}
                                                    error={props.errors.redressNumber ? true : false}
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.redressNumber && props.errors.redressNumber.message}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
}



import React, { useContext } from "react";
import {
  createStyles,
  Theme,
  makeStyles
} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Close as CloseIcon, History } from "@material-ui/icons";
import {
  Dialog,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { TicketRS } from "@cbtravel/common/lib/web/messages/general/responses/ticket-rs";
import { TicketController } from "@cbtravel/common/lib/web/api/general/controllers/ticket-controller";
import { TicketHistoryRS } from "@cbtravel/common/lib/web/messages/general/responses/ticket-history-rs";
import { TicketHistoryRQ } from "@cbtravel/common/lib/web/messages/general/requests/ticket-history-rq";

import { UserContext } from "../../../components/shared/UserContext";
import Spinner from "../../../components/shared/Spinner";
import { HistoryDataRow } from "./HistoryDataRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    blue: {
      color: "#00467E",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    table: {
      "& .MuiTableCell-head": {
        fontWeight: 700,
      },
    },
  })
);

interface DialogTitleProps {
  /** String to use for the ID of the underlying `div` container tag. */
  id: string;
  /** Additional JSX Element to contain within this component. */
  children: React.ReactNode;
  /** Action to execute when the dialog should close. */
  onClose: () => void;
}

/**
 * Component used for displaying the dialog title and providing an onClose action.
 * 
 * @param props {@link DialogTitleProps Properties} for the `DialogTitle` component.
 * @returns A JSX element used to display the title and `x` button to close the dialog.
 */
function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, id } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} id={id}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          id="btn-icon-close"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

let rows: TicketHistoryRS[] = [];

interface HistoryDialogProps {
  /** Object that represents the ticket which backs the dialog. */
  ticket: TicketRS;
  /** The timezone to use when displaying times in the history dialog. */
  timeZone: string;
}

/**
 * Dialog component for displaying information about a tickets history on the UnusedTickets page.
 * 
 * @param props {@link HistoryDialogProps Properties} for the `HistoryDialog` component.
 * @returns A JSX element for displaying ticket history in a dialog on the UnusedTickets page.
 */
export default function HistoryDialog(props: HistoryDialogProps) {
  const classes = useStyles();
  const { userContext } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);

  /**
   * Handler that gets a ticket's history and opens its history dialog.
   */
  async function handleClickOpen() {
    setShowSpinner(true);
    let ticketHistoryRQ: TicketHistoryRQ = new TicketHistoryRQ();
    ticketHistoryRQ.ticketId = props.ticket.ticketId;
    ticketHistoryRQ.pcc = props.ticket.pcc
    rows = await (await TicketController.HistoryFind(userContext.accessToken, ticketHistoryRQ, true)).list;
    setOpen(true);
    setShowSpinner(false);
  };

  /**
   * Handler for clearing and closing the history dialog.
   */
  function handleClose() {
    rows = [];
    setOpen(false);
  };

  return (
    <React.Fragment>
      {showSpinner && <Spinner />}
      <IconButton id="btn-icon-history" onClick={handleClickOpen}>
        <History className={classes.blue} />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="Unused ticket history"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="dialog-unused-ticket-history" onClose={handleClose}>
          Unused Ticket History
        </DialogTitle>
        <TableContainer className={classes.root}>
          <Table
            size="small"
            className={classes.table}
            aria-label="Unused ticket history data"
          >
            <TableHead>
              <TableRow>
                <TableCell>Ticket Number</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <HistoryDataRow row={row} timeZone={props.timeZone} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import Spinner from '../shared/Spinner';

import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';

/**
 * https://developer.okta.com/code/javascript/okta_sign-in_widget/
 * https://github.com/okta/okta-signin-widget#okta-sign-in-widget
 * https://developer.okta.com/live-widget/
 * https://github.com/okta/samples-js-react/blob/master/custom-login/src/Login.jsx
 */


/**
 * Generates and returns a string of random alphanumeric characters.
 * 
 * @param length The length of the string to return.
 * @returns A string of random characters.
 */
function generateRandomText(length: number): string {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

/**
 * Used to render the component used for signing in with Okta. This component also handles
 * several aspects of the user's session and authentication state.
 * 
 * @returns A JSX element to be used for the Okta sign-in widget.
 */
export default function OktaSignInWidget() {
    
    const [showSpinner, setShowSpinner] = useState<boolean>(false) // used to render spinner

    const params = sessionStorage.getItem('secureRouterReferrerPath');

    let email = "";
    let sessionToken = "";
    let correctedParams = params;

    let index = params?.indexOf("email=") ?? -1;
    if (params != null && index >= 0) {
        correctedParams = correctedParams?.substring(0, index) ?? null;
        email = params.substring(index + 6);
        index = email.indexOf("&") ?? -1;
        if (index > -1) {
            email = email.substring(0, index);// remove everything after the email
        }
    }


    index = params?.indexOf("sessionToken=") ?? -1;
    if (params != null && index >= 0) {
        correctedParams = correctedParams?.substring(0, index) ?? null;
        sessionToken = params.substring(index + 13);
        index = sessionToken.indexOf("&") ?? -1;
        if (index > -1) {
            sessionToken = sessionToken.substring(0, index);// remove everything after the session
        }
    }

    //Remove the & if itss the last thing
    index = correctedParams?.lastIndexOf("&") ?? -1;
    if (index == (correctedParams?.length ?? 0) - 1) {
        correctedParams = correctedParams?.substring(0, index) ?? null;
    }

    //Remove the ? if its the last thing
    index = correctedParams?.lastIndexOf("?") ?? -1;
    if (index == (correctedParams?.length ?? 0) - 1) {
        correctedParams = correctedParams?.substring(0, index) ?? null;
    }

    const oktaSignIn = new OktaSignIn({
        issuer: Configuration.Okta.issuer,
        clientId: Configuration.Okta.clientId,
        logo: './ap-logo.png',
        redirectUri: Configuration.Okta.redirectUri,
        authParams: {
            issuer: Configuration.Okta.issuer,
            display: 'page',
            pkce: false,
            responseType: Configuration.Okta.responseType,
            scopes: Configuration.Okta.scopes,
        },
        tokenManager: {
            autoRenew: false,
            storageKey: Configuration.Okta.storageKey
        },
        features: {
            idpDiscovery: true,
        },
        idpDiscovery: {
            requestContext: window.location.href
        },
        helpLinks: {
            forgotPassword: Configuration.AirPortalUrl +'/authentication/forgotpass'
        },
        username: email,
        i18n: {
            en: {
                'primaryauth.username.placeholder': 'Email Address',
                'primaryauth.username.tooltip': 'Email Address',
            }
        },
        services: { autoRenew: false}
    })

    if (sessionToken != null && sessionToken != "") {
        oktaSignIn.authClient.token.getWithoutPrompt({
            responseMode: 'fragment',
            sessionToken: sessionToken,
            scopes: [
                'openid'
            ],
            state: generateRandomText(10),
            nonce: generateRandomText(10)
        })
            .then(function (res: any) {
                // Do something with tokens, such as
                oktaSignIn.authClient.tokenManager.add('idToken', res.tokens.idToken);
                oktaSignIn.authClient.tokenManager.add('accessToken', res.tokens.accessToken);

                //remove the extra stuff off the route, and go there. 
                sessionStorage.setItem('secureRouterReferrerPath', correctedParams ?? "");
                window.location.href = sessionStorage.getItem('secureRouterReferrerPath') ?? "";

            })
            .catch(function (err: any) {
                // handle OAuthError or AuthSdkError
                const error = err;
            });
    }

    oktaSignIn.on('ready', function () {
        if ((sessionToken == null || sessionToken == "")) {
            const params = sessionStorage.getItem('secureRouterReferrerPath');;
            const inputValue = document.getElementById("idp-discovery-username")?.attributes.getNamedItem("value");

            if (inputValue != null && inputValue.nodeValue != null && inputValue.nodeValue != "" && params != null && email != "") {
                document.getElementById('idp-discovery-submit')?.click();
            }
        }
        else {

        }
    });

    useEffect(() => {

        /**
         * Checks for an existing user session. If a session already exists then the user
         * is redirected. If there is no session then the Okta sign-in widget is rendered.
         */
        async function checkSession() {
            const sessionExists = await oktaSignIn.authClient.session.exists()
            
            if (sessionExists) {
                oktaSignIn.authClient.token.getWithRedirect({
                    responseType: Configuration.Okta.responseType,
                    scopes: Configuration.Okta.scopes,
                })
            } else {
                if (sessionToken == null || sessionToken == "") {
                    oktaSignIn.showSignInAndRedirect({
                        el: "#sign-in-widget"
                    })
                        .catch(function (error : any) {
                            // This function is invoked with errors the widget cannot recover from:
                            // Known errors: CONFIG_ERROR, UNSUPPORTED_BROWSER_ERROR
                            console.log("Login Error:", error);
                        })
                }
                else {
                    setShowSpinner(true);
                }


            }
        }
        checkSession();
    }, [])

    return (
        showSpinner ? <Spinner /> :
            <div id="sign-in-widget" />
    )
};



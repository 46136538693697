import React, { useContext, useEffect, useState, SetStateAction } from 'react';

import {
  ButtonGroup,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  Box,
  Button,
  Grid,
  InputAdornment, OutlinedInput,
  Link,
  Switch,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Add as PlusIcon, Delete, Remove as MinusIcon } from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import clsx from "clsx";
import { ApprovalRQ } from "@cbtravel/common/lib/shared/messages/approval/requests/approval-rq";
import { ApprovalRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-rs";
import { ApprovalGroupApproverRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-group-approver-rs';
import { ApprovalGroupRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-group-rs";
import { ApprovalController } from "@cbtravel/common/lib/shared/api/approval/controllers/approval-controller";
import { ApprovalType } from "@cbtravel/common/lib/shared/common/enumerations/approval-type";
import { ClientRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-rs";
import { EntityDepth } from "@cbtravel/common/lib/shared/common/enumerations/entity-depth";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { UserLT } from "@cbtravel/common/lib/shared/messages/general/responses/lite/user-lt";
import { UserRS } from '@cbtravel/common/lib/shared/messages/general/responses/user-rs';

import Spinner from "../../../components/shared/Spinner";
import { UserContext } from "../../../components/shared/UserContext";

import DisableConfigurationDialog from "./dialogs/DisableConfigurationDialog";
import RemoveConfigurationDialog from "./dialogs/RemoveConfigurationDialog";
import RemoveLevelDialog from "./dialogs/RemoveLevelDialog";
import AddManagerDialog from './dialogs/AddManagerDialog';

import ConfigureApprovalTypesLevel from "./ConfigureApprovalTypesLevel";
import UserSearchBox from "../../../components/shared/UserSearchBox";
import { useStyles, ConfigurationWizardSteps, ireminderState, ireminderCountDuration, irecipientUsers } from './ConfigureApprovalTypes';
import { ApprovalRecipientType } from '@cbtravel/common/lib/shared/common/enumerations/approval-recipient-type';
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import { Controller, useFieldArray, useWatch, useForm } from 'react-hook-form';
import { ApprovalRuleRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-rule-rs';
import { AutoApprovalComparisonOperator } from '@cbtravel/common/lib/shared/common/enumerations/auto-approval-comparison-operator';
import { AutoApprovalLogicalOperator } from '@cbtravel/common/lib/shared/common/enumerations/auto-approval-logical-operator';
import { Domain } from 'domain';
import { RegexUtils } from '@cbtravel/common/lib/shared/common/regex-utils';

interface ExistingApprovalProps {
  /** Object representing an existing approval configuration. */
  approval: ApprovalRS,
  /** Function to execute when user wants to disable the approval configuration. */
  disableApproval: (approval: ApprovalRS) => Promise<void>,
  /** Function to execute when user wants to enable the approval configuration. */
  enableApproval: (approval: ApprovalRS) => Promise<void>,
}

interface ApprovalTypeFormProps {
  /** Object representing the currently active client. */
  activeClient?: ClientRS,
  /** React state setter for the current approval configuration. */
  setCurrentApproval: React.Dispatch<SetStateAction<ApprovalRS | undefined>>,
  /** React state setter for the current step of the configuration wizard. */
  setActiveStep: React.Dispatch<SetStateAction<number>>
}

/**
 * Step 1 of the wizard. Used for selecting the approval type to modify.
 * 
 * @param props {@link ApprovalTypeFormProps Properties} for the `ApprovalTypeForm` component.
 * @returns A JSX element used for the first step of the approval configuration wizard.
 */
function ApprovalTypeForm(props: ApprovalTypeFormProps): JSX.Element {
  const { userContext } = useContext(UserContext);
  const snackbar = useCustomSnackbar();
  const classes = useStyles();

  const [isPageBusy, setIsPageBusy] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(true);
  const [disableRadioButtons, setDisableRadioButtons] = useState<boolean>(false);
  const [existingPreTripAuth, setExistingPreTripAuth] = useState<ApprovalRS>();
  const [existingPostBook, setExistingPostBook] = useState<ApprovalRS>();
  const [existingUserEnrollment, setExistingUserEnrollment] = useState<ApprovalRS>();
  const [withApproval, setWithApproval] = useState<ApprovalRS>();

  // When the active client changes, get existing approval configurations for the new client.
  useEffect(() => {
    if (props.activeClient) {
      getExistingConfigurations();
    }

  }, [props.activeClient]);

  // When the selected approval config changes, update wizard step state to reflect available options.
  useEffect(() => {
    if (withApproval !== undefined) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }

  }, [withApproval]);

  useEffect(() => {
    // if user had an approval config selected, maintain selection but update the ApprovalRS being tracked
    if (withApproval) {
      switch (withApproval.approvalType) {
        case ApprovalType.PreTripAuthorization:
          setWithApproval(existingPreTripAuth);
          break;
        case ApprovalType.PostBookApproval:
          setWithApproval(existingPostBook);
          break;
        case ApprovalType.UserEnrollment:
          setWithApproval(existingUserEnrollment);
          break;
      }
    }
  }, [existingPreTripAuth, existingPostBook, existingUserEnrollment]);

  /**
   * Gets existing approval configurations for the active client.
   */
  async function getExistingConfigurations() {
    try {
      setIsPageBusy(true);
      let request = new ApprovalRQ();
      request.clientId = props.activeClient!.clientId;

      let existingApprovalConfigurations = await ApprovalController.Find(userContext.accessToken, request, EntityDepth.Shallow);

      // clear current existing approval configs
      setExistingPreTripAuth(initNewApproval(ApprovalType.PreTripAuthorization));
      setExistingPostBook(initNewApproval(ApprovalType.PostBookApproval));
      setExistingUserEnrollment(initNewApproval(ApprovalType.UserEnrollment));

      setDisableRadioButtons(false)

      existingApprovalConfigurations.list.forEach((config) => {
        switch (config.approvalType) {
          case ApprovalType.PreTripAuthorization:
            setExistingPreTripAuth(config);
            break;
          case ApprovalType.PostBookApproval:
            setExistingPostBook(config);
            break;
          case ApprovalType.UserEnrollment:
            setExistingUserEnrollment(config)
            break;
          // case ApprovalType.PreTripApproval:
          // TODO: not yet supported
          // break;
          // case ApprovalType.PostTicketingApproval:
          // TODO: not yet supported
          // break;
        }
      });

    } catch (e) {
      // clear current existing approval configs
      setExistingPreTripAuth(initNewApproval(ApprovalType.PreTripAuthorization));
      setExistingPostBook(initNewApproval(ApprovalType.PostBookApproval));
      setExistingUserEnrollment(initNewApproval(ApprovalType.UserEnrollment));

      setDisableRadioButtons(true)

      snackbar.error(e as JsonException);
    } finally {
      setIsPageBusy(false);
    }
  }

  /**
   * Calls the API to enable the specified approval configuration and adjusts the wizard state accordingly.
   *
   * @param approval Object representing the approval configuration to enable.
   */
  async function enableApproval(approval: ApprovalRS) {
    let approvalClone = JSON.parse(JSON.stringify(approval));
    try {
      setIsPageBusy(true);

      approvalClone.isEnabled = true;
      approvalClone.dateModified = new Date();

      let resp = await ApprovalController.Update(userContext.accessToken, approvalClone, EntityDepth.Shallow);

      if (resp.ok) {
        approval.isEnabled = true;
        approval.dateModified = new Date();

        switch (approval.approvalType) {
          case ApprovalType.PostBookApproval:
            setExistingPostBook(approvalClone)
            break;
          case ApprovalType.PreTripAuthorization:
            setExistingPreTripAuth(approvalClone)
            break;
          case ApprovalType.UserEnrollment:
            setExistingUserEnrollment(approvalClone)
            break;
        }

        setWithApproval(approval);
        snackbar.info("Approval Configuration has been enabled.");
      }

    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setIsPageBusy(false);
    }
  }

  /**
   * Calls the API to disable the specified approval configuration and adjusts the wizard state accordingly.
   * 
   * @param approval Object representing the approval configuration to disable.
   */
  async function disableApproval(approval: ApprovalRS) {
    let approvalClone = JSON.parse(JSON.stringify(approval));
    try {
      setIsPageBusy(true);

      approvalClone.isEnabled = false;
      approvalClone.dateModified = new Date();

      let resp = await ApprovalController.Update(userContext.accessToken, approvalClone, EntityDepth.Shallow);

      if (resp.ok) {
        approval.isEnabled = false;
        approval.dateModified = new Date();

        switch (approval.approvalType) {
          case ApprovalType.PostBookApproval:
            setExistingPostBook(approvalClone)
            break;
          case ApprovalType.PreTripAuthorization:
            setExistingPreTripAuth(approvalClone)
            break;
          case ApprovalType.UserEnrollment:
            setExistingUserEnrollment(approvalClone)
            break;
        }

        setWithApproval(approval);
        snackbar.info("Approval Configuration has been disabled.");
      } else {
        approval.isEnabled = true;
      }
    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setIsPageBusy(false);
    }
  }

  /**
   * When choosing to edit an existing approval, pull the full data for that approval and set it as
   * the current approval for the configuration page.
   * 
   * In ConfigureApprovalTypes.tsx there is a useEffect that watches for this change and will
   * automatically set the page to the review screen for approval configuration edits.
   *
   * @param approval Object representing the approval configuration to edit.
   */
  async function editApproval(approval: ApprovalRS) {
    try {
      setIsPageBusy(true);
      let infinteApproval = await ApprovalController.Get(userContext.accessToken, approval.approvalId, EntityDepth.Infinite);
      props.setCurrentApproval(infinteApproval);
    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setIsPageBusy(false);
    }
  }

  /**
   * Initializes a new ApprovalRS with basic information related to the specified approval type
   * and active client.
   * 
   * @param type The type of approval configuration to intialize.
   * @returns An {@link ApprovalRS ApprovalRS} populated with the necessary data to create a configuration.
   */
  function initNewApproval(type: ApprovalType): ApprovalRS {
    let newApproval = new ApprovalRS();
    newApproval.approvalType = type;
    newApproval.isEnabled = true;
    newApproval.name = `${props.activeClient?.name} (${type})`;
    newApproval.client = {
      accountNumber: props.activeClient!.accountNumber,
      clientId: props.activeClient!.clientId,
      name: props.activeClient!.name
    }

    return newApproval;
  }

  /**
   * When an approval type radio is selected, choose existing or create new approval configuration.
   *
   * @param approvalType The type of approval configuration that has been selected.
   */
  function selectionChange(approvalType: ApprovalType) {

    // get enum value from selected radio and convert into a key for ApprovalType enum
    switch (approvalType) {
      case ApprovalType.PreTripAuthorization:
        if (existingPreTripAuth) {
          setWithApproval(existingPreTripAuth);
        } else {
          setWithApproval(initNewApproval(ApprovalType.PreTripAuthorization));
        }
        break;
      case ApprovalType.PostBookApproval:
        if (existingPostBook) {
          setWithApproval(existingPostBook);
        } else {
          setWithApproval(initNewApproval(ApprovalType.PostBookApproval));
        }
        break;
      case ApprovalType.UserEnrollment:
        if (existingUserEnrollment) {
          setWithApproval(existingUserEnrollment);
        } else {
          setWithApproval(initNewApproval(ApprovalType.UserEnrollment));
        }
        break;
    }
  }

  /**
   * Component for showing existing configuration options for an approval.
   * 
   * @param props {@link ExistingApprovalProps Properties} for the `ExistingConfiguration` component.
   * @returns 
   */
  function ExistingConfiguration(props: ExistingApprovalProps): JSX.Element {
    const [showDisableDialog, setShowDisableDialog] = useState<boolean>(false);
    const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);

    var isDisabledText = props.approval.isEnabled ? "" : "is disabled";
    var enableToggleText = props.approval.isEnabled ? "Disable" : "Enable";

    /**
     * Opens the disable configuration dialog if currently enabled or enables the approval
     * configuration if it is disabled.
     */
    function disableEnableToggle() {
      if (props.approval.isEnabled) {
        setShowDisableDialog(true);
      } else {
        props.enableApproval(props.approval);
      }
    }

    return (
      <>
        <DisableConfigurationDialog open={showDisableDialog} setOpen={setShowDisableDialog} approvalType={props.approval.approvalType} positiveAction={() => { props.disableApproval(props.approval) }} />
        <div className={classes.existingConfig}>
          Existing configuration {isDisabledText}
          <span className={classes.reviewActions}>
            <Link onClick={disableEnableToggle}>{enableToggleText}</Link>
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      {isPageBusy && <Spinner />}
      <div style={{ display: "block" }}>

        <Grid container={true} spacing={2} alignContent={"flex-end"} className={classes.root}>
          <FormControl className={classes.formControl}>
            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
              <label className={classes.legend}>Select approval type</label>
              <RadioGroup
                name="approvalTypes"
                aria-label="Approval Types"
                value={withApproval?.approvalType || ""}
                onChange={(event) =>
                  selectionChange(
                    ApprovalType[
                    event.target.value as keyof typeof ApprovalType
                    ]
                  )
                }
              >
                <FormControlLabel
                  label="Pre-Trip Authorization - Submitting a travel request for authorization to travel"
                  value={ApprovalType.PreTripAuthorization}
                  disabled={disableRadioButtons}
                  control={<Radio disabled={isPageBusy} />}
                />
                {existingPreTripAuth && existingPreTripAuth.approvalId !== -1 && (
                  <ExistingConfiguration
                    approval={existingPreTripAuth}
                    enableApproval={enableApproval}
                    disableApproval={disableApproval}
                  />
                )}
                <FormControlLabel
                  label="Post-Booking Approval - Submitting a travel booking for approval prior to ticketing and confirming"
                  value={ApprovalType.PostBookApproval}
                  disabled={disableRadioButtons}
                  control={<Radio disabled={isPageBusy} />}
                />
                {existingPostBook && existingPostBook.approvalId !== -1 && (
                  <ExistingConfiguration
                    approval={existingPostBook}
                    enableApproval={enableApproval}
                    disableApproval={disableApproval}
                  />
                )}
                <FormControlLabel
                  label="User Enrollment - Submitting a new user enrollment for approval"
                  value={ApprovalType.UserEnrollment}
                  disabled={disableRadioButtons}
                  control={<Radio disabled={isPageBusy} />}
                />
                {existingUserEnrollment && existingUserEnrollment.approvalId !== -1 && (
                  <ExistingConfiguration
                    approval={existingUserEnrollment}
                    enableApproval={enableApproval}
                    disableApproval={disableApproval}
                  />
                )}
              </RadioGroup>
            </Grid>
          </FormControl>
        </Grid>
      </div>

      {/* Sticky navigation steps */}
      <Box position="fixed" className={classes.stickyNav}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
          <Button
            variant="contained"
            className={classes.nextBtn}
            color="primary"
            onClick={() => {
              if (withApproval)
                if (withApproval.approvalId === -1)
                  props.setCurrentApproval(withApproval!);
                else
                  editApproval(withApproval);
            }}
            disabled={disableNext || isPageBusy}
          >
            {withApproval && withApproval?.approvalId !== -1 ? "Edit" : "Next"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

type RuleFormInputs = {
  domains: { domain: string }[],
}
interface LevelsFormProps {
  /** Object representing the approval configuration being modified. */
  currentApproval: ApprovalRS | undefined,
  /** Array of approval groups associated with `currentApproval`. */
  approvalGroups: ApprovalGroupRS[],
  /** Object representing the currently active client. */
  activeClient: ClientRS | undefined,
  /** React state setter for the array of approval groups. */
  setApprovalGroups: React.Dispatch<React.SetStateAction<ApprovalGroupRS[]>>,
  /** React state setter for the active step of the wizard. */
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  /** Boolean prop that contains Active Status of the manager (udid 38) custom field. Undefined means it's never configured*/
  isManagerCustomFieldActive: boolean | undefined,
  /** Boolean prop to track whether the manager dialog has popped and accepted in this current progress */
  isManagerFieldApplied: boolean
  /** Array of approval rules associated with the email domain inputs. */
  approvalRules: ApprovalRuleRS[] | undefined,
  /** React state setter for approval rules. */
  setApprovalRules: React.Dispatch<React.SetStateAction<ApprovalRuleRS[] | undefined>>,
}

/**
 * Step 2 of the wizard. Used for modifying approval levels and groups.
 * 
 * @param props {@link LevelsFormProps Properties} for the `LevelsForm` component.
 * @returns A JSX element used for the second step of the approval configuration wizard.
 */
function LevelsForm(props: LevelsFormProps): JSX.Element {
  const classes = useStyles();
  const [approvalGroupList, setApprovalGroupList] = useState<ApprovalGroupRS[]>(props.approvalGroups);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const { formState: { errors, isValid }, control, reset }
    = useForm<RuleFormInputs>({
      mode: "onBlur",
      defaultValues: {
        domains: [{ domain: "" }]
      }
    });

  const domainFieldArray = useFieldArray({ control: control, name: "domains" });
  const watchDomains = useWatch({ control: control, name: "domains" });
  const controlDomains = domainFieldArray.fields.map((field, index) => {
    return {
      ...field,
      ...watchDomains[index]
    };
  });

  const excludedDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "live.com",
    "ymail.com",
    "outlook.com",
    "inbox.com",
    "mail.com",
    "facebook.com",
    "comcast.net",
    "rediffmail.com",
    "me.com",
  ];


  const formRules = {
    emailDomainValidation: {
      validate: (value: string) => {
        if (excludedDomains.includes(value.toLowerCase().trim()))
          return "This domain is not allowed for auto approvals.";
        else if (value.includes("@")) {
          return "Don't include @ symbol";
        }
        else if (value.length > 0 && !value.match(RegexUtils.DOMAIN)) { // if there is a domain, check domain is valid i.e. yourdomain.com
          return "Must be a valid domain";
        }

        // check that there are no duplicates
        let count = 0;
        controlDomains.forEach((field) => {
          if (field.domain.length > 0 && field.domain === value) {
            count++;
          }
        })

        if (count > 1) {
          return "This domain already exists within the list";
        }
      }
    }
  };

  /**React state to control whether the traveler's manager approver is applied on any of the existing approval levels */
  const [isManagerApproverApplied, setIsManagerApproverApplied] = useState<boolean>(props.approvalGroups.some(approvalGroup => approvalGroup.isManagerApprover));

  // Set the default values for the email domain inputs. If there are no existing approval rules we won't render any empty components this way.
  useEffect(() => {
    if (!props.approvalRules) {
      return;
    }
    let defaultValues: RuleFormInputs = { domains: props.approvalRules.map(ar => ({ domain: ar.value })) };
    reset({ ...defaultValues });
  }, []);

  // UseEffect to disable the Next button when there's an invalid field
  useEffect(() => {
    // Checking isValid from reactHookForm fields
    if (!isValid) {
      setDisableNext(true);
      return;
    }

    // if there are no approval groups, disable next
    if (props.approvalGroups.length < 1) {
      setDisableNext(true);
      return;
    }
    else {
      for (let group of approvalGroupList) {
        // if a group is completely empty or contains unititialized/invalid users, disable next
        if ((group.approvalGroupApproverList.length == 0 && !group.isManagerApprover) || group.approvalGroupApproverList.filter((a) => a.user.userId == -1).length > 0) {
          setDisableNext(true);
          return;
        }
      }
    }

    // When all the error checks passed, don't disable Next button
    setDisableNext(false);
  }, [props.approvalGroups, isValid, domainFieldArray]);

  // update external state when group list changes
  useEffect(() => {
    props.setApprovalGroups(approvalGroupList);
    //update isManagerApproverApplied when group list changes
    setIsManagerApproverApplied(approvalGroupList.some(group => group.isManagerApprover));
  }, [approvalGroupList]);

  /**
   * Convert the email domain inputs (in the future other rule form inputs as well) 
   * to approval rule objects and use the setter from the props to save them.
   * The email domains come straight out of the field array. 
   * The input component's onBlur function saves the component value to the field array.
   */
  function RuleFormInputsToApprovalRules() {
    let newApprovalRules = new Array<ApprovalRuleRS>();
    // Cloned string array contains only unique and non-empty strings 
    let domainList: Array<string> = controlDomains
      .map(entry => entry.domain)
      .filter((entry, index, array) => array.indexOf(entry) == index && entry.length > 0);
    // NOTE: once we add features for complex auto-approval rules, this "cleanup before making rule objects" should be made into a function 

    if (domainList.length > 0) {
      // Iterate through each domain and form a list of approval rules 
      for (let i = 0; i < domainList.length; i++) {


        let newRule = new ApprovalRuleRS();
        newRule.value = domainList[i];
        newRule.approvalTypePropertyId = 1;
        newRule.comparisonOperator = AutoApprovalComparisonOperator.Equal;
        newRule.ruleGroupId = 1;
        newRule.ruleGroupLogicalOperator = AutoApprovalLogicalOperator.None;

        // If it's the last rule being added, don't include a logical operator to avoid the service layer throwing errors.
        if (i === domainList.length - 1) {
          newRule.logicalOperator = AutoApprovalLogicalOperator.None;
        }
        else {
          newRule.logicalOperator = AutoApprovalLogicalOperator.Or;
        }

        newApprovalRules.push(newRule);
      }
    }
    // If the field array is empty then we still set the empty approval rules array. This effectively deletes the rules for this configuration.
    props.setApprovalRules(newApprovalRules);
  }

  /**
   * Converts approval rule inputs back into a RuleFormInputs object.
   * This allows us to reset the form state.
   * Currently the only thing we need to reset is the domains so we can just map the field array into a new object.
   */
  function ApprovalRulesToRuleFormInputs() {
    let form: RuleFormInputs = { domains: domainFieldArray.fields.map(f => ({ domain: f.domain })) };
    return form;
  }
  /**
   * When a level is removed, shift other levels to prevent level gaps
   * 
   * @param level The number of the level to remove from the approval configuration.
   */
  function removeLevel(level: number) {
    let newLevel = 1;
    let newList = approvalGroupList.reduce<ApprovalGroupRS[]>((total, group) => {
      if (group.level !== level) {
        group.level = newLevel;
        group.name = `${props.activeClient!.name} (Group ${newLevel})`;
        newLevel++;
        return [...total, group];
      }
      return total;
    }, []);

    setApprovalGroupList(newList);
  }

  /**
   * Adds an empty group to the backing state
   */
  function addEmptyGroup() {
    let newGroup = new ApprovalGroupRS();
    newGroup.level = approvalGroupList.length + 1;
    newGroup.approvalGroupApproverList.push(new ApprovalGroupApproverRS()); // so an empty search box is created
    newGroup.name = `${props.currentApproval?.client.name} (Group ${newGroup.level})`;
    newGroup.isEnabled = true;
    setApprovalGroupList([...approvalGroupList, newGroup]);
  }

  /**
   * Callback method for ConfigureApprovalTypesLevel used to reflect changes in the group list state
   * 
   * @param group Object representing the approval group being changed.
   * @param index Index of the approval group list to set `group` to.
   */
  function onGroupChange(group: ApprovalGroupRS, index: number) {
    group.dateModified = new Date();

    let newGroupList = [...approvalGroupList];
    newGroupList[index] = group;
    setApprovalGroupList(newGroupList);
  }

  /**
   * Edits go back to review page, new approvals go to confirmation users.
   * Advances the wizard to the appropriate step.
   */
  function next() {

    // Update approval rules state variable, so that we we can update the approval object with them when we submit the configuration.
    RuleFormInputsToApprovalRules();
    let formInputs = ApprovalRulesToRuleFormInputs();
    if (props.currentApproval?.approvalId !== -1) {
      props.setActiveStep(ConfigurationWizardSteps.Review);
    } else {
      props.setActiveStep(ConfigurationWizardSteps.ConfirmationUsers);
    }
    reset(formInputs);
  }

  return (
    <div style={{ display: "block" }}>

      {props.currentApproval?.approvalType === ApprovalType.UserEnrollment &&
        <Grid container={true} spacing={2} alignContent="flex-end" className={classes.root}>
          <Grid item={true} lg={9} md={9} sm={12} xs={12}>
            <Box mt={-1}>
              <Typography variant="h2" className={classes.levelHeader}>
                Define domains for automatic approval
              </Typography>
            </Box>
            <Box pt={1}>
              <div style={{ borderBottom: ".5px solid #000", display: "block" }}></div>
            </Box>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>

            <Box pt={2} pb={1}>
              <label className={classes.legend}>Requests that contain the domains below will be automatically approved.</label>
            </Box>
            {controlDomains.map((field, index) => {
              return (
                <Box display="flex" flexDirection="row" mb={1} key={`domain-${field.id}-${index}`}>
                  <Grid lg={8} md={8} sm={8} xs={8}>
                    {/*TODO: add styling for @ symbol in startAdornment*/}
                    <Controller
                      name={`domains.${index}.domain` as const}
                      key={field.id}
                      control={control}
                      rules={formRules.emailDomainValidation}
                      render={({
                        field: { onChange, value, ref, onBlur }
                      }) => (
                        <OutlinedInput
                          fullWidth
                          margin="dense"
                          error={errors?.domains?.[index]?.domain ? true : false}
                          ref={ref}
                          onChange={onChange}
                          startAdornment={<span>@</span>}
                          placeholder={"yourdomain.com"}
                          onBlur={(e) => { onBlur(); }}
                          value={value}
                        />
                      )}
                    />
                    <Typography variant='body2' color='error'>
                      {errors?.domains?.[index]?.domain && (errors?.domains?.[index]?.domain as any).message}
                    </Typography>
                  </Grid>
                  <Grid lg={2} md={2} sm={2} xs={2}>
                    <Box mt={-1}>
                      <Button
                        variant="contained"
                        className={classes.confirmationUserRemove}
                        onClick={
                          () => domainFieldArray.remove(index)
                        }
                      >
                        <Delete />
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              )
            })}
            <Box pb={4}>
              <Button
                variant="outlined"
                className={classes.btnAddAnother}
                onClick={() => domainFieldArray.append({ domain: "" })}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      }
      {props.currentApproval?.approvalType === ApprovalType.UserEnrollment &&
        <>
          <Grid container={true} spacing={2} alignContent="flex-end" className={classes.root}>
            <Grid item={true} lg={9} md={9} sm={12} xs={12} key={0}>
              <Box>
                <label className={classes.legend}>Define approvers for domains not specified above.</label>
              </Box>
            </Grid>
          </Grid>
        </>
      }
      {props.approvalGroups.map((group, index) => {
        return (
          <ConfigureApprovalTypesLevel
            key={group.approvalGroupId}
            group={group}
            groupIndex={index}
            levelCount={props.approvalGroups.length}
            setGroup={onGroupChange}
            isManagerApproverApplied={isManagerApproverApplied}
            removeLevel={() => { removeLevel(group.level) }}
            isManagerCustomFieldActive={props.isManagerCustomFieldActive}
            currentApproval={props.currentApproval}
            isManagerFieldApplied={props.isManagerFieldApplied} />
        )
      })}
      <Grid item={true} lg={9} md={9} sm={12} xs={12}>
        <Box mt={1} ml={1} mb={20}>
          <div style={{ borderBottom: ".5px solid #000", display: "block" }}></div>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Typography
              className={classes.addApproval}
              onClick={addEmptyGroup}
            >
              Add approval level
              <PlusIcon />
            </Typography>
          </Box>
          <Box position="fixed" className={classes.stickyNav}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <Box mr={4}>
                <Button onClick={() => { props.setActiveStep(ConfigurationWizardSteps.SelectApprovalType) }}>Cancel</Button>
              </Box>

              <Button variant="contained" color="primary" className={classes.nextBtn} disabled={disableNext} onClick={() => next()}>
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </div>
  );
};

interface RecipientEmailSearchBoxProps {
  /** Whether or not the `RecipientEmailSearchBox` should autofocus. */
  focus?: boolean,
  /** Object representing the user held by this component. */
  user: UserLT,
  /** Handler for when the user held by this component should change. */
  onChange: (oldUserId: number, newUser: UserLT, recipientType: ApprovalRecipientType) => void,
  /** Handler for when the user should held by this component should be removed. */
  removeUser: (userId: number, recipientType: ApprovalRecipientType) => void,
  /** Type of notification email this user should receive. */
  recipientType: ApprovalRecipientType,
}

interface RecipientEmailsFormProps {
  /** Object representing the approval configuration being modified. */
  currentApproval: ApprovalRS | undefined,
  /** Object representing the lists of users to receive reminder and confirmation emails. */
  approvalRecipients: irecipientUsers,
  /** React state setter for the `approvalRecipients` object. */
  setApprovalRecipients: React.Dispatch<React.SetStateAction<irecipientUsers>>,
  /** React state setter for the current step of the configuration wizard. */
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  /** Object representing the reminder notification settings. */
  reminderState: ireminderState,
  /** React state setter for the `reminderState` object. */
  setReminderState: React.Dispatch<React.SetStateAction<ireminderState>>,
  /** Object representing the interval for when email reminders should start. */
  startReminderState: ireminderCountDuration,
  /** React state setter for the `startReminderState` object. */
  setStartReminderState: React.Dispatch<React.SetStateAction<ireminderCountDuration>>,
  /** Object representing the interval for email reminder frequency. */
  frequencyReminderState: ireminderCountDuration,
  /** React state setter for the `frequencyReminderState` object. */
  setFrequencyReminderState: React.Dispatch<React.SetStateAction<ireminderCountDuration>>,
  /** Boolean prop that contains Active Status of the manager (udid 38) custom field. Undefined means it's never configured*/
  isManagerCustomFieldActive: boolean | undefined,
  /** Boolean prop representing if travel manager is configured as a reminder email recipient  */
  isManagerReminder: boolean,
  /** Boolean prop representing if travel manager is configured as a confirmation email recipient  */
  isManagerConfirmation: boolean,
  /** React state setter for the `isManagerReminder` prop. */
  setIsManagerReminder: React.Dispatch<React.SetStateAction<boolean>>,
  /** React state setter for the `isManagerConfirmation` prop. */
  setIsManagerConfirmation: React.Dispatch<React.SetStateAction<boolean>>,
  /** Boolean prop to track whether the manager dialog has popped and accepted in this current progress */
  isManagerFieldApplied: boolean
}

interface iSwitchStates {
  /** Whether or not automatic email reminders are enabled */
  automaticEmailReminders: boolean;
  /** Whether or not confirmation emails are enabled */
  confirmationEmails: boolean;
}

interface irecipientsMap {
  /** Users to send confirmation emails to. */
  confirmationUsers: Map<number, UserLT>,
  /** Users to send reminder emails to. */
  reminderUsers: Map<number, UserLT>,
}

/**
 * Step 3 of the wizard. Used for modifying confirmation email recipients and reminder 
 * notification settings.
 * 
 * @param props {@link RecipientEmailsFormProps Properties} for the `RecipientEmailsForm` component.
 * @returns A JSX element used for the third step of the approval configuration wizard.
 */
const RecipientEmailsForm = (props: RecipientEmailsFormProps): JSX.Element => {
  const classes = useStyles();

  // These are for for the automatic email reminders
  const [switchStates, setSwitchStates] = useState<iSwitchStates>({
    automaticEmailReminders: props.reminderState.enabled,
    confirmationEmails: (props.approvalRecipients.confirmationUsers.length > 0
      && props.approvalRecipients.confirmationUsers[0]?.userId !== -1)
      || props.isManagerConfirmation
  });
  const durations = ["hour(s)", "day(s)"];
  const [startCountDuration, setStartCountDuration] = React.useState<ireminderCountDuration>({ count: 4, duration: durations[1] });
  const [freqCountDuration, setFreqCountDuration] = React.useState<ireminderCountDuration>({ count: 4, duration: durations[1] });
  const [recipientUsers, setRecipientUsers] = useState<irecipientsMap>({
    confirmationUsers: new Map<number, UserLT>(),
    reminderUsers: new Map<number, UserLT>(),
  });
  // Local boolean states to track manager as email recipients
  const [managerConfirmationEnabled, setManagerConfirmationEnabled] = useState<boolean>(props.isManagerConfirmation);
  const [managerReminderEnabled, setManagerReminderEnabled] = useState<boolean>(props.isManagerReminder);

  // Most other aspects of this form update the parent form state as they are updated. If we did that with the reminder counters and drop downs,
  // it'd be gratuitous re-rendering. We save those when they navigate to a new step
  // this flag is used to prevent the reminder notification states from being overwritten by the (blank) parent state if we update
  // confirmation users after setting them
  const [localStateUpdated, setLocalStateUpdated] = useState<boolean>(false);

  /**
   * Handles the event when a toggleable switch state is changed.
   * 
   * @param event The that caused the switch to change state.
   */
  function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === "automaticEmailReminders") {
      setSwitchStates({
        automaticEmailReminders: !switchStates.automaticEmailReminders,
        confirmationEmails: switchStates.confirmationEmails,
      });
    } else if (event.target.name === "confirmationEmails") {
      setSwitchStates({
        confirmationEmails: !switchStates.confirmationEmails,
        automaticEmailReminders: switchStates.automaticEmailReminders,
      });
    }
  }

  /**
   * Handles the action when decreasing the count of email reminder interval settings.
   * 
   * @param countType The name of the type of count being decreased.
   */
  function handleDecreaseCount(countType: string) {
    !localStateUpdated && setLocalStateUpdated(true)

    if (countType === "start" && startCountDuration.count > 1) {
      setStartCountDuration({ count: startCountDuration.count - 1, duration: startCountDuration.duration })

    } else if (countType === "frequency" && freqCountDuration.count > 1) {
      setFreqCountDuration({ count: freqCountDuration.count - 1, duration: freqCountDuration.duration });
    }
  }

  /**
   * Handles the action when increasing the count of email reminder interval settings.
   * 
   * @param countType The name of the type of count being increased.
   */
  function handleIncreaseCount(countType: string) {
    !localStateUpdated && setLocalStateUpdated(true)

    countType === "start" ?
      setStartCountDuration({ count: startCountDuration.count + 1, duration: startCountDuration.duration })
      :
      setFreqCountDuration({ count: freqCountDuration.count + 1, duration: freqCountDuration.duration });
  };

  /**
   * Handles the event when modifying an email reminder interval setting by changing the value 
   * of the text.
   * 
   * @param event The event that changed the text field.
   * @param countType The name of the type of count being modified.
   */
  function handleCountTextfield(event: React.ChangeEvent<HTMLInputElement>, countType: string) {
    !localStateUpdated && setLocalStateUpdated(true)

    let value = Number(event.target.value);
    if (!isNaN(value)) {
      value = Math.max(1, value);
      countType === "start" ?
        setStartCountDuration({ count: value, duration: startCountDuration.duration })
        :
        setFreqCountDuration({ count: value, duration: freqCountDuration.duration });
    }
  };


  /**
   * A component used for searching for users by email.
   * 
   * @param props {@link RecipientEmailSearchBoxProps Properties} for the `RecipientEmailSearchBox` component.
   * @returns A JSX element used for creating a UI element that can search for users by email address.
   */
  function RecipientEmailSearchBox(props: RecipientEmailSearchBoxProps): JSX.Element {
    const [currentUser, setCurrentUser] = useState<UserLT>(props.user ? props.user : new UserLT());

    /**
     * Handles the action when the value held by this component changes.
     * 
     * @param newUser Object that represents a user to be held by this component.
     */
    function handleUserChange(newUser: UserLT | UserRS | null) {
      if (!newUser) {
        newUser = new UserLT();
      }
      if (currentUser.userId !== newUser.userId) {
        props.onChange(currentUser.userId, newUser, props.recipientType);
        setCurrentUser(newUser);
      }
    };

    return (
      <div className={classes.users}>
        <div className="header-aligned">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <UserSearchBox focus={props.focus} label="User email" user={currentUser} onChange={handleUserChange} />
          </Grid>
          <Button
            variant="contained"
            size="small"
            className={classes.confirmationUserRemove}
            onClick={() => {
              props.removeUser(currentUser.userId, props.recipientType);
            }}
          >
            <Delete />
          </Button>
        </div>
      </div>
    );
  }

  /**
   * Used to add an empty user to email reminders or confirmation emails.
   * 
   * @param recipientType The type of email recipient.
   */
  function addRecipientUser(recipientType: ApprovalRecipientType) {
    !localStateUpdated && setLocalStateUpdated(true);

    if (recipientType === ApprovalRecipientType.Confirmation) {
      let newConfirmationUsers = new Map(recipientUsers.confirmationUsers);
      let newUser = new UserLT();
      newConfirmationUsers.set(newUser.userId, newUser);
      setRecipientUsers({
        confirmationUsers: newConfirmationUsers,
        reminderUsers: recipientUsers.reminderUsers,
      });
    } else if (recipientType === ApprovalRecipientType.Reminder) {
      let newReminderUsers = new Map(recipientUsers.reminderUsers);
      let newUser = new UserLT();
      newReminderUsers.set(newUser.userId, newUser);
      setRecipientUsers({
        confirmationUsers: recipientUsers.confirmationUsers,
        reminderUsers: newReminderUsers,
      });
    }
  }

  /**
   * Enable manager as a email recipient given the type for **local** state
   * @param recipientType ApprovalRecipientType - type of email recipient
   */
  function addRecipientManager(recipientType: ApprovalRecipientType) {
    !localStateUpdated && setLocalStateUpdated(true);
    if (recipientType === ApprovalRecipientType.Confirmation) {
      setManagerConfirmationEnabled(true);
    }
    else if (recipientType === ApprovalRecipientType.Reminder) {
      setManagerReminderEnabled(true);
    }

  }
  /**
   * Remove manager as a email recipient given the type 
   * @param recipientType ApprovalRecipientType - type of email recipient
   */
  function removeRecipientManager(recipientType: ApprovalRecipientType) {
    !localStateUpdated && setLocalStateUpdated(true);
    if (recipientType === ApprovalRecipientType.Confirmation) {
      setManagerConfirmationEnabled(false);
    }
    else if (recipientType === ApprovalRecipientType.Reminder) {
      setManagerReminderEnabled(false);
    }
  }

  /**
   * Used to remove a user from those receiving reminder or confirmation emails.
   * 
   * @param userId The user ID of the user to to remove.
   * @param recipientType The type of email recipient.
   */
  function removeRecipientUser(userId: number, recipientType: ApprovalRecipientType) {
    !localStateUpdated && setLocalStateUpdated(true);

    if (recipientType === ApprovalRecipientType.Confirmation) {
      let newConfirmationUsers = new Map(recipientUsers.confirmationUsers);
      newConfirmationUsers.delete(userId);
      setRecipientUsers({
        confirmationUsers: newConfirmationUsers,
        reminderUsers: recipientUsers.reminderUsers,
      });
    } else if (recipientType === ApprovalRecipientType.Reminder) {
      let newReminderUsers = new Map(recipientUsers.reminderUsers);
      newReminderUsers.delete(userId);
      setRecipientUsers({
        confirmationUsers: recipientUsers.confirmationUsers,
        reminderUsers: newReminderUsers,
      });
    }
  }

  /**
   * Used to determine if the list of users in props.approvalRecipientUsers matches the list of
   * UserLTs being managed by this component.
   * 
   * Added checks to determine to isManagerReminder and isManagerConfirmation are different than 
   * the states being managed by this component 
   * 
   * @returns False if they do not match, true if they do
   */
  function recipientUsersEqualsProp(): boolean {
    if (recipientUsers.confirmationUsers.size !== props.approvalRecipients.confirmationUsers.length
      || recipientUsers.reminderUsers.size !== props.approvalRecipients.reminderUsers.length) {
      return false;
    }
    // Check if global manager recipient states are different  
    if (props.isManagerReminder !== managerReminderEnabled
      || props.isManagerConfirmation !== managerConfirmationEnabled) {
      return false;
    }

    // Check if users in global vs local are matched
    let userMatches = 0;
    props.approvalRecipients.confirmationUsers.forEach((u: UserLT) => {
      if (!recipientUsers.confirmationUsers.has(u.userId)) {
        return false;
      } else {
        userMatches++;
      }
    });
    props.approvalRecipients.reminderUsers.forEach((u: UserLT) => {
      if (!recipientUsers.reminderUsers.has(u.userId)) {
        return false;
      } else {
        userMatches++;
      }
    });


    if (userMatches !== recipientUsers.confirmationUsers.size + recipientUsers.reminderUsers.size) {
      return false;
    }

    return true;
  }

  /**
   * Changes the local recipientUsers state to match changes within a UserSearchBox.
   * 
   * @param oldUserId The userId of the user that used to populate this `UserSearchBox`, to be removed from state.
   * @param newUser The user that now populates the `UserSearchBox`, to be added to state.
   */
  function onRecipientUserChange(oldUserId: number, newUser: UserLT, recipientType: ApprovalRecipientType) {
    if (recipientType === ApprovalRecipientType.Confirmation) {
      let newConfirmationUsers = new Map(recipientUsers.confirmationUsers);
      newConfirmationUsers.delete(oldUserId);
      newConfirmationUsers.set(newUser.userId, newUser);
      setRecipientUsers({
        confirmationUsers: newConfirmationUsers,
        reminderUsers: recipientUsers.reminderUsers
      });
    } else if (recipientType === ApprovalRecipientType.Reminder) {
      let newReminderUsers = new Map(recipientUsers.reminderUsers);
      newReminderUsers.delete(oldUserId);
      newReminderUsers.set(newUser.userId, newUser);
      setRecipientUsers({
        confirmationUsers: recipientUsers.confirmationUsers,
        reminderUsers: newReminderUsers
      });
    }
  }

  /**
   * Translate a count/duration pair into their value in minutes e.g. 1 hour = 60.
   * 
   * @param count The number of `duration`s to convert to minutes.
   * @param duration A string representing a length of time i.e. days, hours, or minutes.
   * @returns The total number of minutes in `count` of `duration`.
   */
  function translateToMinutes(count: number, duration: string) {
    let totalMinutes: number = 0;
    switch (duration) {
      case "day(s)":
        totalMinutes = count * 1440;
        break;
      case "hour(s)":
        totalMinutes = count * 60;
        break;
    }
    return totalMinutes;
  }

  /**
   * Saves the current page data and updates parent form state when the user navigates to a new step in the
   * wizard (forward to review or back to edit approvers).
   * 
   * @param nextStep An enum indicating which step of the wizard to move to after saving the data on the page.
   */
  function handleSaveReminderConfirmations(nextStep: ConfigurationWizardSteps) {
    setLocalStateUpdated(false);

    // disabling reminders
    if (!switchStates.automaticEmailReminders) {
      props.setReminderState({ enabled: false, startMin: 0, frequencyMin: 0 })
      // reset the form to default for next time
      props.setStartReminderState({ count: 4, duration: "hour(s)" })
      props.setFrequencyReminderState({ count: 4, duration: "hour(s)" })

      // if they've disabled reminder emails, wipe out any saved users, otherwise we handle for adding/saving/updating elsewhere
      setRecipientUsers({ confirmationUsers: recipientUsers.confirmationUsers, reminderUsers: new Map<number, UserLT>() })
      props.setApprovalRecipients({
        confirmationUsers: props.approvalRecipients.confirmationUsers,
        reminderUsers: new Array<UserRS>(),
      });
      // If disabling reminder, set isManagerReminder to false
      props.setIsManagerReminder(false);
      setManagerReminderEnabled(false);
    } else {
      // enable and translate everything to minutes for the form state
      props.setReminderState({
        enabled: true,
        startMin: translateToMinutes(startCountDuration.count, startCountDuration.duration),
        frequencyMin: translateToMinutes(freqCountDuration.count, freqCountDuration.duration)
      })
      props.setStartReminderState(startCountDuration)
      props.setFrequencyReminderState(freqCountDuration)
    }

    // if they've disabled confirmation emails, wipe out any saved users, otherwise we handle for adding/saving/updating elsewhere
    if (!switchStates.confirmationEmails) {
      // setConfirmationUsers(new Map<number, UserLT>());
      setRecipientUsers({ confirmationUsers: new Map<number, UserLT>(), reminderUsers: recipientUsers.reminderUsers })
      props.setApprovalRecipients({
        confirmationUsers: new Array<UserRS>(),
        reminderUsers: props.approvalRecipients.reminderUsers,
      });
      // if disabling confirmation emails, set manager confirmation to false:
      props.setIsManagerConfirmation(false);
      setManagerConfirmationEnabled(false);
    }

    // now let's navigate to the next step
    props.setActiveStep(nextStep)
  }

  useEffect(() => {
    if (!props.currentApproval) {
      return;
    }

    let currentUsers: irecipientsMap = {
      confirmationUsers: new Map(recipientUsers.confirmationUsers),
      reminderUsers: new Map(recipientUsers.reminderUsers)
    }

    props.approvalRecipients.confirmationUsers.forEach((u: UserLT) => {
      currentUsers.confirmationUsers.set(u.userId, u);
    });
    props.approvalRecipients.reminderUsers.forEach((u: UserLT) => {
      currentUsers.reminderUsers.set(u.userId, u);
    });
    setRecipientUsers(currentUsers);
    // Update local manager recipients states 
    setManagerReminderEnabled(props.isManagerReminder);
    setManagerConfirmationEnabled(props.isManagerConfirmation);

    // reminder notification and confirmation set up (switch states and time settings)
    if (!localStateUpdated) {
      setSwitchStates({
        automaticEmailReminders: props.reminderState.enabled,
        confirmationEmails: (props.approvalRecipients.confirmationUsers.length > 0
          && props.approvalRecipients.confirmationUsers[0]?.userId !== -1)
          || switchStates.confirmationEmails
          || props.isManagerConfirmation
      });
      setStartCountDuration({ count: props.startReminderState.count, duration: props.startReminderState.duration })
      setFreqCountDuration({ count: props.frequencyReminderState.count, duration: props.frequencyReminderState.duration })
    }


  }, [props]);

  /**
   * UseEffect to update parent (global) state if the recipients are changed in this element
   */
  useEffect(() => {
    // only call to update external state if parity is broken
    if (!recipientUsersEqualsProp()) {
      let users: irecipientUsers = { confirmationUsers: [], reminderUsers: [] };

      for (let u of recipientUsers.confirmationUsers.values()) {
        users.confirmationUsers.push(u);
      }
      for (let u of recipientUsers.reminderUsers.values()) {
        users.reminderUsers.push(u);
      }

      props.setApprovalRecipients(users);
      // set global manager recipient states:
      props.setIsManagerConfirmation(managerConfirmationEnabled);
      props.setIsManagerReminder(managerReminderEnabled);
    }
  }, [recipientUsers.confirmationUsers, recipientUsers.reminderUsers, managerReminderEnabled, managerConfirmationEnabled]);

  useEffect(() => {
    if (props.reminderState.enabled !== switchStates.automaticEmailReminders) {
      props.setReminderState({
        enabled: switchStates.automaticEmailReminders,
        startMin: translateToMinutes(startCountDuration.count, startCountDuration.duration),
        frequencyMin: translateToMinutes(freqCountDuration.count, freqCountDuration.duration)
      });
    }
  }, [switchStates]);

  return (
    <React.Fragment>
      <Box mt={1} ml={1}>
        <Typography variant="h2">Notifications</Typography>
      </Box>
      <Grid
        container={true}
        spacing={2}
        alignContent="flex-end"
        className={classes.root}
      >
        <Grid lg={9}>
          <FormGroup className={classes.switchSelections}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchStates.automaticEmailReminders}
                  onChange={handleSwitchChange}
                  name="automaticEmailReminders"
                  size="small"
                />
              }
              label={
                <span className={classes.switchHeader}>
                  Additional automatic email reminders
                </span>
              }
            />
            <span className={classes.switchDescription}>
              Schedule when and how frequently email reminders are sent to
              approvers. View your travel policy for restrictions.
            </span>

            {switchStates.automaticEmailReminders && (
              <div className={classes.reminderSelections}>
                <Box mt={2}>
                  <Typography variant="body1">
                    <strong>
                      Once a travel request has been submitted, when should email
                      reminders start sending?
                    </strong>
                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Box mr={1.5} my={1}>
                      <ButtonGroup aria-label="count stepper">
                        <Button onClick={() => handleDecreaseCount("start")}>
                          <MinusIcon />
                        </Button>
                        <TextField
                          id="start-reminder-emails"
                          className={classes.startEmailReminder}
                          value={startCountDuration.count}
                          onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => handleCountTextfield(changeEvent, "start")}
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          autoFocus={false}
                          size="small"
                          autoComplete="off"
                        />
                        <Button onClick={() => handleIncreaseCount("start")}>
                          <PlusIcon />
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Box mr={1.5}>
                      <Autocomplete
                        value={startCountDuration.duration}
                        fullWidth={true}
                        autoSelect
                        autoHighlight
                        size="small"
                        onChange={(event: any, newValue: string) => {
                          !localStateUpdated && setLocalStateUpdated(true)
                          setStartCountDuration({ count: startCountDuration.count, duration: newValue });
                        }}
                        disableClearable
                        inputValue={startCountDuration.duration}
                        onInputChange={(event, newInputValue) => {
                          !localStateUpdated && setLocalStateUpdated(true);
                          setStartCountDuration({ count: startCountDuration.count, duration: newInputValue });
                        }}
                        id="controllable-states-demo"
                        options={durations}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span className={classes.duration}>{option}</span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: 122 }}
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                    <Box flexShrink={2} className={classes.reminderSummary}>
                      <Typography variant="body1">
                        Automatic email reminders will start sending <strong>{startCountDuration.count} {startCountDuration.duration}</strong> after a travel request has been
                        submitted
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box mt={1} py={2}>
                  <Typography variant="body1">
                    <strong>Once reminders have started, how often should they be sent?</strong>
                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Box mr={1.5} my={1}>
                      <ButtonGroup aria-label="count stepper">
                        <Button onClick={() => handleDecreaseCount("frequency")}>
                          <MinusIcon />
                        </Button>
                        <TextField
                          id="email-frequency"
                          className={classes.startEmailReminder}
                          value={freqCountDuration.count}
                          onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => handleCountTextfield(changeEvent, "frequency")}
                          inputProps={{
                            style: { textAlign: "center" },
                          }}
                          autoFocus={false}
                          size="small"
                          autoComplete="off"
                        />
                        <Button onClick={() => handleIncreaseCount("frequency")}>
                          <PlusIcon />
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Box mr={1.5}>
                      <Autocomplete
                        value={freqCountDuration.duration}
                        fullWidth={true}
                        autoSelect
                        autoHighlight
                        size="small"
                        onChange={(event: any, newValue: string) => {
                          !localStateUpdated && setLocalStateUpdated(true);
                          setFreqCountDuration({ count: freqCountDuration.count, duration: newValue });
                        }}
                        disableClearable
                        inputValue={freqCountDuration.duration}
                        onInputChange={(event, newInputValue) => {
                          !localStateUpdated && setLocalStateUpdated(true)
                          setFreqCountDuration({ count: freqCountDuration.count, duration: newInputValue });
                        }}
                        id="controllable-states-demo"
                        options={durations}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span className={classes.duration}>{option}</span>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: 122 }}
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                    <Box flexShrink={2} className={classes.reminderSummary}>
                      <Typography variant="body1">
                        Automatic email reminders will be sent to approvers every <strong>{freqCountDuration.count} {freqCountDuration.duration}</strong>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box my={1}>
                  <Typography variant="body1">
                    <strong>Add users to follow up with approvers</strong>
                  </Typography>
                  <Typography variant="body1">
                    If an approver is on vacation or not responding, these users
                    will be notified with reminders to ensure requests are being
                    completed
                  </Typography>
                  <Box mt={1}>
                    <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                      {/* Traveler's Manager Box: Reminder */}
                      {managerReminderEnabled && props.currentApproval?.approvalType !== ApprovalType.UserEnrollment &&
                        <Box display="flex" flexDirection="row" mb={1}>
                          <Grid lg={6} md={6} sm={8} xs={8}>
                            <OutlinedInput
                              style={{ background: "#f3f3f3" }}
                              fullWidth margin="dense"
                              value="Traveler's manager"
                              endAdornment={<InputAdornment position="end"><LockOutlinedIcon style={{ width: 20 }} /></InputAdornment>}
                              disabled />
                          </Grid>
                          {/* Traveler's Manager Box Remove reminder */}
                          <Grid lg={2} md={2} sm={2} xs={2}>
                            <Box mt={-1}>
                              <Button
                                variant="contained"
                                className={classes.confirmationUserRemove}
                                onClick={() => removeRecipientManager(ApprovalRecipientType.Reminder)}
                              >
                                <Delete />
                              </Button>
                            </Box>
                          </Grid>
                        </Box>
                      }
                      {[...recipientUsers.reminderUsers.values()].map((u) => {
                        if (u.userId === -1) {
                          return (
                            <>
                              <RecipientEmailSearchBox
                                key={u.userId}
                                user={u}
                                onChange={onRecipientUserChange}
                                removeUser={removeRecipientUser}
                                recipientType={ApprovalRecipientType.Reminder}
                              />
                            </>
                          );
                        } else {
                          return (
                            <RecipientEmailSearchBox
                              user={u}
                              key={u.userId}
                              onChange={onRecipientUserChange}
                              removeUser={removeRecipientUser}
                              recipientType={ApprovalRecipientType.Reminder}
                            />
                          );
                        }
                      })}
                      <Box display="flex" flexDirection="row">
                        <Box mr={1}>
                          <Button
                            variant="outlined"
                            className={classes.btnAddAnother}
                            onClick={() => addRecipientUser(ApprovalRecipientType.Reminder)}
                          >
                            Add
                          </Button>
                        </Box>
                        {/* Add manager as additional reminder email recipient button */}
                        {!managerReminderEnabled && props.currentApproval?.approvalType !== ApprovalType.UserEnrollment &&
                          <AddManagerDialog
                            isManagerFieldApplied={managerReminderEnabled || managerConfirmationEnabled || props.isManagerFieldApplied}
                            isManagerCustomFieldActive={props.isManagerCustomFieldActive}
                            onClickPositiveAction={() => addRecipientManager(ApprovalRecipientType.Reminder)} />
                        }
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </div>
            )}

            <FormControlLabel
              control={
                <Switch
                  checked={switchStates.confirmationEmails}
                  onChange={handleSwitchChange}
                  name="confirmationEmails"
                  color="secondary"
                  size="small"
                />
              }
              label={
                <span className={classes.switchHeader}>Confirmation emails</span>
              }
            />
            <span className={classes.switchDescription}>
              Add users to receive confirmation emails (in addition to the requester)
            </span>
            {switchStates.confirmationEmails && (
              <div className={classes.reminderSelections}>
                <Box pb={4} mb={4}>
                  <Grid item={true} lg={12} md={12} sm={12} xs={12}>
                    {/* Traveler's Manager Box: Confirmation */}
                    {managerConfirmationEnabled && props.currentApproval?.approvalType !== ApprovalType.UserEnrollment &&
                      <Box display="flex" flexDirection="row" mb={1}>
                        <Grid lg={6} md={6} sm={8} xs={8}>
                          <OutlinedInput
                            style={{ background: "#f3f3f3" }}
                            fullWidth margin="dense"
                            value="Traveler's manager"
                            endAdornment={<InputAdornment position="end"><LockOutlinedIcon style={{ width: 20 }} /></InputAdornment>}
                            disabled />
                        </Grid>
                        {/* Traveler's Manager Box Remove Confirmation */}
                        <Grid lg={2} md={2} sm={2} xs={2}>
                          <Box mt={-1}>
                            <Button
                              variant="contained"
                              className={classes.confirmationUserRemove}
                              onClick={() => removeRecipientManager(ApprovalRecipientType.Confirmation)}
                            >
                              <Delete />
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    }
                    {[...recipientUsers.confirmationUsers.values()].map((u) => {
                      if (u.userId === -1) {
                        return (
                          <RecipientEmailSearchBox
                            focus={true}
                            key={u.userId}
                            user={u}
                            onChange={onRecipientUserChange}
                            removeUser={removeRecipientUser}
                            recipientType={ApprovalRecipientType.Confirmation}
                          />
                        );
                      } else {
                        return (
                          <RecipientEmailSearchBox
                            user={u}
                            key={u.userId}
                            onChange={onRecipientUserChange}
                            removeUser={removeRecipientUser}
                            recipientType={ApprovalRecipientType.Confirmation}
                          />
                        );
                      }
                    })}
                    <Box display="flex" flexDirection="row">
                      <Box mr={1}>
                        <Button
                          variant="outlined"
                          className={classes.btnAddAnother}
                          onClick={() => addRecipientUser(ApprovalRecipientType.Confirmation)}
                        >
                          Add
                        </Button>
                      </Box>
                      {/* Add manager as confirmation email recipient button */}
                      {!managerConfirmationEnabled && props.currentApproval?.approvalType !== ApprovalType.UserEnrollment &&
                        <AddManagerDialog
                          isManagerFieldApplied={managerReminderEnabled || managerConfirmationEnabled || props.isManagerFieldApplied}
                          isManagerCustomFieldActive={props.isManagerCustomFieldActive}
                          onClickPositiveAction={() => addRecipientManager(ApprovalRecipientType.Confirmation)} />}
                    </Box>
                  </Grid>
                </Box>
              </div>
            )}
          </FormGroup>
        </Grid>

        <Box position="fixed" className={classes.stickyNav}>
          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button onClick={() => { props.setActiveStep(ConfigurationWizardSteps.SelectApprovalType) }}>
                Cancel
              </Button>
              <Button variant="outlined" className={clsx(classes.nextBtn, classes.activeButton)} style={{ marginLeft: 10, }} onClick={() => { handleSaveReminderConfirmations(ConfigurationWizardSteps.GroupsAndLevels) }}>
                Back
              </Button>
              <Button variant="contained" color="primary" style={{ marginLeft: 10, }} className={classes.nextBtn} onClick={() => { handleSaveReminderConfirmations(ConfigurationWizardSteps.Review); }}>
                Next
              </Button>
            </Box>


          </Box>

        </Box>
      </Grid>
      <Box mt={5}><p style={{ color: "white", }}>spacing</p></Box>
    </React.Fragment>
  );
};

interface ReviewLevelProps {
  /** The object that represents the approval group. */
  group: ApprovalGroupRS,
  /** The total number of groups in the approval configuration. */
  groupCount: number,
  /** The React state setter for the current step of the wizard. */
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  /** The function to execute when removing an approval level. */
  removeLevel: () => void
}

interface ReviewFormProps {
  /** Object representing the approval configuraion currently being modified. */
  currentApproval: ApprovalRS | undefined,
  /** Array of approval groups associated with the current approval configuration. */
  approvalGroups: ApprovalGroupRS[] | undefined,
  /** The React state setter for the `approvalGroups`. */
  setApprovalGroups: React.Dispatch<React.SetStateAction<ApprovalGroupRS[]>>,
  /** The object representing the lists of notification email users. */
  approvalRecipients: irecipientUsers | undefined,
  /** The React state setter for the current step of the wizard. */
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  /** The function to execute to submit the form. */
  submit: () => void,
  /** The object representing the interval when reminder emails start sending. */
  startReminderState: ireminderCountDuration,
  /** The object represnting the interval that reminder emails continuously send. */
  frequencyReminderState: ireminderCountDuration,
  /** The object representing reminder email state. */
  reminderState: ireminderState,
  /** Boolean prop representing if travel manager is configured as a reminder email recipient  */
  isManagerReminder: boolean,
  /** Boolean prop representing if travel manager is configured as a confirmation email recipient  */
  isManagerConfirmation: boolean,
  /** List of approval rules to display on review step */
  approvalRules: ApprovalRuleRS[] | undefined
}

/**
 * Step 4 of the wizard. Used for reviewing the approval configuration settings before submitting.
 * 
 * @param props {@link ReviewFormProps Properties} for the `ReviewForm` component.
 * @returns A JSX element used for the fourth step of the approval configuration wizard.
 */
const ReviewForm = (props: ReviewFormProps): JSX.Element => {
  const classes = useStyles();
  const [groups, setGroups] = useState<ApprovalGroupRS[]>([]);
  const [recipientUsers, setConfirmationUsers] = useState<irecipientUsers>({ confirmationUsers: [], reminderUsers: [] });

  let approvalTypeText = "";
  switch (props.currentApproval?.approvalType) {
    case ApprovalType.PreTripAuthorization:
      approvalTypeText = "Pre-Trip Authorization";
      break;
    case ApprovalType.PostBookApproval:
      approvalTypeText = "Post-Booking Approval";
      break;
    // not yet supported
    // case ApprovalType.PreTripApproval:
    //   approvalTypeText = "Pre-Trip Approval";
    //   break;
    // case ApprovalType.PostTicketingApproval:
    //   approvalTypeText = "Post-Ticketing Approval";
    //   break;
    case ApprovalType.UserEnrollment:
      approvalTypeText = "User Enrollment";
      break;
  }

  useEffect(() => {
    if (!props.currentApproval) {
      return;
    }

    if (props.approvalGroups) {
      setGroups(props.approvalGroups);
    }

    if (props.approvalRecipients) {
      setConfirmationUsers(props.approvalRecipients);
    }

  }, [props]);

  /**
   * Component used to represent a single approval configuration level.
   * 
   * @param props {@link ReviewLevelProps Properties} for the ReviewLevel component.
   * @returns A JSX element used to display an approval group.
   */
  function ReviewLevel(props: ReviewLevelProps): JSX.Element {
    const [group, setGroup] = useState<ApprovalGroupRS>(props.group);
    const [showRemoveLevelDialog, setShowRemoveLevelDialog] = useState<boolean>(false);

    return (
      <>
        <div className="header-aligned">
          <Typography variant="h2">Level {group?.level}: {group?.approvalGroupType}</Typography>
          <span className={classes.reviewActions}>
            <Link onClick={() => props.setActiveStep(ConfigurationWizardSteps.GroupsAndLevels)}>Edit</Link>
            {props.groupCount > 1 &&
              <>
                <span className="vertical-bar">|</span>
                <Link onClick={() => setShowRemoveLevelDialog(true)}>Remove</Link>
              </>
            }
            <RemoveLevelDialog
              open={showRemoveLevelDialog}
              setOpen={setShowRemoveLevelDialog}
              positiveAction={props.removeLevel}
            />
          </span>
        </div>

        <Box mb={2}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Approver</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Travel Manager approver row if needed */}
                {group?.isManagerApprover &&
                  <TableRow key={"isManagerApprover"}>
                    <TableCell width="40%" component="th" scope="row">
                      Traveler's Manager
                    </TableCell>
                    <TableCell width="60%">
                      (designated at time of request)
                    </TableCell>
                  </TableRow>
                }
                {group?.approvalGroupApproverList.map((a) => {
                  return (
                    <TableRow key={a.user.userId}>
                      <TableCell width="40%" component="th" scope="row">{a.user.firstName} {a.user.lastName}</TableCell>
                      <TableCell width="60%">{a.user.emailAddress}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mt={5}><p style={{ color: "white", }}>spacing</p></Box>
      </>
    );
  }

  /**
   * When a level is removed, shift other levels to prevent level gaps.
   *
   * @param level The number of the approval level to remove.
   */
  function removeLevel(level: number) {
    let newLevel = 1;
    let newList = groups.reduce<ApprovalGroupRS[]>((total, group) => {
      if (group.level !== level) {
        group.level = newLevel;
        newLevel++;
        return [...total, group];
      }
      return total;
    }, []);

    props.setApprovalGroups(newList);
  }

  return (
    <>
      <div style={{ display: "block" }}>
        <Grid container={true} spacing={2} alignContent={"flex-end"} className={classes.root}>
          <Grid item={true} lg={9} md={12} sm={12} xs={12}>
            {/* Approval Type */}
            <Box mt={3}>
              <div className="header-aligned">
                <Typography variant="h2">Approval Type</Typography>
              </div>
              <Box pb={2} pt={1}>
                <div style={{ borderBottom: ".5px solid #000", display: "block" }}></div>
              </Box>
              <Typography variant="body1">{approvalTypeText}</Typography>
            </Box>

            {/* Levels and Groups */}
            <Box mt={3}>
              <div className="header-aligned">
                <Typography variant="h2">Levels and Groups</Typography>
              </div>
            </Box>
            {/* Domain auto-approval. Only show for user enrollment types */}
            {props.currentApproval?.approvalType == ApprovalType.UserEnrollment && (
              <>
                <Box pt={1}>
                  <div style={{ borderBottom: ".5px solid #000", display: "block" }}></div>
                </Box>
                <Box mt={2}>
                  <div className="header-aligned">
                    <Typography variant="h2">Domain auto-approval</Typography>
                    <span className={classes.reviewActions}>
                      <Link onClick={() => props.setActiveStep(ConfigurationWizardSteps.GroupsAndLevels)}>Edit</Link>
                    </span>
                  </div>
                  {/* Show list of approved domains if there's any */}
                  {props.approvalRules && props.approvalRules.length > 0 &&
                    <Box marginLeft={2} mt={1}>
                      <Typography>Email domains that will be auto approved</Typography>
                      <ul>
                        {props.approvalRules.map(rule => <li>{rule.value}</li>)}
                      </ul>
                    </Box>
                  }
                  {/* Show a dash if there isn't any domains configured */}
                  {props.approvalRules && props.approvalRules.length == 0 &&
                    <Typography > - </Typography>
                  }

                </Box>
              </>
            )
            }

            {/* Create component for each group */}
            <Box mt={3}>
              {groups.map((g) => (
                <ReviewLevel
                  key={g.approvalGroupId}
                  group={g}
                  groupCount={groups.length}
                  setActiveStep={props.setActiveStep}
                  removeLevel={() => removeLevel(g.level)}
                />
              ))}
            </Box>

            {/*Notifications - this replaces the Confirmation Emails section*/}
            <Box mt={3} mb={6}>
              <div className="header-aligned">
                <Typography variant="h2">Notifications</Typography>
                <span className={classes.reviewActions}>
                  <Link onClick={() => props.setActiveStep(ConfigurationWizardSteps.ConfirmationUsers)}>Edit</Link>
                </span>
              </div>
              <Box pt={1}>
                <div style={{ borderBottom: ".5px solid #000", display: "block" }}></div>
              </Box>
              <Box pl={2} className={classes.reviewReminderNotifications}>
                {props.reminderState.enabled && (
                  <React.Fragment>
                    <Typography variant="h3">Automatic email reminders</Typography>
                    <ul>
                      <li>
                        Email reminders will start sending{" "}
                        <strong>
                          {props.startReminderState.count}{" "}
                          {props.startReminderState.duration}
                        </strong>{" "}
                        after a travel request has been submitted
                      </li>
                      <li>
                        Email reminders will be sent at a frequency of every{" "}
                        <strong>
                          {props.frequencyReminderState.count}{" "}
                          {props.frequencyReminderState.duration}
                        </strong>
                      </li>
                    </ul>
                    {((recipientUsers.reminderUsers.length > 0 && recipientUsers.reminderUsers[0] !== undefined && recipientUsers.reminderUsers[0].userId !== -1)
                      || (props.isManagerReminder)) && (
                        <Box mb={2}>
                          <Typography variant="h3">
                            Follow up reminders will also be sent to:
                          </Typography>
                          <TableContainer>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                              size="small"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>User Name</TableCell>
                                  <TableCell>Email</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* Travel Manager reminder row if needed */}
                                {props.isManagerReminder &&
                                  <TableRow key={"isManagerReminder"}>
                                    <TableCell width="40%" component="th" scope="row">
                                      Traveler's Manager
                                    </TableCell>
                                    <TableCell width="60%">
                                      (designated at time of request)
                                    </TableCell>
                                  </TableRow>
                                }
                                {/* Other reminder users */}
                                {recipientUsers.reminderUsers.map((u) => (
                                  <TableRow key={u.userId}>
                                    <TableCell width="40%" component="th" scope="row">
                                      {u.firstName} {u.lastName}
                                    </TableCell>
                                    <TableCell width="60%">
                                      {u.emailAddress}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>)}
                  </React.Fragment>
                )}
                {((recipientUsers.confirmationUsers.length > 0 && recipientUsers.confirmationUsers[0] !== undefined && recipientUsers.confirmationUsers[0].userId !== -1)
                  || (props.isManagerConfirmation)) && (
                    <React.Fragment>
                      <Box mb={4}>
                        <Typography variant="h3">Confirmation Emails</Typography>
                        <TableContainer>
                          <Table className={classes.table} aria-label="simple table" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>User Name</TableCell>
                                <TableCell>Email</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {/* Travel Manager Confirmation row if needed */}
                              {props.isManagerConfirmation &&
                                <TableRow key={"isManagerConfirmation"}>
                                  <TableCell width="40%" component="th" scope="row">
                                    Traveler's Manager
                                  </TableCell>
                                  <TableCell width="60%">
                                    (designated at time of request)
                                  </TableCell>
                                </TableRow>
                              }
                              {/* Other confirmation users */}
                              {recipientUsers.confirmationUsers.map((u) =>
                                <TableRow key={u.userId}>
                                  <TableCell width="40%" component="th" scope="row">{u.firstName} {u.lastName}</TableCell>
                                  <TableCell width="60%">{u.emailAddress}</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </React.Fragment>
                  )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>

      <Box position="fixed" className={classes.stickyNav}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
          <Box mr={4}>
            <Link href="#" className={classes.mediumGray} onClick={() => { props.setActiveStep(ConfigurationWizardSteps.SelectApprovalType) }}>
              Cancel
            </Link>
          </Box>

          <Button variant="contained" color="primary" className={classes.nextBtn} onClick={props.submit}>
            Submit
          </Button>
        </Box>
      </Box>
      <Box mt={5}><p style={{ color: "white", }}>spacing</p></Box>
    </>
  );
};



export {
  ApprovalTypeForm,
  LevelsForm,
  RecipientEmailsForm,
  ReviewForm,
}



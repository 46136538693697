import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useEffect, useState, useContext } from "react";

import { Box, Grid, Link, MenuItem, TextField, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import IconButton from '@material-ui/core/IconButton';
import { AddCircleOutline as AddIcon, TodayOutlined, Visibility } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


import { Control, Controller, DeepMap, FieldErrors, useFieldArray, UseFormClearErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, useWatch } from "react-hook-form";

import { AddressType } from "@cbtravel/common/lib/shared/common/enumerations/address-type";
import { PhoneType } from "@cbtravel/common/lib/shared/common/enumerations/phone-type";

import { Suffix } from "@cbtravel/common/lib/shared/common/enumerations/suffix";
import { RegexUtils } from '@cbtravel/common/lib/shared/common/regex-utils';
import { RegionRS } from "@cbtravel/common/lib/shared/messages/general/responses/region-rs";
import { PhoneInput } from "../../../components/ui/Input/PhoneInput";
import { formUtils } from "../../../util/form-utils";
import AddressFormFields from "./FieldArrayComponents/AddressFieldArray";
import PhoneFormFields from "./FieldArrayComponents/PhoneFieldArray";
import { AddressFields, formRules, formRulesBasic, ProfileFormInputs } from "./Profile";
import { phoneUtil } from "../../../util/phone-util";
import Clear from "@material-ui/icons/Clear";
import MaskedDatePicker from "../../../components/ui/Input/MaskedDatePicker";
import { iUserContext } from "@cbtravel/common/lib/shared/interfaces/iUserContext";
import { UserContext } from "../../../components/shared/UserContext";
import { Gender } from "@cbtravel/common/lib/shared/common/enumerations/gender";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        h3: {
            fontSize: 12,
            fontWeight: 300,
            textTransform: "uppercase",
            paddingTop: 10,
            marginBottom: -4,
            display: "block",
        },
        accordion: {
            border: "1px solid rgba(0, 0, 0, .125)",
            boxShadow: "none",
            "&:not(:last-child)": {
                borderBottom: 0,
            },
            "&:before": {
                display: "none",
            },
            "&$expanded": {
                margin: "auto",
            },
            "& .MuiFormHelperText-root": {
                fontSize: 11,
                color: "#808080",
            },
            "& .MuiInputBase-input": {
                background: "#fff",
            },
            "& .MuiInputBase-root": {
                background: "#fff",
            },
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: 0
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
            },
        },
        accordionDetails: {
            padding: theme.spacing(2)
        },
        accordionSummary: {
            backgroundColor: "rgba(0, 0, 0, .03)",
            borderBottom: "1px solid rgba(0, 0, 0, .125)",
            marginBottom: -1,
            minHeight: 60,
            "&.MuiAccordionSummary-root.Mui-expanded": {
                minHeight: 60
            }
        },
        accordionSummaryContent: {
            "&$expanded": {
                margin: "12px 0"
            }
        },
        addIcon: {
            color: "#00467E",
            marginTop: -4,
        },
        removeIcon: {
            color: "#BC2C2F",
        },
        addText: {
            "& .MuiLink-button": {
                fontSize: 12,
                fontFamily: "Open Sans, Arial, sans-serif",
                fontWeight: 600,
                color: "#00467E",
                marginTop: 0,
                textDecoration: "none",
            },
            "& .MuiSvgIcon-root": {
                marginBottom: -1.5,
                marginRight: -1,
                fontSize: 13,
            },
        },
        removeWrapper: {
            marginTop: -36,
            marginBottom: -36,
            "& .MuiLink-button": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "Open Sans, Arial, sans-serif",
                color: "#BC2C2F",
                textDecoration: "none",
            },
        },
        removeText: {
            "& .MuiSvgIcon-root": {
                marginRight: -1,
                marginBottom: -1.5,
                fontSize: 12,
            },
        },
        whiteBG: {
            background: "#fff",
        },
        flex: {
            display: "flex",
            flexDirection: "column",
            marginLeft: 3,
        },
        h2: {
            fontSize: 13,
            fontWeight: 600,
            lineHeight: 1.5,
        },
        summary: {
            fontSize: 12,
        },
        errorTextField: {
            paddingTop: '5px',
        },
        errorTextCalendar: {
            paddingTop: '2px',
        },
    })
);

interface PersonalInfoTabProps {
    control: Control<ProfileFormInputs>,
    errors: DeepMap<ProfileFormInputs, FieldErrors>,
    register: UseFormRegister<ProfileFormInputs>,
    countries: RegionRS[],
    getValues: UseFormGetValues<ProfileFormInputs>,
    setValue: UseFormSetValue<ProfileFormInputs>,
    clearErrors: UseFormClearErrors<ProfileFormInputs>,
    /* Boolean prop indicating whether the user is viewing other's profile (true) or his/her profile (false) */
    viewingOtherProfile: boolean
}

export default function PersonalInfoTab(props: PersonalInfoTabProps) {
    const classes = useStyles();
    const { userContext } = useContext<iUserContext>(UserContext);
    const { viewingOtherProfile } = props;

    // set up field array for phone information
    const phoneFieldArray = useFieldArray({ control: props.control, name: "phoneNumbers" });

    const watchPhones = useWatch({ control: props.control, name: "phoneNumbers" });
    const controlPhones = phoneFieldArray.fields.map((field, index) => {
        return {
            ...field,
            ...watchPhones[index]
        };
    });

    // set up field array for address information
    const addressFieldArray = useFieldArray({ control: props.control, name: "addresses" });
    const watchAddresses = useWatch({ control: props.control, name: "addresses" });
    const controlAddresses = addressFieldArray.fields.map((field, index) => {
        return {
            ...field,
            ...watchAddresses[index]
        };
    });

    let maxPhones = 3; // one per phone type
    let maxAddresses = 3; // one per address type

    const [phoneTypeOptions, setPhoneTypeOptions] = useState<Array<Array<string>>>(new Array(phoneFieldArray.fields.length).fill(phoneTypes));
    const [addressTypeOptions, setAddressTypeOptions] = useState<Array<Array<string>>>(new Array(addressFieldArray.fields.length).fill(addressTypes));
    /**
     * Used to update the available options in phone type dropdowns so user can't select multiple of the same type
     */
    function updatePhoneTypeOptions() {
        let phones = props.getValues("phoneNumbers");
        const currentTypes: PhoneType[] = [];
        phones.forEach(p => currentTypes.push(p.phoneType))

        setPhoneTypeOptions(
            currentTypes.map((p) => {
                let availableTypes = phoneTypes.filter((t) => (
                    currentTypes.indexOf(t) === -1
                ));

                // include this fields phone type as an option
                availableTypes.push(p);

                return availableTypes;
            }));
    }

    /**
     * Used to update the available options in address type dropdowns so user can't select multiple of the same type
     */
    function updateAddressTypeOptions() {
        let addresses = props.getValues("addresses");
        const currentTypes: AddressType[] = [];
        addresses.forEach((a: AddressFields) => currentTypes.push(a.addressType))
        setAddressTypeOptions(
            currentTypes.map((a) => {
                let availableTypes = addressTypes.filter((t) => (
                    currentTypes.indexOf(t) === -1
                ));

                // include this fields address type as an option
                availableTypes.push(a);

                return availableTypes;
            }));
    }

    useEffect(() => {
        updatePhoneTypeOptions();

    }, [watchPhones.length]);

    useEffect(() => {
        updateAddressTypeOptions();
    }, [watchAddresses.length]);
    return (
        <>
            <Box mt={2}>
                <Accordion defaultExpanded className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="basic-info-content"
                        id="basic-info-header"
                        className={classes.accordionSummary}
                    >
                        <div className={`flex ${classes.accordionSummaryContent}`}>
                            <Typography variant="h2" className={classes.h2}>
                                Basic Info
                            </Typography>
                            <Typography variant="body1" className={classes.summary}>
                            Legal name, date of birth and gender must match the traveler's government-issued identification.
                            </Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Box mt={2} mx={1} style={{ width: "100%" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="firstName"
                                            control={props.control}
                                            rules={(viewingOtherProfile && userContext.userType > UserType.TravelManager) ? formRulesBasic.firstName : formRules.firstName }
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="ff-first-name"
                                                    label="Legal first name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    onChange={onChange}
                                                    value={value}
                                                    ref={ref}
                                                    error={props.errors.firstName ? true : false}
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.firstName && props.errors.firstName.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="middleName"
                                            control={props.control}
                                            rules={formRules.nonRequiredName}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                < TextField
                                                    id="middleName"
                                                    label="Legal middle name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    error={props.errors.middleName ? true : false}
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.middleName && props.errors.middleName.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="lastName"
                                            control={props.control}
                                            rules={(viewingOtherProfile && userContext.userType < UserType.TravelManager) ? formRulesBasic.lastName : formRules.lastName}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                < TextField
                                                    id="lastName"
                                                    label="Legal last name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    error={props.errors.lastName ? true : false}
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.lastName && props.errors.lastName.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="suffix"
                                            control={props.control}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="ff-suffix"
                                                    select
                                                    label="Suffix"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    InputProps={{ autoComplete: "off" }}
                                                >
                                                    {suffixes.map((s) => (
                                                        <MenuItem id={`dd-suffix-${suffixes.indexOf(s)}`} key={s} value={s} style={{ minHeight: "2em" }}>
                                                            {s == "NotApplicable" ? "" : s}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )} />
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="dateOfBirth"
                                            control={props.control}
                                            rules={(viewingOtherProfile && userContext.userType < UserType.TravelManager) ? formRules.nonRequiredDate : formRules.dateOfBirth}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <>
                                                    <MaskedDatePicker
                                                        userType={userContext.userType}
                                                        action={props.viewingOtherProfile ? "dateOfBirth:unmask" : "ownProfileField:unmask"}
                                                        disableFuture
                                                        margin="dense"
                                                        id="dp-dateOfBirth"
                                                        label="Date of birth"
                                                        openTo="year"
                                                        format="MM/dd/yyyy"
                                                        placeholder="MM/DD/YYYY"
                                                        maxDateMessage={""}
                                                        minDateMessage={""}
                                                        invalidDateMessage=""
                                                        views={["year", "month", "date"]}
                                                        inputVariant="outlined"
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onClose={onBlur}
                                                        onChange={onChange}
                                                        fullWidth
                                                        InputProps={{
                                                            autoComplete: "off",
                                                        }}
                                                        error={props.errors.dateOfBirth ? true : false}
                                                    />
                                                    <Typography variant='body2' color='error' className={classes.errorTextCalendar}>
                                                        {props.errors.dateOfBirth && props.errors.dateOfBirth.message}
                                                    </Typography>
                                                </>
                                            )} />
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Box mt={1} style={{ width: "100%" }}>
                                        <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                            <Controller
                                                name="gender"
                                                control={props.control}
                                                rules={(viewingOtherProfile && userContext.userType < UserType.TravelManager) ? undefined : formRules.gender}
                                                render={({
                                                    field: { onChange, value, ref, onBlur }
                                                }) => (
                                                    <TextField
                                                        id="ff-gender"
                                                        select
                                                        label="Legal gender"
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e.target.value);
                                                            onBlur();
                                                        }}
                                                        onBlur={onBlur}
                                                        ref={ref}
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        error={props.errors.gender ? true : false}
                                                        InputProps={{ autoComplete: "off" }}
                                                    >
                                                        {Object.values(Gender).map((g, i) => (
                                                            <MenuItem id={`dd-gender-${i}`} key={i} value={g} style={{ minHeight: "2em" }}>
                                                                {g}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )} />
                                        </form>
                                    </Box>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.gender && props.errors.gender.message}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="Contact info"
                        id="contact-info"
                        className={classes.accordionSummary}
                    >
                        <div className={`flex ${classes.accordionSummaryContent}`}>
                            <Typography variant="h2" className={classes.h2}>
                                Contact Info
                            </Typography>
                            <Typography variant="body1" className={classes.summary}>
                                Email, phone, address, emergency contact info
                            </Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Box mx={1} style={{ width: "100%" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box pb={3}>
                                    <Typography variant="h3" className={classes.h3}>
                                        Phone
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* Map the field array to form elements */}
                            {phoneFieldArray.fields.map((field, index) => {
                                return (
                                    <PhoneFormFields
                                        key={field.id}
                                        field={field}
                                        index={index}
                                        control={props.control}
                                        controlPhones={controlPhones}
                                        phoneFieldArray={phoneFieldArray}
                                        viewingOtherProfile={viewingOtherProfile}
                                        errors={props.errors}
                                        phoneTypes={(phoneTypeOptions[index] || phoneTypes)}
                                        updatePhoneTypes={updatePhoneTypeOptions}
                                        getValues={props.getValues}
                                    />
                                );
                            })}

                            {/* Limit the number of allowed elements */}
                            {phoneFieldArray.fields.length < maxPhones &&
                                <Box
                                    component="p"
                                    display="flex"
                                    py={0.5}
                                    mt={0}
                                    className={classes.addText}
                                >
                                    <Link href="#" component="button" color="primary" id={`link-new-phone`} onClick={
                                        // When adding a new phone, set it's type to one that isn't already in the field array
                                        () => phoneFieldArray.append({
                                            phoneType: PhoneType[phoneTypes.find((t) => !props.getValues("phoneNumbers").find((p) => p.phoneType === t)) as keyof typeof PhoneType],
                                            phoneNumber: "",
                                        })}>
                                        <AddIcon className={classes.addIcon} /> Add another phone
                                    </Link>
                                </Box>
                            }

                            {/* Address */}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box pb={3}>
                                    <Typography variant="h3" className={classes.h3}>
                                        Address
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* Map the field array to form elements */}
                            {addressFieldArray.fields.map((field, index) => {
                                return (
                                    <AddressFormFields
                                        key={field.id}
                                        field={field}
                                        index={index}
                                        control={props.control}
                                        controlAddresses={controlAddresses}
                                        addressFieldArray={addressFieldArray}
                                        countries={props.countries}
                                        errors={props.errors}
                                        register={props.register}
                                        addressTypes={(addressTypeOptions[index] || addressTypes)}
                                        updateAddressTypes={updateAddressTypeOptions}
                                        setValue={props.setValue}
                                        clearErrors={props.clearErrors}
                                        getValues={props.getValues}
                                        viewingOtherProfile={viewingOtherProfile}
                                    />
                                );
                            })}

                            {/* Limit the number of allowed elements */}
                            {addressFieldArray.fields.length < maxAddresses &&
                                <Box
                                    component="p"
                                    display="flex"
                                    py={0.5}
                                    mt={0}
                                    className={classes.addText}
                                >
                                    <Link href="#" component="button" color="primary" id={`link-new-address`} onClick={
                                        // When adding a new address make it's type on that isn't used on the form yet
                                        () => addressFieldArray.append({
                                            addressType: AddressType[addressTypes.find((t) => !props.getValues("addresses").find((p: AddressFields) => p.addressType === t)) as keyof typeof AddressType],
                                            address1: "",
                                            address2: "",
                                            city: "",
                                            country: new RegionRS(),
                                            postalCode: "",
                                            stateProvince: new RegionRS()
                                        })}>
                                        <AddIcon className={classes.addIcon} /> Add another address
                                    </Link>
                                </Box>
                            }

                            {/* Emergency contact  */}
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography variant="h3" className={classes.h3}>
                                        Emergency contact
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="emergencyContactFirstName"
                                            control={props.control}
                                            rules={{
                                                validate: {
                                                    required: (value: string | null) => {
                                                        if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                            const emergencyContactFields = props.getValues(['emergencyContactFirstName', 'emergencyContactLastName', 'emergencyContactPhone', 'emergencyContactEmail'])
                                                            let hasOtherValues = emergencyContactFields.some(f => f?.length > 0)
                                                            if (hasOtherValues && !emergencyContactFields[0]) {
                                                                return "Please enter an emergency contact first name"
                                                            }
                                                            if (!value) {
                                                                if (props.errors.emergencyContactLastName) {
                                                                    props.clearErrors('emergencyContactLastName')
                                                                }
                                                                if (props.errors.emergencyContactPhone) {
                                                                    props.clearErrors('emergencyContactPhone')
                                                                }
                                                                if (props.errors.emergencyContactEmail) {
                                                                    props.clearErrors('emergencyContactEmail')
                                                                }
                                                            }
                                                            return true;
                                                        }
                                                    },
                                                },
                                                maxLength: { value: 50, message: 'Max 50 characters' }
                                            }}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="ff-emergency-contact-first-name"
                                                    label="First name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    error={props.errors.emergencyContactFirstName ? true : false}
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.emergencyContactFirstName && props.errors.emergencyContactFirstName.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="emergencyContactLastName"
                                            control={props.control}
                                            rules={{
                                                validate: {
                                                    required: (value: string | null) => {
                                                        if (!(props.viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                            const emergencyContactFields = props.getValues(['emergencyContactFirstName', 'emergencyContactLastName', 'emergencyContactPhone', 'emergencyContactEmail'])
                                                            let hasOtherValues = emergencyContactFields.some(f => f?.length > 0)
                                                            if (hasOtherValues && !emergencyContactFields[1]) {
                                                                return "Please enter an emergency contact last name"
                                                            }
                                                            if (!value) {
                                                                if (props.errors.emergencyContactFirstName) {
                                                                    props.clearErrors('emergencyContactFirstName')
                                                                }
                                                                if (props.errors.emergencyContactPhone) {
                                                                    props.clearErrors('emergencyContactPhone')
                                                                }
                                                                if (props.errors.emergencyContactEmail) {
                                                                    props.clearErrors('emergencyContactEmail')
                                                                }
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                },
                                                maxLength: { value: 50, message: 'Max 50 characters' }
                                            }}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="ff-emergency-contact-last-name"
                                                    label="Last name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    error={props.errors.emergencyContactLastName ? true : false}
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.emergencyContactLastName && props.errors.emergencyContactLastName.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="emergencyContactPhone"
                                            control={props.control}
                                            rules={{
                                                validate: {
                                                    required: (value: string | null) => {
                                                        if (!(props.viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                            const emergencyContactFields = props.getValues(['emergencyContactFirstName', 'emergencyContactLastName', 'emergencyContactPhone', 'emergencyContactEmail'])
                                                            if (!emergencyContactFields[2]) props.setValue('emergencyContactPhone', "")
                                                            let hasOtherValues = emergencyContactFields.some(f => f?.length > 0)
                                                            if (hasOtherValues && !emergencyContactFields[2]) {
                                                                return "Please enter an emergency contact phone"
                                                            }
                                                            if (!value) {
                                                                if (props.errors.emergencyContactFirstName) {
                                                                    props.clearErrors('emergencyContactFirstName')
                                                                }
                                                                if (props.errors.emergencyContactLastName) {
                                                                    props.clearErrors('emergencyContactLastName')
                                                                }
                                                                if (props.errors.emergencyContactEmail) {
                                                                    props.clearErrors('emergencyContactEmail')
                                                                }
                                                            }
                                                            return true;
                                                        }
                                                    },
                                                    value: (value: string) => {
                                                        if (value && !phoneUtil.isPossibleNumber(value)) {
                                                            return "Please enter a valid phone number"
                                                        }
                                                    }
                                                },
                                            }}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <PhoneInput
                                                    id="emergency-contact-phone"
                                                    label="Phone number"
                                                    variant="outlined"
                                                    size="small"
                                                    defaultCountry="US"
                                                    international={false}
                                                    fullWidth
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    ref={ref}
                                                    error={props.errors.emergencyContactPhone ? true : false}
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.emergencyContactPhone && props.errors.emergencyContactPhone.message}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="emergencyContactEmail"
                                            control={props.control}
                                            rules={{
                                                validate: {
                                                    required: (value: string | null) => {
                                                        if (!(props.viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                                            const emergencyContactFields = props.getValues(['emergencyContactFirstName', 'emergencyContactLastName', 'emergencyContactPhone', 'emergencyContactEmail'])
                                                            let hasOtherValues = emergencyContactFields.some(f => f?.length > 0)
                                                            if (!hasOtherValues && emergencyContactFields[3]) {
                                                                return "Please fill other fields"
                                                            }
                                                            if ((/\s/).test(emergencyContactFields[3])) {
                                                                return "Please enter a valid email address"
                                                            }
                                                            return true;
                                                        }
                                                    },
                                                },
                                                pattern: { value: RegexUtils.EMAIL_ADDRESS, message: 'Please enter a valid email address' },
                                                maxLength: { value: 255, message: 'Max 255 characters' }
                                            }}
                                            // rules={formRules.emergencyContactEmail}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="ff-emergency-contact-email"
                                                    label="Email"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    error={props.errors.emergencyContactEmail ? true : false}
                                                    InputProps={{ autoComplete: "off" }}
                                                />
                                            )} />
                                    </form>
                                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                                        {props.errors.emergencyContactEmail && props.errors.emergencyContactEmail.message}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
}

const phoneTypes = [
    PhoneType.Mobile,
    PhoneType.Home,
    PhoneType.Business
];

const addressTypes = [
    AddressType.Billing,
    AddressType.Home,
    AddressType.Business
];

const suffixes = [
    Suffix.NotApplicable,
    Suffix.I,
    Suffix.II,
    Suffix.III,
    Suffix.IV,
    Suffix.V,
    Suffix.VI,
    Suffix.Jr,
    Suffix.Sr
];


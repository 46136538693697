import React, { SetStateAction } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import ActionDialog from "../../../../components/ui/Dialog/ActionDialog";
import { ApprovalType } from "@cbtravel/common/lib/shared/common/enumerations/approval-type";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    strong: {
      fontWeight: 600,
      lineHeight: 1.5
    }
  })
);

interface DisableConfigurationDialogProps {
  /** The type of the approval configuration being disabled. */
  approvalType: ApprovalType,
  /** Whether or not the dialog should be displayed. */
  open: boolean,
  /** React state setter for whether this dialog should be displayed. */
  setOpen: React.Dispatch<SetStateAction<boolean>>,
  /** The function to execute when the user confirms the action. */
  positiveAction: () => void
}

/**
 * A component used to display a dialog for confirmation that an approval configuration
 * should be disabled before actually disabling it.
 * 
 * @param props {@link DisableConfigurationDialogProps Properties} for the `DisableConfigurationDialog` component.
 * @returns A JSX element used by the Configure Approval Types page to confirm the disabling of a configuration.
 */
export default function DisableConfigurationDialog(props: DisableConfigurationDialogProps) {
  const classes = useStyles();
  var approvalTypeText = getApprovalTypeText();

  /**
   * Used to get the proper string representation of an approval type.
   * 
   * @returns A string representation of the approval type passed to this component.
   */
  function getApprovalTypeText() {
    switch (props.approvalType) {
      case ApprovalType.PreTripAuthorization:
        return "Pre-Trip Authorization";
      case ApprovalType.PostBookApproval:
        return "Post-Booking Approval";
      // not yet supported
      // case ApprovalType.PreTripApproval:
      //   return "Pre-Trip Approval";
      // case ApprovalType.PostTicketingApproval:
      //   return "Post-Ticketing Approval";
    }
  }

  return (
    <React.Fragment>
      <ActionDialog
        title="Disable Configuration?"
        positiveText="Disable Configuration"
        open={props.open}
        icon={<DesktopAccessDisabledIcon color="error" />}
        setOpen={props.setOpen}
        positiveAction={props.positiveAction}
        dialogType="delete"
      >
        <Typography variant="body1">
          <p className={classes.strong}>
            This will disable the existing {approvalTypeText} configuration.
            {props.approvalType === ApprovalType.PreTripAuthorization &&
              <p>It will no longer be displayed on:</p>}
          </p>
          <p>
            {props.approvalType === ApprovalType.PreTripAuthorization &&
              <Link href="/booktravel/approvalrequest" target="_blank">
                Approval Requests (Beta)
              </Link>}
          </p>
        </Typography>
      </ActionDialog>
    </React.Fragment>
  );
}

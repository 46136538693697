interface ILocationInfo {
  title: string,
  clientSelector: boolean
}

interface ILocations {
  [key: string]: ILocationInfo
}

const navLocations: ILocations = {
  "/": {
    title: "Dashboard",
    clientSelector: true
  },
  "/reporting/unusedtickets": {
    title: "Unused Tickets",
    clientSelector: false,
  },
  "/booktravel/approvalrequest": {
    title: "Approval Requests (Beta)",
    clientSelector: true,
  },
  "/travelapproval/approvals": {
    title: "Approvals (Beta)",
    clientSelector: false,
  },
  "/companyadmin/configureapprovaltypes": {
    title: "Configure approval types (Beta)",
    clientSelector: true,
  },
  "/companyadmin/reportingfields": {
    title: "Reporting Fields (Beta)",
    clientSelector: true,
  },
  "/companyadmin/users": {
    title: "Users (Beta)",
    clientSelector: false,
  },
  "/companyadmin/incompleteprofiles": {
    title: "Incomplete Profiles",
    clientSelector: false,
  },
  "/companyadmin/hrfeedupload": {
    title: "HR feed upload (Beta)",
    clientSelector: true,
  },
  "/required": {
    title: "Required fields",
    clientSelector: false,
  },
  "/user/profile": {
    title: "Travel profile",
    clientSelector: false,
  },
  "/airportaladmin/vendors": {
    title: "Vendors (Beta)",
    clientSelector: false,
  },
  "/airportaladmin/locations": {
    title: "Locations (Beta)",
    clientSelector: false,
  },
  "/airportaladmin/referencedata": {
    title: "Reference Data (Beta)",
    clientSelector: false,
  },

  //* v2 routes start here

  "/dashboard/manager": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/dashboard/agent": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/dashboard/mytravel": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/airtinerary/airtineraries/lookup": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/airtinerary/airtineraries/present": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/airtinerary/airtineraries/past": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/hotelattachments/reporting/unknownstays": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/vpay/hotel/checkins": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/travelrequest": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/travelrequest/index/online": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/groups/request/travel": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/groups/inprocess/submitted": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/groups/finalize/future": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/groups/manage/groups": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/airtinerary/approval": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/airtinerary/approval/approved": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/travelapproval/canceledtrips": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/securitylogic/map": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/securitylogic/report/insight": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/securitylogic/assistance": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/securitylogic/travelalerts": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/securitylogic/notifications": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/savingstracker/scorecard": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/vpay/lookup": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/domo": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/ibank": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/manager/travelgpa": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/information": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/people": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/contracts/air": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/people/contacts": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/helpdesk": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/travelpolicy": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/servicefees/payment": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/servicefees": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/reporting/fields": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/report": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/technology/general": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/airtinerary": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/vpayhotel/card": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/userrequests": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/integrations": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/consult": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/notes": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/accountnotes": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/log": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/agents/search": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/airtinerary/agentapproval/mytasks": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/agents/team": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/andavo/admin": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/employee/carriercontracts2": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/client/clientdirectory": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/employee/directory": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/gdsscripts": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/employee/index/forwardtotmu": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/commissions/statement/collected": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/commissions/unclaimed": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/vpay/billing": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/voidlogger": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/airtinerary/import": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/vendorprofiles/airvendor": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/locationprofiles/airport": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/serviceprofiles/aircraft": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/office": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/tmc": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/analytics/siteanalytics2": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/analytics/profile2/export": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/analytics/tripit2": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/permissions/permission2": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/sap2": {
    title: "(Redirecting)",
    clientSelector: false,
  },
  "/qmanager2": {
    title: "(Redirecting)",
    clientSelector: false,
  },
};

export default navLocations;
import { ApprovalRequestController } from '@cbtravel/common/lib/shared/api/approval/controllers/approval-request-controller';
import { CustomFieldController } from '@cbtravel/common/lib/shared/api/general/controllers/custom-field-controller';
import { TimeZoneController } from '@cbtravel/common/lib/shared/api/general/controllers/time-zone-controller';
import { ActiveSearchType } from '@cbtravel/common/lib/shared/common/enumerations/active-search-type';
import { ApprovalStatus } from '@cbtravel/common/lib/shared/common/enumerations/approval-status';
import { EntityDepth } from '@cbtravel/common/lib/shared/common/enumerations/entity-depth';
import { Gender } from '@cbtravel/common/lib/shared/common/enumerations/gender';
import { CustomFieldSourceSearchType } from '@cbtravel/common/lib/shared/common/enumerations/search-types';
import { CustomFieldSortType } from '@cbtravel/common/lib/shared/common/enumerations/sort-types';
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { PagedList } from '@cbtravel/common/lib/shared/common/paged-list';
import { RegexUtils } from '@cbtravel/common/lib/shared/common/regex-utils';
import { CustomFieldListFields } from '@cbtravel/common/lib/shared/messages/approval/responses/custom/custom-field-list-fields';
import { CustomFieldMobileVerifyRQ } from '@cbtravel/common/lib/shared/messages/general/requests/custom-field-mobile-verify-rq';
import { CustomFieldRQ } from '@cbtravel/common/lib/shared/messages/general/requests/custom-field-rq';
import { MobilePhoneVerificationRQ } from '@cbtravel/common/lib/shared/messages/general/requests/custom/mobile-phone-verification-rq';
import { TimeZoneRQ } from "@cbtravel/common/lib/shared/messages/general/requests/time-zone-rq";
import { UserEnrollRQ } from '@cbtravel/common/lib/shared/messages/general/requests/user-enroll-rq';
import { CustomFieldRS } from '@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs';
import { MobilePhoneVerificationRS } from '@cbtravel/common/lib/shared/messages/general/responses/custom/mobile-phone-verification-rs';
import { TimeZoneRS } from "@cbtravel/common/lib/shared/messages/general/responses/time-zone-rs";
import DateFnsUtils from '@date-io/date-fns';
import { CustomFieldValueRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-value-rs";
import {
  Box,
  Button, Dialog,
  DialogContent,
  DialogContentText,
  Grid, IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Error as NoteIcon, TodayOutlined } from "@material-ui/icons";
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { forEachLeadingCommentRange } from 'typescript';
import { useCustomSnackbar } from '../../components/shared/customHooks/useCustomSnackbar';
import Spinner from '../../components/shared/Spinner';
import VerificationDialog from '../../components/ui/Dialog/VerificationDialog';
import { PhoneInput } from "../../components/ui/Input/PhoneInput";
import { CloseIcon } from '../../icons/Icons';
import { getCustomFieldsListFields, getU99FieldsListFields } from "../../pages/CompanyAdmin/Users/UserForm";
import { formUtils } from '../../util/form-utils';
import { phoneUtil } from '../../util/phone-util';
import EnrollCustomFields from './EnrollCustomFields';
import EnrollMessage from './EnrollMessage';
import { CustomFieldSource } from '@cbtravel/common/lib/shared/common/enumerations/custom-field-source';
import { CustomFieldAccessibility } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-accessibility";
import { InputType } from '@cbtravel/common/lib/web/common/enumerations/input-type';
import { InputDataType } from "@cbtravel/common/lib/web/common/enumerations/input-data-type";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      "#pendo-image-badge-5c3c4f83": {
        display: "none !important",
      },
    },
    root: {
      "& .MuiButton-containedSecondary": {
        color: "#fff",
        padding: "6px 20px 9px",
      },
      "& .MuiGrid-container": {
        margin: "0 !important",
      },
      "& .MuiGrid-grid-xs-12": {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      "& .MuiTypography-h1": {
        lineHeight: '1.3',
        fontSize: '30px',
        fontWeight: 300,
      },
    },
    container: {
      "& .MuiGrid-container": {
        margin: "0 !important",
      },
      "& .MuiGrid-spacing-xs-3": {
        width: '100%',
      },
    },
    title: {
      flexGrow: 1,
      paddingTop: "2px",
    },
    topBanner: {
      backgroundColor: '#1f2532',
    },
    content: {
      flexGrow: 1,
      overflowY: "auto",
      minHeight: "100vh",
      width: "100%",
      background: "#fff",
    },
    logo: {
      height: 24,
    },
    iconography: {
      height: 255,
    },
    tooltip: {
      marginTop: 0,
      color: "#fff",
      backgroundColor: "rgba(77, 77, 77, .9)",
      "& .MuiSvgIcon-root": {
        width: "1.3em",
        fontSize: "1.1rem",
      },
    },
    accountHelp: {
      cursor: "help",
      width: 250,
      "& .MuiSvgIcon-root": {
        fontSize: "1rem",
        width: "1.5em",
      },
    },
    helpText: {
      color: "#fff",
      fontSize: "0.75rem",
      lineHeight: 1.6,
      padding: theme.spacing(1),
    },
    errorText: {
      paddingTop: 4,
      paddingBottom: '2px'
    },
    errorTextDropDown: {
      paddingTop: 1,
      paddingBottom: '2px'
    },
    errorTextCalendar: {
      paddingTop: '2px',
    },
    instructions: {
      "& .MuiTypography-body2": {
        lineHeight: '1.5',
      },
    },
    dialogPadding: {
      "& .MuiDialogContent-root:first-child": {
        paddingTop: 0,
      },
      "& .MuiTypography-h1": {
        lineHeight: '1.3',
      },
      "& .MuiTypography-body2": {
        lineHeight: '1.5',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    terms: {
      fontSize: '12px',
      color: '#585B64',
    },
    notsticky: {
      bottom: '0',
      padding: "20px 15px",
    },
  })

);

export type EnrollFormInputs = {
  AccountNumber: string,
  Email: string,
  MobilePhone: string,
  LastName: string,
  MiddleName: string,
  FirstName: string,
  DateOfBirth: Date | null,
  Gender: string,
  u99Field: CustomFieldListFields,
  customFieldList: Array<CustomFieldListFields>,
  TimeZoneOption: TimeZoneOption,
};

interface TimeZoneOption {
  label: string,
  timeZone: TimeZoneRS
}


export default function Enroll() {
  const classes = useStyles();
  const [showVerificationDialog, setShowVerificationDialog] = useState<boolean>(false); //whether or not the dialog displays for single approve/deny
  const [verificationRequest, setVerificationRequest] = useState<MobilePhoneVerificationRQ>();
  const [displayCustomFieldForm, setDisplayCustomFieldForm] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [verifyToken, setVerifiyToken] = useState<MobilePhoneVerificationRS>(new MobilePhoneVerificationRS());
  const [customFieldListFields, setCustomFieldListFields] = useState<CustomFieldListFields[]>(new Array<CustomFieldListFields>());
  const [displayTimeZone, setDisplayTimeZone] = useState<boolean>(true);
  const [timeZoneOptions, setTimeZoneOptions] = useState<TimeZoneOption[]>([]);
  const snackbar = useCustomSnackbar();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [customFields, setCustomFields] = useState<CustomFieldRS[]>();
  const [displayEnrollMessage, setDisplayEnrollMessage] = useState<boolean>(false);
  const [enrollMessageType, setEnrollMessageType] = useState<ApprovalStatus>();



  //on component mount
  useEffect(() => {
    // check for account number in url and pre-populate field if one is found
    const path = window.location.pathname;
    if (path.length > 7) {
      let acctNum = path.slice(8);
      // just in case we have a trailing slash or need to modify later to include queries
      if (acctNum.includes("/")) {
        const address = acctNum.indexOf("/");
        acctNum = acctNum.slice(0, address);
      }
      setValue("AccountNumber", acctNum);
    }

    getAndSetTimeZones();
  }, []);

  useEffect(() => {
    if (customFields) {
      let input = new CustomFieldValueRS();
      let currentValue = getValues('u99Field');
      if (currentValue && typeof currentValue?.value !== 'string')
        input = currentValue.value;

      let propsForm = getValues();

      let form: EnrollFormInputs = {
        AccountNumber: propsForm.AccountNumber,
        Email: propsForm.Email,
        FirstName: propsForm.FirstName,
        MiddleName: propsForm.MiddleName,
        LastName: propsForm.LastName,
        MobilePhone: propsForm.MobilePhone,
        Gender: propsForm.Gender,
        DateOfBirth: propsForm.DateOfBirth,
        TimeZoneOption: propsForm.TimeZoneOption,
        u99Field: getU99FieldsListFields(customFields, input),
        customFieldList: getCustomFieldsListFields(customFields)
      };
      reset(form);
    }
  }, [customFields])

  useEffect(() => {
    if (verifyToken.mobileToken) {
      let accountNumber = getValues("AccountNumber");
      getCustomFields(accountNumber);
    }
  }, [verifyToken.mobileToken])

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control,
    setValue,
    getValues,
    setError,
    reset,
  } = useForm<EnrollFormInputs>({
    mode: "onBlur",
    defaultValues: {
      AccountNumber: "",
      Email: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      MobilePhone: "",
      Gender: "",
      DateOfBirth: null,
      TimeZoneOption: { label: "", timeZone: new TimeZoneRS() },
      customFieldList: customFieldListFields,
    }
  });
  const formRules = {
    accountNumber: {
      validate: {
        required: (value: string) => {
          if (!value) {
            return "Please enter a valid account number";
          }
          if (value.length > 50) {
            return "Max 50 characters";
          }
        },
      },
    },
    email: {
      required: "Please enter a valid email",
      pattern: {
        value: RegexUtils.EMAIL_ADDRESS,
        message: "Please enter a valid email address",
      },
      validate: {
        value: (value: string) => { if (value) { if ((/\s/).test(value)) { return "Please enter a valid email" } } },
      },
      maxLength: { value: 255, message: "Max 255 characters" },
    },
    mobilePhone: {
      validate: {
        required: (value: string) => {
          if (value === undefined)
            return "Please enter a valid Mobile Phone";
          else if (value.trim().length === 0)
            return "Please enter a valid Mobile Phone";
        },
        value: (value: string) => {
          if (value && !phoneUtil.isPossibleNumber(value)) {
            return "Please enter a valid phone number"
          }
        }
      },
    },
    firstName: {
      required: "Please enter a valid first name",
      maxLength: { value: 50, message: "Max 50 characters" },
    },
    middleName: { maxLength: { value: 50, message: "Max 50 characters" } },
    lastName: {
      required: "Please enter a valid last name",
      maxLength: { value: 50, message: "Max 50 characters" },
    },
    gender: {
      validate: {
        required: (value: string) => {
          if (!value || value.trim().length === 0) return "Please select a gender";
        }
      }
    },
    dateOfBirth: {
      validate: {
        required: (value: Date | null) => {
          if (!value) return "Please enter your date of birth";
        },
        DateInFuture: (value: Date | null) => {
          let currentDate = new Date();
          if (value && value > currentDate) {
            return "date can not be in the future"
          }
        },
        MinDate: (value: Date | null) => {
          let minDate = new Date("1900-01-01T00:00:00");
          if (value && value <= minDate) {
            return "Date should not be before minimal date";
          }
        },
        pattern: (value: Date | null) => {
          let date: Date | null = null;
          if (value != null) {
            date = new Date(value);
          }
          if (!(date instanceof Date)
            || (date.getTime() !== date.getTime()) // if this is invalid, it will be NaN, which is never equal to NaN
          ) {
            return "Invalid date format"
          }
        },
      }
    },
    timeZoneOption: {
      validate: {
        required: (value: TimeZoneOption | null) => {
          // only required if we don't already have a timezone
          if (displayTimeZone) {
            if (!value || value.timeZone.timeZoneId === -1)
              return "Please select a valid timezone"
          }
        }
      }
    }
  };

  /** Close cancel dialog  */
  function handleCloseDialog() {
    setOpenCancelDialog(false);
  }

  /** Display cancel dialog  */
  function handleOpenDialog() {
    setOpenCancelDialog(true);
  }

  /** close and clear form */
  function handleCancelEnrollment() {
    setOpenCancelDialog(false);
    resetAllFields();
  }

  /** close and clear form while in EnrollCustomFields.tsx */
  function handleCancelEnrollCustomFields() {
    setDisplayCustomFieldForm(false);
    resetAllFields();
  }

  /**
   * Gets and sets time zone option for the dropdown.
   */
  async function getAndSetTimeZones() {
    const timeZoneRQ = new TimeZoneRQ();

    try {
      const timeZonePagedList: PagedList<TimeZoneRS> = await TimeZoneController.Find(timeZoneRQ);
      const timeZoneList = timeZonePagedList.list;

      //get local timezone
      let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let tzInd = timeZoneList.findIndex(
        (ele, ind) => ele.name === localTimezone
      );

      // if there is no timezone, we need to show the timezone selector
      if (tzInd !== -1) {
        let currentTimeZone: TimeZoneRS = timeZoneList[tzInd];
        setDisplayTimeZone(false);

        // set timezone
        setValue("TimeZoneOption", { label: currentTimeZone.name, timeZone: currentTimeZone });
      }
      else {
        // set the options in order to display them
        setTimeZoneOptions(timeZoneList.map((t) => {
          return { label: t.name, timeZone: t }
        }));
      }
    } catch (e) {
      snackbar.error(e as JsonException);
    }
  };

  /**
   * Clears the form except for account number and time zone.
   */
  function resetAllFields() {
    let current = getValues();
    reset({
      AccountNumber: current.AccountNumber,
      Email: "",
      FirstName: "",
      LastName: "",
      MiddleName: "",
      MobilePhone: "",
      Gender: "",
      DateOfBirth: null,
      customFieldList: new Array<CustomFieldListFields>(),
    });
  }

  /**
   * Handles opening the verification dialog.
   */
  function handleOpenVerificationDialog() {
    // if the user chooses to edit their phone number, we will have the input box focused automatically
    document.getElementById("mobile-phone")?.focus();
    let mobilePhoneVerificationRQ = new MobilePhoneVerificationRQ();
    mobilePhoneVerificationRQ.accountNumber = getValues("AccountNumber");
    mobilePhoneVerificationRQ.mobilePhoneNumber = getValues("MobilePhone");

    setVerificationRequest(mobilePhoneVerificationRQ);
    setShowVerificationDialog(true);
  }

  /**
   * Gets and sets the list of custom fields for the user's client.
   */
  async function getCustomFields(accountNumber: string, clientId: number = 0) {
    setShowSpinner(true);
    // Get customFields
    let request: CustomFieldRQ = new CustomFieldRQ();
    // Can't use Client Id, as it's not being populated in /Enroll
    request.sortType = CustomFieldSortType.ByPosition;
    request.activeSearchType = ActiveSearchType.Active;
    request.customFieldSourceSearchType = CustomFieldSourceSearchType.Profile;

    let customFieldMobileVerifyRQ = new CustomFieldMobileVerifyRQ();
    customFieldMobileVerifyRQ.mobilePhoneVerificationRS = verifyToken;
    customFieldMobileVerifyRQ.customFieldRQ = request;
    customFieldMobileVerifyRQ.accountNumber = accountNumber;
    if (clientId)
      customFieldMobileVerifyRQ.customFieldRQ.clientId = clientId;

    try {
      let newCustomFields: CustomFieldRS[] = (await CustomFieldController.FindMobileVerify(customFieldMobileVerifyRQ, EntityDepth.Infinite)).list;
      let emptyDefaultFields = newCustomFields.filter(cf => cf.defaultValue === '' || cf.number === 99);
      //if new client has u99, replace the old one. We want to keep the old one otherwise
      let newU99 = emptyDefaultFields.find(e => e.number === 99);
      let oldU99 = customFields?.find(e => e.number === 99);
      if (!newU99 && oldU99) {
        emptyDefaultFields.push(oldU99);
      }

      // no Custom Fields without default values, skip to enroll 
      if (emptyDefaultFields.length === 0) {
        let enrollRQ: UserEnrollRQ = new UserEnrollRQ();
        let form: EnrollFormInputs = getValues();

        enrollRQ.clientAccountNumber = form.AccountNumber;
        enrollRQ.emailAddress = form.Email;
        enrollRQ.firstName = form.FirstName;
        enrollRQ.middleName = form.MiddleName;
        enrollRQ.lastName = form.LastName;
        enrollRQ.phoneCell = form.MobilePhone;
        enrollRQ.gender = form.Gender;
        enrollRQ.dateOfBirth = form.DateOfBirth as Date;
        enrollRQ.defaultTimeZoneId = form.TimeZoneOption.timeZone.timeZoneId;

        await Enroll(enrollRQ);

        return; // return before setting the custom fields
      } else {
        setCustomFields(emptyDefaultFields);
        setDisplayCustomFieldForm(true);
      }
    } catch (e) {
      // Hightlight the Account number if it is invalid.
      let exception = e as JsonException;
      exception.exceptionDetailList.forEach(element => {
        if (element.message == "AccountNumber is invalid.") {
          setError("AccountNumber", { message: 'Account Number is invalid' });
        }
      });

      snackbar.error(e as JsonException)
    } finally {
      setShowSpinner(false);
    }
  }



  async function Enroll(enrollRQ: UserEnrollRQ): Promise<void> {
    try {
      let response = await ApprovalRequestController.Enroll(enrollRQ);

      if (response.ok) {
        // Enrollment successful
        setEnrollMessageType(ApprovalStatus.Approved);
      } else if (response.status === 460) {
        // Account is pending
        setEnrollMessageType(ApprovalStatus.Pending);
      } else if (response.status === 470) {
        // Account already exists
        setEnrollMessageType(ApprovalStatus.Denied);
      }

      setDisplayEnrollMessage(true);
    } catch (e) {
      snackbar.error(e as JsonException);
    }
  }

  return (
    <React.Fragment>
      {showSpinner && <Spinner />}
      <div className={classes.content}>
        <div className={classes.root}>
          <div className={classes.topBanner}>
            <Box display="flex" flexDirection="row" py={2.5} ml={2}>
              <img
                src={`${process.env.PUBLIC_URL}/img/Christopherson_logo_rev.png`}
                className={classes.logo}
                id="logo"
                alt="cbt logo"
              />
            </Box>
          </div>
          {displayEnrollMessage ?
            <EnrollMessage messageType={enrollMessageType} />
            :
            displayCustomFieldForm ?
              <EnrollCustomFields
                control={control}
                errors={errors}
                reset={reset}
                handleSubmit={handleSubmit}
                isValid={isValid}
                isDirty={isDirty}
                handleCancelEnrollment={handleCancelEnrollCustomFields}
                enroll={Enroll}
                getValues={getValues}
                getCustomFields={getCustomFields}
              />
              :
              <Grid container>
                <Grid container>
                  <Grid item xs={12}>
                    <Box pt={5} ml={1.5}>
                      <Typography variant="h1">
                        Request a new enrollment</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography component="h2">
                        <Box fontSize={14} fontWeight={600} my={2}>
                          Current Account
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="AccountNumber"
                        control={control}
                        defaultValue=""
                        rules={formRules.accountNumber}
                        render={({ field: { onChange, value, ref, onBlur } }) => (
                          <TextField
                            id="account-number"
                            label="Account number"
                            variant="outlined"
                            size="small"
                            onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                            onChange={(e) => {
                              return onChange(e.target.value);
                            }}
                            value={value}
                            ref={ref}
                            error={errors.AccountNumber ? true : false}
                            fullWidth
                            disabled={window.location.pathname.length > 8}
                          />
                        )}
                      />
                      <Typography
                        variant="body2"
                        color="error"
                        className={classes.errorText}
                      >
                        {errors.AccountNumber && errors.AccountNumber.message}
                      </Typography>
                      <div className={classes.accountHelp}>
                        <Tooltip
                          disableFocusListener
                          enterTouchDelay={100}
                          placement="bottom-start"
                          classes={{ tooltip: classes.tooltip }}
                          interactive={true}
                          title={
                            <React.Fragment>
                              <Typography classes={{ root: classes.helpText }}>
                                Contact your travel manager or look for an email with a
                                direct link that has the account number pre-filled
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <Box component="div" display="flex" py={0.5} fontSize={12}>
                            <NoteIcon color="primary" /> How to find your account number
                          </Box>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="h2">
                        <Box fontSize={14} fontWeight={600} mt={3} mb={.5}>
                          Full legal name as it appears on government-issued I.D.
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.container}>
                    <Grid container spacing={3} alignContent="flex-end">
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Controller
                          name="FirstName"
                          control={control}
                          defaultValue=""
                          rules={formRules.firstName}
                          render={({ field: { onChange, value, ref, onBlur } }) => (
                            <TextField
                              id="first-name"
                              label="Legal first name"
                              variant="outlined"
                              size="small"
                              onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                              error={errors.FirstName ? true : false}
                              onChange={onChange}
                              value={value}
                              ref={ref}
                              fullWidth
                            />
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="error"
                          className={classes.errorText}
                        >
                          {errors.FirstName && errors.FirstName.message}
                        </Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Controller
                          name="MiddleName"
                          control={control}
                          defaultValue=""
                          rules={formRules.middleName}
                          render={({ field: { onChange, value, ref, onBlur } }) => (
                            <TextField
                              id="middle-name"
                              label="Legal middle name (optional)"
                              variant="outlined"
                              size="small"
                              onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                              onChange={onChange}
                              value={value}
                              ref={ref}
                              fullWidth
                            />
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="error"
                          className={classes.errorText}
                        >
                          {errors.MiddleName && errors.MiddleName.message}
                        </Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Controller
                          name="LastName"
                          control={control}
                          defaultValue=""
                          rules={formRules.lastName}
                          render={({ field: { onChange, value, ref, onBlur } }) => (
                            <TextField
                              id="last-name"
                              label="Legal last name"
                              variant="outlined"
                              size="small"
                              onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                              error={errors.LastName ? true : false}
                              onChange={onChange}
                              value={value}
                              ref={ref}
                              fullWidth
                            />
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="error"
                          className={classes.errorText}
                        >
                          {errors.LastName && errors.LastName.message}
                        </Typography>
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Controller
                          name="Gender"
                          control={control}
                          rules={formRules.gender}
                          render={({
                            field: { onChange, value, ref, onBlur }
                          }) => (
                            <TextField
                              select
                              variant="outlined"
                              margin="dense"
                              id="legalGender"
                              label="Legal gender"
                              fullWidth
                              onChange={onChange}
                              ref={ref}
                              onBlur={onBlur}
                              value={value}
                              error={errors.Gender ? true : false}
                              InputProps={{ autoComplete: "off" }}
                            >
                              {Object.values(Gender).map((g, i) => (
                                <MenuItem key={i} value={g} style={{ minHeight: "2em" }}>
                                  {g}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Controller
                          name="DateOfBirth"
                          control={control}
                          rules={formRules.dateOfBirth}
                          render={({
                            field: { onChange, value, ref, onBlur }
                          }) => (
                            <>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableFuture
                                  margin="dense"
                                  id="dob-picker"
                                  label="Date of birth"
                                  openTo="year"
                                  format="MM/dd/yyyy"
                                  placeholder="MM/DD/YYYY"
                                  maxDateMessage={""}
                                  minDateMessage={""}
                                  invalidDateMessage=""
                                  views={["year", "month", "date"]}
                                  inputVariant="outlined"
                                  value={value}
                                  onBlur={onBlur}
                                  onClose={onBlur}
                                  onChange={onChange}
                                  fullWidth
                                  InputProps={{
                                    autoComplete: "off",
                                    endAdornment: (
                                      <InputAdornment position={'end'}>
                                        <IconButton color="primary" aria-label="Date of birth">
                                          <TodayOutlined />
                                        </IconButton>
                                      </InputAdornment>)
                                  }}
                                  error={errors.DateOfBirth ? true : false}
                                />
                              </MuiPickersUtilsProvider>
                              <Typography variant='body2' color='error' className={classes.errorTextCalendar}>
                                {errors.DateOfBirth && errors.DateOfBirth.message}
                              </Typography>
                            </>
                          )} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Controller
                          name="MobilePhone"
                          control={control}
                          defaultValue=""
                          rules={formRules.mobilePhone}
                          render={({ field: { onChange, value, ref, onBlur } }) => (
                            <Box mt={1}>
                              <PhoneInput
                                id="mobile-phone"
                                label="Mobile phone"
                                placeholder="Enter phone number"
                                variant="outlined"
                                size="small"
                                defaultCountry="US"
                                international={false}
                                onBlur={onBlur}
                                error={errors.MobilePhone ? true : false}
                                onChange={onChange}
                                value={value}
                                fullWidth
                              />
                            </Box>
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="error"
                          className={classes.errorText}
                        >
                          {errors.MobilePhone && errors.MobilePhone.message}
                        </Typography>
                      </Grid>
                      {displayTimeZone && <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Controller
                          name="TimeZoneOption"
                          control={control}
                          defaultValue={{ label: "", timeZone: new TimeZoneRS() }}
                          rules={formRules.timeZoneOption}
                          render={({ field: { onChange, value, ref, onBlur } }) => (
                            <Autocomplete
                              options={timeZoneOptions}
                              getOptionLabel={(option) => option.label}
                              onChange={(e, newValue) => { onChange(newValue) }}
                              onBlur={onBlur}
                              value={value && timeZoneOptions.find(t => t.label === value.label)}
                              ref={ref}
                              renderInput={(params) => (
                                <Box mt={1}>
                                  <TextField
                                    {...params}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    label="Time Zone"
                                    variant="outlined"
                                    size="small"
                                    id="timeZone"
                                    error={errors.TimeZoneOption ? true : false}
                                  /></Box>
                              )}
                            />
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="error"
                          className={classes.errorText}
                        >
                          {errors.TimeZoneOption && (errors.TimeZoneOption as any).message}

                        </Typography>
                      </Grid>}
                      <Grid item xs={12}>
                        <Controller
                          name="Email"
                          control={control}
                          defaultValue=""
                          rules={formRules.email}
                          render={({ field: { onChange, value, ref, onBlur } }) => (
                            <TextField
                              id="email"
                              label="Email"
                              variant="outlined"
                              size="small"
                              onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                              error={errors.Email ? true : false}
                              onChange={onChange}
                              value={value}
                              ref={ref}
                              fullWidth
                            />
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="error"
                          className={classes.errorText}
                        >
                          {errors.Email && errors.Email.message}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                </Grid>

                <Grid item xs={12} sm={3}>
                  <Box display="flex" flexDirection="column" pr={2}>
                    <Paper>
                      <Box className={classes.instructions} p={2}>
                        <Box mt={1}>
                          <Typography variant="body2">
                            We need some basic information to create your account.
                          </Typography>
                        </Box>
                        <Box my={1}>
                          <Typography variant="body2">
                            After completing this form, click "Continue" below, then we'll have you verify your mobile phone number for security. Once you do, your enrollment request will be sent to your travel manager for approval.                        </Typography>
                        </Box>
                      </Box>
                    </Paper>
                    {/* Save and cancel buttons here. */}
                    <Box mt={10}>
                      <Button fullWidth variant="contained" color="primary" id="saveButton"
                        disabled={!(isDirty && isValid)}
                        onClick={handleOpenVerificationDialog}>
                        Continue
                      </Button>
                    </Box>
                    <Box id="box_2" mt={1}>
                      <Button fullWidth variant="outlined" color="primary" id="cancelButton" onClick={handleOpenDialog}>
                        Cancel and close
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.notsticky} >
                    <Typography className={classes.terms} component="p">
                      <Box fontSize={12} py={2}>
                        By enrolling, you agree to the{" "}
                        <Link
                          href="https://app.cbtat.com/legal/terms"
                          color="inherit"
                          target="_blank"
                          underline="always"
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          href="https://app.cbtat.com/legal/privacy"
                          color="inherit"
                          target="_blank"
                          underline="always"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </Box>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
          }
        </div> {/* /root */}
      </div> {/* /content */}

      {/* This should trigger the verification dialog */}
      <VerificationDialog
        openActionDialog={showVerificationDialog}
        setOpenActionDialog={setShowVerificationDialog}
        positiveText="Verify and enroll"
        request={verificationRequest}
        successAction={() => { setShowSpinner(true); }}
        setVerifyToken={setVerifiyToken}
      />

      {/* Cancel dialog box */}
      <Dialog
        maxWidth='xs'
        open={openCancelDialog}
        // onClose={handleCloseAll}
        aria-labelledby="form-dialog-title"
      >
        <Box className={classes.dialogPadding} p={2} display="flex" flexDirection="column" justifyContent="center">
          <DialogContent>
            {/* The 'x' icon on the modal. */}
            <IconButton id="closeIcon" aria-label="close" className={classes.closeButton}
              onClick={handleCloseDialog}
            >
              <CloseIcon width={"20"} height={"20"} color={"#00467E"} />
            </IconButton>
            <DialogContentText>
              <Box mt={6} mb={6}>
                <Typography align="center" variant="h1">Are you sure you<br /> want to cancel?</Typography>
                <Box mt={2} mb={1}>
                  <Typography align="center" variant="body2">
                    If you close this screen now, your progress will be lost and your new enrollment will not be submitted.
                  </Typography>
                </Box>
              </Box>
            </DialogContentText>
            <Box>
              <Button fullWidth onClick={handleCloseDialog} variant="contained" color="primary">
                Continue enrollment request
              </Button>
            </Box>
            <Box my={1}>
              <Button fullWidth variant="outlined" color="primary"
                onClick={handleCancelEnrollment}>
                Cancel and close
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog >

    </React.Fragment >
  );
}
import { UserRS } from '@cbtravel/common/shared/messages/general/responses/user-rs';
import { Box, Dialog, IconButton, Menu, MenuItem, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import HistoryRoundedIcon from "@material-ui/icons/HistoryRounded";
import { DialogTitle } from '../../TravelApproval/Approvals/HistoryDialog';
import { dateUtil } from "../../../util/date-util";
import { useState, useContext, Dispatch,SetStateAction, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ActionDialog from "../../../components/ui/Dialog/ActionDialog";
import { UserLogRQ } from '@cbtravel/common/lib/web/messages/general/requests/user-log-rq';
import { UserLogRS } from '@cbtravel/common/lib/web/messages/general/responses/user-log-rs';
import { UserLogController } from '@cbtravel/common/lib/shared/api/general/controllers/user-log-controller';
import { UserController } from '@cbtravel/common/lib/shared/api/general/controllers/user-controller';
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import { PagedList } from '@cbtravel/common/lib/shared/common/paged-list';
import { UserContext } from '../../../components/shared/UserContext';
import { JsonException } from '@cbtravel/common/lib/shared/common/exceptions/json-exception';
import { ClientSettingsController } from '@cbtravel/common/lib/shared/api/general/controllers/client-settings-controller';
import { ServiceRequestController } from '@cbtravel/common/lib/shared/api/general/controllers/service-request-controller';
import { ProfileController } from "@cbtravel/common/lib/shared/api/profiles/profile-controller";
import { ClientSettingRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-setting-rs';
import { ServiceRequestRS } from '@cbtravel/common/lib/web/messages/general/responses/service-request-rs';
import { EntityDepth } from '@cbtravel/common/lib/shared/common/enumerations/entity-depth';
import { PhoneType } from '@cbtravel/common/lib/shared/common/enumerations/phone-type';
import { ProfileEntry } from '@cbtravel/common/lib/shared/common/enumerations/profile-entry';
import { ProfileRS } from '@cbtravel/common/lib/shared/messages/profiles/responses/profile-rs';

// Icon imports
import { EditIcon } from "../../../icons/Icons";
import Spinner from '../../../components/shared/Spinner';
import { MoreVert, Person, Send } from '@material-ui/icons';

interface UserSearchDataRowProps {
    /** Legal first name */
    firstName: string,
    /** Legal last name */
    lastName: string,
    /** Email */
    email: string,
    /** User Id */
    userId: number,
    user: UserRS
}

const useStyles = makeStyles((theme: Theme) => ({
    activeBadge: {
        borderRadius: 4, background: '#ECF1E6', padding: '2px 6px', height: 'auto', textAlign: 'center', display: 'inline-flex',
        '& .MuiTypography-body1': {
            color: '#417505', fontSize: 11, textTransform: 'uppercase',
        }
    },
    inactiveBadge: {
        borderRadius: 4, background: 'rgba(201, 199, 199, .2)', padding: '2px 6px', height: 'auto', textAlign: 'center', display: 'inline-flex',
        '& .MuiTypography-body1': {
            color: '#808080', fontSize: 11, textTransform: 'uppercase',
        }
    },
    pointer: {
        cursor: "pointer",
    },
    tableContainer: {
        padding: "0 16px 16px",
        "& .MuiTypography-h3": {
            fontSize: ".9rem",
            fontWeight: 700,
            margin: "32px 0 12px 8px",
        },
    },
    table: {
        "& .MuiTableCell-head": {
            fontWeight: 700,
        },
        "& .MuiTableCell-root": {
            lineHeight: 2,
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1rem",
            top: ".2em",
            position: "relative",
        },
        "& .MuiButton-outlinedSizeSmall": {
            padding: "1px 0 3px",
            fontSize: "0.75rem",
            fontWeight: 700,
            margin: "5px 0",
        },
    },
    dialogContent: {
        lineHeight: 1.5,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    actionMenuItem: {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        cursor: "pointer"
    }
}));


interface ActionButtonsProps {
    isActive: boolean,
    isPending: boolean,
    setShowDialog: Dispatch<SetStateAction<boolean>>
}

function ActionButtons(props: ActionButtonsProps) {
        let actionsCell =
            <Switch
                onChange={() => props.setShowDialog(true)}
                checked={props.isActive}
                name="activate"
                color="secondary"
                size="small"
            />
        if (props.isPending && !props.isActive)
            actionsCell = <a>Pending</a>;
        return (
            <TableCell>
                {actionsCell}
            </TableCell>
        )
}
    
export default function UserSearchDataRow(props: UserSearchDataRowProps) {
    const classes = useStyles();
    const routerHistory = useHistory();
    const { firstName, lastName, email, userId, user } = props;
    const { userContext } = useContext(UserContext);
    const snackbar = useCustomSnackbar();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showEmailDialog, setShowEmailDialog] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const [isActive, setIsActive] = useState<boolean>(props.user.activeStatus);
    const [isPending, setIsPending] = useState<boolean>(props.user.isPending);

    function handleOnClick() {
        routerHistory.push("/user/profile?email=" + email);
    }

    function handleClickOptions(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    };
    function handleCloseOptions(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(null);
    };
    useEffect(() => {
        setIsActive(props.user.activeStatus);
        setIsPending(props.user.isPending)
    },
        [props.user.activeStatus, props.user.isPending])

    async function handlePositiveAction() {
        try {
            setShowSpinner(true);
            if (isActive) {
                try {
                    //ProfileController.Delete will delete the profile and deactivate the user
                    let responseData = await ProfileController.Delete(userContext.accessToken, user.userId);
                    user.activeStatus = responseData.status === 204 ? false : user.activeStatus;
                    setIsActive(false);
                }
                catch (e) {
                    snackbar.error(e as JsonException)
                }
            }
            else {
                let clientSettings: ClientSettingRS = await ClientSettingsController.GetRootByClientId(userContext.accessToken, user.client.clientId, EntityDepth.Shallow);
                if (clientSettings.obeId != -1) { //if it's a OBT user
                    //create Jira ticket
                    let serviceRequestRS: ServiceRequestRS = new ServiceRequestRS();
                    serviceRequestRS.reporterEmail = userContext.email;
                    serviceRequestRS.serviceRequestKey = "OS";
                    serviceRequestRS.summary = "User re-activation, please remove the termination date";
                    serviceRequestRS.description =
                        "Client Name " + user.client.name +
                        "\nAccount # " + user.client.accountNumber +
                        "\nTraveler First name " + user.firstName +
                        "\nTraveler Last name " + user.lastName +
                        "Traveler Email address " + user.emailAddress;
                    await ServiceRequestController.Add(userContext.accessToken, serviceRequestRS)
                    user.isPending = true;
                    await UserController.Update(userContext.accessToken, user, EntityDepth.Shallow);
                    setIsPending(true);
                }
                else {
                    let profileRS: ProfileRS = new ProfileRS();
                    profileRS.client.clientId = user.client.clientId;
                    profileRS.client.accountNumber = user.client.accountNumber;
                    profileRS.emailAddress = user.emailAddress;
                    profileRS.emailAddress = user.emailAddress;
                    profileRS.firstName = user.firstName;
                    profileRS.middleName = user.middleName;
                    profileRS.lastName = user.lastName;
                    // Add Mobile phone (if exists) to profileRS
                    let mobilePhone = user.phoneList.find(input => input.phoneType === PhoneType.Mobile);
                    if (mobilePhone) profileRS.phoneList.push(mobilePhone);
                    profileRS.defaultTimeZoneId = user.defaultTimeZoneId;
                    profileRS.defaultLanguage = user.defaultLanguage;
                    profileRS.defaultCurrency = user.defaultCurrency;
                    profileRS.userType = user.userType;
                    let responseData = await ProfileController.Initialize(userContext.accessToken, profileRS, EntityDepth.Shallow, ProfileEntry.ProfileByProxy);
                    user.activeStatus = responseData.activeStatus;
                    setIsActive(true);
                }
            }
            setShowSpinner(false);
            snackbar.info(isActive ? "User deactivated" : "User reactivated");
        }
        catch (e) {
            snackbar.error(e as JsonException)
            setShowSpinner(false);
        }
    }
    

    return (
        <>
            {showSpinner && <Spinner />}
            <TableRow>
                <ActionButtons
                isActive= {props.user.activeStatus}
                isPending = {isPending}
                setShowDialog = {setShowDialog}   
                />
                <TableCell component="th">
                    <Box className={classes.pointer} mt={-.5} onClick={handleOnClick}>
                        <Typography style={{ textDecoration: 'underline' }}>
                            {firstName}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell>
                    <Box className={classes.pointer} mt={-.5} onClick={handleOnClick}>
                        <Typography style={{ textDecoration: 'underline' }}>
                            {lastName}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell>
                    {email}
                </TableCell>
                <TableCell align="center">
                    <Box className={classes.pointer} mt={-.5} onClick={handleClickOptions}>
                        <MoreVert />
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseOptions}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleOnClick}>
                            <Box className={classes.actionMenuItem}>
                                <Person height="22" width="20" />
                                <Typography>
                                    View profile
                                </Typography>
                            </Box>
                        </MenuItem>
                        <UserHistoryDialog userId={userId} />
                        <MenuItem onClick={() => setShowEmailDialog(true)}>
                            <Box className={classes.actionMenuItem}>
                                <Send height="22" width="20" />
                                <Typography>
                                    Send profile completion email
                                </Typography>
                            </Box>
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>

            <ActionDialog
                id="dialog-activate"
                title={isActive ? "Deactivate user" : "Request reactivation"}
                positiveText={isActive ? "Deactivate" : "Request"}
                negativeText="Cancel"
                open={showDialog}
                icon={null}
                setOpen={setShowDialog}
                positiveAction={handlePositiveAction}
                negativeAction={() => setShowDialog(false)}
            >
                {isActive ? <p className={classes.dialogContent}>
                    This will delete user data and make this user ineligible for travel, do you wish to continue?
                </p> : <p className={classes.dialogContent}>
                    User will receive an email within 48hrs to complete any missing profile data and will then regain access for booking.
                </p>}

            </ActionDialog>
            <ActionDialog
                id="dialog-send-profile-email"
                title={"Send profile completion reminder?"}
                positiveText={"Send email"}
                negativeText="Cancel"
                icon={null}
                open={showEmailDialog}
                setOpen={setShowEmailDialog}
                positiveAction={() => { UserController.SendProfileCompletionEmail(user.username); setShowEmailDialog(false) }}
                negativeAction={() => setShowDialog(false)}
            />
        </>
    );
}

interface UserHistoryDialogProps {
    userId: number,
}

/**
 * renders the user history dialog
 * @param props 
 * @returns 
 */
function UserHistoryDialog(props: UserHistoryDialogProps) {
    const { userContext } = useContext(UserContext);
    const [open, setOpen] = useState<boolean>(false);
    const [historyList, setHistoryList] = useState<UserLogRS[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const snackbar = useCustomSnackbar();

    function handleClose() {
        setOpen(false);
    }

    async function handleClickOpen() {
        setShowSpinner(true);
        try {
            let userLogRQ: UserLogRQ = new UserLogRQ();
            userLogRQ.fieldListToInclude = ["Active", "Created"];
            userLogRQ.userId = props.userId;

            let response: PagedList<UserLogRS> = await UserLogController.Find(userContext.accessToken, userLogRQ);

            if (response) {
                setOpen(true);
                setHistoryList(response.list);
            }
        } catch (e) {
            snackbar.error(e as JsonException);
        } finally {
            setShowSpinner(false);
        }
    }

    return (<>
        {showSpinner && <Spinner />}
        <MenuItem style={{
            display: "flex", gap: "8px", alignItems: "center", cursor: "pointer"
        }} aria-label="History" onClick={handleClickOpen}>
            <HistoryRoundedIcon />
            <Typography>
                View history
            </Typography>
        </MenuItem>
        <Dialog
            id="user-history-dialog"
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="sm"
            fullWidth={true}>
            <DialogTitle id="dialog-title" onClose={handleClose}>
                User History
            </DialogTitle>
            <UserHistoryTable historyList={historyList} />
        </Dialog>

    </>);
}

interface UserHistoryTableProps {
    historyList: UserLogRS[]
}

/**
 * renders the table for the user history in the history dialog popup
 * @param props 
 * @returns 
 */
function UserHistoryTable(props: UserHistoryTableProps) {
    const classes = useStyles();
    const { userContext } = useContext(UserContext);

    async function getCreatorFullName(userId: number) {
        let user = await UserController.Get(userContext.accessToken, userId)
        console.log(user.firstName + " " + user.lastName);
    }

    return (
        <TableContainer className={classes.tableContainer}>
            <Table
                aria-label="User history table"
                size="small"
                className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Modified</TableCell>
                        <TableCell>Prev Status</TableCell>
                        <TableCell>New Status</TableCell>
                        <TableCell>Modified By</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.historyList.length > 0 && props.historyList.map((userLog, i) => {
                        return (<TableRow key={userLog.userLogId}>
                            <TableCell id={`history-date-created-${i}`}>{dateUtil.formatDate(new Date(userLog.dateCreated), userContext.timeZone)}</TableCell>
                            <TableCell id={`old-value-${i}`}>{userLog.field === 'Created' ? " " : // display nothing before creation
                                userLog.oldValue === 'Yes' ?
                                    <Box className={classes.activeBadge}><Typography component="span">Active</Typography></Box>
                                    : <Box className={classes.inactiveBadge}><Typography component="span">Inactive</Typography></Box>}
                            </TableCell>
                            <TableCell id={`new-value-${i}`}>{userLog.field === 'Created' ? // display the active page after creation
                                <Box className={classes.activeBadge}><Typography component="span">Active</Typography></Box>
                                : userLog.newValue === 'Yes' ?
                                    <Box className={classes.activeBadge}><Typography component="span">Active</Typography></Box>
                                    : <Box className={classes.inactiveBadge}><Typography component="span">Inactive</Typography></Box>}</TableCell>
                            <TableCell id={`created-by-${i}`}>{userLog.createdBy.firstName + " " + userLog.createdBy.lastName}</TableCell>
                        </TableRow>);
                    })}
                </TableBody>
            </Table>
        </TableContainer>);
}


import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { Box, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ActionDialog from "../../../components/ui/Dialog/ActionDialog";
import SaveIcon from "@material-ui/icons/Save";
import CloseDialogIcon from '../../../components/ui/CloseIcon';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

const useStyles = makeStyles(() => ({

    center: {
        textAlign: 'center',
    },
    dialogContent: {
        lineHeight: 1.5,
    },
    strong: {
        fontWeight: 600,
        lineHeight: 1.5,
    },
    iconAction: {
        margin: "25px auto 14px",
        background: "#00467F",
        borderRadius: 40,
        width: "44px",
        height: "44px",
        "& .MuiSvgIcon-root": {
            color: "#fff",
            marginTop: "10px",
        },
    },
    dialogActions: {
        padding: "16px 32px",
        "& .MuiButton-contained:hover": {
            backgroundColor: "rgba(0,166,207, .8)",
        },
    },
    saveFab: {
        marginTop: 30,
        color: "#ffffff",
        backgroundColor: "#00467F",
        boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 1%), 0px 1px 18px 0px rgb(0 0 0 / 11%)",
    },
    closeImg: {
        cursor: 'pointer',
        float: 'right',
        marginTop: '5px',
        width: '20px',
    },
    closeIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
    },
    customFieldPrompt: {
        minWidth: '320px',
        background: '#F9F9F9',
        textAlign: 'center',
        borderRadius: 4,
        padding: '12px 20px 24px',
        margin: '8px 24px',
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
            marginBottom: 12,
            marginTop: 10,
        },
        '& .MuiCheckbox-root': {
            paddingRight: 4,
            paddingTop: 1,
            paddingBottom: 0,
        },
    },
    customFieldPrompt1: {
        minWidth: '320px',
        background: '#FFFFFF',
        textAlign: 'center',
        borderRadius: 4,
        padding: '0px 10px',
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
            marginTop: 10,
        },
        '& .MuiCheckbox-root': {
            paddingRight: 4,
            paddingTop: 1,
            paddingBottom: 0,
        },
    },

}));
export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface SaveActivateDialogProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((v: boolean) => void),
    positiveAction: (active: boolean) => void,
    isActive: boolean
}

export default function SaveActivateDialog(props: SaveActivateDialogProps) {
    const classes = useStyles();

    function handleSaveOnly() {
        props.positiveAction(false);
        props.setOpen(false);
    }
    function handleSaveAndActivate() {
        props.positiveAction(true);
        props.setOpen(false);
    }
    function handleClose() {
        props.setOpen(false);
    }

    return (
        <div>
            <Dialog id="dialog-save" maxWidth={"xs"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <Box className={classes.center}>
                    <Fab
                        aria-label="save"
                        className={classes.saveFab}
                    >
                        <SaveIcon />
                    </Fab>
                </Box>

                {/* Show this section when field is already active. */}
                {props.isActive && <Box>
                    <Box mt={1}>
                        <Typography className={classes.center} variant="h2">Save changes</Typography>
                    </Box>
                    <Box mb={2} className={classes.customFieldPrompt1}>
                        <p className={classes.dialogContent}>
                            There are unsaved changes.<br />
                            Do you wish to save them?
                        </p>
                    </Box>
                    <Box marginX={2}>
                        <Button id="btn-save" fullWidth variant="contained" color="primary" onClick={handleSaveAndActivate}>
                            Save
                        </Button>
                    </Box>
                    <Box mb={2}>
                        <Button id="btn-cancel" fullWidth onClick={handleClose}>Cancel</Button>
                    </Box>
                </Box>}

                {/* Show this section when field has not been activated. */}
                {!props.isActive && <Box>
                    <Box mb={1}>
                        <Typography className={classes.center} variant="h2">Save or activate?</Typography>
                    </Box>
                    <Box mt={4} className={classes.customFieldPrompt}>
                        <p className={classes.dialogContent}>
                            Save changes and leave field inactive?
                        </p>
                        <Button id="btn-save" fullWidth variant="contained" color="primary" onClick={handleSaveOnly}>
                            Save only
                        </Button>
                    </Box>
                    <Box className={classes.customFieldPrompt}>
                        <Typography style={{ lineHeight: '18px', fontSize: 12, }}>Save changes and activate the field? <br />This action cannot be undone. </Typography>
                        <Box mt={2}>
                            <Button fullWidth variant="outlined" color="primary" href="#outlined-buttons" onClick={handleSaveAndActivate}>
                                Save and activate
                            </Button>
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <Button id="btn-cancel" fullWidth onClick={handleClose}>Cancel</Button>
                    </Box>
                </Box>}

            </Dialog>
        </div>
    );
}

import { useState } from "react";

/**
 * Properties for the Trashcan component.
 */
interface SvgProps {
  /** Width of the icon. */
  width: string;
  /** Height of the icon. */
  height: string;
  /** Color of the icon. */
  color: string;
  /** Color of the icon when hovering over it. */
  hoverColor?: string;
}

/**
 * A component for displaying a trashcan icon.
 * 
 * @param props {@link SvgProps Properties} for a `Trashcan` component.
 * @returns A JSX element for displaying a trashcan icon.
 */
export default function Trashcan(props: SvgProps) {

  const [hover, setHover] = useState<boolean>(false);

  const hvrColor = props.hoverColor === undefined ? props.color : props.hoverColor;//if we have no hoverColor, default to color.
  return (
    <svg
      width={props.width}
      height={props.height}
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.3125 9.24852V19.9637C19.3125 20.6668 18.7287 21.25 17.9518 21.25H9.04821C8.2713 21.25 7.6875 20.6668 7.6875 19.9637V9.24852H19.3125Z" stroke={hover ? hvrColor : props.color} stroke-width="1.5" />
      <path d="M15.7767 6.33505V5.01815C15.7767 4.42762 15.2838 4 14.7052 4H12.2945C11.7159 4 11.2231 4.42762 11.2231 5.01815V6.33505" stroke={hover ? hvrColor : props.color} stroke-width="1.5" />
      <path d="M10.6182 11.7633L10.6182 18.7354" stroke={hover ? hvrColor : props.color} />
      <path d="M13.4998 11.7633L13.4998 18.7354" stroke={hover ? hvrColor : props.color} />
      <path d="M16.3814 11.7633L16.3814 18.7354" stroke={hover ? hvrColor : props.color} />
      <path d="M6 6.46787L21 6.46787" stroke={hover ? hvrColor : props.color} stroke-width="1.5" />
    </svg>

  );
}


import { DataEntryFlow } from "@cbtravel/common/lib/shared/common/enumerations/data-entry-flow";
import { UserEnrollRQ } from "@cbtravel/common/lib/shared/messages/general/requests/user-enroll-rq";
import { Box, Button, createStyles, Dialog, DialogContent, DialogContentText, Grid, IconButton, Link, makeStyles, Paper, Theme, Typography, TextField, Tooltip, } from "@material-ui/core";
import { useState, useRef, useEffect, useContext } from "react";
import { CustomFieldValueRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-value-rs";
import { Control, DeepMap, FieldErrors, useFieldArray, UseFormHandleSubmit, UseFormReset, UseFormGetValues, useWatch, Controller } from "react-hook-form";
import CustomFieldFieldArray from "../../components/shared/CustomFieldFieldArray";
import Spinner from "../../components/shared/Spinner";
import { CloseIcon } from "../../icons/Icons";
import { EnrollFormInputs } from "./Enroll";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

type FormInputsExportType = Control<EnrollFormInputs>
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      "#pendo-image-badge-5c3c4f83": {
        display: "none !important",
      },
    },
    root: {
      // flexGrow: 1,
      "& .MuiButton-containedSecondary": {
        color: "#fff",
        padding: "6px 20px 9px",
      },
      "& .MuiGrid-container": {
        margin: "0 !important",
      },
      "& .MuiGrid-grid-xs-12": {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      "& .MuiTypography-h1": {
        lineHeight: '1.3',
        fontSize: '30px',
        fontWeight: 300,
      },
    },
    container: {
      "& .MuiGrid-container": {
        margin: "0 !important",
      },
      "& .MuiGrid-spacing-xs-3": {
        width: '100%',
      },
    },
    title: {
      flexGrow: 1,
      paddingTop: "2px",
    },
    topBanner: {
      backgroundColor: '#1f2532',
    },
    content: {
      flexGrow: 1,
      overflowY: "auto",
      minHeight: "100vh",
      width: "100%",
      background: "#fff",
    },
    tooltipLabel: { fontSize: 16, pointerEvents: "auto" },
    errorTextField: {
      paddingTop: '5px',
  },
    logo: {
      height: 24,
    },
    iconography: {
      height: 255,
    },
    tooltip: {
      marginTop: 0,
      color: "#fff",
      backgroundColor: "rgba(77, 77, 77, .9)",
      "& .MuiSvgIcon-root": {
        width: "1.3em",
        fontSize: "1.1rem",
      },
    },
    accountHelp: {
      cursor: "help",
      width: 250,
      "& .MuiSvgIcon-root": {
        fontSize: "1rem",
        width: "1.5em",
      },
    },
    helpText: {
      color: "#fff",
      fontSize: "0.75rem",
      lineHeight: 1.6,
      padding: theme.spacing(1),
    },
    errorText: {
      paddingTop: 4,
      paddingBottom: '2px'
    },
    errorTextDropDown: {
      paddingTop: 1,
      paddingBottom: '2px'
    },
    errorTextCalendar: {
      paddingTop: '2px',
    },
    instructions: {
      "& .MuiTypography-body2": {
        lineHeight: '1.5',
      },
    },
    dialogPadding: {
      "& .MuiDialogContent-root:first-child": {
        paddingTop: 0,
      },
      "& .MuiTypography-h1": {
        lineHeight: '1.3',
      },
      "& .MuiTypography-body2": {
        lineHeight: '1.5',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    terms: {
      fontSize: '12px',
      color: '#585B64',
    },
    sticky: {
      bottom: '0',
      position: 'absolute',
      padding: 20,
    },
  })

);


interface EnrollCustomFieldsProps {
  control: Control<EnrollFormInputs>,
  errors: DeepMap<EnrollFormInputs, FieldErrors>,
  reset: UseFormReset<EnrollFormInputs>,
  handleSubmit: UseFormHandleSubmit<EnrollFormInputs>,
  isValid: boolean,
  isDirty: boolean,
  handleCancelEnrollment: () => void,
  enroll: (enrollRQ: UserEnrollRQ) => Promise<void>,
  getValues: UseFormGetValues<EnrollFormInputs>
  getCustomFields: (accountNumber: string, clientId : number ) => Promise<void>
}

export default function EnrollCustomFields(props: EnrollCustomFieldsProps) {
  const classes = useStyles();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const customFieldArray = useFieldArray({ control: props.control, name: "customFieldList" });
  const firstRender = useRef(true);
  const watchU99 = useWatch({ control: props.control, name: "u99Field" });



  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
    else {
      if (typeof watchU99.value !== 'string' && +watchU99.value.customFieldId !== -1) {
        props.getCustomFields('',+watchU99.value.value); //tricky way to convert to number
      }
    }
  },  [watchU99? (typeof watchU99?.value !== 'string' ? watchU99.value.value : watchU99?.value): watchU99])

  /** Close cancel dialog  */
  const handleCloseDialog = () => {
    setOpenCancelDialog(false)
  };
  /** Display cancel dialog  */
  const handleOpenDialog = () => {
    setOpenCancelDialog(true)
  };

  /**
   * Submits the values in the form.
   * 
   * @param form The object which holds the values that are in the form.
   */
  async function onSubmit(form: EnrollFormInputs) {
    setShowSpinner(true);
    const enrollRQ = new UserEnrollRQ();
    enrollRQ.clientAccountNumber = form.AccountNumber;
    enrollRQ.firstName = form.FirstName;
    enrollRQ.middleName = form.MiddleName;
    enrollRQ.lastName = form.LastName;
    enrollRQ.emailAddress = form.Email;
    enrollRQ.phoneCell = form.MobilePhone;
    enrollRQ.dateOfBirth = form.DateOfBirth ? form.DateOfBirth : new Date();
    enrollRQ.defaultTimeZoneId = form.TimeZoneOption.timeZone.timeZoneId;
    enrollRQ.gender = form.Gender;


    // If the client didn't have any active custom fields, then we don't want to run into any undefined type errors.
    if (form.customFieldList) {
      // Map custom fields onto the profile, including custom field 38
      form.customFieldList.forEach(field => {
        // avoid pushing the duplicate Custom Fields
        let exists = false;

        for (let i = 0; i < enrollRQ.customFieldList.length; i++) {
          if (enrollRQ.customFieldList[i].number === field.number) {
            exists = true;
            // custom field already exists, replace old values.
            enrollRQ.customFieldList[i].value = typeof field.value === 'string' ? field.value : field.value.value;
            enrollRQ.customFieldList[i].customFieldSource = field.customFieldSource;
          }
        }

        if (!exists) {
          enrollRQ.customFieldList.push(({
            number: field.customFieldNumber,
            value: typeof field.value === 'string' ? field.value : field.value.value,
            customFieldSource: field.customFieldSource
          }));
        }
      });
    }
    if (form.u99Field.customFieldId !== -1) {
      enrollRQ.customFieldList.push(({
        number: 99,
        value: typeof form.u99Field.value === 'string' ? form.u99Field.value : form.u99Field.value.value,
        customFieldSource: form.u99Field.customFieldSource
    }));
    }

    await props.enroll(enrollRQ);
    setShowSpinner(false);
  };

  return (
    <>
      {showSpinner && <Spinner />}
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1">
              <Box pt={5} ml={1.5} mb={3}>
                Request a new enrollment
              </Box>
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={9}>
          <div className={classes.container}>
            <Grid container spacing={3} alignContent="flex-end">
              {/* Custom component that's meant to be used with a field array and a RHF control object. Used for custom field inputs. */}
              {watchU99 && watchU99.number !== -1 &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Controller
                        name={`u99Field.value` as const}
                        control={props.control as FormInputsExportType}
                        rules={{
                            validate: {

                                required: (value: string | CustomFieldValueRS) => {
                                    if (typeof value === 'string')
                                        return value ? true : "This field is required "
                                    else
                                        return value.customFieldId === -1 ? "This field is required. " : true;
                                }
                            }
                        }}
                        render={({ field: { onChange, value: rhfValue, ref, onBlur } }) => {
                            return <Autocomplete
                                id={`u99fieldvalue`}
                                options={watchU99.customFieldValueList}
                                getOptionLabel={(option) => option.name === "" ? option.value : option.name}
                                getOptionSelected={(option, val) => option.value.toUpperCase() == val?.value.toUpperCase()}
                                fullWidth
                                autoSelect
                                autoHighlight
                                value={watchU99.customFieldValueList?.find(f => {

                                    return f.value === (rhfValue as CustomFieldValueRS).value
                                })
                                }
                                ref={ref}
                                onBlur={onBlur}
                                onChange={(_, val) => val === null ? onChange(new CustomFieldValueRS()) : onChange(val)} // if the value is null, return an empty string
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id={`ff-u99fieldvalue-noteExternal`}
                                        label={
                                            <Box mr={-0.5}>
                                                {watchU99.name}{" "}
                                                {watchU99.noteExternal &&
                                                    <Tooltip arrow
                                                        title={watchU99.noteExternal}
                                                        placement="top"
                                                    >
                                                        <InfoOutlinedIcon className={classes.tooltipLabel} />
                                                    </Tooltip>
                                                }
                                            </Box>
                                        }
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={props.errors.u99Field ? true : false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />
                                )}
                            />

                        }
                        } />
                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                        {props.errors?.u99Field?.value && (props?.errors?.u99Field?.value as any).message}
                    </Typography>
                </Grid>
            }
              {
                customFieldArray.fields.map((field, index) => {
                  return <CustomFieldFieldArray
                    key={`customField-${field.id}-${index}`}
                    field={field}
                    index={index}
                    control={props.control}
                    errors={props.errors}
                    customFieldValueList={field.customFieldValueList}
                    small={index !== customFieldArray.fields.length - 1 ? false :
                      customFieldArray.fields.length % 2 === 0 ? false : true}
                    flow={DataEntryFlow.NewUserCreation}
                  />
                })
              }
            </Grid>
          </div>
        </Grid>


        <Grid item xs={12} sm={3}>
          <Box display="flex" flexDirection="column" pr={2}>
            {/* Text on the right of modal that explains to user what the expected functionality is. */}
            <Paper>
              <Box className={classes.instructions} p={2}>
                <Box mt={1}>
                  <Typography variant="body2">
                    This information is required by your organization.
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography variant="body2">
                    When completed, click “Submit” to finalize your enrollment request.
                  </Typography>
                </Box>
                <Box my={1}>
                  <Typography variant="body2">
                    Reach out to someone from your organization if you are unsure how to complete the form.
                  </Typography>
                </Box>
              </Box>
            </Paper>
            {/* Save and cancel buttons here. */}
            <Box id="box_1" mt={10}>
              <Button fullWidth variant="contained" color="primary" id="saveButton"
                disabled={!(props.isValid)}
                onClick={props.handleSubmit(onSubmit)}>
                Submit
              </Button>
            </Box>
            <Box id="box_2" mt={1}>
              <Button fullWidth variant="outlined" color="primary" id="cancelButton" onClick={handleOpenDialog}>
                Cancel and close
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.sticky} >
            <Typography className={classes.terms} component="p">
              <Box fontSize={12} py={2}>
                By enrolling, you agree to the{" "}
                <Link
                  href="https://app.cbtat.com/legal/terms"
                  color="inherit"
                  target="_blank"
                  underline="always"
                >
                  Terms and Conditions
                </Link>{" "}
                and{" "}
                <Link
                  href="https://app.cbtat.com/legal/privacy"
                  color="inherit"
                  target="_blank"
                  underline="always"
                >
                  Privacy Policy
                </Link>
                .
              </Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Cancel dialog box */}
      <Dialog
        maxWidth='xs'
        open={openCancelDialog}
        // onClose={handleCloseAll}
        aria-labelledby="form-dialog-title"
      >
        <Box className={classes.dialogPadding} p={2} display="flex" flexDirection="column" justifyContent="center">
          <DialogContent>
            {/* The 'x' icon on the modal. */}
            <IconButton id="closeIcon" aria-label="close" className={classes.closeButton}
              onClick={handleCloseDialog}
            >
              <CloseIcon width={"20"} height={"20"} color={"#00467E"} />
            </IconButton>
            <DialogContentText>
              <Box mt={6} mb={6}>
                <Typography align="center" variant="h1">Are you sure you<br /> want to cancel?</Typography>
                <Box mt={2} mb={1}>
                  <Typography align="center" variant="body2">
                    If you close this screen now, your progress will be lost and your new enrollment will not be submitted.
                  </Typography>
                </Box>
              </Box>
            </DialogContentText>
            <Box>
              <Button fullWidth onClick={handleCloseDialog} variant="contained" color="primary">
                Continue enrollment request
              </Button>
            </Box>
            <Box my={1}>
              <Button fullWidth onClick={props.handleCancelEnrollment} variant="outlined" color="primary">
                Cancel and close
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};
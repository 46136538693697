import moment, { Moment } from "moment";
import { DateRange } from "moment-range";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, Link, Switch, Table, TableBody, TableCell, TableContainer,
  TableFooter,
  TableHead, TablePagination, TableRow, TextField, Typography
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import LastPageIcon from "@material-ui/icons/LastPage";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { DownloadFormat } from "@cbtravel/common/lib/shared/common/enumerations/download-format";
import { ReturnCount } from "@cbtravel/common/lib/shared/common/enumerations/return-count";
import { SortDirection } from "@cbtravel/common/lib/shared/common/enumerations/sort-direction";
import { TicketSortType } from "@cbtravel/common/lib/shared/common/enumerations/sort-types";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { PagedList } from "@cbtravel/common/lib/shared/common/paged-list";
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { ClientRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-rs";
import { TicketController } from "@cbtravel/common/lib/web/api/general/controllers/ticket-controller";
import { TicketStatus } from "@cbtravel/common/lib/web/common/enumerations/ticket-status";
import { TriStateBoolean } from "@cbtravel/common/lib/web/common/enumerations/tri-state-boolean";
import { TicketRQ } from "@cbtravel/common/lib/web/messages/general/requests/ticket-rq";
import { TicketRS } from '@cbtravel/common/lib/web/messages/general/responses/ticket-rs';
import { Controller, useForm } from "react-hook-form";
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import Spinner from "../../../components/shared/Spinner";
import { UserContext } from "../../../components/shared/UserContext";
import DateRangePicker from "../../../components/ui/DatePicker/DateRangePicker";
import MultipleSelectChips, { Option } from "../../../components/ui/Input/MultipleSelectChips";
import SortIcon from "../../../icons/SortIcon";
import { formUtils } from "../../../util/form-utils";
import FloatingButton from "./FloatingButton";
import TicketDataRow from "./TicketDataRow";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      // margin: theme.spacing(0.5),
    },
    "& .MuiTableCell-root": {
      lineHeight: 1.4,
    },
    "& div.MuiFormGroup-row": {
      display: "inline-block",
      marginTop: "6px",
    },
    "& .MuiFormControlLabel-label.MuiTypography-body1": {
      fontSize: ".9rem",
      lineHeight: 1,
      marginTop: "-2px",
    },
  },
  results: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    marginBottom: 0,
  },
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  download: {
    paddingTop: "7px",
    paddingRight: "4px",
  },
  filetype: {
    padding: 0,
    "& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense":
    {
      padding: "0 4px 2px",
    },
  },
  switchHeader: {
    fontWeight: 600,
    paddingRight: theme.spacing(2),
    display: "inline",
  },
  table: {
    minWidth: 500,
    width: "100%",
    "& a": {
      color: "#00467E",
      textDecoration: "none",
    },
    "& .MuiTableCell-head": {
      fontWeight: 700,
    },
    "& .MuiTableCell-head.MuiTableCell-sizeSmall": {
      padding: "8px",
      whiteSpace: "nowrap",
    },
    // background color for when "history" gets collapsed
    "& .MuiTableRow-root.moreInfoRow": {
      background: "#f9f9f9",
    },
    "& .moreInfoCell": {
      borderBottom: "none",
      padding: 0,
    },
    "& .tableHeader": {
      fontWeight: 700,
    },
    // This is the table cell padding for the unused tickets
    "& .MuiTableCell-sizeSmall": {
      padding: "0 16px 0 8px",
      marginTop: "2px",
    },
    "& .moreInfoTable .MuiTableCell-sizeSmall": {
      padding: "8px",
    },
    "& .MuiIconButton-sizeSmall": {
      padding: 0,
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      textAlign: "center",
      width: 400,
    },
    "& .MuiDialogTitle-root": {
      padding: "16px 24px 0",
    },
    "& .MuiDialogContent-root": {
      padding: "0 24px",
    },
    "& .MuiTypography-body1": {
      lineHeight: 1.6,
      fontSize: "13px",
      color: "#4D4D4D",
    },
    "& .MuiTypography-h6": {
      fontWeight: 600,
    },
  },
  iconNonTransferable: {
    margin: "8px auto 14px",
    background: "#bc2c2f",
    borderRadius: 40,
    width: "44px",
    height: "44px",
    "& .MuiSvgIcon-root": {
      color: "#fff",
      marginTop: "10px",
      fontSize: "1.5rem",
    },
    "& svg": {
      color: "#fff",
      marginTop: "11px",
    },
  },
  iconTransferable: {
    margin: "8px auto 14px",
    background: "#417505",
    borderRadius: 40,
    width: "44px",
    height: "44px",
    "& .MuiSvgIcon-root": {
      color: "#fff",
      marginTop: "10px",
      fontSize: "1.5rem",
    },
    "& svg": {
      color: "#fff",
      marginTop: "11px",
    },
  },
  dialogActions: {
    padding: "16px 24px",
    "& .MuiButton-contained.btnTransferable": {
      backgroundColor: "#417505",
    },
    "& .MuiButton-contained.btnNonTransferable": {
      backgroundColor: "#bc2c2f",
    },
  },
  btnCancel: {
    color: "#00467E",
    marginLeft: "0 !important",
    fontSize: "13px",
  },
  transferableHeader: {
    whiteSpace: "nowrap",
    "& .MuiCheckbox-root": {
      padding: "0 4px 0 0",
      marginTop: "-3px",
      marginLeft: "-2px",
      marginBottom: "-1px",
    },
  },
  redRow: {
    backgroundColor: "#f9ebeb",
  },
  dateErrorText: {
    paddingTop: '2px',
  },
  errorText: {
    paddingTop: '5px',
  },
}));

const TransferableOptions: Option[] = [{ label: TriStateBoolean.True, value: 0 }, { label: TriStateBoolean.False, value: 1 }]
const isInternationalOptions: Option[] = [{ label: "Domestic", value: 0 }, { label: "International", value: 1 }]

const airlinesThatAllowTransferableTickets = ['DL', 'UA', 'AS', 'B6', 'AA', 'NH']

interface sortManagerI {
  /** Sort by total airfare. */
  TotalAirfare: boolean,
  /** Sort by date expired. */
  DateExpired: boolean,
  /** Sort by airline. */
  Airline: boolean,
  /** Sort by passenger first name. */
  FirstName: boolean,
  /** Sort by passenger last name. */
  LastName: boolean,
}

/** TicketRQ form inputs for React Hook Form element */
type UnusedTicketFormInputs = {
  accountOptions: Array<Option>, //clientId List
  statusOptions: Array<Option>, // status List
  recordLocator: string, //confirmation number
  firstName: string,
  lastName: string,
  expireDateRange: [Date, Date],
  isInternationalOptions: Array<Option>,
  airline: string,
  ticketNumber: string,
  transferableOptions: Array<Option>,
}

export default function UnusedTickets(props: { activeClient?: ClientRS, }) {
  const classes = useStyles();
  const theme = useTheme();

  const { userContext, setUserContext } = useContext(UserContext);
  const snackbar = useCustomSnackbar();
  const fileType = ["CSV", "ZIP"];
  const [ticketRq, setTicketRq] = useState<TicketRQ | null>(null);
  const [didDefaultSearch, setDidDefaultSearch] = useState<boolean>(false);

  // form references
  const [sortType, setSortType] = useState<TicketSortType>(TicketSortType.ByDateExpired)
  const [sortAscending, setSortAscending] = useState<boolean>(false)
  const [sortManager, setSortManager] = useState<sortManagerI>({
    TotalAirfare: false,
    DateExpired: false,
    Airline: false,
    LastName: false,
    FirstName: false,
  });

  //Table state
  const [isPageBusy, setIsPageBusy] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [ticketList, setTicketList] = useState<PagedList<TicketRS>>(new PagedList<TicketRS>());
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sendBulkRequest, setSendBulkRequest] = useState<boolean>(false); // a flag sent to TicketDataRow.tsx to trigger a status change for each individual Ticket in a bulk action
  const [bulkComment, setBulkComment] = useState<string>(""); // the comment value for a bulk request

  const bulkSuccess = useRef<number>(0); // the number of successful/failed status changes from a bulk request
  const bulkFailure = useRef<number>(0); // these need to be refs and not state because we use them immediately after updating their values.

  const [bulkSwitchStates, setBulkSwitchStates] = useState<{ transferable: boolean, nonTransferable: boolean }>({
    transferable: false,
    nonTransferable: false
  });
  const [selectedTransferablesIds, setSelectedTransferablesIds] = useState<Set<number>>(new Set<number>());
  const [actionName, setActionName] = useState<"transferable" | "non-transferable">("transferable");


  // React Hook Form setup for TicketRQ inputs (except for sorts)
  const {
    control,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, dirtyFields }
  } = useForm<UnusedTicketFormInputs>({
    mode: "onBlur",
    // Default empty values for TicketRQ:
    defaultValues: {
      accountOptions: new Array<Option>(), // client/account default option will change once activeclient is loaded 
      statusOptions: new Array<Option>({ label: TicketStatus.Open, value: 0 }),
      recordLocator: "",
      firstName: "",
      lastName: "",
      expireDateRange: undefined,
      isInternationalOptions: new Array<Option>(),
      airline: "",
      ticketNumber: "",
      transferableOptions: new Array<Option>()
    }
  });

  const formRules = {
    accountOptions: {
      validate: {
        required: (value: Option[]) => {
          if (value.length > Configuration.ClientSearchLimit.value) return "You may only include " + Configuration.ClientSearchLimit.name + " items. Please reduce your selection.";
        }
      }
    },
    recordLocator: {
      validate: {
        atLeast6CharactersOrEmpty: (value: string) => {
          if (value.length < 6 && value.length > 0)
            return "Record Locator number must be at least 6 characters"
        }
      }
    },
    firstName: {
      minLength: { value: 2, message: "First name must be at least 2 characters" }
    },
    lastName: {
      minLength: { value: 2, message: "Last name must be at least 2 characters" }
    },
    expireDateRange: {
      validate: {
        max2Years: (value: [Date, Date]) => {
          if (!value) return;
          // calculate days between 2 dates
          let dateOfExpirationAdditionalDays = moment(value[1]).diff(
            moment(value[0]),
            "d"
          );
          // Error when daterange > 730 
          if (dateOfExpirationAdditionalDays > 730) {
            return "Date range cannot exceed 2 years";
          }
        }
      }
    }
  }

  // create the options for the select account and status inputs
  const clientOptions: Option[] = userContext.viewableClients.map((c) => {
    return { label: c.name + (c.accountNumber ? " (" + c.accountNumber + ")" : ""), value: c.clientId };
  });

  const statuses: Option[] = Object.values(TicketStatus).map((val, i) => {
    return { label: val, value: i };
  });

  useEffect(() => {
    // set default account option to active client (same as ClientSelector, see Home.tsx)
    // and perform default search when user loaded the page
    if (props.activeClient) {
      let clientName: string = props.activeClient.name;
      let clientId: number = props.activeClient.clientId;
      if (userContext.userType === "Administrator" || userContext.userType === "AccountManager" || userContext.userType === "SalesManager") {
        setValue("accountOptions", [{ label: clientName, value: clientId }]);
      }

      handleSubmit(submitFilterOptions)();

    }
  }, [props.activeClient]);

  useEffect(() => {
    // If paginated or if rowsPerPage changes, get the new tickets.
    let ticketRqNew = ticketRq;
    if (ticketRqNew != null) {
      ticketRqNew.limit = rowsPerPage;
      ticketRqNew.skip = pageNumber * rowsPerPage;
      getTickets(ticketRqNew);
    }
  }, [pageNumber, rowsPerPage]);

  useEffect(() => {
    //fetch new tickets when sortTypes were changed and after default search
    if (didDefaultSearch) {// prevent initial run 
      handleSubmit(submitFilterOptions)();
    }
  }, [sortManager]);

  /**  
   * clears all of the filter form values.
   */
  function clearFilter() {
    //Reset RHF values
    reset({
      accountOptions: new Array<Option>(),
      statusOptions: new Array<Option>(),
      recordLocator: "",
      firstName: "",
      lastName: "",
      expireDateRange: undefined,
      isInternationalOptions: new Array<Option>(),
      airline: "",
      ticketNumber: "",
      transferableOptions: new Array<Option>()
    });
  }

  /**
   * Converts the form to a TicketRQ.
   * 
   * @returns TicketRQ populated with the form value.
   */
  function formToTicketRq(): TicketRQ {
    let ticketRQ: TicketRQ = new TicketRQ();

    // Retrieve data from RHF values
    let formInputs: UnusedTicketFormInputs = getValues();

    // moment doesn't allow dates before ~1970, so make min DateTime if null
    if (!formInputs.expireDateRange) {
      ticketRQ.dateOfIssue = new Date("0001-01-01T00:00:00Z");
      ticketRQ.dateOfExpiration = new Date("0001-01-01T00:00:00Z");
    }
    else {
      //Populate expire date and expire additional days
      let startMoment: Moment = moment(formInputs.expireDateRange[0]);
      let endMoment: Moment = moment(formInputs.expireDateRange[1]);
      ticketRQ.dateOfExpiration = startMoment.toDate();
      ticketRQ.dateOfExpirationAdditionalDays = endMoment.diff(startMoment, "d");
    }
    // Specify page limits
    ticketRQ.limit = rowsPerPage;
    ticketRQ.skip = pageNumber * rowsPerPage;

    ticketRQ.returnCount = ReturnCount.ReturnRecordsAndCount;

    //Populate client list to search for
    ticketRQ.clientIdList = formInputs.accountOptions.map((o) => o.value);
    //Populate other inputs
    ticketRQ.firstName = formInputs.firstName;
    ticketRQ.lastName = formInputs.lastName;
    ticketRQ.ticketNumber = formInputs.ticketNumber;
    ticketRQ.confirmationNumber = formInputs.recordLocator;
    ticketRQ.vendorCode = formInputs.airline;
    ticketRQ.ticketStatusList = formInputs.statusOptions.map(
      (s) => TicketStatus[s.label as keyof typeof TicketStatus]
    );

    //Populate TriState boolean values
    if (formInputs.transferableOptions.length > 0) {
      ticketRQ.isTransferable = formInputs.transferableOptions.length > 1 ? TriStateBoolean.Both : (formInputs.transferableOptions[0].label === TriStateBoolean.True ? TriStateBoolean.True : TriStateBoolean.False);
    }

    if (formInputs.isInternationalOptions.length > 0) {
      ticketRQ.isInternational = formInputs.isInternationalOptions.length > 1 ? TriStateBoolean.Both : (formInputs.isInternationalOptions[0].label === "Domestic" ? TriStateBoolean.False : TriStateBoolean.True);
    }
    //Populate Sort Types:
    ticketRQ.sortType = sortType;
    ticketRQ.sortDirection = sortAscending ? SortDirection.Ascending : SortDirection.Descending;

    return ticketRQ;
  }

  /**
   * Handles the form submission.
   */
  function submitFilterOptions() {
    // Validation to see if there's at least one chip in the accounts/status fields 
    // when no other detailed search criteria are provided ...
    let newTicketRQ = formToTicketRq();
    newTicketRQ.skip = 0;
    setTicketRq(newTicketRQ);
    getTickets(newTicketRQ);
    setPageNumber(0)
  }

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number
    ) => void;
  }

  /**
   * Component for table pagination actions.
   * 
   * @param pageinationActions comes from the TablePagination component with info about the current states of the table.
   * @returns the footer that handles the table Pagination buttons.
   */
  function tablePagination(pageinationActions: TablePaginationActionsProps) {
    let count = pageinationActions.count
    let upperRange = Math.ceil(count / rowsPerPage)

    return (
      <Box className={classes.pagination}>
        <IconButton
          id="btn-icon-first-page"
          onClick={() => setPageNumber(0)}
          disabled={pageNumber === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          id="btn-icon-previous-page"
          onClick={() => setPageNumber(pageNumber - 1)}
          disabled={pageNumber === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          id="btn-icon-next-page"
          onClick={() => setPageNumber(pageNumber + 1)}
          disabled={pageNumber >= upperRange - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          id="btn-icon-last-page"
          onClick={() => setPageNumber(Math.max(0, upperRange - 1))}
          disabled={pageNumber >= upperRange - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    )
  }

  /**
   * Handles the updating of RecordLocator from the form inputs.
   * @param event html input element containing the updated record locator value.
   */
  function handleRecordLocatorChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    // searching by RecordLocator (confirmationnumber) will clear other search inputs
    clearFilter();
    setValue("recordLocator", event.target.value);
  }

  /**
   * Handles the updating of TicketNumber from the form inputs.
   * @param event html input element containing the new ticket number.
   */
  function handleTicketNumberChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    // searching by TicketNumber will clear other search inputs
    clearFilter();
    setValue("ticketNumber", event.target.value);
  }

  /**
   * Function to clear ticketnumber and record locator inputs 
   * used when other input fields are entered 
   */
  function clearTicketNumberRecordLocator() {
    // Empty the ticketNum & recordLocator value and also revalidating them (to clear our errors)
    setValue("ticketNumber", "", { shouldValidate: true });
    setValue("recordLocator", "", { shouldValidate: true });

  }


  /**
   * Handles the updating of pagination.
   * @param event is provided but not used. 
   * @param newPage is the new page number.
   */
  function handleChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
    setPageNumber(newPage);
  }

  /**
   * Handles changing the number of tickets that are displayed in the table.
   * @param event the new number of tickets to be displayed by the table.
   */
  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  }

  /**
   * Toggles selection of every row of the table
   * @param event from clicking the 'select all toggle'. 
   */
  function handleSelectAllToggle(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const toggleableRequestIds = ticketList.list.reduce<number[]>(
        (accumulator, ticket) => {
          if (airlinesThatAllowTransferableTickets.includes(ticket.vendorCode)) {
            return [...accumulator, ticket.ticketId]
          }
          return accumulator;
        }, []
      )

      const newSelectedRequests = new Set<number>(toggleableRequestIds);
      setSelectedTransferablesIds(newSelectedRequests);
    } else {
      setSelectedTransferablesIds(new Set<number>());
    }
  }

  /**
   * sets transferable/non-transferable switches to their appropriate states when one of them is clicked; ensures only one can be "on" at a time
   * @param event the click event that calls this funciton
   */
  function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      if (event.target.name === "transferable") {
        setBulkSwitchStates({ transferable: !bulkSwitchStates.transferable, nonTransferable: false })
        setActionName("transferable");
      } else {
        setBulkSwitchStates({ nonTransferable: !bulkSwitchStates.nonTransferable, transferable: false })
        setActionName("non-transferable");
      }
    } else {
      setBulkSwitchStates({ transferable: false, nonTransferable: false })
      setActionName("transferable");
      setSelectedTransferablesIds(new Set<number>())
    }
  }

  /**
   * Toggles selection of an individual row of the table
   * @param requestId
   */
  function handleSelectToggle(event: React.ChangeEvent<HTMLInputElement>, requestId: number) {
    let newSelectedTransferablesIds = new Set<number>(selectedTransferablesIds)

    // if already selected then remove from selected values, otherwise add it
    if (selectedTransferablesIds.has(requestId)) {
      newSelectedTransferablesIds.delete(requestId);
    } else {
      newSelectedTransferablesIds.add(requestId);
    }
    setSelectedTransferablesIds(newSelectedTransferablesIds);
  }

  /**
  * a handler function that sets the necessary state objects to send as props to all of the child components affected by a bulk action
  * @param isTransferable boolean- true = approved, false = denied. this isn't currently used here, but it mirrors the signature of the function single approvals call directly and therefore cuts down on typeErrors
  * @param comment the string entered into the comment section of the action dialog, to be included in the changeStatusRQ
  */
  function handleBulkAction(isTransferable: boolean, comment: string = "") {
    setBulkComment(comment)
    setSendBulkRequest(true)
  }


  /**
   * Updates a running tally of how many requests in a bulk action succeeded or failed. This method is called in TicketDataRow after attempting to update ticket transferability
   * @param didSucceed a boolean-- true if the ticket's transferability status change succeeded
   */
  function tallyBulkResults(didSucceed: boolean) {
    if (didSucceed) {
      bulkSuccess.current++;
    } else {
      bulkFailure.current++
    }
    if (selectedTransferablesIds.size === bulkSuccess.current + bulkFailure.current) {
      if (bulkFailure.current === 0) {
        setSelectedTransferablesIds(new Set<number>())
        setActionName("transferable");
        setBulkSwitchStates({ transferable: false, nonTransferable: false })
      }
      showTimedInfoSnackbar(`${bulkSuccess.current} tickets completed. ${bulkFailure.current} tickets failed to send.`)
      getTickets(null);
    }
  }

  /**
   * Displays a message in the snackbar.
   * 
   * @param message The message to display.
   */
  function showTimedInfoSnackbar(message: string) {
    snackbar.info(message);
    bulkSuccess.current = 0;
    bulkFailure.current = 0;
  }

  /**
   * Handles changing the sort type when a sort icon is clicked.
   * 
   * @param filterBy The sort type to use when fetching and displaying tickets.
   */
  function handleSortIconClick(filterBy: TicketSortType) {
    let newSortManager = {
      TotalAirfare: false,
      DateExpired: false,
      Airline: false,
      LastName: false,
      FirstName: false,
    };

    //change sort type
    setSortType(filterBy);

    switch (filterBy) {
      case TicketSortType.ByDateExpired:
        newSortManager.DateExpired = !sortManager.DateExpired;
        setSortAscending(!sortManager.DateExpired);
        break;
      case TicketSortType.ByLastName:
        newSortManager.LastName = !sortManager.LastName;
        setSortAscending(!sortManager.LastName);
        break;
      case TicketSortType.ByFirstName:
        newSortManager.FirstName = !sortManager.FirstName;
        setSortAscending(!sortManager.FirstName);
        break;
      case TicketSortType.ByAirLine:
        newSortManager.Airline = !sortManager.Airline;
        setSortAscending(!sortManager.Airline);
        break;
      case TicketSortType.ByTotalAirFare:
        newSortManager.TotalAirfare = !sortManager.TotalAirfare;
        setSortAscending(!sortManager.TotalAirfare);
        break;
    }

    setSortManager(newSortManager);
  }

  /**
   * gets all the unused tickets and sets them in setTicketList.
   * @param ticketRq a ticket request
   */
  async function getTickets(ticket: TicketRQ | null) {
    let ticketRqNew = new TicketRQ();

    //if we do't pass a ticket, use the ticketRq state object
    if (!ticket && ticketRq) {
      ticketRqNew = ticketRq
    } else if (ticket) {
      ticketRqNew = ticket
    }

    ticketRqNew.limit = rowsPerPage;
    ticketRqNew.skip = pageNumber * rowsPerPage;

    try {
      setIsPageBusy(true);
      let tickets: PagedList<TicketRS> = await TicketController.Find(userContext.accessToken, ticketRqNew);
      setTicketList(tickets);

      // show message if more than 50 results, but not after a bulk request
      if (tickets.totalCount > rowsPerPage && pageNumber === 0 && !sendBulkRequest) {
        snackbar.info("Try changing the filter options to narrow your results.");
      } else if (sendBulkRequest) {
        setSendBulkRequest(false)
      }
    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setIsPageBusy(false);
      //Switch didDefaultSearch to true when called submit
      if (!didDefaultSearch)
        setDidDefaultSearch(true);
    }
  }

  /**
   * Handles the download of the tickets. 
   * @param csv boolean for if a csv or a zip file.
   */
  async function handleDownloadReport(csv: boolean) {
    let newTicketRq = ticketRq;
    if (newTicketRq == null) newTicketRq = new TicketRQ();
    newTicketRq.limit = 0;
    newTicketRq.skip = 0;
    let defaultColumnList = Configuration.UnusedTicket.defaultColumns.split(",");
    try {
      setIsPageBusy(true);
      // get blob from API
      let fileBlob = await TicketController.FindTicketAsCsv(
        userContext.accessToken,
        newTicketRq,
        !csv,
        defaultColumnList
      );

      // make download
      let filename = "tickets.zip";
      if (csv) {
        filename = "tickets.csv";
      }

      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) { // for IE
        (window.navigator as any).msSaveOrOpenBlob(fileBlob, filename);
      } else {
        let blobUrl = window.URL.createObjectURL(fileBlob);
        let link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        link.click();
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      }
    } catch (e) {
      snackbar.error(e as JsonException);
    } finally {
      setIsPageBusy(false);
    }
  }

  return (
    <Box className={classes.root}>
      {isPageBusy && <Spinner />}

      <Box mt={3}>
        <Typography id="h2-1" variant="h2" color="textPrimary">
          Filter unused tickets
        </Typography>
      </Box>

      <Grid container spacing={2} alignContent="flex-end">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box mt={0}>
            <Controller
              name="accountOptions"
              control={control}
              rules={formRules.accountOptions}
              render={({
                field: { onChange, value, onBlur }
              }) => (
                <MultipleSelectChips
                  id="ff-accounts"
                  label="Account(s)"
                  options={clientOptions}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onBlur();
                    clearTicketNumberRecordLocator();
                  }}
                  error={errors?.accountOptions ? true : false}
                  errorMessage={(errors?.accountOptions as any)?.message} // since TS resolves accountOptions to Option[] instead of FieldError, this is to grab error message from FieldError
                />
              )} />
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Box mt={0}>
            <Controller
              name="statusOptions"
              control={control}
              render={({
                field: { onChange, value, onBlur }
              }) => (
                <MultipleSelectChips
                  id="ff-status"
                  label="Status"
                  options={statuses}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    clearTicketNumberRecordLocator();
                    onBlur();
                  }}
                  error={false}
                  errorMessage={""}
                />
              )} />
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Box mt={1}>
            <Controller
              name="recordLocator"
              control={control}
              rules={formRules.recordLocator}
              render={({
                field: { onChange, value, onBlur, ref }
              }) => (
                <TextField
                  id="ff-record-locator"
                  label="Record Locator"
                  variant="outlined"
                  value={value}
                  size="small"
                  fullWidth
                  ref={ref}
                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                  onChange={(e) => {
                    handleRecordLocatorChange(e);
                  }}
                  error={errors?.recordLocator ? true : false}
                />
              )} />
            {/* Error label for record locator */}
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              {errors?.recordLocator && errors.recordLocator.message}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignContent="flex-end">
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box mt={0}>
            <Controller
              name="firstName"
              control={control}
              rules={formRules.firstName}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <TextField
                  id="ff-first-name"
                  label="First Name"
                  margin="dense"
                  variant="outlined"
                  ref={ref}
                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                  value={value}
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearTicketNumberRecordLocator();
                  }}
                  error={errors?.firstName ? true : false}
                />)}
            />
          </Box>
          {/* Error label for firstName */}
          <Typography
            variant="body2"
            color="error"
            className={classes.errorText}
          >
            {errors?.firstName && errors.firstName.message}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box mt={0}>
            <Controller
              name="lastName"
              control={control}
              rules={formRules.lastName}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <TextField
                  id="ff-last-name"
                  label="Last Name"
                  margin="dense"
                  variant="outlined"
                  ref={ref}
                  onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                  value={value}
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearTicketNumberRecordLocator();
                  }}
                  error={errors?.lastName ? true : false}
                />)}
            />
            {/* Error label for lastName */}
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              {errors?.lastName && errors.lastName.message}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box mt={0}>
            <Controller
              name="expireDateRange"
              control={control}
              rules={formRules.expireDateRange}
              render={({
                field: { onChange, value, onBlur }
              }) => (
                <DateRangePicker
                  title="expire-range"
                  numberOfCalendars={2}
                  displayFutureFilters={true}
                  displayPreviousFilters={false}
                  onBlur={onBlur}
                  textFieldLabel="Expire Dates"
                  onChange={(dateRange) => {
                    //Set form values if not null
                    if (dateRange && dateRange.start !== null && dateRange.end !== null) {
                      onChange([dateRange.start, dateRange.end])
                    }
                    else if (!dateRange) {
                      onChange(null);
                    }
                    clearTicketNumberRecordLocator();
                  }}
                  value={value && new DateRange(value)}
                  error={errors?.expireDateRange ? true : false}
                />)}
            />
            <Typography
              variant="body2"
              color="error"
              className={classes.dateErrorText}
            >
              {errors?.expireDateRange && (errors?.expireDateRange as any).message}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignContent="flex-end">
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Box mt={0}>
            <Controller
              name="isInternationalOptions"
              control={control}
              render={({
                field: { onChange, value, onBlur }
              }) => (
                <MultipleSelectChips
                  id="ff-domestic-international"
                  label="Dom/Int"
                  options={isInternationalOptions}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onBlur();
                    clearTicketNumberRecordLocator();
                  }}
                  error={false}
                  errorMessage={""}
                />)}
            />
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Controller
            name="airline"
            control={control}
            render={({
              field: { onChange, value, ref, onBlur }
            }) => (
              <TextField
                id="ff-airline-code"
                label="Airline Code"
                margin="dense"
                variant="outlined"
                ref={ref}
                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                value={value}
                fullWidth
                onChange={(e) => {
                  onChange(e.target.value);
                  clearTicketNumberRecordLocator();
                }}
              />)}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Controller
            name="ticketNumber"
            control={control}
            render={({
              field: { onChange, value, ref, onBlur }
            }) => (
              <TextField
                id="ff-ticket-number"
                label="Ticket Number"
                margin="dense"
                variant="outlined"
                value={value}
                ref={ref}
                onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                fullWidth
                onChange={(e) => {
                  handleTicketNumberChange(e);
                }}
              />)}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Box mt={0}>
            <Controller
              name="transferableOptions"
              control={control}
              render={({
                field: { onChange, value, onBlur }
              }) => (
                <MultipleSelectChips
                  id="ff-transferable"
                  label="Transferable"
                  options={TransferableOptions}
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onBlur();
                    clearTicketNumberRecordLocator();
                  }}
                  error={false}
                  errorMessage={""}
                />)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box ml={-1} mt={1}>
          <Button
            id="btn-submit"
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleSubmit(submitFilterOptions)}
          >
            Filter
          </Button>
          <Button
            id="btn-clear"
            style={{ color: "#00467E", marginLeft: "8px" }}
            variant="text"
            onClick={clearFilter}
          >
            Clear Filters
          </Button>
        </Box>
      </Grid>

      <Grid
        container
        spacing={2}
        alignContent="flex-end"
        className={classes.root}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box mt={1}>
            <TableContainer>
              <Box>
                <h3 className={classes.results}>
                  {ticketList.totalCount} Results
                </h3>
                <Box display="flex" color="textPrimary">
                  <Box flexGrow={1}>
                    <p className={classes.switchHeader}>Bulk Actions:</p>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={handleSwitchChange}
                            checked={bulkSwitchStates.transferable}
                            name="transferable"
                            size="small"
                          />
                        }
                        label="Set transferable"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={handleSwitchChange}
                            checked={bulkSwitchStates.nonTransferable}
                            name="nonTransferable"
                            color="secondary"
                            size="small"
                          />
                        }
                        label="Set non-transferable"
                      />
                    </FormGroup>
                  </Box>
                  <Box display="flex" pb={1}>
                    <Box pt={2} mr={1} className={classes.download}>
                      <span className="dark-blue">
                        <Link
                          href="#"
                          onClick={() => {
                            handleDownloadReport(
                              "CSV" == userContext.userState.downloadFormat
                            );
                          }}
                        >
                          Download Report
                        </Link>
                      </span>
                    </Box>
                    <Box>
                      <Autocomplete
                        value={userContext.userState.downloadFormat}
                        onChange={(
                          event: any,
                          newValue: string | undefined
                        ) => {
                          if (newValue == "ZIP") {
                            userContext.userState.downloadFormat = DownloadFormat.ZIP;
                            setUserContext(userContext);
                          } else if (newValue == "CSV") {
                            userContext.userState.downloadFormat = DownloadFormat.CSV;
                            setUserContext(userContext);
                          } else {
                            return;
                          }
                        }}
                        className={classes.filetype}
                        inputValue={userContext.userState.downloadFormat}
                        id="ff-report-filetype"
                        style={{ width: 70 }}
                        options={fileType}
                        disableClearable
                        autoSelect
                        autoHighlight
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </Box>

              <Table
                className={classes.table}
                aria-label="custom pagination table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box className={classes.transferableHeader}>
                        {bulkSwitchStates.nonTransferable ||
                          bulkSwitchStates.transferable ? (
                          <Checkbox
                            onChange={handleSelectAllToggle}
                            inputProps={{
                              "aria-label": "select all approval requests",
                            }}
                            size="small"
                          />
                        ) : (
                          ""
                        )}
                        {bulkSwitchStates.nonTransferable
                          ? "Non-transferable"
                          : "Transferable"}
                      </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">Status</TableCell>
                    <TableCell>Account Name</TableCell>
                    <TableCell onClick={() => handleSortIconClick(TicketSortType.ByDateExpired)}>Expire Date <SortIcon sortAscending={sortManager.DateExpired} /></TableCell>
                    <TableCell >Last Name </TableCell>
                    <TableCell onClick={() => handleSortIconClick(TicketSortType.ByFirstName)}>First Name <SortIcon sortAscending={sortManager.FirstName} /></TableCell>
                    <TableCell onClick={() => handleSortIconClick(TicketSortType.ByAirLine)} align="center">Airline <SortIcon sortAscending={sortManager.Airline} /></TableCell>
                    <TableCell>Ticket Number</TableCell>
                    <TableCell>Record Locator</TableCell>
                    <TableCell onClick={() => handleSortIconClick(TicketSortType.ByTotalAirFare)} align="center">Total Airfare <SortIcon sortAscending={sortManager.TotalAirfare} /></TableCell>
                    <TableCell align="center">History</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {ticketList.list.map((ticket, i) => {
                    const isSelected: boolean = selectedTransferablesIds.has(ticket.ticketId);
                    return (
                      <TicketDataRow
                        key={ticket.ticketId}
                        ticket={ticket}
                        bulkSwitchStates={bulkSwitchStates}
                        selectedTransferableIds={selectedTransferablesIds}
                        handleSelectToggle={handleSelectToggle}
                        setShowMainSpinner={setIsPageBusy}
                        token={userContext.accessToken}
                        timeZone={userContext.timeZone}
                        getTickets={getTickets}
                        showTimedInfoSnackbar={showTimedInfoSnackbar}
                        bulkAction={actionName}
                        bulkComment={bulkComment}
                        inBulkRequest={sendBulkRequest}
                        tallyBulkResults={tallyBulkResults}
                        selected={isSelected}
                      />
                    )
                  })}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={12}
                      count={ticketList.totalCount}
                      rowsPerPage={rowsPerPage}
                      page={pageNumber}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={tablePagination}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            {selectedTransferablesIds.size > 0 ? (
              <FloatingButton
                setShowSpinner={setIsPageBusy}
                action={
                  actionName === "transferable"
                    ? "transferable"
                    : "non-transferable"
                }
                bulkActionCount={selectedTransferablesIds.size}
                onConfirm={handleBulkAction}
              />
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

import { createRef} from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';

import './styles/main.css';
import Home from './routes/Home';
import Login from './routes/Login';
import Enroll from "./routes/Enroll/Enroll";
import CreatePassword from "./routes/Password";

//styles for the global snackbars
const useStyles = makeStyles((theme) => ({
  snackbarBtn: {
    flexWrap: "nowrap",
    alignItems: "start",
    lineHeight: 1.8,
    "& .MuiButton-root": {
      minWidth: "auto",
      color: "#fff",
    },
  },
  error: {
    backgroundColor: "#bc2c2f !important", // error background color
  },
  info: {
    backgroundColor: "#00467E !important", // info background color
  },
  warning: {
    backgroundColor: "#ff8401 !important", // warning background color
  },
}));

function App(props: RouteComponentProps) {
  const classes = useStyles();
  const notistackRef = createRef<SnackbarProvider>();
  // Okta Auth instance initialization
  const oktaAuth = new OktaAuth({
    issuer: Configuration.Okta.issuer,
    clientId: Configuration.Okta.clientId,
    redirectUri: Configuration.Okta.redirectUri,
    pkce: Configuration.Okta.pkce,
    tokenManager: { autoRenew: false, autoRemove: false, storageKey: Configuration.Okta.storageKey },
    scopes: Configuration.Okta.scopes,
    clientSecret: Configuration.Okta.clientSecret,
    services: { autoRenew: false, autoRemove: false },
  });

  //watches for token expiration and logs the user out
  oktaAuth.tokenManager.on('expired', async function (key: string) {
    if (key !== 'idToken') {
      await oktaAuth.closeSession();
      await oktaAuth.signOut({ revokeAccessToken: true });

      oktaAuth.tokenManager.clear();
      // Unsubscribe from the event listener once it's executing
      oktaAuth.tokenManager.off("expired");
    }
  })

  /**
   * Called when a user that is not authenticated attempts to access a SecureRoute.
   * Redirects to the login page.
   */
  function onAuthRequired() {
    window.location.replace("/login");
  }
  
  /**
   * A callback function required by Okta's <Security/>.
   * When routing back from a successful login, this function is called to restore to the original URI 
   * Recommended implementation by okta-react docs: https://github.com/okta/okta-react#creating-react-router-routes-with-class-based-components
   * @param _oktaAuth oktaAuth instance 
   * @param originalUri The original URI that was called from <Security/> component
   */
  const restoreOriginalUri = async (_oktaAuth : OktaAuth, originalUri: string) => {
    props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <SnackbarProvider
      className={classes.snackbarBtn}
      classes={{
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      maxSnack={5}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      hideIconVariant
      ref={notistackRef}
    >
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={onAuthRequired}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Switch>
          <Route path="/enroll" component={() => <Enroll />} />
          <Route path="/password" component={() => <CreatePassword />} />
          <Route path="/login" component={Login} />
          <Route path="/implicit/callback" component={LoginCallback} />
          <SecureRoute path="/" component={Home} />
        </Switch>
      </Security>
    </SnackbarProvider>
  );
};


export default withRouter(App);
import { formatPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";

export class phoneUtil {


    /**
     * wrapper for react-phone-number-input's formatPhoneNumber function
     * @param phoneNumber a phone number
     * @returns whether the given phone number is in a valid format
     */
    static formatPhone(phoneNumber: string) {
        return formatPhoneNumber(phoneNumber);
    }

    static isPossibleNumber(phoneNumber: string) {
        return isPossiblePhoneNumber(phoneNumber);
     }
}
import React, { SetStateAction } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ActionDialog from "../../../../components/ui/Dialog/ActionDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    strong: {
      fontWeight: 600,
      lineHeight: 1.5,
    },
  })
);

interface CancelDialogProps {
  /** Whether or not the dialog should be displayed. */
  open: boolean,
  /** React state setter for whether this dialog should be displayed. */
  setOpen: React.Dispatch<SetStateAction<boolean>>,
}

/**
 * A component used to display a dialog for confirmation that an approval level
 * should be removed before actually removing it.
 * 
 * @param props {@link CancelDialogProps Properties} for the `RemoveLevelDialog` component.
 * @returns A JSX element used by the Configure Approval Types page to confirm the removal of an approval level.
 */
export default function CancelDialog(props: CancelDialogProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ActionDialog
        title="Are you sure you want to cancel?"
        positiveText="Discard"
        open={props.open}
        icon={<DeleteIcon />}
        setOpen={props.setOpen}
        dialogType="delete"
      >
        <Typography variant="body1">
          <p className={classes.strong}>Any unsaved changes will be discarded. </p>
        </Typography>
      </ActionDialog>
    </React.Fragment>
  );
}

import React, { useState, } from "react";
import { Fab, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ActionDialog from "../../../components/ui/Dialog/ActionDialog";
import CloseDialogIcon from '../../../components/ui/CloseIcon';

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            textAlign: "center",
            width: 340,
        },
        "& .MuiDialogTitle-root": {
            padding: "16px 24px 0",
        },
        "& .MuiDialogContent-root": {
            padding: "0 24px",
            lineHeight: 1.5,
        },
        "& .MuiTypography-body1": {
            lineHeight: "unset",
            fontSize: "13px",
            color: "#4D4D4D",
        },
        "& .MuiTypography-h6": {
            fontWeight: 600,
        },
    },
    dialogContent: {
        lineHeight: 1.5,
    },
    strong: {
        fontWeight: 600,
        lineHeight: 1.5,
    },
    iconAction: {
        margin: "25px auto 14px",
        background: "#bc2c2f",
        borderRadius: 40,
        width: "44px",
        height: "44px",
        "& .MuiSvgIcon-root": {
            color: "#fff",
            marginTop: "10px",
        },
    },
    dialogActions: {
        padding: "16px 32px"
    },
    cancelFab: {
        position: 'relative',
        color: "#ffffff",
        backgroundColor: "#808080",
        boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 1%), 0px 1px 18px 0px rgb(0 0 0 / 11%)",
    },
}));

interface DiscardDialogProps {
    /** Whether the dialog should be open or not. */
    open: boolean,
    /** React state setter for `open`. */
    setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((v: boolean) => void),
    /** Function to execute when the positve button is clicked. */
    positiveAction: () => Promise<void>,
    /** Function to execute when the negative button is clicked. */
    negativeAction: () => void,
    /** Text to display on the negative button. */
    negativeText: string,
    /** Addition JSX element to display in the dialog. */
    children: React.ReactNode
}

/**
 * A Component for displaying an {@link ActionDialog} that confirms that changes should be discarded.
 * 
 * @param props {@link DiscardDialogProps Properties} for the DiscardDialog component.
 * @returns A JSX element for a dialog used for confirming discarding of changes.
 */
function DiscardDialog(props: DiscardDialogProps) {
    return (
        <ActionDialog
            id="dialog-discard"
            title="Discard changes"
            icon={<div className={useStyles().iconAction}><CloseIcon /></div>}
            positiveText="Discard"
            negativeText={props.negativeText}
            open={props.open}
            setOpen={props.setOpen}
            positiveAction={props.positiveAction}
            negativeAction={props.negativeAction}
            dialogType="delete"
        >
            {props.children}
        </ActionDialog>
    );
}

/**
 * A button for discarding existing changes on the Report Settings page. When clicked,
 * triggers a dialog to confirm that changes should be discarded.
 * 
 * @returns A JSX element that is a button that will show a confirmation dialog.
 */
export default function CancelBtn(props: { handleCancel: () => Promise<void> }) {
    const classes = useStyles();
    const [showClickSaveDialog, setShowClickSaveDialog] = useState<boolean>(false);

    /**
     * Shows the discard changes dialog when clicking the button
     */
    function handleCancelBtnClick() {
        setShowClickSaveDialog(true);
    }

    return (
        <React.Fragment>
            <Fab
                aria-label="discard"
                className={classes.cancelFab}
                onClick={handleCancelBtnClick}
            >
                <CloseIcon />
            </Fab>
            <DiscardDialog
                open={showClickSaveDialog}
                setOpen={setShowClickSaveDialog}
                positiveAction={async () => { await props.handleCancel() }}
                negativeAction={() => { }}
                negativeText="Cancel"
            >
                <p className={classes.dialogContent}>
                    This will discard your changes and take you back to the Report Settings page.
                </p>
                <CloseDialogIcon onClick={() => setShowClickSaveDialog(false)} />
            </DiscardDialog>
        </React.Fragment>
    );
}

import { forwardRef } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { RefCallBack } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import '../../../styles/phoneInput.css';
import ReactPhoneNumberInput, { FeatureProps as PhoneInputProps } from 'react-phone-number-input';

interface CustomPhoneInputProps {
    onChange: (...event: any[]) => void,
    value: any,
    ref?: RefCallBack,
}

/**
 * This is used for the input component of PhoneInput. The 'forwardRef' call is necessary
 * for it to function properly.
 */
const PhoneTextField = forwardRef((props: TextFieldProps, ref: any) => {
    return (
        <TextField
        style={{marginLeft: 5}}
            {...props}
            inputRef={ref}
        />
    );
});

/**
 * The PhoneInput component is used for inputs that represent phone numbers.
 * This component uses react-phone-number-input and wraps over the TextField component
 * from Material-UI.
 * @param props Any TextField or react-phone-number-input props.
 * @returns A component used for phone number inputs.
 */
export function PhoneInput(props: CustomPhoneInputProps & PhoneInputProps<TextFieldProps>) {

    return (
        <ReactPhoneNumberInput
            {...props}
            inputComponent={PhoneTextField}
        />
    );
}

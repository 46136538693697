import { ProfileController } from '@cbtravel/common/lib/shared/api/profiles/profile-controller';
import { MobilePhoneVerificationRQ } from "@cbtravel/common/lib/shared/messages/general/requests/custom/mobile-phone-verification-rq";
import { ProfileRS } from '@cbtravel/common/lib/shared/messages/profiles/responses/profile-rs';
import { InputDataType } from '@cbtravel/common/lib/web/common/enumerations/input-data-type';
import { dateUtil } from "../../util/date-util"
import DateFnsUtils from "@date-io/date-fns";
import { ProfileEntry } from "@cbtravel/common/lib/shared/common/enumerations/profile-entry";

import {
  Box, Button,
  Card,
  CardContent, Dialog, Grid, MenuItem, TextField,
  Typography, useMediaQuery

} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  TodayOutlined
} from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Control, Controller, DeepMap, FieldErrors, useFieldArray, useForm, UseFormClearErrors, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { useCustomSnackbar } from '../../components/shared/customHooks/useCustomSnackbar';
import Spinner from '../../components/shared/Spinner';
import { UserContext } from '../../components/shared/UserContext';
import VerificationDialog from "../../components/ui/Dialog/VerificationDialog";

import { EntityDepth } from '@cbtravel/common/lib/shared/common/enumerations/entity-depth';
import { PhoneType } from "@cbtravel/common/lib/shared/common/enumerations/phone-type";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { Phone } from '@cbtravel/common/lib/shared/common/messages/phone';
import { CustomFieldRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { PhoneInput } from "../../components/ui/Input/PhoneInput";
import TopBanner from '../../components/ui/TopBanner';
import { phoneUtil } from "../../util/phone-util";
import { AddressFields, formRules, SelectOption } from './Profile/Profile';
import { AddressType } from '@cbtravel/common/lib/shared/common/enumerations/address-type';
import { RegionRQ } from '@cbtravel/common/lib/shared/messages/general/requests/region-rq';
import { RegionTypeSearchType } from '@cbtravel/common/lib/shared/common/enumerations/search-types';
import { RegionSortType } from '@cbtravel/common/lib/shared/common/enumerations/sort-types';
import { PagedList } from '@cbtravel/common/lib/shared/common/paged-list';
import { RegionController } from '@cbtravel/common/lib/shared/api/general/controllers/region-controller';
import { RegionRS } from '@cbtravel/common/lib/shared/messages/general/responses/region-rs';
import { CustomFieldListFields } from '@cbtravel/common/lib/shared/messages/approval/responses/custom/custom-field-list-fields';
import CustomFieldFieldArray from '../../components/shared/CustomFieldFieldArray';
import { DataEntryFlow } from '@cbtravel/common/lib/shared/common/enumerations/data-entry-flow';
import { Gender } from '@cbtravel/common/lib/shared/common/enumerations/gender';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      "#pendo-image-badge-5c3c4f83": {
        display: "none !important",
      },
    },

    root: {
      width: 400,
      [theme.breakpoints.down("sm")]: {
        "& .MuiButton-containedSecondary": {
          width: "100%",
        },
      },
    },
    whiteBG: {
      background: "#fff",
    },
    logo: {
      height: 39,
    },
    box: {
      minHeight: "100vh",
      backgroundColor: "#1f2532",
    },
    container: {
      marginTop: 50,
      marginBottom: 50,
      "& .MuiSvgIcon-root": {
        fontSze: "1.3rem",
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: 0,
      },
    },
    // This is error validation styling for autocomplete and dropdown
    errorText: {
      paddingLeft: "13px",
      paddingBottom: "2px",
    },
    // This is error validation styling for regular textboxes 
    errorTextField: {
      paddingLeft: "13px",
      paddingTop: "4px",
    },
    errorAlignment: {
      flexDirection: "column",
    },
    errorTextCalendar: {
      paddingTop: '2px',
    }
  })
);

export type RequiredFormInputs = {
  dateOfBirth: Date | null,
  legalGender: string,
  phoneNumber: string,
  customFieldList: CustomFieldListFields[],
  addressList: Array<AddressFields>,
}

export interface RequiredFormProps {
  profileIsMissingOrIncomplete: boolean,
  setProfileIsMissingOrIncomplete: React.Dispatch<React.SetStateAction<boolean>>,
  existingUserProfile: ProfileRS | null,
  customFields: Array<CustomFieldRS>,
  destination: string,
  setDestination: React.Dispatch<SetStateAction<string>>,
  populateFormData(): void,
}

export default function RequiredFields(props: RequiredFormProps) {
  const classes = useStyles();
  const { handleSubmit, formState: { errors, isValid, dirtyFields }, control, setValue, getValues, clearErrors, reset, trigger } = useForm<RequiredFormInputs>({
    mode: "onBlur",
    defaultValues: {
      dateOfBirth: null,
      legalGender: "",
      phoneNumber: "",
      customFieldList: new Array<CustomFieldListFields>(),
      addressList: new Array<AddressFields>(),
    }
  });

  // set up field array for customField information
  const customFieldArray = useFieldArray({ control: control, name: "customFieldList", shouldUnregister: true });
  const watchCustomFieldList = useWatch({ control: control, name: "customFieldList" });
  const controlCustomFieldList = watchCustomFieldList?.map((field, index) => {
    return {
      ...field,
      ...watchCustomFieldList[index]
    };
  });

  const { userContext } = useContext(UserContext);
  const snackbar = useCustomSnackbar()
  const routerHistory = useHistory();
  const [hasExistingMobile, setHasExistingMobile] = useState<boolean>(false);
  const [hasExistingGender, setHasExistingGender] = useState<boolean>(false);
  const [hasExistingDob, setHasExistingDob] = useState<boolean>(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState<boolean>(false);
  const [verificationRequest, setVerificationRequest] = useState<MobilePhoneVerificationRQ>();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [createSearchUserDialogue, setCreateSearchUserDialogue] = useState<boolean>(false);
  const [countries, setCountries] = useState<RegionRS[]>([]);
  const [stateProvinces, setStateProvinces] = useState<RegionRS[]>([]);
  const [triggerValidation, setTriggerValidation] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);


  // these are all for the full screen dialog for required fields CD-6593
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // set up field array for address information
  const addressFieldArray = useFieldArray({ control: control, name: "addressList" });
  const watchAddressList = useWatch({ control: control, name: "addressList" });
  const controlAddresses = addressFieldArray.fields.map((field, index) => {
    return {
      ...field,
      ...watchAddressList[index]
    };
  });

  useEffect(() => {
    setShowSpinner(true);
    // get countries and provinces
    getCountries();
    getStateProvinces();

    /**
             * Gets and sets the list of countries for dropdown options.
             */
    async function getCountries() {
      const regionRQ = new RegionRQ();
      regionRQ.regionTypes.push(RegionTypeSearchType.Country);
      regionRQ.sortType = RegionSortType.ByPosition;
      try {
        const countryList: PagedList<RegionRS> = await RegionController.Find(regionRQ);
        setCountries(countryList.list);
      } catch (e) {
        snackbar.error(e as JsonException);
      }
    }

    /**
        * Gets and sets the list of states/provinces for the dropdown options.
        */
    async function getStateProvinces() {
      const regionRQ = new RegionRQ();
      regionRQ.regionTypes.push(RegionTypeSearchType.Subdivision);
      regionRQ.sortType = RegionSortType.ByPosition;
      try {
        const provinceList: PagedList<RegionRS> = await RegionController.Find(regionRQ);
        setStateProvinces(provinceList.list);
      } catch (e) {
        snackbar.error(e as JsonException);
      }
    }
  }, [])

  useEffect(() => {
    // populate profile if it exists on mount
    if (!props.existingUserProfile) return;
    if (!countries || countries.length === 0) return;
    if (!stateProvinces || stateProvinces.length === 0) return;

    // if Logged in user is not editing their own profile, use different dialogue
    if (Number(userContext.userId) !== props.existingUserProfile?.userId) {
      setCreateSearchUserDialogue(true);
    }

    let inputValues = profileRSToFormInputs(props.existingUserProfile);

    reset(inputValues);
    setTriggerValidation(true);
    setIsLoaded(true);

  }, [props.existingUserProfile, countries, stateProvinces, props.customFields])

  useEffect(() => {
    // iterate through the address fields and trigger each
    props.existingUserProfile?.addressList.forEach((a, i) => {
      trigger([
        `addressList.${i}.address1` as 'addressList.0.address1',
        `addressList.${i}.address2` as 'addressList.0.address2',
        `addressList.${i}.city` as 'addressList.0.city',
        `addressList.${i}.stateProvince` as 'addressList.0.stateProvince',
        `addressList.${i}.country` as 'addressList.0.country',
        `addressList.${i}.postalCode` as 'addressList.0.postalCode'
      ])
    })

    setShowSpinner(false);
  }, [triggerValidation])

  /**
     * Finds the RegionRS for a country or state based on its 2-letter country/state code (i.e. from Address objects)
     * @param code string: the 2-letter country or state code
     * @param parentCode string: the 2-letter country code for the state's parent region (prevents issues when states from different countries have the same code), empty string if we want to return a country
     * @returns a RegionRS of the desired country or state
     */
  function findCountryAndState(code: string, parentCode: string = "") {
    let found: RegionRS = new RegionRS();

    if (!parentCode && code) {
      let country = (countries?.find(c => c.code === code));
      if (country) found = country;
    }
    if (parentCode && code) {
      let parent = (countries?.find(c => c.code === parentCode));
      let state = (stateProvinces?.find(s => (s.code === code || s.isO31662 === code) && s.parentRegionId === parent?.regionId));
      if (state) found = state;
    }
    return found;
  }

  /**
   * Populates the required fields profile fields
   * @param profile the profile loaded in
   * @returns the form input values
   */
  function profileRSToFormInputs(profile: ProfileRS): RequiredFormInputs {
    let form: RequiredFormInputs = {
      addressList: profile.addressList.map((a) => ({
        addressType: a.addressType,
        address1: a.address1,
        address2: a.address2,
        city: a.city,
        country: findCountryAndState(a.countryCode),
        stateProvince: findCountryAndState(a.stateCode, a.countryCode),
        postalCode: a.postalCode
      })),
      dateOfBirth: profile.dateOfBirth,
      legalGender: profile.gender,
      phoneNumber: "",
      customFieldList: getCustomFields(), // leave the custom fields for the use effect
    }

    let mobilePhone = profile.phoneList?.find(p => p.phoneType === PhoneType.Mobile)?.number

    if (mobilePhone) {
      setHasExistingMobile(true);
      form.phoneNumber = mobilePhone
    }
    if (form.dateOfBirth && new Date(form.dateOfBirth).toTimeString() !== new Date("0001-01-01T00:00:00Z").toTimeString()) // check dob value exists
      setHasExistingDob(true);
    if (form.legalGender.length > 0)
      setHasExistingGender(true);

    return form;
  }

  function getCustomFields(): CustomFieldListFields[] {
    //set up custom fields user needs to fill out
    if (!props.customFields) return [];

    // setup custom fields
    let newCustomFieldsBuilder: CustomFieldRS[] = [];
    //filter props.customfields by required
    let requiredCustomFields = props.customFields.filter(cf => cf.isRequired);

    if (props.existingUserProfile) {

      // compare requiredCustomFields against userProfileCustomFields. Any left out get added.
      for (let i in requiredCustomFields) {
        //We should return only fields that have not been filled out by the user. We'll never get more than one since we are matching the udid numbers.
        let emptyField = props.existingUserProfile.customFieldList.filter(userCF => userCF.number === requiredCustomFields[i].number && userCF.value)
        // If the user does not have the custom field on their profile then we'll check the default value.
        if (emptyField && emptyField.length < 1) {
          // If there's no default value, we want to display the field.
          // We don't want to show custom fields that have default values because they are pre-configured for that client
          // and will be added to the profile in ProfileService.populateProfileCustomFields.
          if (!requiredCustomFields[i].defaultValue) {
            // Add to list of custom fields that will show on required fields.
            newCustomFieldsBuilder.push(requiredCustomFields[i]);
          }
        }
      }
    } else {
      // no profile means it's a new user, so all of them are required
      newCustomFieldsBuilder = requiredCustomFields;
    }

    let newFormFields = newCustomFieldsBuilder.map(cf => (
      {
        customFieldNumber: cf.number,
        value: "",
        customFieldId: cf.customFieldId,
        customFieldSource: cf.customFieldSource,
        example: cf.example,
        inputDataType: cf.inputDataType as InputDataType,
        inputMaxLength: cf.inputMaxLength,
        inputMinLength: cf.inputMinLength,
        inputType: cf.inputType,
        activeStatus: cf.activeStatus,
        isRequired: cf.isRequired,
        name: cf.name,
        noteInternal: cf.noteInternal,
        noteExternal: cf.noteExternal,
        number: cf.number,
        position: cf.position,
        reportingCodeId: cf.reportingCodeId,
        customFieldValueList: cf.customFieldValueList,
        accessibility: cf.accessibility
      }
    ))

    return newFormFields
  }

  function handleVerificationDialog(form: RequiredFormInputs) {
    setOpenVerificationDialog(true);
    let mobileVerificationRq: MobilePhoneVerificationRQ = new MobilePhoneVerificationRQ();
    mobileVerificationRq.clientId = Number(userContext.clientId);
    mobileVerificationRq.mobilePhoneNumber = form.phoneNumber;
    setVerificationRequest(mobileVerificationRq);
  }

  /**
   * Submits the form values.
   * 
   * @param form The object which holds the values that back the form.
   * @param existingProfile An existing profile object used to populate the rest of the fields.
   */
  async function onSubmit(form: RequiredFormInputs, existingProfile: ProfileRS | null) {
    setShowSpinner(true);
    let profile: ProfileRS | null = null;

    let phoneNumber = new Phone();
    phoneNumber.number = form.phoneNumber;
    phoneNumber.phoneType = PhoneType.Mobile;

    try {
      if (existingProfile) {
        existingProfile.firstName = props.existingUserProfile ? props.existingUserProfile?.firstName : userContext.userState.firstName;
        existingProfile.lastName = props.existingUserProfile ? props.existingUserProfile?.lastName : userContext.userState.lastName;
        existingProfile.userId = props.existingUserProfile ? props.existingUserProfile?.userId : Number(userContext.userId);
        existingProfile.client.clientId = props.existingUserProfile ? props.existingUserProfile?.client.clientId : Number(userContext.clientId);
        existingProfile.emailAddress = props.existingUserProfile ? props.existingUserProfile?.emailAddress : userContext.email;
        existingProfile.gender = form.legalGender;
        if (form.dateOfBirth)
          existingProfile.dateOfBirth = form.dateOfBirth;

        // update existingProfile customFields. If it already exists, add customfields.
        form.customFieldList?.forEach(cf => {
          let exists = false;
          for (let i = 0; i < existingProfile.customFieldList.length; i++) {
            if (existingProfile.customFieldList[i].number === cf.number) {
              exists = true;
              // custom field already exists, replace old values.
              existingProfile.customFieldList[i].value = typeof cf.value === 'string' ? cf.value : cf.value.value;
              existingProfile.customFieldList[i].customFieldSource = cf.customFieldSource;
            }
          }

          if (!exists) {
            existingProfile.customFieldList.push({
              number: cf.customFieldNumber,
              value: typeof cf.value === 'string' ? cf.value : cf.value.value,
              customFieldSource: cf.customFieldSource
            })
          }
        })


        form.addressList?.forEach((address) => {
          for (let i = 0; i < existingProfile.addressList.length; i++) {
            if (existingProfile.addressList[i].addressType === address.addressType) {
              existingProfile.addressList[i].address1 = address.address1;
              existingProfile.addressList[i].address2 = address.address2;
              existingProfile.addressList[i].city = address.city;
              existingProfile.addressList[i].countryCode = address.country.code;
              existingProfile.addressList[i].stateCode = address.stateProvince.isO31662;
              existingProfile.addressList[i].postalCode = address.postalCode;
            }
          }

        })

        if (phoneNumber) {
          // Phone number was invalid or missing.
          // replace existingProfile phone number with the form's phone number
          existingProfile.phoneList = existingProfile.phoneList.filter((p) => {
            return p.phoneType !== PhoneType.Mobile;
          });

          existingProfile.phoneList.push(phoneNumber);
        }

        if (!existingProfile.phoneList.find(p => p.phoneType === PhoneType.Mobile)) {
          existingProfile.phoneList.push(phoneNumber)
        }
        profile = await ProfileController.Update(userContext.accessToken, existingProfile, EntityDepth.Infinite, ProfileEntry.UserProfileUpdate);

      } else {

        const profileRS = new ProfileRS();
        profileRS.firstName = userContext.userState.firstName;
        profileRS.lastName = userContext.userState.lastName;
        profileRS.userId = Number(userContext.userId);
        profileRS.client.clientId = Number(userContext.clientId);
        profileRS.emailAddress = userContext.email;
        profileRS.gender = form.legalGender;
        if (form.dateOfBirth)
          profileRS.dateOfBirth = form.dateOfBirth;
        profileRS.phoneList = [phoneNumber];
        profileRS.customFieldList = form.customFieldList.map(f => (
          {
            number: f.customFieldNumber,
            value: typeof f.value === 'string' ? f.value : f.value.value,
            customFieldSource: f.customFieldSource
          }
        ))

        profile = await ProfileController.Add(userContext.accessToken, profileRS, EntityDepth.Infinite, ProfileEntry.UserProfileUpdate);
      }

      if (profile) {
        props.populateFormData();
        props.setProfileIsMissingOrIncomplete(false);
        if (props.destination.length > 0) {
          routerHistory.push(props.destination)
          props.setDestination("");
        }
      }

    } catch (e) {
      snackbar.error(e as JsonException);

    } finally {
      setShowSpinner(false);
    }

  }

  /**
   * 
   */
  function isValidAddress(address: AddressFields): boolean {

    if (address) {
      // if any of the required address fields, but not all, are not present, the address is not valid
      if ((address.address1 === "" && address.city !== "" && address.country.regionId !== -1)
        || (address.address1 !== "" && address.city === "" && address.country.regionId !== -1)
        || (address.address1 !== "" && address.city !== "" && address.country.regionId === -1)
        || (address.address1 === "" && address.city === "" && address.country.regionId !== -1)
        || (address.address1 === "" && address.city !== "" && address.country.regionId === -1)
        || (address.address1 !== "" && address.city === "" && address.country.regionId === -1)) {
        return false;
      }

      // if any of the non required address fields are filled out but the required aren't, address is invalid
      if ((address.address1 === "" || address.city === "" || address.country.regionId === -1)
        && (address.address2 !== "" || address.postalCode !== "" || address.stateProvince.regionId !== -1)) {
        return false;
      }

      return true;
    }

    return false;

  }

  return (
    isLoaded ? <Dialog
      fullScreen={fullScreen}
      open={props.profileIsMissingOrIncomplete}
      // onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {props.existingUserProfile ?
        window.location.search.substring(1) ?
          <TopBanner
            message={"Creating"}
            firstAndLastName={props.existingUserProfile.firstName + ' ' + props.existingUserProfile.lastName}
          />
          :
          <></>
        : <></>}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.box}
      >
        {showSpinner && <Spinner />}


        <div className={classes.container}>
          <Card style={{ width: 400 }}>
            <CardContent>
              <Box px={3}>
                <Box display="flex" justifyContent="center" py={3}>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/airportal-blue.png`}
                    // src={`${process.env.PUBLIC_URL}/img/logo/cbt.png`}
                    className={classes.logo}
                    alt="AirPortal Logo"
                    id="img-logo"
                  />
                </Box>
                <Box pb={4}>
                  {userContext.userId !== props.existingUserProfile?.userId.toString() ?
                    <Typography variant="body2" component="p" align="center">
                      This user does not have a travel profile configured.
                      Fill out these required fields to continue.
                    </Typography>
                    :
                    <Typography variant="body2" component="p" align="center">
                      These fields are <strong>required</strong> to create your
                      profile
                    </Typography>}
                </Box>
                {!hasExistingDob && <Box
                  display="flex"
                  justifyContent="center"
                  pb={2.5}
                  className={classes.errorAlignment}
                >
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    rules={{
                      validate: {
                        required: (value: Date | null) => {
                          if (!value) return "Please enter your date of birth";
                        },
                        DateInFuture: (value: Date | null) => {
                          let currentDate = new Date();
                          if (value && value > currentDate) {
                            return "date can not be in the future"
                          }
                        },
                      },
                    }}
                    render={({ field: { onChange, ref, value, onBlur } }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableFuture
                          margin="dense"
                          id="dob-picker"
                          label="Date of birth"
                          openTo="year"
                          format="MM/dd/yyyy"
                          placeholder="MM/DD/YYYY"
                          maxDateMessage={""}
                          invalidDateMessage=""
                          views={["year", "month", "date"]}
                          inputVariant="outlined"
                          value={value && dateUtil.compareToMinDate(new Date(value)) ? null : value}
                          onBlur={onBlur}
                          onClose={onBlur}
                          onChange={onChange}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                <IconButton
                                  color="primary"
                                  aria-label="Date of birth"
                                  id="btn-dateOfBirth"
                                >
                                  <TodayOutlined />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={errors.dateOfBirth ? true : false}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                  <Typography
                    variant="body2"
                    color="error"
                    className={classes.errorText}
                  >
                    {errors.dateOfBirth && errors.dateOfBirth.message}
                  </Typography>
                </Box>}
                {!hasExistingGender && <Box
                  display="flex"
                  justifyContent="center"
                  pb={3}
                  className={classes.errorAlignment}
                >
                  <Controller
                    name="legalGender"
                    control={control}
                    // defaultValue={null}
                    rules={{ required: "Please select a gender" }}
                    render={({ field: { onChange, ref, value, onBlur } }) => (
                      <Autocomplete
                        id="gender"
                        options={Object.values(Gender)}
                        blurOnSelect
                        autoSelect
                        autoHighlight
                        onBlur={onBlur}
                        getOptionLabel={(option) => option}
                        fullWidth
                        onChange={(q, change) => onChange(change)}
                        ref={ref}
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Legal gender"
                            id="ff-legal-gender"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            error={errors.legalGender ? true : false}
                          />
                        )}
                      />
                    )}
                  />
                  <Typography
                    variant="body2"
                    color="error"
                    className={classes.errorText}
                  >
                    {errors.legalGender && errors.legalGender.message}
                  </Typography>
                </Box>}
                {!hasExistingMobile && <Box
                  display="flex"
                  justifyContent="center"
                  pb={3}
                  className={classes.errorAlignment}
                >
                  <Controller
                    name={`phoneNumber` as const}
                    control={control}
                    rules={{
                      validate: {
                        required: (value: string) => {
                          if (value === undefined)
                            return "Please enter a valid mobile phone";
                          else if (value.trim().length === 0)
                            return "Please enter a valid mobile phone";
                        },
                        value: (value: string) => {
                          if (value && !phoneUtil.isPossibleNumber(value)) {
                            return "Please enter a valid phone number"
                          }
                        }
                      },
                    }}
                    render={({ field: { onChange, value, ref, onBlur } }) => (
                      <PhoneInput
                        id={`phone-mobile`}
                        label="Mobile phone number"
                        variant="outlined"
                        size="small"
                        placeholder="Enter phone number"
                        defaultCountry="US"
                        fullWidth
                        onBlur={onBlur}
                        disabled={hasExistingMobile}
                        InputProps={{
                          endAdornment: (
                            <>
                              {hasExistingMobile &&
                                <InputAdornment position="end" style={{ paddingRight: 12 }}>
                                  <LockOutlinedIcon style={{ width: 20 }} />
                                </InputAdornment>
                              }
                            </>
                          ),
                        }}
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        error={errors.phoneNumber ? true : false}
                      />
                    )}
                  />
                  <Typography
                    variant="body2"
                    color="error"
                    className={classes.errorTextField}
                  >
                    {errors.phoneNumber && errors.phoneNumber.message}
                  </Typography>
                </Box>}
                <Box>
                  <Grid container spacing={3}>
                    {customFieldArray.fields.map((field, index) => {
                      return (
                        <CustomFieldFieldArray
                          key={field.id}
                          field={field}
                          index={index}
                          control={control}
                          errors={errors}
                          customFieldValueList={field.customFieldValueList}
                          small={true}
                          flow={DataEntryFlow.RequiredFields}
                          viewingOtherProfile={props?.existingUserProfile?.userId !== Number(userContext.userId)}
                        />
                      );
                    })}
                  </Grid>
                </Box>

                {/* Start address */}
                {addressFieldArray.fields.map((field, index) => {
                  return (
                    !isValidAddress(field) && <RequiredFieldsAddressForm
                      key={field.id}
                      index={index}
                      control={control}
                      field={field}
                      errors={errors}
                      setShowSpinner={setShowSpinner}
                      setValue={setValue}
                      getValues={getValues}
                      countries={countries}
                      clearErrors={clearErrors}
                    />
                  );
                }
                )}

                <Box display="flex" justifyContent="center" pt={1} pb={2.5}>
                  <Button
                    variant="contained"
                    disabled={!isValid}
                    color="primary"
                    id="btn-continue-profile"
                    fullWidth
                    onClick={handleSubmit((data: RequiredFormInputs) =>
                      // skip phone verification if phone already exists or Logged in user is not editing their own profile 
                      (hasExistingMobile || props.existingUserProfile?.userId !== Number(userContext.userId)) ? onSubmit(data, props.existingUserProfile) : handleVerificationDialog(data)
                    )}
                  >
                    {hasExistingMobile || props.existingUserProfile?.userId !== Number(userContext.userId) ? "Continue to your profile" : "Continue to verify mobile phone"}
                  </Button>
                </Box>
                <VerificationDialog
                  openActionDialog={openVerificationDialog}
                  setOpenActionDialog={setOpenVerificationDialog}
                  positiveText="Verify and continue to your profile"
                  request={verificationRequest}
                  successAction={handleSubmit((data: RequiredFormInputs) =>
                    onSubmit(data, props.existingUserProfile)
                  )}
                />

              </Box>
            </CardContent>
          </Card>
        </div>
      </Box>
    </Dialog> : <></>
  );

}

type RequiredFieldsAddressFormProps = {
  index: number,
  control: Control<RequiredFormInputs>,
  field: AddressFields & { id: string }
  errors: DeepMap<RequiredFormInputs, FieldErrors>,
  setShowSpinner: Dispatch<SetStateAction<boolean>>,
  setValue: UseFormSetValue<RequiredFormInputs>,
  getValues: UseFormGetValues<RequiredFormInputs>,
  countries: RegionRS[],
  clearErrors: UseFormClearErrors<RequiredFormInputs>
}

function RequiredFieldsAddressForm(props: RequiredFieldsAddressFormProps) {
  const classes = useStyles();
  const snackbar = useCustomSnackbar();
  const [provinces, setProvinces] = useState<RegionRS[]>([]);

  useEffect(() => {
    //on mount: populates the state/province drop down with the correct values if this field has a country
    async function getFirstStateProvinces(parentRegionId: number) {
      let region = new RegionRQ();
      region.regionTypes.push(RegionTypeSearchType.Subdivision);
      region.regionTypes.push(RegionTypeSearchType.District);
      region.parentRegionId = parentRegionId;
      try {
        const provinceList: PagedList<RegionRS> = await RegionController.Find(region);
        setProvinces(provinceList.list);
      }
      catch (e) {
        snackbar.error(e as JsonException);
      }

    }
    if (props.field.country) {
      const country = props.field.country as RegionRS;
      if (country.regionId && country.regionId > 0) {
        getFirstStateProvinces(country.regionId);
      }
    }
  }, [])

  /**
   * Handles changing the addresss's country in the form.
   * @param value The new country to store in the form object.
   */
  async function handleChangeCountry(value: RegionRS | null, index: number) {
    if (value) {
      props.setShowSpinner(true);
      setProvinces([]);

      props.setValue(`addressList.${index}.country` as `addressList.0.country`, value)

      let regionRQ = new RegionRQ();
      regionRQ.regionTypes.push(RegionTypeSearchType.Subdivision);
      regionRQ.regionTypes.push(RegionTypeSearchType.District);
      regionRQ.parentRegionId = value.regionId;
      try {
        const provinceList: PagedList<RegionRS> = await RegionController.Find(regionRQ);

        if (provinceList.list.length <= 0) {
          // remove possible existing errors if there are no provinces to choose from
          props.clearErrors(`addressList.${index}.stateProvince` as `addressList.0.stateProvince`);
        }

        setProvinces(provinceList.list);
        props.setValue(`addressList.${index}.stateProvince` as `addressList.0.stateProvince`, new RegionRS())
      }
      catch (e) {
        snackbar.error(e as JsonException);
      }
      finally {
        props.setShowSpinner(false);
      }
    }
  }

  /**
* Determines if any part of this address has been filled out.
* 
* @returns True if the address has no filled out fields, false otherwise.
*/
  function areFieldsEmpty(): boolean {
    let address = props.field;

    return (address?.address1?.length === 0 &&
      address?.address2?.length === 0 &&
      address?.city?.length === 0 &&
      address?.postalCode?.length === 0 &&
      (!address.stateProvince ||
        address.stateProvince.regionId === -1) &&
      (!address.country ||
        address.country.regionId === -1));
  }

  return (<Box key={props.field.id}>
    <Box mb={2}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Controller
          name={`addressList.${props.index}.addressType` as const}
          control={props.control}
          render={({
            field: { onChange, value, ref, onBlur }
          }) => {
            return (
              <TextField
                id={`ff-address-${props.index}-type`}
                label="Address type"
                disabled
                variant="outlined"
                size="small"
                fullWidth
                onChange={onChange}
                value={value}
                ref={ref}
                onBlur={onBlur}
                InputProps={{ autoComplete: "off" }}
              >
                {addressTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>);
          }} />
      </form>
    </Box>

    <Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Controller
          name={`addressList.${props.index}.address1` as const}
          control={props.control}
          rules={{
            validate: {
              required: (value: string | null) => {
                if (!areFieldsEmpty() && value?.trim().length === 0)
                  return "Please enter a valid address";
              },
            },
            maxLength: { value: 255, message: "Max 255 characters" }
          }}
          render={({
            field: { onChange, value, ref, onBlur }
          }) => (
            <TextField
              id={`ff-address-${props.index}-address1`}
              label="Street address 1"
              variant="outlined"
              size="small"
              fullWidth
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              ref={ref}
              error={props.errors.addressList?.[props.index]?.address1 ? true : false}
              InputProps={{ autoComplete: "off" }}
            />
          )} />
      </form>
      <Typography variant='body2' color='error' className={classes.errorTextField}>
        {props.errors?.addressList && props.errors?.addressList?.[props.index]?.address1?.message}
      </Typography>
    </Box>

    <Box mt={2}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Controller
          name={`addressList.${props.index}.address2` as const}
          control={props.control}
          rules={{ maxLength: { value: 255, message: "Max 255 characters" } }}
          render={({
            field: { onChange, value, ref, onBlur }
          }) => (
            <TextField
              id={`ff-address-${props.index}-address2`}
              label="Street address 2"
              variant="outlined"
              size="small"
              fullWidth
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              ref={ref}
              error={props.errors.addressList?.[props.index]?.address2 ? true : false}
              InputProps={{ autoComplete: "off" }}
            />
          )} />
      </form>
      <Typography variant='body2' color='error' className={classes.errorTextField}>
        {props.errors?.addressList && props.errors?.addressList?.[props.index]?.address2?.message}
      </Typography>
    </Box>

    <Box mt={1}>
      <Controller
        name={`addressList.${props.index}.country` as const}
        control={props.control}
        rules={{
          validate: {
            required: (value: RegionRS) => {
              if (!areFieldsEmpty() && (!value || value.regionId === -1)) return "Please select a valid country";
            }
          }
        }}
        render={({
          field: { onChange, value, ref, onBlur }
        }) => (
          <Autocomplete
            id={`address-${props.index}-country`}
            options={props.countries}
            getOptionLabel={(option) => option.name ? option.name : ""}
            getOptionSelected={(option: RegionRS, value: RegionRS) => option.regionId === value.regionId || value.regionId == -1}
            fullWidth
            autoSelect
            autoHighlight
            value={value ? value : new RegionRS()}
            ref={ref}
            onBlur={onBlur}
            onChange={(q, change) => {
              handleChangeCountry(change, props.index)
              return onChange(change)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={`ff-address-${props.index}-country`}
                label="Country"
                variant="outlined"
                margin="dense"
                className={classes.whiteBG}
                InputLabelProps={{
                  shrink: true,
                }}
                error={props.errors.addressList?.[props.index]?.country ? true : false}
              />
            )}
          />
        )} />
      <Typography variant='body2' color='error' className={classes.errorTextCalendar}>
        {props.errors?.addressList && (props.errors?.addressList?.[props.index]?.country as any)?.message}
      </Typography>
    </Box>

    <Box mt={1}>
      <Controller
        name={`addressList.${props.index}.stateProvince` as const}
        control={props.control}
        rules={{
          validate: {
            required: (value: RegionRS) => {
              const a = props.getValues(`addressList.${props.index}` as `addressList.0`);
              if (!a.country)
                return true;
              const country = a.country as RegionRS;
              const v = value as RegionRS;
              if (country.regionId > 0 && provinces && provinces.length > 0) {  // we have a country and a list of provinces
                if (provinces?.some(p => country.regionId === p.parentRegionId) // and at least one state/province recognizes that country as its parent region
                  && v.parentRegionId // and we have a parentRegionId on our selected state
                  && v.parentRegionId > 0 // and it's a valid parentRegionId
                  && v.parentRegionId === country.regionId // and that parentRegionId matches the selected country
                ) {
                  return true; // outcome still true
                } else {
                  return "Please select a valid state/province for selected country" // invalid!
                }
              }
            }
          }
        }}
        render={({
          field: { onChange, value, ref, onBlur }
        }) => (
          <Autocomplete
            disabled={(provinces && provinces.length <= 0) ? true : false}
            id={`address-${props.index}-state`}
            options={provinces}
            getOptionLabel={(option) => option.name ? option.name : ""}
            getOptionSelected={(option: RegionRS, value: RegionRS) => option.regionId === value.regionId || value.regionId == -1}
            fullWidth
            autoSelect
            autoHighlight
            value={value ? (value as RegionRS) : new RegionRS()}
            ref={ref}
            onBlur={onBlur}
            onChange={(q, change) => onChange(change)}
            renderInput={(params) => (
              <TextField
                {...params}
                id={`ff-address-${props.index}-state`}
                label="State/Province"
                variant="outlined"
                margin="dense"
                className={classes.whiteBG}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                error={props.errors.addressList?.[props.index]?.stateProvince ? true : false}
              />
            )}
          />
        )} />
      <Typography variant='body2' color='error'>
        {props.errors?.addressList && (props.errors?.addressList?.[props.index]?.stateProvince as any)?.message}
      </Typography>
    </Box>

    <Box mt={2}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Controller
          name={`addressList.${props.index}.city` as const}
          control={props.control}
          rules={{
            validate: {
              required: (value: string) => {
                if (!areFieldsEmpty() && value.trim().length === 0) {
                  return "Please enter a city";
                }
              }
            },
            maxLength: { value: 255, message: 'Max 255 characters' }
          }}
          render={({
            field: { onChange, value, ref, onBlur }
          }) => (
            <TextField
              id={`ff-address-${props.index}-city`}
              label="City"
              variant="outlined"
              size="small"
              fullWidth
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              ref={ref}
              error={props.errors.addressList?.[props.index]?.city ? true : false}
              InputProps={{ autoComplete: "off" }}
            />
          )} />
      </form>
      <Typography variant='body2' color='error' className={classes.errorTextField}>
        {props.errors?.addressList && props.errors?.addressList?.[props.index]?.city?.message}
      </Typography>
    </Box>

    <Box mt={2} mb={2}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Controller
          name={`addressList.${props.index}.postalCode` as const}
          control={props.control}
          rules={{ maxLength: { value: 10, message: "Max 10 characters" } }}
          render={({
            field: { onChange, value, ref, onBlur }
          }) => (
            <TextField
              id={`ff-address-${props.index}-postal-code`}
              label="Postal code"
              variant="outlined"
              size="small"
              fullWidth
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              ref={ref}
              error={props.errors.addressList?.[props.index]?.postalCode ? true : false}
              InputProps={{ autoComplete: "off" }}
            />
          )} />
      </form>
      <Typography variant='body2' color='error'>
        {props.errors?.addressList && props.errors?.addressList?.[props.index]?.postalCode?.message}
      </Typography>
    </Box>
  </Box>)
}

const addressTypes = [
  AddressType.Billing,
  AddressType.Home,
  AddressType.Business
];

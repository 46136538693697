import { useState } from "react";

/**
 * Properties for the `BulkImportIcon` component.
 */
interface SvgProps {
  /** Width of the icon. */
  width: string;
  /** Height of the icon. */
  height: string;
  /** Color of the icon. */
  color: string;
  /** Color of the icon when hovering over it. */
  hoverColor?: string;
}

/**
 * A component for displaying the bulk import icon.
 * 
 * @param props {@link SvgProps Properties} properties for the `BulkImportIcon` component.
 * @returns A JSX element to display the bulk import icon.
 */
export default function BulkImportIcon(props: SvgProps) {

  const [hover, setHover] = useState<boolean>(false);

  const hvrColor = props.hoverColor === undefined ? props.color : props.hoverColor;//if we have no hoverColor, default to color.
  return (
    <svg
      width={props.width}
      height={props.height}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 50 51"
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
    >

      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3945 4.5V15.8136H9.89453V4.25C9.89453 3.00736 10.9019 2 12.1445 2H47.1088V48.4726H9.89453V47.2226V34.659H12.3945V45.9726H44.6088V4.5H12.3945Z" fill={props.color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0276 23.9863L20.7855 15.1465L22.5136 13.3398L34.9513 25.2363L22.5136 37.1328L20.7855 35.3261L30.0276 26.4863H1V23.9863H30.0276Z" fill={props.color} />

    </svg>
  );
}


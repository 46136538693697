import { useContext, useEffect, useRef, useState } from "react";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { UserContext } from "../../../components/shared/UserContext";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Button, Checkbox, CircularProgress, Grid, IconButton, Link, MenuItem, TextField, Typography } from "@material-ui/core";
import { TableContainer, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MultipleSelectChips, { Option } from "../../../components/ui/Input/MultipleSelectChips";
import Spinner from "../../../components/shared/Spinner";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import SortIcon from "../../../icons/SortIcon";

import ReferenceDataForm from "./ReferenceDataForm";
import HotelDataRow from "./HotelDataRow";

interface HotelTabProps {
    /** Object representing the currently active client. */
    activeClient?: ClientRS,
    /** Whether or not to display the add new data form */
    displayReferenceDataForm: boolean,
    /** Function to call when changes are discarded from the ReferenceDataForm */
    discardChanges: () => void,
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number
    ) => void;
}

const useStyles = makeStyles((theme) => ({
    "@global": {
        ".MuiAutocomplete-option": {
            display: "block",
        },
        ".MuiAutocomplete-popupIndicatorOpen": {
            transform: "rotate(0)"
        },
        // Remove after multiplechipselect is implemented
        ".MuiAutocomplete-listbox": {
            padding: "0 0 8px",
        },
    },
    results: {
        paddingTop: 33,
        paddingBottom: theme.spacing(0.5),
        marginBottom: 0,
        fontSize: '1rem',
    },
    pagination: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    downloadWrapper: {
        paddingTop: 33,
    },
    download: {
        paddingTop: 18,
        paddingRight: "4px",
        fontSize: '.8rem',
    },
    filetype: {
        padding: 0,
        "& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense":
        {
            padding: "0 4px 2px",
        },
    },
    headersWithIcon: {
        padding: '0px 24px 2px 16px !important',
    },
    table: {
        "& .MuiTableCell-root": {
            borderBottom: 'none',
            lineHeight: 1.5,
        },
        "& .MuiTableCell-head": {
            borderTop: '1px solid #E0E0E0 !important',
            borderBottom: '1px solid #E0E0E0 !important',
            padding: '3px 24px 5px 16px',
            whiteSpace: 'nowrap',
        },
    },

    // Remove when multipleSelectChip is implemented
    formControl: {
        "& .MuiChip-label": {
            paddingLeft: "8px",
            paddingRight: "12px",
            paddingBottom: "2px",
        },
        "& .MuiChip-root": {
            backgroundColor: "#00467E",
            fontSize: ".75rem",
            height: "24px",
            marginBottom: -1, // this gets rid of the extra padding when a chip has been selected - add to the multiselectchip component 
        },
        "& .MuiChip-deleteIcon": {
            color: "white",
            width: "15px",
        },
    },
    checkBox: { color: "#00467E" },
    // Remove when multipleSelectChip is implemented
    filterPadding: {
        [theme.breakpoints.up("sm")]: {
            paddingTop: 6,
        },
    },

    filterCode: {
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: -4,
        },
    },
}));

/** Hotel data tab */
export default function HotelTab(props: HotelTabProps) {
    const userState = useContext(UserContext);
    const classes = useStyles();
    const fileType = ["CSV", "ZIP"];
    const [loading, setLoading] = useState<boolean>(false);
    const [referenceDataType, setReferenceDataType] = useState("");
    const { displayReferenceDataForm, discardChanges } = props;

    const handleReferenceDataType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReferenceDataType(event.target.value);
    };

    return (
        <>
            {displayReferenceDataForm ?
                <ReferenceDataForm
                    discardChanges={discardChanges}
                    dataType="hotel"
                /> :
                <Box mt={1.75}>
                    <Box mb={1} ml={-.5}>
                        <Typography variant="h2" color="textPrimary">
                            Filter by
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2} alignContent="flex-end">
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <Box mt={1}>
                                        <TextField
                                            id="reference-data-type"
                                            variant="outlined"
                                            fullWidth
                                            select
                                            size="small"
                                            label="Type"
                                            value={referenceDataType}
                                            InputProps={{ autoComplete: "off" }}
                                            onChange={handleReferenceDataType}
                                        >
                                            {referenceDataTypes.map((option) => (
                                                <MenuItem key={option.type} value={option.type}>
                                                    {option.type}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </form>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                {/* <MultipleSelectChips
                                label="Status"
                                options={statuses}
                                value={statusValue}
                                onChange={setStatusValue}
                                error={false}
                                errorMessage={""}
                            /> */}

                                {/* TODO: should use components/ui/Input/MultipleSelectChips but this is just for showing the two active states  */}
                                <Autocomplete
                                    className={classes.formControl}
                                    multiple={true}
                                    options={referenceDataStatuses}
                                    // filterOptions={filterOptions}
                                    // value={value}
                                    // inputValue={inputValue}
                                    // onInputChange={(event, value, reason) => {
                                    //     if (reason !== "reset") {
                                    //         setInputValue(value);
                                    //     }
                                    // }}
                                    disableCloseOnSelect={true}
                                    getOptionLabel={(option) => option.status}
                                    // getOptionSelected={(option: Option, value: Option) => {
                                    //     return option.value == value.value;
                                    // }}
                                    renderOption={(option, { selected }) => (
                                        <>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checked={selected}
                                                checkedIcon={
                                                    <CheckBoxIcon className={classes.checkBox} fontSize="small" />
                                                }
                                            />
                                            {option && option.status}
                                        </>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Status"
                                            InputLabelProps={{ shrink: true }}
                                            margin="dense"
                                        // error={error}
                                        // helperText={errorMessage}
                                        />
                                    )}
                                // onChange={(event, value) => props.onChange(value)}
                                // onClose={(event, reason) => {
                                //     if (reason === "escape" || reason === "blur" || (reason === "toggleInput" && event.type === "click")) {
                                //         setInputValue("");
                                //     }
                                // }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className={classes.filterPadding}>
                        <Grid container spacing={2} alignContent="flex-end">
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} className={classes.filterCode}>
                                <TextField
                                    label="Code"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={1} style={{ marginLeft: -3 }}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            // onClick={submitFilterOptions}
                            id="filter-btn"
                        >
                            Filter
                        </Button>
                        <Button
                            style={{ color: "#00467E", marginLeft: "8px" }}
                            variant="text"
                            // onClick={clearFilter}
                            id="clear-filter-btn"
                        >
                            Clear filters
                        </Button>
                    </Box>

                    <Grid container spacing={2} alignContent="flex-end">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box display="flex" flexDirection="row" pb={1} style={{ marginTop: -20, }}>
                                <Box flexGrow={1}>
                                    <h3 className={classes.results}>2 Results</h3>
                                </Box>
                                <Box className={classes.downloadWrapper} display="flex" flexDirection="row">
                                    <Box pt={2} mr={1} className={classes.download}>
                                        <span className="dark-blue">
                                            <Link
                                                href="#"
                                                onClick={() => {
                                                    // handleDownloadReport(
                                                    //   "CSV" ==
                                                    //   userState.properties.get("downloadFormat")
                                                    // );
                                                }}
                                            >
                                                Download report
                                            </Link>
                                        </span>
                                    </Box>
                                    <Box style={{ marginTop: 11, }}>
                                        <Autocomplete
                                            // value={userState.properties.get("downloadFormat")}
                                            // onChange={(
                                            //     event: any,
                                            //     newValue: string | undefined
                                            // ) => {
                                            //     let before = userState.properties.get(
                                            //         "downloadFormat"
                                            //     );
                                            //     if (newValue == "ZIP") {
                                            //         userState.updateProperties("downloadFormat", "ZIP");
                                            //     } else if (newValue == "CSV") {
                                            //         userState.updateProperties("downloadFormat", "CSV");
                                            //     } else {
                                            //         return;
                                            //     }
                                            //     let after = userState.properties.get(
                                            //         "downloadFormat"
                                            //     );
                                            // }}
                                            className={classes.filetype}
                                            // inputValue={userState.properties.get("downloadFormat")}
                                            id="report-filetype"
                                            style={{ width: 70 }}
                                            options={fileType}
                                            disableClearable
                                            autoSelect
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-label="Hotel reference data table"
                                    size="small"
                                    id="table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Code</TableCell>
                                            <TableCell className={classes.headersWithIcon}
                                            // onClick={() => handleSortIconClick(VendorSortType.ByName)}
                                            >Name <SortIcon /></TableCell>
                                            <TableCell align="center">Display tier</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Edit</TableCell>
                                            <TableCell align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody id="tableBody">
                                        <HotelDataRow code="DB" extraCodes={true} name="Double" displayTier={5} referenceDataType="Room type" isActive={true} />
                                        <HotelDataRow code="KG" extraCodes={false} name="King" displayTier={5} referenceDataType="Room type" isActive={true} />
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            {/* TODO: Pagination */}
                                            {/* <TablePagination
                                        rowsPerPageOptions={[25, 50, 100]}
                                        colSpan={12}
                                        count={vendorList.totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={pageNumber}
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={tablePagination}
                                        /> */}
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            }
        </>
    );
}

// Please remove this once hooked up
const referenceDataTypes = [
    { type: 'Add-on' },
    { type: 'Amenity' },
    { type: 'Room type' },
];
const referenceDataStatuses = [
    { status: 'Active' },
    { status: 'Inactive' },
]



import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { TextField } from "@material-ui/core";
import Autocomplete, {
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import { LocationRS } from '@cbtravel/common/lib/shared/messages/general/responses/location-rs';
import { LocationRQ } from '@cbtravel/common/lib/shared/messages/general/requests/location-rq';
import { LocationController } from '@cbtravel/common/lib/shared/api/general/controllers/location-controller'
import { useCustomSnackbar } from './customHooks/useCustomSnackbar'
import { JsonException } from '@cbtravel/common/lib/shared/common/exceptions/json-exception'
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";
import { LocationSearchType } from "@cbtravel/common/lib/shared/common/enumerations/search-types";
import { LocationSortType } from "@cbtravel/common/lib/shared/common/enumerations/sort-types";
import { ServiceType } from "@cbtravel/common/lib/shared/common/enumerations/service-type";
import { PagedList } from "@cbtravel/common/lib/shared/common/paged-list";
import { RefCallBack } from "react-hook-form";
import { Configuration } from "@cbtravel/common/shared/config/client-config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      ".MuiAutocomplete-option": {
        display: "block",
      },
      ".MuiAutocomplete-popupIndicatorOpen": {
        transform: "rotate(0)"
      }
    },
    approverPopout: {
      "& .MuiAutocomplete-option": {
        display: "block",
      },
    },
    approverName: {
      fontSize: ".9rem",
      fontWeight: 600,
      display: "block",
    },
    emailAddress: {
      fontSize: ".8rem",
    },
  })
);

/**
 * Properties for the `LocationSearchBox` component.
 */
interface LocationSearchBoxProps {
  /** Text to display as a label for the `LocationSearchBox`. */
  label: string,
  /** The value held by the `LocationSearchBox`. */
  location?: LocationRS,
  /** Whether or not the `LocationSearchBox` should autofocus. */
  focus?: boolean,
  /** onChange event handler to use when not using React Hook Forms. */
  onChange?: ((value: LocationRS) => void), // This field is not optional unless you are using react hook form (then use the below instead)
  /** onChange event handler to use when using React Hook Forms. */
  onReactHookChange?: (change: any, value: any) => void, //react-hook-forms has a very specific onChange set up and we have to use; this should not be used if you aren't using RHF
  /** Text to display when no value is selected. */
  placeholder?: string,
  /** React Hook Forms ref object. */
  ref?: RefCallBack,
  /** React Hook Forms onBlur action. */
  onBlur?: () => void,
  /** Specifies the kind of locations to search for. */
  serviceType?: ServiceType,
}

/**
 * The LocationSearchBox component is used as a UI element that allows a user to search for locations.
 * 
 * @param props {@link LocationSearchBoxProps Properties} for the `LocationSearchBox` component.
 * @returns A JSX element used for searching for locations.
 */
export default function LocationSearchBox(props: LocationSearchBoxProps) {
  const classes = useStyles();
  // const userState = useContext(UserContext);
  const snackbar = useCustomSnackbar();

  //state variables
  const [searchResults, setSearchResults] = React.useState<(LocationRS)[]>([new LocationRS()]); //the results of the location search, will be spread into the autocomplete options array
  const [loading, setLoading] = useState<boolean>(false); //whether or not the autocomplete should display its loading settings
  const [searchText, setSearchText] = useState<string>(""); //the text the location has typed into the autocomplete input
  const [currentValue, setCurrentValue] = useState<LocationRS | null | undefined>(props.location?.locationId !== -1 ? props.location : null) // the current value of the autocomplete

  /**
   * Gets the value of what's being typed in the input field for searching for a location, sets the searchText value.
   * 
   * @param event the event that triggers the change (not used, but in the mui signature).
   * @param value the value of the input inside the autocomplete.
   * @param reason the reason for the change "reset" for programmatic change, "input" for location input.
   */
  function handleInputChange(event: object, value: string, reason: string) {
    //only change searchText if the input is changing from location input (not props changing or being cleared)
    if (reason !== "reset") {
      setSearchText(value);
      //when clear the location
      if (value.length === 0) {
        if (props.onReactHookChange) {
          props.onReactHookChange(event, new LocationRS());
        }
        else if (props.onChange) {
          props.onChange(new LocationRS());
        }
      }
      else if (value.length < 3) {
        // clear this array so that we don't keep results when folks delete search text
        setSearchResults([new LocationRS()])
      }
    }
  };

  //registers changes in searchText and executes locationSearch only after 1.5 seconds of no change so we know the user is done typing
  useEffect(() => {

    if (searchText.length > 2) {
      setLoading(true)

      //don't search until we know the user is done typing (1.5 seconds after they stop)
      const timeOut = setTimeout(function () {
        locationSearch(searchText.toLowerCase())
      }, Configuration.SearchWaitConfigTime)

      return () => clearTimeout(timeOut)
    }
    else {
      setLoading(false)
    }

  }, [searchText]);

  /**
   * Creates a LocationRQ and sends to services to execute a search on first/last name + email address.
   * 
   * @param text The value to search the database with.
   */
  async function locationSearch(text: string) {
    const locationRQ = new LocationRQ();
    locationRQ.activeSearchType = ActiveSearchType.Active;
    locationRQ.skip = 0;
    locationRQ.limit = 15;
    locationRQ.searchType = LocationSearchType.ByAll;
    locationRQ.sortType = LocationSortType.ByName;
    locationRQ.serviceType = props.serviceType ? props.serviceType : ServiceType.Flight;
    locationRQ.wildcard = text;

    try {
      const resultList: PagedList<LocationRS> = await LocationController.Find(locationRQ);
      setSearchResults([new LocationRS(), ...resultList.list]);
    } catch (err) {
      snackbar.error(err as JsonException);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Fires when the Autocomplete has an option selected; calls props.onChange then clears searchText and options
   * 
   * @param event The event that triggers the onSelect  (not used, but in the mui signature).
   * @param value The value of the autocomplete, i.e. the option selected.
   */
  function handleSelect(event: object, value: (LocationRS) | null, reason: string) {
    setCurrentValue(value);

    if (props.onReactHookChange) {
      value && props.onReactHookChange(event, value);
    }
    else if (props.onChange) {
      value && props.onChange(value);
    }
    setSearchText("");
    setSearchResults(value ? [value] : [new LocationRS()]);
  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <Autocomplete
          openOnFocus={props.location?.locationId !== -1}
          value={currentValue}
          blurOnSelect={true}
          onInputChange={handleInputChange}
          autoSelect
          autoHighlight
          loading={loading}
          options={searchResults}
          classes={{ option: classes.approverPopout }}
          filterOptions={(options) => options}
          getOptionLabel={(option) => `${option.code} - ${option.name}`}
          getOptionSelected={(option: LocationRS, value: LocationRS) => option.locationId === value.locationId || value.locationId == -1}
          popupIcon={<SearchOutlinedIcon />}
          renderOption={(option) => (
            <React.Fragment>
              <span className={classes.approverName}>
                {option.code} - {option.name}
              </span>
              <span className={classes.emailAddress}>{option.provinceRegion.regionId != -1 && option.provinceRegion.name + ", "}{option.countryRegion.regionId != -1 && option.countryRegion.name}</span>
            </React.Fragment>
          )}
          ref={props.ref && props.ref}
          onBlur={props.onBlur && props.onBlur}
          style={{ minWidth: 300 }}
          renderInput={(params) => (
            <TextField
              autoFocus={props.focus}
              {...params}
              label={props.label}
              placeholder={props.placeholder && props.placeholder}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                autoComplete: 'off',
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, value, reason) => handleSelect(event, value, reason)}
        />
      </form>
    </React.Fragment>
  );
}

import { useEffect, useState } from 'react';

import { UserType } from '@cbtravel/common/lib/shared/common/enumerations/user-type';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TodayOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
    KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider
} from '@material-ui/pickers';

import { check } from '../../shared/Can';
import permissions from '../../shared/Permissions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputDisabled: {
            "& .MuiSelect-icon": {
                display: 'none',
            },
            "& .MuiInputBase-input.Mui-disabled": {
                color: '#808080',
            },
            "& .MuiIconButton-root.Mui-disabled": {
                marginRight: -13,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
                color: '#999999'
            },
        },
    }));

/**
 * MaskedDatePickerProps includes all the properties needed from KeyboardDatePickerProps 
 * and 
 * VisibilityPermissionProps whiich requires the component to be given
 * a UserType and the action `perform` to determine and control visibility of the field.
 */
interface MaskedDatePickerProps extends KeyboardDatePickerProps {
    /** The name of the role. */
    userType: UserType,
    /** The name of the action to check permission for. */
    action: string,

}

/**
 * 
 * @param props 
 */
export default function MaskedDatePicker(props: MaskedDatePickerProps) {
    const classes = useStyles();

    /** boolean state to control whether the content of the field is masked (Visible)-- */
    const [fieldVisibility, setFieldVisibility] = useState<boolean>(false);
    /** boolean state to control whether the calendar UI is opened or not -- default to false*/
    const [calendarOpened, setCalendarOpened] = useState<boolean>(false);
    /** boolean state whether the user can unmask (reveal) the field */
    const [canReveal, setCanReveal] = useState<boolean>(() => check(permissions, props.userType, props.action));

    /** Omit props that require modifications for masking */
    const { disabled, className, onClose, open, InputProps, InputAdornmentProps, KeyboardButtonProps, ...datePickerProps } = props;

    // UseEffect to update visibility state when userType or action props changed
    useEffect(() => {
        setCanReveal(check(permissions, props.userType, props.action));
    }, [props.userType, props.action]);
    
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    {...datePickerProps}
                    className={canReveal ? "" : classes.inputDisabled}
                    type={fieldVisibility ? "text" : "password"}
                    onClose={() => { props.onClose?.(); setCalendarOpened(false); }}
                    open={calendarOpened}
                    fullWidth
                    disabled={canReveal ? false : true}
                    InputProps={{
                        autoComplete: "off",
                        endAdornment: (
                            <>
                                {canReveal &&
                                    <>
                                        {/* 2nd Most right button: Visibility */}
                                        <IconButton
                                            style={{ marginRight: 0, cursor: "pointer", order: 1 }}
                                            onClick={() => setFieldVisibility(!fieldVisibility)}
                                        >
                                            {fieldVisibility ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        {/* Most right button: Calendar icon */}
                                        <IconButton
                                            onClick={() => { setCalendarOpened(true); }}
                                            style={{ marginRight: 20, cursor: "pointer", order: 2 }}
                                        >
                                            <TodayOutlined />
                                        </IconButton>
                                    </>
                                }
                                {!canReveal &&
                                    <>
                                        <LockOutlinedIcon
                                            className={classes.inputDisabled}
                                        />
                                    </>
                                }
                            </>
                        )
                    }}
                    InputAdornmentProps={{
                        position: "start",
                        style: { order: 2, marginLeft: 0, }
                    }}
                    KeyboardButtonProps={{ style: { display: 'none' } }}
                    error={props.error}

                />
            </MuiPickersUtilsProvider>
        </>);
}
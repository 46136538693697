import React from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {useCustomSnackbar} from '../../../../components/shared/customHooks/useCustomSnackbar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            "& .MuiDialog-paper": {
                textAlign: "center",
                width: 350,
            },
            "& .MuiDialogTitle-root": {
                padding: "30px 24px 0",
            },
            "& .MuiDialogContent-root": {
                padding: "16px 24px",
            },
            "& .MuiTypography-body1": {
                lineHeight: "20px",
                fontSize: "13px",
                color: "#4D4D4D",
            },
            "& .MuiTypography-h6": {
                fontWeight: 600,
            },
        },
        strong: {
            fontWeight: 600,
            lineHeight: 1.5,
        },
        iconAction: {
            margin: "8px auto 14px",
            background: "#bc2c2f",
            borderRadius: 40,
            width: "44px",
            height: "44px",
            "& .MuiSvgIcon-root": {
                color: "#fff",
                marginTop: "10px",
            },
        },
        dialogActions: {
            padding: "16px 32px 24px",
        },
        btnSave: {
            background: "#00467F",
            "&:hover": {
                backgroundColor: "#00467F" // I don't know why these buttons change on hover when others in the app don't, but this hides that behavior
            },
        },
        btnAddManager: {
            marginTop: "9px",
            padding: "5px 15px !important",
            background: "transparent !important",
        },
        btnCancel: {
            color: "#00467E",
            marginLeft: "0 !important",
            fontSize: "13px",
        },
    })
);

interface AddManagerDialogProps{
    /** Boolean prop that contains Active Status of the manager (udid 38) custom field. Undefined means it's never configured*/
    isManagerCustomFieldActive: boolean | undefined
    /** Function callback when the "Proceed" button is clicked on the dialog */
    onClickPositiveAction: () => void,
    /** Boolean prop indicating whether the dialog has popped up before  */
    isManagerFieldApplied: boolean | undefined
    
}
export default function AddManagerDialog(props: AddManagerDialogProps): JSX.Element {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const snackbar = useCustomSnackbar();
    const handleClickOpen = () => {
        //if the customfield 38 isn't configured for this client 
        if (props.isManagerCustomFieldActive === undefined)
            snackbar.info("Traveler's Manager field needs to be configured for this client to enable this feature");
        // open the dialog if manager custom field exists but isn't active 
        else if (!props.isManagerCustomFieldActive && !props.isManagerFieldApplied)
            setOpen(true);
        else
            props.onClickPositiveAction();
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="outlined" className={classes.btnAddManager} onClick={handleClickOpen}>
                Add manager
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.dialog}
            >

                <DialogTitle>
                    Do you want to proceed?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Adding "Traveler's manager" will now require travelers to provide their manager's email at next login or upon account creation
                    </Typography>
                </DialogContent>

                <div className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        className={classes.btnSave}
                        fullWidth={true}
                        onClick={props.onClickPositiveAction}
                    >
                        Proceed
                    </Button>
                    <Button
                        className={classes.btnCancel}
                        fullWidth={true}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </div>
            </Dialog>
        </>
    );
}

import { UserController } from '@cbtravel/common/lib/shared/api/general/controllers/user-controller';
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";
import { ReturnCount } from "@cbtravel/common/lib/shared/common/enumerations/return-count";
import { UserSearchType } from "@cbtravel/common/lib/shared/common/enumerations/search-types";
import { UserSortType } from "@cbtravel/common/lib/shared/common/enumerations/sort-types";
import { JsonException } from '@cbtravel/common/lib/shared/common/exceptions/json-exception';
import { PagedList } from "@cbtravel/common/lib/shared/common/paged-list";
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { UserRQ } from '@cbtravel/common/lib/shared/messages/general/requests/user-rq';
import { UserRS } from '@cbtravel/common/lib/shared/messages/general/responses/user-rs';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import { UserContext } from '../../../components/shared/UserContext';


//components
import {
  Box,
  Grid, IconButton, Table, TableBody,
  TableCell, TableContainer,
  TableFooter,
  TableHead,
  TablePagination, TableRow, TextField, Typography
} from "@material-ui/core";

import { ClientSettingsController } from '@cbtravel/common/lib/shared/api/general/controllers/client-settings-controller';
import { EntityDepth } from '@cbtravel/common/lib/shared/common/enumerations/entity-depth';
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { ClientSettingRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-setting-rs';
import MultipleSelectChips, { Option } from "../../../components/ui/Input/MultipleSelectChips";
import SortIcon from "../../../icons/SortIcon";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { formUtils } from '../../../util/form-utils';
import UserForm from './UserForm';
import UserSearchDataRow from "./UserSearchDataRow";
import { SortDirection } from '@cbtravel/common/lib/shared/common/enumerations/sort-direction';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiTableCell-root": {
      lineHeight: 1.4,
    },
    "& .MuiButton-contained": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  results: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    marginBottom: 0,
  },
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  headersWithIcon: {
    padding: '0px 24px 0px 16px !important',
  },
  table: {
    marginBottom: 50,
    "& .MuiTableCell-root": {
      borderBottom: 'none',
      lineHeight: 1.5,
    },
    "& .MuiTableCell-head": {
      borderTop: '1px solid #E0E0E0 !important',
      borderBottom: '1px solid #E0E0E0 !important',
      padding: '3px 24px 5px 16px',
      whiteSpace: 'nowrap',
    },
    "& .MuiTableRow-root": {
      borderTop: '1px solid #E0E0E0 !important',
      borderBottom: '1px solid #E0E0E0 !important',
    },
  },
  mobileMargin: {
    marginTop: 10,
  },
  errorText: {
    paddingTop: '5px',
  },
}));

type userSearchFormInputs = {
  searchTerm: string,
  account: string,
}

interface sortManagerI {
  firstName: boolean,
  lastName: boolean,
  activeStatus: boolean
}

/**
 * Creates an UserRQ and sends to services to execute a search on current filter in the page .
 * 
 * @param text The value to search the database with.
 */
export default function Users(props: { activeClient?: ClientRS, }) {
  const classes = useStyles();
  const theme = useTheme();
  const [searchResults, setSearchResults] = useState<PagedList<UserRS>>(new PagedList<UserRS>());
  const [selectedClients, setSelectedClients] = useState<Option[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortBy, setSortBy] = useState<UserSortType>(UserSortType.ByLastName);
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const { userContext } = useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const snackbar = useCustomSnackbar();
  const [linkActive, setLinkActive] = useState<boolean>(false);

  const [sortManager, setSortManager] = useState<sortManagerI>({
    firstName: true,
    lastName: true,
    activeStatus: true
  });

  const clientOptions: Option[] = userContext.viewableClients.map((c) => {
    return { label: c.name + (c.accountNumber ? " (" + c.accountNumber + ")" : ""), value: c.clientId };
  });
  /**
   * formRules form for firstname,lastname and accounts
   */
  const formRules = {
    searchTerm: {
    },
    account: {
      required: "Please select at least one account",
      validate: {
        value: (value: string) => {
          if (value) {
            if (value.length > Configuration.ClientSearchLimit.value) {
              return "You may only include " + Configuration.ClientSearchLimit.name + " items. Please reduce your selection.";
            }
          }
        }
      }
    }
  }
  const {
    formState: { errors, isValid },
    control,
    getValues,
    setValue,
  } = useForm<userSearchFormInputs>({ mode: "onChange" });

  useEffect(() => {
    if (searchTerm.length > 1) {

      //don't search until we know the user's done typing (1.5 seconds after they stop)
      //search until the user type on either last/first name with minimum 2 characters
      const timeOut = setTimeout(function () {
        userSearch(0)
        //set the page number to first page when initialize a search
        setPageNumber(0);
      }, Configuration.SearchWaitConfigTime)
      return () => clearTimeout(timeOut)
    }
    else if (selectedClients.length > 0) {
      userSearch(0)
      //set the page number to first page when initialize a search
      setPageNumber(0);
    }
  }, [searchTerm, sortBy, sortAscending, selectedClients, rowsPerPage]);

  useEffect(() => {
    if (userContext.clientName !== "" && userContext.clientId !== "") setSelectedClients([...selectedClients, { label: userContext.clientName, value: parseInt(userContext.clientId) }]);
  }, [userContext]);

  // This useEffect decides if we should activate or disable the "Add user" link by watching the client selector.
  useEffect(() => {
    if (selectedClients.length === 1) {
      EnableAddUser(selectedClients[0].value);
    }
    else {
      setLinkActive(false);
    }
  }, [selectedClients])

  useEffect(() => {

    // Watch the user's active client so we that we can select that client by default when this page is rendered.
    if (props.activeClient) {
      let clientName: string = props.activeClient.name;
      let clientId: number = props.activeClient.clientId;
      setValue("account", clientName);
      setSelectedClients([{ label: clientName, value: clientId }]);
    }
  }, [props.activeClient]);

  /**
   * Handles the updating of pagination.
   * @param event is provided but not used. 
   * @param newPage is the new page number.
   */
  function handleChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
    setPageNumber(newPage);
  }

  /**
   * Handles changing the number of tickets that are displayed in the table.
   * @param event the new number of tickets to be displayed by the table.
   */
  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  }

  /**
   * We'll only use this if there's one client in the client selector.
   * @param clientId 
   * @returns Returns a client settings object for a given client id. 
   */
  async function getClientSettings(clientId: number) {
    try {
      let clientSettings: ClientSettingRS = await ClientSettingsController.GetRootByClientId(userContext.accessToken, clientId, EntityDepth.Shallow);
      if (clientSettings) {
        return clientSettings;
      }
      else {
        return new ClientSettingRS();
      }
    }
    catch (e) {
      snackbar.error(e as JsonException)
    }
  }

  /**
   * Determine if Add new user link needs to be active based on the client settings.
   * Additional logic to disable the link is handled outside of this function.
   * This function ONLY gets called when there's one client in the selector.
   * @param clientId 
   */
  async function EnableAddUser(clientId: number) {
    let clientSettings = await getClientSettings(clientId);

    // If no client settings were returned then definitely don't let people add users to that client.
    if (!clientSettings) {
      setLinkActive(false);
    }

    // Only enable link if this flag is true. Otherwise it should always be disabled.
    if (clientSettings?.isProfilesEnabled) {
      setLinkActive(true);
    }
    else {
      setLinkActive(false);
    }
  }

  async function userSearch(inputPageNumber: number) {
    /**
     * should initialize an array of userRQ
     */
    // Do not search if there are too many selected clients
    if (selectedClients.length <= Configuration.ClientSearchLimit.value) {
      const userRQ = new UserRQ();
      userRQ.clientIds = selectedClients.map(a => a.value);
      searchTerm.length > 1 ? userRQ.wildcard = searchTerm : userRQ.firstName = '';
      userRQ.activeSearchType = ActiveSearchType.Both;

      userRQ.skip = inputPageNumber * rowsPerPage;
      userRQ.limit = rowsPerPage;
      userRQ.searchType = UserSearchType.ByAll;
      userRQ.sortType = sortBy;
      userRQ.returnCount = ReturnCount.ReturnRecordsAndCount;
      userRQ.sortDirection = sortAscending ? SortDirection.Ascending : SortDirection.Descending;

      try {
        /**
         * set up a result array and push the array for different selected Clients to the return array.
         */
        const resultList = await UserController.Find(userContext.accessToken, userRQ);
        setSearchResults(resultList);
      } catch (err) {
        snackbar.error(err as JsonException);
      }
    }
  }

  function handleChangeSort(sortBy: UserSortType) {
    let newSortManager = { firstName: true, lastName: true, activeStatus: true };

    setSortBy(sortBy);

    switch (sortBy) {
      case UserSortType.ByFirstName:
        newSortManager.firstName = !sortManager.firstName;
        setSortAscending(sortManager.firstName);
        break;
      case UserSortType.ByLastName:
        newSortManager.lastName = !sortManager.lastName;
        setSortAscending(sortManager.lastName);
        break;
      case UserSortType.ByActiveStatus:
        newSortManager.activeStatus = !sortManager.activeStatus;
        setSortAscending(sortManager.activeStatus);
        break;
    }

    setSortManager(newSortManager);
  }


  return (
    <>
      <Box mt={1} mx={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3 className={classes.results}>
              Search by
            </h3>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="searchTerm"
              control={control}
              rules={formRules.searchTerm}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <TextField
                  id="search-term"
                  label="Search by"
                  value={value}
                  onBlur={(e) => {
                    formUtils.trimOnBlur(onBlur, onChange, e.target.value);
                    setSearchTerm(e.target.value.trim())
                  }}
                  onChange={event => { setSearchTerm(event.target.value); onChange(event); }}
                  margin="dense"
                  variant="outlined"
                  inputProps={{ minLength: 2 }}
                  placeholder={"Search by name or email"}
                  error={errors?.searchTerm ? true : false}
                  fullWidth
                  InputProps={{ startAdornment: <SearchOutlinedIcon /> }}
                />
              )}
            />
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              {errors.searchTerm && errors.searchTerm.message}
            </Typography>
          </Grid >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controller
              name="account"
              control={control}
              rules={formRules.account}
              render={({
                field: { onChange, value, ref, onBlur }
              }) => (
                <MultipleSelectChips
                  id="ff-accounts"
                  label="Account(s)"
                  options={clientOptions}
                  value={selectedClients}
                  onChange={(value) => { setSelectedClients(value); onChange(value) }}
                  errorMessage={''}
                  error={errors?.account ? true : false}
                />)}
            />
            <Typography
              variant="body2"
              color="error"
              className={classes.errorText}
            >
              {errors.account && errors.account.message}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Box mb={3}>
                <h3 className={classes.results}>
                  {searchResults.totalCount} Results
                </h3>
              </Box>
              <Box>
                <UserForm
                  linkActive={linkActive}
                  clientId={selectedClients[0]?.value}
                  parentName={selectedClients[0]?.label}
                />
              </Box>
            </Box>
          </Grid>
          <TableContainer style={{ marginTop: -10, }}>
            <Table
              className={classes.table}
              aria-label="User Search Table"
              size="small"
              id="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center"
                    onClick={() => { handleChangeSort(UserSortType.ByActiveStatus) }}
                  >Active Status <SortIcon sortAscending={!sortManager.activeStatus} /></TableCell>
                  <TableCell component="th" className={classes.headersWithIcon}
                    onClick={() => { handleChangeSort(UserSortType.ByFirstName) }}
                  >Legal first name <SortIcon sortAscending={!sortManager.firstName} /></TableCell>
                  <TableCell className={classes.headersWithIcon}
                    onClick={() => { handleChangeSort(UserSortType.ByLastName) }}
                  >Legal last name <SortIcon sortAscending={!sortManager.lastName} /></TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              {searchResults.totalCount > 0 ? <TableBody id="tableBody">
                {searchResults.list.map((e) => {
                  return (<UserSearchDataRow
                    firstName={e.firstName}
                    lastName={e.lastName}
                    email={e.emailAddress}
                    userId={e.userId}
                    user={e}
                  />)
                })}
              </TableBody>
                :
                <TableBody>
                  <TableRow>
                    <TableCell style={{ padding: 60, }} colSpan={12}><Typography variant='body2' align="center">No data available in this table</Typography></TableCell>
                  </TableRow>
                </TableBody>
              }
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    colSpan={12}
                    count={searchResults.totalCount}
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={tablePagination}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid >
      </Box >
    </>
  );
  /**
   * Component for table pagination actions.
   * 
   * @returns the footer that handles the table Pagination buttons.
   */
  function tablePagination() {
    let count = searchResults.totalCount;
    let upperRange = Math.ceil(count / rowsPerPage)

    return (
      <Box className={classes.pagination}>
        <IconButton
          onClick={() => { userSearch(0); setPageNumber(0); }}
          disabled={pageNumber === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={() => { userSearch(pageNumber - 1); setPageNumber(pageNumber - 1); }}
          disabled={pageNumber === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={() => { userSearch(pageNumber + 1); setPageNumber(pageNumber + 1); }}
          disabled={pageNumber >= upperRange - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={() => { userSearch(Math.max(0, upperRange - 1)); setPageNumber(Math.max(0, upperRange - 1)); }}
          disabled={pageNumber >= upperRange - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box >
    )
  }
}
import React, { Dispatch, SetStateAction, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Delete as CancelIcon } from "@material-ui/icons";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        textAlign: "center",
        width: 400,
      },
      "& .MuiDialogTitle-root": {
        padding: "16px 24px 0",
      },
      "& .MuiDialogContent-root": {
        padding: "0 24px",
      },
      "& .MuiTypography-body1": {
        lineHeight: 1.6,
        fontSize: "13px",
        color: "#4D4D4D",
      },
    },
    iconCancel: {
      margin: "8px auto 14px",
      background: "#bc2c2f",
      borderRadius: 40,
      width: "44px",
      height: "44px",
      "& .MuiSvgIcon-root": {
        color: "#fff",
        marginTop: "10px",
        fontSize: "1.5rem",
      },
    },
    dialogActions: {
      padding: "16px 24px",
      "& .MuiButton-contained.btnDeny": {
        backgroundColor: "#bc2c2f",
      },
    },
    btnCancel: {
      color: "#00467E",
      marginLeft: "0 !important",
      fontSize: "13px",
    },
  })
);

interface CancelDialogProps {
  openActionDialog: boolean;
  setOpenActionDialog: Dispatch<SetStateAction<boolean>>;
  onConfirm: (comment: string) => void;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CancelDialog(props: CancelDialogProps) {
  const classes = useStyles();
  const {
    openActionDialog,
    setOpenActionDialog,
    onConfirm,
    setShowSpinner
  } = props;
  const [commentValue, setCommentValue] = useState<string>("")

  /**
   * Handles the confirm action in the cancel dialog.
   */
  function handleConfirm() {
    onConfirm(commentValue);
    setShowSpinner(true)
    setOpenActionDialog(false);
  }

  return (
    <React.Fragment>
      <Dialog
        id="dialog-cancel"
        open={openActionDialog}
        onClose={() => {
          setOpenActionDialog(false);
        }}
        aria-labelledby="Cancel action dialog"
        aria-describedby="Cancel action dialog"
        className={classes.dialog}
      >
        <DialogTitle id="dialog-title">
          <Box>
            <div className={classes.iconCancel}>
              <CancelIcon />
            </div>
          </Box>
          Cancel request?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-text">
            <Typography variant="body1">
              <p>
                A cancelation email will be sent out to all approvers that have already been notified of your request.
              </p>
              <Box mt={4}>
                <TextField
                  id="ff-comments"
                  label="Comments (optional)"
                  multiline
                  fullWidth
                  rows={2}
                  variant="outlined"
                  inputProps={{ maxLength: 500 }}
                  onChange={(event) => setCommentValue(event.target.value)}
                  value={commentValue}
                />
              </Box>
            </Typography>
          </DialogContentText>
        </DialogContent>

        <div className={classes.dialogActions}>
          <Button
            id="btn-cancel"
            onClick={handleConfirm}
            variant="contained"
            className="btnDeny"
            fullWidth
          >
            Cancel request
          </Button>
          <Button
            className={classes.btnCancel}
            onClick={() => {
              setOpenActionDialog(false);
            }}
            fullWidth
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00467E", // dark blue
    },
    secondary: {
      main: "#00a6cf", // light blue
    },
    error: {
      main: "#bc2c2f", // red
      light: "#F8EAEA", // light red for muted backgrounds on buttons
    },
    success: {
      main: "#417505", // green
      light: "#ECF1E6", // light green for muted backgrounds on buttons
    },
    warning: {
      main: "#fea501", // yellow
      dark: "#ff8401", // orange
    },
    grey: {
      50: "#f9f9f9", // pale gray  [ card background color ]
      100: "#c9c7c7", // light gray
      300: "#808080", // medium gray [ labels and input field color/background ]
      500: "#4D4D4D", // dark gray
    },
    text: {
      primary: "#4D4D4D", // dark gray
      secondary: "#4D4D4D", // light gray
    },
  },

  typography: {
    fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),

    h1: {
      fontSize: "30px",
      fontWeight: 300,
      lineHeight: 2,
      color: "textPrimary",
    },
    h2: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: 2,
      color: "textPrimary",
    },
    h3: {
      fontSize: ".8rem",
      fontWeight: 600,
      lineHeight: 2,
      color: "textPrimary",
    },
    body2: {
      fontSize: ".8rem",
      fontWeight: 400,
      lineHeight: 1,
      // color: "#000",
    },
    caption: {
      fontSize: ".9rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
  },

  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiTextField: {
      InputLabelProps: {
        shrink: true,
        disableAnimation: true,
      },
    },
  },

  // Only used to override EVERY single instance of this UI element - use with caution because it will change it globally
  overrides: {
    MuiAccordion: {
      root: {
        // square: true,
        "&$expanded": {
          margin: 0, // sets the accordion so there's no padding in between
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
    MuiAccordionSummary: {
      root: {
        height: "48px",
      },
    },
    // MuiAutocomplete: {
    //   option: {
    //     display: "block", // This is for the dropdown that displays at least two lines of text
    //   },
    // },
    MuiButton: {
      root: {
        textTransform: "initial",
        paddingBottom: 8,
      },
    },
    MuiChip: {
      labelSmall: {
        fontSize: ".75rem",
        paddingBottom: "2px",
        lineHeight: 1.5,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "#1F2532",
        color: "#fff",
      },
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          fontSize: ".8rem",
          paddingBottom: "4px",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#808080",
      },
    },

    // Fixes the defaulted uneven margin that MUI's grid has
    MuiGrid: {
      container: {
        margin: "-12px 0 0 -12px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: ".9rem",
        fontWeight: 600,
        color: "#4D4D4D",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "#00467E",
        },
      },
      root: {
        color: "#808080",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "none",
        }
      },
      selectMenu: {
        height: 'initial',
        paddingTop: 9,
        paddingBottom: 10,
      },
    },
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#808080",
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#417505",
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.4,
        backgroundColor: "#808080",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.5,
          backgroundColor: "#417505",
        }
      }
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.8rem',
        backgroundColor: '#1F2532',
      },
      arrow: {
        "&::before": {
          backgroundColor: "#1F2532",
        }
      }
    },
    MuiTypography: {
      body1: {
        color: "#4D4D4D",
      },
      body2: {
        color: "#4D4D4D",
      },
    },
  },
});

export default theme; 
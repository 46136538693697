import { Box } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { MouseEventHandler } from "react";
import { useHistory } from 'react-router-dom';

interface TopBannerProps {

    //Message could be "Currently editing" or "Creating"... 
    message: string;

    //User's first and last name
    firstAndLastName: string;
}

const useStyles = makeStyles(() => ({
    topBanner: {
        color: "#ffffff",
        textAlign: 'center',
        backgroundColor: "#00467F",
        padding: 6,
        display: 'block',
        marginLeft: -32,
        marginRight: -32,
    },
    topBannerCreating: {
        color: "#ffffff",
        textAlign: 'center',
        backgroundColor: "#00467F",
        padding: 6,
        display: 'block',
        marginLeft: 0,
        marginRight: 0,
    },
    TopRight: {
        float: 'right',
        paddingTop: 8,
        cursor: 'pointer',
    },

}));


// This TopBanner sits at the top of the page's main content. 
// It is used to alert the user that they are currently editing another user's profile or creating a new profile.

export default function TopBanner(props: TopBannerProps) {
    const classes = useStyles();
    const { firstAndLastName, message } = props;
    const routerHistory = useHistory();
    let creatingScreen = false;
    if (message.toLowerCase() === 'creating') creatingScreen = true;

    return (
        <Box className={creatingScreen ? classes.topBannerCreating : classes.topBanner}>
            <Box className={classes.TopRight}> <CloseIcon onClick={() => { routerHistory.goBack(); }} /></Box>
            {/* Message example: Currently editing Daphne Blake's profile  */}
            {/* Message example: Creating Velma Dinkley's profile  */}
            <Box><p>{message} <strong>{firstAndLastName}'s</strong> profile</p> </Box>
        </Box>

    );
}

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Box, Typography } from "@material-ui/core";
import FaceMenu from './FaceMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      "& .MuiPaper-elevation4": {
        boxShadow: "none",
      },
    },
    title: {
      flexGrow: 1,
      paddingTop: "2px",
    },
  })
);

/**
 * ButtonAppBar component properties.
 */
interface ButtonAppBarProps {
  /** Text to display as a page title. */
  pageTitle: string
}

/**
 * Component to display the page title and the `FaceMenu` component.
 * 
 * @param props {@link ButtonAppBarProps Properties} for the `ButtonAppBar` component.
 * @returns A JSX element.
 */
export default function ButtonAppBar(props: ButtonAppBarProps) {
  const classes = useStyles();

  return (
    <div className={classes.appbar}>
      <AppBar position="static" color="inherit">
        <Box display="flex" color="textPrimary">
          <Box flexGrow={1} className={classes.title} color="textPrimary">
            <Typography
              id={`h1-${props.pageTitle}`}
              variant="h1"
              color="textPrimary"
              className={classes.title}
            >
              {props.pageTitle}
            </Typography>
          </Box>
          <Box p={1}>
            <FaceMenu />
          </Box>
        </Box>
      </AppBar>
    </div>
  );
}
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";
import { CustomFieldAccessibility } from "@cbtravel/common/lib/shared/common/enumerations/custom-field-accessibility";
import { DataEntryFlow } from "@cbtravel/common/lib/shared/common/enumerations/data-entry-flow";

export class customFieldUtil {
    /**
     * NOTE: The return paths are based on an excel sheet provided from the Jira ticket CD-7008.
     * If this code is confusing, seek out that data for a more granular view of the various permissions.
     * @param permission The accessibility/permission of the custom field.
     * @param userType  User type of the user attempting to edit the custom field.
     * @param flow Data entry flow we are currently in.
     * @returns a boolean that determines if the current user can edit the custom field.
     */
    static canDataEntryEdit(permission: CustomFieldAccessibility, userType: UserType, flow: DataEntryFlow): boolean {
        // Anyone can edit profile/trip custom fields with an open permission, and admins can edit anything.
        if (userType === UserType.Administrator || permission === CustomFieldAccessibility.Open) {
            return true;
        }

        // Handle all of internal permission.
        // (Excluding admin) only AM's and SM's can edit internal custom fields in new user creation. Otherwise all other users can't edit internal custom fields.
        if (permission === CustomFieldAccessibility.Internal && flow === DataEntryFlow.NewUserCreation && (userType === UserType.AccountManager || userType === UserType.SalesManager)) {
            return true;
        }
        // Every other case (flow and user type combination) can't edit internal custom fields.
        else if (permission === CustomFieldAccessibility.Internal) {
            return false;
        }

        // The only other permissions we have left to handle are Permissioned and hidden.
        // Advisors and Traveler's can never edit custom fields that aren't open.
        if (userType === UserType.Advisor || userType === UserType.Traveler) {
            return false;
        }

        // AM's, TM's, and SM's can edit permissioned custom fields for all flows.
        if (permission === CustomFieldAccessibility.Permissioned) {
            return true;
        }
        // BUT only TM's can edit hidden custom fields for all flows.
        else if (permission === CustomFieldAccessibility.Hidden && userType === UserType.TravelManager) {
            return true;
        }
        // Every other user type at this point can edit custom fields in new user creation.
        else if (flow === DataEntryFlow.NewUserCreation) {
            return true;
        }

        // If a case gets missed, do not let that user edit any custom field.
        return false;
    }

    /**
     * NOTE: The return paths are based on an excel sheet provided from the Jira ticket CD-7008.
     * If this code is confusing, seek out that data for a more granular view of the various permissions.
     * @param permission The accessibility/permission of the custom field.
     * @param userType User type of the user attempting to view the custom field.
     * @returns a boolean that determines if the current user can view the custom field.
     */
    static canDataEntryView(permission: CustomFieldAccessibility, userType: UserType): boolean {
        // There are only 3 cases where we want to hide custom fields from the user:
        // Travelers for hidden and internal permission
        // Travel managers for internal permission.

        if (userType === UserType.Traveler && (permission === CustomFieldAccessibility.Hidden || permission === CustomFieldAccessibility.Internal)) {
            return false;
        }
        else if (userType === UserType.TravelManager && permission === CustomFieldAccessibility.Internal) {
            return false;
        }
        else {
            return true;
        }
    }

    /**
     * NOTE: The return paths are based on an excel sheet provided from the Jira ticket CD-7008.
     * If this code is confusing, seek out that data for a more granular view of the various permissions.
     * @param permission The accessibility/permission of the custom field.
     * @param userType User type of the user attempting to edit another user's custom field.
     * @param flow We only need this parameter to check for the company info tab.
     * @returns a boolean that determines if the current user can edit the custom field.
     */
    static canEditForOthers(permission: CustomFieldAccessibility, userType: UserType, flow: DataEntryFlow): boolean {
        // Admins can edit anything and travel managers can edit other people's open custom fields ONLY on the company info tab.
        if (userType === UserType.Administrator || ((userType === UserType.TravelManager || userType === UserType.AccountManager) &&
            (permission === CustomFieldAccessibility.Open || permission === CustomFieldAccessibility.Permissioned) &&
            flow === DataEntryFlow.CompanyInfoTab)) {
            return true;
        }
        // No one else can edit other users' custom fields.
        else {
            return false;
        }
    }

    /**
     * NOTE: The return paths are based on an excel sheet provided from the Jira ticket CD-7008.
     * If this code is confusing, seek out that data for a more granular view of the various permissions.
     * @param permission The accessibility/permission of the custom field.
     * @param userType User type of the user attempting to view another user's custom field.
     * @returns a boolean that determines if the current user can view the custom field.
     */
    static canViewForOthers(permission: CustomFieldAccessibility, userType: UserType, flow: DataEntryFlow): boolean {
        // Admins can see everything
        if (userType === UserType.Administrator) {
            return true;
        }
        // Handle all the user types that should never bbe able to view other users' custom fields.
        else if (userType === UserType.Traveler || userType === UserType.Advisor || permission === CustomFieldAccessibility.Hidden || permission === CustomFieldAccessibility.Internal) {
            return false;
        }

        // The only remaining user types to handle are AM's, SM's, and TM's.
        // Travel managers can view other company info tabs but not other users' required fields, which is why this case is important.
        if ((permission === CustomFieldAccessibility.Open || permission === CustomFieldAccessibility.Permissioned) && userType !== UserType.TravelManager) {
            return true;
        }
        // Handle travel manager specifically since can only view for company info tab, not required fields.
        else if ((permission === CustomFieldAccessibility.Open || permission === CustomFieldAccessibility.Permissioned) && userType === UserType.TravelManager && flow === DataEntryFlow.CompanyInfoTab) {
            return true;
        }
        // In case an unhandled case slips through the cracks, don't let the user view the custom field.
        else {
            return false;
        }

    }
}
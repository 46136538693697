import {
  Box, Button, Checkbox, Divider, TextField, FormControlLabel, FormGroup, Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import React, { useContext, useEffect, useRef, useState } from "react";

import { ApprovalRequestController } from '@cbtravel/common/lib/shared/api/approval/controllers/approval-request-controller';
import { ApprovalStatus } from '@cbtravel/common/lib/shared/common/enumerations/approval-status';
import { ApprovalType } from "@cbtravel/common/lib/shared/common/enumerations/approval-type";
import { EntityDepth } from '@cbtravel/common/lib/shared/common/enumerations/entity-depth';
import { ReturnCount } from "@cbtravel/common/lib/shared/common/enumerations/return-count";
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";
import { PagedList } from '@cbtravel/common/lib/shared/common/paged-list';
import { Configuration } from '@cbtravel/common/lib/shared/config/client-config';
import { ApprovalRequestRQ } from '@cbtravel/common/lib/shared/messages/approval/requests/approval-request-rq';
import { ApprovalRequestRS } from '@cbtravel/common/lib/shared/messages/approval/responses/approval-request-rs';
import { UserLT } from "@cbtravel/common/lib/shared/messages/general/responses/lite/user-lt";
import { UserRS } from "@cbtravel/common/lib/shared/messages/general/responses/user-rs";
import Spinner from '../../../components/shared/Spinner';
import { UserContext } from '../../../components/shared/UserContext';
import UserSearchBox from "../../../components/shared/UserSearchBox";
import MultipleSelectChips, {
  Option
} from "../../../components/ui/Input/MultipleSelectChips";

import { ApprovalRequestSortType } from "@cbtravel/common/lib/shared/common/enumerations/sort-types";
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";
import { ApprovalRequestApprovalRS } from "@cbtravel/common/lib/shared/messages/approval/responses/approval-request-approval-rs";
import { ClientRS } from "@cbtravel/common/lib/shared/messages/general/responses/client-rs";
import { Controller, useForm } from "react-hook-form";
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';
import { formUtils } from '../../../util/form-utils';
import DataRow from './DataRow';
import FloatingButton from "./FloatingButton";
import { ActiveSearchType } from "@cbtravel/common/lib/shared/common/enumerations/active-search-type";

interface EnhancedTableHeadProps {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected: number;
  selectableRowsCount: number;
  showCheckboxes: boolean;
  actionName: string;
}

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { onSelectAllClick, numSelected, selectableRowsCount, showCheckboxes, actionName } = props;
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.actionCell}>
          {showCheckboxes && (
            <Checkbox
              indeterminate={
                numSelected > 0 && numSelected < selectableRowsCount
              }
              checked={
                selectableRowsCount > 0 && numSelected === selectableRowsCount
              }
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all approval requests" }}
              size="small"
            />
          )}
          {/* This toggles the action name either Approve or Deny  */}
          {actionName}
        </TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Date Created</TableCell>
        <TableCell>Requester Name</TableCell>
        <TableCell>Traveler</TableCell>
        <TableCell>Client Name</TableCell>
        <TableCell>Details</TableCell>
        <TableCell>Approval Type</TableCell>
        <TableCell align="center">History</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}


interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& .MuiChip-root": {
      margin: theme.spacing(0.5),
      border: "1px solid #00467E",
    },
    "& div.MuiFormGroup-row": {
      display: "inline-block",
    },
  },
  flexGrow: {
    flexGrow: 1,
    "& .MuiFormControlLabel-label.MuiTypography-body1": {
      fontSize: ".9rem",
      lineHeight: 1,
      marginTop: "-2px",
    },
  },
  results: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    marginBottom: 0,
  },
  switchHeader: {
    fontWeight: 600,
    paddingRight: theme.spacing(2),
    display: "inline",
  },
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 500,
    width: "100%",
    th: {
      fontWeight: 700,
    },
    "& a": {
      color: "#00467E",
      textDecoration: "none",
    },
    "& .MuiTableCell-head": {
      fontWeight: 700,
    },
    "& .MuiTableCell-head.MuiTableCell-sizeSmall": {
      padding: "0 8px 8px 8px",
    },
    // Background color for when accordion gets collapsed
    "& .MuiTableRow-root.moreInfoRow": {
      background: "#f9f9f9",
    },
    "& .moreInfoCell": {
      borderBottom: "none",
      padding: 0,
    },
    "& .tableHeader": {
      fontWeight: 700,
    },
    // This is the table cell padding for first data row
    "& .MuiTableCell-sizeSmall": {
      padding: "0 16px 0 8px",
      marginTop: "2px",
      lineHeight: 1.4,
    },
    "& .status.MuiTableCell-sizeSmall": {
      fontWeight: 600,
      textTransform: "uppercase",
    },
    "& .pending.MuiTableCell-sizeSmall": {
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#ff8401",
    },
    "& .approved.MuiTableCell-sizeSmall": {
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#417505",
    },
    "& .denied.MuiTableCell-sizeSmall": {
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#bc2c2f",
    },

    "& .approve.MuiLink-button.MuiTypography-colorPrimary": {
      color: "#417505",
      textDecoration: "underline",
    },
    "& .deny.MuiLink-button.MuiTypography-colorPrimary": {
      color: "#bc2c2f",
      textDecoration: "underline",
    },
    "& .moreInfoTable .MuiTableCell-sizeSmall": {
      padding: "8px",
    },
  },
  moreInfoRow: {
    "& .moreInfoCell": {
      borderBottom: "none",
      padding: "8px",
    },
  },
  actionCell: {
    whiteSpace: "nowrap",
    "& .PrivateSwitchBase-root-34": {
      padding: "0 4px 0 0",
      marginTop: "-7px",
      marginBottom: "-3px",
    },
  },
  errorText: {
    paddingTop: '5px',
  },
}));
type approvalRequestFromInputs = {
  accountOptions: Array<Option>,
  statusOptions: Array<Option>,
  approvalTypeOptions: Array<Option>,
  searchByName: string

}

export default function Approvals(props: { activeClient?: ClientRS, }) {
  const classes = useStyles();
  const theme = useTheme();
  const { userContext } = useContext(UserContext);
  const snackbar = useCustomSnackbar();
  // create the options for the select account and status inputs
  const clientOptions: Option[] = userContext.viewableClients.map((c) => {
    return {
      label: c.name + (c.accountNumber ? " (" + c.accountNumber + ")" : ""),
      value: c.clientId,
    };
  });
  const statuses: Option[] = Object.values(ApprovalStatus).map((val, i) => {
    return { label: val, value: i };
  });
  const approvalTypes: Option[] = Object.values(ApprovalType).map((val, i) => {
    return { label: val, value: i };
  });
  const [bulkSwitchStates, setBulkSwitchStates] = useState<{ approve: boolean, deny: boolean }>({
    approve: false,
    deny: false
  })


  const [actionName, setActionName] = useState<string>("Actions");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [showSpinner, setShowSpinner] = useState<boolean>(true); // renders the spinner
  const [currentUser, setCurrentUser] = useState<UserRS | UserLT | null>(new UserLT());
  const [approvalRequestList, setApprovalRequestList] = useState<PagedList<ApprovalRequestRS>>(new PagedList<ApprovalRequestRS>()); // list of approvals from server
  const [selectedRequestIds, setSelectedRequestIds] = useState<Set<number>>(new Set<number>()); // a set of which requests/datarows have their checkboxes checked when bulk actions are on
  const [sendBulkRequest, setSendBulkRequest] = useState<boolean>(false); // a flag sent to DataRow.tsx to trigger a status change for each individual ApprovalRequest in a bulk action
  const [bulkComment, setBulkComment] = useState<string>("") // the comment value for a bulk request
  const [selectRequestId, setSelectRequestId] = useState<number>(-1);
  const [searchByName, setSearchByName] = useState<string>("");
  const bulkSuccess = useRef<number>(0); // the number of successful/failed status changes from a bulk request
  const bulkFailure = useRef<number>(0); // these need to be refs and not state because we use them immediately after updating their values.
  const pageNumber = useRef<number>(0); // track the value of page number
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    getValues
  } = useForm<approvalRequestFromInputs>({
    mode: "onBlur",
    defaultValues: {
      accountOptions: new Array<Option>(),
    }
  });
  const formRules = {

    accountOptions: {
      validate: {
        required: (value: Option[]) => {
          if (value.length < 1) return "Select at least one account";
          if (value.length > Configuration.ClientSearchLimit.value) return "You may only include " + Configuration.ClientSearchLimit.name + " items. Please reduce your selection.";
        }
      }
    }
  }
  useEffect(() => {
    if (!props.activeClient) return; // ensure we have an active client before making the api call
    const search = window.location.search;
    if (search.includes("request=")) {
      let requestId = "";
      const start = search.indexOf("request=") + 8;
      const end = search.indexOf("&", start);
      end != -1 ? requestId = search.substring(start, end) : requestId = search.substring(start);
      getSpecificRequest(Number(requestId));
    } else {
      getApprovalRequests(pageNumber.current, rowsPerPage, props.activeClient.clientId);
    }
    setValue("accountOptions", [{ label: props.activeClient.name, value: props.activeClient.clientId }]);
  }, [props.activeClient]);

  useEffect(() => {
    if (!currentUser || currentUser.userId > 0) {
      //set the page number to first page when initialize a search
      pageNumber.current = 0;
      //don't search until we know the user's done typing (1.5 seconds after they stop)
      const timeOut = setTimeout(function () {
        getApprovalRequests(0)
      }, Configuration.SearchWaitConfigTime)
      return () => clearTimeout(timeOut)
    }
  }, [currentUser]);

  useEffect(() => {
    //set the page number to 0 when we initialize a search
    pageNumber.current = 0
    const search = window.location.search;
    if (searchByName.length > 1) {
      //don't search until we know the user's done typing (1.5 seconds after they stop)
      const timeOut = setTimeout(function () {
        getApprovalRequests(0)
      }, Configuration.SearchWaitConfigTime)
      return () => clearTimeout(timeOut)
    }
    //if user delete the searchByName input and it's not coming from email,initialize the search without searchByName
    else if (!search.includes("request=") && searchByName.length != 1) {
      getApprovalRequests(0);
    }
  }, [searchByName]);
  useEffect(() => {
    if (selectRequestId !== -1) {
      handleSelectToggle(selectRequestId);
      setSelectRequestId(-1);
    }

  }, [selectRequestId]);
  /**
   * Component used for controlling the pagination actions.
   *
   * @param paginationActions comes from the TablePagination component with info about the current states of the table.
   * @returns the footer that handles the table Pagination buttons.
   */
  function tablePagination(paginationActions: TablePaginationActionsProps) {
    const { count, page, rowsPerPage, onChangePage } = paginationActions;
    let upperRange = Math.ceil(count / rowsPerPage)

    /**
     * Changes the page to the first page of pagination.
     * 
     * @param event Mouse event that triggered this function call.
     */
    function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      onChangePage(event, pageNumber.current = 0);
    }

    /**
     * Changes the page to the one before the current page.
     * 
     * @param event Mouse event that triggered this function call.
     */
    function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      onChangePage(event, pageNumber.current--);
    }

    /**
     * Changes the page to the one after the current page.
     * 
     * @param event Mouse event that triggered this function call.
     */
    function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      onChangePage(event, pageNumber.current++);
    }

    /**
     * Changes the page to that last page of pagination.
     * 
     * @param event The mouse event that triggered this function call.
     */
    function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
      onChangePage(event, pageNumber.current = Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
      <Box className={classes.pagination}>

        <IconButton
          id="btn-first-page"
          onClick={handleFirstPageButtonClick}
          disabled={pageNumber.current === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          id="btn-previous-page"
          onClick={handleBackButtonClick}
          disabled={pageNumber.current === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          id="btn-next-page"
          onClick={handleNextButtonClick}
          disabled={pageNumber.current >= upperRange - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          id="btn-last-page"
          onClick={handleLastPageButtonClick}
          disabled={pageNumber.current >= upperRange - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    )
  }

  /**
   * builds an ApprovalRequestRs object and makes a call to the services to get a list of Approval Requests based on the parameters in state.
   *
   * Builds an ApprovalRequestRs object and makes a call to the services to get a list of Approval Requests based on the parameters in state.
   * @param page - the current page number of the data table
   * @param limit - the current rowsPerPage of the data table 
   * @param clientIdOnMount - the active client's id on mount, used if the accountOptionsValue is not populated when this function is called
   */
  async function getApprovalRequests(page: number = pageNumber.current, limit: number = rowsPerPage, clientIdOnMount: number = -1) {

    //check for errors in the form before proceeding (we do not currently validate the status options field)
    if (errors?.accountOptions) {
      return;
    }

    setShowSpinner(true);

    const token = userContext.accessToken;
    let formInputs: approvalRequestFromInputs = getValues();
    const request = new ApprovalRequestRQ();
    // travel managers can view all requests from their client hierarchy.
    // Admin,Account Manager,Sales Manager, Travel Advisor can view all clients approvals
    if (userContext.userType === UserType.Traveler) {
      request.userId = Number(userContext.userId);
    }

    // check which status chips are selected and include them in our query
    formInputs.statusOptions?.map((val) => {
      if (val.label === ApprovalStatus.Pending) {
        request.approvalStatusList.push(ApprovalStatus.Pending);
      }
      if (val.label === ApprovalStatus.Approved) {
        request.approvalStatusList.push(ApprovalStatus.Approved);
      }
      if (val.label === ApprovalStatus.Denied) {
        request.approvalStatusList.push(ApprovalStatus.Denied);
      }
    });
    formInputs.approvalTypeOptions?.map((val) => {
      if (val.label === ApprovalType.PostBookApproval) {
        request.approvalTypeList.push(ApprovalType.PostBookApproval);
      }
      // if (val.label === ApprovalType.PostTicketingApproval) {
      //   request.approvalTypeList.push(ApprovalType.PostTicketingApproval);
      // }
      // if (val.label === ApprovalType.PreTripApproval) {
      //   request.approvalTypeList.push(ApprovalType.PreTripApproval);
      // }
      if (val.label === ApprovalType.PreTripAuthorization) {
        request.approvalTypeList.push(ApprovalType.PreTripAuthorization);
      }
      if (val.label === ApprovalType.UserEnrollment) {
        request.approvalTypeList.push(ApprovalType.UserEnrollment);
      }
    });

    //check if that is default user
    if (currentUser && currentUser.userId > 0) {
      request.requestorId = currentUser.userId;
    }
    //check if user input the primary passenger name
    searchByName.length > 1 ? request.primaryTravelerName = searchByName : request.primaryTravelerName = "";

    if (formInputs.accountOptions.length > 0) {
      formInputs.accountOptions.map((val) => {
        request.clientList.push(val.value)
      })
    }
    // if we don't have the account options populated on mount, then we will set the request
    // client list to whatever the activeClient id on mount is
    if (clientIdOnMount != -1) {
      request.clientList = [clientIdOnMount];
    }

    // Travelers should get all clients asociated with them
    if (userContext.userType === UserType.Traveler) {
      request.clientList = [];
    }

    request.sortType = ApprovalRequestSortType.ByClientName;
    request.returnCount = ReturnCount.ReturnRecordsAndCount;
    // request.limit = rowsPerPage;
    request.limit = limit;
    // request.skip = pageNumber * rowsPerPage;
    request.skip = page * limit;
    try {
      const approvalList: PagedList<ApprovalRequestRS> = await ApprovalRequestController.Find(
        token,
        request,
        EntityDepth.Deep
      );
      setApprovalRequestList(approvalList);

    } catch (err) {
      snackbar.error(err as JsonException);
    } finally {
      setShowSpinner(false);
    }
  }
  /**
   * Function for getting an approval request with a specific ID.
   * 
   * @param requestId The ID number of the request.
   */
  async function getSpecificRequest(requestId: number) {
    setShowSpinner(true);
    const token = userContext.accessToken;

    try {

      const approvalList = new PagedList<ApprovalRequestRS>();
      const theRequest: ApprovalRequestRS = await ApprovalRequestController.Get(
        token,
        requestId,
        EntityDepth.Deep
      );
      approvalList.list.push(theRequest);
      approvalList.totalCount = 1;

      setApprovalRequestList(approvalList);
    } catch (err) {
      snackbar.error(err as JsonException);
    } finally {
      setShowSpinner(false);
    }
  }

  /**
   * Handles changing the filter for the user that made the approval request.
   * 
   * @param newUser 
   */
  function handleUserChange(newUser: UserLT | UserRS | null) {
    if (!newUser || !currentUser)
      setCurrentUser(newUser)
    else if (currentUser.userId !== newUser.userId) {
      setCurrentUser(newUser)
    }
  }

  /**
   * determines whether to show Approve/Deny buttons for a given Request based on ApprovalRequestRS status, if the Logged In User is an approver, and the status of the LIU's approval
   * @param currentRequest an ApprovalRequestRS associated with a particular DataRow
   */
  function checkShowButtons(currentRequest: ApprovalRequestRS) {
    const levelMap: { [key: number]: Array<ApprovalRequestApprovalRS>; } = getLevelMap(currentRequest);
    let thisApprover = getLoggedInUserApproval(currentRequest);
    let alreadyApproved;
    let currentLevel = 1;
    while (levelMap[currentLevel]) {
      if (thisApprover && thisApprover.approvalGroupType === "Any") {
        //they're an approver, check if this request has already been approved by someone in their group
        alreadyApproved = levelMap[currentLevel].find(x => x.approvalStatus === 'Approved' && x.approvalGroupId === thisApprover?.approvalGroupId)
      }
      if (currentRequest.approvalStatus.toLowerCase() === "pending" && thisApprover && !alreadyApproved) {
        return true;
      }
      currentLevel++;
    }
    return false;

  }

  /**
   * Returns a map of the levels on an approval request.
   * 
   * @param currentRequest The approval request to create a level map of.
   * @returns A mapping of the level number to list of approvers for that level.
   */
  function getLevelMap(currentRequest: ApprovalRequestRS) {
    let levelMap: { [key: number]: Array<ApprovalRequestApprovalRS>; } = {};
    currentRequest.approvalRequestApprovalList.forEach(e => {
      if (levelMap[e.level]) {
        levelMap[e.level].push(e);
      } else {
        let levelList = new Array<ApprovalRequestApprovalRS>();
        levelList.push(e);
        levelMap[e.level] = levelList;
      }
    });
    return levelMap;
  }

  /**
   * Updates a running tally of how many requests in a bulk action succeeded or failed. This method is called in DataRow after attempting to update request status
   * @param didSucceed a boolean-- true if the request's status change succeeded
   */
  function tallyBulkResults(didSucceed: boolean, requestId: number) {
    if (didSucceed) {
      bulkSuccess.current++;
      setSelectRequestId(requestId);
    } else {
      bulkFailure.current++
    }

    if (selectedRequestIds.size === bulkSuccess.current + bulkFailure.current) {
      //clear everything if we had no failures
      if (bulkFailure.current === 0) {
        setActionName("Actions");
        setBulkSwitchStates({ approve: false, deny: false })
        setSelectedRequestIds(new Set<number>())
      }
      showTimedInfoSnackbar(`${bulkSuccess.current} requests completed. ${bulkFailure.current} requests failed to send.`)
      setSendBulkRequest(false)
      getApprovalRequests();
    }
  }

  /**
   * sets the message for and triggers a 6-second long informational snackbar message
   * 
   * @param message The message to show in the snackbar.
   */
  function showTimedInfoSnackbar(message: string) {
    snackbar.info(message);
    bulkSuccess.current = 0;
    bulkFailure.current = 0;
  }

  /**
   * checks whether or not the logged in User is an active Approver, and if their Approval is still pending
   * @param request the ApprovalRequestRS to check
   * @returns the ApprovalRequestApproval object that corresponds to the Logged in User for the given request.
   */
  function getLoggedInUserApproval(request: ApprovalRequestRS) {

    const levelMap: { [key: number]: Array<ApprovalRequestApprovalRS>; } = getLevelMap(request);
    let currentLevel = 1;
    while (levelMap[currentLevel]) {
      let levelApproved = levelMap[currentLevel].some(function (e) {
        return e.approvalStatus === "Approved" && e.approvalGroupType === "Any";
      });

      if (!levelApproved) {
        let thisApprover = levelMap[currentLevel].find(x => x.approvalStatus === "Pending" && x.user.userId === Number(userContext.userId) && x.isSent);
        if (thisApprover)
          return thisApprover;
      }

      currentLevel++;
    }
    return undefined;
  }

  /**
   * Toggles selection of every row of the table
   * @param event from clicking the 'select all toggle'.
   */
  function handleSelectAllToggle(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      // only can select approval requests that are Pending
      const toggleableRequestIds = approvalRequestList.list.reduce<number[]>(
        (accumulator, request) => {
          if (checkShowButtons(request)) {
            return [...accumulator, request.approvalRequestId];
          }

          return accumulator;
        },
        []
      );

      const newSelectedRequests = new Set<number>(toggleableRequestIds);
      setSelectedRequestIds(newSelectedRequests);
    } else {
      setSelectedRequestIds(new Set<number>());
    }
  }

  /**
   * a handler function that sets the necessary state objects to send as props to all of the child components affected by a bulk action
   * @param wasApproved boolean- true = approved, false = denied. this isn't currently used here, but it mirrors the signature of the function single approvals call directly and therefore cuts down on typeErrors
   * @param comment the string entered into the comment section of the action dialog, to be included in the changeStatusRQ
   */
  function handleBulkAction(wasApproved: boolean, comment: string = "") {
    setBulkComment(comment)
    setSendBulkRequest(true)
  }

  /**
   * Toggles selection of an individual row of the table
   * @param requestId
   */
  function handleSelectToggle(requestId: number) {
    let newSelectedRequestIds = new Set<number>(selectedRequestIds)

    // if already selected then remove from selected values, otherwise add it
    if (selectedRequestIds.has(requestId)) {
      newSelectedRequestIds.delete(requestId);
    } else {
      newSelectedRequestIds.add(requestId);
    }
    setSelectedRequestIds(newSelectedRequestIds);
  }

  /**
   * sets approve and deny switches to their appropriate states when one of them is clicked; ensures only one can be "on" at a time
   * @param event the click event that calls this funciton
   */
  function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      if (event.target.name === "approve") {
        setBulkSwitchStates({ approve: !bulkSwitchStates.approve, deny: false })
        setActionName("Approve");
      } else {
        setBulkSwitchStates({ deny: !bulkSwitchStates.deny, approve: false })
        setActionName("Deny");
      }
    } else {
      setBulkSwitchStates({ approve: false, deny: false })
      setActionName("Actions");
      setSelectedRequestIds(new Set<number>())
    }
  }

  /**
   *  Handles changes in pagination
   * @param event The button to change data table pages was clicked
   * @param newPage the new page number to go to.
   */
  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {

    getApprovalRequests(pageNumber.current, rowsPerPage);
  }

  /**
   * Handles chages to the number of rows shown  on a page.
   *
   * @param event The button to change data table pages was clicked
   */
  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    let rows = parseInt(event.target.value, 10);
    pageNumber.current = 0;
    getApprovalRequests(pageNumber.current, rows);
  }

  return (
    <Box className={classes.root}>
      {showSpinner && <Spinner />}
      <Box mt={3}>
        <Typography variant="h2" color="textPrimary">
          Filter approval requests
        </Typography>
      </Box>
      <Grid container spacing={2} alignContent="flex-end">
        {userContext.userType !== UserType.Traveler && <Grid item lg={4} md={4} sm={12} xs={12}>
          <UserSearchBox
            label="Requester"
            user={currentUser ? currentUser : new UserLT()}
            onChange={handleUserChange}
            activeSearchType={ActiveSearchType.Both}
          />
        </Grid>}
        {userContext.userType !== UserType.Traveler && <Grid item lg={4} md={4} sm={12} xs={12}>
          <Controller
            name="searchByName"
            control={control}
            render={({
              field: { onChange, value, ref, onBlur }
            }) => (
              <TextField
                id="ff-searchByName"
                label="Search by"
                value={value}
                onBlur={(e) => {
                  formUtils.trimOnBlur(onBlur, onChange, e.target.value);
                  setSearchByName(e.target.value.trim())
                }}
                onChange={event => { setSearchByName(event.target.value); onChange(event); }}
                margin="dense"
                variant="outlined"
                inputProps={{ minLength: 2 }}
                placeholder={"Search by first or last name"}
                fullWidth
              />
            )}
          />
        </Grid>}
        {userContext.userType !== UserType.Traveler && <Grid item lg={4} md={4} sm={12} xs={12}>
          <Controller
            name="accountOptions"
            control={control}
            rules={formRules.accountOptions}
            render={({
              field: { onChange, value, onBlur }
            }) => (
              <MultipleSelectChips
                id="ff-accounts"
                label="Account(s)"
                options={clientOptions}
                value={value}
                onChange={(value) => { onChange(value); pageNumber.current = 0; getApprovalRequests(0); }}
                errorMessage={''}
                error={errors?.accountOptions ? true : false}
              />
            )} />
          <Typography variant='body2' color='error' className={classes.errorText}>
            {errors.accountOptions && (errors?.accountOptions as any)?.message}
          </Typography>
        </Grid>}
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Controller
            name="approvalTypeOptions"
            control={control}
            render={({
              field: { onChange, value, onBlur }
            }) => (
              <MultipleSelectChips
                id="ff-approvalTypeOptions"
                label="Approval Type"
                options={approvalTypes}
                value={value}
                onChange={(value) => { onChange(value); pageNumber.current = 0; getApprovalRequests(0); }}
                error={false}
                errorMessage={""} />
            )} />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Controller
            name="statusOptions"
            control={control}
            render={({
              field: { onChange, value, onBlur }
            }) => (
              <MultipleSelectChips
                id="ff-status"
                label="Status"
                options={statuses}
                value={value}
                onChange={(value) => { onChange(value); pageNumber.current = 0; getApprovalRequests(0); }}
                error={false}
                errorMessage={""}
              />
            )} />
        </Grid>
      </Grid>

      <Box pt={2.5} className={classes.flexGrow}>
        <p className={classes.switchHeader}>Bulk Actions:</p>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                id="switch-approve"
                onChange={handleSwitchChange}
                checked={bulkSwitchStates.approve}
                name="approve"
                size="small"
              />
            }
            label="Approve"
          />
          <FormControlLabel
            control={
              <Switch
                id="switch-deny"
                onChange={handleSwitchChange}
                checked={bulkSwitchStates.deny}
                name="deny"
                color="secondary"
                size="small"
              />
            }
            label="Deny"
          />
        </FormGroup>
      </Box>
      <Grid container spacing={2} alignContent="flex-end">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Box mt={2} mb={1}>
              <Divider />
            </Box>
            <TableContainer>
              <Table
                className={classes.table}
                aria-label="Approval table"
                size="small"
              >
                <EnhancedTableHead
                  onSelectAllClick={handleSelectAllToggle}
                  numSelected={selectedRequestIds.size}
                  selectableRowsCount={approvalRequestList.list.filter((request) => request.approvalStatus === ApprovalStatus.Pending).length}
                  showCheckboxes={bulkSwitchStates.approve || bulkSwitchStates.deny}
                  actionName={actionName}
                />

                {approvalRequestList.totalCount > 0 ? <TableBody>
                  {approvalRequestList.list.map((row, i) => {
                    const isSelected: boolean = selectedRequestIds.has(row.approvalRequestId);
                    return (
                      <DataRow
                        showCheckbox={bulkSwitchStates.approve || bulkSwitchStates.deny}
                        selected={isSelected}
                        key={row.approvalRequestId}
                        row={row}
                        loggedInUserId={Number(userContext.userId)}
                        loggedInUserEmail={userContext.email}
                        token={userContext.accessToken}
                        timeZone={userContext.timeZone}
                        getApprovalRequests={getApprovalRequests}
                        onSelectToggle={handleSelectToggle}
                        inBulkRequest={sendBulkRequest}
                        setShowMainSpinner={setShowSpinner}
                        bulkAction={actionName}
                        bulkComment={bulkComment}
                        checkShowButtons={checkShowButtons}
                        getThisApprover={getLoggedInUserApproval}
                        tallyBulkResults={tallyBulkResults}
                        showTimedInfoSnackbar={showTimedInfoSnackbar}
                        expandSingle={approvalRequestList.totalCount > 1 ? false : true}
                      />
                    );
                  })}
                </TableBody>
                  :
                  <TableBody>
                    <TableRow>
                      {!showSpinner && <TableCell style={{ padding: 60, }} colSpan={12}><Typography variant='body2' align="center">No data available in this table</Typography></TableCell>}
                    </TableRow>
                  </TableBody>
                }
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={11}
                      count={approvalRequestList.totalCount}
                      rowsPerPage={rowsPerPage}
                      page={pageNumber.current}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={tablePagination}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

            {/* Checks to see if checkbox is selected, if so shows the FAB. When none are selected, all bulk actions are toggled off, or if a bulk request is currently processing, FAB disappears */}
            {(selectedRequestIds.size > 0 && (bulkSwitchStates.approve || bulkSwitchStates.deny)) && !sendBulkRequest &&
              <FloatingButton
                setShowSpinner={setShowSpinner}
                action={actionName}
                bulkApprovalCount={selectedRequestIds.size}
                onConfirm={handleBulkAction} />
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

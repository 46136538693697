import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Button, Box, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiTypography-body2': {
        lineHeight: 1.5,
      },
      "& .MuiTable-root": {
        maxWidth: '500px',
      },
    },
    stepLabel: {
      fontSize: 12,
      textTransform: 'uppercase',
    },
    bold: {
      fontWeight: 'bold',
    },
    //table styles
    tableHeadWhite: {
      backgroundColor: "#FFFFFF",
    },
    tableStyles: {
      "& .MuiTableCell-root": {
        border: "1px solid #eee",
        padding: '10px',
      }, 
      "& .MuiTableCell-head": {
        padding: 5,
        paddingLeft: 10,
      },
    },
    rowFocus: {
      fontWeight: 'bold',
    },
    tableCellHighlight: {
      "& .MuiTableCell-root": {
        border: "2px solid #00467E",
      },
    },
    lightGreyBg: {
      backgroundColor: "#F9F9F9",
    },
    edit: {
      color: '#00467E',
      fontSize: 12,
      marginLeft: 8,
      textDecoration: 'underline',
    }
  }),

);


export default function Review() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box my={4}>
        <Typography className={classes.stepLabel}>Step 5</Typography>
        <Typography>Review</Typography>
      </Box>
      <Box mt={2} mb={1}>
        <Typography className={classes.stepLabel}>Client Info</Typography>
      </Box>
      <hr />
      <Box mt={2} mb={6}>
      <Typography className={classes.bold} variant='body2'>Client</Typography>
      <Typography variant='body2'>Mystery Inc.</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mt={4} mb={1}>
        <Box><Typography className={classes.stepLabel}>Upload</Typography></Box>
        <Box><Typography className={classes.edit}>Edit</Typography></Box>
      </Box>
      <hr />
      <Box mt={2}>
        <Typography className={classes.bold} variant='body2'>File name</Typography>
        <Typography variant='body2'>MysteryInc-4-21-22.csv</Typography>
      </Box>
      <Box mt={2}>
        <Typography className={classes.bold} variant='body2'>Delimiter</Typography>
        <Typography variant='body2'>N/A</Typography>
      </Box>
      <Box mt={2}>
        <Typography className={classes.bold} variant='body2'>Column Wrapper</Typography>
        <Typography variant='body2'>N/A</Typography>
      </Box>
      <Box mt={2}>
        <Typography className={classes.bold} variant='body2'>Security</Typography>
        <Typography variant='body2'>N/A</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mt={4} mb={1}>
        <Box><Typography className={classes.stepLabel}>Map Fields</Typography></Box>
        <Box><Typography className={classes.edit}>Edit</Typography></Box>
      </Box>
      <hr />
      <Box mt={2}>
        <Typography className={classes.bold} variant='body2'>Does the file contain column headers?</Typography>
        <Typography variant='body2'>No</Typography>
      </Box>
   
      <Box mt={2} mb={1}>
      <Typography className={classes.bold} variant='body2'>Required fields</Typography>
      </Box>
      <Table className={classes.tableStyles}>
        <TableHead className={classes.tableHeadWhite}>
          <TableRow>
            <TableCell>Database column name</TableCell>
            <TableCell>Imported column name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              First Name
            </TableCell>
            <TableCell>
              Velma
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Last Name
            </TableCell>
            <TableCell>
              Dinkley
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Email address
            </TableCell>
            <TableCell>
              velma.dinkley@mystery.inc
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Date of birth
            </TableCell>
            <TableCell>
              11/22/84
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Gender
            </TableCell>
            <TableCell>
              Female
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Mobile number
            </TableCell>
            <TableCell>
              555-555-5123
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box mt={4} mb={1}>
      <Typography className={classes.bold} variant='body2'>Additional fields</Typography>
      </Box>
      <Table className={classes.tableStyles}>
        <TableHead className={classes.tableHeadWhite}>
          <TableRow>
            <TableCell>Database column name</TableCell>
            <TableCell>Imported column name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Employee ID/Unique ID
            </TableCell>
            <TableCell>
              MI123
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Travel/Rule Class
            </TableCell>
            <TableCell>
              Economy
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Org. Unit/Division
            </TableCell>
            <TableCell>
              Crime
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Position/Title
            </TableCell>
            <TableCell>
              Detective
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box display="flex" flexDirection="row" mt={4} mb={1}>
        <Box><Typography className={classes.stepLabel}>OBE Login</Typography></Box>
        <Box><Typography className={classes.edit}>Edit</Typography></Box>
      </Box>
      <hr />
      <Box mt={2}>
      <Typography className={classes.bold} variant='body2'>  Does the client have an existing instance with Concur?</Typography>
        <Typography variant='body2'>Yes</Typography>
      </Box>
      <Box mt={2}>
      <Typography variant='body2' className={classes.bold}>Unique login option</Typography>
        <Typography variant='body2'>Generate new email</Typography>
        <ul style={{lineHeight: 1.5,}}>
          <li>Imported column: Employee ID</li>
          <li>New email domain: @scoobydoo.inc</li>
        </ul>
      </Box>
      <Box display="flex" flexDirection="row" mt={4} mb={1}>
        <Box><Typography className={classes.stepLabel}>Notifications</Typography></Box>
        <Box><Typography className={classes.edit}>Edit</Typography></Box>
      </Box>
      <hr />
      <Table className={classes.tableStyles}>
        <TableHead className={classes.tableHeadWhite}>
          <TableRow>
            <TableCell>Database column name</TableCell>
            <TableCell>Imported column name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              Employee ID/Unique ID
            </TableCell>
            <TableCell>
              MI123
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box my={20} />
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import { UseFieldArrayReturn, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { makeStyles, createStyles, Theme, withStyles, WithStyles, } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Link } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from "clsx";
import { Box, TableCell, Table, TableRow, TableHead, TextField, } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import LoopIcon from '@material-ui/icons/Loop';
import { useDropzone } from 'react-dropzone';
import { JsonException } from "@cbtravel/common/lib/shared/common/exceptions/json-exception";

import { CustomFieldFormInputs, listValueFields } from './CustomFieldForm';
import BulkImportIcon from '../../../icons/BulkImportIcon';
import { useCustomSnackbar } from '../../../components/shared/customHooks/useCustomSnackbar';

const csvTemplateFile = require("../../../components/shared/csvTemplates/custom-field-bulk-import.csv").default;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    pointer: {
        cursor: 'pointer',
    },
    floatRight: {
        float: 'right',
    },
    tableTitle: {
        fontSize: '14px',
        color: '#4d4d4d',
        fontWeight: 'bold',
    },
    table: {
        minWidth: 400,
        border: '1px solid #C9C7C7',
        width: "100%",
        "& a": {
            color: "#00467E",
            textDecoration: "none",
        },
        "& .MuiTableCell-head": {
            fontWeight: 700,
            lineHeight: 0,
        },
        "& .MuiTableCell-head.MuiTableCell-sizeSmall": {
            padding: "5px 0px 5px 10px",
            whiteSpace: "nowrap",
        },
        "& .MuiTableCell-sizeSmall": {
            padding: "5px 0px 5px 10px",
            marginTop: "2px",
            borderRight: '1px solid #c9c7c7'
        },
        "& .moreInfoTable .MuiTableCell-sizeSmall": {
            padding: "8px",
        },
        "& .MuiIconButton-sizeSmall": {
            padding: 0,
        },
        "& .MuiIconButton-root": {
            padding: '0px',
        },
    },

    th: {
        "& .MuiIconButton-root": {
            padding: '0px',
        }
    },
    dialogContent: {
        padding: '20px 25px 40px 25px',
        marginTop: '-45px',
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textAlign: 'left',
    },
    flexGrow1: {
        flexGrow: 1,
    },
    flexGrow3: {
        flexGrow: 3,
    },
    flexRowRight: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'flex-end',
        textAlign: 'right',
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        fontSize: '14px',
    },
    dropArea: {
        border: '1px dotted #c9c7c7',
        width: '100%',
        height: '284px',
        marginTop: '20px',
    },
    dropAreaActive: {
        border: '1px dotted #00467e',
        width: '100%',
        height: '284px',
        marginTop: '20px',
    },
    dropHeader: {
        color: '#1F2532',
        textAlign: 'center',
        fontSize: '18px',
    },
    dropText: {
        color: '#4d4d4d',
        textAlign: 'center',
        fontSize: '14px',
        lineHeigt: '16px',
    },
    center: {
        textAlign: 'center',
    },
    createOrimportLink: {
        fontSize: 14,
    },
    validation: {
        paddingTop: '5px',
    }
}),
);

export interface DialogTitleProps {
    /** The text for the underlying `div` container's id attribute. */
    id: string;
    /** Additional JSX element to include with the dialog. */
    children: React.ReactNode;
    /** Action to execute to close the dialog. */
    onClose: () => void;
}

/**
 * Component to display a title with a button to close the dialog.
 * 
 * @param props {@link DialogTitleProps Properties} for the `DialogTitle` component.
 * @returns A JSX element for displaying a title and close button in the dialog.
 */
function DialogTitle(props: DialogTitleProps) {
    const { children, onClose, id } = props;
    const classes = useStyles();

    return (
        <MuiDialogTitle disableTypography className={classes.root} id={id}>
            <Typography style={{ fontSize: 24, }} variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton id="btn-close" aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
}

interface BulkListProps {
    /** React hook form function for setting a form's backing value. */
    setValue: UseFormSetValue<CustomFieldFormInputs>,
    /** React hook form function for getting a form's backing value(s). */
    getValues: UseFormGetValues<CustomFieldFormInputs>,
    /** React hook form field array for the custom field form inputs. */
    fieldValueArray: UseFieldArrayReturn<CustomFieldFormInputs, "customFieldValueList", "id">,
    /** The ID number of the custom field. */
    customFieldId: number,
    /** Whether the current user has access to edit the dropdown options */
    canEdit: boolean
}

/**
 * Component for displaying a dialog used for importing a CSV file into custom field
 * dropdown list values.
 * 
 * @param props {@link BulkListProps Properties} for the `BulkList` component.
 * @returns A JSX Element used for opening and displaying the bulk list import dialog.
 */
export default function BulkList(props: BulkListProps) {
    const classes = useStyles();
    const snackbar = useCustomSnackbar();
    const [open, setOpen] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState<File>();
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [uploadValues, setUploadValues] = useState<Array<listValueFields>>([]);
    const [uploadError, setUploadError] = useState<string>();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: ".csv",
        multiple: false,
        noClick: true,
        maxSize: 1000 * 1000,
        onDrop: acceptedFiles => { onChangeFile(acceptedFiles[0]) },
        onDropRejected: rejected => {
            setFileUpload(undefined);
            setUploadValues([]);
            setUploadError(rejected[0].errors[0].message)
        }
    });

    /**
     * Opens the dialog.
     */
    function handleClickOpen() {
        setOpen(true);
    }

    /**
     * Closes and resets the dialog.
     */
    function handleClose() {
        setOpen(false);
        clearFile();
    }

    /**
     * Clears any state related to a file upload.
     */
    function clearFile() {
        setFileUpload(undefined);
        setUploadValues([]);
        setUploadError(undefined);
    }

    /**
     * When the file is changed, verifies that base criteria is met before
     * updating the state.
     * 
     * @param file The file being uploaded.
     */
     function onChangeFile(file: File | undefined) {
        
        if (file) {
            // Only accept csv file extensions
            if (file.name.toLowerCase().indexOf(".csv") < 0) {
                setUploadError("CSV files only");
                setFileUpload(undefined);
                setUploadValues([]);
            }
            // Only accept files less than 1MB in size
            else if (file.size > 1000 * 1000) {
                setUploadError("File must be less than 1MB");
                setFileUpload(undefined);
                setUploadValues([]);
            } else {
                setFileUpload(file);
                setUploadValues([]);
                setUploadError(undefined);
            }
        }
    }
    
    /**
     * When browse computer is clicked. Reset the value 
     * @param event 
     */
    function onClickFile(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        event.currentTarget.value = ""; // reset value of filepath to allow submission of the same filename. 
    }

    /**
     * Handles initializing the template CSV file download.
     * 
     * @param event Mouse click event that triggered the function.
     */
    async function handleDownloadTemplate(event: React.MouseEvent) {
        event.preventDefault();

        try {
            let filename = "custom-field-bulk-import.csv";
            let url = csvTemplateFile;
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (e) {
            snackbar.error(e as JsonException);
        }
    }

    /**
     * Reads through the string representation of the CSV while validating each
     * line and converting the lines to values we can use for the form.
     * 
     * @param text The raw text which has been read from a CSV file.
     */
    function validateCsv(text: string | ArrayBuffer) {
        let newValues: Array<listValueFields> = [];
        let hasError = false;

        // Split CSV text into lines
        let lines = String(text).split(/[\r\n]+/g);

        if (lines.length >= 1 && lines[0].toLowerCase().replace(/\s+/g, '') !== "listitem,reportvalue") {
            setUploadError("Header row is required and must match example");
            return;
        }

        // Read and validate each line. First line is the CSV header so skip.
        for (let i = 1; i < lines.length; i++) {
            // Ignore empty lines
            if (lines[i].trim().length === 0) continue;

            // Split line by comma
            let lineValue = lines[i].split(',');

            // Too many or too few commas
            if (lineValue.length !== 2) {
                setUploadError(`Line ${i + 1} must have exactly 2 items separated by a single comma.`);
                hasError = true;
                break;
            }

            // Convert line to list value
            newValues.push({
                customFieldId: props.customFieldId,
                customFieldValueId: -1,
                name: lineValue[0],
                value: lineValue[1],
                markedForDeletion: false,
                defaultListItem: false,
            });
        }

        // Import cannot result in more than 50 rows
        if (newValues.length + props.fieldValueArray.fields.length > 50) {
            setUploadError("List cannot contain more than 50 rows.");
            hasError = true;
        }

        // Update state with new values
        if (!hasError) {
            setUploadValues(newValues);
        } else {
            setUploadValues([]);
        }
    }

    /**
     * Appends the values from the CSV into the underlying form and close the 
     * import dialog.
     */
    function importCsv() {
        if (!uploadError) {
            // Get existing values and filter out empty rows in the table
            let existingValues = props.getValues("customFieldValueList").filter(v => v.name.length > 0 && v.value.length > 0);

            // Append imported values to filtered exsting values and update the form
            props.setValue("customFieldValueList", [...existingValues, ...uploadValues]);
            handleClose();
        }
    }

    useEffect(() => {

        if (fileUpload) {
            setUploadInProgress(true);

            let reader = new FileReader();

            // Fires after reader.readAsText is successful
            reader.onload = async (e) => {
                let text = e.target!.result;

                if (text) {
                    validateCsv(text);
                    setUploadInProgress(false);
                }
            };

            reader.readAsText(fileUpload);
        }
    }, [fileUpload]);

    return (
        <div>
            <Box className={classes.flexRowRight}>
                <Box className={classes.flexColumn}>
                    <Button style={{ paddingRight: 0 }} id="btn-open" variant="text" disabled={!props.canEdit || props.fieldValueArray.fields.length >= 50} onClick={handleClickOpen}>
                        <Typography
                            className={clsx(classes.pointer, classes.createOrimportLink)}
                            style={props.canEdit && props.fieldValueArray.fields.length < 50 ? { color: '#00467F' } : { color: '#00000040' }}
                            aria-label="save"
                        > Create or import bulk list <ExitToAppIcon style={{ width: 20, marginLeft: 7, marginRight: 0, }} className={classes.floatRight} />
                        </Typography>
                    </Button>

                </Box>
            </Box>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <Box mt={1}>
                    <DialogTitle id="dialog-title" onClose={handleClose}>
                        Create or import bulk list
                    </DialogTitle>
                </Box>
                <Box className={classes.dialogContent}>
                    <Typography gutterBottom>
                        <ul style={{ fontSize: '14px', }}>
                            <li><Link id="link-download" href="#" onClick={handleDownloadTemplate}>Download</Link> and open our list template (.csv file). </li>
                            <li>Enter your data in the appropriate columns in the template. </li>
                            <li>Do <strong>NOT</strong> include commas in your fields or this will break the file formatting.</li>
                            <li>You may add up to a max of 50 rows. </li>
                        </ul>
                        <p style={{ fontSize: '14px', }}>Example of correct and incorrect data formatting:</p>
                        <Table
                            className={classes.table}
                            aria-label="list items"
                            size="small"
                            id="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell width='50%' >
                                        <Typography className={classes.tableTitle}>
                                            List item
                                        </Typography>
                                    </TableCell>
                                    <TableCell width='50%'>
                                        <Typography className={classes.tableTitle}>
                                            Report value
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        <Box className={classes.flexRow}>
                                            <Box className={classes.flexColumn}>
                                                James Marcus Garcia
                                            </Box>
                                            <Box className={classes.flexColumn}>
                                                <CheckIcon style={{ color: green[500], marginRight: '6px', }} />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <Box className={classes.flexRow}>
                                            <Box className={classes.flexColumn}>
                                                Jim Garcia
                                            </Box>
                                            <Box className={classes.flexColumn}>
                                                <CheckIcon style={{ color: green[500], marginRight: '6px', }} />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        <Box className={classes.flexRow}>
                                            <Box className={classes.flexColumn}>
                                                Robert L. Davis
                                            </Box>
                                            <Box className={classes.flexColumn}>
                                                <CheckIcon style={{ color: green[500], marginRight: '6px', }} />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <Box className={classes.flexRow}>
                                            <Box className={classes.flexColumn}>
                                                Rob Davis, OD
                                            </Box>
                                            <Box className={classes.flexColumn}>
                                                <ClearIcon color='error' style={{ marginRight: '6px', }} />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        <Box className={classes.flexRow}>
                                            <Box className={classes.flexColumn}>
                                                Patricia Williams, MD
                                            </Box>
                                            <Box className={classes.flexColumn}>
                                                <ClearIcon color='error' style={{ marginRight: '6px', }} />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <Box className={classes.flexRow}>
                                            <Box className={classes.flexColumn}>
                                                Patty Williams
                                            </Box>
                                            <Box className={classes.flexColumn}>
                                                <CheckIcon style={{ color: green[500], marginRight: '6px', }} />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Typography>

                    <Box {...getRootProps({ className: (isDragActive ? classes.dropAreaActive : classes.dropArea) })}>
                        <Box mt={3} style={{ textAlign: 'center', }}>
                            <BulkImportIcon width='44px' height='53px' color='#00467F' />
                        </Box>
                        <Box className={classes.center} mt={1}>
                            <Typography className={classes.dropHeader}>
                                Drag and drop your file here
                            </Typography>
                            <Box mt={.5}>
                                <Typography className={classes.dropHeader}>
                                    OR
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Button id="btn-open" variant="outlined" color="primary" component="label">
                                    Browse computer
                                    <input
                                        type="file"
                                        accept=".csv"
                                        hidden
                                        {...getInputProps()}
                                        onChange={(e) => onChangeFile(e.target.files?.[0])}
                                        onClick={(e) => onClickFile(e)}
                                    />
                                </Button>
                            </Box>
                            <Box mt={2}>
                                <Typography className={classes.dropText}>
                                    Only supports CSV files
                                </Typography>
                                <Typography className={classes.dropText}>
                                    Max file size is 1MB
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.flexRow} mt={2}>
                        <Box className={clsx(classes.flexColumn, classes.flexGrow3)}>
                            <TextField
                                style={{ paddingRight: '10px', }}
                                size="small"
                                id="ff-file-name"
                                placeholder="example_dropdownlist.csv"
                                value={fileUpload?.name || ''}
                                variant="outlined"
                                fullWidth
                                error={uploadError !== undefined}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <React.Fragment>

                                            {uploadInProgress &&
                                                <InputAdornment position="end">
                                                    <IconButton disableRipple={true} color="default" aria-label="search" style={{ paddingRight: '0px', backgroundColor: 'transparent', }} >
                                                        <LoopIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            {/* NOTE: This Loop icon will appear while importing and will disappear when done importing.  */}

                                            {
                                                (fileUpload && !uploadError) &&
                                                <InputAdornment position="end">
                                                    <IconButton disableRipple={true} color="default" aria-label="search" style={{ paddingRight: '0px', backgroundColor: 'transparent', }} >
                                                        <CheckIcon style={{ color: green[500], }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            {/* Note: The checkIcon will appear after and will indicate to the user that import is complete. */}

                                            <InputAdornment position="end">
                                                <IconButton onClick={clearFile} disableRipple={true} color="default" aria-label="search" style={{ paddingRight: '0px', paddingLeft: '0px', backgroundColor: 'transparent', }} >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                            {/* Note: The clearIcon will be used to clear the text field. */}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box className={clsx(classes.flexColumn, classes.flexGrow1)}>
                            <Button
                                id="btn-save"
                                variant="contained"
                                color="primary"
                                disabled={fileUpload === undefined || uploadError !== undefined}
                                onClick={importCsv}
                            >
                                Import
                            </Button>
                        </Box>
                    </Box>
                    {uploadError &&
                        <Box className={classes.flexRow}>
                            <Typography className={classes.validation} variant='body2' color='error'>
                                {uploadError}
                            </Typography>
                        </Box>
                    }

                </Box>

            </Dialog>
        </div>
    );
}

import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { ClientRS } from '@cbtravel/common/lib/shared/messages/general/responses/client-rs';
import { useLocation, useHistory, Prompt, } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Box, Fab, Grid, Link, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import Spinner from "../../../components/shared/Spinner";
import ActionDialog from "../../../components/ui/Dialog/ActionDialog";

import { AddCircleOutline as AddIcon, RemoveCircleOutline as RemoveIcon } from "@material-ui/icons";
import { CloseIcon } from "../../../icons/Icons";
import SaveIcon from "@material-ui/icons/Save";

interface ReferenceDataFormProps {
    /** Object representing the currently active client. */
    activeClient?: ClientRS,
    /** Function to call when changes are discarded from the ReferenceDataForm */
    discardChanges: () => void,
    /** Reference data type (air, hotel, car etc) */
    dataType: string,
}

interface SaveDialogProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((v: boolean) => void),
    positiveAction: () => void,
    negativeAction: () => void,
    negativeText: string,
    children: React.ReactNode
}

interface CancelDialogProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((v: boolean) => void),
    positiveAction: () => void,
    negativeAction: () => void,
    negativeText: string,
    children: React.ReactNode
}

/** The save dialog that pops up when clicking the save button. */ 
function SaveDialog(props: SaveDialogProps) {
    return (
        <ActionDialog
            title="Save changes"
            icon={<div className={useStyles().iconAction}><SaveIcon /></div>}
            positiveText="Save"
            negativeText={props.negativeText}
            open={props.open}
            setOpen={props.setOpen}
            positiveAction={props.positiveAction}
            negativeAction={props.negativeAction}
            dialogType="save"
        >
            {props.children}
        </ActionDialog>
    );
}

/** The cancel dialog that pops up when clicking the cancel button. */ 
function CancelDialog(props: CancelDialogProps) {
    return (
        <ActionDialog
            title="Discard changes"
            icon={<div className={useStyles().iconCancelAction}><CloseIcon height="16" width="16" color="#fff" /></div>}
            positiveText="Discard"
            negativeText={props.negativeText}
            open={props.open}
            setOpen={props.setOpen}
            positiveAction={props.positiveAction}
            negativeAction={props.negativeAction}
            dialogType="delete"
        >
            {props.children}
        </ActionDialog>
    );
}


const useStyles = makeStyles((theme: Theme) => ({
    tabs: {
        "& .MuiTab-root": {
            minWidth: 'auto',
        },
        "& .MuiTabScrollButton-root": {
            width: '20px',
        },
        "& .MuiSvgIcon-fontSizeSmall": {
            marginTop: 1,
            color: "#00467E",
        },
        backgroundColor: '#ffffff',
        borderBottomWidth: '1px',
        borderBottomColor: '#ddd',
        borderBottomStyle: 'solid',
    },
    tab: {
        textTransform: 'none',
        paddingLeft: 25,
        paddingRight: 25,
    },
    tabRow: {
        marginTop: 17,
        marginLeft: -4,
        boxShadow: 'none',
    },
    tooltip: {
        fontSize: '.7rem',
        marginTop: -4,
    },
    tooltipLabel: {
        fontSize: '11px', pointerEvents: "auto",
        // marginLeft: -22, marginTop: 12,
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },

    h2: {
        fontWeight: 400,
        letterSpacing: -.05,
        color: '#1F2532',
        whiteSpace: 'nowrap',
    },
    subhead: {
        margin: '0 0 10px',
        color: '#808080',
        fontWeight: 400,
    },
    statusState: {
        padding: '0 0 2px 3px',
        '& .MuiTypography-body1': { color: '#417505', fontSize: 14, textTransform: 'uppercase', },
    },
    addIcon: {
        color: "#00467E",
        marginTop: -4,
    },
    addText: {
        "& .MuiLink-button": {
            fontSize: 12,
            fontFamily: "Open Sans, Arial, sans-serif",
            fontWeight: 600,
            color: "#00467E",
            marginTop: 0,
            textDecoration: "none",
        },
        "& .MuiSvgIcon-root": {
            marginBottom: -1.5,
            marginRight: -1,
            fontSize: 13,
        },
    },
    fab: {
        position: "absolute",
        bottom: 32, right: 32,
    },
        btn: {
            marginBottom: 6,
            width: 45, height: 45,
            boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            "& .MuiSvgIcon-root": {
                fontSize: '1.4rem',
            },
        },
            saveBtn: {
                padding: '0 0 2px 2px',
            },
            cancelBtn: {
                backgroundColor: '#808080',
                "&:hover": {
                    backgroundColor: '#4d4d4d',
                } 
    },
    iconAction: {
        marginTop: 16,
        background: "#00467F",
        borderRadius: 40,
        width: "44px",
        height: "44px",
        "& .MuiSvgIcon-root": {
            color: "#fff",
            marginTop: "10px",
        },
    },
    iconCancelAction: {
        marginTop: 16,
        background: "#BC2C2F",
        borderRadius: 40,
        width: "44px",
        height: "44px",
        "& svg": {
            marginTop: 14,
        },
    },
    dialogContent: {
        lineHeight: 1.5,
    },
}));

export default function ReferenceDataForm(props: ReferenceDataFormProps) { 
    const classes = useStyles();
    const { discardChanges, dataType } = props;
    const [referenceDataType, setReferenceDataType] = useState("");
    const [provider, setProvider] = useState("");
    const [showClickSaveDialog, setShowClickSaveDialog] = useState<boolean>(false);
    const [showClickCancelDialog, setShowClickCancelDialog] = useState<boolean>(false);

    const handleReferenceDataType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReferenceDataType(event.target.value);
    };

    const handleProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProvider(event.target.value);
    };

    function handleSaveBtnClick() {
        setShowClickSaveDialog(true);
    };

    function handleCancelBtnClick() {
        setShowClickCancelDialog(true);
    };

    return (
        <>
            <Box mt={3.5}>
                <Grid className={classes.flexRow} container spacing={2}>
                    <Grid item sm={6} xs={6}>
                        <Typography variant="h2" className={classes.h2}>
                            Add {dataType} data
                        </Typography>
                    </Grid>
                    {/* TODO: */}
                    {/* {isEdit && */}
                        <Grid item sm={6} xs={6}>
                            <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center" mt={.5}>
                                <Switch size="small" defaultChecked />
                                <Box className={classes.statusState}>
                                    <Typography component="span">Active</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    {/* } */}
                </Grid>

                <Box mt={.5}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <Box mt={1}>
                                    <TextField
                                        id="reference-data-type"
                                        variant="outlined"
                                        fullWidth
                                        select
                                        size="small"
                                        label="Type"
                                        value={referenceDataType}
                                        InputProps={{ autoComplete: "off" }}
                                        onChange={handleReferenceDataType}
                                    >
                                        {referenceDataTypes.map((option) => (
                                            <MenuItem key={option.type} value={option.type}>
                                                {option.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            </form>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TextField
                                id="reference-data-name"
                                label="Name"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={12} xs={12}>
                            <TextField
                                id="code"
                                label="Code"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={12} xs={12}>
                            <TextField
                                id="display-tier"
                                label="Display tier"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                autoComplete="off"
                                defaultValue="5"
                            />
                        </Grid>
                    </Grid>
                </Box>

                {/* Map GDS/third party providers and codes */}
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <Box mt={2.5} mb={-1}>
                            <Typography variant="h2" className={classes.h2} style={{whiteSpace: 'normal', lineHeight: '22px', }}>
                                Map third party provider codes (optional)
                            </Typography>
                        </Box>

                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <Box mt={1}>
                                <TextField
                                    id="gds-provider"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    size="small"
                                    label="Third party provider"
                                    value={provider}
                                    InputProps={{ autoComplete: "off" }}
                                    onChange={handleProvider}
                                >
                                    {providers.map((option) => (
                                        <MenuItem key={option.provider} value={option.provider}>
                                            {option.provider}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </form>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                            id="gds-code"
                            label="Code"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                        />
                    </Grid>

                    {/* Add another provider */}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                            component="p"
                            display="flex"
                            py={0.5}
                            mt={-1}
                            className={classes.addText}
                        >
                            <Link href="#" component="button" color="primary">
                                <AddIcon className={classes.addIcon} /> Add another
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            
            {/* Floating action buttons with dialogs */}
            <Box display="flex" flexDirection="column" className={classes.fab}>
                {/* Save button */}
                <Fab
                    color="primary"
                    aria-label="save"
                    className={clsx(classes.btn, classes.saveBtn)}
                    onClick={handleSaveBtnClick}
                >
                    <SaveIcon />
                </Fab>
                <SaveDialog
                    open={showClickSaveDialog}
                    setOpen={setShowClickSaveDialog}
                    positiveAction={discardChanges}
                    negativeAction={() => { }}
                    negativeText="Cancel"
                >
                    <p className={classes.dialogContent}>
                        There are unsaved changes.<br />
                        Do you wish to save them?
                    </p>
             </SaveDialog>

                {/* Cancel button */}
                <Fab
                    aria-label="cancel"
                    className={clsx(classes.btn, classes.cancelBtn)}
                    onClick={handleCancelBtnClick}
                >
                    <CloseIcon height="17" width="17" color="#fff" />
                </Fab>
                <CancelDialog
                    open={showClickCancelDialog}
                    setOpen={setShowClickCancelDialog}
                    positiveAction={discardChanges}
                    negativeAction={() => {
                        // Continue with navigation without submitting
                        // routerHistory.push(navigateOnSave);
                    }}
                    negativeText="Cancel"
                >
                    <p className={classes.dialogContent}>
                        Are you sure you want to discard your changes?
                    </p>
                </CancelDialog>
            </Box>
        </>
    );
}

const referenceDataTypes = [
    { type: 'Add-on' },
    { type: 'Amenity' },
    { type: 'Other types' },
];

const providers = [
    { provider: 'Abacus' },
    { provider: 'Amadeus' },
    { provider: 'Apollo' },
    { provider: 'Concur' },
    { provider: 'Galileo' },
    { provider: 'Sabre' },
    { provider: 'Umbrella' },
    { provider: 'Worldspan' },
];

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { iUserContext } from "@cbtravel/common/lib/shared/interfaces/iUserContext";
import { UseFormGetValues, Controller, UseFormSetValue } from "react-hook-form";
import { Control, DeepMap, FieldErrors, useFieldArray, useWatch } from "react-hook-form";
import { ProfileFormInputs } from "./Profile";
import CustomFieldFieldArray from "../../../components/shared/CustomFieldFieldArray";
import { CustomFieldRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-rs";
import { DataEntryFlow } from "@cbtravel/common/lib/shared/common/enumerations/data-entry-flow";
import { CustomFieldValueRS } from "@cbtravel/common/lib/shared/messages/general/responses/custom-field-value-rs";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useEffect, useRef, useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { UserContext } from "../../../components/shared/UserContext";
import { customFieldUtil } from '../../../util/custom-field-util';
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";

type FormInputsExportType = Control<ProfileFormInputs>
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiTypography-subtitle1": {
                fontSize: 12,
                marginBottom: theme.spacing(1),
            },
            "& .MuiFormHelperText-root": {
                fontSize: 12,
                color: "#808080",
            },
        },
        errorTextField: {
            paddingTop: '5px',
        },
        tooltipLabel: { fontSize: 16, pointerEvents: "auto" },
        accordion: {
            "& .MuiFormHelperText-root": {
                fontSize: 11,
                color: "#808080",
            },
            "& .MuiInputBase-input": {
                background: "#fff",
            },
            "& .MuiInputBase-root": {
                background: "#fff",
            },
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: 0,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
            },
        },
        h3: {
            fontSize: 12,
            fontWeight: 300,
            textTransform: "uppercase",
            paddingTop: 10,
            marginBottom: -4,
            display: "block",
        },
        addIcon: {
            color: "#00467E",
            marginTop: -4,
        },
        removeIcon: {
            color: "#BC2C2F",
        },
        addText: {
            marginTop: 0,
            "& .MuiLink-button": {
                fontSize: 12,
                fontFamily: "Open Sans, Arial, sans-serif",
                fontWeight: 600,
                color: "#00467E",
                marginTop: 0,
                textDecoration: "none",
            },
            "& .MuiSvgIcon-root": {
                marginBottom: -1.5,
                marginRight: -1,
                fontSize: 13,
            },
        },
        removeWrapper: {
            marginTop: -63,
            marginBottom: -63,
            "& .MuiLink-button": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "Open Sans, Arial, sans-serif",
                color: "#BC2C2F",
                textDecoration: "none",
            },
        },
        removeText: {
            "& .MuiSvgIcon-root": {
                marginRight: -1,
                marginBottom: -1.5,
                fontSize: 12,
            },
        },
        card: {
            padding: "8px 24px 12px",
            marginBottom: 16,
        },
        h2: {
            fontSize: 13,
            fontWeight: 600,
            lineHeight: 1.5,
            paddingBottom: 4,
        },
        summary: {
            fontSize: 12,
        },
        defaultUse: {
            width: 21,
            margin: "4px 4px 0 4px",
        },
    })
);

interface CompanyInfoTabProps {
    control: Control<ProfileFormInputs>,
    errors: DeepMap<ProfileFormInputs, FieldErrors>,
    customFields: CustomFieldRS[],
    disableFieldForAM: boolean,
    viewingOtherProfile?: boolean,
    getValues: UseFormGetValues<ProfileFormInputs>,
    getCustomFields: (clientId: number) => Promise<void>,
    setValue: UseFormSetValue<ProfileFormInputs>
}

export default function CompanyInfoTab(props: CompanyInfoTabProps) {
    // set up field array for hotel loyalty information
    const customFieldArray = useFieldArray({ control: props.control, name: "customFieldList" });
    const firstRender = useRef(true);
    const watchU99 = useWatch({ control: props.control, name: "u99Field" });
    let canEdit = false;
    const classes = useStyles();
    const { userContext } = useContext<iUserContext>(UserContext);

    if (watchU99) {
        if (!props?.viewingOtherProfile) {
            canEdit = customFieldUtil.canDataEntryEdit(watchU99.accessibility, userContext.userType, DataEntryFlow.CompanyInfoTab);
        }
        else {
            canEdit = customFieldUtil.canEditForOthers(watchU99?.accessibility, userContext.userType, DataEntryFlow.CompanyInfoTab);
        }
    }

    
    function handleU99Change(val: CustomFieldValueRS) {
        if (val && val.value) {
            props.setValue('u99Field.value', val, { shouldValidate: true });
            props.getCustomFields(+val.value); //tricky way to convert to number  
            // Clear out the current customFields array so since we are retrieving new custom fields.
            customFieldArray.remove();
        }
    }

    return (
        <Grid container spacing={3}>

            {watchU99 && watchU99.number !== -1 &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Controller
                        name={`u99Field.value` as const}
                        control={props.control as FormInputsExportType}
                        rules={{
                            validate: {

                                required: (value: string | CustomFieldValueRS) => {
                                    if (!(props.viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
                                        if (typeof value === 'string')
                                            return value ? true : "This field is required "
                                        else
                                            return value.customFieldId === -1 ? "This field is required. " : true;
                                    }
                                }
                            }
                        }}
                        render={({ field: { onChange, value: rhfValue, ref, onBlur } }) => {
                            return <Autocomplete
                                id={`u99fieldvalue`}
                                options={watchU99.customFieldValueList}
                                getOptionLabel={(option) => option.name === "" ? option.value : option.name}
                                getOptionSelected={(option, val) => option.value.toUpperCase() == val?.value.toUpperCase()}
                                fullWidth
                                autoSelect
                                autoHighlight
                                value={watchU99.customFieldValueList?.find(f => {

                                    return f.value === (rhfValue as CustomFieldValueRS).value
                                })
                                }
                                ref={ref}
                                onBlur={onBlur}
                                disabled={!canEdit}
                                onChange={(_, val) => val === null ? onChange(new CustomFieldValueRS()) : handleU99Change(val)} // if the value is null, return an empty string
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id={`ff-u99fieldvalue-noteExternal`}
                                        label={
                                            <Box mr={-0.5}>
                                                {watchU99.name}{" "}
                                                {watchU99.noteExternal &&
                                                    <Tooltip arrow
                                                        title={watchU99.noteExternal}
                                                        placement="top"
                                                    >
                                                        <InfoOutlinedIcon className={classes.tooltipLabel} />
                                                    </Tooltip>
                                                }
                                            </Box>
                                        }
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={props.errors.u99Field ? true : false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />
                                )}
                            />

                        }
                        } />
                    <Typography variant='body2' color='error' className={classes.errorTextField}>
                        {props.errors?.u99Field?.value && (props?.errors?.u99Field?.value as any).message}
                    </Typography>
                </Grid>
            }
            {
                customFieldArray.fields.map((field, index) => {
                    return <CustomFieldFieldArray
                        key={`customField-${field.id}-${index}`}
                        field={field}
                        index={index}
                        control={props.control}
                        errors={props.errors}
                        customFieldValueList={field.customFieldValueList}
                        flow={DataEntryFlow.CompanyInfoTab}
                        viewingOtherProfile={props?.viewingOtherProfile}
                        requireCustomFields={!(props.viewingOtherProfile && userContext.userType < UserType.TravelManager && field.number !== 56)}
                    />
                })
            }
        </Grid>
    );
}



import React, { useState, useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    AppBar,
    Box,
    Divider,
    Grid,
    Link,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@material-ui/core";
import {
    AddCircleOutline as AddIcon,
    Flight,
    Hotel,
} from "@material-ui/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { Control, Controller, DeepMap, FieldErrors, useFieldArray, UseFormRegister, useWatch, UseFormGetValues, UseFormClearErrors, UseFormSetError, UseFormTrigger } from "react-hook-form";

import LocationSearchBox from "../../../components/shared/LocationSearchBox"
import { ServiceType } from "@cbtravel/common/lib/shared/common/enumerations/service-type";

import { ProfileFormInputs } from "./Profile";
import LoyaltyInfoFlightFormFields from "./FieldArrayComponents/LoyaltyInfoFlightFieldArray";
import LoyaltyInfoHotelFormFields from "./FieldArrayComponents/LoyaltyInfoHotelFieldArray";
import LoyaltyInfoCarFormFields from "./FieldArrayComponents/LoyaltyInfoCarFieldArray";
import { VendorLT } from "@cbtravel/common/lib/shared/messages/general/responses/lite/vendor-lt";
import { CarType } from "@cbtravel/common/lib/shared/common/enumerations/car-type"
import { SeatType } from "@cbtravel/common/lib/shared/common/enumerations/seat-type";
import { RoomType } from "@cbtravel/common/lib/shared/common/enumerations/room-type";
import { CarTransmissionType } from "@cbtravel/common/lib/shared/common/enumerations/car-transmission-type";
import { MealType } from "@cbtravel/common/lib/shared/common/enumerations/meal-type";


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiTypography-subtitle1": {
                fontSize: 12,
                marginBottom: theme.spacing(1),
            },
            "& .MuiFormHelperText-root": {
                fontSize: 12,
                color: "#808080",
            },
        },
        h3: {
            fontSize: 12,
            fontWeight: 300,
            textTransform: "uppercase",
            paddingTop: 10,
            marginBottom: -4,
            display: "block",
        },
        addIcon: {
            color: "#00467E",
            marginTop: -4,
        },
        removeIcon: {
            color: "#BC2C2F",
        },
        addText: {
            marginTop: 0,
            "& .MuiLink-button": {
                fontSize: 12,
                fontFamily: "Open Sans, Arial, sans-serif",
                fontWeight: 600,
                color: "#00467E",
                marginTop: 0,
                textDecoration: "none",
            },
            "& .MuiSvgIcon-root": {
                marginBottom: -1.5,
                marginRight: -1,
                fontSize: 13,
            },
        },
        removeWrapper: {
            marginTop: -36,
            marginBottom: -36,
            "& .MuiLink-button": {
                fontSize: 12,
                fontWeight: 600,
                fontFamily: "Open Sans, Arial, sans-serif",
                color: "#BC2C2F",
                textDecoration: "none",
            },
        },
        removeText: {
            "& .MuiSvgIcon-root": {
                marginRight: -1,
                marginBottom: -1.5,
                fontSize: 12,
            },
        },

        tabRoot: {
            flexGrow: 1,
        },
        preferencesTabs: {
            borderBottom: "none !important",
            "& .MuiButtonBase-root.MuiTab-root": {
                fontSize: 13,
                fontWeight: 600,
                textTransform: "none",
                borderRadius: 4,
                border: "1px solid #C9C7C7",
                height: 80,
            },
            "& .MuiTabs-indicator": {
                display: "none",
            },
            "& .MuiTab-textColorPrimary.Mui-selected": {
                backgroundColor: "#00467E",
            },
            "& .MuiTab-textColorPrimary.Mui-selected .MuiTab-wrapper": {
                color: "white",
            },
            "& .icon-rotate": {
                transform: "rotate(90deg)",
            },
        },
        middleTab: {
            margin: "0 16px",
        },
        errorTextField: {
            paddingTop: '5px',
        },
        errorTextCalendar: {
            paddingTop: '2px',
        }
    })
);

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component={"div"}>{children}</Typography>
            )}
        </div>
    );
}

function tabProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}

interface TravelPreferencesTabProps {
    control: Control<ProfileFormInputs>,
    errors: DeepMap<ProfileFormInputs, FieldErrors>,
    register: UseFormRegister<ProfileFormInputs>,
    airlineVendors: VendorLT[],
    hotelVendors: VendorLT[],
    carVendors: VendorLT[],
    getValues: UseFormGetValues<ProfileFormInputs>,
    clearErrors: UseFormClearErrors<ProfileFormInputs>,
    setError: UseFormSetError<ProfileFormInputs>,
    trigger: UseFormTrigger<ProfileFormInputs>,
    viewingOtherProfile: boolean
}


export default function TravelPreferencesTab(props: TravelPreferencesTabProps) {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    // set up field array for flight loyalty information
    const loyaltyInfoFlightFieldArray = useFieldArray({ control: props.control, name: "loyaltyInfosFlight" });
    const watchLoyaltyInfoFlight = useWatch({ control: props.control, name: "loyaltyInfosFlight" });
    const controlLoyaltyInfoFlight = loyaltyInfoFlightFieldArray.fields.map((field, index) => {
        return {
            ...field,
            ...watchLoyaltyInfoFlight[index]
        };
    });

    // set up field array for hotel loyalty information
    const loyaltyInfoHotelFieldArray = useFieldArray({ control: props.control, name: "loyaltyInfosHotel" });
    const watchLoyaltyInfoHotel = useWatch({ control: props.control, name: "loyaltyInfosHotel" });
    const controlLoyaltyInfoHotel = loyaltyInfoHotelFieldArray.fields.map((field, index) => {
        return {
            ...field,
            ...watchLoyaltyInfoHotel[index]
        };
    });

    // set up field array for rental car loyalty information
    const loyaltyInfoCarFieldArray = useFieldArray({ control: props.control, name: "loyaltyInfosCar" });
    const watchLoyaltyInfoCar = useWatch({ control: props.control, name: "loyaltyInfosCar" });
    const controlLoyaltyInfoCar = loyaltyInfoCarFieldArray.fields.map((field, index) => {
        return {
            ...field,
            ...watchLoyaltyInfoCar[index]
        };
    });


    return (
        <>
            <div className={classes.root}>
                <Typography variant="h2">Travel Preferences</Typography>
                <Typography variant="subtitle1">
                    Add your preferences to travel the way you want
                </Typography>

                <Box mt={3}>
                    <AppBar position="static" elevation={0} color="transparent">
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            variant="fullWidth"
                            scrollButtons="on"
                            textColor="primary"
                            aria-label="scrollable force tabs example"
                            className={classes.preferencesTabs}
                        >
                            <Tab
                                label="Air preferences"
                                icon={<Flight className="icon-rotate" />}
                                {...tabProps(0)}
                            />
                            <Tab
                                label="Hotel preferences"
                                icon={<Hotel />}
                                {...tabProps(1)}
                                className={classes.middleTab}
                            />
                            <Tab
                                label="Car preferences"
                                icon={<FontAwesomeIcon icon={faCar} style={{ fontSize: 20 }} />}
                                {...tabProps(2)}
                            />
                        </Tabs>
                    </AppBar>
                </Box>

                <TabPanel value={tabValue} index={0}>
                    <Box className={classes.root} mt={3}>
                        {controlLoyaltyInfoFlight.map((field, index) => {
                            return (
                                <LoyaltyInfoFlightFormFields
                                    key={field.id}
                                    field={field}
                                    index={index}
                                    control={props.control}
                                    controlLoyaltyInfoFlight={controlLoyaltyInfoFlight}
                                    loyaltyInfoFlightFieldArray={loyaltyInfoFlightFieldArray}
                                    errors={props.errors}
                                    airlineVendors={props.airlineVendors}
                                    getValues={props.getValues}
                                    setError={props.setError}
                                    clearErrors={props.clearErrors}
                                    trigger={props.trigger}
                                    viewingOtherProfile={props.viewingOtherProfile}
                                />
                            );
                        })}

                        <Box
                            component="p"
                            display="flex"
                            py={0.5}
                            className={classes.addText}
                        >
                            <Link href="#" component="button" color="primary" onClick={() => loyaltyInfoFlightFieldArray.append({ vendor: { name: "", code: "", vendorId: -1 }, loyaltyNumber: "", serviceType: ServiceType.Flight })}>
                                <AddIcon className={classes.addIcon} /> Add another airline
                            </Link>
                        </Box>

                        <Box mt={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Box mt={-1}>
                                        <Controller
                                            name="departingAirport"
                                            control={props.control}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <LocationSearchBox
                                                    label={"Preferred departing airport"}
                                                    onReactHookChange={(_, value) => onChange(value)}
                                                    placeholder="Search for and select an airport"
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    location={value}
                                                />
                                            )} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="airSeatRequest"
                                            control={props.control}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="seat-request"
                                                    select
                                                    label="Seat request"
                                                    placeholder="Select a seat type"
                                                    onBlur={onBlur}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    InputProps={{ autoComplete: "off" }}
                                                >
                                                    {seatTypes.map((type) => (
                                                        <MenuItem id={`dd-seat-request-${seatTypes.indexOf(type)}`} key={type} value={type} style={{ minHeight: "2em" }}>
                                                            {type == "NotApplicable" ? "No Preference" : type.replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )} />
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="mealRequest"
                                            control={props.control}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="meal-request"
                                                    select
                                                    label="Meal request"
                                                    placeholder="Select a meal type"
                                                    onBlur={onBlur}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    InputProps={{ autoComplete: "off" }}
                                                >
                                                    {mealTypes.map((type) => (
                                                        <MenuItem id={`dd-meal-request-${mealTypes.indexOf(type)}`} key={type} value={type} style={{ minHeight: "2em" }}>
                                                            {type == "NotApplicable" ? "No Preference" : type.replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )} />
                                    </form>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Box className={classes.root}>
                        <Box mt={3}>
                            {controlLoyaltyInfoHotel.map((field, index) => {
                                return (
                                    <LoyaltyInfoHotelFormFields
                                        key={field.id}
                                        field={field}
                                        index={index}
                                        control={props.control}
                                        controlLoyaltyInfoHotel={controlLoyaltyInfoHotel}
                                        loyaltyInfoHotelFieldArray={loyaltyInfoHotelFieldArray}
                                        errors={props.errors}
                                        hotelVendors={props.hotelVendors}
                                        getValues={props.getValues}
                                        setError={props.setError}
                                        clearErrors={props.clearErrors}
                                        trigger={props.trigger}
                                        viewingOtherProfile={props.viewingOtherProfile}
                                    />
                                );
                            })}
                        </Box>

                        <Box
                            component="p"
                            display="flex"
                            py={0.5}
                            className={classes.addText}
                        >
                            <Link href="#" component="button" color="primary" onClick={() => loyaltyInfoHotelFieldArray.append({ vendor: { name: "", code: "", vendorId: -1 }, loyaltyNumber: "", serviceType: ServiceType.Hotel })}>
                                <AddIcon className={classes.addIcon} /> Add another hotel chain
                            </Link>
                        </Box>

                        <Box mt={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="hotelRoomType"
                                            control={props.control}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="room-type-select"
                                                    select
                                                    label="Room type"
                                                    placeholder="Select a room type"
                                                    onBlur={onBlur}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    InputProps={{ autoComplete: "off" }}
                                                >
                                                    {roomTypes.map((type) => (
                                                        <MenuItem id={`dd-room-type-select-${roomTypes.indexOf(type)}`} key={type} value={type} style={{ minHeight: "2em" }}>
                                                            {type == "NotApplicable" ? "No Preference" : type.replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )} />
                                    </form>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </TabPanel>

                {/* Car preferences content */}
                <TabPanel value={tabValue} index={2}>
                    <Box className={classes.root} mt={3}>
                        {controlLoyaltyInfoCar.map((field, index) => {
                            return (
                                <LoyaltyInfoCarFormFields
                                    key={field.id}
                                    field={field}
                                    index={index}
                                    control={props.control}
                                    controlLoyaltyInfoCar={controlLoyaltyInfoCar}
                                    loyaltyInfoCarFieldArray={loyaltyInfoCarFieldArray}
                                    errors={props.errors}
                                    register={props.register}
                                    carVendors={props.carVendors}
                                    getValues={props.getValues}
                                    setError={props.setError}
                                    clearErrors={props.clearErrors}
                                    trigger={props.trigger}
                                    viewingOtherProfile={props.viewingOtherProfile}
                                />
                            );
                        })}
                        <Box
                            component="p"
                            display="flex"
                            py={0.5}
                            className={classes.addText}
                        >
                            <Link href="#" component="button" color="primary" onClick={() => loyaltyInfoCarFieldArray.append({ vendor: { name: "", code: "", vendorId: -1 }, loyaltyNumber: "", serviceType: ServiceType.Car })}>
                                <AddIcon className={classes.addIcon} /> Add another car rental company
                            </Link>
                        </Box>

                        <Box mt={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="carRentalType"
                                            control={props.control}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="rental-car-type"
                                                    select
                                                    label="Car type"
                                                    placeholder="Select a car type"
                                                    onBlur={onBlur}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    InputProps={{ autoComplete: "off" }}
                                                >
                                                    {carTypes.map((type) => (
                                                        <MenuItem id={`dd-rental-car-type-${carTypes.indexOf(type)}`} key={type} value={type} style={{ minHeight: "2em" }}>
                                                            {type == "NotApplicable" ? "No Preference" : type.replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )} />
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                                        <Controller
                                            name="carRentalTransmissionType"
                                            control={props.control}
                                            render={({
                                                field: { onChange, value, ref, onBlur }
                                            }) => (
                                                <TextField
                                                    id="rental-car-transmission-type"
                                                    select
                                                    label="Car transmission type"
                                                    placeholder="Select a transmission type"
                                                    onBlur={onBlur}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    ref={ref}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    InputProps={{ autoComplete: "off" }}
                                                >
                                                    {transmissionTypes.map(type => (
                                                        <MenuItem id={`dd-rental-car-transmission-type-${transmissionTypes.indexOf(type)}`} key={type} value={type} style={{ minHeight: "2em" }}>
                                                            {type == "NotApplicable" ? "No Preference" : type.replace(/([a-z])([A-Z])/g, '$1 $2')}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )} />
                                    </form>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </TabPanel>
            </div>
        </>
    );
}

const carTypes = [
    CarType.NotApplicable,
    CarType.CompactCar,
    CarType.CompactCarHybrid,
    CarType.EconomyCar,
    CarType.FullSizeCar,
    CarType.FullSizeCarHybrid,
    CarType.FullSizePickup,
    CarType.FullSizeSUV,
    CarType.IntermediateCar,
    CarType.IntermediateCarHybrid,
    CarType.IntermediateSUV,
    CarType.LuxuryCar,
    CarType.MiniCar,
    CarType.MiniVan,
    CarType.PremiumCar,
    CarType.StandardCar,
    CarType.StandardCarHybrid,
    CarType.StandardSUV
];

const transmissionTypes = [
    CarTransmissionType.NotApplicable,
    CarTransmissionType.Automatic,
    CarTransmissionType.Manual
];

const mealTypes = [
    MealType.NotApplicable,
    MealType.AsianVegetarian,
    MealType.Bland,
    MealType.Diabetic,
    MealType.FruitPlatter,
    MealType.GlutenIntolerant,
    MealType.Hindu,
    MealType.Kosher,
    MealType.LactoOvoVegetarian,
    MealType.LowCalorie,
    MealType.LowFat,
    MealType.LowLactose,
    MealType.lowSalt,
    MealType.Muslim,
    MealType.NoFish,
    MealType.PeanutFree,
    MealType.SeaFood,
    MealType.VegetarianJain,
    MealType.VegetarianOriental,
    MealType.VegetarianRaw,
    MealType.VegetarianVegan,
];

const roomTypes = [
    RoomType.NotApplicable,
    RoomType.Disability,
    RoomType.Double,
    RoomType.King,
    RoomType.Queen,
    RoomType.Single,
    RoomType.Twin
];

const seatTypes = [
    SeatType.NotApplicable,
    SeatType.Aisle,
    SeatType.Window,
];
import { UserType } from "@cbtravel/common/lib/shared/common/enumerations/user-type";
import { RegexUtils } from "@cbtravel/common/lib/shared/common/regex-utils";
import DateFnsUtils from "@date-io/date-fns";
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Link, MenuItem, TextField, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  CalendarToday, ExpandMore as ExpandMoreIcon, RemoveCircleOutline as RemoveIcon
} from "@material-ui/icons";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { useContext, useState } from "react";
import { Control, Controller, DeepMap, FieldErrors, UseFieldArrayReturn, UseFormGetValues, UseFormRegister, UseFormSetError, UseFormTrigger } from "react-hook-form";
import { UserContext } from "../../../../components/shared/UserContext";
import ActionDialog from "../../../../components/ui/Dialog/ActionDialog";
import { formUtils } from "../../../../util/form-utils";
import { cardTypes } from "../PaymentInfoTab";
import { CreditCardFields, ProfileFormInputs } from "../Profile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: 14,
      },
      "& .MuiSvgIcon-root": {
        // fontSize: 20,
      },
    },
    formControl: {
      marginLeft: -6,
      "& .MuiFormControlLabel-root": {
        marginLeft: 0,
        marginRight: 24,
      },
      "& .MuiCheckbox-colorSecondary": {
        padding: "5px",
      },
      "& .MuiTypography-body1": {
        fontSize: "12px",
      },
      "& .MuiIconButton-label .MuiSvgIcon-root": {
        fontSize: 16,
      },
    },
    inputWrapper: {
      "& .MuiDivider-root": {
        marginBottom: 24,
      },
    },
    inputEnabled: {
      "& .MuiIconButton-root": {
        marginRight: -13,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 20,
      },
    },
    inputDisabled: {
      "& .MuiSelect-icon": {
        display: 'none',
      },
      "& .MuiInputBase-input.Mui-disabled": {
        color: '#808080',
      },
      "& .MuiIconButton-root.Mui-disabled": {
        marginRight: -13,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: '#999999'
      },
    },
    h3: {
      fontSize: 12,
      fontWeight: 300,
      textTransform: "uppercase",
      paddingTop: 10,
      marginBottom: -4,
      display: "block",
    },
    accordion: {
      "& .MuiFormHelperText-root": {
        fontSize: 11,
        color: "#808080",
      },
      "& .MuiInputBase-input": {
        background: "#fff",
      },
      "& .MuiInputBase-root": {
        background: "#fff",
      },
      border: "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    accordionDetails: {
      padding: theme.spacing(2),
    },
    addIcon: {
      color: "#00467E",
      marginTop: -4,
    },
    removeIcon: {
      color: "#BC2C2F",
    },
    addText: {
      "& .MuiLink-button": {
        fontSize: 12,
        fontFamily: "Open Sans, Arial, sans-serif",
        fontWeight: 600,
        color: "#00467E",
        marginTop: 0,
        textDecoration: "none",
      },
      "& .MuiSvgIcon-root": {
        marginBottom: -1.5,
        marginRight: -1,
        fontSize: 13,
      },
    },
    removeWrapper: {
      marginTop: -36,
      marginBottom: -36,
      "& .MuiLink-button": {
        fontSize: 12,
        fontWeight: 600,
        fontFamily: "Open Sans, Arial, sans-serif",
        color: "#BC2C2F",
        textDecoration: "none",
      },
    },
    removeText: {
      "& .MuiSvgIcon-root": {
        marginRight: -1,
        marginBottom: -1.5,
        fontSize: 12,
      },
    },
    errorTextField: {
      paddingTop: '5px',
    },
    errorTextCalendar: {
      paddingTop: '2px',
    },
    h2: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: 1.5,
      paddingBottom: 4,
    },
    summary: {
      backgroundColor: "rgba(0, 0, 0, .03)",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: -1,
      minHeight: 44,
      "&.MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 44,
      },
    },
    content: {
      "&$expanded": {
        margin: "12px 0",
      },
    },
    defaultUse: {
      width: 21,
      margin: "4px 4px 0 4px",
    },
  })
);

interface CreditCardFormFieldsProps {
  field: CreditCardFields & { id: string },
  index: number,
  control: Control<ProfileFormInputs>,
  controlCreditCards: Array<CreditCardFields & { id: string }>,
  cardFieldArray: UseFieldArrayReturn<ProfileFormInputs, "creditCards", "id">,
  errors: DeepMap<ProfileFormInputs, FieldErrors>,
  register: UseFormRegister<ProfileFormInputs>,
  setError: UseFormSetError<ProfileFormInputs>,
  trigger: UseFormTrigger<ProfileFormInputs>,
  getValues: UseFormGetValues<ProfileFormInputs>,
  viewingOtherProfile: boolean
}

export default function CreditCardFormFields(props: CreditCardFormFieldsProps) {
  const classes = useStyles();
  const { field, index, control, controlCreditCards, cardFieldArray, errors, register, viewingOtherProfile } = props;
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const { userContext } = useContext(UserContext);

  const [expanded, setExpanded] = useState(field.cardNumber === "");

  const cardValidationRules = {
    nameOnCard: {
      validate: {
        required: (value: string) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            let c = props.getValues(`creditCards.${index}` as `creditCards.0`);
            if (c.isSaved) {
              return true
            }
            if (!value && (c.cardNumber || c.cardType || c.displayName || c.expireDate)) {
              return "Name is required";
            }
            return true;
          }
        },
        requireOthers: (value: string) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            checkStringAgainstCardNumber(value);
            checkStringAgainstCardType(value);
            checkStringAgainstExpireDate(value);
            return true;
          }
        }
      },
      maxLength: { value: 255, message: "Max 255 characters" },
    },
    displayName: {
      validate: {
        requireOthers: (value: string) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            checkStringAgainstNameOnCard(value);
            checkStringAgainstCardNumber(value);
            checkStringAgainstCardType(value);
            checkStringAgainstExpireDate(value);
            return true;
          }
        }
      }
    },
    cardType: {
      validate: {
        required: (value: string) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            let c = props.getValues(`creditCards.${index}` as `creditCards.0`);
            if (c.isSaved) {
              return true
            }
            if (!areFieldsEmpty() && (!value || value?.trim().length === 0)) {
              return "Card type is required";
            }
          }
        },
        requireOthers: (value: string) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            checkStringAgainstNameOnCard(value);
            checkStringAgainstCardNumber(value);
            checkStringAgainstExpireDate(value);
            return true;
          }
        }
      }
    },
    cardNumber: {
      validate: {
        required: (value: string) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            let c = props.getValues(`creditCards.${index}` as `creditCards.0`);
            if (c.isSaved) {
              return true;
            }
            if (!areFieldsEmpty() && (!value || value?.trim().length === 0)) {
              return "Card number is required";
            }
          }
        },
        requireOthers: (value: string) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            checkStringAgainstNameOnCard(value);
            checkStringAgainstCardType(value);
            checkStringAgainstExpireDate(value);
            return true;
          }
        },
        pattern: (value: string) => {
          let c = props.getValues(`creditCards.${index}` as `creditCards.0`);
          if (c.isSaved) {
            return true;
          }
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager) && !areFieldsEmpty()) {
            if (!value.match(RegexUtils.NUMBERS_ONLY)) {
              return "Please use numbers only";
            }
            let dup = false;
            const cardNumbers = new Set();
            cardNumbers.add(c.cardNumber)
            controlCreditCards.forEach((e, i) => {
              if (i !== props.index) {
                if (cardNumbers.has(e.cardNumber)) {
                  dup = true;
                }
                else {
                  cardNumbers.add(e.cardNumber)
                }
              }
            });
            if (dup) {
              dup = false;
              return "This credit card number already exists. Please enter a different number.";
            }
          }
        }
      },
      maxLength: { value: 19, message: "Max length of 19" },
      minLength: { value: 10, message: "Min length of 10" }
    },
    expireDate: {
      validate: {
        required: (value: Date | null) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            let c = props.getValues(`creditCards.${index}` as `creditCards.0`);
            if (c.isSaved) {
              return true
            }
            if (!areFieldsEmpty() && value === null) {
              return "Expiration date is required";
            }
          }
        },
        expireDateInFuture: (value: Date | null) => {
          let currentDate = new Date();
          let lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
          if (!areFieldsEmpty() && value && value < lastDayOfMonth) {
            return "Expiration date must be in the future"
          }
        },
        requireOthers: (value: Date | null) => {
          if (!(viewingOtherProfile && userContext.userType < UserType.TravelManager)) {
            let strValue = value?.toString() || "";

            checkStringAgainstNameOnCard(strValue);
            checkStringAgainstCardType(strValue);
            checkStringAgainstCardNumber(strValue);
            return true;
          }
        }
      },
    }
  };

  /**
   * Used for validating whether name on card has an error when another value changes.
   * @param value The value that has changed.
   */
  function checkStringAgainstNameOnCard(value: string) {
    let c = props.getValues(`creditCards.${index}` as `creditCards.0`);

    if (value && !c.nameOnCard) {
      props.setError(`creditCards.${index}.nameOnCard` as `creditCards.0.nameOnCard`, {
        type: "required",
        message: "Name on card is required"
      });
    } else if (!value && errors?.creditCards?.[index]?.nameOnCard) {
      props.trigger(`creditCards.${index}.nameOnCard` as `creditCards.0.nameOnCard`);
    }
  }


  /**
   * Used for validating whether card number has an error when another value changes.
   * @param value The value that has changed.
   */
  function checkStringAgainstCardNumber(value: string) {
    let c = props.getValues(`creditCards.${index}` as `creditCards.0`);

    if (value && !c.cardNumber) {
      props.setError(`creditCards.${index}.cardNumber` as `creditCards.0.cardNumber`, {
        type: "required",
        message: "Card number is required"
      });
    } else if (!value && errors?.creditCards?.[index]?.cardNumber) {
      props.trigger(`creditCards.${index}.cardNumber` as `creditCards.0.cardNumber`);
    }
  }

  /**
   * Used for validating whether card type has an error when another value changes.
   * @param value The value that has changed.
   */
  function checkStringAgainstCardType(value: string) {
    let c = props.getValues(`creditCards.${index}` as `creditCards.0`);

    if (value && !c.cardType) {
      props.setError(`creditCards.${index}.cardType` as `creditCards.0.cardType`, {
        type: "required",
        message: "Card type is required"
      });
    } else if (!value && errors?.creditCards?.[index]?.cardType) {
      props.trigger(`creditCards.${index}.cardType` as `creditCards.0.cardType`);
    }
  }

  /**
   * Used for validating whether expire date has an error when another value changes.
   * @param value The value that has changed.
   */
  function checkStringAgainstExpireDate(value: string) {
    let c = props.getValues(`creditCards.${index}` as `creditCards.0`);

    if (value && !c.expireDate) {
      props.setError(`creditCards.${index}.expireDate` as `creditCards.0.expireDate`, {
        type: "required",
        message: "Expiration date is required"
      });
    } else if (!value && errors?.creditCards?.[index]?.expireDate) {
      props.trigger(`creditCards.${index}.expireDate` as `creditCards.0.expireDate`);
    }
  }

  /**
   * Clears the fields if only one card. Deletes the card of more than one.
   */
  function handleRemove() {
    if (controlCreditCards.length > 1) {
      cardFieldArray.remove(index);
    } else {
      cardFieldArray.update(index, {
        nameOnCard: "",
        displayName: "",
        cardType: "",
        cardNumber: "",
        expireDate: null,
        defaultForAir: false,
        defaultForCar: false,
        defaultForHotel: false,
        defaultForRail: false,
        isSaved: false
      });
    }
  }

  /**
   * Determines if this credit card is partially filled out. U
   * 
   * @returns True if card hasn't been partially filled out, false otherwise.
   */
  function areFieldsEmpty(): boolean {
    let fieldArrayField = controlCreditCards[index];

    return (fieldArrayField.nameOnCard?.length === 0 &&
      fieldArrayField.displayName?.length === 0 &&
      fieldArrayField.cardType?.length === 0 &&
      fieldArrayField.cardNumber?.length === 0 &&
      fieldArrayField?.expireDate === null);
  }

  /**
   * When checking the box that indicates this card as default for plane tickets we need
   * to make it unique by unchecking that box for any other cards.
   * @param checked True if checked, false otherwise
   * @param onChange React hook form onChange function supplied by the Controller component.
   */
  function handleCheckDefaultPlane(checked: boolean, onChange: (...event: any[]) => void) {
    if (checked) {
      controlCreditCards.forEach((c, i) => {
        if (i !== props.index && c.defaultForAir)
          cardFieldArray.update(i, { defaultForAir: false });
      });
    }

    onChange(checked);
  }

  /**
   * When checking the box that indicates this card as default for car rentals we need
   * to make it unique by unchecking that box for any other cards.
   * @param checked True if checked, false otherwise
   * @param onChange React hook form onChange function supplied by the Controller component.
   */
  function handleCheckDefaultCar(checked: boolean, onChange: (...event: any[]) => void) {
    if (checked) {
      controlCreditCards.forEach((c, i) => {
        if (i !== props.index && c.defaultForCar)
          cardFieldArray.update(i, { defaultForCar: false });
      });
    }

    onChange(checked);
  }

  /**
   * When checking the box that indicates this card as default for rail tickets we need
   * to make it unique by unchecking that box for any other cards.
   * @param checked True if checked, false otherwise
   * @param onChange React hook form onChange function supplied by the Controller component.
   */
  function handleCheckDefaultRail(checked: boolean, onChange: (...event: any[]) => void) {
    if (checked) {
      controlCreditCards.forEach((c, i) => {
        if (i !== props.index && c.defaultForRail)
          cardFieldArray.update(i, { defaultForRail: false });
      });
    }

    onChange(checked);
  }

  /**
   * When checking the box that indicates this card as default for hotel reservations we need
   * to make it unique by unchecking that box for any other cards.
   * @param checked True if checked, false otherwise
   * @param onChange React hook form onChange function supplied by the Controller component.
   */
  function handleCheckDefaultHotel(checked: boolean, onChange: (...event: any[]) => void) {
    if (checked) {
      controlCreditCards.forEach((c, i) => {
        if (i !== props.index && c.defaultForHotel)
          cardFieldArray.update(i, { defaultForHotel: false });
      });
    }

    onChange(checked);
  }

  /**
  * Used for making the text that displays on the accordion summaries for the cards.
  * 
  * @param cardTypeCode The two-character code for the payment network.
  * @param cardNumber The number on the card.
  * @returns A string with text to place in the accordion summaries on this tab.
  */
  function summaryText(cardTypeCode: string, cardNumber: string) {
    let newCC = cardNumber === "";

    if (newCC) {
      return "New card";
    } else {
      let cardTypeName = cardTypes.find((t) => t.value === cardTypeCode)?.name || "Card";
      return `${cardTypeName} ending in ...${cardNumber.slice(-4)}`;
    }

  }

  return (
    <Accordion className={classes.accordion}
      id={field.id}
      expanded={errors.creditCards?.[index] ? true : expanded}
      key={`cardFieldArray-${index}`} // using index to get past RHF update bug that changes field.id
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.summary}
        onClick={(event) => { setExpanded(errors.creditCards?.[index] ? true : !expanded) }}
        aria-controls="saved card"
        id="card2"
      >
        <Box flexDirection="row" display="flex" alignItems="center">
          <Box>
            <Typography variant="h2" className={classes.h2}>
              {summaryText(field.cardType, field.cardNumber)}
            </Typography>
          </Box>
          <Box ml={2}>
            {field.defaultForCar ?
              <img
                id={`img-car-svg`}
                src={require("../../../../icons/car.svg").default}
                className={classes.defaultUse}
                title="car"
              />
              : ""}
            {field.defaultForHotel ?
              <img
                id={`img-hotel-svg`}
                src={require("../../../../icons/hotel.svg").default}
                className={classes.defaultUse}
                title="hotel"
              />
              : ""}
            {field.defaultForRail ?
              <img
                id={`img-rail-svg`}
                src={require("../../../../icons/rail.svg").default}
                className={classes.defaultUse}
                title="rail"
              />
              : ""}
            {field.defaultForAir ?
              <img
                id={`img-plane-svg`}
                src={require("../../../../icons/plane.svg").default}
                className={classes.defaultUse}
                title="plane"
              />
              : ""}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Box mt={2} mx={1}>
          <Grid container spacing={3} key={field.id} className={classes.root}>
            {/* Display dialog when this item is removed */}
            <ActionDialog
              title="Remove credit card?"
              icon={
                <div className={useStyles().removeIcon}>
                  <RemoveIcon />
                </div>
              }
              positiveText="Remove"
              negativeText="Cancel"
              dialogType="delete"
              open={showRemoveDialog}
              setOpen={setShowRemoveDialog}
              positiveAction={handleRemove}
            >
              <p>
                {field.cardNumber.length >= 4 ?
                  `You will be permanently removing your credit card ending in 
            ${field.cardNumber.substring(field.cardNumber.length - 4)}
            from your profile.`
                  :
                  "You will be removing this card from your profile."}
              </p>
            </ActionDialog>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                <Controller
                  name={`creditCards.${index}.nameOnCard` as const}
                  control={control}
                  rules={cardValidationRules.nameOnCard}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <TextField
                      id={`ff-name-on-card-${field.cardNumber}`}
                      label="Name on card"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      error={errors.creditCards?.[index]?.nameOnCard ? true : false}
                      InputProps={{ autoComplete: "off" }}
                    />
                  )}
                />
              </form>
              <Typography variant='body2' color='error' className={classes.errorTextField}>
                {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                {props.errors?.creditCards?.[index]?.nameOnCard && (props?.errors?.creditCards?.[index]?.nameOnCard as any).message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                <Controller
                  name={`creditCards.${index}.displayName` as const}
                  control={control}
                  rules={cardValidationRules.displayName}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <TextField
                      id={`ff-display-name-${field.cardNumber}`}
                      label="Display name"
                      placeholder="Ex. My corporate card"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      error={errors.creditCards?.[index]?.displayName ? true : false}
                      InputProps={{ autoComplete: "off" }}
                    />
                  )}
                />
              </form>
              <Typography variant='body2' color='error' className={classes.errorTextField}>
                {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                {props.errors?.creditCards?.[index]?.displayName && (props?.errors?.creditCards?.[index]?.displayName as any).message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                <Controller
                  name={`creditCards.${index}.cardType` as const}
                  control={props.control}
                  rules={cardValidationRules.cardType}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <TextField
                      id={`ff-card-type-${field.cardNumber}`}
                      select
                      label="Card type"
                      placeholder="Select a card type"
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      ref={ref}
                      variant="outlined"
                      error={errors.creditCards?.[index]?.cardType ? true : false}
                      size="small"
                      fullWidth
                      className={field.isSaved ? classes.inputDisabled : ""}
                      disabled={field.isSaved}
                      InputProps={field.isSaved ? { endAdornment: <LockOutlinedIcon /> } : { autoComplete: "off" }}
                    >
                      {cardTypes.map((type) => (
                        <MenuItem
                          id={`dd-card-type-${cardTypes.indexOf(type)}`}
                          key={type.value}
                          value={type.value}
                          style={{ minHeight: "2em" }}
                        >
                          {type.name === "NotApplicable"
                            ? ""
                            : type.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </form>
              <Typography variant='body2' color='error' className={classes.errorTextField}>
                {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                {props.errors?.creditCards?.[index]?.cardType && (props?.errors?.creditCards?.[index]?.cardType as any).message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <form onSubmit={(e) => e.preventDefault()}> {/** Adding this tag lets Chrome recognize autocomplete settings */}
                <Controller
                  name={`creditCards.${index}.cardNumber` as const}
                  control={control}
                  rules={cardValidationRules.cardNumber}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <TextField
                      id={`ff-card-number-${field.cardNumber}`}
                      label="Card number"
                      variant="outlined"
                      size="small"
                      error={errors.creditCards?.[index]?.cardNumber ? true : false}
                      fullWidth
                      onBlur={(e) => { formUtils.trimOnBlur(onBlur, onChange, e.target.value) }}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      className={(`creditCards.${index}.isSaved` as const) ? classes.inputDisabled : ""}
                      disabled={field.isSaved}
                      InputProps={field.isSaved ? { endAdornment: <LockOutlinedIcon /> } : { autoComplete: "off" }}
                    />
                  )}
                />
              </form>
              <Typography variant='body2' color='error' className={classes.errorTextField}>
                {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                {props.errors?.creditCards?.[index]?.cardNumber && (props?.errors?.creditCards?.[index]?.cardNumber as any).message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Box mt={-1}>
                <Controller
                  name={`creditCards.${index}.expireDate` as const}
                  control={control}
                  rules={cardValidationRules.expireDate}
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                      <KeyboardDatePicker
                        disablePast
                        margin="dense"
                        id="date-picker"
                        label="Expiration date"
                        openTo="year"
                        format="MM/yy"
                        placeholder="MM/YY"
                        invalidDateMessage=""
                        minDateMessage=""
                        views={["year", "month"]}
                        inputVariant="outlined"
                        value={value}
                        onBlur={onBlur}
                        onClose={onBlur}
                        onChange={onChange}
                        fullWidth
                        className={classes.inputEnabled}
                        InputProps={{ autoComplete: "off" }}
                        keyboardIcon={<CalendarToday />}
                        error={errors.creditCards?.[index]?.expireDate ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />

                {/* TODO: Error message/handling needs to be updated to MM/YYYY and to credit card*/}
                <Typography variant='body2' color='error' className={classes.errorTextCalendar}>
                  {/* (... as any) explained here https://github.com/react-hook-form/react-hook-form/issues/987 */}
                  {props.errors?.creditCards?.[index]?.expireDate && (props?.errors?.creditCards?.[index]?.expireDate as any).message}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="subtitle1">Default card for:</Typography>
              <FormGroup row className={classes.formControl}>
                <Controller
                  name={`creditCards.${index}.defaultForAir` as const}
                  control={control}
                  render={({
                    field: { onChange, value }
                  }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="checkbox-plane"
                          checked={value}
                          onChange={(_e, v) => handleCheckDefaultPlane(v, onChange)}
                          name="checkedPlane"
                          color="secondary"
                        />
                      }
                      label="Plane tickets"
                    />
                  )} />
                <Controller
                  name={`creditCards.${index}.defaultForCar` as const}
                  control={control}
                  render={({
                    field: { onChange, value }
                  }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="checkbox-car"
                          checked={value}
                          onChange={(_e, v) => handleCheckDefaultCar(v, onChange)}
                          name="checkedCar"
                          color="secondary"
                        />
                      }
                      label="Car rentals"
                    />
                  )} />
                <Controller
                  name={`creditCards.${index}.defaultForRail` as const}
                  control={control}
                  render={({
                    field: { onChange, value }
                  }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="checkbox-rail"
                          checked={value}
                          onChange={(_e, v) => handleCheckDefaultRail(v, onChange)}
                          name="checkedRail"
                          color="secondary"
                        />
                      }
                      label="Rail tickets"
                    />
                  )} />
                <Controller
                  name={`creditCards.${index}.defaultForHotel` as const}
                  control={control}
                  render={({
                    field: { onChange, value }
                  }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="checkbox-hotel"
                          checked={value}
                          onChange={(_e, v) => handleCheckDefaultHotel(v, onChange)}
                          name="checkedHotel"
                          color="secondary"
                        />
                      }
                      label="Hotel reservations"
                    />
                  )} />
              </FormGroup>
            </Grid>
            {/* Don't allow removal of final element */}
            <Grid container justify="flex-end" className={classes.removeWrapper}>
              <Box component={"div"} className={classes.removeText}>
                <Typography align="right">
                  <Link
                    id={`link-removeDialog`}
                    href="#"
                    component="button"
                    color="error"
                    onClick={() => {
                      setShowRemoveDialog(true);
                    }}
                  >
                    <RemoveIcon className={classes.removeIcon} /> Remove
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid >


        </Box>
      </AccordionDetails>
    </Accordion >

  );
}


